/* @flow */

import * as React from 'react'
import {
  AutoSizer,
  Grid,
  InfiniteLoader,
  WindowScroller,
} from 'react-virtualized'
import memo from 'memoize-one'
import styled from 'styled-components'
import chunk from 'lodash/chunk'
import gridStyles from './grid_styles.css'

import CloudinaryResource from '../../../../infrastructure/components/CloudinaryResource'
import ProductGridDataSource from './ProductGridDataSource'
import { ROW_OVERSCAN, fetchProducts as defaultFetchProducts } from './shared'

import type { Id } from '../../../types'

type Props = {
  brandId: Id,
  cellData?: Object,
  cellRenderer: React.ComponentType,
  config?: string,
  container?: React.Node,
  mode: 'list' | 'shop',
  onProductsLoad: Array<Product>,
  productsFetchData?: Object,
  query: ?string,
  selected: Array<Id>,
  shopV2?: boolean,
}

const ProductGrid = ({
  brandId,
  cellData,
  cellRenderer = DefaultCellRenderer,
  config,
  container,
  customConfig,
  fetchProducts = defaultFetchProducts,
  filters,
  mode = 'list',
  onClick,
  onProductsLoad,
  productsFetchData,
  query,
  selected,
  selectedProducts,
  shopV2 = false,
  sort,
  useSelectedProductsV2 = false,
}: Props) => {
  const useConfig = React.useMemo(() => {
    if (customConfig) {
      return customConfig
    }

    return config ? configs[config] : configs['normal']
  }, [config, customConfig])

  const toggleProduct = React.useCallback(
    (product, splitKey) => {
      const updated = [...selected]

      const index = updated.indexOf(product.id)
      if (index === -1) {
        updated.push(product.id)
      } else {
        updated.splice(index, 1)
      }

      onClick(updated, product, splitKey)
    },
    [onClick, selected, selectedProducts, useSelectedProductsV2]
  )

  return (
    <AutoSizer disableHeight>
      {({ width }) => {
        return (
          <ProductGridDataSource
            brandId={brandId}
            config={useConfig}
            fetchProducts={fetchProducts}
            filters={filters}
            mode={mode}
            onProductsLoad={onProductsLoad}
            productsFetchData={productsFetchData}
            query={query}
            sort={sort}
            shopV2={shopV2}
            width={width}
          >
            {({
              columnWidth,
              columnCount,
              isLoadedMap,
              onRowsRendered,
              products,
              registerChild,
              rowCount,
              total,
            }) => {
              if (!total) {
                return null
              }

              return (
                <WindowScroller scrollElement={container}>
                  {({ height, isScrolling, scrollTop }) => {
                    return (
                      <div className={gridStyles.WindowScrollerWrapper}>
                        <GridRenderer
                          cellData={cellData}
                          cellRenderer={cellRenderer}
                          config={useConfig}
                          columnCount={columnCount}
                          columnWidth={columnWidth}
                          height={height}
                          isLoadedMap={isLoadedMap}
                          isScrolling={isScrolling}
                          onClick={toggleProduct}
                          onRowsRendered={onRowsRendered}
                          registerChild={registerChild}
                          rowCount={rowCount}
                          selected={selected}
                          scrollTop={scrollTop}
                          total={total}
                          width={width}
                        />
                      </div>
                    )
                  }}
                </WindowScroller>
              )
            }}
          </ProductGridDataSource>
        )
      }}
    </AutoSizer>
  )
}

export default ProductGrid

const CellContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px 0;
`

const ProductContainer = styled.div`
  align-items: center;
  border: ${({ selected }: { selected: boolean }) =>
    selected ? '2px dashed #ab8d55' : '1px solid #f8f8f8'};
  cursor: pointer;
  flex-direction: column;
  display: flex;
  height: 100%;
  padding: 10px;
`

const ProductImage = styled(CloudinaryResource)`
  height: 100%;
  width: 100%;
`

const ProductDataContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const ProductNameLabel = styled.div`
  font-weight: bold;
`

const ProductItemNumberLabel = styled.div``

const configs = {
  normal: {
    columnWidth: 150,
    imagePreset: 'product_grid_normal',
    imageHeight: 160,
    rowHeight: 270,
  },
  large: {
    columnWidth: 180,
    imagePreset: 'product_grid_normal',
    imageHeight: 190,
    rowHeight: 300,
  },
}

type GridRendererProps = {
  cellRenderer: React.ComponentType,
  config: Object,
  columnCount: number,
  columnWidth: number,
  height: number,
  isScrolling: boolean,
  onClick: Function,
  onRowsRendered: Function,
  registerChild: Function,
  rowCount: number,
  selected: Array<Id>,
  scrollTop: number,
  total: number,
  width: number,
}

const GridRenderer = ({
  cellData,
  cellRenderer: CellRendererComponent,
  columnCount,
  columnWidth,
  config,
  height,
  isLoadedMap,
  isScrolling,
  onClick,
  onRowsRendered,
  registerChild,
  rowCount,
  selected,
  scrollTop,
  total,
  width,
}: GridRendererProps) => {
  const onSectionRendered = React.useCallback(
    ({ rowStartIndex, rowStopIndex }) => {
      return onRowsRendered({
        startIndex: rowStartIndex,
        stopIndex: rowStopIndex,
      })
    },
    [onRowsRendered]
  )

  const {
    imageHeight,
    imagePreset,
    rowHeight,
    columnWidth: targetColumnWidth,
  } = config

  const renderCell = React.useCallback(
    ({ columnIndex, key, rowIndex, style }) => {
      const row = isLoadedMap[rowIndex]
      const product = row ? row[columnIndex] : null

      return (
        <CellRendererComponent
          key={key}
          imageHeight={imageHeight}
          imagePreset={imagePreset}
          onClick={onClick}
          product={product}
          row={row}
          selected={selected}
          style={style}
          targetColumnWidth={targetColumnWidth}
          {...(cellData || {})}
        />
      )
    },
    [
      cellData,
      CellRendererComponent,
      imageHeight,
      imagePreset,
      isLoadedMap,
      onClick,
      selected,
      targetColumnWidth,
    ]
  )

  return (
    <Grid
      autoHeight
      cellRenderer={renderCell}
      className={gridStyles.BodyGrid}
      columnWidth={columnWidth}
      columnCount={columnCount}
      height={height}
      isScrolling={isScrolling}
      onSectionRendered={onSectionRendered}
      noContentRenderer={noContent}
      overscanRowCount={ROW_OVERSCAN}
      ref={registerChild}
      rowHeight={rowHeight}
      rowCount={rowCount}
      scrollTop={scrollTop}
      width={width}
    />
  )
}

const noContent = () => <div>No products found...</div>

export const DefaultCellRenderer = ({
  imageHeight,
  imagePreset,
  onClick,
  product,
  row,
  selected,
  style,
  targetColumnWidth,
}) => {
  return (
    <CellContainer style={style}>
      {!product && <div />}
      {product && (
        <ProductContainer
          onClick={() => onClick(product)}
          selected={selected.includes(product.id)}
          style={{ width: targetColumnWidth }}
        >
          <div style={{ height: imageHeight }}>
            <ProductImage
              id={product.image}
              presets={imagePreset}
              fallback="linesheet_list"
            />
          </div>

          <ProductDataContainer>
            <ProductNameLabel>{product.name}</ProductNameLabel>

            <ProductItemNumberLabel>
              {product.item_number}
            </ProductItemNumberLabel>
          </ProductDataContainer>
        </ProductContainer>
      )}
    </CellContainer>
  )
}
