import React from 'react'
import styled from 'styled-components'
import { SessionContext } from '../shared'
import TraedeLogo from '../../assets/images/logo_black.svg'
import { Link } from '@reach/router'

const NoAccessToShowroom = ({ brand, imageResolver }) => {
  const { user } = React.useContext(SessionContext)

  return (
    <Wrapper>
      <Content>
        <div style={{ textAlign: 'center', marginBottom: 60 }}>
          {(brand && brand.logo !== null) && (
            <BrandLogoWrapper>
              <img src={imageResolver(brand.logo)} />
            </BrandLogoWrapper>
          )}
        </div>

        <h1>You need permission to access</h1>

        {user && (
          <div style={{ textAlign: 'center' }}>
            <p>Ask for access or switch to an account with permission.</p>

            <p>
              You are signed in as <strong>{user.email}</strong>
            </p>
          </div>
        )}
        {!user && (
          <div style={{ textAlign: 'center' }}>
            <p>You have tried to access a private showroom.</p>
            <p>Please sign in to access.</p>

            <br />
            <br />

            <Link to="/login" className="btn btn-primary">
              Sign in
            </Link>
          </div>
        )}

        <div style={{ textAlign: 'center', marginTop: 40 }}>
          <LogoContainer>
            showrooms{' '}
            <sub>
              by <img src={TraedeLogo} alt="Traede" />
            </sub>
          </LogoContainer>
        </div>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: #f3f3f3;

  h1 {
    color: #000;
    font-size: 35px;
    margin-bottom: 45px;
  }

  h2 {
    margin: 0;
    font-size: 19px;
    line-height: 190%;
    font-weight: 100;
  }

  p {
    color: #000;
    margin: 0;
    font-size: 18px;
    line-height: 190%;
    font-weight: 100;

    strong {
      font-size: 95%;
      font-weight: 500;
      letter-spacing: 0.1px;
    }
  }

  .btn {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 100;
    font-size: 12px;
    padding: 7px 16px;
    background-color: #43ace9;
  }
`

const BrandLogoWrapper = styled.div`
  display: inline-block;
  padding: 15px;
  background-color: #fff;
  border-radius: 4px;
  margin-right: 20px;

  > img {
    max-width: 200px;
    max-height: 60px;
  }
`

const LogoContainer = styled.div`
  color: #000;
  font-family: 'Rokkitt', sans-serif;
  font-size: 27px;

  sub {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    bottom: -0.12em !important;

    img {
      height: 10px;
      margin-top: -2px;
      margin-left: 7px;
    }
  }
`

const Content = styled.div`
  width: 100%;
  padding: 50px 30px;
  max-width: 560px;
`

export default NoAccessToShowroom
