import {
  ENTER_WEBSHOP,
  LEAVE_WEBSHOP,
  CHANGE_WEBSHOP_CURRENCY,
  WEBSHOP_TOTALS_REQUEST,
  WEBSHOP_TOTALS_SUCCESS,
  WEBSHOP_TOTALS_FAILURE,
  WEBSHOP_FAVORITES_SUCCESS,
  FETCH_WEBSHOP_LANDING_REQUEST,
  FETCH_WEBSHOP_LANDING_SUCCESS,
} from '../actions/index'
import {
  WEBSHOP_PAGES_SUCCESS,
  AVAILABLE_CURRENCIES_SUCCESS,
} from '../actions/shop'

const initialState = {
  currency: null,
  favorites: {
    products: [],
    total: 0,
  },
  pages: [],
  inWebshop: false,
  totals: {
    isFetching: false,
    quantity: 0,
    price: 0.0,

    shipping: 0,
    shipping_vat: true,
    free_shipping_above: null,
    free_shipping_diff: null,
    reached_free_shipping: null,
  },
  landing: {
    isFetching: false,
    settings: [],
    templates: [],
  },
}

export function webshop(state = initialState, action) {
  switch (action.type) {
    case ENTER_WEBSHOP:
      return Object.assign({}, state, {
        inWebshop: true,
      })
    case LEAVE_WEBSHOP:
      return Object.assign({}, state, {
        inWebshop: false,
      })
    case CHANGE_WEBSHOP_CURRENCY:
      return Object.assign({}, state, {
        currency: action.currency,
      })
    case WEBSHOP_TOTALS_REQUEST:
      return Object.assign({}, state, {
        totals: Object.assign({}, state.totals, {
          isFetching: true,
        }),
      })
    case WEBSHOP_PAGES_SUCCESS:
      return Object.assign({}, state, {
        pages: action.payload,
      })
    case WEBSHOP_TOTALS_SUCCESS:
      return Object.assign({}, state, {
        totals: Object.assign({}, state.totals, {
          isFetching: false,
          quantity: action.payload.quantity,
          price: action.payload.price,

          shipping: action.payload.shipping,
          shipping_vat: action.payload.shipping_vat,
          free_shipping_above: action.payload.free_shipping_above,
          free_shipping_diff: action.payload.free_shipping_diff,
          reached_free_shipping: action.payload.reached_free_shipping,
        }),
      })
    case WEBSHOP_TOTALS_FAILURE:
      return Object.assign({}, state, {
        totals: Object.assign({}, state.totals, {
          isFetching: false,
        }),
      })
    case WEBSHOP_FAVORITES_SUCCESS:
      return Object.assign({}, state, {
        favorites: action.payload,
      })
    case FETCH_WEBSHOP_LANDING_REQUEST:
      return Object.assign({}, state, {
        landing: Object.assign({}, state.landing, {
          isFetching: true,
        }),
      })
    case FETCH_WEBSHOP_LANDING_SUCCESS:
      return Object.assign({}, state, {
        landing: Object.assign({}, state.landing, {
          settings: action.payload.settings,
          templates: action.payload.templates,
          isFetching: false,
        }),
      })
    default:
      return state
  }
}
