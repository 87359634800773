import React from 'react'
import Select from 'react-select'
import { createAction } from '../shared'
import { ProductTableOptionActionCallback } from './shared'
import styled from 'styled-components'

const SortComponent = props => {
  const { callback, tableOptions } = props

  return (
    <Container>
      <Label>Sort by:</Label>
      <Selector className="react-select--small">
        <Select
          options={sortOptions}
          value={tableOptions.sort}
          clearable={false}
          searchable={false}
          onChange={option => callback({ sort: option.value })}
        />
      </Selector>
    </Container>
  )
}

export const createSortAction = (callback = () => {}) => {
  return createAction((...args) => {
    ProductTableOptionActionCallback(...args)
    callback(args[0] ? args[0].sort : null)
  }, SortComponent)
}

export default createSortAction

// The z-index is for ShowOrder
const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  z-index: 10;
`

const Label = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
`

const Selector = styled.div`
  flex: 1;
  margin-left: 10px;
  width: 160px;
`

export const sortOptions = [
  { value: 'name', label: 'Product name' },
  { value: 'item_number', label: 'Item number' },
  { value: 'collection', label: 'Collection' },
  { value: 'subbrand', label: 'Subbrand' },
]
