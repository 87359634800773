import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { createAction } from '../shared'
import {
  IsBrandPredicate,
  IsNotB2COrderPredicate,
  CanDiscountProductsPredicate,
} from './shared'

const createEmptyVariantsToggle = () => {
  return createAction(
    ({ product }, { toggleEmptyVariants }) => {
      return toggleEmptyVariants(product)
    },
    ({ callback }) => {
      const [showing, setShowing] = useState(false)

      function toggle() {
        setShowing(!showing)
        callback()
      }

      return (
        <Button onClick={toggle}>
          {showing === true && <span className="glyphicon glyphicon-ok" />}
          {showing === false && <span className="glyphicon glyphicon-plus" />}
        </Button>
      )
    },
    [IsBrandPredicate],
    { size: 'xs' }
  )
}

export default createEmptyVariantsToggle

const Button = styled.button.attrs({
  type: 'button',
  className: 'btn btn-white btn-xs',
})`
  font-weight: bold;
`
