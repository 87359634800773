/* @flow */

import React, { Component, PureComponent, PropTypes } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import onClickOutside from 'react-onclickoutside'
import styled, { css } from 'styled-components'
import { Tooltip } from 'react-bootstrap'
import { Map } from 'immutable'
import { FormControl } from '../Forms'
import { hideColumn, showColumn, EDITABLE_TABLE_SHOW_COLUMN } from './actions'

export const DefaultLabel = ({
  children,
  ...props
}: {
  children?: React.Children,
}) => <div {...props}>{children}</div>

export const DefaultInput = ({
  setRef,
  type = 'text',
  ...props
}: {
  setRef: Function,
  type: string,
}) => <FormControl inputRef={setRef} type="text" {...props} />

export type ValueType = string | number | React.Element<*>

type EditableColumnProps = {
  active: boolean,
  children?: React.Children,
  cols: number,
  dispatch: Function,
  elementType?: string,
  error?: String,
  errorPlacement?: string,
  InputComponent: DefaultInput,
  LabelComponent: DefaultLabel,
  labelProps: Object,
  name: string,
  onBlur?: Function,
  onChange?: Function,
  rows: number,
  tableKey: string,
  x: number,
  y: number,
  value: ValueType,
  isValueChanged: boolean,
}

type EditableColumnDefaultProps = {
  errorPlacement: string,
  elementType: string,
  InputComponent:
    | React.Component<*, *, *>
    | ((...args: any) => React.Element<*>),
  LabelComponent:
    | React.Component<*, *, *>
    | ((...args: any) => React.Element<*>),
  onBlur: Function,
  onChange: Function,
  isValueChanged: boolean,
}

class Column extends PureComponent<
  EditableColumnDefaultProps,
  EditableColumnProps,
  void
> {
  static defaultProps = {
    errorPlacement: 'top',
    elementType: 'td',
    InputComponent: DefaultInput,
    LabelComponent: DefaultLabel,
    labelProps: {},
    onBlur: () => {},
    onChange: () => {},
    isValueChanged: false,
  }

  props: EditableColumnProps

  _input: any

  constructor(props) {
    super(props)

    ;(this: any).hideColumn = this.hideColumn.bind(this)
    ;(this: any).onKeyUp = this.onKeyUp.bind(this)
    ;(this: any).setRef = this.setRef.bind(this)
    ;(this: any).showColumn = this.showColumn.bind(this)
  }

  hideColumn() {
    const { active, dispatch, tableKey } = this.props

    if (active) {
      dispatch(hideColumn(tableKey))
    }
  }

  changeColumn(change) {
    const x = parseInt(this.props.x)
    const y = parseInt(this.props.y)

    let newX = x + change
    let newY = y

    const cols = parseInt(this.props.cols)
    const rows = parseInt(this.props.rows)

    // We are going to the right at the last column. Then we go to the first column of next row.
    if (newX > cols) {
      newX = 1
      newY++

      // We are at the last row. We therefore go to the first column of the first row
      if (newY > rows) {
        newY = 1
      }
      // If we are the first column trying to go left we go one up and then to the last column
    } else if (newX < 1) {
      newX = cols
      newY--

      // We are at the first column. We then go to the last column in the last row
      if (newY < 1) {
        newY = rows
      }
    }

    return showColumn(this.props.tableKey, newX, newY)
  }

  changeRow(change) {
    const x = parseInt(this.props.x)
    const y = parseInt(this.props.y)

    let newY = y + change

    const cols = parseInt(this.props.cols)
    const rows = parseInt(this.props.rows)

    if (newY > rows) {
      newY = 1
    } else if (newY < 1) {
      newY = rows
    }

    return showColumn(this.props.tableKey, x, newY)
  }

  onKeyUp(e) {
    const { dispatch, tableKey } = this.props

    const x = parseInt(this.props.x)
    const y = parseInt(this.props.y)

    let action
    switch (e.keyCode) {
      // tab
      case 9:
        action = this.changeColumn(1)
        break
      // enter
      case 13:
        action = this.changeRow(1)
        break
      // escape
      case 27:
        // We omit options as the save callback should not
        // be run when pressing escape
        action = hideColumn(tableKey)
        break
      // left
      case 37:
        action = this.changeColumn(-1)
        break
      // up
      case 38:
        action = this.changeRow(-1)
        break
      // right
      case 39:
        action = this.changeColumn(1)
        break
      // down
      case 40:
        action = this.changeRow(1)
        break
    }

    if (action) {
      if (
        action.type === EDITABLE_TABLE_SHOW_COLUMN &&
        parseInt(action.x) === x &&
        parseInt(action.y) === y
      ) {
        action = hideColumn(tableKey)
      }

      e.preventDefault()
      dispatch(action)
    }
  }

  setRef(ref) {
    this._input = ref
  }

  showColumn() {
    this.props.dispatch(
      showColumn(this.props.tableKey, this.props.x, this.props.y)
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.active === true && prevProps.active === false) {
      // When selecting a currency input the value will change from $2,000 to 2000.
      // This update happens async, so therefore we make sure to wait for it.
      setTimeout(() => {
        if (this._input && this._input.focus) {
          this._input.focus()
        }

        // Only valid for text inputs
        if (
          this._input instanceof HTMLElement &&
          this._input.tagName.toLowerCase() === 'input' &&
          this._input.type === 'text'
        ) {
          this._input.select()
          this._input.setSelectionRange(0, this._input.value.length)
        }
      }, 10)
    }
  }

  render() {
    const {
      active,
      children,
      dispatch,
      elementType,
      error,
      errorPlacement,
      InputComponent,
      LabelComponent,
      labelProps = {},
      onBlur,
      onChange,
      tableKey,
      x,
      y,
      value,
      isValueChanged,
      ...rest
    } = this.props

    let onClickHandler = !active ? this.showColumn : this.hideColumn
    // We do not want to hide the input when the user is trying to click the input field
    if (active) {
      onClickHandler = () => {}
    }

    const Element = ReactDOM[elementType]

    return React.createElement(
      elementType,
      {
        onClick: onClickHandler,
        ...rest,
      },
      <ColumnError error={error} placement={errorPlacement}>
        <EditableInput show={active}>
          <OnOutsideClickWrappedInput onHide={this.hideColumn}>
            <InputComponent
              setRef={this.setRef}
              onBlur={onBlur}
              onChange={onChange}
              onKeyDown={this.onKeyUp}
              value={value}
            />
          </OnOutsideClickWrappedInput>
        </EditableInput>
        <EditableLabel show={!active} isValueChanged={isValueChanged}>
          <LabelComponent {...labelProps}>{value}</LabelComponent>
        </EditableLabel>
      </ColumnError>
    )
  }
}

/**
 * We need to use onKeyDown for capturing tab presses since the browser will tab to
 * next input before the this.onKeyUp callback is called. This means the callback will
 * be called on the next input in the tab order. Therefore, we use onKeyDown. onKeyDown fires
 * before onBlur and this means the onBlur handler will be called *after* the input has already
 * changed. This causes the hideColumn() action to be called after the input has changed
 * and that causes the column to be hidden immediately after its shown. To prevent this
 * we do not implement and onBlur handler at all. We simply add an on-outsideclick handler
 * that will hide the input when clicked outside the input field.
 */
class InputWrapper extends Component {
  handleClickOutside() {
    this.props.onHide()
  }

  render() {
    return this.props.children
  }
}

const OnOutsideClickWrappedInput = onClickOutside(InputWrapper)

const ColumnError = ({
  children,
  error,
  placement = 'top',
}: {
  children?: React.Children,
  error?: String,
  placement?: string,
}) => {
  return (
    <ErrorWrapper error={error}>
      {error && (
        <ToolTipWrapper placement={placement} className="in">
          {error}
        </ToolTipWrapper>
      )}
      {children}
    </ErrorWrapper>
  )
}

const calculateToolTipPosition = (side, placement) => {
  let positions

  switch (placement) {
    case 'bottom':
      positions = {
        bottom: 'auto',
        left: 'auto',
        right: '0',
        top: '40px',
      }
      break
    case 'top':
      positions = {
        bottom: 'auto',
        left: 'auto',
        right: '0',
        top: '-40px',
      }
      break
  }

  return positions[side]
}

const ToolTipWrapper = styled(Tooltip)`
  bottom: ${props => calculateToolTipPosition('bottom', props.placement)}
  left: ${props => calculateToolTipPosition('left', props.placement)}
  right: ${props => calculateToolTipPosition('right', props.placement)}
  top: ${props => calculateToolTipPosition('top', props.placement)}
`

export const EditableColumn = connect((state, props) => {
  const table = state.editableTable.tables.get(props.tableKey, Map())

  return {
    active: table.get('x') == props.x && table.get('y') == props.y,
  }
})(Column)

export const EditableTable = styled.table`
  table-layout: fixed;

  thead > tr > th,
  thead > th {
    padding: 5px 7px;
  }

  td {
    padding: 0 !important;
  }
`

const ErrorWrapper = styled.div`
  border: ${props => (props.error ? '1px dashed red' : 'none')};
  height: 100%;
  position: relative;
  width: 100%;

  @media print {
    height: auto;
    width: auto;
  }
`

const EditableInput = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};

  input {
    height: 41px;
    width: 100%;

    &[type='text']:focus {
      border-color: #66afe9;
      background: transparent;
    }

    &:focus {
      border-radius: 0;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
`

const EditableLabel = styled.div`
  border: 1px solid white;
  display: ${props => (props.show ? 'block' : 'none')};
  height: 41px;
  line-height: 41px;
  overflow: hidden;
  padding: 0 7px;
  width: 100%;

  ${props =>
    props.isValueChanged &&
    css`
      background: #fffcec;
      border-color: #fdf8da;
    `}

  &:hover {
    background: #f7f7f7;
    border: 1px solid #e1d900;
    cursor: text;
  }

  @media print {
    line-height: initial;
    height: auto;
    border: none;
  }
`

// This method creates an redux-form input renderer by passing an input component
export const createEditableInputRender = (
  Component: React.Component<*, *, *>
) => {
  return props => {
    const { input, meta, type, ...rest } = props

    let error
    if (meta.touched && meta.error) {
      error = meta.error
    }

    return <Component error={error} {...input} {...rest} />
  }
}

// This creates a complete redux-form input by passing a renderer created by createEditableInputRender
export const createEditableInput = (renderer: React.Component<*, *, *>) => {
  return (props: Object) => <Field component={renderer} {...props} />
}

const normalRenderer = createEditableInputRender(EditableColumn)
export const EditableColumnInput = createEditableInput(normalRenderer)

export default EditableColumnInput
