/* @flow */

import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { MenuItem } from 'react-bootstrap'

import {
  CheckmarkDropdown,
  CheckmarkDropdownButton,
  CheckmarkDropdownGroup,
} from '../../infrastructure/components/Dropdown'

const perPageOptions = [10, 20, 30, 50, 100]

type Props = {
  id: string,
  onChange: (perPage: number) => void,
  selected: number,
  size: 'normal' | 'small',
  title: string,
}

class PerPageSelector extends PureComponent<Props, void> {
  static defaultProps = {
    size: 'normal',
  }

  render() {
    const { id, selected, size, title } = this.props

    return (
      <CheckmarkDropdown
        buttonBsSize={size !== 'normal' ? size : undefined}
        multi={false}
        onChange={this.props.onChange}
        id={id}
        selected={selected}
        title={title}
      >
        {perPageOptions.map(option => (
          <CheckmarkDropdownButton
            key={`${id}_${option}`}
            label={option}
            value={option}
          >
            {option}
          </CheckmarkDropdownButton>
        ))}
      </CheckmarkDropdown>
    )
  }
}

export default PerPageSelector
