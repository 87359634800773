import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { resolver } from 'cloudinary-url-resolver'
import cloneDeep from 'lodash/cloneDeep'

import { useConnectedBrand } from '../../app/hooks'
import {
  productPresets as productPresetsSettings,
  otherPresets as otherPresetsSettings,
} from '../providers/cloudinary'

const cloudName = 'traede'

const fallbackSettings = {
  user_table: require('./../../assets/images/user_table.png'),
  user_top_bar: require('./../../assets/images/user_top_bar.png'),
  ordersheet: require('./../../assets/images/ordersheet.png'),
  product_carousel: require('./../../assets/images/product_carousel_thumb.png'),
  product_carousel_thumb: require('./../../assets/images/product_carousel_thumb.png'),
  product_search: require('./../../assets/images/product_search.png'),
  product_table: require('./../../assets/images/product_table.png'),
  logo_black: require('./../../assets/images/logo_black.png'),
  logo_black_v2: require('./../../assets/images/logo_black_v2.svg'),
  linesheet_list: require('./../../assets/images/linesheet_list.jpg'),
  webshop_product_carousel_mobile: require('./../../assets/images/product_carousel_large.png'),
}

resolver.settings.cloud_name = cloudName

const CloudinaryResource = ({
  brandId,
  children,
  fallback,
  id,
  isFile,
  presets,
  transformations,
  transformationOverrides,
  // Export settings and dispatch to avoid unknown prop warning
  settings,
  dispatch,
  format = 'jpg',
  brandSettings: brandSettingsProp = null,

  xScale,
  yScale,
  nonZoomHeight,
  nonZoomWidth,
  zoomOnHover,

  style = {},
  ...rest
}) => {
  const [connectedBrand] = useConnectedBrand(brandId)

  const brandSettings = connectedBrand
    ? connectedBrand.settings
    : brandSettingsProp

  const { url, transformations: resolvedTransformations } =
    resolveCloudinaryResource({
      id,
      presets,
      transformations,
      transformationOverrides,
      format,
      fallback,
      isFile,
      brandSettings,
    })

  if (isFile) {
    return (
      <a style={style} {...rest} href={url}>
        {children}
      </a>
    )
  }

  const baseStyle = {}

  // When device-to-pixel ratio is higher than 1, the returned image will be bigger
  // in pixels by that ratio, so we need to set constraints for displaying it by max
  // height and width. We don't want to set constraints always as some parts of codebase
  // rely on img to have max-width: 100%.
  if (resolvedTransformations.dpr > 1) {
    if (resolvedTransformations.width) {
      baseStyle.maxWidth = parseInt(resolvedTransformations.width, 10)
    }
    if (resolvedTransformations.height) {
      baseStyle.maxHeight = parseInt(resolvedTransformations.height, 10)
    }
  }

  return <img style={{ ...baseStyle, ...style }} {...rest} src={url} />
}

CloudinaryResource.propTypes = {
  fallback: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
  ]),
  fallbackSettings: PropTypes.object,
  id: PropTypes.string,
  isFile: PropTypes.bool,
  presets: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  productPresetsSettings: PropTypes.object,
  otherPresetsSettings: PropTypes.object,
  transformations: PropTypes.object,
}

CloudinaryResource.defaultProps = {
  fallback: false,
  id: null,
  isFile: false,
  presets: [],
  settings: {},
  transformations: {},
}

export default CloudinaryResource

const resolveFallback = (fallbackSettings, key) => {
  var saved = fallbackSettings[key]
  if (saved === undefined) {
    return key
  } else {
    return saved
  }
}

const addPresetsToTransformations = (
  presetSettings,
  baseTransformations,
  propPresets,
  transformationOverrides
) => {
  const transformations = Object.assign({}, baseTransformations)

  if (typeof propPresets === 'string') {
    propPresets = [propPresets]
  }

  let preset
  for (var i in propPresets) {
    preset = presetSettings[propPresets[i]]
    if (preset === undefined) {
      console.error('Could not find preset!')
      console.error('Preset:', propPresets[i])
      continue
      throw new Error(propPresets[i] + ' is not a defined preset')
    }

    for (var type in preset) {
      transformations[type] = preset[type]
    }
  }

  // because `transformations` is overridden by `presets`, we need a way to override `presets`
  if (transformationOverrides) {
    for (var i in transformationOverrides) {
      transformations[i] = transformationOverrides[i]
    }
  }

  return transformations
}

export const addOverridesToProductPresetsSettings = (
  productPresetsSettings,
  settingsOverride
) => {
  const result = cloneDeep(productPresetsSettings)

  const { _all_product_presets: allProductPresetsOverride, ...restOverride } =
    settingsOverride

  if (allProductPresetsOverride) {
    for (const preset in result) {
      Object.assign(result[preset], settingsOverride._all_product_presets)
    }
  }

  for (const preset in restOverride) {
    if (result[preset]) {
      Object.assign(result[preset], restOverride[preset])
    }
  }

  return result
}

export const addOverridesToOtherPresetsSettings = (
  otherPresetsSettings,
  settingsOverride
) => {
  const result = cloneDeep(otherPresetsSettings)

  for (const preset in settingsOverride) {
    if (result[preset]) {
      Object.assign(result[preset], settingsOverride[preset])
    }
  }

  return result
}

export const resolveCloudinaryResource = ({
  id = null,
  presets = [],
  transformations = {},
  transformationOverrides,
  format = 'jpg',
  fallback = false,
  isFile = false,
  brandSettings = null,
}) => {
  let presetsSettings = { ...productPresetsSettings, ...otherPresetsSettings }
  if (brandSettings && brandSettings.image_settings_override) {
    presetsSettings = {
      ...addOverridesToProductPresetsSettings(
        productPresetsSettings,
        brandSettings.image_settings_override
      ),
      ...addOverridesToOtherPresetsSettings(
        otherPresetsSettings,
        brandSettings.image_settings_override
      ),
    }
  }

  // Format is not a Cloudinary transformation but we want to be able to
  // override it using image_settings_override
  const { format: overriddenFormat, ...transformationsWithPresets } =
    addPresetsToTransformations(
      presetsSettings,
      transformations,
      presets,
      transformationOverrides
    )

  const useFormat = overriddenFormat || format

  let useId = id
  let isVideo = false
  let useIsFile = false
  if (useId && useId.startsWith('video/')) {
    useId = useId.replace('video/', '')
    isVideo = true
  } else if (useId && useId.startsWith('raw/')) {
    useId = useId.replace('raw/', '')
    useIsFile = true
  }

  let url
  if (id === null && fallback !== false) {
    url = resolveFallback(fallbackSettings, fallback)
  } else {
    url = resolver(useId, transformationsWithPresets, useIsFile)
  }

  if (isVideo) {
    url = url.replace('/image/', '/video/')
  }

  if (!useIsFile && !url.match(/\.[a-z]+$/)) {
    url += `.${useFormat}`
  }

  return { url, transformations: transformationsWithPresets }
}
