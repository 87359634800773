import keyBy from 'lodash/keyBy'

import {
  CREATE_ENTITY_USER_SUCCESS,
  FETCH_ENTITY_USERS_REQUEST,
  FETCH_ENTITY_USERS_SUCCESS,
  FETCH_ENTITY_USERS_FAILURE,
} from '../actions'

const initialState = {
  isFetching: false,
  items: [],
}

export default function entityUsers(state = initialState, action) {
  switch (action.type) {
    case FETCH_ENTITY_USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }

    case FETCH_ENTITY_USERS_SUCCESS:
      return {
        isFetching: false,
        items: keyBy(action.payload.entity_users, 'id'),
      }

    case FETCH_ENTITY_USERS_FAILURE:
      return {
        ...state,
        isFetching: false,
      }

    case CREATE_ENTITY_USER_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }

    default:
      return state
  }
}
