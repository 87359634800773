/* @flow */

import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { Content, ContentHeader, WhiteBox } from '../template/Content'
import { Link } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import moment from 'moment-timezone'

import IframelyContent from '../../infrastructure/components/IframelyContent'

import { renderDate, SessionContext } from '../shared'

import { markAsRead } from './api'
import { useAllNewsNotifications } from './hooks'

const breadcrumbs = [
  {
    label: 'News archive',
  },
]

const newsFetchOptions = {
  includes: ['news'],
}

const NewsArchive = ({ history, match }) => {
  const {
    params: { newsId },
  } = match
  const [sortedNews, setSortedNews] = React.useState([])
  const [news, isFetching, { refresh }] = useAllNewsNotifications(
    newsFetchOptions
  )

  React.useEffect(() => {
    const filteredNews = news.filter(n =>
      moment(n.news.added_at).isSameOrBefore(moment())
    )

    setSortedNews(
      sortBy(
        filteredNews,
        notification => -moment(notification.news.added_at).unix()
      )
    )
  }, [news, setSortedNews])

  React.useEffect(() => {
    if (!newsId) {
      const firstNews = sortedNews[0]

      if (firstNews) {
        history.push(`/news/${firstNews.id}`)
      }
    }
  }, [history, sortedNews, newsId])

  const selected = React.useMemo(() => {
    if (!newsId) {
      return null
    }

    return sortedNews.find(n => n.id == newsId)
  }, [sortedNews, newsId])

  React.useEffect(() => {
    if (selected && selected.read_at == null) {
      markAsRead(selected.id)
    }
  }, [selected, refresh])

  return (
    <div>
      <ContentHeader breadcrumbs={breadcrumbs} />
      <Content>
        <SplitContainer>
          <Navigation>
            {sortedNews.map((notification, i) => {
              const active = selected === notification
              let status = 'read'
              if (active) {
                status = 'active'
              } else if (notification.read_at === null) {
                status = 'unread'
              }

              return (
                <NavigationElement
                  onClick={() => history.push(`/news/${notification.id}`)}
                  active={active}
                  status={status}
                >
                  <NavigationElementTitle>
                    {notification.news.title}{' '}
                    {notification.read_at === null && (
                      <span className="label label-warning label-sm">
                        Unread
                      </span>
                    )}
                  </NavigationElementTitle>
                  <NavigationElementAdditionDate>
                    {`Added: ${renderDate(notification.news.added_at)}`}
                  </NavigationElementAdditionDate>
                </NavigationElement>
              )
            })}
          </Navigation>
          {selected && (
            <UpdatesContainer>
              <NewsTitle>{selected.news.title}</NewsTitle>

              <NewsContent>
                <IframelyContent content={selected.news.description} />
              </NewsContent>
            </UpdatesContainer>
          )}
        </SplitContainer>
      </Content>
    </div>
  )
}

export default NewsArchive

const SplitContainer = styled.div`
  display: flex;
`

const Navigation = styled.ul`
  padding: 10px 0;
  max-width: 300px;
  min-width: 300px;
`

const statusToStyle = status => {
  switch (status) {
    default:
      return css`
        &:hover {
          background: #efefef;
        }
      `
    case 'active':
      return css`
        background: #a8aeb3;
        color: white;

        &:hover {
          background: #a8aeb3;
        }
      `
  }
}

const NavigationElement = styled.li`
  cursor: pointer;
  list-style-type: none;
  padding: 12px 20px;

  ${({ status }) => statusToStyle(status)};
`

const NavigationElementTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
`

const NavigationElementAdditionDate = styled.div`
  color: #ccc;
  font-size: 14px;
`

const UpdatesContainer = styled.div`
  background: white;
  flex: 1;
  padding: 20px;
`

const NewsTitle = styled.div`
  color: black;
  font-size: 22px;
  margin-bottom: 10px;
`

export const NewsContent = styled.div.attrs({ className: 'editor-body' })`
  h2 {
    color:black;
    text-decoration:underline;
  }
`
