/* @flow */

import * as React from 'react'
import { Suspense } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import type {
  Location as LocationType,
  Match as MatchType,
  RouterHistory,
} from 'react-router-dom'
import styled, { css } from 'styled-components'
import querystring from 'qs'
import { FiShoppingCart, FiHeart } from 'react-icons/fi'

import Navigation from '../template/components/Navigation'
import CookiesBanner from '../../infrastructure/components/CookiesBanner/CookiesBanner'

const WebshopNavigation = React.lazy(() =>
  import('./components/Navigation/WebshopNavigation')
)
const WebshopMobileNavigation = React.lazy(() =>
  import('./components/Navigation/WebshopMobileNavigation')
)

import {
  enterWebshop,
  leaveWebshop,
  changeWebshopCurrency,
  fetchWebshopTotals,
  fetchWebshopFavorites,
} from './actions/index'
import { getWebshopNavigation, trackOpened } from './actions/shop'
import { getWebshopPagesForSession } from './api'
import { changeBrandContext } from '../brands/actions/index'
import { getDefaultCurrency } from '../brands/helpers'
import { getPrimaryAddress } from '../entities/components/AddressSelector'
import CloudinaryResource from '../../infrastructure/components/CloudinaryResource'
import Address from '../entities/components/Address'
import FormatCurrency from '../../infrastructure/components/FormatCurrency'
import AppContext from '../app-store/components/AppContext'

const CreditCard = React.lazy(() => import('./components/CreditCard'))
import { WebshopContext } from './shared'
import { shouldShowSplashForMe } from './WebshopSplash'

import type {
  AvailableBrand,
  Id,
  ConnectedBrand,
  Entity,
  NavigationLink,
  WebshopPage,
  WebshopSession,
  User,
} from '../types'

const getPreferredCurrency = (entity: Entity, brand: ConnectedBrand) =>
  entity.entity_type === 'shop'
    ? brand.currencies[0]
    : getDefaultCurrency(brand)

const WEBSHOP_CUSTOM_CSS_DIV_ID = 'webshop-custom-css'

type Props = {
  availableBrand: AvailableBrand | null,
  brand: ConnectedBrand,
  brandContext: Id,
  children?: React.Node,
  currency: string,
  dispatch: Function,
  entity: Entity,
  history: RouterHistory,
  location: LocationType,
  match: MatchType,
  onUpdateCustomerConnection: Function,
  onUpdateSession: Function,
  totals: Object,
  webshopSettings: Object,
  webshopSession: WebshopSession,
  user: User,
}

type State = {
  availableBrands: Array<AvailableBrand>,
  navigationLinks: Array<NavigationLink>,
  pages: Array<WebshopPage>,
  searchQuery: string,
  showMobileNavigation: boolean,
}

class WebshopTemplate extends React.Component<Props, State> {
  state = {
    availableBrands: [],
    navigationLinks: [],
    pages: [],
    searchQuery: '',
    showMobileNavigation: false,
  }

  static contextType = WebshopContext

  componentDidMount() {
    const { location } = this.props

    const query = querystring.parse(location.search.slice(1))

    this.setState({
      searchQuery: query.q || '',
    })

    this._initialize(this.props)
  }

  componentWillReceiveProps(nextProps: Props) {
    // TODO: TEST
    if (nextProps.currency !== this.props.currency) {
      this._updateTotals(nextProps.currency)
    }

    // TODO: TEST
    if (nextProps.location.search !== this.props.location.search) {
      const query = querystring.parse(location.search.slice(1))
      this.setState({ searchQuery: query.q || '' })
    }

    // TODO: TEST
    if (nextProps.brand !== this.props.brand) {
      this._initialize(nextProps)
    }

    if (
      nextProps.webshopSession.drop_id !== this.props.webshopSession.drop_id ||
      nextProps.webshopSession.customer_id !==
        this.props.webshopSession.customer_id
    ) {
      this._getNavigation()
    }

    const fullUrl = `${this.props.location.pathname}${this.props.location.search}`
    const fullNewUrl = `${nextProps.location.pathname}${nextProps.location.search}`

    if (fullNewUrl !== fullUrl) {
      this.setState({
        showMobileNavigation: false,
      })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(leaveWebshop(this.props.match.params.brandId))
    this._removeCustomCSS()
  }

  render() {
    const {
      availableBrand,
      brand,
      brandContext,
      currency,
      entity,
      location,
      match,
      totals,
      webshopSession,
      webshopSettings,
      updateWebshopSession,
      user,
    } = this.props
    const { isMobile } = this.context

    let shopUrl = '/shop/'
    if (match.params.brandId) {
      shopUrl += match.params.brandId
    }

    const { pages, searchQuery, showMobileNavigation } = this.state

    if (!brand || !brandContext) {
      return <div>Loading...</div>
    }

    const primaryAddress = getPrimaryAddress(brand.addresses)

    const webshopSessionBody = []
    if (!webshopSession.dropshipping && webshopSession.drop) {
      webshopSessionBody.push(<div>Drop: {webshopSession.drop.name}</div>)
    }
    if (webshopSession.dropshipping) {
      webshopSessionBody.push(
        <div className="label label-xxs label-success">Dropshipping</div>
      )
    }
    if (webshopSession.customer) {
      webshopSessionBody.push(
        <div>
          {entity.entity_type === 'shop' ? 'Shopping as' : 'Customer'}:{' '}
          {webshopSession.customer.name}
        </div>
      )
    }
    if (webshopSessionBody.length < 2) {
      for (var i = 0; i < 2 - webshopSessionBody.length; i++) {
        webshopSessionBody.push(<div />)
      }
    }

    const shouldShowSplash = shouldShowSplashForMe(
      webshopSettings,
      entity,
      user
    )

    // TODO: How to do smarter?
    const isLanding = location.pathname === '/shop/10919'
    const landingClass = isLanding ? 'webshop-current-page-landing' : ''

    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Navigation fullWidth={webshopSettings.full_width} inWebshop />
          <Container
            className={`webshop-main-container ${landingClass}`}
            fullWidth={webshopSettings.full_width}
          >
            <TopContainer>
              <HeaderContainer isMobile={isMobile}>
                <HeaderLogo isMobile={isMobile}>
                  <Link to={shopUrl}>
                    <CloudinaryResource
                      brandId={brand.id}
                      id={brand.logo}
                      presets="logo_webshop"
                      fallback="logo_black"
                    />
                  </Link>
                </HeaderLogo>

                {isMobile && (
                  <MobileHeaderBoxes>
                    <MobileFavoritesButton to={`${shopUrl}/favorites`}>
                      <FiHeart />
                    </MobileFavoritesButton>

                    <MobileCartButton to={`${shopUrl}/cart`}>
                      <FiShoppingCart />
                    </MobileCartButton>
                  </MobileHeaderBoxes>
                )}

                {!isMobile && (
                  <HeaderBoxes>
                    <WebhopSessionBox>
                      <HeaderBoxContent>
                        {(shouldShowSplash === true ||
                          (availableBrand &&
                            availableBrand.retailer_connections.length >
                              1)) && (
                          <>
                            <HeaderBoxTitle>
                              <HeaderBoxIcon>
                                <span className="lnr lnr-store" />
                              </HeaderBoxIcon>
                              <span>Current Order</span>
                            </HeaderBoxTitle>
                            <HeaderBoxBody>{webshopSessionBody}</HeaderBoxBody>
                            <HeaderBoxAction>
                              <button
                                type="button"
                                className="btn btn-mini"
                                onClick={
                                  shouldShowSplash
                                    ? this.props.onUpdateSession
                                    : this.props.onUpdateCustomerConnection
                                }
                              >
                                Change
                              </button>
                            </HeaderBoxAction>
                          </>
                        )}
                      </HeaderBoxContent>
                    </WebhopSessionBox>
                    {shouldShowSplash === false &&
                      webshopSession.customer &&
                      webshopSession.customer.dropshipping === true && (
                        <WebhopSessionBox>
                          <HeaderBoxContent style={{ paddingTop: 20 }}>
                            <HeaderBoxTitle>
                              <HeaderBoxIcon>
                                <span className="lnr lnr-store" />
                              </HeaderBoxIcon>
                              <span>Dropshipping</span>
                            </HeaderBoxTitle>

                            <div className="checkbox check-success">
                              <input
                                type="checkbox"
                                id="webshop-dropshipping-checkbox"
                                checked={webshopSession.dropshipping}
                                onChange={() =>
                                  updateWebshopSession({
                                    dropshipping: !webshopSession.dropshipping,
                                  })
                                }
                              />
                              <label htmlFor="webshop-dropshipping-checkbox">
                                Dropshipping
                              </label>
                            </div>
                          </HeaderBoxContent>
                        </WebhopSessionBox>
                      )}
                    <FavoritesBox>
                      <HeaderBoxContent>
                        <HeaderBoxTitle>
                          <HeaderBoxIcon>
                            <span className="lnr lnr-heart" />
                          </HeaderBoxIcon>
                          <span>Favorites</span>
                        </HeaderBoxTitle>

                        <HeaderBoxBody>
                          <div>{this.props.favorites.total} products</div>
                          <div>selected</div>
                        </HeaderBoxBody>
                        <HeaderBoxAction>
                          <Link
                            to={`${shopUrl}/favorites`}
                            className="btn btn-mini webshop-go-to-favorites-btn"
                          >
                            Go to Favorites
                          </Link>
                        </HeaderBoxAction>
                      </HeaderBoxContent>
                    </FavoritesBox>
                    <CartBox>
                      <HeaderBoxContent>
                        <HeaderBoxTitle>
                          <HeaderBoxIcon>
                            <span className="lnr lnr-cart" />
                          </HeaderBoxIcon>
                          <span>Cart</span>
                        </HeaderBoxTitle>

                        <HeaderBoxBody>
                          <div>{this.props.totals.quantity} pieces</div>
                          <div>
                            <FormatCurrency currency={this.props.currency}>
                              {this.props.totals.price}
                            </FormatCurrency>
                          </div>
                        </HeaderBoxBody>
                        <HeaderBoxAction>
                          <Link
                            to={`${shopUrl}/cart`}
                            className="btn btn-mini webshop-go-to-cart-btn"
                          >
                            Go to Cart
                          </Link>
                        </HeaderBoxAction>
                      </HeaderBoxContent>
                    </CartBox>
                  </HeaderBoxes>
                )}
              </HeaderContainer>

              {!isMobile && (
                <WebshopNavigation
                  handleSearchSubmit={this._handleSearchSubmit}
                  navigationLinks={this.state.navigationLinks}
                  searchQuery={searchQuery}
                  shopUrl={shopUrl}
                  brand={brand}
                />
              )}

              {isMobile && (
                <WebshopMobileNavigation
                  brand={brand}
                  handleSearchSubmit={this._handleSearchSubmit}
                  navigationLinks={this.state.navigationLinks}
                  onHide={this._hideMobileNavigation}
                  onShow={this._showMobileNavigation}
                  searchQuery={searchQuery}
                  shopUrl={shopUrl}
                  show={showMobileNavigation}
                />
              )}
            </TopContainer>
            <ContentContainer>{this.props.children}</ContentContainer>
            <FooterContainer>
              <div>
                <Address
                  center={true}
                  address={primaryAddress}
                  entity={brand}
                />
              </div>
              {pages.length ? (
                <div className="text-center">
                  {pages.map((page, index) => {
                    return (
                      <Link key={index} to={`${shopUrl}/page/${page.id}`}>
                        {page.heading}&nbsp;&nbsp;&nbsp;
                      </Link>
                    )
                  })}
                </div>
              ) : null}

              <AppContext
                app="Epay"
                overrideEntity={brand}
                render={({ app }) => {
                  if (app.settings.credit_card_icons.length === 0) {
                    return null
                  }

                  return (
                    <CreditCardContainer>
                      <CreditCard cards={app.settings.credit_card_icons} />
                    </CreditCardContainer>
                  )
                }}
              />
            </FooterContainer>
          </Container>

          <CookiesBanner brand={brand} />
        </Suspense>
      </div>
    )
  }

  _applyCustomCSS = (props: Props) => {
    const { webshopSettings } = props

    if (webshopSettings.custom_css && webshopSettings.custom_css.length) {
      document.querySelectorAll(`#${WEBSHOP_CUSTOM_CSS_DIV_ID}`)[0].innerHTML =
        webshopSettings.custom_css
    }
  }

  _getNavigation = () => {
    const {
      dispatch,
      match: {
        params: { brandId },
      },
      webshopSession,
    } = this.props

    dispatch(getWebshopNavigation(brandId, webshopSession.id)).then(
      response => {
        this.setState({
          navigationLinks: response.payload,
        })
      }
    )
  }

  _initialize = (props: Props) => {
    const {
      brand,
      currency,
      dispatch,
      entity,
      location,
      match: {
        params: { brandId },
      },
      webshopSession,
    } = props

    dispatch(enterWebshop(brandId))
    // Right now webshopSession is keyed by entity ID so that if a retailer switches entities
    // all this initialization will run again. If we remove this to WebshopSession or further back
    // we need to rethink how a retailer with 2 entities can get new brand context when switching
    dispatch(changeBrandContext(parseInt(brandId)))
    dispatch(fetchWebshopFavorites(brandId))

    this._getNavigation()

    if (currency) {
      this._updateTotals(currency)
    }

    // When retailer opens webshop, track it for timeline. We have to have
    // a special endpoint for this, because none of the other endpoints used
    // when initializing webshop is used solely here.
    if (entity.entity_type === 'shop') {
      dispatch(trackOpened(brandId))
    }

    if (brand) {
      const preferredCurrency = getPreferredCurrency(entity, brand)
      // Currency might already be set by Session
      if (!currency) {
        this._setCurrency(preferredCurrency)
        this._updateTotals(preferredCurrency)
      }
    }

    getWebshopPagesForSession(brandId).then(response => {
      this.setState({ pages: response.payload })
    })

    this._removeCustomCSS()
    this._applyCustomCSS(props)
  }

  _removeCustomCSS = () => {
    const customCssDiv = document.querySelectorAll(
      `#${WEBSHOP_CUSTOM_CSS_DIV_ID}`
    )[0]
    if (customCssDiv) {
      customCssDiv.innerHTML = ''
    }
  }

  _handleSearchSubmit = searchQuery => {
    const {
      history,
      match: {
        params: { brandId },
      },
    } = this.props

    if (brandId) {
      const newUrl = `/shop/${brandId}/search?q=${searchQuery}`
      history.push(newUrl)
    }
  }

  _hideMobileNavigation = () => {
    this.setState({
      showMobileNavigation: false,
    })
  }

  _setCurrency = newCurrency => {
    this.props.dispatch(changeWebshopCurrency(newCurrency))
  }

  _showMobileNavigation = () => {
    this.setState({
      showMobileNavigation: true,
    })
  }

  _updateTotals = currency => {
    if (currency) {
      this.props.dispatch(
        fetchWebshopTotals(
          parseInt(this.props.match.params.brandId),
          currency,
          this.props.webshopSession.id
        )
      )
    }
  }
}

export default connect(state => {
  return {
    brandContext: state.brandContext,
    currency: state.webshop.currency,
    entity: state.session.entity,
    favorites: state.webshop.favorites,
    totals: state.webshop.totals,
    user: state.session.user,
  }
})(WebshopTemplate)

const fixedWidthContainer = css`
  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }

  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
`

const fullWidthContainer = css``

const Container = styled.div`
  ${props =>
    props.fullWidth === true ? fullWidthContainer : fixedWidthContainer};
`

const TopContainer = styled.div.attrs({ className: 'webshop-top-container' })``

const ContentContainer = styled.div.attrs({ className: 'webshop-content' })`
  min-height: 500px;
  padding-top: 25px;
  position: relative;

  img {
    max-width: 100%;
  }
`

const FooterContainer = styled.div.attrs({ className: 'webshop-footer' })`
  padding: 25px 0;
`

// Need class for custom CSS hook
const HeaderContainer = styled.div.attrs({ className: 'webshop-header' })`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-top: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
`

const MobileHeaderBoxes = styled.div`
  display: flex;

  > * {
    margin-left: 20px;
  }
`

const MobileFavoritesButton = styled(Link)`
  color: black;
  font-size: 24px;
`

const MobileCartButton = styled(Link)`
  color: black;
  font-size: 24px;
`

const HeaderBoxes = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  max-width: 40vw;

  > * {
    flex: 1 0 0;
    text-align: right;
  }

  @media (max-width: 1024px) {
    max-width: 50vw;
  }
`

const HeaderLogo = styled.div`
  flex: 1;
  max-width: ${({ isMobile }) => (isMobile ? '200px' : '280px')};

  img {
    max-width: 100%;
  }
`

const HeaderBox = styled.div`
  display: flex;
  height: 110px;
  overflow-x: hidden;
`

const HeaderBoxIcon = styled.div`
  display: inline-block;
  font-size: 20px;
  margin-right: 10px;
`

const HeaderBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`

const HeaderBoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 11px;
`

const HeaderBoxBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media (max-width: 1024px) {
    font-size: 85%;
  }
`

const HeaderBoxAction = styled.div`
  margin-top: 10px;
`

const WebhopSessionBox = styled(HeaderBox)``

const CartBox = styled(HeaderBox)`
  ${HeaderBoxBody} {
    white-space: nowrap;
  }
`

const FavoritesBox = styled(HeaderBox)``

const CreditCardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`
