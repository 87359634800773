/* @flow */

import { createApiHook, quickCreateHook } from '../../../app/hooks'
import type {
  EmailCreateData,
  EmailTemplateGroup,
  Id,
} from '../../../app/types'

import {
  generateEmailSenderSettings,
  getEmailsCreateData,
  getEmailTemplateGroups,
  getEmailTriggers,
} from './api'

const {
  hook: useEmailSenderSettings,
  hookCached: useCachedEmailSenderSettings,
  clearCache: clearEmailSenderSettingsCache,
} = quickCreateHook(generateEmailSenderSettings, 'email_sender_settings', null)

export {
  useEmailSenderSettings,
  useCachedEmailSenderSettings,
  clearEmailSenderSettingsCache,
}

const { hook: useEmailsCreateData } = quickCreateHook(getEmailsCreateData, null)

export { useEmailsCreateData }

const {
  hook: useEmailTemplateGroups,
  hookCached: useCachedEmailTemplateGroups,
  clearCache: clearEmailTemplateGroups,
} = quickCreateHook(getEmailTemplateGroups, null, [])

export {
  useEmailTemplateGroups,
  useCachedEmailTemplateGroups,
  clearEmailTemplateGroups,
}

const {
  hook: useEmailTriggers,
  hookCached: useCachedEmailTriggers,
  clearCache: clearEmailEmailTriggers,
} = quickCreateHook(getEmailTriggers, null, [])

export { useEmailTriggers, useCachedEmailTriggers, clearEmailEmailTriggers }
