import { CALL_API } from '../../../infrastructure/middleware/api'
import {
  refreshSession,
  setAccessToken,
  setAdminEmail,
} from '../../../infrastructure/actions/session'
import { resetState } from '../../rootReducer'

import api from '../../api'

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE'
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const REGAIN_ADMIN_REQUEST = 'REGAIN_ADMIN_REQUEST'
export const REGAIN_ADMIN_SUCCESS = 'REGAIN_ADMIN_SUCCESS'
export const REGAIN_ADMIN_FAILURE = 'REGAIN_ADMIN_FAILURE'
export const SEARCH_USERS_REQUEST = 'SEARCH_USERS_REQUEST'
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS'
export const SEARCH_USERS_FAILURE = 'SEARCH_USERS_FAILURE'
export const SEARCH_BRANDS_REQUEST = 'SEARCH_BRANDS_REQUEST'
export const SEARCH_BRANDS_SUCCESS = 'SEARCH_BRANDS_SUCCESS'
export const SEARCH_BRANDS_FAILURE = 'SEARCH_BRANDS_FAILURE'

export const GET_PAYING_CUSTOMERS_REQUEST = 'GET_PAYING_CUSTOMERS_REQUEST'
export const GET_PAYING_CUSTOMERS_SUCCESS = 'GET_PAYING_CUSTOMERS_SUCCESS'
export const GET_PAYING_CUSTOMERS_FAILURE = 'GET_PAYING_CUSTOMERS_FAILURE'
export const MERGE_ENTITIES_REQUEST = 'MERGE_ENTITIES_REQUEST'
export const MERGE_ENTITIES_SUCCESS = 'MERGE_ENTITIES_SUCCESS'
export const MERGE_ENTITIES_FAILURE = 'MERGE_ENTITIES_FAILURE'
export const MERGE_USERS_REQUEST = 'MERGE_USERS_REQUEST'
export const MERGE_USERS_SUCCESS = 'MERGE_USERS_SUCCESS'
export const MERGE_USERS_FAILURE = 'MERGE_USERS_FAILURE'
export const SEARCH_ENTITIES_REQUEST = 'SEARCH_ENTITIES_REQUEST'
export const SEARCH_ENTITIES_SUCCESS = 'SEARCH_ENTITIES_SUCCESS'
export const SEARCH_ENTITIES_FAILURE = 'SEARCH_ENTITIES_FAILURE'

export const CREATE_PAYING_CUSTOMERS_REQUEST = 'CREATE_PAYING_CUSTOMERS_REQUEST'
export const CREATE_PAYING_CUSTOMERS_SUCCESS = 'CREATE_PAYING_CUSTOMERS_SUCCESS'
export const CREATE_PAYING_CUSTOMERS_FAILURE = 'CREATE_PAYING_CUSTOMERS_FAILURE'

export const UPDATE_PAYING_CUSTOMERS_REQUEST = 'UPDATE_PAYING_CUSTOMERS_REQUEST'
export const UPDATE_PAYING_CUSTOMERS_SUCCESS = 'UPDATE_PAYING_CUSTOMERS_SUCCESS'
export const UPDATE_PAYING_CUSTOMERS_FAILURE = 'UPDATE_PAYING_CUSTOMERS_FAILURE'

export const SPLIT_CUSTOMERS_REQUEST = 'SPLIT_CUSTOMERS_REQUEST'
export const SPLIT_CUSTOMERS_SUCCESS = 'SPLIT_CUSTOMERS_SUCCESS'
export const SPLIT_CUSTOMERS_FAILURE = 'SPLIT_CUSTOMERS_FAILURE'

export const REMOVE_USER_FROM_ENTITY_REQUEST = 'REMOVE_USER_FROM_ENTITY_REQUEST'
export const REMOVE_USER_FROM_ENTITY_SUCCESS = 'REMOVE_USER_FROM_ENTITY_SUCCESS'
export const REMOVE_USER_FROM_ENTITY_FAILURE = 'REMOVE_USER_FROM_ENTITY_FAILURE'

export const UPDATE_VAT_REQUEST = 'UPDATE_VAT_REQUEST'
export const UPDATE_VAT_SUCCESS = 'UPDATE_VAT_SUCCESS'
export const UPDATE_VAT_FAILURE = 'UPDATE_VAT_FAILURE'

export const GET_VERIFICATIONS_REQUEST = 'GET_VERIFICATIONS_REQUEST'
export const GET_VERIFICATIONS_SUCCESS = 'GET_VERIFICATIONS_SUCCESS'
export const GET_VERIFICATIONS_FAILURE = 'GET_VERIFICATIONS_FAILURE'

export const CREATE_VERIFICATIONS_REQUEST = 'CREATE_VERIFICATIONS_REQUEST'
export const CREATE_VERIFICATIONS_SUCCESS = 'CREATE_VERIFICATIONS_SUCCESS'
export const CREATE_VERIFICATIONS_FAILURE = 'CREATE_VERIFICATIONS_FAILURE'

export const DELETE_VERIFICATIONS_REQUEST = 'DELETE_VERIFICATIONS_REQUEST'
export const DELETE_VERIFICATIONS_SUCCESS = 'DELETE_VERIFICATIONS_SUCCESS'
export const DELETE_VERIFICATIONS_FAILURE = 'DELETE_VERIFICATIONS_FAILURE'

export const MANUAL_VERIFICATION_REQUEST = 'MANUAL_VERIFICATION_REQUEST'
export const MANUAL_VERIFICATION_SUCCESS = 'MANUAL_VERIFICATION_SUCCESS'
export const MANUAL_VERIFICATION_FAILURE = 'MANUAL_VERIFICATION_FAILURE'

export const INSTALL_APPLICATION_REQUEST = 'INSTALL_APPLICATION_REQUEST'
export const INSTALL_APPLICATION_SUCCESS = 'INSTALL_APPLICATION_SUCCESS'
export const INSTALL_APPLICATION_FAILURE = 'INSTALL_APPLICATION_FAILURE'
export const DELETE_APPLICATION_REQUEST = 'DELETE_APPLICATION_REQUEST'
export const DELETE_APPLICATION_SUCCESS = 'DELETE_APPLICATION_SUCCESS'
export const DELETE_APPLICATION_FAILURE = 'DELETE_APPLICATION_FAILURE'

export const GET_FEATURE_ADOPTION_REQUEST = 'GET_FEATURE_ADOPTION_REQUEST'
export const GET_FEATURE_ADOPTION_SUCCESS = 'GET_FEATURE_ADOPTION_SUCCESS'
export const GET_FEATURE_ADOPTION_FAILURE = 'GET_FEATURE_ADOPTION_FAILURE'
export const GET_FAILED_JOBS_REQUEST = 'GET_FAILED_JOBS_REQUEST'
export const GET_FAILED_JOBS_SUCCESS = 'GET_FAILED_JOBS_SUCCESS'
export const GET_FAILED_JOBS_FAILURE = 'GET_FAILED_JOBS_FAILURE'
export const RETRY_FAILED_JOB_REQUEST = 'RETRY_FAILED_JOB_REQUEST'
export const RETRY_FAILED_JOB_SUCCESS = 'RETRY_FAILED_JOB_SUCCESS'
export const RETRY_FAILED_JOB_FAILURE = 'RETRY_FAILED_JOB_FAILURE'

export const login = email => {
  return (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        endpoint: '/admin/users/login',
        method: 'POST',
        body: {
          email,
        },
        types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
      },
    }).then(response => {
      const state = getState()
      // If we refer directly to the object, resetState() will remove the values
      const adminEmail = state.session.adminEmail
      // After we change user for the first time adminEmail will be set, so we only use the user
      // email for the first change
      const email = !adminEmail ? state.session.user.email : adminEmail

      dispatch(resetState())
      dispatch(setAdminEmail(email))

      return Promise.all([
        dispatch(
          setAccessToken(
            response.payload.accessToken,
            response.payload.accessTokenExpiration
          )
        ),
        dispatch(refreshSession()),
      ])
    })
  }
}

export const regainAdmin = history => {
  return (dispatch, getState) => {
    /**
     * The current URL might be something like /customers/edit/123. This will cause an
     * exception as the data is not there, so we redirect first.
     */
    history.push('/')

    const state = getState()
    const adminEmail = state.session.adminEmail

    return dispatch({
      [CALL_API]: {
        endpoint: '/admin/users/regain-admin',
        method: 'POST',
        body: {
          email: adminEmail,
        },
        types: [
          REGAIN_ADMIN_REQUEST,
          REGAIN_ADMIN_SUCCESS,
          REGAIN_ADMIN_FAILURE,
        ],
      },
    }).then(response => {
      dispatch(
        switchIdentity(
          response.payload.accessToken,
          response.payload.accessTokenExpiration,
          adminEmail
        )
      )

      return dispatch(refreshSession())
    })
  }
}

export const SWITCH_TO_PAYING_CUSTOMER_REQUEST =
  'SWITCH_TO_PAYING_CUSTOMER_REQUEST'
export const SWITCH_TO_PAYING_CUSTOMER_SUCCESS =
  'SWITCH_TO_PAYING_CUSTOMER_SUCCESS'
export const SWITCH_TO_PAYING_CUSTOMER_FAILURE =
  'SWITCH_TO_PAYING_CUSTOMER_FAILURE'

export const switchToPayingCustomer = (history, entityId) => {
  return (dispatch, getState) => {
    /**
     * The current URL might be something like /customers/edit/123. This will cause an
     * exception as the data is not there, so we redirect first.
     */
    history.push('/')

    const state = getState()
    // If we refer directly to the object, resetState() will remove the values
    let adminEmail = state.session.adminEmail
    // After we change user for the first time adminEmail will be set, so we only use the user
    // email for the first change
    if (!adminEmail) {
      adminEmail = state.session.user.email
    }

    return dispatch({
      [CALL_API]: {
        endpoint: '/admin/users/switch-entity',
        method: 'POST',
        body: {
          entity_id: entityId,
        },
        types: [
          SWITCH_TO_PAYING_CUSTOMER_REQUEST,
          SWITCH_TO_PAYING_CUSTOMER_SUCCESS,
          SWITCH_TO_PAYING_CUSTOMER_FAILURE,
        ],
      },
    }).then(response => {
      if (!response.error) {
        dispatch(
          switchIdentity(
            response.payload.accessToken,
            response.payload.accessTokenExpiration,
            adminEmail
          )
        )

        return dispatch(refreshSession())
      }
    })
  }
}

export const SWITCH_IDENTITY = 'SWITCH_IDENTITY'

export const switchIdentity = (
  accessToken,
  accessTokenExpiration,
  adminEmail
) => ({
  type: SWITCH_IDENTITY,
  accessToken,
  accessTokenExpiration,
  adminEmail,
})

export const searchUsers = email => {
  return {
    [CALL_API]: {
      endpoint: '/admin/users',
      method: 'GET',
      query: {
        filter_groups: [
          {
            filters: [{ key: 'email', value: email, operator: 'ct' }],
            or: true,
          },
        ],
      },
      types: [SEARCH_USERS_REQUEST, SEARCH_USERS_SUCCESS, SEARCH_USERS_FAILURE],
    },
  }
}

export const updateCreditNoteNumber = (creditNoteId, creditNoteNumber) =>
  api.put(`/admin/credit-notes/${creditNoteId}/update-number`, {
    body: {
      credit_note_number: creditNoteNumber,
    },
  })
export const updateInvoiceNumber = (invoiceId, invoiceNumber) =>
  api.put(`/admin/invoices/${invoiceId}/update-number`, {
    body: {
      invoice_number: invoiceNumber,
    },
  })

export const unsetShipmentCompleted = shipmentId =>
  api.post(`/admin/shipments/unset-completed/${shipmentId}`)

export const splitCustomers = (customerA, customerB) => ({
  [CALL_API]: {
    endpoint: '/admin/customers/split',
    method: 'POST',
    body: {
      customer_to_keep: customerA,
      customer_to_split: customerB,
    },
    types: [
      SPLIT_CUSTOMERS_REQUEST,
      SPLIT_CUSTOMERS_SUCCESS,
      SPLIT_CUSTOMERS_FAILURE,
    ],
  },
})

export const removeUserFromEntity = (entityId, userId) => ({
  [CALL_API]: {
    endpoint: '/admin/users/remove-user-from-entity',
    method: 'POST',
    body: {
      entity_id: entityId,
      user_id: userId,
    },
    types: [
      REMOVE_USER_FROM_ENTITY_REQUEST,
      REMOVE_USER_FROM_ENTITY_SUCCESS,
      REMOVE_USER_FROM_ENTITY_FAILURE,
    ],
  },
})

export const updateVat = (type, id, newVat) => ({
  [CALL_API]: {
    endpoint: '/admin/orders/update-vat',
    method: 'POST',
    body: {
      type,
      id,
      new_vat: newVat,
    },
    types: [
      REMOVE_USER_FROM_ENTITY_REQUEST,
      REMOVE_USER_FROM_ENTITY_SUCCESS,
      REMOVE_USER_FROM_ENTITY_FAILURE,
    ],
  },
})

export const mergeEntities = (receivingEntity, disposedEntity) => ({
  [CALL_API]: {
    endpoint: '/admin/entities/merge-entities',
    method: 'POST',
    body: {
      receiving: receivingEntity,
      disposed: disposedEntity,
    },
    types: [
      MERGE_ENTITIES_REQUEST,
      MERGE_ENTITIES_SUCCESS,
      MERGE_ENTITIES_FAILURE,
    ],
  },
})

export const mergeUsers = (receivingEntity, disposedEntity) => ({
  [CALL_API]: {
    endpoint: '/admin/users/merge-users',
    method: 'POST',
    body: {
      receiving: receivingEntity,
      disposed: disposedEntity,
    },
    types: [MERGE_USERS_REQUEST, MERGE_USERS_SUCCESS, MERGE_USERS_FAILURE],
  },
})

export const searchEntities = entityName => ({
  [CALL_API]: {
    endpoint: '/admin/entities',
    method: 'GET',
    query: {
      includes: ['apps'],
      filter_groups: [
        {
          filters: [{ key: 'name', value: entityName, operator: 'ct' }],
          or: false,
        },
      ],
    },
    types: [
      SEARCH_ENTITIES_REQUEST,
      SEARCH_ENTITIES_SUCCESS,
      SEARCH_ENTITIES_FAILURE,
    ],
  },
})

export const searchBrands = brandName => ({
  [CALL_API]: {
    endpoint: '/admin/entities',
    method: 'GET',
    query: {
      includes: ['apps'],
      filter_groups: [
        {
          filters: [
            { key: 'name', value: brandName, operator: 'ct' },
            { key: 'entity_type', value: 'brand', operator: 'eq' },
          ],
          or: false,
        },
      ],
    },
    types: [
      SEARCH_BRANDS_REQUEST,
      SEARCH_BRANDS_SUCCESS,
      SEARCH_BRANDS_FAILURE,
    ],
  },
})

export const getPayingCustomers = options => ({
  [CALL_API]: {
    endpoint: '/admin/paying-customers',
    method: 'GET',
    query: {
      includes: ['entity'],
      sort: [
        {
          key: 'entity',
          direction: 'asc',
        },
      ],
      ...options,
    },
    types: [
      GET_PAYING_CUSTOMERS_REQUEST,
      GET_PAYING_CUSTOMERS_SUCCESS,
      GET_PAYING_CUSTOMERS_FAILURE,
    ],
  },
})

export const createPayingCustomer = data => ({
  [CALL_API]: {
    endpoint: '/admin/paying-customers',
    method: 'POST',
    body: { data: data },
    types: [
      CREATE_PAYING_CUSTOMERS_REQUEST,
      CREATE_PAYING_CUSTOMERS_SUCCESS,
      CREATE_PAYING_CUSTOMERS_FAILURE,
    ],
  },
})

export const updatePayingCustomer = (customerId, data) => ({
  [CALL_API]: {
    endpoint: `/admin/paying-customers/${customerId}`,
    method: 'PUT',
    body: { data: data },
    types: [
      UPDATE_PAYING_CUSTOMERS_REQUEST,
      UPDATE_PAYING_CUSTOMERS_SUCCESS,
      UPDATE_PAYING_CUSTOMERS_FAILURE,
    ],
  },
})

export const getVerifications = () => ({
  [CALL_API]: {
    endpoint: '/admin/elastic-data-verifications',
    method: 'GET',
    query: {
      includes: ['payingCustomer.entity'],
    },
    types: [
      GET_VERIFICATIONS_REQUEST,
      GET_VERIFICATIONS_SUCCESS,
      GET_VERIFICATIONS_FAILURE,
    ],
  },
})

export const createVerification = data => ({
  [CALL_API]: {
    endpoint: '/admin/elastic-data-verifications',
    method: 'POST',
    body: { data: data },
    types: [
      CREATE_VERIFICATIONS_REQUEST,
      CREATE_VERIFICATIONS_SUCCESS,
      CREATE_VERIFICATIONS_FAILURE,
    ],
  },
})

export const deleteVerification = id => ({
  [CALL_API]: {
    endpoint: `/admin/elastic-data-verifications/${id}`,
    method: 'DELETE',
    types: [
      DELETE_VERIFICATIONS_REQUEST,
      DELETE_VERIFICATIONS_SUCCESS,
      DELETE_VERIFICATIONS_FAILURE,
    ],
  },
})

export const manualVerification = entityId => ({
  [CALL_API]: {
    endpoint: `/admin/elastic-data-verifications/manual/${entityId}`,
    method: 'GET',
    types: [
      MANUAL_VERIFICATION_REQUEST,
      MANUAL_VERIFICATION_SUCCESS,
      MANUAL_VERIFICATION_FAILURE,
    ],
  },
})

export const deleteUser = email => {
  return {
    [CALL_API]: {
      endpoint: `/admin/users/${email}`,
      method: 'DELETE',
      types: [DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE],
    },
  }
}

export const installApplication = (brandId, app) => ({
  [CALL_API]: {
    endpoint: `/admin/apps`,
    method: 'POST',
    body: { app, brandId },
    types: [
      INSTALL_APPLICATION_REQUEST,
      INSTALL_APPLICATION_SUCCESS,
      INSTALL_APPLICATION_FAILURE,
    ],
  },
})

export const deleteApplication = appId => ({
  [CALL_API]: {
    endpoint: `/admin/apps/${appId}`,
    method: 'DELETE',
    types: [
      DELETE_APPLICATION_REQUEST,
      DELETE_APPLICATION_SUCCESS,
      DELETE_APPLICATION_FAILURE,
    ],
  },
})

export const getFeatureAdoption = () => ({
  [CALL_API]: {
    endpoint: `/admin/feature-adoption`,
    method: 'GET',
    types: [
      GET_FEATURE_ADOPTION_REQUEST,
      GET_FEATURE_ADOPTION_SUCCESS,
      GET_FEATURE_ADOPTION_FAILURE,
    ],
  },
})

export const getFailedJobs = () => ({
  [CALL_API]: {
    endpoint: `/admin/queue/failed`,
    method: 'GET',
    types: [
      GET_FAILED_JOBS_REQUEST,
      GET_FAILED_JOBS_SUCCESS,
      GET_FAILED_JOBS_FAILURE,
    ],
  },
})

export const retryJob = jobId => ({
  [CALL_API]: {
    endpoint: `/admin/queue/retry/${jobId}`,
    method: 'POST',
    types: [
      RETRY_FAILED_JOB_REQUEST,
      RETRY_FAILED_JOB_SUCCESS,
      RETRY_FAILED_JOB_FAILURE,
    ],
  },
})
