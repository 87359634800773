/* @flow */

import sortBy from 'lodash/sortBy'

import {
  createColumnsDependencyGraph,
  createColumnKey,
  createRows,
  createSubSection,
  ensureRowGroup,
  findAllSubsectionSplitsFromInput,
  sortSubsections,
} from './shared'

import type { ColumnConfig, RowConfig, Section } from '../../types'
import type { Product, Variant } from '../../../../../types'

export const generateSections = (
  attributes,
  columns: Array<ColumnConfig>,
  data = {},
  dataConfigs,
  horizontalAttribute: string | null,
  matrix: boolean,
  product: Product,
  rows: Array<RowConfig>,
  tableData: { [string]: any },
  variants: Array<Variant>,
  verticalAttributes: Array<string>,
  sort
) => {
  /**
   * Every section is determined by the vertical attribute signature
   * (meaning the variant's combination of vertical attribute values) (e.g. Color)
   */
  const sectionsByVerticalAttributeSignature = {}
  const horizontalAttributeCodes = {}
  const horizontalAttributeValues = new Set()
  const assortmentAttributes = new Set()
  let hasAssortment = false
  for (let variant of variants) {
    if (variant.placeholder_variant) {
      continue
    }

    const verticalAttributesObject = {}
    const verticalAttributesCodes = {}
    const verticalAttributesValues = new Set()
    for (let verticalAttribute of verticalAttributes) {
      const value = variant.attributes[verticalAttribute]

      if (value) {
        verticalAttributesObject[verticalAttribute] = value
        verticalAttributesValues.add(value)
      }

      if (
        variant.attributes_codes &&
        variant.attributes_codes[verticalAttribute]
      ) {
        verticalAttributesCodes[verticalAttribute] =
          variant.attributes_codes[verticalAttribute]
      }
    }

    const verticalAttributeSignature = Array.from(
      verticalAttributesValues
    ).join('###')

    if (!sectionsByVerticalAttributeSignature[verticalAttributeSignature]) {
      let key = verticalAttributes
        .filter(attribute => verticalAttributesObject[attribute])
        .map(attribute => `${attribute}:${verticalAttributesObject[attribute]}`)
        .join(',')

      sectionsByVerticalAttributeSignature[verticalAttributeSignature] = {
        data: {},
        dataByVariantId: {},
        key: key,

        rowGroups: [],

        totalRowCount: 0,

        horizontalAttributesByVariantId: {},

        product,
        hasNoos: false,

        verticalAttributesCodes,
        verticalAttributesObject,
        verticalAttributeSignature: verticalAttributeSignature,
        verticalAttributesValues,

        variantsByHorizontalAttribute: {},
        variants: [],
        variantsById: {},
        variantIds: [],
      }
    }

    // shortcut to above created object
    const sectionData =
      sectionsByVerticalAttributeSignature[verticalAttributeSignature]

    sectionData.dataByVariantId[variant.id] = {}
    sectionData.variants.push(variant)
    sectionData.variantsById[variant.id] = variant
    sectionData.variantIds.push(variant.id)

    let isNoos = product.noos === true
    if (variant.noos !== null) {
      isNoos = variant.noos
    }

    if (isNoos === true) {
      sectionsByVerticalAttributeSignature[
        verticalAttributeSignature
      ].hasNoos = true
    }

    let horizontalAttributeValue
    if (horizontalAttribute) {
      horizontalAttributeValue = variant.attributes[horizontalAttribute]

      if (!horizontalAttributeValue) {
        horizontalAttributeValue = 'Variant'
      }

      horizontalAttributeValues.add(horizontalAttributeValue)

      if (
        variant.attributes_codes &&
        variant.attributes_codes[horizontalAttribute]
      ) {
        horizontalAttributeCodes[horizontalAttributeValue] =
          variant.attributes_codes[horizontalAttribute]
      }

      // assortment variant
      if (variant.assortment && Object.keys(variant.assortment).length > 0) {
        hasAssortment = true

        assortmentAttributes.add(horizontalAttributeValue)

        for (let assortmentAttributeValue in variant.assortment) {
          horizontalAttributeValues.add(assortmentAttributeValue)
        }
      }

      sectionData.horizontalAttributesByVariantId[variant.id] =
        horizontalAttributeValue
      sectionData.variantsByHorizontalAttribute[horizontalAttributeValue] =
        variant
    }

    for (let [dataKey, dataConfig] of Object.entries(dataConfigs)) {
      if (dataConfig.data_level === 'table') {
        continue
      }

      const dataOfKey = data[dataKey]

      if (dataOfKey) {
        if (!sectionData.data[dataKey]) {
          sectionData.data[dataKey] = {}
        }

        if (dataConfig.data_level === 'product') {
          sectionData.data[dataKey] = dataOfKey.data
          continue
        }

        const dataOfVariant = dataOfKey.data[variant.id]

        sectionData.data[dataKey][variant.id] = dataOfVariant

        sectionData.dataByVariantId[variant.id][dataKey] = dataOfVariant
      }
    }
  }

  let sections = Object.values(sectionsByVerticalAttributeSignature)

  // In traditional mode we will assume the variants are already sorted by the API
  if (matrix) {
    // previously we would fallback to sort sections by vertical attributes if
    // not sort was equal warehouse_location. this would sort colors alphabetically.
    // but by not doing that we allow people to have custom sort orders for colors.
    if (sort === 'warehouse_location') {
      sections = sortBy(sections, section => {
        const variant = section.variants.find(v => v.location)

        if (!variant) {
          return null
        }

        return variant.location
      })
    }
  }

  for (let section of sections) {
    const rowGroups = []

    const { variants, data: dataOfSection } = section

    for (let rowGroup of rows) {
      rowGroup = ensureRowGroup(rowGroup)

      const dataSourceRows = rowGroup.configs.filter(r => r.data_source)
      const dataSources = dataSourceRows
        .filter(r => dataOfSection[r.data_source])
        .map(r => Object.values(dataOfSection[r.data_source]))
      const splitKeys = findAllSubsectionSplitsFromInput(
        dataSources,
        rowGroup.split_by,
        tableData
      )

      const subSectionsBySplitKey = {}
      for (let splitKey of splitKeys) {
        subSectionsBySplitKey[splitKey.key] = createSubSection(
          splitKey.key,
          splitKey.label,
          splitKey.data,
          []
        )

        for (let rowConfig of rowGroup.configs) {
          const createdRows = createRows(
            product,
            rowConfig,
            columns,
            dataOfSection,
            variants,
            matrix,
            splitKey.key,
            'data',
            rowGroup.split_by
          )

          for (let row of createdRows) {
            subSectionsBySplitKey[splitKey.key].rows.push(row)
            section.totalRowCount++
          }
        }
      }

      let subSections = Object.values(subSectionsBySplitKey)
      subSections = sortSubsections(subSections)

      rowGroups.push({
        // useful to pass this one a long since we need it later when generating
        // subSections from lines
        split_by: rowGroup.split_by,
        subSections,
        show_label_filter: rowGroup.show_label_filter,
      })
    }

    section.rowGroups = rowGroups
  }

  let horizontalAttributeValuesArray = Array.from(horizontalAttributeValues)
  const lowercaseHorizontalAttribute = horizontalAttribute
    ? horizontalAttribute.toLowerCase()
    : ''
  const horizontalAttributeConfig = attributes[
    lowercaseHorizontalAttribute
  ] || { sort_order: [], tooltips: {} }

  sortAttributesBySortOrder(
    horizontalAttributeValuesArray,
    horizontalAttributeConfig.sort_order,
    Array.from(assortmentAttributes)
  )

  return {
    horizontalAttributeCodes,
    horizontalAttributeValues: horizontalAttributeValuesArray,
    generatedSections: sections,
    variants,
  }
}

const sortAttributesBySortOrder = (
  values,
  sortOrders,
  assortmentAttributes
) => {
  if (!sortOrders) {
    return values
  }

  values.sort((a, b) => {
    let aIndex = sortOrders.indexOf(a)
    let bIndex = sortOrders.indexOf(b)

    // Send to bottom
    if (aIndex === -1) {
      aIndex = 10000
    }
    if (bIndex === -1) {
      bIndex = 10000
    }

    // we always want assortments to show "at the end of the row"
    const aPrefix = assortmentAttributes.includes(a) ? 1 : 0
    const bPrefix = assortmentAttributes.includes(b) ? 1 : 0

    if (aPrefix > bPrefix) {
      return 1
    }

    if (aPrefix < bPrefix) {
      return -1
    }

    // aIndex will appear first since "sort_order" value is bigger
    if (aIndex > bIndex) {
      return 1
    }

    // bIndex will be sorted to index lower than aIndex
    if (aIndex < bIndex) {
      return -1
    }

    // leave aIndex and bIndex unchanged with respect to each other since aIndex equals bIndex
    return 0
  })

  return values
}
