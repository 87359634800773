/* @flow */

import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { MenuItem } from 'react-bootstrap'

import {
  CheckmarkDropdown,
  CheckmarkDropdownButton,
  CheckmarkDropdownGroup,
} from '../../infrastructure/components/Dropdown'

import type { ColumnOptions } from '../types'

type Props = {
  columns: ColumnOptions,
  id: string,
  onChange: (columns: Array<string>) => void,
  selected: Array<string>,
  size: 'normal' | 'small',
  title: string,
}

class ColumnsSelector extends PureComponent<Props, void> {
  static defaultProps = {
    size: 'normal',
  }

  render() {
    const { columns, id, selected, size, title } = this.props

    return (
      <CheckmarkDropdown
        buttonBsSize={size !== 'normal' ? size : undefined}
        onChange={this.props.onChange}
        id={id}
        selected={selected}
        title={title}
      >
        {columns.reduce((carry, group, i) => {
          const menuItems = [
            <CheckmarkDropdownGroup>{group.label}</CheckmarkDropdownGroup>,
            ...group.columns.map(column => (
              <CheckmarkDropdownButton
                key={`${id}_${column.value}`}
                label={column.label}
                value={column.value}
              >
                {column.label}
              </CheckmarkDropdownButton>
            )),
          ]

          if (i < columns.length - 1) {
            menuItems.push(<MenuItem divider />)
          }

          return carry.concat(menuItems)
        }, [])}
      </CheckmarkDropdown>
    )
  }
}

export default ColumnsSelector
