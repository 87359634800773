/* @flow */

import api from '../api'

import type {
  ApiResponse,
  CartRow,
  Currency,
  Id,
  WebshopProductRequestOptions,
} from '../types'
import type { AvailableDelivery } from './types'

// Webshop

export const ensureWebshopSession = (brandId: Id, linesheetId?: Id) =>
  api.post(`/webshop/${brandId}/sessions`, {
    body: {
      linesheetId,
    },
  })

export const ensureAnonymousWebshopSession = (brandId: Id, extraData) =>
  api.post(`/webshop/${brandId}/sessions/anonymous`, {
    body: {
      extra_data: extraData,
    },
  })

export const getWebshopCurrencies = (
  entityId: Id
): Promise<ApiResponse<Array<Currency>>> =>
  api.get(`/webshop/${entityId}/currencies`)

export const getWebshopPagesForSession = (brandId: Id) =>
  api.get(`/webshop/${brandId}/pages`)

export const updateWebshopSession = (
  brandId: Id,
  sessionId: Id,
  data: Object
) =>
  api.put(`/webshop/${brandId}/sessions/${sessionId}`, {
    body: {
      session: data,
    },
  })

// Cart

export const getCartDeliveries = (
  brandId: Id,
  currency: Currency,
  productIds: Array<Id>
): Promise<
  ApiResponse<{ available_deliveries: { [string]: Array<AvailableDelivery> } }>
> =>
  api.post(`/cart/${brandId}/deliveries`, {
    body: {
      currency: currency,
      product_ids: productIds,
    },
  })

export const getRelatedProducts = (
  brandId: Id,
  productId: Id,
  options: WebshopProductRequestOptions
) =>
  api.get(`/webshop/${brandId}/products/${productId}/related-products`, {
    query: options,
  })

export const getCartRows = (
  brandId: Id,
  currency: Currency,
  webshopSessionId: Id,
  productId?: Id
): Promise<ApiResponse<{ rows: Array<CartRow> }>> =>
  api.get(`/cart/${brandId}`, {
    query: {
      currency,
      productId,
      webshop_session_id: webshopSessionId,
    },
  })

export const updateCartDeliveries = (
  brandId: Id,
  currency: Currency,
  webshopSessionId: Id,
  data: Array<{ cart_row_id: Id, delivery_mode: number }>
) =>
  api.put(`/cart/${brandId}/deliveries`, {
    body: {
      currency: currency,
      deliveries: data,
      webshop_session_id: webshopSessionId,
    },
  })

export const fillCartFromOrder = (
  brandId: Id,
  orderId: Id,
  clearNonEmptyCart: boolean
): Promise<ApiResponse<void>> =>
  api.post(`/cart/${brandId}/fill-from-order`, {
    body: {
      order_id: orderId,
      clear_non_empty_cart: clearNonEmptyCart,
    },
    // Turn off default 422 error handling, we will handle it ourselves
    statusCodeCallback: {
      code: 422,
      callback: () => {},
    },
  })

export const removeCartRows = (
  brandId: Id,
  currency: Currency,
  rows: Array<NonPersitedCartRow>,
  webshopSessionId: Id
) =>
  api.delete(`/cart/${brandId}`, {
    query: {
      currency,
      rows,
      webshop_session_id: webshopSessionId,
    },
  })

export const updateCart = (
  brandId: Id,
  currency: Currency,
  rows: Array<NonPersitedCartRow>,
  webshopSessionId: Id
) =>
  api.put(`/cart/${brandId}`, {
    body: {
      currency,
      rows,
      webshop_session_id: webshopSessionId,
    },
  })

export const clearCart = (
  brandId: Id,
  currency: Currency,
  webshopSessionId: Id
) =>
  api.delete(`/cart/${brandId}`, {
    query: {
      currency,
      webshop_session_id: webshopSessionId,
    },
  })

// Products

export const getProducts = (
  brandId: Id,
  options?: WebshopProductRequestOptions
) =>
  api.post(`/webshop/${brandId}/search`, {
    body: options,
  })

export const getProduct = (
  brandId: Id,
  productId: Id,
  options?: WebshopProductRequestOptions
) =>
  api.get(`/webshop/${brandId}/products/${productId}`, {
    query: options,
  })

export const getProductsAttributes = (
  brandId: Id,
  options?: WebshopProductRequestOptions
) =>
  api.get(`/webshop/${brandId}/attributes`, {
    query: options,
  })

// Landing

export const getWebshopLandingSettings = (brandId: Id) =>
  api.get(`/webshop/${brandId}/landing`)

export const getWebshopLandingTemplates = () =>
  api.get('/webshop/landing-templates')

// Public webshop

export const getPublicBrand = (id?: Id, options?: Object) =>
  api.get(`/brands/public`, {
    query: {
      brand_id: id,
      options: options || [],
    },
  })

export const registerNewAccount = (brandId: Id, account) =>
  api.post(`/webshop/${brandId}/register`, {
    body: {
      account,
    },
  })

// Payments

export const getWebshopSessionPayment = (brandId: Id, webshopSessionId: Id) =>
  api.get(`/webshop/${brandId}/sessions/${webshopSessionId}/payment`)

export const getWebshopPayStatus = (
  brandId: Id,
  webshopSessionId: Id,
  token: string
) =>
  api.get(
    `/webshop/pay/${brandId}/sessions/${webshopSessionId}/payments/${token}/status`
  )

export const acceptWebshopPay = (
  brandId: Id,
  webshopSessionId: Id,
  token: string,
  data: Object
) =>
  api.post(
    `/webshop/pay/${brandId}/sessions/${webshopSessionId}/payments/${token}/accept`,
    {
      body: {
        data,
      },
    }
  )

// Webshop product labels

export const getWebshopProductLabels = () => api.get('/webshop/product-labels')

export const createWebshopProductLabel = (data: Object) =>
  api.post('/webshop/product-labels', {
    body: {
      webshop_product_label: data,
    },
  })

export const updateWebshopProductLabel = (id: Id, data: Object) =>
  api.put(`/webshop/product-labels/${id}`, {
    body: {
      webshop_product_label: data,
    },
  })

export const deleteWebshopProductLabel = (id: Id) =>
  api.delete(`/webshop/product-labels/${id}`)
