/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import total_quantity from './total_quantity'

import type { ColumnConfig } from '../../types'

type Options = {
  colli_not_included_in_value?: boolean,
  rows?: Array<string>,
}

const total_assortment_quantity: (options: Options) => ColumnConfig = options =>
  total_quantity({
    ...options,
    key: 'total_assortment_quantity',
    multiply_assortments: true,
    label: 'Total assort. Q',
  })

export default total_assortment_quantity
