import React from 'react'
import { OverlayTrigger, Tooltip } from '../components/Forms'

export default class TruncatedString extends React.Component {
  render() {
    const {
      value,
      maxLength,
      wordBreaker,
      showTooltip,
      tooltipPlacement,
    } = this.props

    let truncatedValue = value
    if (typeof value === 'string' && value.length > maxLength) {
      truncatedValue = value.substring(0, maxLength) + wordBreaker
    }

    let display = showTooltip ? (
      <TruncatedWithTooltip
        tooltipPlacement={tooltipPlacement}
        value={value}
        truncatedValue={truncatedValue}
      />
    ) : (
      <span>{truncatedValue}</span>
    )

    return <span>{display}</span>
  }
}
TruncatedString.defaultProps = {
  maxLength: 12,
  wordBreaker: '...',
  showTooltip: false,
  tooltipPlacement: 'top',
}

const TruncatedWithTooltip = ({ tooltipPlacement, truncatedValue, value }) => {
  const tooltip = <Tooltip id="tooltip">{value}</Tooltip>

  return (
    <OverlayTrigger placement={tooltipPlacement} overlay={tooltip}>
      <span>{truncatedValue}</span>
    </OverlayTrigger>
  )
}
