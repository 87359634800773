/* @flow */

import React from 'react'
import styled from 'styled-components'

import { SelectWrapper } from '../../../../../../infrastructure/components/Forms'

import { createDefaultLineData, flattenRows } from '../shared'
import { createConfiguredVariant } from '../../shared'

import type { Product } from '../../../../../types'
import {
  ProductTableContext,
  TableColumn,
  TableRow,
  createEmptyArray,
} from '../../shared'

type Props = {
  dataContext: Object,
  maxHorizontalColumns: number,
  noActionColumns: number,
  onAdd: () => void,
  product: Product,
}

const ConfiguratorRow = ({
  dataContext,
  maxHorizontalColumns,
  noActionColumns,
  onAdd: propsOnAdd,
  product,
}: Props) => {
  const {
    brand,
    columnKeys,
    columns,
    defaultRowData,
    matrix,
    rows,
    productImage,
  } = React.useContext(ProductTableContext)
  const [addFormValues, setAddFormValues] = React.useState({})

  const placeholderVariant = React.useMemo(
    () => product.variants.find(v => v.placeholder_variant),
    [product]
  )

  const flattenedRows = React.useMemo(
    () => (matrix ? [] : flattenRows(rows)[0]),
    [matrix, rows]
  )

  const onAttributeChange = React.useCallback(
    (attribute, value) => {
      setAddFormValues(s => {
        const copy = { ...s }

        copy[attribute.name] = value

        // this makes sure we unset the 3rd attribute if the clear the 2nd
        const attributeIndex = product.attributes.indexOf(attribute.name)
        const childAttributes = product.attributes.slice(attributeIndex + 1)
        for (let attribute of childAttributes) {
          copy[attribute] = null
        }

        return copy
      })
    },
    [product, setAddFormValues]
  )

  const onAdd = React.useCallback(() => {
    const configuratorValues = []
    for (let attributeName of product.attributes) {
      const attributeValueId = addFormValues[attributeName]
      const attribute = brand.attributes.find(a => a.name === attributeName)

      configuratorValues.push({
        attribute_id: attribute.id,
        attribute_value_id: attributeValueId,
      })
    }

    const variant = createConfiguredVariant(
      brand.attributes,
      product,
      placeholderVariant,
      configuratorValues
    )

    propsOnAdd(product, variant)
    setAddFormValues({})
  }, [
    addFormValues,
    brand,
    placeholderVariant,
    product,
    propsOnAdd,
    setAddFormValues,
  ])

  const addAttributesHaveValue = React.useMemo(() => {
    for (let attribute of product.attributes) {
      if (!addFormValues[attribute]) {
        return false
      }
    }

    return true
  }, [addFormValues, product.attributes])

  let colSpan = maxHorizontalColumns
  if (matrix) {
    colSpan += 1
  }

  return (
    <TableRow>
      {productImage === true && <TableColumn />}
      <TableColumn colSpan={colSpan}>
        <AttributesContainer>
          {product.attributes.map((attribute, i) => {
            if (i > 0) {
              const previousAttribute = product.attributes[i - 1]
              const previousAttributeHasValue = addFormValues[previousAttribute]

              if (!previousAttributeHasValue) {
                return null
              }
            }

            return (
              <AttributeSelectorContainer>
                <AttributeSelector
                  addFormValues={addFormValues}
                  attributes={brand.attributes}
                  attributeName={attribute}
                  configuratorOptions={product.configurator_options}
                  dataContext={dataContext}
                  onAttributeChange={onAttributeChange}
                  parentAttribute={i > 0 ? product.attributes[i - 1] : null}
                  value={addFormValues[attribute]}
                />

                {product.attributes.length === i + 1 && (
                  <AttributeAddButton
                    disabled={!addAttributesHaveValue}
                    onClick={onAdd}
                  >
                    <span className="glyphicon glyphicon-plus" /> Add
                  </AttributeAddButton>
                )}
              </AttributeSelectorContainer>
            )
          })}
        </AttributesContainer>
      </TableColumn>
      {flattenedRows.map(row => (
        <TableColumn key={row.key} />
      ))}
      {columns.map(column => (
        <TableColumn key={column.key} />
      ))}
      {createEmptyArray(noActionColumns).map((key, i) => (
        <TableColumn key={i} />
      ))}
    </TableRow>
  )
}

export default ConfiguratorRow

const AttributeSelector = ({
  addFormValues,
  attributes,
  attributeName,
  configuratorOptions,
  dataContext,
  onAttributeChange,
  parentAttribute,
  value,
}) => {
  const attribute = React.useMemo(() => {
    return attributes.find(a => a.name === attributeName)
  }, [attributes, attributeName])

  const immediateParentHaveAValue = React.useMemo(() => {
    return parentAttribute ? !!addFormValues[parentAttribute] : true
  }, [addFormValues, parentAttribute])

  const isEditable = immediateParentHaveAValue || !parentAttribute

  const availableOptions = React.useMemo(() => {
    const currency = dataContext.currency || false

    if (!parentAttribute) {
      const options = configuratorOptions.filter(
        o => o.attribute_id === attribute.id
      )

      return attribute.values
        .map(v => mapAttributeValueToOption(v, options, currency))
        .filter(v => v)
    }

    const parentAttributeValueId = addFormValues[parentAttribute]

    if (!parentAttributeValueId) {
      return []
    }

    const options = configuratorOptions.filter(o => {
      return (
        o.attribute_id === attribute.id &&
        o.parent_attribute_value_id === parentAttributeValueId
      )
    })

    return attribute.values
      .map(v => mapAttributeValueToOption(v, options, currency))
      .filter(v => v)
  }, [
    addFormValues,
    attribute,
    configuratorOptions,
    dataContext,
    parentAttribute,
  ])

  return (
    <AttributeContainer>
      <AttributeLabel>{attributeName}</AttributeLabel>

      <div className="react-select--micro">
        <SelectWrapper
          disabled={!isEditable}
          labelKey="name"
          options={availableOptions}
          onChange={value => onAttributeChange(attribute, value)}
          simpleValue
          value={value}
          valueKey="id"
        />
      </div>
    </AttributeContainer>
  )
}

const mapAttributeValueToOption = (
  attributeValue,
  configuratorOptions,
  currency
) => {
  const option = configuratorOptions.find(
    option => attributeValue.id === option.attribute_value_id
  )

  if (!option) {
    return false
  }

  const valueCopy = { ...attributeValue }

  if (
    currency &&
    option.sales_prices &&
    option.sales_prices[currency] &&
    option.sales_prices[currency].sales_price
  ) {
    valueCopy.name += ` (+${option.sales_prices[currency].sales_price} ${currency})`
  }

  return valueCopy
}

const AttributesContainer = styled.div`
  margin-top: -5px;
`

const AttributeSelectorContainer = styled.div`
  align-items: flex-end;
  display: flex;
  margin-top: 5px;
`

const AttributeContainer = styled.div`
  min-width: 100px;
  max-width: 200px;
  width: 150px;
`

const AttributeLabel = styled.div`
  font-size: 10px;
  font-weight: bold;
`

const AttributeAddButton = styled.button.attrs({
  className: 'btn btn-white btn-xs',
  type: 'button',
})`
  margin-left: 15px;
`
