// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import type { OrderStatusLabel as OrderStatusLabelType } from '../types'
import { changeBrandContext, fetchBrandsIfNeeded } from '../../brands/actions'

type Props = {
  brandId: number,
  dispatch: Function,
  order: {
    cancelled: boolean,
    status: string,
  },
  orderLabels: Array<OrderStatusLabelType>,
  size?: 'xxs' | 'xs' | 'sm',
}

class OrderStatusLabel extends Component<Props, void> {
  componentDidMount() {
    this.props.dispatch(fetchBrandsIfNeeded())
  }

  render() {
    const { order, orderLabels, size } = this.props

    let labelClass = ''
    if (size) {
      labelClass += `label-${size}`
    }

    return (
      <Label
        className={labelClass}
        style={{
          backgroundColor: order.cancelled
            ? '#f35958'
            : statusColor(order.status, orderLabels),
        }}
      >
        {order.cancelled ? 'Cancelled' : statusLabel(order.status, orderLabels)}
      </Label>
    )
  }
}

export default connect((state, ownProps) => {
  const brand = state.brands.items[ownProps.brandId]

  return {
    orderLabels: brand ? brand.settings.order_labels : [],
  }
})(OrderStatusLabel)

export const statusLabel = (
  givenStatus: string,
  orderLabels: Array<OrderStatusLabelType>
) => {
  const orderLabel = orderLabels.find(label => label.key === givenStatus)

  return orderLabel ? orderLabel.label : givenStatus
}

export const statusColor = (
  givenStatus: string,
  orderLabels: Array<OrderStatusLabelType>
) => {
  const orderLabel = orderLabels.find(label => label.key === givenStatus)

  return orderLabel ? orderLabel.color : ''
}

const Label = styled.div.attrs({
  className: 'label',
})`
  color: white;
`
