import {
  FETCH_COLLECTIONS_REQUEST,
  FETCH_COLLECTIONS_SUCCESS,
  FETCH_COLLECTIONS_FAILURE,
} from '../actions/index'
import _ from 'underscore'

/**
 *
 * @type {{brands: {}}}
 */
const initialState = {
  isFetching: true,
  brands: {},
}

/**
 * Reducer to update the collections state tree
 * @param  {object} state  =             initialState
 * @param  {object} action
 * @return {object}
 */
export default function collections(state = initialState, action) {
  switch (action.type) {
    case FETCH_COLLECTIONS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        brands: Object.assign({}, state.brands, {
          [action.meta.brandId]: {
            items: _.indexBy(action.payload.collections, 'id'),
          },
        }),
      })
      return state
    case FETCH_COLLECTIONS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
      })
    case FETCH_COLLECTIONS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    default:
      return state
  }
}
