/* @flow */

import * as React from 'react'
import isFunction from 'lodash/isFunction'
import {
  splitProductsIntoTableSectionsByData,
  addLinesToTableSections,
  hideProductsWithoutLinesIfPreviewMode,
  sortTableSections,
  ensureTableSections,
} from './table_sections'

export const useProductTableSections = (
  dataCacheDataProduct,
  dataCacheDataTable,
  dataConfigs,
  preview,
  productRows,
  lines,
  linesByProductId,
  tableSectionsConfig,
  useLinesReducerV2,
  tableSectionFilter
) => {
  const tableSectionsFromDataSources = React.useMemo(
    () =>
      splitProductsIntoTableSectionsByData(
        productRows,
        dataCacheDataProduct,
        dataConfigs,
        tableSectionsConfig,
        dataCacheDataTable
      ),
    [
      productRows,
      dataCacheDataProduct,
      dataConfigs,
      tableSectionsConfig,
      dataCacheDataTable,
    ]
  )

  const tableSectionsWithLines = React.useMemo(
    () =>
      addLinesToTableSections(
        tableSectionsFromDataSources,
        productRows,
        dataCacheDataProduct,
        lines,
        linesByProductId,
        tableSectionsConfig,
        dataCacheDataTable,
        useLinesReducerV2
      ),
    [
      tableSectionsFromDataSources,
      productRows,
      dataCacheDataProduct,
      lines,
      linesByProductId,
      tableSectionsConfig,
      dataCacheDataTable,
      preview,
      useLinesReducerV2,
    ]
  )

  const tableSectionsWithEnsuredSections = React.useMemo(() => {
    if (!tableSectionsConfig || !tableSectionsConfig.ensure) {
      return tableSectionsWithLines
    }

    // e.g. in shipment delivery notes we want to show colli sections without any lines
    return ensureTableSections(
      tableSectionsWithLines,
      tableSectionsConfig,
      dataCacheDataTable
    )
  }, [
    tableSectionsWithLines,
    tableSectionsConfig ? tableSectionsConfig.ensure : null,
    dataCacheDataTable,
  ])

  const tableSectionsWithProductsRemovedInPreviewMode = React.useMemo(() => {
    return hideProductsWithoutLinesIfPreviewMode(
      tableSectionsWithEnsuredSections,
      preview
    )
  }, [tableSectionsWithEnsuredSections, preview])

  const hideIfNoLines = tableSectionsConfig
    ? tableSectionsConfig.hide_if_no_lines
    : false
  const tableSectionsFilteredByLines = React.useMemo(() => {
    if (!tableSectionsConfig || !hideIfNoLines) {
      return tableSectionsWithProductsRemovedInPreviewMode
    }

    return tableSectionsWithProductsRemovedInPreviewMode.filter(
      tableSection => {
        if (tableSection.ensured === true) {
          return true
        }

        let lines = tableSection.lines

        if (isFunction(hideIfNoLines)) {
          return hideIfNoLines({ lines, tableSection })
        }

        return Object.keys(lines).length > 0
      }
    )
  }, [
    hideIfNoLines,
    tableSectionsConfig,
    tableSectionsWithProductsRemovedInPreviewMode,
  ])

  const tableSectionsSorted = React.useMemo(
    () =>
      sortTableSections(
        tableSectionsFilteredByLines,
        tableSectionsConfig,
        dataCacheDataTable
      ),
    [tableSectionsFilteredByLines, tableSectionsConfig, dataCacheDataTable]
  )

  const tableSectionsFiltered = React.useMemo(() => {
    if (!tableSectionFilter) {
      return tableSectionsSorted
    }

    return tableSectionsSorted.filter(tableSection => {
      let matches = true
      for (let [k, v] of Object.entries(tableSectionFilter)) {
        if (tableSection.tableSectionData[k] !== v) {
          matches = false
          break
        }
      }

      return matches
    })
  }, [tableSectionsSorted, tableSectionFilter])

  return tableSectionsFiltered
}
