/* @flow */

import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import onClickOutside from 'react-onclickoutside'
import moment from 'moment-timezone'

import {
  getNotifications,
  updateNotificationsReadStatus,
  updateNotificationsSeenStatus,
} from '../../api'
import { renderDateTime, Tooltip, SessionContext } from '../../../shared'

type Props = {}

const refreshTime = 1000 * 60 * 60 * 2 // once 60 minutes

const Notifications = ({}: Props) => {
  const { useNewColors } = React.useContext(SessionContext)
  const [notifications, setNotifications] = useState([])
  const [showList, setShowList] = useState(false)

  const toggleList = () => {
    // Will be closed of outside handler
    if (showList === true) {
      return
    }

    setShowList(true)

    updateNotificationsSeenStatus(
      notifications.reduce((carry, notification) => {
        carry[notification.id] = true
        return carry
      }, {})
    ).then(response => {
      if (!response.error) {
        setNotifications(
          notifications.map(n => ({
            ...n,
            seen: true,
          }))
        )
      }
    })
  }

  const markAllAsRead = () => {
    updateNotificationsReadStatus(
      notifications.reduce((carry, notification) => {
        carry[notification.id] = true
        return carry
      }, {})
    ).then(response => {
      if (!response.error) {
        setNotifications(
          notifications.map(n => ({
            ...n,
            read: true,
          }))
        )
      }
    })
  }

  const onClickLink = notification => {
    // we manually hide the navigation since we might already be on the page. e.g.
    // if we are already on order A page and navigate to order B.
    setShowList(false)

    updateNotificationsReadStatus({
      [notification.id]: true,
    }).then(response => {
      if (!response.error) {
        const updatedNotifications = [...notifications]
        const index = updatedNotifications.findIndex(
          n => n.id === notification.id
        )

        if (index !== -1) {
          updatedNotifications[index] = {
            ...updatedNotifications[index],
            read: true,
          }
          setNotifications(updatedNotifications)
        }
      }
    })
  }

  useEffect(() => {
    const fetchAndSetNotifications = () => {
      return getNotifications({
        limit: 100,
        sort: [{ key: 'created_at', direction: 'desc' }],
      }).then(response => {
        if (!response.error) {
          setNotifications(response.payload.notifications)
        }

        return response
      })
    }

    let interval
    fetchAndSetNotifications().then(() => {
      interval = setInterval(fetchAndSetNotifications, refreshTime)
    })

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  const unseenNotifications = useMemo(() => {
    return notifications.filter(n => n.seen === false)
  }, [notifications])

  return (
    <Container>
      <ClickContainer onClick={toggleList}>
        <IconContainer useNewColors={useNewColors}>
          <span className="glyphicon glyphicon-bell" />
          {unseenNotifications.length > 0 && (
            <UnseenCountBadge>{unseenNotifications.length}</UnseenCountBadge>
          )}
        </IconContainer>
      </ClickContainer>

      <WrappedNotificationList
        onHide={() => setShowList(false)}
        show={showList}
      >
        <NotificationListHeader>
          <NotificationListTitle>Notifications</NotificationListTitle>
          <NotificationListActions>
            <NotificationListAction onClick={markAllAsRead}>
              Mark all as read
            </NotificationListAction>
          </NotificationListActions>
        </NotificationListHeader>
        <NotificationListScrollContainer>
          {notifications.map(notification => {
            const link = createNotificationLink(notification)

            return (
              <Notification key={notification.id} read={notification.read}>
                {link && (
                  <Link to={link} onClick={() => onClickLink(notification)}>
                    {notification.message}
                  </Link>
                )}
                {!link && <span>{notification.message}</span>}

                <TimeAgo>
                  <Tooltip
                    id={`notification-${notification.id}-time`}
                    tip={renderDateTime(notification.created_at)}
                  >
                    <span>{moment(notification.created_at).fromNow()}</span>
                  </Tooltip>
                </TimeAgo>
              </Notification>
            )
          })}
        </NotificationListScrollContainer>
      </WrappedNotificationList>
    </Container>
  )
}

export default Notifications

class UnwrappedNotificationList extends React.Component {
  handleClickOutside = event => {
    this.props.onHide()
  }

  render() {
    return (
      <NotificationsList {...this.props}>
        {this.props.children}
      </NotificationsList>
    )
  }
}

const WrappedNotificationList = onClickOutside(UnwrappedNotificationList)

const Container = styled.div`
  cursor: pointer;
  height: 100%;
  position: relative;
  width: 100%;
`

const ClickContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`

const IconContainer = styled.div`
  height: ${({ useNewColors }) => (useNewColors ? '16px' : '20px')};
  position: relative;
  margin-top: -1px;
`

const UnseenCountBadge = styled.div`
  background: #fa3e3e;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  font-size: 10px;
  min-width: 18px;
  padding: 1px 3px;
  position: absolute;
  right: -11px;
  text-align: center;
  top: -4px;
`

const NotificationsList = styled.div`
  background: white;
  border: 1px solid rgba(100, 100, 100, 0.4);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
  color: black;
  font-size: 12px;
  display: ${({ show }: { show: boolean }) => (show ? 'block' : 'none')};
  right: 0;
  position: absolute;
  top: 62px;
  width: 300px;
  z-index: 10000;
`

const NotificationListScrollContainer = styled.div`
  height: 400px;
  overflow-y: auto;
  width: 100%;
`

const Notification = styled.div`
  background: ${({ read }: { read: boolean }) =>
    read ? 'transparent' : '#edf2fa'};
  border-bottom: 1px solid #dddfe2;
  padding: 5px 15px;

  &:hover {
    background: ${({ read }: { read: boolean }) =>
      read ? '#f9f9f9' : '#e6ecf5'};
  }

  a {
    color: black;
  }

  a:hover {
    color: black !important;
    text-decoration: underline;
  }
`

const NotificationListHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  font-size: 11px;
  display: flex;
  padding: 5px 10px;
`

const NotificationListTitle = styled.div`
  color: black;
  font-weight: bold;
`

const NotificationListActions = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

const NotificationListAction = styled.div`
  color: blue;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const TimeAgo = styled.div`
  color: #ccc;
  font-size: 11px;
  margin-top: 3px;
`

const createNotificationLink = notification => {
  if (notification.notification_type === 'order.created') {
    return `/orders/show/${notification.context.order_id}`
  }

  if (notification.notification_type.startsWith('task.')) {
    if (notification.data.task_context.order_id) {
      return `/orders/show/${notification.data.task_context.order_id}`
    }
    if (notification.data.task_context.customer_id) {
      return `/customers/${notification.data.task_context.customer_id}`
    }
  }

  return false
}
