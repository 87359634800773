/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import FormatCurrency from '../../../../../../infrastructure/components/FormatCurrency'

import type { ColumnConfig } from '../../types'

const unit_discount_amount: (options: Options) => ColumnConfig = ({
  rows,
}) => ({
  // if there is no default value then the row will unmount and mount all the time
  default_value: [{ discount_amount: null }],
  key: 'unit_discount_amount',
  label: 'Unit discount',
  rows: rows,
  right: true,
  split_by_values: true,
  value: ({ line }) => [
    { discount_amount: line ? line.discount_amount : null },
  ],
  render: ({ context, value }) => {
    return (
      <FormatCurrency currency={context.currency}>
        {value[0] ? value[0].discount_amount : null}
      </FormatCurrency>
    )
  },
})

export default unit_discount_amount
