/* @flow */

import api from '../api'

import type { ApiResponse, Role } from '../types'

export const getEntityUsers = (options?: Object) =>
  api.get('/entities/entity-users', {
    query: options,
  })

export const getUserEntities = (options?: Object) =>
  api.get('/entities/my-available-entities', {
    query: options,
  })

export const getRoles = (
  options?: Object
): Promise<ApiResponse<{ roles: Array<Role> }>> =>
  api.get('/roles', {
    query: options,
  })

export const sendForgotPasswordEmail = (email: string) =>
  api.post('/login/forgot-password', {
    body: {
      email,
    },
  })

export const saveUser = (userId, data) =>
  api.put(`/users/${userId}`, {
    body: {
      user: data,
    },
  })

export const saveEntityUser = (entityUserId, data) =>
  api.put(`/entity-users/${entityUserId}`, {
    body: data,
  })


export const fetchEntityUserCreateOptions = email =>
  api.get('/entity-users/create-options', {
    query: {
      email,
    },
  })

export const createEntityUser = data =>
  api.post('/entity-users', {
    body: data,
  })
