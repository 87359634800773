import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  CREATE_USER_SUCCESS,
  SAVE_USER_SUCCESS,
} from '../actions/index'
import { SETUP_NEXT_STEP, SETUP_PREVIOUS_STEP } from '../actions/setup'
import _ from 'underscore'

const initialState = {
  isFetching: false,
  items: [],
  setupStep: 0,
}

export default function users(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })

    case GET_USERS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        items: _.indexBy(action.payload.users, 'id'),
      })

    case GET_USERS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
      })

    case SETUP_PREVIOUS_STEP:
      return Object.assign({}, state, {
        setupStep: state.setupStep - 1,
      })

    case SETUP_NEXT_STEP:
      return Object.assign({}, state, {
        setupStep: state.setupStep + 1,
      })

    case CREATE_USER_SUCCESS:
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.payload.id]: action.payload,
        }),
      })

    default:
      return state
  }
}
