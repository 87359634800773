import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  Button,
  Modal,
  OverlayTrigger,
  Col,
  Row,
  Tabs,
  Tab,
} from 'react-bootstrap'
import CloudinaryResource from '../../../../infrastructure/components/CloudinaryResource'
import { Link } from 'react-router-dom'
import groupBy from 'lodash/groupBy'
import {
  ColoredStockLabel,
  STOCK_TYPES,
  StockLabel,
} from '../../../../infrastructure/components/Stock'
import { SessionContext } from '../../../shared'

import InlineReports from '../../../reports/components/Generator/InlineReports'

export class ProductModal extends React.Component {
  static contextType = SessionContext

  constructor(props) {
    super(props)

    this.state = {
      product: null,
      image: '',
    }

    this.changeImage = this.changeImage.bind(this)
  }

  componentDidMount() {
    this.props.fetchProduct().then(response => {
      if (!response.error) {
        const product = response.payload

        if (product.images === undefined) {
          product.images = []
        }

        this.setState({
          image: product.image,
          product,
        })
      }
    })
  }

  changeImage(image) {
    this.setState({
      image: image,
    })
  }

  render() {
    const { children, entity, onHide, show } = this.props
    const { image, product } = this.state
    const { brand } = this.context

    if (!product) {
      return null
    }

    let reportContexts = [
      { default: true, key: 'product_id', name: 'Product', value: product.id },
    ]
    if (product.collection) {
      reportContexts.push({
        key: 'collection_id',
        name: product.collection.title,
        value: product.collection_id,
      })
    }
    if (product.product_groups) {
      for (var productGroup of product.product_groups) {
        reportContexts.push({
          key: 'product_group_ids',
          name: productGroup.name,
          value: productGroup.id,
        })
      }
    }

    // brand might not be set if retailer in webshop
    const inventoryLocations = brand ? brand.inventory_locations : []

    return (
      <Modal show={show} onHide={onHide} bsSize="large">
        <Modal.Body className="product-modal">
          {entity.entity_type === 'shop' && this._renderProductData()}
          {entity.entity_type === 'brand' && (
            <Tabs defaultActiveKey="data" id="product-modal-tabs">
              <Tab eventKey="data" title="Data">
                <InlineReportsContainer>
                  <InlineReports contexts={reportContexts} />
                </InlineReportsContainer>
              </Tab>
              <Tab eventKey="images" title="Images and Description">
                {this._renderProductData()}
              </Tab>
            </Tabs>
          )}
          <div>
            <table className="table table-condensed">
              <thead>
                <tr>
                  {product.attributes.map(attribute => (
                    <th key={attribute}>{attribute}</th>
                  ))}
                  <th>SKU</th>
                  <th>EAN</th>
                  {inventoryLocations.map(location => (
                    <th key={location.id} style={{ textAlign: 'right' }}>
                      {location.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {product.variants.map(variant => {
                  // In case of multiple future deliveries there can be multiple entries per stock type
                  const stockByLocation = groupBy(
                    variant.stock,
                    'inventory_location_id'
                  )

                  return (
                    <tr key={variant.id}>
                      {product.attributes.map(attribute => (
                        <td key={attribute}>{variant.attributes[attribute]}</td>
                      ))}
                      <td>{variant.sku}</td>
                      <td>{variant.ean}</td>
                      {inventoryLocations.map(location => {
                        const stockOfLocation =
                          stockByLocation[location.id] || []
                        const quantity = stockOfLocation.reduce(
                          (carry, stock) => (carry += stock.quantity),
                          0
                        )

                        return (
                          <td key={location.id} style={{ textAlign: 'right' }}>
                            <ColoredStockLabel location={location}>
                              {quantity}
                            </ColoredStockLabel>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  _renderProductData = () => {
    const { image, product } = this.state

    return (
      <Row>
        <Col md={5}>
          <CloudinaryResource
            id={image}
            presets="product_search"
            fallback="product_search"
          />
          <br />
          {product.images.map(image => {
            return (
              <CloudinaryResource
                id={image}
                className="cursor-pointer"
                presets="table"
                fallback="product_table"
                onClick={() => this.changeImage(image)}
              />
            )
          })}
        </Col>
        <Col md={7}>
          <div className="product-modal-content">
            <h4>{product.name}</h4>
            {product.categories && (
              <div>
                <h6>Categories</h6>
                <ul className="inline-list">
                  {product.categories.map(category => {
                    return <li key={category.id}>{category.name}</li>
                  })}
                </ul>
              </div>
            )}

            {product.collection && (
              <div>
                <h6>Collection</h6>
                <ul className="inline-list">
                  <li>{product.collection.title}</li>
                </ul>
              </div>
            )}

            <h6>Description</h6>
            <div dangerouslySetInnerHTML={{ __html: product.description }} />
          </div>
        </Col>
      </Row>
    )
  }
}

export default connect(state => ({
  entity: state.session.entity,
}))(ProductModal)

const InlineReportsContainer = styled.div`
  height: 300px;
`
