import shallowEqual from 'fbjs/lib/shallowEqual'

export const shouldUpdate = (props, nextProps) => {
  let shouldUpdate = false
  for (var i in props) {
    if (!shallowEqual(props[i], nextProps[i])) {
      shouldUpdate = true
      break
    }
  }
  return shouldUpdate
}

export default shouldUpdate
