/* @flow */

import React, { Component, type Node } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { Link, type Location, type Match } from 'react-router-dom'
import querystring from 'qs'
import memoize from 'memoize-one'

import { hideMessage } from '../../infrastructure/actions/toast'
import { Content, ContentHeader, WhiteBox } from './Content'
import GdprLinkImage from '../users/assets/gdpr.png'
import { signDpa } from '../users/actions'
import { refreshSession } from '../../infrastructure/actions/session'
import { fetchBrandsIfNeeded, fetchBrands } from '../brands/actions'
import mobile from '../../infrastructure/modules/mobile'
import { useCustomCss } from '../../infrastructure/hooks/custom_css'

import type { Dispatch, Entity, User } from '../types'
import type { PayingCustomer } from '../admin/types'
import { SessionContext, useRefValue } from '../shared'

type Props = {
  brandsHaveFetched: boolean,
  className?: string,
  customer?: PayingCustomer,
  children?: Node,
  dispatch: Dispatch,
  entity: Entity,
  location: Location,
  loggingOut: boolean,
  match: Match,
  messages: Array<string>,
  showMessage: boolean,
  type: 'success' | 'info' | 'error',
  user: User,
}

const DOMContainer = ({
  brandContext,
  brands,
  brandsHaveFetched,
  className,
  children,
  customer,
  dispatch,
  entity,
  location,
  loggingOut,
  match,
  messages,
  session,
  showMessage,
  type,
  user,
}) => {
  const addFacebookPixel = React.useCallback(() => {
    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.appendChild(
      document.createTextNode(`
        !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
        n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
        document,'script','https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '153437695113847'); // Insert your pixel ID here.
        fbq('track', 'PageView');
      `)
    )
    if (document.body) {
      document.body.appendChild(script)
    }
  }, [])

  const renderError = message => {
    if (typeof message === 'string') {
      return <p dangerouslySetInnerHTML={{ __html: message }} />
    } else {
      return <p dangerouslySetInnerHTML={{ __html: message.detail }} />
    }
  }

  const refreshBrands = React.useCallback(() => {
    dispatch(fetchBrands())
  }, [dispatch])

  const handleSignDpa = React.useCallback(() => {
    dispatch(signDpa()).then(response => {
      if (!response.error) {
        dispatch(refreshSession())
      }
    })
  }, [dispatch])

  React.useEffect(() => {
    const urlQuery = querystring.parse(window.location.search.slice(1))
    if (
      process.env.NODE_ENV === 'production' &&
      !urlQuery.traede_pdf_generation
    ) {
      addFacebookPixel()
    }

    if (user && user.id && entity.entity_type !== 'shop') {
      dispatch(fetchBrandsIfNeeded())
    }
  }, [addFacebookPixel, dispatch, entity, user])

  React.useEffect(() => {
    if (user.id && !brandsHaveFetched && entity.entity_type !== 'shop') {
      dispatch(fetchBrandsIfNeeded())
    }
  }, [brandsHaveFetched, dispatch, entity, user])

  const showMessageRefValue = useRefValue(showMessage)
  const typeRefValue = useRefValue(type)

  React.useEffect(() => {
    if (showMessageRefValue.current && typeRefValue.current !== 'success') {
      dispatch(hideMessage())
    }
  }, [dispatch, location.pathname, showMessageRefValue, typeRefValue])

  React.useEffect(() => {
    let addedStyle

    const useNewCss = user.feature_flags ? user.feature_flags.new_colors : false
    const head = document.head || document.getElementsByTagName('head')[0]

    if (useNewCss) {
      addedStyle = document.createElement('style')

      head.appendChild(addedStyle)

      addedStyle.type = 'text/css'
      if (addedStyle.styleSheet) {
        // This is required for IE8 and below.
        addedStyle.styleSheet.cssText = newCssStyle
      } else {
        addedStyle.appendChild(document.createTextNode(newCssStyle))
      }
    }

    return () => {
      if (addedStyle) {
        head.removeChild(addedStyle)
      }
    }
  }, [user])

  if (loggingOut) {
    return null
  }

  // Since many components rely on the brands it is easier to just not render anything unless they are fetched
  if (user.id && !brandsHaveFetched && entity.entity_type !== 'shop') {
    return null
  }

  const hide = () => {
    dispatch(hideMessage())
  }

  if (
    customer &&
    customer.admin_email === user.email &&
    customer.dpa_accepted === false &&
    match.path !== '/gdpr'
  ) {
    return (
      <Content>
        <ContentHeader breadcrumbs={[{ label: 'Please sign our DPA' }]} />
        <WhiteBox>
          <p>Dear {user.firstname},</p>

          <p>
            You are marked as the contact person between Traede and{' '}
            {entity.name}. We have an important update to share with you. To
            meet the new rules about personal data, the General Data Protection
            Regulation (GDPR), we have to enter into a Data Processing Agreement
            with you as our customer.
          </p>

          <p>
            <strong>
              You can read the full Data Processing Agreement here on Traede by
              hovering your name in the top right corner and clicking "GDPR"
              (see image below).
            </strong>
          </p>

          <img src={GdprLinkImage} />

          <p />

          <p>
            We advise you to read the full agreement, and we think the most
            important things for you to know is that we:
          </p>

          <ul>
            <li>Process and store your data the right way</li>
            <li>Always guarantee that your data is secure</li>
            <li>Only transfer data to approved countries</li>
          </ul>

          <p>
            We have also updated our{' '}
            <a href="https://traede.com/terms-of-service" target="_blank">
              Terms of Service
            </a>{' '}
            and our{' '}
            <a href="https://traede.com/privacy-policy" target="_blank">
              Privacy Policy
            </a>
            , which we encourage you to keep updated on.
          </p>

          <p>
            <strong>
              For now, please click the button below to agree to our Data
              Processing Agreement. You can always go back and read it later
            </strong>
          </p>

          <button
            type="button"
            className="btn btn-success btn-lg"
            onClick={handleSignDpa}
          >
            Sign Data Processing Agreement
          </button>
        </WhiteBox>
      </Content>
    )
  }

  return (
    <div>
      <SessionContext.Provider
        value={createSessionContext(
          session,
          brands,
          brandContext,
          refreshBrands
        )}
      >
        {children}
      </SessionContext.Provider>
      {type !== 'success' && showMessage === true && (
        <Modal show={showMessage} onHide={hide}>
          <Modal.Body>
            {messages.length === 1 && renderError(messages[0])}
            {messages.length > 1 && (
              <ul>
                {messages.map(message => (
                  <li>{renderError(message)}</li>
                ))}
              </ul>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-white" onClick={hide}>
              Okay
            </button>
          </Modal.Footer>
        </Modal>
      )}
      {type === 'success' && showMessage === true && (
        <div className={`toast-container ${className || ''}`} onClick={hide}>
          <div className={`toast toast-${type}`}>
            <div className="toast-message">{messages[0]}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export const createSessionContext = memoize(
  (session, brands, brandContext, refreshBrands = null) => {
    const entity = session.entity

    let brand
    if (entity.entity_type === 'brand') {
      brand = brands[entity.id]
    } else if (brandContext && brands[brandContext]) {
      brand = brands[brandContext]
    }

    return {
      ...session,
      brands,
      brand,
      refreshBrands: refreshBrands,
      useNewColors:
        session.user && session.user.feature_flags
          ? session.user.feature_flags.new_colors
          : false,
    }
  }
)

export default connect(state => {
  const { lastFetched: brandsLastFetched, items: brands } = state.brands
  const { customer, entity, loggingOut, user } = state.session
  const { className, messages, showMessage, type } = state.toast

  return {
    brandContext: state.brandContext,
    brandsHaveFetched: brandsLastFetched !== null,
    brands: brands,
    className,
    customer,
    entity,
    loggingOut,
    messages,
    showMessage,
    session: state.session,
    type,
    user,
  }
})(DOMContainer)

const newCssStyle = `
  body {
    color:#303030;
  }

  .table td {
    color:#303030;
  }

  .btn-success, .btn-success:focus {
    background:#303030;
  }

  .btn-success:hover {
    background:#1a1a1a;
  }

  .btn-success:active, .btn-success:active:focus {
    background:#5a5a5a;
    border:none;
    outline:0;
  }

  .btn-success[disabled], .btn-success[disabled]:focus, .btn-success[disabled]:active, .btn-success[disabled]:focus:active, .btn-success[disabled]:hover {
    background:rgba(0,0,0,.40);
    background-color:rgba(0,0,0,.40) !important;
  }

  .full-height {
    min-height:calc(100vh - 103px)
  }

  .content {
    background:#f8f8f8;
    margin-top:103px;
  }

  .content.content-no-breadcrumbs {
    margin-top:53px;
  }

  .content-topbar {
    top:50px !important;
  }

  .breadcrumbs-bar {
    top:50px !important;
  }

  .label-success {
    background:#303030;
  }

  .checkbox.check-success input[type='checkbox']:checked + label:before {
    background:#303030;
    border:none;
  }

  .radio.radio-success input[type='radio']:checked + label:before {
    border: 1px solid #303030;
  }

  .radio.radio-success label:after {
    background-color: #303030;
    border: 1px solid #303030;
  }

  .table-hover tbody tr:hover > td, .table-hover tbody tr:hover > th {
    background-color: #fbfbfb;
  }

  .label-green-new,
  .badge-green-new {
    background:#E3F3E9;
    color:#11B066;
  }

  .label-important,
  .badge-important {
    background:#FEF3F2;
    color:#E74136;
  }
`
