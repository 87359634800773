/* @flow */

import React, { Component, PureComponent, PropTypes } from 'react'
import { connect } from 'react-redux'
import difference from 'lodash/difference'
import isArray from 'lodash/isArray'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import { OverlayTrigger } from 'react-bootstrap'
import styled from 'styled-components'
import { List, Map } from 'immutable'
import mobile from '../../../../infrastructure/modules/mobile'
import { convertWeight } from '../../../../infrastructure/utilities'

import FormatCurrency, {
  format as formatAsCurrency,
} from '../../../../infrastructure/components/FormatCurrency'
import { toQuantityTotal } from '../../../orders/components/shared'

import {
  ClickLabel,
  showColumn,
  ProductTableContainer,
  Column,
  Row,
  HeaderRow,
  createEmptyArray,
  createVariantPopover,
  defaultColumns,
  ProductTablePropertiesHeader,
  ImageColumn,
  ImageHeaderColumn,
  columnRenderers,
  renderColumn,
  ActionColumn,
  AttributeColumn,
  AttributeHeaderColumn,
  QuantityColumn,
  PriceColumn,
  ProductNameColumn,
  sortProducts,
  generateLineIndexFromVariants,
  getQuantityForVariant,
} from './shared'
import QuantityField from './QuantityField'
import { DiscountedPrice, RrpTip, WhsTip, getPrice } from '../Price'
import shouldUpdate from './utils/shouldUpdate'
import { getAttributes } from './actions'

import type { CustomField } from '../../../custom-fields/types'
import type { ProductTableAction, RowSize } from './types'
import type { Currency, Product, Quantities, Variant } from '../../../types'

import { aggregateValuesToLabel } from './actions/AggregateMenu'

const GROUPBY_DELIMITER = '#!@$%@nnbgf#'
export const HORIZONTAL_ATTRIBUTE_DEFAULT_VALUE = 'auto'

type Props = {
  canOverrideColliOnly: boolean,
  colliOnly?: boolean,
  currency: Currency,
  customFields: Array<CustomField>,
  editableQuantities?: boolean,
  onQuantityChange?: Function,
  productActions?: Array<ProductTableAction>,
  rowSize: RowSize,
  quantities: Quantities,
}

class AggregateProductTable extends Component<Props, State> {
  state = {
    quantities: Map(),
    rows: [],
  }

  componentDidMount() {
    this.updateProducts(this.props, false)
  }

  componentWillReceiveProps(nextProps: Props) {
    this.updateProducts(nextProps, true)
  }

  // When we load the attributes we do not wish to check for changes,
  // since we are making the initial render
  updateProducts(nextProps: Props, checkForChanges: boolean) {
    const {
      currency,
      customFields,
      products,
      options: { aggregates, aggregate_method, columns },
      quantities,
    } = nextProps

    if (
      !checkForChanges ||
      this.props.products !== products ||
      this.props.currency !== currency ||
      this.props.options.aggregates !== aggregates ||
      this.props.options.aggregate_method !== aggregate_method ||
      this.props.quantities !== quantities ||
      this.props.customFields !== customFields
    ) {
      this.setState({
        rows: generateRows(
          aggregates,
          aggregate_method,
          products,
          quantities,
          currency,
          customFields
        ),
      })
    }
  }

  render() {
    const {
      bordered,
      currency,
      customFields,
      editableQuantities,
      options,
      onQuantityChange,
      settings,
      renderers,
      rowSize,
      session,
      toggleDiscountModal,
      toggleProductModal,
      ...rest
    } = this.props

    const { quantities, rows } = this.state

    const customFieldsByInternalName = keyBy(customFields, 'internal_name')
    const headers = options.aggregates.map(agg => {
      const isMeta = isMetaColumn(agg)

      return isMeta
        ? customFieldsByInternalName[isMeta[1]]
          ? customFieldsByInternalName[isMeta[1]].label
          : ''
        : aggregateValuesToLabel[agg]
    })

    return (
      <ProductTableContainer bordered={bordered} {...rest}>
        <tbody>
          <HeaderRow rowSize={rowSize}>
            {headers.map(label => (
              <Column>{label}</Column>
            ))}
            <QuantityColumn>Quantity</QuantityColumn>
            <PriceColumn>Total Price</PriceColumn>
          </HeaderRow>
          {rows.map(row => {
            return (
              <Row rowSize={rowSize}>
                {row.columns.map(value => (
                  <Column>{value}</Column>
                ))}
                {options.aggregates.includes('total_weight') && (
                  <QuantityColumn>{row.total_weight} kg</QuantityColumn>
                )}
                <QuantityColumn>{row.quantity}</QuantityColumn>
                <PriceColumn>
                  <FormatCurrency currency={currency}>
                    {row.total_price}
                  </FormatCurrency>
                </PriceColumn>
              </Row>
            )
          })}
        </tbody>
      </ProductTableContainer>
    )
  }
}

export default AggregateProductTable

export const generateRows = (
  aggregates: Array<string>,
  aggregate_method: 'quantity' | 'percentage',
  products: List<Product>,
  quantities: Quantities,
  currency: Currency,
  customFields: Array<CustomField>
) => {
  const productsAsJs: Array<Product> = products.toArray()
  const productsById = keyBy(productsAsJs, 'id')

  const customFieldsByInternalName = keyBy(customFields, 'internal_name')

  const variantsById = {}
  for (let product of productsAsJs) {
    const collection = product.collection
      ? product.collection.title
      : 'No collection'

    const subCategories = product.categories
      ? product.categories.filter(c => c.parent !== null).map(c => c.name)
      : []
    const subCategoryLabel =
      subCategories.length > 0 ? subCategories.join(' - ') : 'No subcategory'

    const topCategories = product.categories
      ? product.categories.filter(c => c.parent === null).map(c => c.name)
      : []
    const topCategoryLabel =
      topCategories.length > 0 ? topCategories.join(' - ') : 'No top category'

    for (let variant of product.variants) {
      const variantQuantity = getQuantityForVariant(
        quantities.getIn([product.id, variant.id], Map()),
        variant.prices,
        currency
      )
      const q = toQuantityTotal(variantQuantity)

      const prices = variant.prices[currency]
      const price = getPrice(prices)

      const data = {
        id: variant.id,
        price,

        collection,
        item_number: product.item_number,
        product_name: product.name,
        sub_category: subCategoryLabel,
        top_category: topCategoryLabel,
        unit_weight: convertWeight(
          product.weight,
          product.weight_type,
          'kg',
          true
        ),

        meta: product.meta,
      }

      variantsById[variant.id] = data
    }
  }

  const variantData = []
  for (let [productId, productQuantities] of quantities.entries()) {
    for (let [variantId, variantQuantities] of productQuantities.entries()) {
      const variant = variantsById[variantId]
      const priceSplits = variantQuantities.get('price_splits')

      if (!variant) {
        continue
      }

      if (priceSplits) {
        for (let [splitPrice, splitQuantity] of priceSplits.entries()) {
          variantData.push({
            ...variant,
            quantity: splitQuantity.get('quantity'),
            price: parseFloat(splitPrice),
          })
        }
      } else {
        variantData.push({
          ...variant,
          quantity: variantQuantities.get('quantity'),
        })
      }
    }
  }

  const productsGroupedByAggregate = groupBy(variantData, variant => {
    return extractGroupData(
      variant,
      aggregates,
      customFieldsByInternalName,
      currency
    ).join('###')
  })

  let rows = Object.keys(productsGroupedByAggregate).reduce(
    (carry, groupLabel) => {
      const variants = productsGroupedByAggregate[groupLabel]

      const totals = variants.reduce(
        (carry, variant) => {
          carry.quantity += variant.quantity
          carry.price += variant.quantity * variant.price
          carry.weight += variant.quantity * variant.unit_weight

          return carry
        },
        {
          quantity: 0,
          price: 0,
          weight: 0,
        }
      )

      const group = {
        columns: groupLabel.split('###'),
        key: groupLabel,
        quantity: totals.quantity,
        total_price: totals.price,
        total_weight: totals.weight.toFixed(1),
      }

      carry.push(group)

      return carry
    },
    []
  )

  rows = sortBy(rows, row => row.columns[0])

  if (aggregate_method === 'percentage') {
    const totalQuantity = rows.reduce(
      (carry, row) => (carry += row.quantity),
      0
    )

    for (var i in rows) {
      const row = rows[i]

      const percentage = parseInt(
        ((100 / totalQuantity) * row.quantity).toFixed(0)
      )
      row.quantity = `${percentage}%`
    }
  }

  return rows
}

export const extractGroupData = (
  variant: Object,
  aggregates: Array<string>,
  customFieldsByInternalName: { [string]: CustomField },
  currency: Currency
): Array<string> => {
  const groupValues = []

  for (var i in aggregates) {
    const agg = aggregates[i]

    const isMeta = isMetaColumn(agg)

    if (isMeta !== null) {
      const key = isMeta[1]
      const customField = customFieldsByInternalName[key]
      const value = variant.meta[key]

      groupValues.push(value || `No ${customField ? customField.label : ''}`)

      continue
    }

    switch (agg) {
      case 'collection':
        groupValues.push(variant.collection)
        break
      case 'item_number':
        groupValues.push(variant.item_number)
        break
      case 'product_name':
        groupValues.push(variant.product_name)
        break
      case 'sub_category':
        groupValues.push(variant.sub_category)
        break
      case 'top_category':
        groupValues.push(variant.top_category)
        break
      case 'price':
        groupValues.push(formatAsCurrency(currency, variant.price))
        break
    }
  }

  return groupValues
}

const isMetaColumn = (column: string) => /^meta\.([^\.]+)/.exec(column)
