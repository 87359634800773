/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import EanBarcode from '../../../../../../infrastructure/components/EanBarcode'

import type { RowConfig } from '../../types'

type Options = {
  label?: string,
}

const barcode: (options: Options) => RowConfig = ({ label = 'Barcode' }) => ({
  data_source: '__variant',
  key: 'barcode',
  label,
  render: ({ value }) => {
    if (!value) {
      return null
    }

    return <EanBarcode format="ean13" ean={value} />
  },
  value: ({ variant }) => {
    return variant.ean
  },
})

export default barcode
