import { CALL_API } from '../middleware/api'

export const REQUEST_COUNTRIES = 'REQUEST_COUNTRIES'
export const RECEIVE_COUNTRIES = 'RECEIVE_COUNTRIES'

export const requestCountries = () => ({
  type: REQUEST_COUNTRIES,
})

export const receiveCountries = countries => ({
  type: RECEIVE_COUNTRIES,
  countries,
})

export const shouldFetchCountries = () => {
  return (dispatch, getState) => {
    const { misc } = getState()

    if (misc.countries.items.length === 0 && !misc.countries.isFetching) {
      dispatch(fetchCountries())
    }
  }
}

const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST'
const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS'
const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE'

const getCountries = () => ({
  [CALL_API]: {
    endpoint: '/misc/countries',
    method: 'GET',
    types: [
      GET_COUNTRIES_REQUEST,
      GET_COUNTRIES_SUCCESS,
      GET_COUNTRIES_FAILURE,
    ],
  },
})

export const fetchCountries = () => {
  return dispatch => {
    dispatch(requestCountries())
    dispatch(getCountries()).then(response =>
      dispatch(receiveCountries(response.payload))
    )
  }
}
