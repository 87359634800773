import {
  REFRESH_SESSION_SUCCESS,
  SET_ACCESS_TOKEN,
  SET_ADMIN_EMAIL,
  SET_SESSION,
  USER_LOGOUT_FINISH,
  USER_LOGOUT_START,
  USER_LOGOUT_SUCCESS,
} from '../actions/session'
import {
  CLEAR_REFRESH_TOKEN_PROMISE,
  SAVE_REFRESH_TOKEN_PROMISE,
} from '../middleware/api'

export const setContainerEntities = ({ entity, settings, user }) => {}

const defaultSessionStorage = {
  customer: {},
  entity: {},
  settings: {},
  user: {},
}

const tokenStorage = {
  token: null,
  expiration: null,
}

export const initialState = Object.assign({}, defaultSessionStorage, {
  accessToken: tokenStorage,
  adminEmail: null,
  loggingOut: false,
  refreshTokenPromise: null,
  virgin: false,
})

export default function session(state = initialState, action) {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return Object.assign({}, state, {
        accessToken: Object.assign({}, state.accessToken, {
          token: action.accessToken,
          expiration: action.accessTokenExpiration,
        }),
      })
    case SET_ADMIN_EMAIL:
      return Object.assign({}, state, {
        adminEmail: action.email,
      })
    case SET_SESSION:
      // TODO: Remove
      setContainerEntities(action)
      return Object.assign({}, state, {
        customer: action.customer || {},
        entity: action.entity,
        settings: action.settings,
        user: action.user,
      })
    case USER_LOGOUT_FINISH:
      return Object.assign({}, state, {
        loggingOut: false,
      })
    case USER_LOGOUT_START:
      return Object.assign({}, state, {
        loggingOut: true,
      })
    case USER_LOGOUT_SUCCESS:
      setContainerEntities(defaultSessionStorage)

      return Object.assign({}, state, defaultSessionStorage)
    case CLEAR_REFRESH_TOKEN_PROMISE:
      return Object.assign({}, state, {
        refreshTokenPromise: initialState.refreshTokenPromise,
      })
    case SAVE_REFRESH_TOKEN_PROMISE:
      return Object.assign({}, state, {
        refreshTokenPromise: action.promise,
      })
    default:
      return state
  }
}
