/* @flow */

import React, { Component, useMemo } from 'react'
import Select from 'react-select'
import sortBy from 'lodash/sortBy'

import { useCachedProductGroups } from '../hooks'

import type { ProductGroup } from '../../types'

const ProductGroupSelector = (props: any) => {
  const [productGroups, { isFetching }] = useCachedProductGroups()

  const options = useMemo(() => {
    let options = sortBy(productGroups, 'name')

    return options
  }, [productGroups])

  return (
    <Select
      isLoading={isFetching}
      labelKey="name"
      placeholder="Select product group..."
      options={options}
      valueKey="id"
      {...props}
    />
  )
}

export default ProductGroupSelector

export const DatalessProductGroupSelector = ({
  productGroups,
  isFetching,
  ...props
}) => {
  const options = useMemo(() => {
    const options = productGroups.map(productGroup => ({
      ...productGroup,
      label: productGroup.name,
    }))

    return sortBy(options, productGroup => productGroup.name.toLowerCase())
  }, [productGroups])

  return (
    <Select
      isLoading={isFetching}
      labelKey="name"
      placeholder="Select product group..."
      options={options}
      valueKey="id"
      {...props}
    />
  )
}
