/* @flow */

import React, { Component } from 'react'
import styled from 'styled-components'

import { FormInput, RadioInput, SelectInput } from '../../Formik'
import { FieldGroup } from './shared'

import type { FilterOperator } from '../../../../app/types'

type Props = {
  operator: FilterOperator,
  value: Array<string>,
}

export default class StringFilter extends Component<Props, void> {
  render() {
    const { operator, value } = this.props

    return (
      <div>
        <FieldGroup>
          <RadioInput
            id="filter_string_operator_ct"
            name="operator"
            label="contains"
            value="ct"
          />
          {operator === 'ct' && <FormInput name="value" />}
        </FieldGroup>

        <FieldGroup>
          <RadioInput
            id="filter_string_operator_eq"
            name="operator"
            label="equals"
            value="eq"
          />
          {operator === 'eq' && <FormInput name="value" />}
        </FieldGroup>

        <FieldGroup>
          <RadioInput
            id="filter_string_operator_sw"
            name="operator"
            label="starts with"
            value="sw"
          />
          {operator === 'sw' && <FormInput name="value" />}
        </FieldGroup>

        <FieldGroup>
          <RadioInput
            id="filter_string_operator_ew"
            name="operator"
            label="ends with"
            value="ew"
          />
          {operator === 'ew' && <FormInput name="value" />}
        </FieldGroup>

        <FieldGroup>
          <RadioInput
            id="filter_string_operator_no_value"
            name="operator"
            label="no value"
            value="no_value"
          />
        </FieldGroup>
      </div>
    )
  }
}
