/* @flow */

import React from 'react'
import styled, { css } from 'styled-components'

import type { RowConfig } from './types'
import type { ConnectedBrand } from '../../../types'

export const TableRow = styled.tr`
  &:hover {
    .product-table-hover-column {
      background: #f9f9f9;
    }
  }
`

export const TableColumn = styled.td`
  border-bottom: ${({ borderBottom = true }) =>
    borderBottom ? '1px solid #e5e9ec' : 'none'};
  border-left: ${({ borderLeft = true }) =>
    borderLeft ? '1px solid #e5e9ec' : 'none'};
  border-right: ${({ borderRight = true }) =>
    borderRight ? '1px solid #e5e9ec' : 'none'};
  border-top: ${({ borderTop = true }) =>
    borderTop ? '1px solid #e5e9ec' : 'none'};
  font-size: 11px;
  padding-bottom: 3px;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 3px;
  position: relative;
  vertical-align: middle;

  ${({ mode }) => tableColumnModeToStyle(mode)};
`

const tableColumnModeToStyle = (
  mode: 'assortment' | 'disabled' | 'editable'
) => {
  switch (mode) {
    case 'assortment':
    case 'disabled':
      return css`
        background-image: linear-gradient(
          135deg,
          #f0f0f0 12.5%,
          #fff 12.5%,
          #fff 50%,
          #f0f0f0 50%,
          #f0f0f0 62.5%,
          #fff 62.5%,
          #fff 100%
        );
        background-size: 5.66px 5.66px;
        cursor: not-allowed;
      `
    case 'editable':
      return css`
        border-left: none;
        border-right: none;
        padding-bottom: 4px;
        padding-top: 4px;
      `
    default:
      return null
  }
}

// .map will not work with new Array()
export const createEmptyArray = (length: number) =>
  length > 0 ? new Array(length).join().split(',') : []

export const sortAttributesBySortOrder = (values, sortOrders) => {
  if (!sortOrders) {
    return values
  }

  values.sort((a, b) => {
    let aIndex = sortOrders.indexOf(a)
    let bIndex = sortOrders.indexOf(b)

    // Send to bottom
    if (aIndex === -1) {
      aIndex = 10000
    }
    if (bIndex === -1) {
      bIndex = 10000
    }

    // aIndex will appear first since "sort_order" value is bigger
    if (aIndex > bIndex) {
      return 1
    }

    // bIndex will be sorted to index lower than aIndex
    if (aIndex < bIndex) {
      return -1
    }

    // leave aIndex and bIndex unchanged with respect to each other since aIndex equals bIndex
    return 0
  })

  return values
}

export const defaultSettings: ProductTableSettings = {
  bordered: false,
  columns: [
    'name',
    'image',
    'quantity',
    'price',
    'total_price',
    'item_number',
    'collection',
    'subbrand',
    'rec_sales_price',
  ],
  custom_diameter: 200,
  hide_attributes: [],
  horizontal_attribute: 'Size',
  image_shape: 'circle',
  image_size: 'medium',
  matrix: true,
  product_name_label:
    '{{#name}}{{name}}{{/name}} {{#item_number}}(#{{item_number}}){{/item_number}} {{#collection}}({{collection}}){{/collection}} {{#subbrand}}({{subbrand}}){{/subbrand}}',
  rec_sales_price_label: 'RRP',
  rec_sales_price_label_tooltip: 'Recommended Retail Price',
  sales_price_label: 'WHS',
  sales_price_label_tooltip: 'Wholesale Price',
  show_product_image: true,
  show_variant_images: true,
  show_variant_popover: true,
  sort: 'name',

  primary_image_size: 'medium',
  primary_image_zoom_size: 'large',

  zoom_custom_height: '',
  zoom_custom_width: '',
  zoom_on_hover: true,
  zoom_size: 'large',

  variant_popover_data: ['inventory', 'sku', 'ean', 'prices', 'colli_prices'],
}

export const ProductTableContext = React.createContext({
  columns: [],
  dataConfig: [],
  settings: defaultSettings,
})

export const determineHorizontalAttribute = (brand, settings) => {
  let horizontalAttribute = settings.horizontal_attribute

  return horizontalAttribute
}

export const IconButton = styled.button`
  background: none;
  border: none;
  float: ${({ float }) => float};
  outline: 0;

  ${({ size }: { size: 'normal' | 'small' }) => sizeToStyle(size)};
`

const sizeToStyle = size => {
  switch (size) {
    case 'normal':
      return css``

    case 'small':
      return css`
        font-size: 11px;
        font-weight: normal;
      `
  }
}

export const createConfiguredVariant = (
  attributes,
  product,
  placeholderVariant,
  configuratorValues
) => {
  const variant = JSON.parse(JSON.stringify(placeholderVariant))

  const attributesObject = {}
  for (let value of configuratorValues) {
    const attribute = attributes.find(a => a.id === value.attribute_id)
    const attributeValue = attribute.values.find(
      v => v.id === value.attribute_value_id
    )

    attributesObject[attribute.name] = attributeValue.name
  }

  variant.attributes = attributesObject

  variant.placeholder_variant = false
  variant.id = `${variant.sku}-${Object.values(variant.attributes).join('-')}`
  variant.sku = variant.id
  variant.__is_configured_variant = true
  variant.__configurator_values = configuratorValues
  variant.__placeholder_variant = placeholderVariant.id

  return variant
}

export const wrapRowsInBatchControl = (
  brand: ConnectedBrand,
  rows: Array<RowConfig>,
  batch: ?string,
  splittable: ?boolean
) => {
  if (!brand.settings.stock_settings.use_batch_control) {
    return rows
  }

  return [
    {
      // We create it as a group to make rowValuesSiblings work
      type: 'group',
      configs: rows,
      split_by: [
        {
          default_value: batch,
          property_label: 'Batch',
          property: 'batch',
          null_label: 'No batch',
          splittable,
        },
      ],
    },
  ]
}

export const wrapRowsInBatchControlAndInventoryLocations = (
  brand: ConnectedBrand,
  rows: Array<RowConfig>,
  batch: ?string,
  splittable: ?boolean,
  alwaysShowDefaultLocationFilter: ?boolean
) => {
  const splitBy = [
    {
      label: ({ data, tableData }) => {
        const inventoryLocation = data.inventory_location_id
          ? tableData.inventory_locations.data.find(
              location => location.id == data.inventory_location_id
            )
          : null

        return inventoryLocation ? inventoryLocation.name : 'Default location'
      },
      property_label: 'Location',
      property: 'inventory_location_id',
      null_label: 'Default location',
      splittable: true,
    },
  ]

  if (brand.settings.stock_settings.use_batch_control) {
    splitBy.push({
      default_value: batch,
      property_label: 'Batch',
      property: 'batch',
      null_label: 'No batch',
      splittable,
    })
  }

  const defaultInventoryLocationId = brand.inventory_locations.find(
    l => l.default
  ).id

  const group = [
    {
      // We create it as a group to make rowValuesSiblings work
      type: 'group',
      configs: rows,
      split_by: splitBy,
      show_label_filter: alwaysShowDefaultLocationFilter
        ? undefined
        : ({ subSections }) => {
            if (subSections.length > 1) {
              return true
            }

            let showLabel = false
            for (let subSection of subSections) {
              if (
                typeof subSection.subSectionSplitData.batch === 'string' &&
                subSection.subSectionSplitData.batch.length > 0
              ) {
                showLabel = true
                break
              }

              if (
                subSection.subSectionSplitData.inventory_location_id &&
                subSection.subSectionSplitData.inventory_location_id !=
                  defaultInventoryLocationId
              ) {
                showLabel = true
                break
              }
            }

            return showLabel
          },
    },
  ]

  return group
}
