/* @flow */

import React, { useMemo } from 'react'
import sortBy from 'lodash/sortBy'
import { SelectWrapper } from './Forms'

import { useCachedStates } from '../localization'

const StatesSelector = ({ selectedCountry, ...props }) => {
  const [states, { isFetching }] = useCachedStates()

  const options = useMemo(() => {
    if (!selectedCountry || !states[selectedCountry]) return []

    let options = sortBy(
      Object.entries(states[selectedCountry]).map(([key, value]) => ({
        value: key,
        label: value,
      })),
      'label'
    )

    return options
  }, [states, selectedCountry])

  return (
    <SelectWrapper
      isLoading={isFetching}
      placeholder="Select state ..."
      options={options}
      {...props}
    />
  )
}

export default StatesSelector
