/* @flow */

import React from 'react'
import styled from 'styled-components'
import {
  unstable_trace as trace,
  unstable_wrap as wrap,
} from 'scheduler/tracing'

import InputField from '../InputField'

type Props = {
  availableColli: number[],
  colliIncludedInValue?: boolean,
  defaultColli: number | null,
  enableColli?: boolean,
  id: string,
  onBlur: () => void,
  onChange: ({ quantity: number, colli: number | null }) => void,
  onLabelClick: () => void,
  onTab: () => void,
  right?: boolean,
  showField: boolean,
  value: number | '',
  valueColli: number | null,
}

const QuantityField = ({
  availableColli,
  colliIncludedInValue = false,
  defaultColli,
  enableColli = false,
  disabled = false,
  id,
  onBlur,
  onChange,
  onControlKey,
  onFocus: propsOnFocus,
  onLabelClick,
  right = false,
  showField,
  value,
  valueColli,
}: Props) => {
  const inputRef = React.useRef(null)

  let showValue = value
  if (
    enableColli &&
    colliIncludedInValue &&
    value !== '' &&
    value !== 0 &&
    valueColli !== null
  ) {
    showValue = value / valueColli
  }

  const onQuantityChangeHandler = React.useCallback(
    e => {
      const colli = valueColli ? valueColli : defaultColli

      let quantity = e.target.value
      if (enableColli && colliIncludedInValue && colli !== null) {
        quantity = quantity * colli
      }

      trace('Quantity change', performance.now(), () => {
        onChange({
          quantity,
          colli,
        })
      })
    },
    [
      availableColli,
      colliIncludedInValue,
      enableColli,
      onChange,
      valueColli,
      defaultColli,
    ]
  )
  const onColliChangeHandler = React.useCallback(
    e => {
      const colli = parseInt(e.target.value)

      let quantity = value
      if (enableColli && colliIncludedInValue && valueColli !== null) {
        quantity = (value / valueColli) * colli
      }

      onChange({
        quantity,
        colli,
      })
    },
    [enableColli, colliIncludedInValue, onChange, value, valueColli]
  )
  const wrappedFocus = React.useCallback(() => {
    if (inputRef.current) {
      // hack because number type input fields do not support selection
      inputRef.current.type = 'text'
      inputRef.current.setSelectionRange(0, inputRef.current.value.length)
      inputRef.current.type = 'number'
    }

    if (propsOnFocus) {
      propsOnFocus()
    }
  }, [propsOnFocus, inputRef])

  const colliOptions = React.useMemo(
    () =>
      availableColli.map(size => ({
        value: size,
        label: size === 1 ? '× 1 piece' : `× ${size}-pieces colli`,
      })),
    [availableColli]
  )

  const showColliSelect =
    enableColli === true &&
    (availableColli.length > 1 ||
      (availableColli.length === 1 && availableColli[0] !== 1))

  return (
    <Container>
      <InputFieldWrapper>
        <InputField
          disabled={disabled}
          onBlur={onBlur}
          onChange={onQuantityChangeHandler}
          onControlKey={onControlKey}
          onFocus={wrappedFocus}
          right={right}
          ref={inputRef}
          value={showValue}
        />
      </InputFieldWrapper>
      {showColliSelect && (
        <ColliSelectWrapper>
          <ColliSelect
            disabled={disabled}
            name={`${id}__colli-select`}
            id={`${id}__colli-select`}
            onChange={onColliChangeHandler}
            tabIndex="-1"
            value={valueColli}
          >
            {colliOptions.map(colliOption => (
              <option value={colliOption.value}>{colliOption.label}</option>
            ))}
          </ColliSelect>
          <ColliSelectedLabel>× {valueColli} pcs</ColliSelectedLabel>
        </ColliSelectWrapper>
      )}
    </Container>
  )
}

export default QuantityField

const Container = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
`

const InputFieldWrapper = styled.div`
  flex: 5 0 30px;
  min-height: 21px;
`

const SingleColliLabel = styled.div`
  flex: 1 0 50px;
  min-height: 11px;
  padding: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ColliSelectWrapper = styled.div`
  flex: 1 0 50px;
  min-height: 21px;
  position: relative;
`

const ColliSelect = styled.select`
  position: absolute;
  width: 100%;
  height: 100% !important;
  min-height: auto;
  background-color: white;
  background-image: none !important;
  border: none;
  border-radius: 0px;
  padding: 0 12px 0 3px;
  margin: 0;
  appearance: none; /* hide the default dropdown arrow */
  cursor: pointer;
  color: #6f7b8a;
  font-size: 11px;
  line-height: 1.5;

  /* use background image for custom arrow */
  background-image: linear-gradient(225deg, black 50%, transparent 50%),
    linear-gradient(315deg, transparent 50%, black 50%) !important;
  background-position: calc(100% - 7px) 50%, calc(100% - 3px) 50%;
  background-size: 4px 4px, 4px 4px;
  background-repeat: no-repeat;

  &:focus,
  /* ColliSelectedLabel div overlays part of the select, we need to change its bg color too */
  &:focus + div {
    background-color: #f4f5f7;
  }
`

const ColliSelectedLabel = styled.div`
  position: absolute;
  height: 100%;
  top: 0.5px;
  left: 3px;
  right: 12px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #6f7b8a;
  font-size: 11px;
  line-height: 1.5;
  white-space: nowrap;
  pointer-events: none; /* label needs to be click-through so the select can be opened */
  background-color: white;
`

const ResetColliContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 3px;
  background: white;
`

const ResetColliButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: #25a3ce;
  cursor: pointer;
  font-size: 11px;
  line-height: 1.2;
`
