/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import type { RowConfig } from '../../types'

type Options = {
  label?: string,
}

const ean_colli: (options: Options) => RowConfig = ({
  label = 'EAN Colli',
}) => ({
  data_source: '__product',
  label,
  render: ({ value }) => {
    if (!value) {
      return null
    }

    var ean_colli_lines = []
    value.forEach(ean_colli =>
      ean_colli_lines.push(
        <span>
          <span style={{ whiteSpace: 'nowrap' }}>{ean_colli}</span>
          {'\n'}
        </span>
      )
    )
    return ean_colli_lines
  },

  value: ({ product, variant }) => {
    if (!variant) {
      return null
    }

    var ean_collis = []

    if (variant.colli_ean) {
      const variantColli =
        (variant.colli !== null ? variant.colli : product.colli) || []

      for (let colliSize of variantColli) {
        if (variant.colli_ean[colliSize]) {
          ean_collis.push(colliSize + ': ' + variant.colli_ean[colliSize])
        }
      }
    }

    return ean_collis
  },
})

export default ean_colli
