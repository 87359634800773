import React from 'react'

const IframelyContent = ({ content }) => {
  const ref = React.useRef(null)

  React.useEffect(() => {
    if (!window.iframely || !ref.current) {
      return
    }

    const elements = [...ref.current.getElementsByTagName('oembed')]

    elements.forEach(element => {
      // Remove children
      element.innerHTML = ''

      // Load iframely
      iframely.load(element, element.getAttribute('url'))
    })
  }, [ref.current])

  return <div ref={ref} dangerouslySetInnerHTML={{ __html: content }} />
}

export default IframelyContent
