/* @flow */

import moment from 'moment'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import Datetime from 'react-datetime'
import React, { Component } from 'react'

import 'react-datetime/css/react-datetime.css'

const createMomentObjectIfDate = date => (!Boolean(date) ? null : moment(date))

class ReactDatePicker extends Component {
  onChange = (newDate: ?moment) => {
    const { dateFormat, timeFormat } = this.props

    let format = dateFormat
    if (timeFormat) {
      format += ' ' + timeFormat + ':ss'
    }

    this.props.onChange(
      newDate instanceof moment ? newDate.format(format) : null
    )
  }

  render() {
    const { disabled, placeholder, user, dateFormat, date, ...rest } =
      this.props

    return (
      <Datetime
        {...rest}
        onChange={this.onChange}
        inputProps={{ disabled, placeholder }}
        locale={user.locale}
        dateFormat={true}
        value={createMomentObjectIfDate(date)}
      />
    )
  }
}

const ConnectedReactDatePicker = connect(state => ({
  user: state.session.user,
}))(ReactDatePicker)

export default ConnectedReactDatePicker

ReactDatePicker.defaultProps = {
  date: null,
  dateFormat: 'YYYY-MM-DD',
  timeFormat: false,
  placeholder: 'Please pick a date',
  disabled: false,
  closeOnSelect: true,
}

export const RenderReactDatePickerInput = props => {
  const { meta, input, onChange, ...rest } = props

  const handleOnChange = newDate => {
    input.onChange(newDate)
    onChange(newDate)
  }

  return (
    <div>
      <ConnectedReactDatePicker
        date={input.value}
        onChange={handleOnChange}
        {...rest}
      />
      {meta.touched && meta.error && (
        <span className="error">{meta.error}</span>
      )}
    </div>
  )
}

RenderReactDatePickerInput.propTypes = {
  onChange: PropTypes.func,
}

RenderReactDatePickerInput.defaultProps = {
  onChange: () => {},
}

export const ReactDatePickerInput = props => {
  const { name, ...rest } = props

  return <Field name={name} component={RenderReactDatePickerInput} {...rest} />
}

ReactDatePickerInput.propTypes = {
  name: PropTypes.string.isRequired,
}

ReactDatePickerInput.defaultProps = {
  name: 'date',
}
