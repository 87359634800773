/* @flow */

import {
  getNews,
  getNewsNotification,
  getAllNewsNotifications,
  getUnreadNewsNotifications,
} from './api'
import { createApiHook, quickCreateHook } from '../hooks'

const {
  hook: useNews,
  hookCached: useCachedNews,
  clearCache: clearNewsCache,
} = quickCreateHook(getNews, 'news', [])
export { useNews, useCachedNews, clearNewsCache }

const {
  hook: useAllNewsNotifications,
  hookCached: useCachedAllNewsNotifications,
  clearCache: clearAllNewsNotificationsCache,
} = quickCreateHook(getAllNewsNotifications, 'news_notifications', [])

export {
  useAllNewsNotifications,
  useCachedAllNewsNotifications,
  clearAllNewsNotificationsCache,
}

const {
  hook: useUnreadNewsNotifications,
  hookCached: useCachedUnreadNewsNotifications,
  clearCache: clearUnreadNewsNotificationsCache,
} = quickCreateHook(getUnreadNewsNotifications, 'news_notifications', [])

export {
  useUnreadNewsNotifications,
  useCachedUnreadNewsNotifications,
  clearUnreadNewsNotificationsCache,
}

const {
  hook: useNewsNotification,
  hookCached: useCachedNewsNotification,
  clearCache: clearNewsNotificationCache,
} = quickCreateHook(getNewsNotification, 'news_notification', null)

export {
  useNewsNotification,
  useCachedNewsNotification,
  clearNewsNotificationCache,
}
