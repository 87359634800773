/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import type { RowConfig } from '../../types'

type Options = {
  label?: string,
}

const ean: (options: Options) => RowConfig = ({ label = 'EAN' }) => ({
  data_source: '__variant',
  key: 'ean',
  label,
})

export default ean
