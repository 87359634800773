/* @flow */

import * as React from 'react'
import styled from 'styled-components'

import { SaveButton } from '../../../infrastructure/components/Formik'
import { createWebshopButtonStyle, createStyledWebshopButton } from './shared'

import { WebshopContext } from '../shared'

const WebshopSaveButton = ({ color = 'white', ...props }) => {
  const { webshopDesignSettings } = React.useContext(WebshopContext)

  const combinedStyle = React.useMemo(() => {
    return createWebshopButtonStyle(color, webshopDesignSettings)
  }, [color, webshopDesignSettings])

  return <StyledSaveButton theme={combinedStyle} {...props} />
}

export default WebshopSaveButton

const StyledSaveButton = createStyledWebshopButton(SaveButton)
