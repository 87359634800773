/* @flow */

import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'

import Notifications from '../Notifications'
import { switchEntity } from '../../../actions'
import { regainAdmin, switchToPayingCustomer } from '../../../../admin/actions'

import NavigationRightSidePayingCustomers from './NavigationRightSidePayingCustomers'
import NavigationRightSideFreemiumCustomers from './NavigationRightSideFreemiumCustomers'
import NavigationRightSideUserProfile from './NavigationRightSideUserProfile'
import { SecondaryMenuHoverContainer } from './shared'

type NavigationRightSideStateProps = {
  adminEmail: ?string,
}

type NavigationRightSideProps = NavigationBarProps &
  NavigationRightSideStateProps

type NavigationRightSideState = {
  userEntities: Array<{ id: number, name: string, entity_type: string }>,
}

const NavigationRightSide = ({
  adminEmail,
  dispatch,
  entity,
  inWebshop,
  history,
  settingsPage,
  type,
  user,
  useNewColors,
}) => {
  const entityType = entity.entity_type

  const fireSwitchEntityAction = useCallback(
    entityId => dispatch(switchEntity(entityId, history)),
    [dispatch, history]
  )
  const fireSwitchToPayingCustomerAction = useCallback(
    entityId => dispatch(switchToPayingCustomer(history, entityId)),
    [dispatch, history]
  )
  const fireRegainAdminAction = useCallback(
    () => dispatch(regainAdmin(history)),
    [dispatch, history]
  )

  return (
    <Container type={type}>
      {(user.user_type === 'admin' || adminEmail) && (
        <>
          <SecondaryMenuContainer useNewColors={useNewColors}>
            <NavigationRightSideFreemiumCustomers
              inWebshop={inWebshop}
              onSwitchEntity={fireSwitchToPayingCustomerAction}
              sessionEntity={entity}
              type={type}
              useNewColors={useNewColors}
            />
          </SecondaryMenuContainer>

          <SecondaryMenuContainer useNewColors={useNewColors}>
            <NavigationRightSidePayingCustomers
              inWebshop={inWebshop}
              onSwitchEntity={fireSwitchToPayingCustomerAction}
              sessionEntity={entity}
              type={type}
              useNewColors={useNewColors}
            />
          </SecondaryMenuContainer>
        </>
      )}
      {entityType === 'brand' && !inWebshop && (
        <SecondaryMenuContainer useNewColors={useNewColors}>
          <SecondaryMenuHoverContainer useNewColors={useNewColors} type={type}>
            <Notifications />
          </SecondaryMenuHoverContainer>
        </SecondaryMenuContainer>
      )}
      <UserProfileContainer>
        <NavigationRightSideUserProfile
          adminEmail={adminEmail}
          history={history}
          inWebshop={inWebshop}
          onRegainAdmin={fireRegainAdminAction}
          onSwitchEntity={fireSwitchEntityAction}
          settingsPage={settingsPage}
          type={type}
          useNewColors={useNewColors}
        />
      </UserProfileContainer>
    </Container>
  )
}

const ConnectedNavigationRightSide = withRouter(
  connect((state): UserNavigationStateProps => ({
    adminEmail: state.session.adminEmail,
  }))(NavigationRightSide)
)

export default ConnectedNavigationRightSide

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: flex-end;
`

const SecondaryMenuContainer = styled.div`
  display: flex;
  flex-basis: 45px;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: ${({ useNewColors }) => (useNewColors ? '16px' : '18px')};
`

const UserProfileContainer = styled.div`
  margin-left: 15px;
`
