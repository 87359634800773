/* @flow */

import * as React from 'react'
import sortBy from 'lodash/sortBy'
import styled from 'styled-components'
import {
  DropdownButton as BootstrapDropdownButton,
  MenuItem,
} from 'react-bootstrap'

import { updateUser } from '../../../../../entities/api'
import { isCustomFieldVisible } from '../../../../../custom-fields/shared'

import { ProductTableContext } from '../../shared'
import {
  refreshSession,
  SessionContext,
  useRefValue,
  mixpanelTrack,
} from '../../../../../shared'

const createQuickToggleAction = ({
  key = 'quick_toggle',
  inputToggles = [],
  mixpanelEvent,
}) => {
  return {
    key,
    render: ({ brand, onTableControlsChange, tableId, tableControls }) => {
      return (
        <QuickToggleComponent
          brand={brand}
          inputToggles={inputToggles}
          mixpanelEvent={mixpanelEvent}
          onTableControlsChange={onTableControlsChange}
          tableControls={tableControls}
          tableId={tableId}
        />
      )
    },
  }
}

export default createQuickToggleAction

const QuickToggleComponent = ({
  brand,
  inputToggles,
  mixpanelEvent,
  onTableControlsChange,
  tableControls,
  tableId,
}) => {
  const { entity, user } = React.useContext(SessionContext)
  const { columns, rows, productDataConfig } =
    React.useContext(ProductTableContext)
  const tableControlsRefValue = useRefValue(tableControls)

  const { columnKeys, productDataKeys, rowKeys } = React.useMemo(() => {
    // The problem is we use mapped rows, columns and product data to determine
    // "what is visible". This is important because that means e.g. SKU will also
    // be shown as `activated` in the quick toggle if it's already added in the extra_rows
    // of the product table rows. The difficult part is that in product table settings,
    // and in here, we map custom fields as variant_meta_{custom_field_name}. However, when it's
    // processed in the setup part of the product table it's turned into an object with
    // {"data_source": "__product", "key": "custom_field_name"}. So when we `activate` a quick
    // toggle we need to use the variant_meta_{custom_field_name} as the key, but when we
    // check if something already exists we should use __variant_{custom_field_name}
    const prefixWithDataSource = input => {
      let key = input.key
      if (input.data_source === '__product') {
        key = `__product_${key}`
      } else if (input.data_source === '__variant') {
        key = `__variant_${key}`
      }

      return key
    }

    const columnKeys = columns.map(column => prefixWithDataSource(column))
    const rowKeys = rows.map(row => prefixWithDataSource(row))
    const productDataKeys = productDataConfig.map(config =>
      prefixWithDataSource(config)
    )

    return {
      columnKeys,
      productDataKeys,
      rowKeys,
    }
  }, [columns, rows, productDataConfig])

  const activeToggles = React.useMemo(() => {
    return [...columnKeys, ...productDataKeys, ...rowKeys]
  }, [columnKeys, productDataKeys, rowKeys])

  const allToggles = React.useMemo(() => {
    const customFields = (brand.custom_fields || []).filter(customField => {
      return isCustomFieldVisible(entity.entity_type === 'brand', customField)
    })

    const productCustomFields = []
    const variantCustomFields = []
    for (let customField of customFields) {
      if (customField.group_type === 'product') {
        productCustomFields.push({
          // the naming is a bit weird here. __product is from prefixWithDataSource which is needed
          // to make the selected keys match quick toggles and product table settings. The reason for
          // the extra `product_` is because custom_field row creators prefix the custom field name
          // with product_/variant_. So a row/product data configuration for a custom field is
          // named product_{custom_field_name}
          check_value: `__product_product_${customField.internal_name}`,
          value: `meta_product_${customField.internal_name}`,
          label: customField.label,
          type: 'product_data',
        })
      } else if (customField.group_type === 'variant') {
        variantCustomFields.push({
          check_value: `__variant_variant_${customField.internal_name}`,
          value: `meta_variant_${customField.internal_name}`,
          label: customField.label,
          type: 'row',
        })
      }
    }

    const allToggles = [
      { value: '__variant_ean', label: 'EAN', type: 'row' },
      { value: '__variant_sku', label: 'SKU', type: 'row' },
      { value: 'item_number', label: 'Item number', type: 'product_data' },
      { value: 'subbrand', label: 'Subbrand', type: 'product_data' },
      { value: 'collection', label: 'Collection', type: 'product_data' },
      ...productCustomFields,
      ...variantCustomFields,
      ...(inputToggles || []),
    ]

    return sortBy(allToggles, 'label')
  }, [brand, entity, inputToggles])

  const onToggle = React.useCallback(
    toggle => {
      let updatedQuickToggleSettingsOfTable = []
      if (
        tableControlsRefValue.current &&
        tableControlsRefValue.current.quickToggles
      ) {
        updatedQuickToggleSettingsOfTable = [
          ...tableControlsRefValue.current.quickToggles,
        ]
      }

      const existingIndex = updatedQuickToggleSettingsOfTable.findIndex(t => {
        return t.value === toggle.value && t.type === toggle.type
      })

      // Check big comment further up for explanation.
      // Active toggles are calculated from *mapped* rows, columns, etc.
      // So we have to check for the `product_` prefix here.
      const checkValue = toggle.check_value || toggle.value
      const newStatus = activeToggles.includes(checkValue) ? false : true

      if (existingIndex === -1) {
        updatedQuickToggleSettingsOfTable.push({
          value: toggle.value,
          type: toggle.type,
          show: newStatus,
        })
      } else {
        updatedQuickToggleSettingsOfTable.splice(existingIndex, 1)

        // this is a bit weird, but we do this so the order of which
        // the user activates a column matters (so they are basically sorting)
        updatedQuickToggleSettingsOfTable.push({
          value: toggle.value,
          type: toggle.type,
          show: newStatus,
        })
      }

      const newUserProductTableSettings = {
        [tableId]: {
          ...(user.product_table_settings[tableId] || {}),
          quick_toggles: updatedQuickToggleSettingsOfTable,
        },
      }

      onTableControlsChange({
        type: 'update_quick_toggles',
        data: updatedQuickToggleSettingsOfTable,
      })

      updateUser(user.id, {
        product_table_settings: newUserProductTableSettings,
      }).then(response => {
        if (!response.error) {
          refreshSession()
        }
      })

      if (mixpanelEvent) {
        mixpanelTrack(mixpanelEvent, {
          tableId,
          toggleValue: toggle.value,
          toggleType: toggle.type,
          show: newStatus,
        })
      }
    },
    [
      activeToggles,
      mixpanelEvent,
      onTableControlsChange,
      tableControlsRefValue,
      tableId,
      user,
    ]
  )

  const onReset = React.useCallback(() => {
    const updatedQuickToggleSettingsOfTable = []

    const newUserProductTableSettings = {
      [tableId]: {
        ...(user.product_table_settings[tableId] || {}),
        quick_toggles: updatedQuickToggleSettingsOfTable,
      },
    }

    onTableControlsChange({
      type: 'update_quick_toggles',
      data: updatedQuickToggleSettingsOfTable,
    })

    updateUser(user.id, {
      product_table_settings: newUserProductTableSettings,
    }).then(response => {
      if (!response.error) {
        refreshSession()
      }
    })
  }, [onTableControlsChange, tableId, user])

  return (
    <DropdownContainer>
      <BootstrapDropdownButton
        id={`${tableId}-quick-toggle`}
        title="Quick toggle"
        bsSize="sm"
        bsStyle="white"
      >
        {allToggles.map(toggle => {
          return (
            <DropDownOption
              key={toggle.value}
              onClick={onToggle}
              selected={activeToggles.includes(
                toggle.check_value || toggle.value
              )}
              toggle={toggle}
            />
          )
        })}

        <MenuItem divider />

        <DropDownOption
          onClick={onReset}
          toggle={{ label: 'Reset to default' }}
        />
      </BootstrapDropdownButton>
    </DropdownContainer>
  )
}

const DropdownContainer = styled.div`
  .dropdown-menu > li > a {
    padding: 3px 6px;
  }
`

const DropDownOption = ({ onClick, selected, toggle }) => {
  return (
    <li onClick={() => onClick(toggle)}>
      <a href="javascript:;">
        <DropdownButtonContainer>
          <Checkmark>
            {selected && <span className="glyphicon glyphicon-ok" />}
          </Checkmark>
          <ColumnName>{toggle.label}</ColumnName>
        </DropdownButtonContainer>
      </a>
    </li>
  )
}

const DropdownButtonContainer = styled.div`
  display: flex;
`

const Checkmark = styled.div`
  margin-right: 5px;
  width: 15px;
`

const ColumnName = styled.div`
  flex: 1;
`
