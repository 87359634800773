/* @flow */

import * as React from 'react'
import styled from 'styled-components'

import {
  VerticalAttributeSplitRow,
  createFieldId,
  fieldIdToString,
  createDefaultLineData,
  columnConfigToStyle,
  renderValue,
} from '../shared'

const VerticalAttributeColumnRender = ({
  brand,
  column,
  dataContext,
  defaultRowData,
  editColumn,
  horizontalAttributeValues,
  onColumnValueChange,
  onUpdateLines,
  product,
  rowGroups,
  splitsDispatcher,
  tableSectionData,
  variants,
}) => {
  const lines = React.useMemo(() => {
    const lines = []

    for (let rowGroup of rowGroups) {
      for (let subSection of rowGroup.subSections) {
        for (let row of subSection.rows) {
          for (let line of row.lines) {
            lines.push(line)
          }
        }
      }
    }

    return lines
  }, [rowGroups])

  const value = React.useMemo(() => {
    return column.value({ lines })
  }, [column, lines])

  let edit = false
  if (editColumn !== false && editColumn.columnKey === column.key) {
    edit = true
  }

  const renderData = {
    context: dataContext,
    editProperty: column.edit_property,
    editable: column.editable,
    edit: edit,
    horizontalAttributeValues,
    lines,
    onColumnValueChange: lineUpdate => {
      return onColumnValueChange(
        {},
        {},
        {
          createOnMissing: true,
          defaultRowData: createDefaultLineData(
            tableSectionData,
            defaultRowData,
            {
              splitKey: '__traede_no_split',
            },
            [],
            {}
          ),
          product,
          variants,
          lines: lines,
          type: 'update',
          updates: lineUpdate,
        }
      )
    },
    onEdit: editMode => {
      if (editMode) {
        splitsDispatcher({
          type: 'edit_column',
          row: {},
          column,
        })
      } else {
        splitsDispatcher({
          type: 'cancel_edit_column',
        })
      }
    },
    updateLines: onUpdateLines,
    rowValues: {},
  }

  return (
    <Container>
      <Label>{column.label}:</Label>
      <div>
        {column.render({
          ...renderData,
          brand,
          config: column,
          value,
        })}
      </div>
    </Container>
  )
}

export default VerticalAttributeColumnRender

const Container = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 2px;

  input[type='text'] {
    min-height: 25px !important;
  }
`

const Label = styled.div`
  font-weight: bold;
  margin-right: 5px;
`
