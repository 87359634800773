import { CALL_API } from '../../../infrastructure/middleware/api'

export const GET_PAYMENT_TERMS_REQUEST = 'GET_PAYMENT_TERMS_REQUEST'
export const GET_PAYMENT_TERMS_SUCCESS = 'GET_PAYMENT_TERMS_SUCCESS'
export const GET_PAYMENT_TERMS_FAILURE = 'GET_PAYMENT_TERMS_FAILURE'
export const getPaymentTerms = ({ context = undefined } = {}) => ({
  [CALL_API]: {
    types: [
      GET_PAYMENT_TERMS_REQUEST,
      GET_PAYMENT_TERMS_SUCCESS,
      GET_PAYMENT_TERMS_FAILURE,
    ],
    method: 'GET',
    endpoint: '/payment-terms',
    query: {
      context,
    },
  },
})

export const shouldFetchPaymentTerms = ({ context = undefined } = {}) => {
  return (dispatch, getState) => {
    const { misc } = getState()

    if (
      misc.payment_terms.items.length === 0 &&
      !misc.payment_terms.isFetching
    ) {
      dispatch(getPaymentTerms({ context }))
    }
  }
}
