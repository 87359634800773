/* @flow */

import React from 'react'
import styled from 'styled-components'

import {
  EU_VAT_VALIDATION_FORMAT_INVALID,
  EU_VAT_VALIDATION_INVALID,
  EU_VAT_VALIDATION_VALID,
} from '../shared'

type Props = {
  status: null | string,
  size?: 'sm',
}

const EuVatValidationLabel = ({ size, status, ...rest }: Props) => {
  let classes = 'label '
  let label = 'Not validated yet'
  let tooltip
  switch (status) {
    case EU_VAT_VALIDATION_INVALID:
      classes += 'label-danger'
      label = 'Vat not valid'
      break
    case EU_VAT_VALIDATION_FORMAT_INVALID:
      classes += 'label-danger'
      label = 'Invalid format'
      break
    case EU_VAT_VALIDATION_VALID:
      classes += 'label-green-new'
      label = 'Valid'
      break
  }

  if (size) {
    classes += ` label-${size}`
  }

  return (
    <span className={classes} {...rest}>
      {label}
    </span>
  )
}

export default EuVatValidationLabel
