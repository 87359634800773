/* @flow */

import * as React from 'react'
import DropDeliveryDate from '../../../../../orders/components/DropDeliveryDate'

const createDeliveryLinesTableSections = overrides => {
  return {
    always_show_header: true,
    hide_if_no_lines: true,
    data_source: [
      // AVAILABILITY
      // B2B webshop the delivery status will return many drops with the same availability.
      // We want to make sure that availability is correctly divided into the different
      // table sections. A product can be available in both SS 25 January delivery and
      // SS 25 Februrary delivery. If we do not divide the availability by the drop key,
      // then all delivery keys will receive all the availability.
      //
      // DELIVERY STATUS
      // Similarly delivery status is also calculated per drop_key. E.g. if a customer has bought
      // the same variant in SS25 January and SS25 February, then we should show the correct
      // delivery status for each drop_key
      {
        source: 'availability',
        property: 'drop_key',
      },
      {
        source: 'delivery_status',
        property: 'drop_key',
      },
    ],
    // when we create new lines through split we need the drop delivery ID and drop id to be present o
    // on the line so that they are passed to the API
    extra_data: ['drop_id', 'drop_delivery_id'],
    lines: {
      property: 'drop_key',
    },
    label: ({ data, tableData, value }) => {
      if (!tableData.delivery_lines) {
        return null
      }

      const deliveryLines = tableData.delivery_lines.data || []

      if (!value) {
        return null
      }

      const deliveryLine = deliveryLines.find(d => d.drop_key == value)

      if (!deliveryLine) {
        return null
      }

      return (
        <span>
          {deliveryLine.label}
          <DropDeliveryDate
            confirmed={deliveryLine.delivery_date_confirmed}
            fromDate={deliveryLine.delivery_date_from}
            prefix={` - `}
            toDate={deliveryLine.delivery_date_to}
            type={deliveryLine.delivery_date_type}
          />
        </span>
      )
    },
    sort: ({ tableSectionData, ...rest }, dataCacheTableDrops) => {
      if (!dataCacheTableDrops.delivery_lines) {
        return -1
      }

      const deliveryLines = dataCacheTableDrops.delivery_lines.data || []

      if (!tableSectionData.drop_key) {
        return -1
      }

      const index = deliveryLines.findIndex(
        d => d.drop_key == tableSectionData.drop_key
      )

      return index
    },
    ...(overrides || {}),
  }
}

export default createDeliveryLinesTableSections
