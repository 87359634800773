// @flow

import * as React from 'react'
import styled from 'styled-components'

import { ProductTableContext } from '../../../shared'
import { flattenRows, VerticalAttributeSplitRow } from '../../shared'

type Props = {
  id: string,
  noActionColumns: number,
}

const TraditionalVariantsFooterRow = ({ id, noActionColumns }: Props) => {
  const context = React.useContext(ProductTableContext)
  const { columns, productImage, variantImages, rows } = context

  const [flattenedRows] = React.useMemo(() => flattenRows(rows), [rows])

  const imageColumn = productImage === true || variantImages === true ? 1 : 0

  return (
    <VerticalAttributeSplitRow key={id}>
      <td
        colSpan={
          1 +
          imageColumn +
          columns.length +
          flattenedRows.length +
          noActionColumns
        }
      />
    </VerticalAttributeSplitRow>
  )
}

export default TraditionalVariantsFooterRow
