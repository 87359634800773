/* @flow */

import * as React from 'react'
import { PureComponent } from 'react'
import { FastField, Field, type FieldProps } from 'formik'

const DateControl = React.lazy<Object>(() => import('../DateControl'))

import { ErrorLabel } from '../Forms'

type Props = {
  dateFormat?: string,
  formikFastField?: boolean,
}

const DateInput = React.forwardRef((props: Props, ref) => {
  const { formikFastField, ...restProps } = props

  // FastField re-renders only when the value of field changes and on few other occassions
  // limiting the number of re-renders. Useful when form starts to slow down because of lot
  // of fields, but can block other needed renders, be careful when using it.
  const FieldComponent = formikFastField === true ? FastField : Field

  return (
    <FieldComponent innerRef={ref} component={renderDateInput} {...restProps} />
  )
})

export default DateInput

export const renderDateInput = ({
  field: { onBlur, name, value },
  form: { touched, errors, setFieldValue },
  innerRef,
  ...rest
}: FieldProps & Props) => {
  const onChange = React.useCallback(
    newDate => setFieldValue(name, newDate),
    [name, setFieldValue]
  )

  return (
    <div>
      <React.Suspense fallback={<div>Loading...</div>}>
        <DateControl
          ref={innerRef}
          onChange={onChange}
          value={value}
          {...rest}
        />
      </React.Suspense>

      {touched[name] && errors[name] && <ErrorLabel>{errors[name]}</ErrorLabel>}
    </div>
  )
}
