/* @flow */

import React from 'react'
import { connect } from 'react-redux'
import { createAction } from '../shared'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { List, Map } from 'immutable'
import memo from 'memoize-one'

import ProductAdder from '../../../../orders/components/ProductAdder'
import ProductAdderCached from '../../../../orders/components/ProductAdderCached'
import { addLinesToOrder } from '../../../../orders/actions'
import { toLinesWithoutColli } from '../../../../orders/components/shared'
import {
  SaveButton,
  FormControl,
  FormGroup,
  InputGroup,
  Button,
  ControlLabel,
} from '../../../../../infrastructure/components/Forms'
import ActionButton from '../../../../../infrastructure/components/ActionButton'
import msg from '../../../../../infrastructure/modules/msg'
import { PermissionContext } from '../../../../../infrastructure/components/Authorization'
import { getProducts } from '../../../../shop/actions/shop'
import { SessionContext } from '../../../../shared'
import ProductCache from '../../../../products/components/ProductCache'
import {
  IsNotB2COrderPredicate,
  CanWriteProductsPredicate,
  IsBrandPredicate,
  OpenOrderPredicate,
  UnlockedOrderPredicate,
} from './shared'

import type {
  Dispatch,
  Id,
  Order,
  Product,
  Quantities,
} from '../../../../types'

type Props = {
  context: {
    order: Order,
  },
  callback: (quantities: Object, lines: Array<Object>) => void,
  dispatch: Dispatch,
}

type State = {
  availableProducts: Array<Product>,
  customDiscountRate: number,
  discount: 'customer' | 'custom' | 'no_discount',
  isFetchingProducts: boolean,
  quantities: Quantities,
  selectedProducts: List<Product>,
  showModal: boolean,
  showQuantities: boolean,
}

class ProductAdderComponent extends React.Component<Props, State> {
  static contextType = SessionContext

  state = {
    availableProducts: [],
    customDiscountRate: 0,
    discount: 'customer',
    isFetchingProducts: false,
    productAdderProducts: [],
    products: [],
    quantities: Map(),
    selectedProducts: List(),
    showModal: false,
    showQuantities: false,
  }

  onProductAdderProductChange = productIds => {
    this.setState({
      productAdderProducts: productIds,
    })
  }

  onQuantityChange = quantities => {
    this.setState({
      quantities,
    })
  }

  onShowQuantitiesChange = showQuantities => {
    this.setState({
      showQuantities,
    })
  }

  save = () => {
    const { customDiscountRate, discount } = this.state
    const orderId = this.props.context.order.id

    const quantities = this.state.quantities
    const lines = toLinesWithoutColli(quantities)

    return this.props
      .dispatch(addLinesToOrder(orderId, lines, discount, customDiscountRate))
      .then(response => {
        if (!response.error) {
          // Toggle modal will reset the object
          this.props.callback(
            JSON.parse(JSON.stringify(quantities)),
            response.payload
          )

          this.toggleModal()
          msg('success', 'The products have been added to the order.')
          // tell the viewmodel products were added so products can be refreshed
        }
      })
  }

  toggleModal = () => {
    const newState = !this.state.showModal

    const changes: $Shape<State> = {
      // reset the discount thing
      customDiscountRate: 0,
      discount: 'customer',
      quantities: Map(),
      showModal: newState,
    }

    this.setState(changes, () => {
      if (this.state.showModal === true) {
        this._getProducts()
      }
    })
  }

  render() {
    const {
      context: {
        order: { currency, customer, drop_id },
      },
    } = this.props
    const {
      availableProducts,
      customDiscountRate,
      discount,
      isFetchingProducts,
      productAdderProducts,
      quantities,
      selectedProducts,
      showModal,
      showQuantities,
    } = this.state
    const { user } = this.context

    return (
      <div>
        <button className="btn btn-sm btn-white" onClick={this.toggleModal}>
          <span>
            <span className="glyphicon glyphicon-plus" /> Add Products
          </span>
        </button>
        <Modal
          show={showModal}
          onHide={this.toggleModal}
          dialogClassName="xl-modal"
        >
          <Modal.Body>
            {user.feature_flags.new_order_cached !== true && (
              <ProductAdder
                customerId={this.props.context.order.customer_id}
                currency={currency}
                dropId={drop_id}
                headline="Products"
                isProductsLoading={isFetchingProducts}
                quantities={quantities}
                onShowQuantitiesChange={this.onShowQuantitiesChange}
                onQuantityChange={this.onQuantityChange}
                products={availableProducts}
                selectedProducts={selectedProducts}
                showQuantities={showQuantities}
                style={{ marginTop: 30 }}
              />
            )}
            {user.feature_flags.new_order_cached === true && (
              <ProductCache
                brandId={this.props.context.order.brand_id}
                mode="shop"
                onLoad={this._productsWereLoaded}
                productIds={createProductCacheProductIds(productAdderProducts)}
                renderData={this.state}
                render={this._renderProductCache}
              />
            )}
            <PermissionContext permission="orders:discounts:percentage">
              <DiscountOptionsContainer>
                <div>
                  <ControlLabel>
                    The products will be discounted using
                  </ControlLabel>
                  <DiscountOption>
                    <div className="radio radio-success">
                      <input
                        type="radio"
                        id="orders_add_products_customer_discount"
                        onChange={this._changeDiscountMethod}
                        checked={discount === 'customer'}
                        value="customer"
                      />
                      <label htmlFor="orders_add_products_customer_discount">
                        The customer's discount rate{' '}
                        {customer && (
                          <span>({customer.discount_percentage}%)</span>
                        )}
                      </label>
                    </div>
                  </DiscountOption>
                  <DiscountOption>
                    <div className="radio radio-success">
                      <input
                        type="radio"
                        id="orders_add_products_custom_discount"
                        onChange={this._changeDiscountMethod}
                        checked={discount === 'custom'}
                        value="custom"
                      />
                      <label htmlFor="orders_add_products_custom_discount">
                        <DiscountCustomOptionContainer>
                          <div>A custom rate:</div>
                          <div style={{ paddingLeft: 10, width: 100 }}>
                            <InputGroup>
                              <FormControl
                                bsSize="sm"
                                onChange={this._onCustomDiscountRateChange}
                                value={customDiscountRate}
                              />
                              <InputGroup.Addon>%</InputGroup.Addon>
                            </InputGroup>
                          </div>
                        </DiscountCustomOptionContainer>
                      </label>
                    </div>
                  </DiscountOption>
                  <DiscountOption>
                    <div className="radio radio-success">
                      <input
                        type="radio"
                        id="orders_add_products_no_discount_discount"
                        onChange={this._changeDiscountMethod}
                        checked={discount === 'no_discount'}
                        value="no_discount"
                      />
                      <label htmlFor="orders_add_products_no_discount_discount">
                        No discount
                      </label>
                    </div>
                  </DiscountOption>
                </div>
              </DiscountOptionsContainer>
            </PermissionContext>
          </Modal.Body>
          <Modal.Footer>
            <ActionButton
              className="btn pull-right btn-primary"
              onClick={this.save}
              style={{ marginLeft: 15 }}
            >
              Add Products to Order!
            </ActionButton>
            <Button className="pull-right" onClick={this.toggleModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }

  _changeDiscountMethod = e => {
    this.setState({
      discount: e.target.value,
    })
  }

  _onCustomDiscountRateChange = e => {
    this.setState({
      customDiscountRate: e.target.value,
    })
  }

  _getProducts = () => {
    const { order } = this.props.context
    const { user } = this.context

    this.setState({
      isFetchingProducts: true,
    })

    this.props
      .dispatch(
        getProducts(order.brand_id, {
          currency: order.currency,
          customer_id: order.customer_id,
          drop_id: order.drop_id,
          no_variants:
            user.feature_flags.new_order_cached === true ? 1 : undefined,
        })
      )
      .then(response => {
        const changes: $Shape<State> = {
          isFetchingProducts: false,
        }

        if (!response.error) {
          changes.availableProducts = response.payload.products
        }

        this.setState(changes)
      })
  }

  _productsWereLoaded = ({ products, productCache, variantCache }) => {
    this.setState({
      products: products,
    })
  }

  _renderProductCache = ({}) => {
    const {
      availableProducts,
      customDiscountRate,
      discount,
      isFetchingProducts,
      products,
      quantities,
      productAdderProducts,
      showModal,
      showQuantities,
    } = this.state

    return (
      <ProductAdderCached
        availableProducts={availableProducts}
        customerId={this.props.context.order.customer_id}
        currency={this.props.context.order.currency}
        headline="Products"
        isProductsLoading={isFetchingProducts}
        quantities={quantities}
        loadedProducts={products}
        onProductChange={this.onProductAdderProductChange}
        onQuantityChange={this.onQuantityChange}
        onShowQuantitiesChange={this.onShowQuantitiesChange}
        selectedProducts={productAdderProducts}
        showQuantities={productAdderProducts.length > 0}
        tableKey="new_order"
      />
    )
  }
}

const createProductCacheProductIds = memo(selectedProductAdderProducts => {
  return selectedProductAdderProducts.map(p => p.id)
})

const createProductAdderAction = (extraProps?: Object = {}) => {
  return createAction(
    (quantities, response, instance) => {
      return instance.emitProductAdded(quantities, response)
    },
    connect()(ProductAdderComponent),
    [
      IsBrandPredicate,
      IsNotB2COrderPredicate,
      CanWriteProductsPredicate,
      OpenOrderPredicate,
      UnlockedOrderPredicate,
    ],
    extraProps
  )
}

export default createProductAdderAction

const DiscountOptionsContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

const DiscountOption = styled.div``

const DiscountCustomOptionContainer = styled.div`
  align-items: center;
  display: flex;
`
