/* @flow */

import unit_price from '../../products/components/ProductTable2/configuration/columns/unit_price'
import { linePriceExtractor } from './shared'

// we save this as file so we can use it as a configuration in documents

const production_order_unit_price_column = options => {
  return unit_price({
    ...options,
    linePriceExtractor,
    valueToNewLine: columnValue => {
      return {
        // PO lines do not have price_before_discount, however we need it for the unit price column component
        // When splitting a line from an existing line price_before_discount will be equal to the existing lines price.
        // We need to transfer this to the newly created line to avoid creating 3 lines instead of 2
        price_before_discount: columnValue.price_before_discount,
        unit_price: columnValue.net_price,
        discount_method: columnValue.discount_method,
      }
    },
  })
}

export default production_order_unit_price_column
