/* @flow */

import type { Id } from '../../types'

import api from '../../api'

export const search = (axiosOptions, query: string) =>
  api.post('/search-v2', {
    body: {
      query,
    },
    ...axiosOptions,
  })

export const pushRecentSelection = (type: string, id: Id | string) =>
  api.post('/search/push-recent-selection', {
    body: {
      type,
      id,
    },
  })
