/* @flow */

import React, { useMemo } from 'react'
import Select from 'react-select'
import sortBy from 'lodash/sortBy'

import { useCachedOrderChannels } from '../hooks'

const OrderChannelSelector = ({
  excludeOptions,
  includeInactive = false,
  ...props
}) => {
  const [orderChannels, { isFetching }] = useCachedOrderChannels()

  const options = useMemo(() => {
    let options = sortBy(orderChannels, 'label')

    if (excludeOptions) {
      options = options.filter(
        subbrand => !excludeOptions.includes(subbrand.id)
      )
    }

    if (!includeInactive) {
      options = options.filter(drop => !drop.archived)
    }

    return options
  }, [orderChannels, includeInactive, excludeOptions])

  return (
    <Select
      isLoading={isFetching}
      labelKey="label"
      placeholder="Select order channel..."
      options={options}
      valueKey="id"
      {...props}
    />
  )
}

export default OrderChannelSelector
