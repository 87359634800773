/* @flow */

import * as React from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import { Formik } from 'formik'

import {
  ControlLabel,
  FormGroup,
  FormInput,
  SaveButton,
} from '../../../../../../infrastructure/components/Formik'

const warehouse_location = ({ editable, rows }) => {
  return {
    aggregate_product: false,
    editable: true,
    key: 'warehouse_location',
    label: 'Warehouse location',
    rows: rows,
    split_by_values: true,
    render: ({ edit, onEdit, onColumnValueChange, lines, value }) => {
      let parsedValue = ''
      if (value && value[0]) {
        parsedValue =
          value[0].warehouse_location_adjusted ||
          value[0].warehouse_location ||
          ''
      }

      if (!editable) {
        return parsedValue
      }

      return (
        <EditableWarehouseLocation
          currentLocation={parsedValue}
          edit={edit}
          onChange={onColumnValueChange}
          onEdit={onEdit}
          lines={lines}
        />
      )
    },
    value: ({ line }) => {
      if (!line) {
        return []
      }

      return [
        {
          warehouse_location: line.warehouse_location,
          warehouse_location_adjusted: line.warehouse_location_adjusted,
        },
      ]
    },
  }
}

export default warehouse_location

export const EditWarehouseLocationContainer = styled.div`
  display: flex;
`

const EditWarehouseLocationButton = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: none;
  font-size: 10px;
  outline: 0;
`

const EditableWarehouseLocation = ({
  currentLocation,
  edit,
  onChange,
  onEdit,
  lines,
}) => {
  const onSave = React.useCallback(
    (values, { setSubmitting }) => {
      onChange({
        warehouse_location: null,
        warehouse_location_adjusted: values.new_location,
      })

      setSubmitting(false)
    },
    [onChange]
  )

  return (
    <>
      {edit === true && (
        <EditWarehouseLocationModal
          currentLocation={currentLocation}
          onHide={() => onEdit(false)}
          onSave={onSave}
          show={edit === true}
        />
      )}

      <EditWarehouseLocationContainer>
        <EditWarehouseLocationButton onClick={() => onEdit(true)}>
          <span className="glyphicon glyphicon-pencil" />
        </EditWarehouseLocationButton>

        {currentLocation}
      </EditWarehouseLocationContainer>
    </>
  )
}

const EditWarehouseLocationModal = ({
  currentLocation,
  onHide,
  onSave,
  show,
}: {
  onHide: Function,
  onSave: Function,
  show: boolean,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Formik
        initialValues={{
          new_location: currentLocation,
        }}
        onSubmit={onSave}
        render={({ handleSubmit, isSubmitting }) => {
          const wrappedSubmit = e => {
            e.stopPropagation()
            handleSubmit(e)
          }

          return (
            <form onSubmit={wrappedSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Change location</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-xs-6">
                    <FormGroup>
                      <ControlLabel required>New location</ControlLabel>
                      <FormInput markOnFocus name="new_location" />
                    </FormGroup>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={onHide}
                >
                  Cancel
                </button>
                <SaveButton submitting={isSubmitting}>Save</SaveButton>
              </Modal.Footer>
            </form>
          )
        }}
      />
    </Modal>
  )
}
