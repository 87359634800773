import { CALL_API } from '../../../infrastructure/middleware/api'

export const GET_RESOURCES_REQUEST = 'GET_RESOURCES_REQUEST'
export const GET_RESOURCES_SUCCESS = 'GET_RESOURCES_SUCCESS'
export const GET_RESOURCES_FAILURE = 'GET_RESOURCES_FAILURE'
export const getResources = data => ({
  [CALL_API]: {
    endpoint: '/resources',
    method: 'GET',
    query: data,
    types: [
      GET_RESOURCES_REQUEST,
      GET_RESOURCES_SUCCESS,
      GET_RESOURCES_FAILURE,
    ],
  },
})

export const CREATE_RESOURCES_REQUEST = 'CREATE_RESOURCES_REQUEST'
export const CREATE_RESOURCES_SUCCESS = 'CREATE_RESOURCES_SUCCESS'
export const CREATE_RESOURCES_FAILURE = 'CREATE_RESOURCES_FAILURE'
export const createResources = (files, path) => ({
  [CALL_API]: {
    endpoint: '/resources',
    method: 'POST',
    body: { files, path },
    types: [
      CREATE_RESOURCES_REQUEST,
      CREATE_RESOURCES_SUCCESS,
      CREATE_RESOURCES_FAILURE,
    ],
  },
})

export const UPDATE_RESOURCES_REQUEST = 'UPDATE_RESOURCES_REQUEST'
export const UPDATE_RESOURCES_SUCCESS = 'UPDATE_RESOURCES_SUCCESS'
export const UPDATE_RESOURCES_FAILURE = 'UPDATE_RESOURCES_FAILURE'
export const updateResources = resources => ({
  [CALL_API]: {
    endpoint: '/resources',
    method: 'PUT',
    body: { resources },
    types: [
      UPDATE_RESOURCES_REQUEST,
      {
        type: UPDATE_RESOURCES_SUCCESS,
        meta: {
          resources,
        },
      },
      UPDATE_RESOURCES_FAILURE,
    ],
  },
})

export const DELETE_RESOURCES_REQUEST = 'DELETE_RESOURCES_REQUEST'
export const DELETE_RESOURCES_SUCCESS = 'DELETE_RESOURCES_SUCCESS'
export const DELETE_RESOURCES_FAILURE = 'DELETE_RESOURCES_FAILURE'
export const deleteResources = resources => ({
  [CALL_API]: {
    endpoint: '/resources',
    method: 'DELETE',
    body: { resources },
    types: [
      DELETE_RESOURCES_REQUEST,
      {
        type: DELETE_RESOURCES_SUCCESS,
        meta: {
          resources,
        },
      },
      DELETE_RESOURCES_FAILURE,
    ],
  },
})

export const MOVE_RESOURCES_REQUEST = 'MOVE_RESOURCES_REQUEST'
export const MOVE_RESOURCES_SUCCESS = 'MOVE_RESOURCES_SUCCESS'
export const MOVE_RESOURCES_FAILURE = 'MOVE_RESOURCES_FAILURE'
export const moveResources = (ids, target) => ({
  [CALL_API]: {
    endpoint: '/resources/move',
    method: 'POST',
    body: { resources: ids, move_to: target },
    types: [
      MOVE_RESOURCES_REQUEST,
      MOVE_RESOURCES_SUCCESS,
      MOVE_RESOURCES_FAILURE,
    ],
  },
})

export const DOWNLOAD_RESOURCES_REQUEST = 'DOWNLOAD_RESOURCES_REQUEST'
export const DOWNLOAD_RESOURCES_SUCCESS = 'DOWNLOAD_RESOURCES_SUCCESS'
export const DOWNLOAD_RESOURCES_FAILURE = 'DOWNLOAD_RESOURCES_FAILURE'
export const downloadResources = resources => ({
  [CALL_API]: {
    endpoint: '/resources/download',
    method: 'POST',
    body: { resources },
    types: [
      DOWNLOAD_RESOURCES_REQUEST,
      DOWNLOAD_RESOURCES_SUCCESS,
      DOWNLOAD_RESOURCES_FAILURE,
    ],
  },
})

export const shouldFetchFolders = (options, forceFetch = false) => (
  dispatch,
  getState
) => {
  const { filebank } = getState()

  const fetch = () =>
    dispatch(getResources(options)).then(response => {
      if (!response.error) {
        dispatch(foldersFetched(response.payload))
      }
    })

  if (forceFetch) {
    return fetch()
  }

  if (Object.keys(filebank.folders.items).length) {
    return new Promise(resolve => resolve())
  } else {
    return fetch()
  }
}

export const FOLDERS_FETCHED = 'FOLDERS_FETCHED'
export const foldersFetched = folders => ({
  type: FOLDERS_FETCHED,
  folders,
})

export const FOLDER_CREATED = 'FOLDER_CREATED'
export const folderCreated = folder => ({
  type: FOLDER_CREATED,
  folder,
})
