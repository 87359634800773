import '@babel/polyfill'
import 'whatwg-fetch'

import ReactBreakpoints from 'react-breakpoints'
import { Installer as RavenInstaller } from '../infrastructure/exceptions/raven'
import { setupIframely } from '../infrastructure/actions/iframely'
RavenInstaller()

import LogRocket from 'logrocket'
import mixpanel from 'mixpanel-browser'
import querystring from 'qs'
import styles from 'styles/style.less'
import Raven from 'raven-js'

const urlQuery = querystring.parse(window.location.search.slice(1))
const isPdfGeneration = urlQuery.traede_pdf_generation !== undefined

if (!isPdfGeneration) {
  if (process.env.NODE_ENV === 'production' && process.env.LOG_ROCKET_CLIENT) {
    LogRocket.init(process.env.LOG_ROCKET_CLIENT)
  }
  if (process.env.NODE_ENV === 'production' && process.env.MIXPANEL_TOKEN) {
    mixpanel.init(process.env.MIXPANEL_TOKEN)
  }
}

import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { UserAuthWrapper } from 'redux-auth-wrapper'
import configureStore from '../infrastructure/store'
import { setStore } from '../infrastructure/modules/msg'
import { setReduxStore } from '../infrastructure/api'
import { setStore as setSharedStore } from './shared'

const initialState = {}
export const store = configureStore(initialState)

setReduxStore(store)
setStore(store)
setSharedStore(store)

import {
  ACCESS_TOKEN_STORAGE_KEY,
  ADMIN_EMAIL_STORAGE_KEY,
  refreshSession,
  setAccessToken,
  setAdminEmail,
  setSession,
} from '../infrastructure/actions/session'
import { resetState } from './rootReducer'
import Globalize from 'globalize'
import localStorage from '../infrastructure/modules/localStorage'
import cloudinary from '../infrastructure/providers/cloudinary'
import Routes from './routes'
import utilities from '../infrastructure/utilities'

cloudinary(store)

/*
 We move localStorage retrieval out of reducers because it will hinder the
 logout function from completely resetting the state. This is because when we reset the state
 on logout the "default" data of the reducers will still be set to the localStorage
 data retrieved on app boot.
 */
let tokenCorrupt = false
let hasToken = false
const token = JSON.parse(localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY))

if (urlQuery.access_token) {
  store.dispatch(setAccessToken(urlQuery.access_token, '1234'))
  hasToken = true
} else if (token && token.token && token.expiration) {
  hasToken = true
  store.dispatch(setAccessToken(token.token, token.expiration))
}

const adminEmail = JSON.parse(localStorage.getItem(ADMIN_EMAIL_STORAGE_KEY))
if (adminEmail) {
  store.dispatch(setAdminEmail(adminEmail))
}

/**
 * We clear the session cache for each deployment. Since removing session from localStorage will also mean
 * the user is authenticated, the user will be redirected to the login screen. To prevent this, we ensure
 * the user state is refreshed before rendering the application.
 */
const initialize = () => {
  try {
    localStorage.setItem('version', JSON.stringify(process.env.VERSION))
  } catch (e) {}

  const breakpoints = {
    mobile: 320,
    tablet: 768,
    desktop: 1200,
    desktopLarge: 1500,
    desktopWide: 1920,
  }

  const render = () =>
    ReactDOM.render(
      <ReactBreakpoints breakpoints={breakpoints}>
        <Provider store={store}>
          <BrowserRouter>
            <React.StrictMode>
              <Routes />
            </React.StrictMode>
          </BrowserRouter>
        </Provider>
      </ReactBreakpoints>,
      document.getElementById('root')
    )

  Raven.context(() => {
    if (hasToken) {
      store.dispatch(refreshSession()).then(render).catch(render)
    } else {
      render()
    }
  })
}

// A token key exists, however the data is not complete. We reset the localStorage and force the user
// to login once more.
if (tokenCorrupt) {
  localStorage.clear()
  store.dispatch(resetState())
  initialize()
  // Everything is fine. Token is OK. Session is OK. We initialize. This is also run the first time
  // the user visits the site (localStorage) is empty.
} else {
  initialize()
}

if (!isPdfGeneration) {
  setupIframely(process.env.IFRAMELY_KEY)
}
