/* @flow */

import * as React from 'react'
import { PureComponent } from 'react'
import { Field, type FieldProps } from 'formik'
import { FormControl, InputGroup } from 'react-bootstrap'
import { ErrorLabel } from '../Forms'
import get from 'lodash/get'

type Props = {
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void,
  onChange: (value: string) => void,
  value: string,
}

const TextareaInput = (props: {
  ...$Diff<Props, { onChange: (value: string) => void }>,
  onChange?: (value: string) => void,
}) => {
  return <Field component={renderTextareaInput} {...props} />
}

export default TextareaInput

export const renderTextareaInput = ({
  field: { onBlur, name, onChange, value },
  form: { setFieldValue, touched, errors },
  ...props
}: FieldProps & Props) => {
  return (
    <div>
      <textarea
        onChange={e => setFieldValue(name, e.target.value)}
        // If value changes from a string to null or undefined then the value won't unset
        // (e.g. when changing from one order to the next)
        // ref: https://github.com/facebook/react/issues/2533
        value={value === null || value === undefined ? '' : value}
        {...props}
      />

      {get(touched, name) && get(errors, name) && (
        <ErrorLabel>{get(errors, name)}</ErrorLabel>
      )}
    </div>
  )
}
