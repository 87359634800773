import { CALL_API } from '../../../infrastructure/middleware/api'

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST'
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS'
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE'
export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE'
export const SAVE_CATEGORY_REQUEST = 'SAVE_CATEGORY_REQUEST'
export const SAVE_CATEGORY_SUCCESS = 'SAVE_CATEGORY_SUCCESS'
export const SAVE_CATEGORY_FAILURE = 'SAVE_CATEGORY_FAILURE'
export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE'

export const MODIFY_SUBCATEGORY_REQUEST = 'MODIFY_SUBCATEGORY_REQUEST'
export const MODIFY_SUBCATEGORY_SUCCESS = 'MODIFY_SUBCATEGORY_SUCCESS'
export const MODIFY_SUBCATEGORY_FAILURE = 'MODIFY_SUBCATEGORY_FAILURE'

const includes = ['children', 'products', 'children.products']

export const modifySubCategory = (
  subCategoryId,
  targetParentCategoryId,
  moveSubCategory
) => ({
  [CALL_API]: {
    endpoint: `/categories/${subCategoryId}/${
      moveSubCategory ? 'move-subcategory' : 'merge-subcategory'
    }`,
    method: 'POST',
    body: { target_id: targetParentCategoryId },
    types: [
      MODIFY_SUBCATEGORY_REQUEST,
      MODIFY_SUBCATEGORY_SUCCESS,
      MODIFY_SUBCATEGORY_FAILURE,
    ],
  },
})

export const fetchCategories = () => {
  return {
    [CALL_API]: {
      endpoint: '/categories',
      method: 'GET',
      query: {
        includes,
      },
      types: [
        FETCH_CATEGORIES_REQUEST,
        FETCH_CATEGORIES_SUCCESS,
        FETCH_CATEGORIES_FAILURE,
      ],
    },
  }
}

export const fetchCategoriesIfNeeded = () => {
  return (dispatch, getState) => {
    const { categories } = getState()

    if (shouldFetchCategories(categories)) {
      return dispatch(fetchCategories())
    }

    return null
  }
}

const shouldFetchCategories = categories => {
  if (Object.keys(categories.items).length === 0 && !categories.isFetching) {
    return true
  }

  return false
}

export const createCategory = ({ parent, ...category }) => ({
  [CALL_API]: {
    endpoint: !parent ? '/categories' : `/categories/${parent}/categories`,
    method: 'POST',
    body: {
      category,
      includes,
    },
    types: [
      CREATE_CATEGORY_REQUEST,
      CREATE_CATEGORY_SUCCESS,
      CREATE_CATEGORY_FAILURE,
    ],
  },
})

export const saveCategory = (categoryId, category) => ({
  [CALL_API]: {
    endpoint: `/categories/${categoryId}`,
    method: 'PUT',
    body: {
      category,
      includes,
    },
    types: [
      SAVE_CATEGORY_REQUEST,
      SAVE_CATEGORY_SUCCESS,
      SAVE_CATEGORY_FAILURE,
    ],
  },
})

export const deleteCategory = categoryId => ({
  [CALL_API]: {
    endpoint: `/categories/${categoryId}`,
    method: 'DELETE',
    types: [
      DELETE_CATEGORY_REQUEST,
      DELETE_CATEGORY_SUCCESS,
      DELETE_CATEGORY_FAILURE,
    ],
  },
})
