/* @flow */

import React, { Component } from 'react'
import type { ComponentType } from 'react'
import styled from 'styled-components'
import { TrackError } from '../../infrastructure/exceptions/raven'

type Props = {
  children?: any,
  message: string,
}

type ErrorInfo = {
  componentStack: string,
}

type State = {
  error: ?Error,
  info: ?ErrorInfo,
}

class ErrorBoundary extends Component<Props, State> {
  state = {
    error: null,
    info: null,
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    try {
      if (process.env.NODE_ENV !== 'production') {
        console.log(error)
      }

      TrackError(error)
    } catch (ignoredError) {}

    this.setState({ error, info })
  }

  render() {
    const { children, message } = this.props
    const { error, info } = this.state

    if (error !== null) {
      return (
        <ErrorContainer>
          {message}. A message about this has been delivered to the Traede team
          and we will look at it ASAP.
        </ErrorContainer>
      )
    }

    return children ? children : null
  }
}

export const withErrorBoundary = (
  Component: ComponentType<any>,
  message: string
): Function => props => (
  <ErrorBoundary message={message}>
    <Component {...props} />
  </ErrorBoundary>
)

export default ErrorBoundary

const ErrorContainer = styled.div`
  padding: 5px;
`
