/* @flow */

import * as React from 'react'
import { Formik } from 'formik'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import sortBy from 'lodash/sortBy'

import {
  ControlLabel,
  FormGroup,
  FormInput,
  PriceInput,
  SaveButton,
} from '../../../../infrastructure/components/Formik'

const UpdateUnitPriceModal = ({
  allowUpdatingDiscountPercentage,
  allowUpdatingNetPrice,
  currency,
  editMode,
  inputPrices,
  lines,
  onHide,
  onSave,
  product,
  setEditMode,
  show,
  variants,
}: {
  onHide: Function,
  onSave: Function,
  show: boolean,
}) => {
  const initialValues = React.useMemo(() => {
    const priceValues = {
      price_before_discount: null,
      net_price: null,
      discount_amount: null,
      discount_percentage: null,
      update_property: null,
    }

    const price = inputPrices[0]

    if (price) {
      priceValues.price_before_discount = price.price_before_discount
      priceValues.net_price = price.net_price
      priceValues.discount_amount = price.discount_amount
      priceValues.discount_percentage = price.discount_percentage
    }

    let linesToUpdate = []
    if (editMode === 'row') {
      linesToUpdate.push({
        lines: lines,
        prices: priceValues,
      })
    } else {
      for (let line of lines) {
        linesToUpdate.push({
          lines: [line],
          prices: priceValues,
        })
      }

      linesToUpdate = sortBy(linesToUpdate, line => {
        const variantIndex = variants.findIndex(
          v => v.id == line.lines[0].variant_id
        )

        return variantIndex
      })
    }

    return {
      lines: linesToUpdate,
    }
  }, [editMode, inputPrices, lines, variants])

  return (
    <Modal show={show} onHide={onHide}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSave}
        render={formikProps => (
          <InnerFormComponent
            {...formikProps}
            allowUpdatingDiscountPercentage={allowUpdatingDiscountPercentage}
            allowUpdatingNetPrice={allowUpdatingNetPrice}
            currency={currency}
            editMode={editMode}
            lines={lines}
            onHide={onHide}
            product={product}
            setEditMode={setEditMode}
            variants={variants}
          />
        )}
      />
    </Modal>
  )
}

export default UpdateUnitPriceModal

const InputContainer = styled.div`
  display: flex;
  margin-left: -20px;

  > div {
    margin-left: 20px;
  }
`

const InnerFormComponent = ({
  allowUpdatingDiscountPercentage,
  allowUpdatingNetPrice,
  currency,
  editMode,
  handleSubmit,
  isSubmitting,
  lines,
  product,
  onHide,
  setEditMode,
  setFieldValue,
  updateProperty,
  values,
  variants,
}) => {
  const wrappedSubmit = e => {
    e.stopPropagation()
    handleSubmit(e)
  }

  const onChangeField = React.useCallback(
    (property, newValue) => {
      const prices = values.lines[0] ? values.lines[0].prices : false

      if (property === 'net_price') {
        setFieldValue('lines[0].prices.update_property', {
          key: 'net_price',
          label: 'unit price',
        })

        if (prices && prices.price_before_discount) {
          const priceBeforeDiscount = parseFloat(prices.price_before_discount)
          const newUnitPrice = parseFloat(newValue)

          if (!isNaN(newUnitPrice) && !isNaN(priceBeforeDiscount)) {
            let discountPercentage = 0

            if (newUnitPrice > priceBeforeDiscount) {
              discountPercentage = 0
            } else if (newUnitPrice == 0) {
              discountPercentage = 100
            } else if (priceBeforeDiscount == 0) {
              discountPercentage = 0
            } else {
              const discountAmount = priceBeforeDiscount - newUnitPrice

              discountPercentage = (100 / priceBeforeDiscount) * discountAmount

              if (discountPercentage <= 0) {
                discountPercentage = 0
              }

              discountPercentage = discountPercentage.toFixed(2)
            }

            if (!isNaN(parseFloat(discountPercentage))) {
              setFieldValue(
                'lines[0].prices.discount_percentage',
                discountPercentage
              )
            }
          }
        }
      } else {
        if (prices && prices.price_before_discount) {
          const priceBeforeDiscount = parseFloat(prices.price_before_discount)
          let newDiscountPercentage = parseFloat(newValue)

          if (!isNaN(newDiscountPercentage) && !isNaN(priceBeforeDiscount)) {
            if (newDiscountPercentage < 0) {
              newDiscountPercentage = 0
            }
            if (newDiscountPercentage > 100) {
              newDiscountPercentage = 100
            }

            let discountAmount =
              newDiscountPercentage == 100
                ? priceBeforeDiscount
                : (priceBeforeDiscount / 100) * newDiscountPercentage

            let newPrice = priceBeforeDiscount - discountAmount
            if (newPrice < 0) {
              newPrice = 0
            }

            if (!isNaN(parseFloat(newPrice))) {
              setFieldValue('lines[0].prices.net_price', newPrice.toFixed(2))
            }
          }
        }

        setFieldValue('lines[0].prices.update_property', {
          key: 'discount_percentage',
          label: 'discount percentage',
        })
      }
    },
    [setFieldValue, values]
  )

  return (
    <form onSubmit={wrappedSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>Change price</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {lines.length > 1 && (
          <ModeContainer>
            <ModeButton
              className={
                editMode == 'row' ? 'btn btn-success' : 'btn btn-white'
              }
              onClick={() => setEditMode('row')}
            >
              Row
            </ModeButton>
            <ModeButton
              className={
                editMode == 'lines' ? 'btn btn-success' : 'btn btn-white'
              }
              onClick={() => setEditMode('lines')}
            >
              Lines
            </ModeButton>
          </ModeContainer>
        )}

        {editMode === 'row' && (
          <>
            <InputContainer>
              {allowUpdatingNetPrice && (
                <FormGroup>
                  <ControlLabel>Unit price</ControlLabel>
                  <PriceInput
                    currency={currency}
                    markOnFocus
                    name="lines[0].prices.net_price"
                    onChange={e => onChangeField('net_price', e.target.value)}
                  />
                </FormGroup>
              )}
              {allowUpdatingDiscountPercentage && (
                <FormGroup>
                  <ControlLabel>Discount percentage</ControlLabel>
                  <FormInput
                    markOnFocus
                    name="lines[0].prices.discount_percentage"
                    onChange={e =>
                      onChangeField('discount_percentage', e.target.value)
                    }
                  />
                </FormGroup>
              )}
            </InputContainer>

            {values.lines[0].prices &&
              values.lines[0].prices.update_property && (
                <div className="alert alert-info">
                  Will update {values.lines[0].prices.update_property.label}
                </div>
              )}
          </>
        )}
        {editMode === 'lines' && (
          <>
            <table className="table table-condensed">
              <thead>
                <tr>
                  <th>Variant</th>
                  {allowUpdatingNetPrice && <th>Price</th>}
                  {allowUpdatingDiscountPercentage && (
                    <th>Discount percentage</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {values.lines.map((lineData, lineIndex) => {
                  const variant = variants.find(
                    v => v.id == lineData.lines[0].variant_id
                  )

                  return (
                    <tr key={lineData.lines[0].id}>
                      <td>
                        {product.attributes
                          .map(attribute => variant.attributes[attribute])
                          .join(' ')}
                      </td>
                      {allowUpdatingNetPrice && (
                        <td>
                          <PriceInput
                            currency={currency}
                            markOnFocus
                            name={`lines[${lineIndex}].prices.net_price`}
                            onChange={() =>
                              setFieldValue(
                                `lines[${lineIndex}].prices.update_property`,
                                {
                                  key: 'net_price',
                                  label: 'unit price',
                                }
                              )
                            }
                          />
                        </td>
                      )}
                      {allowUpdatingDiscountPercentage && (
                        <td>
                          <FormInput
                            markOnFocus
                            name={`lines[${lineIndex}].prices.discount_percentage`}
                            onChange={() =>
                              setFieldValue(
                                `lines[${lineIndex}].prices.update_property`,
                                {
                                  key: 'discount_percentage',
                                  label: 'discount percentage',
                                }
                              )
                            }
                          />
                        </td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-white" onClick={onHide}>
          Cancel
        </button>
        <SaveButton submitting={isSubmitting}>Change price</SaveButton>
      </Modal.Footer>
    </form>
  )
}

const ModeContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-left: -10px;
`

const ModeButton = styled.button.attrs({
  type: 'button',
})`
  margin-left: 10px;
`
