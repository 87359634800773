/* @flow */

import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'
import partition from 'lodash/partition'

import type { RowGroup } from '../../../types'

export const mapSectionsToTraditionalRows = (rowGroups: Array<RowGroup>) => {
  let displaySubsections = false
  const columnKeysBySubsectionKey = {}
  const allSubSections = {}
  const subSectionOrder = []
  const dataSourceOrder = []
  const lineRowsBySubsectionKey = {}
  const dataRowsBySubsectionKey = {}
  const splittableSubSectionProperties = []

  for (let rowGroup of rowGroups) {
    const { subSections, split_by = [] } = rowGroup

    for (let splitBy of split_by) {
      if (splitBy.splittable == true) {
        splittableSubSectionProperties.push({
          property: splitBy.property,
          property_label: splitBy.property_label,
        })
      }
    }

    // We want to display the label if any of the subsections are != null
    const nonEmptySubsections = subSections.filter(
      s => s.subSectionKey !== 'traede_no_split'
    )
    if (nonEmptySubsections.length > 0) {
      displaySubsections = true
    }

    for (let subSection of subSections) {
      if (!allSubSections[subSection.subSectionKey]) {
        allSubSections[subSection.subSectionKey] = subSection
      }

      if (!subSectionOrder.includes(subSection.subSectionKey)) {
        subSectionOrder.push(subSection.subSectionKey)
      }

      for (let row of subSection.rows) {
        // the data of the row comes from lines and is therefore dependent on
        // the column key
        if (!row.data_source) {
          if (!lineRowsBySubsectionKey[subSection.subSectionKey]) {
            lineRowsBySubsectionKey[subSection.subSectionKey] = []
          }

          lineRowsBySubsectionKey[subSection.subSectionKey].unshift(row)

          if (!columnKeysBySubsectionKey[subSection.subSectionKey]) {
            columnKeysBySubsectionKey[subSection.subSectionKey] = []
          }

          if (
            !columnKeysBySubsectionKey[subSection.subSectionKey].includes(
              row.columnKey
            )
          ) {
            columnKeysBySubsectionKey[subSection.subSectionKey].push(
              row.columnKey
            )
          }

          // the data of the row is from a data source and should be present in
          // every column key
        } else {
          if (!dataRowsBySubsectionKey[subSection.subSectionKey]) {
            dataRowsBySubsectionKey[subSection.subSectionKey] = []
          }

          dataRowsBySubsectionKey[subSection.subSectionKey].unshift(row)

          if (!dataSourceOrder.includes(row.key)) {
            dataSourceOrder.push(row.key)
          }
        }
      }
    }
  }

  let multipleSubSections = false
  if (Object.keys(allSubSections).length > 1) {
    delete allSubSections['traede_no_split']

    multipleSubSections = true
  }

  const nonEmptySubsections = Object.values(allSubSections).filter(
    s => s.subSectionKey !== 'traede_no_split'
  )
  // We want to display the label if any of the subsections are != null
  const isSubsectioned = nonEmptySubsections.length > 0

  const finalRows = []
  for (let [subSectionKey, subSection] of Object.entries(allSubSections)) {
    const dataRowsWithoutSubsection =
      dataRowsBySubsectionKey['traede_no_split'] || []
    const dataRowsOfSubsection =
      subSectionKey !== 'traede_no_split'
        ? dataRowsBySubsectionKey[subSectionKey] || []
        : []
    const dataRowsCombined = sortBy(
      [...dataRowsWithoutSubsection, ...dataRowsOfSubsection],
      row => dataSourceOrder.indexOf(row.key)
    )
    const lineRowsOfSubsectionByColumnKey = groupBy(
      lineRowsBySubsectionKey[subSectionKey] || [],
      'columnKey'
    )

    let columnKeysOfSubsection = columnKeysBySubsectionKey[subSectionKey] || []

    // TODO: TEST
    if (columnKeysOfSubsection.length == 0) {
      columnKeysOfSubsection.push('')
    } else if (columnKeysOfSubsection.length > 1) {
      columnKeysOfSubsection = columnKeysOfSubsection.filter(key => key !== '')
    }

    for (let columnKey of columnKeysOfSubsection) {
      const lineRowsOfKey = lineRowsOfSubsectionByColumnKey[columnKey] || []

      if (columnKey !== '') {
        const lineRowsWithoutColumnKey =
          lineRowsOfSubsectionByColumnKey[''] || []

        for (let row of lineRowsWithoutColumnKey) {
          lineRowsOfKey.push(row)
        }
      }

      const rowsOfColumnKey = [
        // we have to reverse them individually so we ensure we reverse
        // the order but keep data rows BEFORE line rows
        ...dataRowsCombined,
        ...reverse([...lineRowsOfKey]),
      ]

      finalRows.push({
        columnKey,
        subSectionKey: subSectionKey,
        subSection,
        rows: rowsOfColumnKey,
      })
    }
  }

  const finalRowsSorted = sortBy(finalRows, row =>
    subSectionOrder.indexOf(row.subSectionKey)
  )

  const finalRowsBySubSectionKey = groupBy(finalRowsSorted, 'subSectionKey')

  return {
    displaySubsections,
    finalRowsBySubSectionKey,
    splittableSubSectionProperties,
  }
}
