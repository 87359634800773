/* @flow */

import * as React from 'react'
import styled, { css } from 'styled-components'
import Autosuggest from 'react-autosuggest'
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'
import classnames from 'classnames'

import CloudinaryResource from '../../../infrastructure/components/CloudinaryResource'

import { fetchProducts } from './ProductGrid/shared'
import { useDebounce } from '../../../infrastructure/hooks'

import type { Id } from '../../types'

type Props = {
  brandId: Id,
  dataMode: 'list' | 'shop_v2',
  extraData?: Object,
  limit?: number,
  onFocus?: Function,
  onSelect: Function,
}

const ProductAutoCompleteInput = ({
  autoFocus,
  align = 'left',
  brandId,
  dataMode,
  extraData,
  id,
  limit = 20,
  onFocus,
  onSelect,
}: Props) => {
  // input query is raw current input value
  const [inputQuery, setInputQuery] = React.useState('')
  // search query is updated every time react-autosuggest tells us to refetch results
  // this is later debounced
  const [searchQuery, setSearchQuery] = React.useState('')

  const [{ exact_matches, products }, setResult] = React.useState({
    exact_matches: [],
    products: [],
  })
  const [isFetching, setIsFetching] = React.useState(false)

  const debouncedSearchQuery = useDebounce(searchQuery, 350)

  // debounced search query is updated which means we should request results
  React.useEffect(() => {
    if (!debouncedSearchQuery || debouncedSearchQuery.length < 2) {
      return
    }

    setIsFetching(true)

    const fetchedSearchQuery = debouncedSearchQuery

    fetchProducts(
      brandId,
      dataMode,
      [], // filters
      limit,
      null, // sort
      0, // start
      extraData,
      debouncedSearchQuery
    ).then(response => {
      setIsFetching(false)

      if (!response.error) {
        if (dataMode === 'shop_v2') {
          setResult(response.payload)
        } else {
          setResult({
            ...response.payload,
            exact_matches: [],
          })
        }
      }
    })
  }, [
    brandId,
    dataMode,
    debouncedSearchQuery,
    extraData,
    limit,
    setIsFetching,
    setResult,
  ])

  const onInputChange = React.useCallback(
    (event, { newValue }) => {
      if (newValue !== undefined) {
        setInputQuery(newValue)
      }
    },
    [setInputQuery]
  )

  const getSuggestionValue = React.useCallback(
    suggestion => suggestion.text,
    []
  )

  const onSuggestionsUpdateRequested = React.useCallback(
    ({ value }) => {
      return setSearchQuery(value)
    },
    [setSearchQuery]
  )

  const onSuggestionSelected = React.useCallback(
    (events, { suggestion }) => {
      onSelect(suggestion)
    },
    [onSelect]
  )

  const ref = React.useRef(null)

  const inputProps = React.useMemo(() => {
    return {
      value: inputQuery,
      onChange: onInputChange,
      placeholder: 'Type to search for product',
    }
  }, [inputQuery, onInputChange])

  React.useEffect(() => {
    if (autoFocus && ref.current && ref.current.input) {
      ref.current.input.focus()
    }
  }, [autoFocus, ref])

  const suggestions = React.useMemo(() => {
    const exactMatchIds = exact_matches.map(p => p.id)

    return products.map(product => ({
      ...product,
      exact_match: exactMatchIds.includes(product.id),
    }))
  }, [exact_matches, products])

  const onSuggestionsClearRequested = React.useCallback(() => {
    setResult({
      exact_matches: [],
      products: [],
    })

    setInputQuery('')
    setSearchQuery('')
  }, [setResult, setInputQuery, setSearchQuery])

  const renderSuggestionsContainer = React.useCallback(
    ({ containerProps, children, query, ...rest }) => {
      //const isLoading = isFetching /* || inputQuery !== resultSearchQuery*/

      // Do not show anything when loading initial recents
      if (query === '') {
        return null
      }

      if (isFetching) {
        return (
          <AutosuggestContainer align={align}>
            <LoadingLabel>Loading...</LoadingLabel>
          </AutosuggestContainer>
        )
      }

      return (
        <AutosuggestContainer align={align} {...containerProps}>
          {children}
        </AutosuggestContainer>
      )
    },
    [align, isFetching]
  )

  return (
    <AutosuggestOuterContainer>
      <Autosuggest
        getSuggestionValue={getSuggestionValue}
        highlightFirstSuggestion={true}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsFetchRequested={onSuggestionsUpdateRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        suggestions={suggestions}
        ref={ref}
        id={id}
      />
    </AutosuggestOuterContainer>
  )
}

export default ProductAutoCompleteInput

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const productNameMatches = AutosuggestHighlightMatch(suggestion.name, query)
  const productNameParts = AutosuggestHighlightParse(
    suggestion.name,
    productNameMatches
  )

  const itemNumberMatches = AutosuggestHighlightMatch(
    suggestion.item_number,
    query
  )
  const itemNumberParts = AutosuggestHighlightParse(
    suggestion.item_number,
    itemNumberMatches
  )

  return (
    <Suggestion highlighted={isHighlighted}>
      <SuggestionImage>
        <CloudinaryResource
          id={suggestion.image}
          presets="table"
          fallback="product_table"
        />
      </SuggestionImage>
      <SuggestionContent>
        <SuggestionTitle>
          {productNameParts.map((part, index) => {
            return (
              <span className={part.highlight ? 'highlight' : null}>
                {part.text}
              </span>
            )
          })}
        </SuggestionTitle>
        <SuggestionSubtitle>
          #
          {itemNumberParts.map((part, index) => {
            return (
              <span className={part.highlight ? 'highlight' : null}>
                {part.text}
              </span>
            )
          })}
          {suggestion.exact_match && (
            <SuggestionLabel>Exact match</SuggestionLabel>
          )}
        </SuggestionSubtitle>
      </SuggestionContent>
    </Suggestion>
  )
}

const LoadingLabel = styled.div`
  font-size: 13px;
  padding: 7px 20px 5px;
  text-align: right;
`

const AutosuggestOuterContainer = styled.div`
  position: relative;

  & .react-autosuggest__input {
    width: 100%;
    padding: 5px;
  }

  & .react-autosuggest__suggestions-container--open {
    & .react-autosuggest__suggestions-list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        cursor: pointer;
        padding-left: 0;
      }
    }
  }
`

const AutosuggestContainer = styled.div`
  background: white;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.075);
  margin-top: 8px;
  min-height: 300px;
  overflow-y: auto;
  padding: 5px;
  position: absolute;
  width: 400px;
  z-index: 1000;

  ${({ align = 'left' }) =>
    align === 'left'
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `};
`

const Suggestion = styled.div`
  display: flex;
  padding: 10px 8px;

  background: ${({ highlighted }) => (highlighted ? '#f8f8f8' : 'transparent')};
`

const SuggestionImage = styled.div`
  height: 45px;
  width: 45px;
`

const SuggestionContent = styled.div`
  margin-left: 10px;
`

const SuggestionTitle = styled.div`
  color: black;
  font-weight: bold;

  .highlight {
    background: #f1ca0d;
    padding: 0 1px;
  }
`

const SuggestionSubtitle = styled.div`
  color: #94999c;

  .highlight {
    background: #f1ca0d;
    padding: 0 1px;
  }
`

const SuggestionLabel = styled.span.attrs({
  className: 'label label-xxs label-warning',
})``
