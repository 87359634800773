/* @flow */

import React from 'react'
import styled from 'styled-components'
import { MenuItem } from 'react-bootstrap'
import onClickOutside from 'react-onclickoutside'
import { Modal } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'

import {
  ControlLabel,
  FormGroup,
  FormInput,
  SelectInput,
  SaveButton,
} from '../../../../../../infrastructure/components/Formik'

import { Tooltip } from '../../../../../shared'

import type { ColumnConfig, Row } from '../../types'

type Props = {
  columns: Array<ColumnConfig>,
  onSplit: (column: ColumnConfig) => void,
  row: Row,
}

const SplitButton = ({
  columns,
  onCreateSubSection: propsOnCreateSubSection,
  onSplit: propsOnSplit,
  row,
  subSectionProperties = [],
}: Props) => {
  const [open, setOpen] = React.useState(false)
  const [showSubSectionModal, setShowSubSectionModal] = React.useState(false)

  const onSplit = c => {
    setOpen(false)

    propsOnSplit(c)
  }

  const onCreateSubSection = (property, propertyValue, lineData) => {
    setShowSubSectionModal(false)

    const splitData = { [property]: propertyValue }
    const splitKey = JSON.stringify(splitData)

    propsOnCreateSubSection(splitData, splitKey, lineData)
  }

  if (columns.length > 1 || subSectionProperties.length > 0) {
    return (
      <Container>
        {showSubSectionModal !== false && (
          <SubSectionModal
            property={showSubSectionModal}
            onHide={() => setShowSubSectionModal(false)}
            onSave={(values, { setSubmitting }) => {
              onCreateSubSection(showSubSectionModal.property, values.value, {
                quantity: values.quantity,
              })

              setSubmitting(false)
            }}
            show={showSubSectionModal !== false}
          />
        )}
        <button
          className="btn btn-xs btn-white"
          onClick={() => setOpen(s => !s)}
          tabIndex="-1"
        >
          <span className="glyphicon glyphicon-chevron-down" />
        </button>
        {open && (
          <WrappedMenu setOpen={setOpen}>
            {columns.map(c => {
              return (
                <div key={c.key} onClick={() => onSplit(c)}>
                  {c.label}
                </div>
              )
            })}
            {subSectionProperties.map(property => {
              return (
                <div
                  key={property.property}
                  onClick={() => setShowSubSectionModal(property)}
                >
                  {property.property_label}
                </div>
              )
            })}
          </WrappedMenu>
        )}
      </Container>
    )
  }

  return (
    <Tooltip id="product-table-split-button" tip="Split line">
      <button
        type="button"
        className="btn btn-xs btn-white"
        onClick={() => onSplit(columns[0])}
        tabIndex="-1"
      >
        <span className="glyphicon glyphicon-chevron-down" />
      </button>
    </Tooltip>
  )
}

export default SplitButton

const clickOutsideConfig = {
  handleClickOutside: () => WrappedMenu.handleClickOutside,
}

const Menu = ({ children, setOpen }) => {
  WrappedMenu.handleClickOutside = () => setOpen(false)
  return <MenuContainer>{children}</MenuContainer>
}

const WrappedMenu = onClickOutside(Menu, clickOutsideConfig)

const Container = styled.div`
  position: relative;
`

const MenuContainer = styled.div`
  background: white;
  border: 1px solid #f8f8f8;
  right: 0;
  position: absolute;
  top: 25px;
  z-index: 1000;

  > div {
    cursor: pointer;
    padding: 20px 10px;
    width: 130px;

    &:hover {
      background: #f8f8f8;
    }
  }
`

const batchOptions = [
  {
    label: 'BS5852',
    value: 'BS5852',
  },
  {
    label: 'CAL117',
    value: 'CAL117',
  },
]

const SubSectionModal = ({
  property,
  onHide,
  onSave,
  show,
}: {
  onHide: Function,
  onSave: Function,
  show: boolean,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Formik
        initialValues={{
          value: null,
          quantity: '',
        }}
        onSubmit={onSave}
        validationSchema={SubSectionModalValidation}
        render={({ handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <FormGroup>
                  <ControlLabel required>
                    {property.property_label}
                  </ControlLabel>
                  <SelectInput
                    name="value"
                    simpleValue
                    options={batchOptions}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel required>Quantity</ControlLabel>
                  <FormInput name="quantity" />
                </FormGroup>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={onHide}
                >
                  Cancel
                </button>
                <SaveButton submitting={isSubmitting}>Save</SaveButton>
              </Modal.Footer>
            </form>
          )
        }}
      />
    </Modal>
  )
}

const SubSectionModalValidation = Yup.object().shape({
  quantity: Yup.number()
    .required('Please enter a quantity')
    .typeError('Please enter a quantity'),
})
