/* @flow */

import * as React from 'react'

const createSelectNoneTableAction = ({
  lines_ref,
  key = 'table_none_all',
  set_property = 'quantity',
}) => {
  return {
    key,
    render: ({ updateLines }) => {
      const onClick = () => {
        const updatedLines = []

        for (let line of lines_ref.current) {
          updatedLines.push({
            ...line,
            [set_property]: 0,
          })
        }

        updateLines({
          lines: updatedLines,
          type: 'update',
        })
      }

      return (
        <button
          type="button"
          className="btn btn-white btn-sm"
          onClick={onClick}
        >
          None
        </button>
      )
    },
  }
}

export default createSelectNoneTableAction
