import _ from 'underscore'

import {
  FOLDER_CREATED,
  FOLDERS_FETCHED,
  UPDATE_RESOURCES_SUCCESS,
  DELETE_RESOURCES_SUCCESS,
} from '../actions'

const initialState = {
  folders: {
    items: [],
  },
}

export default function filebank(state = initialState, action) {
  switch (action.type) {
    case FOLDER_CREATED:
      return Object.assign({}, state, {
        folders: Object.assign({}, state.folders, {
          items: [...state.folders.items, action.folder],
        }),
      })
    case UPDATE_RESOURCES_SUCCESS:
      if (action.meta && action.meta.resources) {
        const dataIndexed = _.indexBy(action.meta.resources, 'id')

        return Object.assign({}, state, {
          folders: Object.assign({}, state.folders, {
            items: state.folders.items.map(folder => {
              if (dataIndexed[folder.id]) {
                folder = Object.assign(folder, dataIndexed[folder.id])
              }

              return folder
            }),
          }),
        })
      }

      return state
    case DELETE_RESOURCES_SUCCESS:
      if (action.meta && action.meta.resources) {
        return Object.assign({}, state, {
          folders: Object.assign({}, state.folders, {
            items: state.folders.items.filter(
              folder => !action.meta.resources.includes(folder.id)
            ),
          }),
        })
      }

      return state
    case FOLDERS_FETCHED:
      return Object.assign({}, state, {
        folders: Object.assign({}, state.folders, {
          items: action.folders,
        }),
      })
    default:
      return state
  }
}
