// @flow

import * as React from 'react'
import Mustache from 'mustache'

import { getEmails } from '../../../app/entities/api'
import { getSupplier } from '../../../app/production/api'
import { getCustomer } from '../../../app/customers/api'
import type { EmailCreateData, Id, Supplier } from '../../../app/types'
import {
  ControlLabel,
  EditorInput,
  FormGroup,
  FormInput,
  TextareaInput,
} from '../Formik'
import { useEmailsCreateData } from './hooks'

const AdjustEmailFormFields = ({
  brandRecipientsGroupOptions,
  customerRecipientsGroupOptions,
  emailCreateData,
  isLoaded,
  setFieldValue,
  supplierRecipientsGroupOptions,
  values,
  showRecipientFields = true,
}: {
  brandRecipientsGroupOptions: Array<RecipientsGroupOption> | null,
  customerRecipientsGroupOptions: Array<RecipientsGroupOption> | null,
  emailCreateData: EmailCreateData | null,
  isLoaded: boolean,
  setFieldValue: Function,
  supplierRecipientsGroupOptions: Array<RecipientsGroupOption> | null,
  values: Object,
  showRecipientFields?: Boolean,
}) => {
  if (!isLoaded) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <div className="row">
        {showRecipientFields && (
          <>
            <div className="col-xs-6">
              <FormGroup>
                <ControlLabel>Emails within my company</ControlLabel>
                <ul className="ul-nolist">
                  {brandRecipientsGroupOptions.map(option => (
                    <RecipientsGroupOptionComponent
                      key={option.value}
                      option={option}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  ))}
                </ul>
              </FormGroup>
            </div>

            {supplierRecipientsGroupOptions !== null && (
              <div className="col-xs-6">
                <FormGroup>
                  <ControlLabel>Supplier's emails</ControlLabel>
                  {supplierRecipientsGroupOptions.length > 0 && (
                    <ul className="ul-nolist">
                      {supplierRecipientsGroupOptions.map(option => (
                        <RecipientsGroupOptionComponent
                          key={option.value}
                          option={option}
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      ))}
                    </ul>
                  )}
                  {supplierRecipientsGroupOptions.length === 0 && (
                    <p>No contact persons set up</p>
                  )}
                </FormGroup>
              </div>
            )}

            {customerRecipientsGroupOptions !== null && (
              <div className="col-xs-6">
                <FormGroup>
                  <ControlLabel>Customer's emails</ControlLabel>
                  <ul className="ul-nolist">
                    {customerRecipientsGroupOptions.map(option => (
                      <RecipientsGroupOptionComponent
                        key={option.value}
                        option={option}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    ))}
                  </ul>
                </FormGroup>
              </div>
            )}

            <div className="col-xs-12">
              <FormGroup>
                <ControlLabel>Other emails (separate by comma)</ControlLabel>
                <span className="help">
                  e.g. john@company.com,julie@company.com,...
                </span>
                <TextareaInput
                  name="email.otherEmails"
                  rows={3}
                  style={{ width: '100%' }}
                />
              </FormGroup>
            </div>
          </>
        )}

        <div className="col-xs-12">
          <FormGroup>
            <ControlLabel>Subject</ControlLabel>
            <FormInput name="email.subject" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Body</ControlLabel>
            <EditorInput name="email.body" height={150} />
          </FormGroup>

          {emailCreateData.attachments.length > 0 && (
            <FormGroup>
              <ControlLabel>Attachments</ControlLabel>
              {emailCreateData.attachments.map((attachment, index) => (
                <div key={index}>
                  {Mustache.render(attachment.filename, emailCreateData.data)}
                </div>
              ))}
            </FormGroup>
          )}
        </div>
      </div>
    </div>
  )
}

export default AdjustEmailFormFields

type RecipientsGroupOption = {
  value: string,
  label: string,
  recipients: Array<{
    email: string,
    name?: string,
    user_id?: string,
  }>,
}

const RecipientsGroupOptionComponent = ({
  option,
  setFieldValue,
  values,
}: {
  option: RecipientsGroupOption,
  setFieldValue: Function,
  values: Object,
}) => {
  const checked =
    values &&
    values.email &&
    values.email.recipientsGroups.includes(option.value)

  const onChange = React.useCallback(() => {
    let recipientsGroups
    if (checked) {
      recipientsGroups = values.email.recipientsGroups.filter(
        value => value !== option.value
      )
    } else {
      recipientsGroups = [...values.email.recipientsGroups, option.value]
    }

    setFieldValue('email.recipientsGroups', recipientsGroups)
  }, [setFieldValue, values, checked, option])

  const id = `email-sender-recipients-group-option-${option.value}`

  return (
    <li>
      <div className="checkbox check-success">
        <input id={id} type="checkbox" checked={checked} onChange={onChange} />
        <label htmlFor={id}>{option.label}</label>
      </div>
    </li>
  )
}

export const useAdjustEmailFormFields = ({
  agentIds,
  brandId,
  customerId,
  emailableId,
  emailableType,
  shopId,
  supplierId,
  systemType,
  trigger,
}: {
  agentIds?: Array<Id>,
  brandId: Id,
  customerId?: Id | null,
  emailableId: Id,
  emailableType: string,
  shopId?: Id | null,
  supplierId?: Id | null,
  systemType?: string,
  trigger: string,
}) => {
  const requestArgs = React.useMemo(
    () => [trigger, systemType, emailableType, emailableId],
    [trigger, systemType, emailableType, emailableId]
  )
  const [emailsCreateData, isFetchingEmailsCreateData] =
    useEmailsCreateData(requestArgs)

  // If the email systemType is provided, then there should be just one email
  // returned, otherwise we just take the first one
  const emailCreateData =
    emailsCreateData &&
    !isFetchingEmailsCreateData &&
    emailsCreateData.length > 0
      ? emailsCreateData[0]
      : null

  const [brandRecipientsGroupOptions, setBrandRecipientsGroupOptions] =
    React.useState(null)
  const [isBrandLoaded, setIsBrandLoaded] = React.useState(false)
  React.useEffect(() => {
    getBrandRecipientsGroupOptions(brandId, agentIds).then(options => {
      setBrandRecipientsGroupOptions(options)
      setIsBrandLoaded(true)
    })
  }, [brandId, agentIds])

  const [supplierRecipientsGroupOptions, setSupplierRecipientsGroupOptions] =
    React.useState(null)
  const [isSupplierLoaded, setIsSupplierLoaded] = React.useState(false)
  React.useEffect(() => {
    if (supplierId) {
      getSupplierRecipientsGroupOptions(supplierId).then(options => {
        setSupplierRecipientsGroupOptions(options)
        setIsSupplierLoaded(true)
      })
    } else {
      setIsSupplierLoaded(true)
    }
  }, [supplierId])

  const [customerRecipientsGroupOptions, setCustomerRecipientsGroupOptions] =
    React.useState(null)
  const [isCustomerLoaded, setIsCustomerLoaded] = React.useState(false)
  React.useEffect(() => {
    if (customerId && shopId) {
      getCustomerRecipientsGroupOptions(customerId, shopId).then(options => {
        setCustomerRecipientsGroupOptions(options)
        setIsCustomerLoaded(true)
      })
    } else {
      setIsCustomerLoaded(true)
    }
  }, [customerId, shopId])

  const recipientsGroupOptions = React.useMemo(() => {
    let result = []

    if (brandRecipientsGroupOptions) {
      result = [...result, ...brandRecipientsGroupOptions]
    }
    if (supplierRecipientsGroupOptions) {
      result = [...result, ...supplierRecipientsGroupOptions]
    }
    if (customerRecipientsGroupOptions) {
      result = [...result, ...customerRecipientsGroupOptions]
    }

    return result
  }, [
    brandRecipientsGroupOptions,
    supplierRecipientsGroupOptions,
    customerRecipientsGroupOptions,
  ])

  const initialValues = React.useMemo(() => {
    if (!emailCreateData) {
      return {}
    }
    return createInitialValues(emailCreateData, recipientsGroupOptions)
  }, [emailCreateData, recipientsGroupOptions])

  return {
    initialValues,
    isLoaded:
      emailCreateData !== null &&
      isBrandLoaded &&
      isSupplierLoaded &&
      isCustomerLoaded,
    emailCreateData,
    extractAdjustEmailData: React.useMemo(
      () => extractAdjustEmailData(recipientsGroupOptions),
      [recipientsGroupOptions]
    ),
    brandRecipientsGroupOptions,
    sendEmailDefaultValue:
      emailCreateData !== null ? emailCreateData.active : null,
    supplierRecipientsGroupOptions,
    customerRecipientsGroupOptions,
  }
}

type AdjustEmailFormFieldsValues = {|
  email: {
    recipientsGroups: string[],
    otherEmails: string,
    subject: string,
    body: string,
  },
|}

export const createInitialValues = (
  emailCreateData: EmailCreateData,
  recipientsGroupOptions: Array<RecipientsGroupOption>
): AdjustEmailFormFieldsValues => {
  const prefillEmails = emailCreateData.recipients.map(
    recipient => recipient.email
  )

  // emailCreateData prepared on api return already the list of recipients, which should be
  // prefilled, but not why they are prefilled -- if the recipient is brand owner, in billing
  // emails or just directly added in email settings. That's what we try to reconstruct here
  // by checking the recipients list against the different recipient groups. If all emails
  // from recipients group are in emailCreateData recipients -> preselect that group.
  const preselectedGroups = []
  const emailsFromPreselectedGroups = []
  recipientsGroupOptions.forEach(({ recipients, value }) => {
    if (
      recipients.every(recipient => prefillEmails.includes(recipient.email))
    ) {
      preselectedGroups.push(value)
      emailsFromPreselectedGroups.push(...recipients.map(r => r.email))
    }
  })

  // Other emails are then all the ones, which are not preselected in groups.
  // This means that if there is group of e.g. customer contact emails with
  // 2 emails, one@example.com and two@example.com, and only two@example.com should
  // be prefilled, then two@example.com will go into other emails and the group
  // wont be preselected.
  const otherEmails = prefillEmails.filter(
    email => !emailsFromPreselectedGroups.includes(email)
  )

  return {
    email: {
      recipientsGroups: preselectedGroups,
      otherEmails: otherEmails.join(', '),
      subject: emailCreateData.subject,
      body: emailCreateData.body,
    },
  }
}

export const extractAdjustEmailData =
  (recipientsGroupOptions: Array<RecipientsGroupOption>) =>
  (values: AdjustEmailFormFieldsValues) => {
    let recipients = []
    recipientsGroupOptions.forEach(option => {
      if (values.email.recipientsGroups.includes(option.value)) {
        recipients = [...recipients, ...option.recipients]
      }
    })

    const otherEmails = values.email.otherEmails.trim()
    if (otherEmails !== '') {
      recipients = [
        ...recipients,
        ...otherEmails.split(',').map(email => ({ email: email.trim() })),
      ]
    }

    return {
      recipients,
      subject: values.email.subject,
      body: values.email.body,
      active: true,
    }
  }

export const getBrandRecipientsGroupOptions = (
  brandId: Id,
  agentIds?: Array<Id>
): Promise<Array<RecipientsGroupOption>> => {
  return getEmails(brandId).then(response => {
    if (!response.error) {
      const brandEmails = response.payload.emails

      const options = [
        {
          value: 'brandOwner',
          label: `Send to account owner (${brandEmails.owner.email})`,
          recipients: [
            {
              email: brandEmails.owner.email,
              name: brandEmails.owner.name,
              user_id: brandEmails.owner.id,
            },
          ],
        },
      ]
      if (brandEmails.billing) {
        options.push({
          value: 'brandBilling',
          label: `Send to billing emails (${brandEmails.billing.join(', ')})`,
          recipients: brandEmails.billing.map(email => ({ email })),
        })
      }
      if (agentIds && agentIds.length > 0) {
        agentIds.forEach(agentId => {
          const brandUser = brandEmails.users.find(user => user.id === agentId)
          if (brandUser) {
            options.push({
              value: `brandAgent-${brandUser.id}`,
              label: `Send to agent ${brandUser.name} (${brandUser.email})`,
              recipients: [
                {
                  email: brandUser.email,
                  name: brandUser.name,
                  user_id: brandUser.id,
                },
              ],
            })
          }
        })
      }

      return options
    }
  })
}

export const getSupplierRecipientsGroupOptions = (
  supplierId: Id
): Promise<Array<RecipientsGroupOption>> => {
  return getSupplier(supplierId, { includes: ['contactPersons'] }).then(
    response => {
      if (!response.error) {
        const supplier = response.payload.supplier

        return supplier.contact_persons.map(contactPerson => ({
          value: `supplier-${contactPerson.id}`,
          label: `Send to ${contactPerson.name} (${contactPerson.email})`,
          recipients: [
            {
              email: contactPerson.email,
              name: contactPerson.name,
            },
          ],
        }))
      }
    }
  )
}

export const getCustomerRecipientsGroupOptions = (
  customerId: Id,
  shopId: Id
): Promise<Array<RecipientsGroupOption>> => {
  return Promise.all([getEmails(shopId), getCustomer(customerId)]).then(
    ([shopResponse, customerResponse]) => {
      if (!shopResponse.error && !customerResponse.error) {
        const shopEmails = shopResponse.payload.emails
        const customer = customerResponse.payload

        const options = [
          {
            value: 'shopOwner',
            label: `Send to customer account owner (${shopEmails.owner.email})`,
            recipients: [
              {
                email: shopEmails.owner.email,
                name: shopEmails.owner.name,
                user_id: shopEmails.owner.id,
              },
            ],
          },
        ]

        let billingEmails = shopEmails.billing
        if (customer.billing_emails && customer.billing_emails.length > 0) {
          billingEmails = customer.billing_emails
        }

        if (!Array.isArray(billingEmails)) {
          billingEmails = [billingEmails]
        }

        if (billingEmails && billingEmails.length > 0) {
          options.push({
            value: 'shopBilling',
            label: `Send to customer billing emails (${billingEmails.join(
              ', '
            )})`,
            recipients: billingEmails.map(email => ({ email })),
          })
        }

        let contactEmails = customer.email
        if (contactEmails && contactEmails.length > 0) {
          contactEmails = contactEmails.split(',').map(email => email.trim())

          options.push({
            value: 'shopContact',
            label: `Send to customer contact emails (${contactEmails.join(
              ', '
            )})`,
            recipients: contactEmails.map(email => ({ email })),
          })
        }

        return options
      }
    }
  )
}
