import {
  SET_CLOUDINARY_SETTINGS,
  ADD_CUSTOM_CLOUDINARY_PRESET,
} from '../actions/cloudinary'

const initialState = {
  cloudName: null,
  productPresets: {},
  otherPresets: {},
  fallbacks: {},
}

export default function cloudinary(state = initialState, action) {
  switch (action.type) {
    case SET_CLOUDINARY_SETTINGS:
      return Object.assign({}, state, {
        cloudName: action.cloudName,
        productPresets: action.productPresets,
        otherPresets: action.otherPresets,
        fallbacks: action.fallbacks,
      })
    case ADD_CUSTOM_CLOUDINARY_PRESET:
      return Object.assign({}, state, {
        presets: Object.assign({}, state.otherPresets, {
          [action.presetName]: {
            crop: 'fill',
            height: action.diameter,
            width: action.diameter,
          },
        }),
      })
    default:
      return state
  }
}
