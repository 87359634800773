import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'

const locationHelper = locationHelperBuilder({})

export const UserIsAuthenticated = connectedRouterRedirect({
  authenticatedSelector: state => state.session.user.id !== undefined,
  redirectPath: '/login',
  wrapperDisplayName: 'UserIsAuthenticated',
})

export const UserIsNotAuthenticated = connectedRouterRedirect({
  authenticatedSelector: state => state.session.user.id === undefined,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || '/',
  wrapperDisplayName: 'UserIsNotAuthenticated',
})

export const UserIsSetup = connectedRouterRedirect({
  authenticatedSelector: state => state.session.user.virgin === false,
  wrapperDisplayName: 'UserIsSetup',
  redirectPath: '/setup',
})

export const UserIsNotSetup = connectedRouterRedirect({
  authenticatedSelector: state => state.session.user.virgin !== false,
  wrapperDisplayName: 'UserIsNotSetup',
  redirectPath: '/',
  allowRedirectBack: false,
})

export const UserIsAdmin = connectedRouterRedirect({
  authenticatedSelector: state => state.session.user.user_type === 'admin',
  wrapperDisplayName: 'UserIsAdmin',
  redirectPath: '/',
})

export const LogoutUser = connectedRouterRedirect({
  authenticatedSelector: state => state.session.user.id !== undefined,
  wrapperDisplayName: 'LogoutUser',
  allowRedirectBack: false,
  redirectPath: '/',
})
