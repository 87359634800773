/* @flow */

import type { Id } from '../../types'
import type { State } from '../../types/redux'

import { CHANGE_BRAND_CONTEXT } from '../actions/index'

export type ChangeBrandContextAction = {
  type: 'CHANGE_BRAND_CONTEXT',
  brandId: number,
}

type BrandContextState = null | Id
type Action = ChangeBrandContextAction

export const initialState = null

/**
 * Reducer to update global brand context
 * @param  {object} state  =             initialState
 * @param  {object} action
 * @return {object}
 */
export default function brandContext(
  state: BrandContextState = initialState,
  action: Action
) {
  switch (action.type) {
    case CHANGE_BRAND_CONTEXT:
      return action.brandId
    default:
      return state
  }
}
