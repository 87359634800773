import React from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
  let currenciesToUse = currencies
  if (ownProps.currencies) {
    currenciesToUse = [...ownProps.currencies]
    currenciesToUse.sort()
  }

  if (ownProps.exclude) {
    currenciesToUse = currenciesToUse.filter(
      currency => !ownProps.exclude.includes(currency)
    )
  }

  const items = currenciesToUse.map(currency => ({
    key: currency,
    label: currency,
  }))

  return {
    clearable: ownProps.clearable || false,
    options: items,
    labelKey: 'label',
    ref: ownProps.setRef,
    value: ownProps.value ?? ownProps.defaultValue,
    valueKey: 'key',
    placeholder: 'No currency selected',
    simpleValue: true,
    onBlur: () => {},
  }
}

const Connected = connect(mapStateToProps)(props => <Select {...props} />)

Connected.displayName = 'CurrencySelector'

export default Connected

export const isValidCurrency = input => currencies.indexOf(input) !== -1

// TODO: Replace with API call
export const currencies = [
  'ALL',
  'AED',
  'AFN',
  'ARS',
  'AWG',
  'AUD',
  'AZN',
  'BSD',
  'BBD',
  'BDT',
  'BYR',
  'BZD',
  'BMD',
  'BOB',
  'BAM',
  'BWP',
  'BGN',
  'BRL',
  'BND',
  'KHR',
  'CAD',
  'KYD',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'HRK',
  'CUP',
  'CZK',
  'DKK',
  'DOP',
  'XCD',
  'EGP',
  'SVC',
  'EEK',
  'EUR',
  'FKP',
  'FJD',
  'GHC',
  'GIP',
  'GTQ',
  'GGP',
  'GYD',
  'HNL',
  'HKD',
  'HUF',
  'ISK',
  'INR',
  'IDR',
  'IRR',
  'IMP',
  'ILS',
  'JMD',
  'JPY',
  'JEP',
  'KZT',
  'KPW',
  'KRW',
  'KGS',
  'LAK',
  'LVL',
  'LBP',
  'LRD',
  'LTL',
  'MKD',
  'MYR',
  'MUR',
  'MXN',
  'MNT',
  'MZN',
  'NAD',
  'NPR',
  'ANG',
  'NZD',
  'NIO',
  'NGN',
  'NOK',
  'OMR',
  'PKR',
  'PAB',
  'PYG',
  'PEN',
  'PHP',
  'PLN',
  'QAR',
  'RON',
  'RUB',
  'SHP',
  'SAR',
  'RSD',
  'SCR',
  'SGD',
  'SBD',
  'SOS',
  'ZAR',
  'LKR',
  'SEK',
  'CHF',
  'SRD',
  'SYP',
  'TWD',
  'THB',
  'TTD',
  'TRY',
  'TRL',
  'TVD',
  'UAH',
  'GBP',
  'USD',
  'UYU',
  'UZS',
  'VEF',
  'VND',
  'YER',
  'ZWD',
]

currencies.sort()
