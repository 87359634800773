/* @flow */

import api from '../api'

import type { ApiResponse, BrunoRequestOptions, CustomField } from '../types'

export const getCustomFields = (
  options?: BrunoRequestOptions
): Promise<ApiResponse<{ custom_fields: Array<CustomField> }>> =>
  api.get('/custom-fields')

export const createCustomField = data =>
  api.post(`/custom-fields`, {
    body: {
      custom_field: data,
    },
  })

export const updateCustomField = (id, data) =>
  api.put(`/custom-fields/${id}`, {
    body: {
      custom_field: data,
    },
  })

export const deleteCustomField = id => api.delete(`/custom-fields/${id}`)
