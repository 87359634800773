/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import type { RowConfig } from '../../types'

type Options = {
  label?: string,
}

const location: (options: Options) => RowConfig = ({ label = 'Location' }) => ({
  data_source: '__variant',
  key: 'location',
  label,
})

export default location
