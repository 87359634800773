/* @flow */

import * as React from 'react'

import {
  FormGroup,
  SelectInput,
  FormInput,
} from '../../infrastructure/components/Formik'

import { DatalessCustomerGroupSelector } from '../customers/components/CustomerGroupSelector'
import { DatalessCustomerSelector } from '../customers/components/CustomerSelector'
import { getCustomersList, getCustomerGroups } from '../customers/api'
import { DatalessCollectionSelector } from '../collections/components/CollectionSelector'
import { getCollections } from '../collections/api'
import { DatalessDropSelector } from '../orders/components/DropSelector'
import { getDrops } from '../orders/api'
import { DatalessCategorySelector } from '../categories/components/CategorySelector'
import { getCategories } from '../categories/api'
import {
  DatalessProductSelector,
  productSelectorNeededFields,
} from '../products/components/ProductSelector'
import { getProductsListV2, getAttributes, getTags } from '../products/api'
import { getProductGroups } from '../product-groups/api'
import { DatalessProductGroupSelector } from '../products/components/ProductGroupSelector'
import { DatalessTagSelector } from '../products/components/TagSelector'
import { getSubbrands } from '../subbrands/api'
import { DatalessSubbrandSelector } from '../subbrands/SubbrandSelector'
import { getSuppliers } from '../production/api'
import { DatalessSupplierSelector } from '../production/SupplierSelector'

import { useDataCache, useDataCacheValues } from '../hooks'

export const parseApiFilters = filters => {
  for (let [i, filter] of Object.entries(filters)) {
    const attribute = availableAttributes[filter.key]

    if (!attribute) {
      continue
    }

    if (filter.value === 'true') {
      filters[i] = { ...filter, value: true }
    } else if (filter.value === 'false') {
      filters[i] = { ...filter, value: false }
    }

    if (filter.not === 'true') {
      filters[i] = { ...filter, not: true }
    } else if (filter.not === 'false') {
      filters[i] = { ...filter, not: false }
    }
  }

  return filters
}

const availableAttributes = {
  abc_class: {
    label: 'ABC class',
    property: 'abc_class',
    type: 'array',
    values: ['A', 'B', 'C'],
  },
  archived_variant: {
    label: 'Archived variants',
    property: 'archived_variant',
    type: 'enum',
    values: [
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ],
  },
  attribute: {
    label: 'Attribute',
    property: 'attribute',
  },
  collection: {
    label: 'Collection',
    property: 'collection',
    type: 'render',
    data_source: {
      request: getCollections,
      map: response => response.collections,
    },
    data_cache_label: ({ dataCacheActions, filter }) => {
      if (!filter.value) {
        return Promise.resolve(filter.value)
      }

      return dataCacheActions.primeCache('collection').then(collections => {
        if (Array.isArray(filter.value)) {
          return filter.value.map(id => {
            const collection = collections.find(c => c.id == id)

            return collection ? collection.title : id
          })
        }

        const collection = collections.find(c => c.id == filter.value)

        return collection ? collection.title : id
      })
    },
    render: ({ dataCache, dataCacheActions }) => {
      const [collections, isFetching] = useDataCacheValues(
        dataCache,
        dataCacheActions,
        'collection',
        []
      )

      return (
        <SelectInput
          collections={collections}
          isFetching={isFetching}
          multi
          name="value"
          selectComponent={DatalessCollectionSelector}
          simpleValue
        />
      )
    },
  },
  customer: {
    label: 'Customer',
    property: 'customer',
    type: 'render',
    data_source: {
      request: getCustomersList,
      map: response => response.customers,
    },
    data_cache_label: ({ dataCacheActions, filter }) => {
      if (!filter.value) {
        return Promise.resolve(filter.value)
      }

      return dataCacheActions.primeCache('customer').then(customers => {
        if (Array.isArray(filter.value)) {
          return filter.value.map(id => {
            const customer = customers.find(c => c.id == id)

            return customer
              ? `${customer.name} (#${customer.customer_number})`
              : id
          })
        }

        const customer = customers.find(c => c.id == filter.value)

        return customer ? `${customer.name} (#${customer.customer_number})` : id
      })
    },
    render: ({ dataCache, dataCacheActions }) => {
      const [customers, isFetching] = useDataCacheValues(
        dataCache,
        dataCacheActions,
        'customer',
        []
      )

      return (
        <SelectInput
          customers={customers}
          isFetching={isFetching}
          multi
          name="value"
          selectComponent={DatalessCustomerSelector}
          simpleValue
        />
      )
    },
  },
  customer_group: {
    label: 'Customer group',
    property: 'customer_group',
    type: 'render',
    data_source: {
      request: getCustomerGroups,
      map: response => response.customer_groups,
    },
    data_cache_label: ({ dataCacheActions, filter }) => {
      if (!filter.value) {
        return Promise.resolve(filter.value)
      }

      return dataCacheActions
        .primeCache('customer_group')
        .then(customerGroups => {
          if (Array.isArray(filter.value)) {
            return filter.value.map(id => {
              const customerGroup = customerGroups.find(c => c.id == id)

              return customerGroup ? customerGroup.name : id
            })
          }

          const customerGroup = customerGroups.find(c => c.id == filter.value)

          return customerGroup ? customerGroup.name : id
        })
    },
    render: ({ dataCache, dataCacheActions }) => {
      const [customerGroups, isFetching] = useDataCacheValues(
        dataCache,
        dataCacheActions,
        'customer_group',
        []
      )

      return (
        <SelectInput
          customerGroups={customerGroups}
          isFetching={isFetching}
          multi
          name="value"
          selectComponent={DatalessCustomerGroupSelector}
          simpleValue
        />
      )
    },
  },
  category: {
    label: 'Category',
    property: 'category',
    type: 'render',
    data_source: {
      request: () => getCategories({ includes: ['children'] }),
      map: response => ({
        categories: response.categories,
        categories_with_subcategories: response.categories.reduce(
          (carry, category) => {
            carry.push({
              id: category.id,
              name: category.name,
            })

            for (let child of category.children) {
              carry.push({
                id: child.id,
                name: `${category.name} - ${child.name}`,
              })
            }

            return carry
          },
          []
        ),
      }),
    },
    data_cache_label: ({ dataCacheActions, filter }) => {
      if (!filter.value) {
        return Promise.resolve(filter.value)
      }

      return dataCacheActions
        .primeCache('category')
        .then(({ categories_with_subcategories }) => {
          if (Array.isArray(filter.value)) {
            return filter.value.map(id => {
              const category = categories_with_subcategories.find(
                c => c.id == id
              )

              return category ? category.name : id
            })
          }

          const category = categories_with_subcategories.find(
            c => c.id == filter.value
          )

          return category ? category.name : id
        })
    },
    render: ({ dataCache, dataCacheActions }) => {
      const [{ categories }, isFetching] = useDataCacheValues(
        dataCache,
        dataCacheActions,
        'category',
        {
          categories: [],
          categories_with_subcategories: [],
        }
      )

      return (
        <SelectInput
          categories={categories}
          includeSubcategories
          isFetching={isFetching}
          multi
          name="value"
          selectComponent={DatalessCategorySelector}
          simpleValue
        />
      )
    },
  },
  drop: {
    label: 'Drop',
    property: 'drop',
    type: 'render',
    data_source: {
      request: getDrops,
      map: response => response.drops,
    },
    data_cache_label: ({ dataCacheActions, filter }) => {
      if (!filter.value) {
        return Promise.resolve(filter.value)
      }

      return dataCacheActions.primeCache('drop').then(drops => {
        if (Array.isArray(filter.value)) {
          return filter.value.map(id => {
            const drop = drops.find(c => c.id == id)

            return drop ? drop.name : id
          })
        }

        const drop = drops.find(c => c.id == filter.value)

        return drop ? drop.name : id
      })
    },
    render: ({ dataCache, dataCacheActions }) => {
      const [drops, isFetching] = useDataCacheValues(
        dataCache,
        dataCacheActions,
        'drop',
        []
      )

      return (
        <SelectInput
          drops={drops}
          isFetching={isFetching}
          multi
          name="value"
          selectComponent={DatalessDropSelector}
          simpleValue
        />
      )
    },
  },
  item_number: {
    label: 'Item number',
    property: 'item_number',
    type: 'render',
    render: () => {
      return (
        <FormGroup>
          <FormInput name="value" />
        </FormGroup>
      )
    },
  },
  noos: {
    label: 'NOOS',
    property: 'noos',
    type: 'enum',
    values: [
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ],
  },
  product: {
    label: 'Product',
    property: 'product',
    type: 'render',
    data_source: {
      request: () =>
        getProductsListV2({
          fields: productSelectorNeededFields,
        }),
      map: response => response.products,
    },
    data_cache_label: ({ dataCacheActions, filter }) => {
      if (!filter.value) {
        return Promise.resolve(filter.value)
      }

      return dataCacheActions.primeCache('product').then(products => {
        if (Array.isArray(filter.value)) {
          return filter.value.map(id => {
            const product = products.find(c => c.id == id)

            return product ? `${product.name} (#${product.item_number})` : id
          })
        }

        const product = products.find(c => c.id == filter.value)

        return product ? `${product.name} (#${product.item_number})` : id
      })
    },
    render: ({ dataCache, dataCacheActions }) => {
      const [products, isFetching] = useDataCacheValues(
        dataCache,
        dataCacheActions,
        'product',
        []
      )

      return (
        <SelectInput
          isLoading={isFetching}
          multi
          name="value"
          products={products}
          selectComponent={DatalessProductSelector}
          simpleValue
        />
      )
    },
  },
  product_group: {
    label: 'Product group',
    property: 'product_group',
    type: 'render',
    data_source: {
      request: getProductGroups,
      map: response => response,
    },
    data_cache_label: ({ dataCacheActions, filter }) => {
      if (!filter.value) {
        return Promise.resolve(filter.value)
      }

      return dataCacheActions
        .primeCache('product_group')
        .then(productGroups => {
          if (Array.isArray(filter.value)) {
            return filter.value.map(id => {
              const productGroup = productGroups.find(g => g.id == id)

              return productGroup ? productGroup.name : id
            })
          }

          const productGroup = productGroups.find(c => c.id == filter.value)

          return productGroup ? productGroup.name : id
        })
    },
    render: ({ dataCache, dataCacheActions }) => {
      const [productGroups, isFetching] = useDataCacheValues(
        dataCache,
        dataCacheActions,
        'product_group',
        []
      )

      return (
        <SelectInput
          isLoading={isFetching}
          multi
          name="value"
          productGroups={productGroups}
          selectComponent={DatalessProductGroupSelector}
          simpleValue
        />
      )
    },
  },
  subbrand: {
    label: 'Subbrand',
    property: 'subbrand',
    type: 'render',
    data_source: {
      request: getSubbrands,
      map: response => response.subbrands,
    },
    data_cache_label: ({ dataCacheActions, filter }) => {
      if (!filter.value) {
        return Promise.resolve(filter.value)
      }

      return dataCacheActions.primeCache('subbrand').then(subbrands => {
        if (Array.isArray(filter.value)) {
          return filter.value.map(id => {
            const subbrand = subbrands.find(s => s.id == id)

            return subbrand ? subbrand.name : id
          })
        }

        const subbrand = subbrand.find(s => s.id == filter.value)

        return customer ? customer.name : id
      })
    },
    render: ({ dataCache, dataCacheActions }) => {
      const [subbrands, isFetching] = useDataCacheValues(
        dataCache,
        dataCacheActions,
        'subbrand',
        []
      )

      return (
        <SelectInput
          subbrands={subbrands}
          isFetching={isFetching}
          multi
          name="value"
          selectComponent={DatalessSubbrandSelector}
          simpleValue
        />
      )
    },
  },
  supplier: {
    label: 'Supplier',
    property: 'supplier',
    type: 'render',
    data_source: {
      request: getSuppliers,
      map: response => response.suppliers,
    },
    data_cache_label: ({ dataCacheActions, filter }) => {
      if (!filter.value) {
        return Promise.resolve(filter.value)
      }

      return dataCacheActions.primeCache('supplier').then(suppliers => {
        if (Array.isArray(filter.value)) {
          return filter.value.map(id => {
            const supplier = suppliers.find(c => c.id == id)

            return supplier ? `${supplier.name}` : id
          })
        }

        const supplier = suppliers.find(c => c.id == filter.value)

        return supplier ? `${supplier.name}` : id
      })
    },
    render: ({ dataCache, dataCacheActions }) => {
      const [suppliers, isFetching] = useDataCacheValues(
        dataCache,
        dataCacheActions,
        'supplier',
        []
      )

      return (
        <SelectInput
          suppliers={suppliers}
          isFetching={isFetching}
          multi
          name="value"
          selectComponent={DatalessSupplierSelector}
          simpleValue
        />
      )
    },
  },
  tag: {
    label: 'Tag',
    property: 'tag',
    type: 'render',
    data_source: {
      request: getTags,
      map: response => response.tags,
    },
    data_cache_label: ({ dataCacheActions, filter }) => {
      if (!filter.value) {
        return Promise.resolve(filter.value)
      }

      return dataCacheActions.primeCache('tag').then(tags => {
        if (Array.isArray(filter.value)) {
          return filter.value.map(id => {
            const tag = tags.find(c => c.id == id)

            return tag ? tag.name : id
          })
        }

        const tag = tags.find(c => c.id == filter.value)

        return tag ? tag.name : id
      })
    },
    render: ({ dataCache, dataCacheActions }) => {
      const [tags, isFetching] = useDataCacheValues(
        dataCache,
        dataCacheActions,
        'tag',
        []
      )

      return (
        <SelectInput
          tags={tags}
          isFetching={isFetching}
          multi
          name="value"
          selectComponent={DatalessTagSelector}
          simpleValue
        />
      )
    },
  },
}

export const useApiFilters = inputFilters => {
  const [productAttributes, setProductAttributes] = React.useState([])

  React.useEffect(() => {
    if (inputFilters.includes('attribute')) {
      getAttributes().then(response => {
        if (!response.error) {
          setProductAttributes(response.payload.attributes)
        }
      })
    }
  }, [inputFilters, setProductAttributes])

  const [attributes, dataSources] = React.useMemo(() => {
    const attributes = []
    const dataSources = {}

    for (let filter of inputFilters) {
      const attribute = availableAttributes[filter]

      if (!attribute) {
        continue
      }

      // product attributes
      if (filter === 'attribute') {
        for (let productAttribute of productAttributes) {
          const copy = { ...attribute }

          copy.label = productAttribute.name
          copy.type = 'array'
          copy.property = `attributes.${productAttribute.name}`
          copy.values = productAttribute.values

          attributes.push(copy)
        }

        continue
      }

      const copy = {
        ...attribute,
      }

      if (copy.data_source) {
        dataSources[filter] = copy.data_source
        delete copy.data_source
      }

      attributes.push(copy)
    }

    return [attributes, dataSources]
  }, [inputFilters, productAttributes])

  const [dataCache, dataCacheActions] = useDataCache(dataSources)

  return [attributes, dataCache, dataCacheActions]
}
