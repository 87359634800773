import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { SecondaryMenuHoverContainer } from './shared'
import { Submenu, FakeSubmenuNavigationLink } from '../shared'
import { getFreemiumCustomers } from '../../../../admin/api'

const NavigationRightSideFreemiumCustomers = ({
  dispatch,
  onSwitchEntity,
  type,
  useNewColors,
}) => {
  const [entities, setEntities] = React.useState([])

  React.useEffect(() => {
    getFreemiumCustomers({
      sort: [
        {
          key: 'name',
          direction: 'ASC',
        },
      ],
    }).then(response => {
      setEntities(response.payload.entities)
    })
  }, [setEntities])

  return (
    <FreemiumCustomerSwitcherListContainer>
      <HoverContainer useNewColors={useNewColors} type={type}>
        <span className="glyphicon glyphicon-star-empty" />

        <FreemiumCustomersSubmenu>
          <FreemiumCustomersContainer>
            {entities.map(entity => (
              <FakeSubmenuNavigationLink
                key={entity.id}
                onClick={() => onSwitchEntity(entity.id)}
              >
                {entity.name} ({entity.id})
              </FakeSubmenuNavigationLink>
            ))}
          </FreemiumCustomersContainer>
        </FreemiumCustomersSubmenu>
      </HoverContainer>
    </FreemiumCustomerSwitcherListContainer>
  )
}

export default connect()(NavigationRightSideFreemiumCustomers)

const FreemiumCustomersSubmenu = styled(Submenu)`
  min-width: 200px;
`

const HoverContainer = styled(SecondaryMenuHoverContainer)`
  position: relative;

  &:hover ${FreemiumCustomersSubmenu} {
    display: block;
  }
`

const FreemiumCustomerSwitcherListContainer = styled.div`
  display: flex;
  width: 100%;
`

const FreemiumCustomersContainer = styled.div`
  max-height: 400px;
  overflow-y: scroll;
`
