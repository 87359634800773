/* @flow */

import React, { useContext, memo } from 'react'
import styled from 'styled-components'

import HoverImage from '../HoverImage'
import {
  ProductTableContext,
  TableColumn,
  TableRow,
  createEmptyArray,
} from '../shared'
import { columnConfigToStyle, flattenRows, renderValue } from './shared'

const ProductTotalsRow = ({
  columnTotals,
  dataContext,
  maxHorizontalColumns,
  noActionColumns,
  product,
}) => {
  const {
    columnKeys,
    columns,
    imageSettings,
    matrix,
    preview,
    productDataConfig,
    productDataConfigKeys,
    productImage,
    rows,
    variantImages,
  } = useContext(ProductTableContext)

  const flattenedRows = React.useMemo(
    () => (matrix ? [] : flattenRows(rows)[0]),
    [matrix, rows]
  )

  let colSpan = 0
  if (matrix) {
    // 1 for the empty column between horizontal attributes and columns
    // 1 for image columns (does not matter whether or not images are shown or not)
    colSpan = 2 + maxHorizontalColumns
  } else {
    colSpan = 2 + flattenedRows.length
  }

  return (
    <TableProductTotalsRow>
      <TableProductDataColumn colSpan={colSpan}>
        <strong>Product totals:</strong>
      </TableProductDataColumn>
      {matrix && <TableProductDataColumn />}
      {columns.map(column => {
        const totalsOfColumn = columnTotals[column.key] || []

        return (
          <TableProductDataColumn
            key={column.key}
            style={columnConfigToStyle(column)}
          >
            {totalsOfColumn.map(rowTotal => {
              let render = column.render
              if (typeof render === 'function') {
                render = column.aggregate_render
              }

              const label = rowTotal.label

              return (
                <div key={`${rowTotal.row}-totals`}>
                  {rowTotal.totals.map(total => {
                    return (
                      <div>
                        {totalsOfColumn.length > 1 && <span>{label}: </span>}
                        {renderValue({
                          config: {
                            render: render,
                          },
                          data: {
                            context: dataContext,
                          },
                          value: [total],
                        })}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </TableProductDataColumn>
        )
      })}
      {createEmptyArray(noActionColumns).map((a, i) => (
        <TableProductDataColumn key={`actions-${i}`} />
      ))}
    </TableProductTotalsRow>
  )
}

export default memo(ProductTotalsRow)

const TableProductTotalsRow = styled(TableRow)``
const TableProductDataColumn = styled(TableColumn)`
  background: #e2e2e2 !important;
  font-weight: bold;
  vertical-align: top;
`
