/* @flow */

import React from 'react'
import styled from 'styled-components'
import { produce, original } from 'immer'

import ActionButton from '../../../../infrastructure/components/ActionButton'
import { FiltersList, FiltersMenu } from '../../../datatables_v2'
import { useProductsAttributes } from '../../hooks'
import ProductGrid from './ProductGrid'

import { fetchProducts } from './shared'
import type {
  DataTableAttribute,
  Filter,
  Id,
  Sort,
  SortDirection,
} from '../../../types'

type Props = {
  brandId: Id,
  children: Function,
  defaultFilters?: Array<Filter>,
  defaultSort?: Array<Sort>,
  onSelectProducts?: () => void,
  productsFetchData: Object,
}

const ProductGridWithControls = ({
  brandId,
  children,
  defaultFilters = [{ key: 'active', operator: 'eq', value: true }],
  defaultSort = [{ key: 'name', direction: 'asc' }],
  mode = 'list',
  onSelectProducts,
  pinnableFilters,
  pinnableFiltersOnChange,
  productsFetchData,
  selectedProducts,
  usePinnableFilters = false,
}: Props) => {
  const gridContainerRef = React.useRef(null)
  const [showFilterMenu, setShowFilterMenu] = React.useState<boolean | Filter>(
    false
  )
  const [filters, setFilters] = React.useState<Array<Filter>>(defaultFilters)
  const [sort, setSort] = React.useState<Array<Sort>>(defaultSort)
  const [attributes] = useProductsAttributes()

  const hideFilterMenu = React.useCallback(() => {
    setShowFilterMenu(false)
  }, [setShowFilterMenu])
  const toggleFilterMenu = React.useCallback(
    (filter: Filter | false) => {
      setShowFilterMenu(filter)
    },
    [setShowFilterMenu]
  )
  const onFiltersUpdate = React.useCallback(
    (
      filters: Array<Filter>,
      attribute: DataTableAttribute,
      action: 'add' | 'edit' | 'remove',
      changedFilter: Filter
    ) => {
      setFilters(filters)
      setShowFilterMenu(false)
    },
    [setFilters, setShowFilterMenu]
  )

  const fetchProductsWithinFilter = React.useCallback(() => {
    return fetchProducts(
      brandId,
      mode,
      filters,
      null,
      [],
      null,
      productsFetchData
    )
  }, [brandId, filters, mode, productsFetchData])

  const addToSelection = React.useCallback(() => {
    return fetchProductsWithinFilter().then(response => {
      if (!response.error) {
        const newSelection = produce(selectedProducts, draft => {
          for (let product of response.payload.products) {
            draft.push(product.id)
          }
        })

        onSelectProducts(newSelection)
      }
    })
  }, [fetchProductsWithinFilter, onSelectProducts, selectedProducts])

  const replaceSelection = React.useCallback(() => {
    return fetchProductsWithinFilter().then(response => {
      if (!response.error) {
        onSelectProducts(response.payload.products.map(p => p.id))
      }
    })
  }, [fetchProductsWithinFilter, onSelectProducts])

  return (
    <Container>
      <ControlsContainer>
        <LeftSideActions>
          <FiltersList
            activeFilter={showFilterMenu}
            attributes={attributes}
            filters={filters}
            onChange={onFiltersUpdate}
            onClickFilter={toggleFilterMenu}
            pinnedAttributes={usePinnableFilters ? pinnableFilters : undefined}
          >
            <FiltersMenu
              attributes={attributes}
              filters={filters}
              onChange={onFiltersUpdate}
              onPinChange={
                usePinnableFilters ? pinnableFiltersOnChange : undefined
              }
              pinnedAttributes={
                usePinnableFilters ? pinnableFilters : undefined
              }
              pinnable={usePinnableFilters}
            />
          </FiltersList>
        </LeftSideActions>

        <RightSideActions>
          {onSelectProducts && (
            <ActionButton
              className="btn btn-white btn-sm"
              onClick={replaceSelection}
            >
              Replace existing selection
            </ActionButton>
          )}

          {onSelectProducts && (
            <ActionButton
              className="btn btn-white btn-sm"
              onClick={addToSelection}
            >
              Add all to selection
            </ActionButton>
          )}
        </RightSideActions>
      </ControlsContainer>

      {children({
        filters,
        sort,
      })}
    </Container>
  )
}

export default ProductGridWithControls

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const ControlsContainer = styled.div`
  display: flex;
`

const LeftSideActions = styled.div`
  flex: 1;
`

const RightSideActions = styled.div`
  > * {
    margin-left: 10px;
  }
`

const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;

  > * {
    margin-left: 10px;
  }
`
