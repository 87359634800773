/* @flow */

import * as React from 'react'

const useCustomCss = (css: string) => {
  React.useEffect(() => {
    if (!css) {
      return
    }

    const head = document.head || document.getElementsByTagName('head')[0]
    const style = document.createElement('style')

    head.appendChild(style)

    style.type = 'text/css'
    if (style.styleSheet) {
      // This is required for IE8 and below.
      style.styleSheet.cssText = css
    } else {
      style.appendChild(document.createTextNode(css))
    }

    return () => {
      head.removeChild(style)
    }
  }, [css])
}

export default useCustomCss
