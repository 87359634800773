/* @flow */

import api from '../api'

import type { Id, ApiResponse, DataTableAttribute } from '../types'

export const getAvailableBrands = () => api.get(`/brands/available-brands`)

export const getProductsAvailableForDropShipping = (brandId: Id) =>
  api.get(`/brands/${brandId}/dropshipping/products`)

export const exportDropShippingProducts = (
  brandId: Id,
  productIds: Array<Id>,
  format: 'excel' | 'csv'
) =>
  api.post(`/brands/${brandId}/dropshipping/export-products`, {
    body: {
      product_ids: productIds,
      format,
    },
  })

export const exportDropShippingImages = (
  brandId: Id,
  productIds: Array<Id>,
  transformations
) =>
  api.post(`/brands/${brandId}/dropshipping/export-images`, {
    body: {
      product_ids: productIds,
      transformations,
    },
  })

export const getRetailerOrdersAttributes = (): Promise<
  ApiResponse<{ attributes: Array<DataTableAttribute> }>
> => api.get('/brands/orders/table-parameters')

export const getRetailerSelectionsList = (options?: DataTableRequestOptions) =>
  api.get('/brands/selections', {
    query: options,
  })

export const getRetailerSelectionsAttributes = (
  options?: DataTableRequestOptions
) => api.get('/brands/selections/table-parameters')
