/* @flow */

import * as React from 'react'
import { useEffect, useState } from 'react'

import type { DataTableAttribute } from '../types'

import { createApiHook, createDataCache, quickCreateHook } from '../hooks'
import { SessionContext } from '../shared'

import {
  getAvailableBrands,
  getProductsAvailableForDropShipping,
  getRetailerOrdersAttributes,
  getRetailerSelectionsAttributes,
} from './api'
import { fetchSingleBrandIfNoAlreadyFetching } from './actions'

export const useAvailableBrandsList = createApiHook<{
  brands: Array,
  total: number,
}>(
  (options?: Object) => {
    return getAvailableBrands(options).then(response => {
      if (response.error) {
        return response
      }

      return {
        entity: {
          brands: response.payload.brands,
          total: response.payload.total,
        },
      }
    })
  },
  { brands: [], total: 0 }
)

const { hook: useProductsAvailableForDropShipping } = quickCreateHook(
  getProductsAvailableForDropShipping,
  null,
  { products: [], currency: null }
)

export { useProductsAvailableForDropShipping }

export const useRetailerOrdersAttributes = () => {
  const [state, setState] = useState<{
    attributes: Array<DataTableAttribute>,
    isFetching: boolean,
  }>({
    attributes: [],
    isFetching: false,
  })

  useEffect(() => {
    setState(s => ({
      ...s,
      isFetching: true,
    }))

    getRetailerOrdersAttributes().then(response => {
      if (!response.error) {
        setState({
          isFetching: false,
          attributes: response.payload.attributes,
        })
      } else {
        setState(s => ({
          ...s,
          isFetching: false,
        }))
      }
    })
  }, [])

  return [state.attributes, state.isFetching]
}

const { hook: useRetailerSelectionsAttributes } = quickCreateHook(
  getRetailerSelectionsAttributes,
  'attributes',
  []
)

export { useRetailerSelectionsAttributes }

export const useFetchBrandIfNeeded = (brandId, dispatch) => {
  const { brands, entity } = React.useContext(SessionContext)

  const brand = React.useMemo(() => {
    const useBrandId = entity.entity_type === 'brand' ? entity.id : brandId

    return useBrandId ? brands[useBrandId] : null
  }, [brands, brandId, entity])

  React.useEffect(() => {
    if (!brand && brandId) {
      dispatch(fetchSingleBrandIfNoAlreadyFetching(brandId))
    }
  }, [brandId, brand, dispatch])

  return brand
}
