export const TOGGLE_FILTER_MANAGER_VISIBILITY =
  'TOGGLE_FILTER_MANAGER_VISIBILITY'
export const TOGGLE_TEMPLATE_BLANK = 'TOGGLE_TEMPLATE_BLANK'
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'

export const toggleFilterManagerVisibility = isVisible => ({
  type: TOGGLE_FILTER_MANAGER_VISIBILITY,
  isVisible,
})

export const toggleTemplateBlank = blank => ({
  type: TOGGLE_TEMPLATE_BLANK,
  blank,
})

export const showLoader = () => ({
  type: SHOW_LOADER,
})

export const hideLoader = () => ({
  type: HIDE_LOADER,
})
