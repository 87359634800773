/* @flow */

import React, { useMemo } from 'react'
import sortBy from 'lodash/sortBy'
import { SelectWrapper } from './Forms'

import { useCachedCountries } from '../localization'

const CountrySelector = ({ excludeOptions, ...props }) => {
  const [countries, { isFetching }] = useCachedCountries()

  const options = useMemo(() => {
    let options = sortBy(
      Object.keys(countries).map(key => ({
        value: key,
        label: countries[key],
      })),
      'label'
    )

    if (excludeOptions) {
      options = options.filter(
        collection => !excludeOptions.includes(collection.id)
      )
    }

    return options
  }, [countries, excludeOptions])

  return (
    <SelectWrapper
      isLoading={isFetching}
      placeholder="Select country ..."
      options={options}
      {...props}
    />
  )
}

export default CountrySelector
