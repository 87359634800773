import { REQUEST_COUNTRIES, RECEIVE_COUNTRIES } from '../actions/misc'
import {
  GET_PAYMENT_TERMS_REQUEST,
  GET_PAYMENT_TERMS_SUCCESS,
  CREATE_PAYMENT_TERM_SUCCESS,
} from '../../app/payment-terms/actions'
import {
  GET_BANK_ACCOUNTS_REQUEST,
  GET_BANK_ACCOUNTS_SUCCESS,
  CREATE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_SUCCESS,
} from '../../app/bank-accounts/actions'
import { DATATABLE_DATA_SUCCESS } from '../actions/datatable'
import { sortBy } from 'lodash'

/**
 *
 * @type {{countries: {isFetching: boolean, items: Array}}}
 */
const initialState = {
  countries: {
    isFetching: false,
    items: [],
  },
  payment_terms: {
    isFetching: false,
    items: [],
  },
  bank_accounts: {
    isFetching: false,
    items: [],
  },
}

export default function mics(state = initialState, action) {
  switch (action.type) {
    case DATATABLE_DATA_SUCCESS:
      if (action.meta && action.meta.dataKey == 'payment_terms') {
        return Object.assign({}, state, {
          payment_terms: Object.assign({}, state.payment_terms, {
            isFetching: false,
            items: action.payload.payment_terms,
          }),
        })
      }

      if (action.meta && action.meta.dataKey == 'bank_accounts') {
        return Object.assign({}, state, {
          bank_accounts: Object.assign({}, state.bank_accounts, {
            isFetching: false,
            items: action.payload.bank_accounts,
          }),
        })
      }

      return state
    case REQUEST_COUNTRIES:
      return Object.assign({}, state, {
        countries: Object.assign({}, state.countries, {
          isFetching: true,
        }),
      })
      break
    case GET_PAYMENT_TERMS_REQUEST:
      return Object.assign({}, state, {
        payment_terms: Object.assign({}, state.payment_terms, {
          isFetching: true,
        }),
      })
    case GET_PAYMENT_TERMS_SUCCESS:
      return Object.assign({}, state, {
        payment_terms: Object.assign({}, state.payment_terms, {
          isFetching: false,
          items: action.payload.payment_terms,
        }),
      })
    case CREATE_PAYMENT_TERM_SUCCESS:
      return Object.assign({}, state, {
        payment_terms: Object.assign({}, state.payment_terms, {
          items: [...state.payment_terms.items, action.payload],
        }),
      })
    case GET_BANK_ACCOUNTS_REQUEST:
      return Object.assign({}, state, {
        bank_accounts: Object.assign({}, state.bank_accounts, {
          isFetching: true,
        }),
      })
    case GET_BANK_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        bank_accounts: Object.assign({}, state.bank_accounts, {
          isFetching: false,
          items: action.payload.bank_accounts,
        }),
      })
    case CREATE_BANK_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        bank_accounts: Object.assign({}, state.bank_accounts, {
          items: [...state.bank_accounts.items, action.payload],
        }),
      })
    case DELETE_BANK_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        bank_accounts: Object.assign({}, state.bank_accounts, {
          items: state.bank_accounts.items.filter(
            account => account.id !== action.meta.id
          ),
        }),
      })
    case RECEIVE_COUNTRIES:
      let options = Object.keys(action.countries).map(code => ({
        label: action.countries[code],
        value: code,
      }))
      options = options.filter(option => Boolean(option.label))
      options = sortBy(options, 'label')

      return Object.assign({}, state, {
        countries: Object.assign({}, state.countries, {
          isFetching: false,
          items: options,
        }),
      })
      break
    default:
      return state
  }
}
