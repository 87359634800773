/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { renderCustomField } from '../../../../../custom-fields/shared'

import type { RowConfig } from '../../types'
import type { CustomField } from '../../../../../types'

type Options = {
  customField: CustomField,
}

const custom_field: (options: Options) => RowConfig = ({ customField }) => {
  return {
    data_source: '__product',
    key: `product_${customField.internal_name}`,
    label: customField.label,
    render: ({ product }) => {
      return renderCustomField(
        customField,
        product.meta[customField.internal_name]
      )
    },
  }
}

export default custom_field
