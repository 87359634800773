/* @flow */

import api from '../api'

import type {
  Address,
  Customer,
  CustomerGroup,
  DataTableCustomTab,
  Entity,
  Id,
  User,
} from '../types'

export const getDataTableTabs = (tableId: string) =>
  api.get(`/data-tables/${tableId}/tabs`)

export const getDataTableTabSettings = (tableId: string) =>
  api.get(`/data-tables/${tableId}/tabs/settings`)

export const createDataTableTab = (
  tableId: string,
  tab: $Shape<DataTableCustomTab>
) =>
  api.post(`/data-tables/${tableId}/tabs`, {
    body: {
      tab: tab,
    },
  })

export const deleteDataTableTab = (tableId: string, id: Id) =>
  api.delete(`/data-tables/${tableId}/tabs/${id}`)

export const updateDataTableTab = (
  tableId: string,
  id: Id,
  tab: $Shape<DataTableCustomTab>
) =>
  api.put(`/data-tables/${tableId}/tabs/${id}`, {
    body: {
      tab: tab,
    },
  })

export const updateDataTableTabs = (
  tableId: string,
  tabs: $Shape<DataTableCustomTab>
) =>
  api.put(`/data-tables/${tableId}/tabs`, {
    body: {
      tabs: tabs,
    },
  })

export const saveDataTableSettings = (tableId: string, settings: Object) =>
  api.put(`/data-tables/${tableId}/settings`, {
    body: {
      settings: settings,
    },
  })
