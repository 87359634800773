/* @flow */

import * as React from 'react'
import styled from 'styled-components'

type Props = {}

const InputField = ({ onControlKey, ...props }, ref) => {
  const onWheel = React.useCallback(e => {
    e.target.blur()
  }, [])

  return (
    <StyledInputField type="number" onWheel={onWheel} ref={ref} {...props} />
  )
}

export default React.forwardRef(InputField)

const StyledInputField = styled.input`
  &[type='text'],
  &[type='number'] {
    border: 1px solid #aaaaaa;
    border-radius: 0px;
    color: #6f7b8a;
    font-family: 'proxima-nova', sans-serif;
    font-size: 11px;
    height: 100%;
    min-height: inherit;
    padding: 0 3px;

    text-align: ${({ right }) => (right ? 'right' : 'left')};
    width: 100%;

    &[disabled] {
      background-color: #eeeeee;
      opacity: 1;
    }

    &:focus {
      background: white;
      border-color: #707070;
    }
  }
`
