/* @flow */

import React from 'react'
import styled from 'styled-components'
import isObject from 'lodash/isObject'

import FormatCurrency from '../../infrastructure/components/FormatCurrency'
import CountryLabel from '../../infrastructure/components/CountryLabel'
import { renderDate } from '../shared'

export const getRequiredCustomFields = (customFields, meta, entity) => {
  let requiredCustomField = null
  // we cannot use forEach() here since you cannot simply terminate it using "break" statement
  for (let i = 0, len = customFields.length; i < len; i++) {
    const customField = customFields[i]
    // if required field has not been touched = throw validation error
    // if required custom fueld is set with empty string = throw validation error
    const isEmptyMetaField =
      !meta[customField.internal_name] ||
      meta[customField.internal_name] === null ||
      meta[customField.internal_name].length === 0
    if (
      isCustomFieldRequired(entity.entity_type === 'brand', customField) &&
      (meta.hasOwnProperty(customField.internal_name) ? isEmptyMetaField : true)
    ) {
      requiredCustomField = customField
      break
    }
  }

  return requiredCustomField
}

export const productRequiredForBrandPredicate = field =>
  field.visible &&
  field.group_type === 'product' &&
  field.required_for === 'brand'

export const isCustomFieldRequired = (isBrand, field) => {
  return isBrand
    ? field.required_for === 'brand' || field.required_for === 'brand_retailer'
    : field.required_for === 'retailer' ||
        field.required_for === 'brand_retailer'
}

export const isCustomFieldVisible = (isBrand, field) => {
  return isBrand
    ? field.visibility === 'brand' || field.visibility === 'brand_retailer'
    : field.visibility === 'retailer' || field.visibility === 'brand_retailer'
}

export const renderCustomField = (customField, value) => {
  if (!value) {
    return null
  }

  let useValue = value

  if (
    customField &&
    value &&
    ['select', 'multi_select'].includes(customField.field_type)
  ) {
    const selectedOption = customField.options.find(o => o.value == value)

    if (selectedOption && selectedOption.label) {
      useValue = selectedOption.label
    }
  }

  if (customField.field_type === 'multi_select') {
    // This can happen when an empty array is saved in API, it might come back
    // as an empty object instead of empty array
    if (isObject(useValue)) {
      useValue = Array.from(useValue)
    }

    if (Array.isArray(useValue)) {
      return useValue.join(', ')
    }
  }

  if (customField.field_type === 'datepicker') {
    return renderDate(useValue)
  }

  if (customField.field_type === 'checkbox') {
    return useValue === true ? <CheckboxCheckMark /> : null
  }

  if (customField.field_type === 'country') {
    return <CountryLabel country={useValue} />
  }

  if (customField.field_type === 'money') {
    return (
      <FormatCurrency currency={customField.options[0]}>
        {useValue}
      </FormatCurrency>
    )
  }

  return useValue
}

const CheckboxCheckMark = styled.span.attrs({
  className: 'glyphicon glyphicon-ok',
})`
  font-size: 12px;
`
