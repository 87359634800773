import React from 'react'
import { createAction } from '../shared'
import {
  CheckmarkDropdown,
  CheckmarkDropdownButton,
} from '../../../../../infrastructure/components/Dropdown'

class StylingMenu extends React.Component {
  constructor(props) {
    super(props)

    const selected = []

    if (props.tableOptions.matrix) {
      selected.push('matrix')
    }

    if (props.tableOptions.bordered) {
      selected.push('bordered')
    }

    this.state = {
      selected: selected,
    }

    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    if (this.props.tableOptions.rehydrateTableOptions) {
      this.restoreStateFromStorage()
    }
  }

  onChange(newValue) {
    this.setState(
      {
        selected: newValue,
      },
      () => {
        this.saveStateToStorage()
        this.props.callback(createStylingObject(newValue))
      }
    )
  }

  restoreStateFromStorage() {
    const storage = localStorage.getItem(createStorageKey(this.props.tableKey))

    if (!storage) {
      return
    }

    this.setState(
      {
        selected: JSON.parse(storage),
      },
      () => {
        this.props.callback(createStylingObject(this.state.selected))
      }
    )
  }

  saveStateToStorage() {
    localStorage.setItem(
      createStorageKey(this.props.tableKey),
      JSON.stringify(this.state.selected)
    )
  }

  render() {
    const { selected } = this.state

    return (
      <CheckmarkDropdown
        onChange={this.onChange}
        id="product_table_styling"
        selected={selected}
        title="Styling"
      >
        <CheckmarkDropdownButton value="bordered" label="Bordered" />
        <CheckmarkDropdownButton value="matrix" label="Matrix" />
      </CheckmarkDropdown>
    )
  }
}

export const createStylingMenu = (callback = () => {}) => {
  const selected = []

  return createAction(
    (styling, instance) => {
      instance.setTableOption(styling)
      callback(styling)
    },
    StylingMenu,
    [],
    {
      selected,
    }
  )
}

export default createStylingMenu

const createStorageKey = tableKey => `${tableKey}_styling`

const createStylingObject = selected => {
  const keys = ['bordered', 'matrix']
  const styling = {}

  for (var i in keys) {
    styling[keys[i]] = selected.indexOf(keys[i]) !== -1
  }

  return styling
}
