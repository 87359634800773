/* @flow */

import * as React from 'react'

const useRefValue = (value, defaultValue = null) => {
  const valueRef = React.useRef(defaultValue)

  React.useEffect(() => {
    valueRef.current = value
  }, [value, valueRef])

  return valueRef
}

export default useRefValue
