/* @flow */

import * as React from 'react'
import styled from 'styled-components'

import NewCustomerModal from './NewCustomerModal'
import { useCustomersList, useCachedCustomersList } from '../hooks'
import { DatalessCustomerSelector } from './CustomerSelector'

const CustomerSelectorWithQuickCreateAction = ({
  cached = false,
  onChange,
  simpleValue,
  value,
  ...rest
}) => {
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] =
    React.useState(false)

  const [customers, isFetchingCustomers, { override }] = cached
    ? useCachedCustomersList()
    : useCustomersList()

  const onCustomerCreated = React.useCallback(
    newCustomer => {
      override(hookState => ({
        ...hookState,
        entity: [...hookState.entity, newCustomer],
      }))

      if (simpleValue) {
        onChange(newCustomer.id)
      } else {
        onChange(newCustomer)
      }

      setShowCreateNewCustomerModal(false)
    },
    [onChange, simpleValue, override, setShowCreateNewCustomerModal]
  )

  return (
    <>
      {showCreateNewCustomerModal !== false && (
        <NewCustomerModal
          onCreated={onCustomerCreated}
          onHide={() => setShowCreateNewCustomerModal(false)}
          show={showCreateNewCustomerModal}
        />
      )}

      <Container>
        <SelectContainer>
          <DatalessCustomerSelector
            customers={customers}
            onChange={onChange}
            simpleValue={simpleValue}
            value={value}
            {...rest}
          />
        </SelectContainer>
        <ActionsContainer>
          <button
            type="button"
            className="btn btn-white"
            onClick={() => setShowCreateNewCustomerModal(true)}
          >
            <span className="glyphicon glyphicon-plus" /> New
          </button>
        </ActionsContainer>
      </Container>
    </>
  )
}

export default CustomerSelectorWithQuickCreateAction

const Container = styled.div`
  display: flex;
`

const SelectContainer = styled.div`
  flex: 1;
`

const ActionsContainer = styled.div`
  margin-left: 10px;
`
