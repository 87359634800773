import { CALL_API } from './api'
import { isFunction, isPlainObject } from 'lodash'
import { ApiError, getJSON } from 'redux-api-middleware'

export const CALL_API_BATCH = Symbol('Batch Call Api')

export const hasError = successAction =>
  successAction.payload instanceof ApiError

export const isBatchRSAA = action => action.hasOwnProperty(CALL_API_BATCH)

export const getBatchError = responses => {
  const highest = Object.keys(responses).sort(
    (a, b) => responses[b].statusCode - responses[a].statusCode
  )
  return responses[highest[0]]
}

const parseBatchResponse = responses => {
  return Object.keys(responses).reduce((carry, dataKey) => {
    carry[dataKey] = responses[dataKey].data
    return carry
  }, {})
}

const convertBatchRSAA = action => {
  let { types, ...rest } = action[CALL_API_BATCH]
  const { actions, ...actionProperties } = rest

  if (!isPlainObject(types[1])) {
    types[1] = {
      type: types[1],
    }
  }

  const baseFunction = types[1].payload
  types[1].payload = (action, state, res) => {
    return getJSON(res).then(json => {
      if (json.status === 'error') {
        const { statusCode, data } = getBatchError(json.responses)
        return new ApiError(statusCode, 'An error occurred', data)
      }

      let parsed = parseBatchResponse(json.responses)

      if (isFunction(baseFunction)) {
        parsed = baseFunction(action, state, parsed)
      }

      return parsed
    })
  }

  return {
    [CALL_API]: Object.assign({}, actionProperties, {
      endpoint: '/batch',
      method: 'POST',
      types,
      body: {
        actions,
      },
    }),
  }
}

export default store => next => action => {
  if (isBatchRSAA(action)) {
    action = convertBatchRSAA(action)
  }

  return next(action)
}
