/* @flow */

import * as React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withBreakpoints } from 'react-breakpoints'
import type {
  Location as LocationType,
  Match as MatchType,
  RouterHistory,
} from 'react-router-dom'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import memoize from 'memoize-one'
import querystring from 'qs'

import {
  changeWebshopCurrency,
  ensureWebshopSession,
  fetchWebshopTotals,
  getWebshopSessions,
} from './actions/index'
import { getCustomFields } from '../custom-fields/actions'
import {
  changeBrandContext,
  getAvailableBrands,
  fetchBrands,
} from '../brands/actions/index'
import { getDrops } from '../orders/actions/drops'
import localStorage from '../../infrastructure/modules/localStorage'
import { updateWebshopSession } from './actions'
import { switchEntity } from '../template/actions'
import { getCustomersList } from '../customers/api'
import { WebshopContext } from './shared'

import type {
  AvailableBrand,
  Id,
  ConnectedBrand,
  Currency,
  Customer,
  CustomField,
  Drop,
  Entity,
  RetailerConnection,
  User,
  WebshopSession as WebshopSessionType,
} from '../types'
import WebshopTemplate from './WebshopTemplate'
import WebshopSplash, { shouldShowSplashForMe } from './WebshopSplash'
import { mixpanelTrack, trackMixpanel } from '../shared'

type Props = {
  brands: { [string]: ConnectedBrand },
  brandsIsFetching: boolean,
  currentWebshopCurrency: ?Currency,
  children?: React.Node,
  dispatch: Function,
  entity: Entity,
  history: RouterHistory,
  location: LocationType,
  match: MatchType,
  user: User,
}

type State = {
  availableBrand: null | AvailableBrand,
  availableBrands: Array<AvailableBrand>,
  brand: null | ConnectedBrand,
  completedCustomerSelection: boolean,
  completedSplashDataInput: boolean,
  customers: Array<Customer>,
  customFields: Array<CustomField>,
  drops: Array<Drop>,
  errorCode: string,
  isFetching: boolean,
  isWebshopSessionUpdating: boolean,
  renderTemplate: boolean,
  showCustomerSelector: boolean,
  showErrorModal: boolean,
  showRetailerCustomerSelectionModal: boolean,
  showNoAccessModal: boolean,
  showWebshopSplash: boolean,
  webshopSessions: Array<WebshopSessionType>,
  webshopSession: null | WebshopSessionType,
}

class WebshopSession extends Component<Props, State> {
  state = {
    availableBrand: null,
    availableBrands: [],
    brand: null,
    completedCustomerSelection: false,
    completedSplashDataInput: false,
    customers: [],
    customFields: [],
    drops: [],
    errorCode: '',
    isFetching: false,
    isWebshopSessionUpdating: false,
    renderTemplate: false,
    showCustomerSelector: false,
    showErrorModal: false,
    showRetailerCustomerSelectionModal: false,
    showNoAccessModal: false,
    showWebshopSplash: false,
    webshopSessions: [],
    webshopSession: null,
  }

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { brandId },
      },
    } = this.props

    this.setState({
      isFetching: true,
    })

    mixpanelTrack('webshop.open', {
      brand_id: brandId,
    })

    dispatch(fetchBrands())

    Promise.all([
      dispatch(getAvailableBrands()),
      getCustomersList(),
      dispatch(getWebshopSessions()),
    ]).then(
      ([
        availableBrandsResponse,
        customersListResponse,
        webshopSessionsResponse,
      ]) => {
        const changes: $Shape<State> = { isFetching: false }

        if (!availableBrandsResponse.error) {
          changes.availableBrands = availableBrandsResponse.payload.brands
        }

        if (!customersListResponse.error) {
          changes.customers = customersListResponse.payload.customers
        }

        if (!webshopSessionsResponse.error) {
          changes.webshopSessions = webshopSessionsResponse.payload.sessions
        }

        this.setState(changes, () => {
          this._initializeFlow()
        })
      }
    )
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.brandsIsFetching !== this.props.brandsIsFetching ||
      prevProps.match.params.brandId !== this.props.match.params.brandId
    ) {
      this._initializeFlow()
    }
  }

  componentWillUnmount() {
    this.props.dispatch(changeBrandContext(null))
  }

  render() {
    const {
      availableBrand,
      brand,
      errorCode,
      showCustomerSelector,
      showErrorModal,
      showNoAccessModal,
      webshopSession,
    } = this.state
    const { entity } = this.props

    // Since brand is needed for the content but not for the modals like NoAccessModal, we separate it into
    // 2 render functions

    // Make sure to rerender everything if entity changes. This is especially important if you are a retailer that switches between
    // entities inside the webshop, otherwise WebshopTemplate will not remount and you loose your brandcontext
    return (
      <div key={entity.id}>
        <ErrorModal errorCode={errorCode} show={showErrorModal} />
        <NoAccessModal show={showNoAccessModal} />
        {availableBrand && (
          <Modal show={showCustomerSelector}>
            <Modal.Body>
              <p>
                {availableBrand.name} has added you as 2 separate customers. You
                must therefore choose which customer you wish to shop as. Do not
                worry, you can change this again later.
              </p>
              <table className="table">
                <thead>
                  <tr>
                    <th>Company</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {availableBrand.retailer_connections.map(connection => {
                    return (
                      <tr>
                        <td>{connection.shop_name}</td>
                        <td className="listview-action">
                          <button
                            type="button"
                            className="btn btn-block btn-success btn-sm"
                            onClick={() => this._loginToConnection(connection)}
                          >
                            Shop as {connection.shop_name}
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Modal.Body>
          </Modal>
        )}
        {brand && webshopSession && this._renderContent()}
      </div>
    )
  }

  _renderContent = () => {
    const {
      children,
      currentWebshopCurrency,
      currentBreakpoint,
      entity,
      location,
      history,
      match,
      totals,
      ...rest
    } = this.props
    const {
      availableBrand,
      brand,
      customers,
      drops,
      isWebshopSessionUpdating,
      renderTemplate,
      showWebshopSplash,
      webshopSession,
      webshopSessions,
    } = this.state

    if (!brand) {
      return null
    }

    const isMobile = currentBreakpoint === 'mobile'

    return (
      <div>
        <WebshopContext.Provider
          value={createWebshopContext(
            brand,
            currentWebshopCurrency,
            drops,
            this._fetchWebshopTotals,
            isMobile,
            isWebshopSessionUpdating,
            this._setCurrency,
            totals,
            this._updateWebshopSession,
            webshopSession
          )}
        >
          <WebshopSplash
            brand={brand}
            customers={customers}
            customFields={brand.custom_fields}
            drops={brand.drops}
            entity={entity}
            initialValues={webshopSession}
            onSubmit={this._updateWebshopSession}
            show={showWebshopSplash}
            webshopSettings={brand.settings.webshop_settings}
            webshopSession={webshopSession}
          />
          {renderTemplate === true && brand && webshopSession && (
            /*
                Force us to remount the webshoptemplate when the session is changed.
                We do this to e.g. re-fech the navigation when drop changes
              */
            <WebshopTemplate
              availableBrand={availableBrand}
              brand={brand}
              history={history}
              location={location}
              onUpdateCustomerConnection={this._toggleCustomerSelector}
              onUpdateSession={this._showWebshopSplash}
              match={match}
              updateWebshopSession={this._updateWebshopSession}
              webshopSettings={brand.settings.webshop_settings}
              webshopSession={webshopSession}
            >
              {children}
            </WebshopTemplate>
          )}
        </WebshopContext.Provider>
      </div>
    )
  }

  _ensureBrand = () => {
    return new Promise((resolve, reject) => {
      const {
        brands,
        match: {
          params: { brandId },
        },
      } = this.props

      if (!brandId) {
        resolve(false)
        return
      }

      const brand = brands[brandId]

      if (!brand) {
        resolve(false)
        return
      }

      this.setState(
        {
          brand: brand,
          customFields: brand.custom_fields,
          drops: brand.drops.filter(d => d.active),
        },
        () => resolve(true)
      )
    })
  }

  _ensureCustomer = () => {
    return new Promise((resolve, reject) => {
      const {
        dispatch,
        entity,
        history,
        match: {
          params: { brandId },
        },
        user,
      } = this.props
      const { availableBrand, webshopSession } = this.state

      if (!webshopSession) {
        resolve(false)
        return
      }

      const complete = () => {
        this.setState(
          {
            completedCustomerSelection: true,
          },
          () => resolve(true)
        )
      }

      if (entity.id == parseInt(brandId)) {
        complete()
        return
      }

      // Brands do not have availableBrand set so we check only for retailers
      if (!availableBrand) {
        resolve(false)
        return
      }

      if (
        webshopSession.shop_id === null ||
        webshopSession.customer_id === null
      ) {
        resolve(false)
        return
      }

      const connectionsWithWebshopAccess =
        availableBrand.retailer_connections.filter(connection => {
          return connection.b2b_access === true
        })

      if (connectionsWithWebshopAccess.length === 0) {
        throw new Error('No available webshop access customers found')
      }

      const connection = availableBrand.retailer_connections.filter(c => {
        return (
          c.shop_id === entity.id &&
          c.customer_id === webshopSession.customer_id
        )
      })[0]

      if (!connection || !connection.b2b_access) {
        resolve(false)
        return
      }

      complete()
    })
  }

  _ensureSplashData = () => {
    return new Promise((resolve, reject) => {
      const {
        brands,
        entity,
        match: {
          params: { brandId },
        },
        user,
      } = this.props
      const { brand, webshopSession } = this.state

      if (!brand || !webshopSession) {
        resolve(false)
        return
      }

      const webshopSettings = brand.settings.webshop_settings

      const complete = () => {
        this.setState(
          {
            completedSplashDataInput: true,
            showWebshopSplash: false,
          },
          () => {
            resolve(true)
          }
        )
      }

      const shouldShowForMe = shouldShowSplashForMe(
        webshopSettings,
        entity,
        user
      )

      if (!webshopSettings.splash_show || !shouldShowForMe) {
        complete()
        return
      }

      let missingData = false
      webshopSettings.splash_fields.forEach(property => {
        if (!webshopSession[property]) {
          missingData = true
        }
      })

      webshopSettings.splash_meta_fields.forEach(property => {
        if (!webshopSession.meta[property]) {
          missingData = true
        }
      })

      if (missingData) {
        resolve(false)
        return
      }

      complete()
    })
  }

  _ensureSession = () => {
    const {
      dispatch,
      entity,
      match: {
        params: { brandId },
      },
    } = this.props

    return new Promise(resolve => {
      const q = querystring.parse(location.search.slice(1))

      const extraData = {}

      // push linesheet link to webshop navigation
      extraData.linesheet_id = q.linesheet_id
      extraData.mailchimp_campaign_id = q.mc_cid
      extraData.mailchimp_tracking_code = q.mc_tc

      dispatch(ensureWebshopSession(brandId, extraData)).then(response => {
        if (!response.error) {
          const ensuredSession = response.payload.session

          dispatch(getWebshopSessions()).then(response => {
            if (!response.error) {
              const webshopSessions = response.payload.sessions

              const currentStoredSessionKey = createWebshopSessionKey(
                entity.id,
                parseInt(brandId)
              )
              let currentSession = webshopSessions.filter(
                s => s.id == currentStoredSessionKey
              )[0]
              if (!currentSession) {
                currentSession = ensuredSession
              }

              if (currentSession.currency) {
                this.props.dispatch(
                  changeWebshopCurrency(currentSession.currency)
                )
              }

              this._setSession(currentSession).then(resolve)
            } else {
              this._showErrorModal('COULD_NOT_FETCH_WEBSHOP_SESSIONS')
            }
          })
        } else {
          this._showErrorModal('COULD_NOT_ENSURE_WEBSHOP_SESSION')
        }
      })
    })
  }

  _initializeFlow = () => {
    const {
      brands,
      brandsIsFetching,
      dispatch,
      entity,
      match: {
        params: { brandId },
      },
      user,
    } = this.props
    const { availableBrands, brand, customers, isFetching, renderTemplate } =
      this.state

    if (isFetching || !brandId || brandsIsFetching) {
      return
    }

    // Do not reinitialize flow when the current brand did not change. This occurs if a deep component
    // fires fetchBrands() or similar that causes componentWillReceiveProps to restart the flow.
    if (renderTemplate === true && brand && brand.id === parseInt(brandId)) {
      return
    }

    const startFlow = () => {
      const availableBrand = this.state.availableBrand

      if (entity.id !== parseInt(brandId) && !availableBrand) {
        this.setState({
          showNoAccessModal: true,
        })
        return
      }

      /**
      We start by making sure there is a webshop session between the user and the brand.
     */
      this._ensureSession()
        /**
        First we ensure that the user is logged into a valid shop entity, i.e. a shop entity that
        is connected to the brand in question. The flow is as follows:
        (1) If the user is not logged into an entity that is connected to the brand, login
        (2) If there are more than 1 entities or customer connections show a selector
      */
        .then(() => this._ensureCustomer())
        .then(completed => {
          if (!completed) {
            this._loginToSingleConnectionOrShowCustomerSelector()
          } else {
            this._ensureBrand().then(completed => {
              // We only handle the positive case here, since the negative case is that brands
              // are simply not loaded in redux yet. When they load componentWillReceiveProps will
              // restart the flow and completed will be true.
              if (completed) {
                this._ensureSplashData().then(completed => {
                  if (!completed) {
                    return this._showWebshopSplash()
                  } else {
                    this._renderTemplate()
                  }
                })
              }
            })
          }
        })
    }

    // Ensure that subviews are removed so they do not have stale data when switching from one brand
    // to another. (e.g. brand_settings)
    const changes: $Shape<State> = {
      brand: null,
      renderTemplate: false,
    }

    // We start by setting the availble brand if is not set or if it changes (e.g. when the user
    // changes request brandId parameter)
    let availableBrand = this.state.availableBrand
    if (!availableBrand || availableBrand.id !== parseInt(brandId)) {
      availableBrand = availableBrands.filter(
        b => b.id === parseInt(brandId)
      )[0]

      changes.availableBrand = availableBrand
    }

    this.setState(changes, startFlow)
  }

  _loginToConnection = (connection: RetailerConnection) => {
    return new Promise(resolve => {
      const {
        dispatch,
        match: {
          params: { brandId },
        },
        user,
      } = this.props
      const shopId = connection.shop_id

      if (!brandId) {
        return
      }

      const complete = () => {
        let currency
        if (connection.allowed_currencies.length > 0) {
          currency = connection.allowed_currencies[0].currency
        }

        this.setState({
          showCustomerSelector: false,
        })

        this._updateWebshopSession({
          customer_id: connection.customer_id,
          currency: currency,
          shop_id: shopId,
        })
      }

      if (user.entity_id != shopId) {
        dispatch(switchEntity(shopId)).then(() => {
          this.props.history.push(`/shop/${brandId}`)
          dispatch(fetchBrands())
          complete()
        })
      } else {
        complete()
      }
    })
  }

  _loginToSingleConnectionOrShowCustomerSelector = () => {
    const { availableBrand } = this.state
    const { entity } = this.props

    if (!availableBrand) {
      return
    }

    const connections = availableBrand.retailer_connections

    if (connections.length > 1) {
      this._toggleCustomerSelector()
    } else {
      this._loginToConnection(connections[0])
    }
  }

  _renderTemplate = () => {
    const { completedCustomerSelection, completedSplashDataInput } = this.state

    if (completedCustomerSelection && completedSplashDataInput) {
      this.setState({
        renderTemplate: true,
      })
    }
  }

  _fetchWebshopTotals = () => {
    const { currentWebshopCurrency } = this.props
    const { brand, webshopSession } = this.state

    if (brand && webshopSession) {
      this.props.dispatch(
        fetchWebshopTotals(brand.id, currentWebshopCurrency, webshopSession.id)
      )
    }
  }

  _setCurrency = newCurrency => {
    this.props.dispatch(changeWebshopCurrency(newCurrency))
  }

  _setSession = (session: WebshopSessionType) => {
    const { entity } = this.props

    return new Promise(resolve => {
      const key = createWebshopSessionKey(entity.id, session.brand_id)
      localStorage.setItem(key, session.id)

      this.setState(
        {
          webshopSession: session,
        },
        resolve
      )
    })
  }

  _showErrorModal = (errorCode: string) => {
    this.setState({
      errorCode: errorCode,
      showErrorModal: true,
    })
  }

  _showWebshopSplash = () => {
    this.setState({
      showWebshopSplash: true,
    })
  }

  _toggleCustomerSelector = () => {
    this.setState({
      showCustomerSelector: !this.state.showCustomerSelector,
    })
  }

  _updateWebshopSession = values => {
    const {
      match: {
        params: { brandId },
      },
    } = this.props
    const { webshopSession } = this.state

    if (!webshopSession) {
      return
    }

    this.setState({
      isWebshopSessionUpdating: true,
    })

    return this.props
      .dispatch(updateWebshopSession(brandId, webshopSession.id, values))
      .then(response => {
        if (!response.error) {
          const updatedSession = response.payload

          const webshopSessions = [...this.state.webshopSessions]
          const index = webshopSessions.findIndex(
            s => s.id == webshopSession.id
          )

          webshopSessions[index] = updatedSession

          const didDropChange =
            webshopSession.drop_id !== updatedSession.drop_id

          this.setState(
            {
              isWebshopSessionUpdating: false,
              webshopSession: updatedSession,
              webshopSessions: webshopSessions,
              showWebshopSplash: false,
            },
            () => {
              if (values.currency) {
                this.props.dispatch(changeWebshopCurrency(values.currency))
              }

              // Refetch shipping
              this._fetchWebshopTotals()

              // Redirect to webshop home when drop changes, because if the user
              // was browsing through products, after the drop change they often see
              // just empty shop
              if (didDropChange) {
                this.props.history.push(`/shop/${brandId}`)
              }

              this._initializeFlow()
            }
          )
        }
      })
  }
}

export default connect(state => ({
  brands: state.brands.items,
  brandsIsFetching: state.brands.isFetching,
  currentWebshopCurrency: state.webshop.currency,
  entity: state.session.entity,
  totals: state.webshop.totals,
  user: state.session.user,
}))(withBreakpoints(WebshopSession))

const ErrorModal = ({
  errorCode,
  show,
}: {
  errorCode: string,
  show: boolean,
}) => {
  return (
    <Modal show={show}>
      <Modal.Body>
        <p>
          An error occurred. This was not supposed to happen. Please contact
          Traede support at{' '}
          <a href="mailto:support@traede.com">support@traede.com</a> or
          telephone (+45) 71 99 00 66 and give them this code: {errorCode}
        </p>
      </Modal.Body>
    </Modal>
  )
}

const NoAccessModal = ({ show }: { show: boolean }) => {
  return (
    <Modal show={show}>
      <Modal.Body>
        <p>
          You do not have access to this webshop. If you think this is a mistake
          please contact Traede support at{' '}
          <a href="mailto:support@traede.com">support@traede.com</a> or
          telephone (+45) 71 99 00 66.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Link to="/" className="btn btn-success">
          Go back to Traede
        </Link>
      </Modal.Footer>
    </Modal>
  )
}

const RetailerCustomerSelectionModal = ({
  onHide,
  onSubmit,
  show,
}: {
  onHide: Function,
  onSubmit: Function,
  show: boolean,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>HELLO</Modal.Body>
    </Modal>
  )
}

const createWebshopSessionKey = (myEntityId: Id, brandId: Id) =>
  `webshop_session_${myEntityId}_${brandId}`

const createWebshopContext = memoize(
  (
    brand,
    currency,
    drops,
    fetchWebshopTotals,
    isMobile,
    isWebshopSessionUpdating,
    setCurrency,
    totals,
    updateWebshopSession,
    webshopSession
  ) => ({
    brand,
    currency,
    drops,
    fetchWebshopTotals,
    isMobile,
    isWebshopSessionUpdating,
    setCurrency,
    totals,
    updateWebshopSession,
    webshopSession,
  })
)
