/* @flow */

import React, { type Node } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

type ContentHeaderProps = {
  actions?: Array<Node>,
  breadcrumbs: Array<{ link?: string, label: string }>,
  className?: string,
}

const TopContainer = styled.div`
  display: flex;
`

TopContainer.Headline = styled.div`
  color: #000;
  font-size: 24px;
`

TopContainer.Actions = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  > * {
    margin-left: 15px;
  }
`

export { TopContainer }

export const ContentHeader = (props: ContentHeaderProps) => {
  const { breadcrumbs = [], actions = [], className = '' } = props
  const length = breadcrumbs.length
  const breadcrumbsElements = breadcrumbs.map(function (entry, index) {
    // If last entry, do not create link.
    if (index === length - 1) {
      return <span key={index}>{entry.label}</span>
    } else {
      if (!entry.link) {
        return <span key={index}>{entry.label} / </span>
      } else {
        return (
          <span key={index}>
            <Link to={entry.link}>{entry.label}</Link> /{' '}
          </span>
        )
      }
    }
  })

  return (
    <div className={`content-topbar ${className}`}>
      <div className="content-breadcrumbs">
        <h1>{breadcrumbsElements}</h1>
      </div>
      <div className="content-actions">{actions}</div>
    </div>
  )
}

type ContentProps = {
  className?: string,
  children?: Node,
  noBreadcrumbs?: boolean,
  scroll?: boolean,
}

export const Content = ({
  className = '',
  children,
  noBreadcrumbs = false,
  scroll = false,
  ...rest
}: ContentProps) => {
  const noBreadcrumbsClass = noBreadcrumbs ? 'content-no-breadcrumbs' : ''
  const scrollClass = scroll ? 'content-scroll-container' : ''

  /* id is used for portals */
  return (
    <div
      className={`content ${noBreadcrumbsClass} ${scrollClass} ${className}`}
      {...rest}
    >
      {children}
      <div id="traede-template-portal" />
    </div>
  )
}

type WhiteBoxProps = {
  children?: Node,
}

export const WhiteBox = ({ children, ...props }: WhiteBoxProps) => {
  return (
    <div className="white-box" {...props}>
      {children}
    </div>
  )
}

type Breadcrumb = {
  link?: string,
  label: string,
}

export const Breadcrumbs = ({
  breadcrumbs,
}: {
  breadcrumbs: Array<Breadcrumb>,
}) => {
  const length = breadcrumbs.length

  return (
    <BreadcrumbContainer>
      {breadcrumbs.map((entry, index) => {
        // If last entry, do not create link.
        if (index === length - 1) {
          return (
            <BreadcrumbLabel key={entry.label}>{entry.label}</BreadcrumbLabel>
          )
        } else {
          if (!entry.link) {
            return (
              <BreadcrumbLabel key={entry.label}>
                {entry.label} /{' '}
              </BreadcrumbLabel>
            )
          } else {
            return (
              <BreadcrumbLabel key={entry.label}>
                <Link to={entry.link}>{entry.label}</Link> /{' '}
              </BreadcrumbLabel>
            )
          }
        }
      })}
    </BreadcrumbContainer>
  )
}

const BreadcrumbContainer = styled.div`
  margin-left: -5px;
`

const BreadcrumbLabel = styled.span`
  margin-left: 5px;
`
