// @flow

import * as React from 'react'
import { type Location, type Match, type RouterHistory } from 'react-router-dom'
import styled from 'styled-components'
import querystring from 'querystring'
import { connect } from 'react-redux'

import AnonymousNavigation from '../template/components/Navigation/AnonymousNavigation'
import ShopNavigation from '../template/components/Navigation/ShopNavigation'
import { usePublicBrand } from '../shop/hooks'
import { useAvailableBrandsList } from '../brands/hooks'
import { ensureLoggedIntoEntityWithBrandAccess } from '../brands/shared'
import { switchEntity } from '../template/actions'
import { SessionContext } from '../shared'
import { FileBankContext } from './shared'
import { useAnonymousFileBankSession, useFileBankSession } from './hooks'
import { useFetchBrandIfNeeded } from '../brands/hooks'

import type { Dispatch } from '../types'

const FileBankSessionAuthenticationWrap = (props: Object) => {
  const { user } = React.useContext(SessionContext)

  return user.id ? (
    <ConnectedFileBankSession {...props} />
  ) : (
    <FileBankAnonymousSession {...props} />
  )
}

export default FileBankSessionAuthenticationWrap

const FileBankSession = ({
  children,
  dispatch,
  history,
  location,
  match,
}: {
  children: any,
  dispatch: Dispatch,
  history: RouterHistory,
  location: Location,
  match: Match,
}) => {
  const {
    params: { brandId },
  } = match

  const publicLinkToken = parsePublicLinkTokenFromQuery(location)

  const { adminEmail, brands, entity, settings, user } =
    React.useContext(SessionContext)

  const [
    { brands: availableBrands },
    isAvailableBrandsFetching,
    { isInitialized: isAvailableBrandsAreInitialized },
  ] = useAvailableBrandsList()
  const availableBrand = React.useMemo(() => {
    return availableBrands.find(b => b.id == brandId)
  }, [availableBrands, brandId])

  const brand = useFetchBrandIfNeeded(brandId, dispatch)

  React.useEffect(() => {
    if (isAvailableBrandsAreInitialized) {
      ensureLoggedIntoEntityWithBrandAccess(
        brandId,
        null,
        availableBrands,
        user,
        entity,
        dispatch
      )
    }
  }, [
    availableBrands,
    brandId,
    dispatch,
    user,
    entity,
    isAvailableBrandsAreInitialized,
  ])

  const [session, isFetchingSession, { isInitialized: isInitializedSession }] =
    useFileBankSession([brandId])

  React.useEffect(() => {
    if (isInitializedSession && publicLinkToken) {
      // Redirect to file bank without public link token
      const queryParams = querystring.parse(location.search.substr(1))
      delete queryParams.access

      history.push(`${location.pathname}?${querystring.stringify(queryParams)}`)
    }
  }, [isInitializedSession, publicLinkToken])

  const fileBankContextValue = React.useMemo(
    () => ({
      publicLinkToken,
      brand,
      session,
    }),
    [publicLinkToken, brand, session]
  )

  if (!brand || !session) {
    return null
  }

  return (
    <FileBankOuterContainer>
      <ShopNavigation
        adminEmail={adminEmail}
        brandContext={brand.id}
        brands={brands}
        entity={entity}
        fullWidth={false}
        history={history}
        inWebshop={false}
        user={user}
      />
      <FileBankContext.Provider value={fileBankContextValue}>
        <div>{children}</div>
      </FileBankContext.Provider>
    </FileBankOuterContainer>
  )
}

const ConnectedFileBankSession = connect()(FileBankSession)

const FileBankAnonymousSession = ({
  children,
  history,
  location,
  match,
}: {
  children: any,
  history: RouterHistory,
  location: Location,
  match: Match,
}) => {
  const {
    params: { brandId },
  } = match

  const publicLinkToken = parsePublicLinkTokenFromQuery(location)

  const publicBrandRequestArgs = React.useMemo(
    () => [brandId, { resource: 'file-bank', resource_id: publicLinkToken }],
    [brandId, publicLinkToken]
  )
  const [
    publicBrand,
    isFetchingPublicBrand,
    { isInitialized: isInitializedPublicBrand },
  ] = usePublicBrand(publicBrandRequestArgs)

  React.useEffect(() => {
    if (isInitializedPublicBrand && !publicBrand) {
      const redirect = `${location.pathname}${location.search}`
      history.push(
        `/login?${querystring.stringify({ redirect: encodeURI(redirect) })}`
      )
    }
  }, [isInitializedPublicBrand, publicBrand])

  const sessionRequestArgs = React.useMemo(
    () => [brandId, publicLinkToken],
    [brandId, publicLinkToken]
  )
  const [session] = useAnonymousFileBankSession(sessionRequestArgs)

  const fileBankContextValue = React.useMemo(
    () => ({
      publicLinkToken,
      brand: publicBrand,
      session,
    }),
    [publicLinkToken, publicBrand]
  )

  if (!publicBrand || !session) {
    return null
  }

  return (
    <FileBankOuterContainer>
      <AnonymousNavigation fullWidth={false} />
      <FileBankContext.Provider value={fileBankContextValue}>
        <div>{children}</div>
      </FileBankContext.Provider>
    </FileBankOuterContainer>
  )
}

const FileBankOuterContainer = styled.div`
  min-width: 900px;
  margin-bottom: 100px;
`

const parsePublicLinkTokenFromQuery = (location: Location): null | string => {
  const parsedQueryParams = querystring.parse(location.search.substr(1))
  return parsedQueryParams.access || null
}
