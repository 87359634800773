/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import type { RowConfig } from '../../types'

type Options = {
  label?: string,
  shopUrl?: boolean,
}

const item_number: (options: Options) => RowConfig = ({
  label = 'Item number',
  shopUrl = false,
}) => ({
  key: 'item_number',
  label,
  render: ({ preview, product }: { preview: boolean, product: Product }) => {
    const link = shopUrl
      ? `/shop/${product.brand_id}/show/${product.id}`
      : `/products/${product.id}`

    return (
      <span>
        {product.item_number}

        <span className="print-hide">
          <StyledLink to={link}>
            <span className="glyphicon glyphicon-share-alt" />
          </StyledLink>
        </span>
      </span>
    )
  },
  type: 'product',
})

export default item_number

const StyledLink = styled(Link)`
  font-size: 11px;
  margin-left: 5px;
`
