// @flow

import * as React from 'react'
import { connect } from 'react-redux'
import { Link, type RouterHistory } from 'react-router-dom'
import { withBreakpoints } from 'react-breakpoints'
import sortBy from 'lodash/sortBy'
import styled, { css } from 'styled-components'

import NavigationRightSide from './NavigationRightSide'
import ShopNavigation from './ShopNavigation'
import BrandNavigation from './BrandNavigation'
import AnonymousNavigation from './AnonymousNavigation'
import AgencyNavigation from './AgencyNavigation'

import type { Id, AvailableBrand } from '../../../types'
import type { PayingCustomer } from '../../../admin/types'

import { SessionContext } from '../../../shared'

type NavigationBarOwnProps = {
  fullWidth?: boolean,
  history: RouterHistory,
  navigation: Array<any>,
  settingsPage: boolean,
  toggleSpotlight?: Function,
}

type NavigationBarStateProps = {
  entity: Object,
  inWebshop: boolean,
  user: Object,
}

type NavigationBarProps = NavigationBarOwnProps &
  NavigationBarStateProps & {
    dispatch: (action: Object) => any,
  }

const NavigationBar = (props: NavigationBarProps) => {
  const { adminEmail, customer, brands, entity, user } =
    React.useContext(SessionContext)

  // If user is unauthenticated
  if (!user.id) {
    return (
      <AnonymousNavigation
        brands={brands}
        entity={entity}
        user={user}
        {...props}
      />
    )
  }

  if (props.inWebshop || entity.entity_type === 'shop') {
    return (
      <ShopNavigation brands={brands} entity={entity} user={user} {...props} />
    )
  }

  if (entity.entity_type === 'agency') {
    return (
      <AgencyNavigation
        brands={brands}
        entity={entity}
        user={user}
        {...props}
      />
    )
  }

  return (
    <BrandNavigation
      adminEmail={adminEmail}
      customer={customer}
      brands={brands}
      entity={entity}
      user={user}
      {...props}
    />
  )
}

NavigationBar.defaultProps = {
  navigation: [],
  settingsPage: false,
  toggleSpotlight: null,
}

const ConnectedNavigationBar = withBreakpoints(NavigationBar)

export default ConnectedNavigationBar
