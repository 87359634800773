/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import type { ColumnConfig } from '../../types'

type Options = {
  colli_not_included_in_value?: boolean,
  rows: Array<string>,
}

const total_price: (options: Options) => ColumnConfig = ({
  aggregate_product = true,
  colli_not_included_in_value = false,
  rows,
}) => ({
  aggregate_product,
  depends_on: ['unit_price'],
  key: 'total_price',
  label: 'Total',
  rows: rows,
  render: 'money',
  right: true,
  value: ({
    columnValues,
    matrix,
    rowValuesByVariantId,
    rowValuesCollisByVariantId,
  }) => {
    const quantity = Object.keys(rowValuesByVariantId).reduce(
      (carry, variantId) => {
        const value = rowValuesByVariantId[variantId] || 0
        const valueColli = rowValuesCollisByVariantId[variantId] || 1

        let q = parseInt(value)

        if (colli_not_included_in_value) {
          q = q * parseInt(valueColli)
        }

        return carry + q
      },
      0
    )

    return [
      quantity *
        (columnValues.unit_price && columnValues.unit_price[0]
          ? columnValues.unit_price[0].net_price
          : 0),
    ]
  },
})

export default total_price
