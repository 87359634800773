import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { createAction } from '../shared'
import {
  IsBrandPredicate,
  IsNotB2COrderPredicate,
  CanDiscountProductsPredicate,
  UnlockedOrderPredicate,
} from './shared'

const createDiscountAction = () => {
  return createAction(
    ({ product, lines }, { toggleDiscountModal }) => {
      return toggleDiscountModal([product], lines)
    },
    <Button>%</Button>,
    [IsBrandPredicate, CanDiscountProductsPredicate, UnlockedOrderPredicate],
    { size: 'xs' }
  )
}

export default createDiscountAction

const Button = styled.span`
  font-weight: bold;
`
