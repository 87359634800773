/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { getOrderDeliveryStatus } from '../../../../../orders/api'
import { getWebshopDeliveryStatus } from '../../../../../shopV2/api'

type OptionsType = {
  webshop?: boolean,
}

const delivery_status: (options: OptionsType) => ColumnConfig = ({
  webshop = false,
}) => ({
  data_level: 'variant',
  resolve: ({ context, event }) => {
    const { missing_variants } = event

    const missingVariantIds = missing_variants.map(v => v.id)
    const isCustomerFacingDeliveryStatus = true

    const request = !webshop
      ? getOrderDeliveryStatus(
          context.order.id,
          [],
          missingVariantIds,
          isCustomerFacingDeliveryStatus
        )
      : getWebshopDeliveryStatus(
          context.webshopSession.brand_id,
          context.webshopSession.id,
          missingVariantIds
        )

    return request.then(response => {
      if (response.error) {
        return []
      }

      return response.payload.delivery_status
    })
  },
})

export default delivery_status
