/* @flow */

import { api } from '../api'

export const getCountries = () => api.get('/misc/countries')

export const getStates = () => api.get('/misc/states')

export const generateAutoTranslations = (originalText, targetLanguages) =>
  api.post('/auto-translations', {
    body: {
      original_text: originalText,
      target_languages: targetLanguages,
    },
  })
