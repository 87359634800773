/* @flow */

import React, { PureComponent } from 'react'
import sortBy from 'lodash/sortBy'
import onClickOutside from 'react-onclickoutside'
import { Button, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import classnames from 'classnames'

import msg from '../../../infrastructure/modules/msg'
import ActionButton from '../../../infrastructure/components/ActionButton'

import AddressModalForm from './AddressModalForm'
import {
  createCustomerAddress,
  deleteCustomerAddress,
  getCustomerAddresses,
  updateCustomerAddress,
} from '../../customers/actions/addresses'

import type { Address, ApiResponse, Id } from '../../types'
import type { AddressAction } from './types'

export type Props = {
  activateLabel?: string,
  addresses: Array<Address>,
  defaultAddress?: 'primary' | 'delivery',
  current: ?Address,
  isFetching?: boolean,
  onAdd: (address: Address) => Promise<ApiResponse<*>>,
  onChange: (address: ?Address) => void,
  onDelete: (id: Id, address: Address) => Promise<ApiResponse<*>>,
  onEdit: (id: Id, address: Address) => Promise<ApiResponse<*>>,
  required?: boolean,
}

type State = {
  show: boolean,
  showAddModal: boolean,
  showDeleteModal: false | Address,
  showEditModal: false | Address,
  showSelector: boolean,
}

export class AddressSelector extends PureComponent<Props, State> {
  static defaultProps = {
    activateLabel: 'Add address',
    defaultAddress: 'primary',
    isFetching: false,
    required: true,
  }

  state = {
    show: false,
    showAddModal: false,
    showDeleteModal: false,
    showEditModal: false,
    showSelector:
      this.props.required !== undefined
        ? this.props.required
        : AddressSelector.defaultProps.required,
  }

  /**
   * Triggered by HOC react-click-outside
   */
  handleClickOutside = event => {
    this.setState({
      show: false,
    })
  }

  render() {
    const { activateLabel, current, isFetching, onChange, required } =
      this.props
    const { show, showAddModal, showDeleteModal, showEditModal, showSelector } =
      this.state

    if (isFetching) {
      return (
        <div data-traede-tests="address-selector-loading-indicator">
          <LoadingIndicator /> Loading...
        </div>
      )
    }

    const addresses = sortBy(this.props.addresses, address => [
      address.primary === true ? 0 : 1,
      address.name,
    ])

    // Show a button to activate the selector for non-required selectors
    if (!showSelector) {
      return (
        <button
          className="btn btn-white"
          onClick={this._toggleShowSelector}
          data-traede-tests="address-selector-activate-button"
        >
          {activateLabel}
        </button>
      )
    }

    // If the selector has been activated, but no addresses were found
    // show a button to start adding addresses
    if (!addresses || addresses.length === 0) {
      return (
        <div>
          {this._renderAddAddressButton()}
          {this._renderDataModal()}
        </div>
      )
    }

    let currentClasses = classnames({
      'address-selector-chosen': true,
      active: show,
    })

    return (
      <div className="address-selector ignore-react-onclickoutside">
        <AddressLine
          className={currentClasses}
          address={current}
          onAddressLineClick={this._toggleShow}
          showTitle={true}
        />
        <div
          className="address-selector-options"
          style={{ display: show ? 'block' : 'none' }}
        >
          <div
            className="address-selector-options-scroll"
            data-traede-tests="address-selector-list"
          >
            {addresses.map(address => (
              <AddressLine
                className="address-selector-option"
                key={address.id}
                address={address}
                onAddressLineClick={() => this._onChange(address)}
                onEditClick={() => this._toggleEditModal(address)}
                onDeleteClick={() => this._toggleDeleteModal(address)}
                onMakePrimaryClick={() => this._setAsPrimary(address)}
                showTitle={true}
              />
            ))}
          </div>
          {/* Show a button to deactivate a non-required selector */}
          {!required && (
            <button
              className="btn btn-block"
              onClick={this._toggleShowSelector}
            >
              I do not want to add an address
            </button>
          )}
          {this._renderAddAddressButton()}
        </div>
        <ConfirmDeleteModal
          address={showDeleteModal}
          onHide={() => this._toggleDeleteModal()}
          onSubmit={this._deleteAddress}
          show={showDeleteModal !== false}
        />
        {this._renderDataModal()}
      </div>
    )
  }

  _addAddress = (address: Address) => {
    return this.props.onAdd(address).then(response => {
      if (!response.error) {
        this.setState({
          showAddModal: false,
        })
      }
    })
  }

  _deleteAddress = () => {
    const { showDeleteModal } = this.state

    if (showDeleteModal) {
      return this.props
        .onDelete(showDeleteModal.id, showDeleteModal)
        .then(response => {
          if (!response.error) {
            this.setState({
              showDeleteModal: false,
            })
          }
        })
    }
  }

  _editAddress = (id: Id, address: $Shape<Address>) => {
    return this.props.onEdit(id, address)
  }

  _editAddressFromModal = (address: Address) => {
    const { showEditModal } = this.state

    if (showEditModal) {
      return this._editAddress(showEditModal.id, address).then(response => {
        if (!response.error) {
          this.setState({
            showEditModal: false,
          })
        }
      })
    }
  }

  _setAsPrimary = (address: Address) => {
    return this._editAddress(address.id, { primary: true })
  }

  _onChange = (address: Address) => {
    this.props.onChange(address)
    this.setState({
      show: false,
    })
  }

  _renderAddAddressButton = () => {
    return (
      <div className="address-selector-add" onClick={this._toggleAddModal}>
        <span className="glyphicon glyphicon-plus" />
        Add Address
      </div>
    )
  }

  _renderDataModal = () => {
    const { showAddModal, showEditModal } = this.state

    return (
      <div>
        <AddressModalForm
          initialValues={{}}
          onHide={() => this._toggleAddModal()}
          onSubmit={this._addAddress}
          show={showAddModal !== false}
          mode="update"
        />
        <AddressModalForm
          initialValues={showEditModal !== false ? showEditModal : undefined}
          onHide={() => this._toggleEditModal()}
          onSubmit={this._editAddressFromModal}
          show={showEditModal !== false}
          mode="edit"
        />
      </div>
    )
  }

  _toggleAddModal = () => {
    this.setState({
      showAddModal: !this.state.showAddModal,
    })
  }

  _toggleDeleteModal = (address?: Address) => {
    this.setState({
      showDeleteModal: address || false,
    })
  }

  _toggleEditModal = (address?: Address) => {
    this.setState({
      showEditModal: address || false,
    })
  }

  _toggleShow = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  _toggleShowSelector = () => {
    this.setState(
      {
        show: !this.state.showSelector === true ? true : false,
        showSelector: !this.state.showSelector,
      },
      () => {
        if (this.state.showSelector === false && this.props.current) {
          this.props.onChange(null)
        }
      }
    )
  }
}

export default onClickOutside(AddressSelector)

type DeleteAddressModalProps = {
  address: Address | false,
  onHide: () => void,
  onSubmit: () => void,
  show: boolean,
}

const ConfirmDeleteModal = ({
  address,
  onHide,
  onSubmit,
  show,
}: DeleteAddressModalProps) => {
  let title = `Are you sure you want to delete this address`

  if (address) {
    if (address.name) {
      title = `Are you sure you want to delete address ${address.name}?`
    } else if (address.address) {
      title = `Are you sure you want to delete address ${address.address}?`
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Please confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body>{title}</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHide()}>No, cancel.</Button>
        <ActionButton className="btn btn-danger" onClick={() => onSubmit()}>
          Delete
        </ActionButton>
      </Modal.Footer>
    </Modal>
  )
}

type AddressLineProps = {
  address: Address | null,
  className?: string,
  onAddressLineClick: (address: Address) => void,
  onMakePrimaryClick?: (address: Address) => void,
  onDeleteClick?: (address: Address) => void,
  onEditClick?: (address: Address) => void,
  showTitle?: boolean,
}

export class AddressLine extends PureComponent<AddressLineProps, void> {
  render() {
    const { address, className, onAddressLineClick } = this.props

    return (
      <div onClick={onAddressLineClick} className={className}>
        {address && this._renderAddress()}
      </div>
    )
  }

  _renderAddress = () => {
    const {
      address,
      onEditClick,
      onDeleteClick,
      onMakePrimaryClick,
      showTitle,
    } = this.props

    if (!address) {
      return null
    }

    const icon = []
    if (address.primary) {
      icon.push(
        <AddressLineIconContainer>
          <span className="glyphicon glyphicon-home" />
        </AddressLineIconContainer>
      )
    }
    if (address.delivery) {
      icon.push(
        <AddressLineIconContainer>
          <span className="glyphicon glyphicon-envelope" />
        </AddressLineIconContainer>
      )
    }

    const vat = address.vat ? <span>(VAT: {address.vat})</span> : null
    const ean = address.ean ? <span>(EAN: {address.ean})</span> : null
    const eori = address.eori_number ? (
      <span>(EORI: {address.eori_number})</span>
    ) : null
    const att = address.att ? <span>(att: {address.att})</span> : null
    const phone = address.telephone ? (
      <span>Phone: {address.telephone}</span>
    ) : null

    const deleteBtnClasses = classnames({
      hide: address.primary,
      btn: true,
      'btn-xs': true,
      'btn-danger': true,
      'address-line-addon-button': true,
      'm-l-10': true,
    })

    const makePrimaryButtonClasses = classnames({
      btn: true,
      'btn-xs': true,
      'btn-block': true,
      'address-selector-make-primary-button': true,
      hide: address.primary,
    })

    return (
      <AddressContainer>
        <AddressInformationContainer>
          {showTitle && address.title && (
            <strong>
              {icon}
              {address.title}
              <br />
            </strong>
          )}
          {address.name && (
            <span>
              {address.name} {att} {vat} {ean} {eori}
            </span>
          )}
          <br />
          {address.address} {address.address_2 || ''}
          <br />
          {address.zip} {address.city} {address.region} {address.country}
          <br />
          {phone}
        </AddressInformationContainer>
        <div className="address-selector-edit-buttons text-right">
          {onMakePrimaryClick && (
            <button
              type="button"
              className={makePrimaryButtonClasses}
              onClick={this._onMakePrimaryClick}
            >
              Make primary
            </button>
          )}
          {onEditClick && (
            <button
              type="button"
              onClick={this._onEditClick}
              className="btn btn-xs btn-primary address-line-addon-button"
            >
              Edit
            </button>
          )}
          {onDeleteClick && (
            <button
              type="button"
              className={deleteBtnClasses}
              onClick={this._onDeleteClick}
            >
              Delete
            </button>
          )}
        </div>
      </AddressContainer>
    )
  }

  _onDeleteClick = e => {
    e.preventDefault()
    e.stopPropagation()

    const { address, onDeleteClick } = this.props

    if (address && onDeleteClick) {
      onDeleteClick(address)
    }
  }

  _onEditClick = e => {
    e.preventDefault()
    e.stopPropagation()

    const { address, onEditClick } = this.props

    if (address && onEditClick) {
      onEditClick(address)
    }
  }

  _onMakePrimaryClick = e => {
    e.preventDefault()
    e.stopPropagation()

    const { address, onMakePrimaryClick } = this.props

    if (address && onMakePrimaryClick) {
      onMakePrimaryClick(address)
    }
  }
}

export const getDeliveryAddressIfExists = (addresses: Array<Address>) => {
  const deliveryAddress = getDeliveryAddress(addresses)

  if (deliveryAddress) {
    return deliveryAddress
  }

  return getPrimaryAddress(addresses)
}
export const getDeliveryAddress = (addresses: Array<Address>) =>
  addresses.find(address => address.delivery)
export const getPrimaryAddress = (addresses: Array<Address>) =>
  addresses.find(address => address.primary)

const LoadingIndicator = styled.i.attrs({
  className: 'fa fa-spinner',
})``

const AddressContainer = styled.div`
  display: flex;
  flex: 1;
`

const AddressInformationContainer = styled.div`
  flex: 1;
`

const AddressLineIconContainer = styled.div`
  display: inline;
  margin-right: 5px;
`
