/* @flow */

import React, { useContext, memo } from 'react'
import styled from 'styled-components'

import HoverImage from '../HoverImage'
import {
  ProductTableContext,
  TableColumn,
  TableRow,
  createEmptyArray,
} from '../shared'
import { columnConfigToStyle, flattenRows, renderValue } from './shared'

const ProductDataRow = ({
  columnTotals,
  dataContext,
  product,
  maxHorizontalColumns,
  noActionColumns,
  productHeaderMode,
  toggleCollapsed,
}) => {
  const {
    columnKeys,
    columns,
    imageSettings,
    matrix,
    preview,
    productDataConfig,
    productDataConfigKeys,
    productImage,
    rows,
    variantImages,
  } = useContext(ProductTableContext)

  const flattenedRows = React.useMemo(
    () => (matrix ? [] : flattenRows(rows)[0]),
    [matrix, rows]
  )

  const showImageColumn =
    /*
    We used to not show the product image column when the product header mode was set to 'wide_product_data'.
    However, some brands, like O'TAY, wishes to show the product image in the PO view because they have no
    variant images. But they also want to show the product images at the bottom

    productHeaderMode === 'default' &&
    */
    productImage === true || variantImages === true

  let colSpan = 0
  if (matrix) {
    // 1 for the empty column between horizontal attributes and columns
    // 1 for image columns (does not matter whether or not images are shown or not)
    colSpan = 2 + maxHorizontalColumns
  } else {
    colSpan = 1 + flattenedRows.length
  }

  if (productHeaderMode === 'wide_product_data') {
    colSpan += columns.length

    // the image column is no longer shown
    //colSpan += 1
  }

  return (
    <TableProductDataRow>
      {showImageColumn && (
        <TableProductDataColumn style={{ width: imageSettings.column_width }}>
          {productImage === true && product.image ? (
            <HoverImage image={product.image} primary />
          ) : null}
        </TableProductDataColumn>
      )}
      <TableProductDataColumn colSpan={colSpan}>
        <ProductDataTableContainer>
          <ProductDataTable>
            <tbody>
              {productDataConfig.map(config => {
                return (
                  <tr key={`product-data-${config.key}`}>
                    <ProductDataTableLabel>
                      {config.label}:
                    </ProductDataTableLabel>
                    <ProductDataTableValue>
                      {config.render
                        ? config.render({ preview, product })
                        : product[config.key]}
                    </ProductDataTableValue>
                  </tr>
                )
              })}
            </tbody>
          </ProductDataTable>
        </ProductDataTableContainer>
      </TableProductDataColumn>
      {productHeaderMode !== 'wide_product_data' &&
        columns.map(column => {
          const totalsOfColumn = columnTotals[column.key] || []

          return (
            <TableProductDataColumn
              key={column.key}
              style={columnConfigToStyle(column)}
            >
              {totalsOfColumn.map(rowTotal => {
                let render = column.render
                if (typeof render === 'function') {
                  render = column.aggregate_render
                }

                const label = rowTotal.label

                return (
                  <div key={`${rowTotal.row}-totals`}>
                    {rowTotal.totals.map(total => {
                      return (
                        <div>
                          {totalsOfColumn.length > 1 && <span>{label}: </span>}
                          {renderValue({
                            config: {
                              render: render,
                            },
                            data: {
                              context: dataContext,
                            },
                            value: [total],
                          })}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </TableProductDataColumn>
          )
        })}
      {createEmptyArray(noActionColumns).map((a, i) => (
        <TableProductDataColumn key={`actions-${i}`} />
      ))}
    </TableProductDataRow>
  )
}

export default memo(ProductDataRow)

const TableProductDataRow = styled(TableRow)``
const TableProductDataColumn = styled(TableColumn)`
  vertical-align: top;
`

const ProductDataTableContainer = styled.div``

const ProductNameLabel = styled.div`
  color: #3e3e3e;
  margin-bottom: 5px;
  text-decoration: underline;
`

const ProductDataTable = styled.table``

const ProductDataTableLabel = styled.td`
  color: #a2a2a2;
`

const ProductDataTableValue = styled.td`
  color: #3e3e3e;
  padding-left: 10px;
`
