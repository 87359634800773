/* @flow */

import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Select from 'react-select'

import ActionButton from '../../../../infrastructure/components/ActionButton'
import ErrorBoundary from '../../../../infrastructure/components/ErrorBoundary'
import { ControlLabel } from '../../../../infrastructure/components/Forms'
import DataTable from './DataTable'

import type { Filter, Report, Sheet, Workspace } from './types'
import {
  createReport,
  exportReportToExcel,
  getSheets,
  getWorkspaces,
} from '../../api'

type Context = { default?: boolean, key: string, name: string, value: mixed }

type Props = {
  contexts: Array<Context>,
}

type State = {
  currentContext: Context,
  currentSheet: ?Sheet,
  currentWorkspace: ?Workspace,
  data: null | Report,
  isFetchingReport: boolean,
  isLoaded: boolean,
  sheets: Array<Sheet>,
  workspaces: Array<Workspace>,
}

export default class InlineReports extends PureComponent<Props, State> {
  state = {
    currentContext: this.props.contexts.filter(c => c.default)[0],
    currentSheet: null,
    currentWorkspace: null,
    data: null,
    isFetchingReport: false,
    isLoaded: false,
    sheets: [],
    workspaces: [],
  }

  _dataTableRef: ?DataTable

  componentDidMount() {
    Promise.all([
      getWorkspaces(),
      getSheets({
        filter_groups: [
          {
            filters: [
              {
                key: 'type',
                operator: 'eq',
                value: 'generator',
              },
            ],
            not: false,
          },
        ],
      }),
    ]).then(([workspacesResponse, sheetsResponse]) => {
      const changes: $Shape<State> = { isLoaded: true }

      if (!workspacesResponse.error) {
        changes.workspaces = workspacesResponse.payload.workspaces

        if (changes.workspaces.length > 0) {
          changes.currentWorkspace = changes.workspaces[0]
        }
      }

      if (!sheetsResponse.error) {
        changes.sheets = sheetsResponse.payload.sheets

        if (changes.sheets.length > 0) {
          changes.currentSheet = changes.sheets[0]
        }
      }

      this.setState(changes)
    })
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.state.currentSheet !== prevState.currentSheet ||
      this.state.currentContext !== prevState.currentContext
    ) {
      this._createReport()
    }
  }

  render() {
    const { contexts } = this.props
    const {
      currentContext,
      currentSheet,
      currentWorkspace,
      data,
      isFetchingReport,
      isLoaded,
      sheets,
      workspaces,
    } = this.state

    if (!isLoaded) {
      return null
    }

    if (workspaces.length === 0) {
      return (
        <div>
          You have no workspaces defined. Go to the Report Generator to create
          one
        </div>
      )
    }

    if (sheets.length === 0) {
      return (
        <div>
          You have no sheets defined. Go to the Report Generator to create one
        </div>
      )
    }

    return (
      <Container>
        <ActionsBar>
          <LeftSideActions>
            <div>
              <ControlLabel>Workspace</ControlLabel>
              <div className="react-select--small">
                <Select
                  clearable={false}
                  onChange={workspace =>
                    this.setState({ currentWorkspace: workspace })
                  }
                  options={workspaces}
                  labelKey="name"
                  value={currentWorkspace}
                  valueKey="id"
                />
              </div>
            </div>
            <div>
              <ControlLabel>Sheet</ControlLabel>
              <div className="react-select--small">
                <Select
                  clearable={false}
                  onChange={sheet => this.setState({ currentSheet: sheet })}
                  options={sheets.filter(
                    s => s.workspace_id === currentWorkspace.id
                  )}
                  labelKey="name"
                  value={currentSheet}
                  valueKey="id"
                />
              </div>
            </div>
            <div>
              <ControlLabel>Context</ControlLabel>
              <div className="react-select--small">
                <Select
                  clearable={false}
                  onChange={context =>
                    this.setState({ currentContext: context })
                  }
                  options={contexts}
                  labelKey="name"
                  value={currentContext}
                  valueKey="name"
                />
              </div>
            </div>
          </LeftSideActions>
          <RightSideActions>
            <ActionButton
              type="button"
              className="btn btn-white"
              onClick={this._exportSheet}
            >
              <span className="fa fa-file-excel-o" /> Export
            </ActionButton>
          </RightSideActions>
        </ActionsBar>
        {currentSheet && (
          <DataContainer>
            {isFetchingReport && <div>Loading data...</div>}
            {!isFetchingReport && data && (
              <>
                {data.data.length === 0 && <div>No data was found</div>}
                {data.data.length > 0 && (
                  <ErrorBoundary
                    message={`An error occurred loading ${currentSheet.name}`}
                  >
                    {/*We add the key to force remount when changing sheet. This is mainly to flush the cell cache*/}
                    <DataTable
                      data={data}
                      key={currentSheet.id}
                      ref={ref => (this._dataTableRef = ref)}
                      show={true}
                    />
                  </ErrorBoundary>
                )}
              </>
            )}
          </DataContainer>
        )}
      </Container>
    )
  }

  _createReport = () => {
    const { currentContext, currentSheet } = this.state

    if (!currentSheet) {
      this.setState({
        data: null,
      })
      return
    }

    this.setState({
      isFetchingReport: true,
    })

    const setup = JSON.parse(JSON.stringify(currentSheet.setup))

    setup.filters.push({
      not: false,
      options: {
        operator: 'in',
        values: [currentContext.value],
      },
      type: 'dimension',
      value: currentContext.key,
    })

    createReport(setup).then(response => {
      const changes: $Shape<State> = { isFetchingReport: false }

      if (!response.error) {
        const report = response.payload.report

        changes.data = report
      }

      this.setState(changes)
    })
  }

  _exportSheet = () => {
    const { currentSheet } = this.state

    if (currentSheet && this._dataTableRef) {
      const tableRows = this._dataTableRef.state.tableRows.map(row =>
        row.map(column => column.labels)
      )

      return exportReportToExcel(currentSheet.id, tableRows).then(response => {
        if (!response.error) {
          const url = `${process.env.FULL_API_URL}/documents/download?name=${response.payload.name}&code=${response.payload.code}`
          // Trick for making downloadable link
          var a = document.createElement('a')
          a.href = url
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
        }
      })
    }
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ActionsBar = styled.div`
  display: flex;
`

const LeftSideActions = styled.div`
  display: flex;
  flex: 1;
  margin-left: -10px;

  > div {
    margin-left: 10px;
    min-width: 160px;
  }
`

const RightSideActions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex-grow: 0;
`

const DataContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
