/* @flow */

import * as React from 'react'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Glyphicon } from 'react-bootstrap'
import { Formik } from 'formik'

import {
  NAVIGATION_LINK_TYPE_SEARCH,
  NAVIGATION_LINK_TYPE_PAGE,
  NAVIGATION_LINK_TYPE_BESTSELLERS,
  NAVIGATION_LINK_TYPE_FILE_BANK,
  NAVIGATION_LINK_TYPE_CAMPAIGN_PDF,
  NAVIGATION_LINK_TYPE_SPRII,
} from '../../shared'
import OpenPdfButton from '../../../documents/OpenPdfButton'

type Props = {
  handleSearchSubmit: Function,
  searchQuery: string,
}

const WebshopNavigation = ({
  brand,
  handleSearchSubmit,
  navigationLinks,
  searchQuery,
  shopUrl,
}: Props) => {
  return (
    <NavigationContainer>
      <QuerySearchContainer>
        <QuerySearchForm
          searchQuery={searchQuery}
          onSubmit={handleSearchSubmit}
        />
      </QuerySearchContainer>

      <ul className="ul-navigation">
        <li key={0}>
          <NavLink to={shopUrl} activeClassName="active">
            <span className="glyphicon glyphicon-home" />
          </NavLink>
        </li>

        {navigationLinks.map((link, index) => (
          <li key={index}>
            {(link.type === NAVIGATION_LINK_TYPE_SEARCH ||
              link.type === NAVIGATION_LINK_TYPE_BESTSELLERS) && (
              <NavLink
                to={`${shopUrl}/search/${link.id}`}
                activeClassName="active"
              >
                {link.text}
              </NavLink>
            )}
            {link.type === NAVIGATION_LINK_TYPE_PAGE && (
              <NavLink
                to={`${shopUrl}/page/${link.settings.page_id}`}
                activeClassName="active"
              >
                {link.text}
              </NavLink>
            )}
            {link.type === 'linesheet' && (
              <NavLink
                to={`${shopUrl}/linesheet/${link.linesheet_public_id}`}
                activeClassName="active"
              >
                {link.text}
              </NavLink>
            )}
            {link.type === NAVIGATION_LINK_TYPE_FILE_BANK && (
              <NavLink to={`/file-bank/${brand.id}`} activeClassName="active">
                {link.text}
              </NavLink>
            )}
            {link.type === NAVIGATION_LINK_TYPE_SPRII && (
              <NavLink
                to={`${shopUrl}/sprii/${link.id}`}
                activeClassName="active"
              >
                {link.text}
              </NavLink>
            )}
            {link.type === NAVIGATION_LINK_TYPE_CAMPAIGN_PDF && (
              <OpenPdfButton
                documentId={link.settings.linesheet_document_id}
                render={({ children, open }) => (
                  <a role="button" onClick={open}>
                    {children}
                  </a>
                )}
              >
                {link.text}
              </OpenPdfButton>
            )}
          </li>
        ))}
      </ul>
    </NavigationContainer>
  )
}

export default WebshopNavigation

const NavigationContainer = styled.nav.attrs({
  className: 'webshop-main-navigation',
})``

const QuerySearchContainer = styled.div.attrs({
  className: 'webshop-search-container',
})`
  float: right;
`

const QuerySearchForm = ({ searchQuery, onSubmit }) => {
  const [focused, setFocused] = React.useState(false)

  return (
    <Formik
      enableReinitialize
      initialValues={{ query: searchQuery }}
      onSubmit={values => {
        if (values.query === '') {
          setFocused(true)
        } else {
          onSubmit(values.query)
        }
      }}
    >
      {({ values, handleSubmit, handleChange }) => {
        const active = focused || values.query !== ''

        return (
          <form onSubmit={handleSubmit}>
            <QuerySearchInput
              value={values.query}
              name="query"
              onChange={handleChange}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              show={active}
            />
            <QuerySearchButton type="submit" active={active}>
              <Glyphicon glyph="search" />
            </QuerySearchButton>
          </form>
        )
      }}
    </Formik>
  )
}

const QuerySearchInput = ({ show, ...restProps }) => {
  const inputRef = React.useRef()

  React.useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus()
    }
  }, [show])

  if (!show) {
    return null
  }

  return (
    <input
      ref={inputRef}
      type="text"
      placeholder="Search for product…"
      {...restProps}
      css={css`
        input[type='text']& {
          width: 200px;
          height: 100%;
          padding: 10px 10px 10px 20px;

          position: absolute;
          top: 0;
          right: 39px;

          font-family: proxima-nova, sans-serif;
          font-weight: 800;
          font-size: 16px;
          color: #fff;

          border: none;
          border-radius: 0;
          background-color: #333333;
        }
      `}
    />
  )
}

const QuerySearchButton = styled.button`
  background: none;
  border: none;
  padding: 13px 10px;
  outline: 0;

  font-size: 20px;
  line-height: 1;
  color: #000000;

  ${props =>
    props.active &&
    css`
      color: #ffffff;
      background-color: #333;

      &:hover {
        color: inherit;
      }
    `}
`
