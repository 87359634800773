import { CALL_API } from '../middleware/api'

export const DELETE_TIMELINE_EVENT_REQUEST = 'DELETE_TIMELINE_EVENT_REQUEST'
export const DELETE_TIMELINE_EVENT_SUCCESS = 'DELETE_TIMELINE_EVENT_SUCCESS'
export const DELETE_TIMELINE_EVENT_FAILURE = 'DELETE_TIMELINE_EVENT_FAILURE'
export const FETCH_TIMELINE_EVENTS_REQUEST = 'FETCH_TIMELINE_EVENTS_REQUEST'
export const FETCH_TIMELINE_EVENTS_SUCCESS = 'FETCH_TIMELINE_EVENTS_SUCCESS'
export const FETCH_TIMELINE_EVENTS_FAILURE = 'FETCH_TIMELINE_EVENTS_FAILURE'
export const FETCH_TIMELINE_EVENT_TYPES_REQUEST =
  'FETCH_TIMELINE_EVENT_TYPES_REQUEST'
export const FETCH_TIMELINE_EVENT_TYPES_SUCCESS =
  'FETCH_TIMELINE_EVENT_TYPES_SUCCESS'
export const FETCH_TIMELINE_EVENT_TYPES_FAILURE =
  'FETCH_TIMELINE_EVENT_TYPES_FAILURE'
export const FETCH_TIMELINE_TOTALS_REQUEST = 'FETCH_TIMELINE_TOTALS_REQUEST'
export const FETCH_TIMELINE_TOTALS_SUCCESS = 'FETCH_TIMELINE_TOTALS_SUCCESS'
export const FETCH_TIMELINE_TOTALS_FAILURE = 'FETCH_TIMELINE_TOTALS_FAILURE'

export const deleteTimelineEvent = eventId => ({
  [CALL_API]: {
    endpoint: `/events/${eventId}`,
    method: 'DELETE',
    types: [
      DELETE_TIMELINE_EVENT_REQUEST,
      DELETE_TIMELINE_EVENT_SUCCESS,
      DELETE_TIMELINE_EVENT_FAILURE,
    ],
  },
})

export const fetchTimelineEvents = (
  context,
  startIndex,
  chunkSize,
  type,
  filterMode,
  filteredEvents
) => ({
  [CALL_API]: {
    endpoint: '/events',
    method: 'GET',
    query: {
      context,
      startIndex,
      chunkSize,
      type,
      filter_mode: filterMode,
      filtered_events: filteredEvents,
    },
    types: [
      FETCH_TIMELINE_EVENTS_REQUEST,
      FETCH_TIMELINE_EVENTS_SUCCESS,
      FETCH_TIMELINE_EVENTS_FAILURE,
    ],
  },
})

export const fetchTimelineEventTypes = (
  context,
  filterMode,
  filteredEvents
) => ({
  [CALL_API]: {
    endpoint: '/events/event-types',
    method: 'GET',
    query: {
      context,
      filter_mode: filterMode,
      filtered_events: filteredEvents,
    },
    types: [
      FETCH_TIMELINE_EVENT_TYPES_REQUEST,
      FETCH_TIMELINE_EVENT_TYPES_SUCCESS,
      FETCH_TIMELINE_EVENT_TYPES_FAILURE,
    ],
  },
})

export const fetchTimelineTotals = (
  context,
  type,
  filterMode,
  filteredEvents
) => ({
  [CALL_API]: {
    endpoint: '/events/totals',
    method: 'GET',
    query: {
      context,
      filter_mode: filterMode,
      filtered_events: filteredEvents,
      type,
    },
    types: [
      FETCH_TIMELINE_TOTALS_REQUEST,
      FETCH_TIMELINE_TOTALS_SUCCESS,
      FETCH_TIMELINE_TOTALS_FAILURE,
    ],
  },
})
