/* @flow */

import React, { memo, useContext } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

import {
  IMAGE_RADIUS_CIRCLE,
  IMAGE_RADIUS_ROUNDED,
  IMAGE_SIZE_SMALL,
  IMAGE_SIZE_MEDIUM,
  IMAGE_SIZE_LARGE,
  IMAGE_SIZE_CUSTOM,
} from '../../../settings/brand/constants'
import CloudinaryResource from '../../../../infrastructure/components/CloudinaryResource'

import { ProductTableContext } from './shared'

const HoverImageComponent = (props: ProductTableProps) => {
  let imageSettings = props.imageSettings;
  if (!props.imageSettings) {
    const productTableContextValue = useContext(ProductTableContext)
    imageSettings = productTableContextValue.imageSettings;
  }

  const { image, primary, toggleProductModal } = props

  const imageClasses = classnames({
    'round-pic': imageSettings.image_shape === IMAGE_RADIUS_CIRCLE,
    'rounded-pic': imageSettings.image_shape === IMAGE_RADIUS_ROUNDED,
  })

  const zoomSize = primary
    ? imageSettings.primary_zoom_size
    : imageSettings.zoom_size
  const nonZoomSize = primary
    ? imageSettings.primary_non_zoom_size
    : imageSettings.non_zoom_size

  const useZoomSize =
    imageSettings.zoom_on_hover === true && zoomSize.width > nonZoomSize.width
  const cloudinarySize = useZoomSize ? zoomSize : nonZoomSize

  if (!cachedXScale) {
    cachedXScale = Math.round(zoomSize.width / nonZoomSize.width)
  }
  if (!cachedYScale) {
    cachedYScale = Math.round(zoomSize.height / nonZoomSize.height)
  }

  return (
    <ClickLabel>
      <HoverImage
        className={imageClasses}
        fallback="product_table"
        id={image}
        onClick={toggleProductModal}
        presets="product_table"
        transformations={{
          height: cloudinarySize.height,
          width: cloudinarySize.width,
        }}
        nonZoomHeight={nonZoomSize.height}
        nonZoomWidth={nonZoomSize.width}
        zoomOnHover={imageSettings.zoom_on_hover}
        xScale={cachedXScale}
        yScale={cachedYScale}
      />
    </ClickLabel>
  )
}

export default memo(HoverImageComponent)

const ClickLabel = styled.span`
  cursor: pointer;
`

const HoverImage = styled(CloudinaryResource)`
  -webkit-print-color-adjust: exact;
  height: ${props => `${props.nonZoomHeight}px`};
  transition: transform 0.2s;
  width: ${props => `${props.nonZoomHeight}px`};

  :hover {
    position: relative;
    transform: ${props =>
      props.zoomOnHover
        ? `scaleY(calc(${props.yScale})) scaleX(calc(${props.xScale}))`
        : 'none'};
    z-index: 10000;
  }
`

let cachedXScale, cachedYScale
