import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { resolver } from 'cloudinary-url-resolver'

import traedeLogo from '../../../assets/images/logo.png'
import CloudinaryResource from '../../../infrastructure/components/CloudinaryResource'
import { usePublicBrand } from '../../shop/hooks'

import { useCustomCss } from '../../hooks'

const PublicScreen = props => {
  const { children, width } = props

  const [brand, isFetching, { isError }] = usePublicBrand([])
  const {
    customBackground,
    customBackgroundImage,
    customCss,
  } = React.useMemo(() => {
    let customBackground
    let customBackgroundImage
    let customCss

    if (brand) {
      customBackground = brand.settings.custom_domain_background
      customBackgroundImage = brand.settings.custom_domain_background_image
      customCss = brand.settings.custom_domain_css
    }

    return {
      customBackground,
      customBackgroundImage,
      customCss,
    }
  }, [brand])

  const [element] = useCustomCss(customCss)

  if ((brand === null && !isError) || isFetching) {
    // Do not show anything before public brand is loaded
    return null
  }

  return (
    <Container
      customBackground={customBackground}
      customBackgroundImage={customBackgroundImage}
    >
      <AlignContainer>
        <ContentContainer>
          <LogoContainer>
            <Link to="/">
              {brand !== null && brand.logo ? (
                <CloudinaryResource
                  id={brand.logo}
                  presets="logo_login"
                  format="png" // png for the background transparency
                />
              ) : (
                <img src={traedeLogo} />
              )}
            </Link>
          </LogoContainer>
          <InnerContentContainer>{children}</InnerContentContainer>
        </ContentContainer>
      </AlignContainer>
    </Container>
  )
}

PublicScreen.defaultProps = {
  width: 360,
}

export default PublicScreen

/* Class is custom css hook */
const Container = styled.div.attrs({ className: 'login-container' })`
  background-color: ${({ customBackground }) =>
    customBackground ? customBackground : '#152833'};
  background-image: ${({ customBackgroundImage }) =>
    customBackgroundImage
      ? `url("${resolver(customBackgroundImage)}");`
      : null};
  background-position: center center;
  background-size: cover;
  bottom: 0;
  left: 0;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
`

/* Class is custom css hook */
const AlignContainer = styled.div.attrs({ className: 'login-align-container' })`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const ContentContainer = styled.div.attrs({ className: 'login-content' })`
  width: 100%;
  padding: 0 15px;

  @media (min-width: 430px) {
    width: auto;
    min-width: 400px;
    padding: 0;
  }
`

/* Class is custom css hook */
const LogoContainer = styled.div.attrs({ className: 'login-logo-container' })`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`

const InnerContentContainer = styled.div.attrs({
  className: 'login-inner-content',
})``
