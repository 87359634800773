/* @flow */

import sortBy from 'lodash/sortBy'

export const aggregateColumnsIntoProductTotals = (newSections, columns) => {
  const columnTotals = {}
  const aggregateColumns = columns.filter(c => c.aggregate_product)

  for (let section of newSections) {
    for (let rowGroup of section.rowGroups) {
      for (let subSection of rowGroup.subSections) {
        for (let row of subSection.rows) {
          for (let column of aggregateColumns) {
            if (!column.rows.includes(row.key)) {
              continue
            }

            const rowValue = row.columnValues[column.key] || []

            const aggregatedValue = rowValue.reduce((carry, value) => {
              return (carry += column.aggregate_value
                ? Number(
                    column.aggregate_value({
                      columnValue: value,
                    })
                  )
                : Number(value))
            }, 0)

            if (!columnTotals[column.key]) {
              columnTotals[column.key] = []
            }

            let rowIndex = columnTotals[column.key].findIndex(
              r => r.row === row.key
            )

            if (rowIndex === -1) {
              columnTotals[column.key].push({
                row: row.key,
                label: row.label,
                totals: [],
              })
              rowIndex = columnTotals[column.key].length - 1
            }

            // e.g. with prices we do not wish to sum up the prices, but rather
            // display how many pieces was bought of each price
            if (column.aggregate_product === 'multi') {
              const existingIndex = columnTotals[column.key][
                rowIndex
              ].totals.findIndex(r => r.value === aggregatedValue)

              if (existingIndex === -1) {
                columnTotals[column.key][rowIndex].totals.push({
                  count: 1,
                  value: aggregatedValue,
                })
              } else {
                columnTotals[column.key][rowIndex].totals[
                  existingIndex
                ].count += 1
              }
            } else {
              columnTotals[column.key][rowIndex].totals[0] =
                (columnTotals[column.key][rowIndex].totals[0] || 0) +
                aggregatedValue
            }
          }
        }
      }
    }
  }

  // sort multi aggregate so we show the highest count occurrence first
  for (let column of aggregateColumns) {
    if (column.aggregate_product === 'multi' && columnTotals[column.key]) {
      for (let rowTotals of columnTotals[column.key]) {
        rowTotals.totals = sortBy(rowTotals.totals, val => -val.count).map(
          r => {
            return r.value
          }
        )
      }
    }
  }

  return columnTotals
}
