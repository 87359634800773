/* @flow */

import * as React from 'react'
import styled from 'styled-components'
import keyBy from 'lodash/keyBy'
import groupBy from 'lodash/groupBy'
import uuid from 'uuid'
import merge from 'lodash/merge'
import immer from 'immer'

import ConfiguratorRow from '../ConfiguratorRow'
import ProductDataRow from '../ProductDataRow'
import ProductHeaderRow from '../ProductHeaderRow'
import ProductTotalsRow from '../ProductTotalsRow'
import RowsRenderer from '../RowsRenderer'

import { useProductTableRows } from '../hooks'
import { useSplits } from '../data'

import { createFieldId } from '../shared'
import { ProductTableContext, determineHorizontalAttribute } from '../../shared'
import { VerticalAttributeSplitRow } from '../shared'

import type { Line, Id, Product } from '../../../../../types'

type Props = {
  collapseByDefault: boolean,
  data: { [string]: { [Id]: Array<Object> } },
  filterVariantsWithoutLines: boolean,
  lines: { [Id]: Line },
  maxHorizontalColumns: number,
  noActionColumns: number,
  onProductJump: (direction: 'up' | 'down') => void,
  product: Product,
}

const ProductRow = (
  {
    activeField,
    collapseByDefault = false,
    data,
    dataConfigs,
    dataContext,
    filterVariantsAlwaysRequireLine,
    filterVariantsWithoutLinesByDefault = false,
    filterVariantsByLinesAndData,
    lines,
    maxHorizontalColumns,
    noActionColumns,
    onClearDataCache,
    onConfiguratorAdd,
    onProductJump,
    onTableControlsChange,
    product,
    productActions,
    showNoosLabel,
    tableData,
    toggles,
    setActiveField,
    tableSectionData,
    tableSectionKey,
  }: Props,
  inputRef
) => {
  const variants = React.useMemo(() => {
    return product.variants.filter(v => {
      return v
      //return v.product_id == 58100
      //return v.id == 978633
      //return v.attributes.Color === 'Black' && v.attributes.Size === 'S'
    })
  }, [product.variants])
  const {
    attributes,
    brand,
    brandSettings,
    columns,
    dataColumnsAggregates,
    dataColumnsNonAggregates,
    horizontalAttribute,
    matrix,
    noProductHeadersMode,
    rows,
    dataConfigByType,
    preview,
    primaryDataConfig,
    settings,
    updateLines,
    verticalAttributeColumns,
  } = React.useContext(ProductTableContext)
  const [{ columnsDataConfig, rowsDataConfig }, setDataConfig] = React.useState(
    {
      columnsDataConfig: [],
      rowsDataConfig: [],
    }
  )

  const togglesWithDefaults = React.useMemo(() => {
    const togglesOfProduct = toggles || {}

    return {
      collapsed:
        togglesOfProduct.collapsed !== undefined
          ? togglesOfProduct.collapsed
          : collapseByDefault,
      filterVariantsWithoutLines:
        togglesOfProduct.filter_variants_without_lines !== undefined
          ? togglesOfProduct.filter_variants_without_lines
          : filterVariantsWithoutLinesByDefault,
    }
  }, [collapseByDefault, filterVariantsWithoutLinesByDefault, product, toggles])

  const { collapsed, filterVariantsWithoutLines } = togglesWithDefaults

  const verticalAttributes = React.useMemo(() => {
    const verticalAttributes = product.attributes.filter(
      attr => attr !== horizontalAttribute
    )

    return verticalAttributes
  }, [brand, product, horizontalAttribute])

  const [{ editColumn, splits }, splitsDispatcher] = useSplits(columns)

  const onUpdateLines = React.useCallback(
    (data, targetRow, subSectionKey) => {
      // What we are doing here is to ensure that a split is removed from the user-defined splits
      // array whenever we "actualize" it by adding/removing lines.
      if (targetRow && targetRow.source === 'split') {
        splitsDispatcher({ type: 'delete', row: targetRow, subSectionKey })
      }

      updateLines(data)
    },
    [splitsDispatcher, updateLines]
  )

  const onColumnValueChange = React.useCallback(
    (row, columnValues, updateLineData) => {
      if (row.splitId) {
        splitsDispatcher({
          type: 'update',
          id: row.splitId,
          data: {
            columnValues,
          },
        })
      } else {
        updateLines(updateLineData)

        splitsDispatcher({
          type: 'cancel_edit_column',
        })
      }
    },
    [splitsDispatcher, updateLines]
  )

  const generatedSectionData = useProductTableRows(
    attributes,
    columns,
    data,
    dataConfigs,
    filterVariantsAlwaysRequireLine,
    filterVariantsWithoutLines,
    filterVariantsByLinesAndData,
    horizontalAttribute,
    lines,
    matrix,
    preview,
    product,
    rows,
    splits,
    tableData,
    variants,
    verticalAttributes,
    settings.sort
  )

  const {
    columnTotals,
    generatedSections,
    horizontalAttributeCodes,
    horizontalAttributeValues,
    variants: lastGeneratedVariants,
  } = generatedSectionData

  const onControlKey = React.useCallback(
    key => {
      if (!activeField) {
        return
      }

      const { nextFieldId, shouldJump, jumpDirection } =
        determineNextFieldIdFromControlKeyEvent(
          tableSectionKey,
          activeField,
          key,
          generatedSections
        )

      // Might return false
      if (nextFieldId !== undefined) {
        setActiveField(nextFieldId)
      }
    },
    [
      activeField,
      generatedSections,
      onProductJump,
      setActiveField,
      tableSectionKey,
    ]
  )

  React.useImperativeHandle(inputRef, () => ({
    activeFirstEditableField: () => {
      console.log('YOOOO')
    },
    getSections: () => {
      return generatedSectionData
    },
    getTableSectionData: () => {
      return tableSectionData
    },
  }))

  // all variants might have been filtered out by filterVariantsWithoutLines
  // or filterVariantsByLinesAndData
  if (lastGeneratedVariants.length === 0) {
    return null
  }

  return (
    <>
      {!noProductHeadersMode && (
        <ProductTbody>
          {settings.product_header_show ? (
            <ProductHeaderRow
              collapsed={collapsed}
              key={`header-row-${product.id}`}
              horizontalAttributes={horizontalAttributeValues}
              maxHorizontalColumns={maxHorizontalColumns}
              noActionColumns={noActionColumns}
              onTableControlsChange={onTableControlsChange}
              product={product}
              productActions={productActions}
              productHeaderMode={settings.product_header_mode}
              toggles={togglesWithDefaults}
            />
          ) : null}
          {settings.product_data_show ? (
            <ProductDataRow
              columnTotals={columnTotals}
              dataContext={dataContext}
              key={`product-data-row-${product.id}`}
              maxHorizontalColumns={maxHorizontalColumns}
              noActionColumns={noActionColumns}
              productHeaderMode={settings.product_header_mode}
              product={product}
            />
          ) : null}
          {verticalAttributeColumns && verticalAttributeColumns.length > 0 ? (
            <VerticalAttributeSplitRow key="vertical-attribute-columns-split-row">
              <td
                colSpan={
                  2 + maxHorizontalColumns + columns.length + noActionColumns
                }
              />
            </VerticalAttributeSplitRow>
          ) : null}
        </ProductTbody>
      )}
      {!collapsed ? (
        <React.Fragment key={`product-variants-${product.id}`}>
          <RowsRenderer
            activeField={activeField}
            dataContext={dataContext}
            editColumn={editColumn}
            horizontalAttributeCodes={horizontalAttributeCodes}
            horizontalAttributeValues={horizontalAttributeValues}
            generatedSections={generatedSections}
            matrix={matrix}
            maxHorizontalColumns={maxHorizontalColumns}
            noActionColumns={noActionColumns}
            onClearDataCache={onClearDataCache}
            onColumnValueChange={onColumnValueChange}
            onControlKey={onControlKey}
            onUpdateLines={onUpdateLines}
            product={product}
            sections={generatedSections}
            setActiveField={setActiveField}
            showNoosLabel={showNoosLabel}
            splitsDispatcher={splitsDispatcher}
            tableSectionData={tableSectionData}
            tableSectionKey={tableSectionKey}
            verticalAttributes={verticalAttributes}
          />

          {!preview && product.type === 'configurable' && (
            <ConfiguratorRow
              dataContext={dataContext}
              maxHorizontalColumns={maxHorizontalColumns}
              noActionColumns={noActionColumns}
              onAdd={onConfiguratorAdd}
              product={product}
            />
          )}
        </React.Fragment>
      ) : null}
      {settings.product_header_mode === 'wide_product_data' && (
        <ProductTbody>
          <ProductTotalsRow
            columnTotals={columnTotals}
            dataContext={dataContext}
            maxHorizontalColumns={maxHorizontalColumns}
            noActionColumns={noActionColumns}
            product={product}
          />
        </ProductTbody>
      )}
      {/* if not collapsed this row will be added by VariantsRow */}
      {collapsed || settings.product_header_mode === 'wide_product_data' ? (
        <VerticalAttributeSplitRow key="vertical-attribute-split-row">
          <td
            colSpan={
              2 + maxHorizontalColumns + columns.length + noActionColumns
            }
          />
        </VerticalAttributeSplitRow>
      ) : null}
    </>
  )
}

const ProductRowWithForwardRef = React.forwardRef(ProductRow)

export default (React.memo<Props>(
  ProductRowWithForwardRef
): React.AbstractComponent<Props, mixed>)

const ProductTbody = styled.tbody`
  page-break-inside: avoid;

  /* We want the first variant section tbody to be together with the
     product header tbody, that's why we use after: avoid here */
  page-break-after: avoid;
`

const determineNextFieldIdFromControlKeyEvent = (
  tableSectionKey,
  activeField,
  key,
  generatedSections
) => {
  let nextFieldId
  let shouldJump = false
  let jumpDirection = 'down'

  switch (key) {
    case 'Escape':
      nextFieldId = false

      break
    case 'Tab':
      const currentSection = generatedSections.find(s =>
        s.variantIds.includes(activeField.variant_id)
      )

      if (!currentSection) {
        return
      }

      const variantIndex = currentSection.variantIds.indexOf(
        activeField.variant_id
      )
      const nextVariantId = currentSection.variantIds[variantIndex + 1]

      if (nextVariantId) {
        nextFieldId = createFieldId(
          tableSectionKey,
          activeField.product_id,
          nextVariantId,
          activeField.subsection_key,
          activeField.row_key,
          activeField.column_key
        )
        break
      }

      const currentSectionIndex = generatedSections.findIndex(
        s => s === currentSection
      )
      const nextSection = generatedSections[currentSectionIndex + 1]

      if (nextSection) {
        const nextVariantId = nextSection.variantIds[0]

        if (nextVariantId) {
          let subSectionKeysOfNextSection = new Set()
          for (let rowGroup of nextSection.rowGroups) {
            for (let subSection of rowGroup.subSections) {
              subSectionKeysOfNextSection.add(subSection.subSectionKey)
            }
          }

          const nextSubSectionKey = subSectionKeysOfNextSection.has(
            activeField.subsection_key
          )
            ? activeField.subsection_key
            : Array.from(subSectionKeysOfNextSection)[0]

          nextFieldId = createFieldId(
            tableSectionKey,
            activeField.product_id,
            nextVariantId,
            nextSubSectionKey,
            activeField.row_key,
            activeField.column_key
          )
          break
        }
      }

      break
  }

  return {
    jumpDirection,
    nextFieldId,
    shouldJump,
  }
}
