/* @flow */

import * as React from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import WebshopActionButton from '../WebshopActionButton'
import FormatCurrency from '../../../../infrastructure/components/FormatCurrency'
import WebshopCustomerSelectorModal from '../WebshopCustomerSelectorModal'
import DeleteSessionModal from './DeleteSessionModal'

import { useWebshopSessionListActions } from './shared'

import { renderDateTime, Tooltip } from '../../../shared'
import type { ConnectedBrand, Entity, WebshopSession } from '../../../types'
import { WebshopContext } from '../../shared'

type Props = {
  allowSelectingCustomer: boolean,
  brand: ConnectedBrand,
  currentSession: WebshopSession,
  entity: Entity,
  onHide: Function,
  show: boolean,
  webshopSettings: WebshopSettings,
}

const WebshopSessionListModal = ({
  allowSelectingCustomer,
  availableConnections,
  brand,
  currentSession,
  entity,
  onHide,
  setUseWebshopSessionId,
  show,
  webshopSettings,
}: Props) => {
  const { updateWebshopSession } = React.useContext(WebshopContext)

  const {
    canMakeDropShippingOrder,
    onChangeToSession,
    onClickNewDropShippingOrder,
    onClickNewOrder,
    onDeleteWebshopSession,
    oneSessionHasBrand,
    oneSessionHasDrop,
    refreshSessions,
    sessions,
    setShowDeleteOrderModal,
    setShowNewOrderModal,
    showDeleteOrderModal,
    showNewOrderModal,
    startNewSession,
  } = useWebshopSessionListActions({
    allowSelectingCustomer,
    availableConnections,
    brand,
    currentSession,
    onHide,
    setUseWebshopSessionId,
    updateWebshopSession,
  })

  React.useEffect(() => {
    if (show) {
      refreshSessions()
    }
  }, [refreshSessions, show])

  return (
    <div>
      {showNewOrderModal && (
        <WebshopCustomerSelectorModal
          currentCustomerId={null}
          onHide={() => setShowNewOrderModal(false)}
          onSave={startNewSession}
          show={showNewOrderModal}
          title="Start a new order"
        />
      )}

      {showDeleteOrderModal !== false && (
        <DeleteSessionModal
          onHide={() => setShowDeleteOrderModal(false)}
          onSave={() => onDeleteWebshopSession(showDeleteOrderModal)}
          show={showDeleteOrderModal !== false}
        />
      )}

      <Modal show={show} onHide={onHide} bsSize="large">
        <Modal.Header closeButton>
          <Modal.Title>Current unfinished orders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-condensed">
            <thead>
              <tr>
                <th>No.</th>
                {allowSelectingCustomer && (
                  <>
                    {entity.entity_type === 'shop' ? (
                      <th>Shopping as</th>
                    ) : (
                      <th>Customer</th>
                    )}
                  </>
                )}
                {oneSessionHasBrand && <th>Brand</th>}
                {oneSessionHasDrop && <th>Drop</th>}
                <th className="text-right">Total quantity</th>
                <th className="text-right">Total price</th>
                <th className="text-right">Started at</th>
                <th className="listview-action" />
                <th className="listview-action" />
              </tr>
            </thead>
            <tbody>
              {sessions.map(session => {
                const customerCanMakeDropshipOrder =
                  session.customer_can_make_dropshipping

                return (
                  <tr key={`${session.type}-${session.id}`}>
                    <td>
                      <div>#{session.session_number}</div>
                      {session.type === 'order_selection' && (
                        <span className="label label-warning label-sm">
                          Selection
                        </span>
                      )}
                      {session.dropshipping && (
                        <span className="label label-warning label-sm">
                          Dropshipping
                        </span>
                      )}
                    </td>
                    {allowSelectingCustomer && <td>{session.customer_name}</td>}
                    {oneSessionHasBrand && <td>{session.subbrand_name}</td>}
                    {oneSessionHasDrop && <td>{session.drop_name}</td>}
                    <td className="text-right">{session.quantity}</td>
                    <td className="text-right">
                      {session.currency && (
                        <FormatCurrency currency={session.currency}>
                          {session.price}
                        </FormatCurrency>
                      )}
                    </td>
                    <td className="text-right">
                      {renderDateTime(session.created_at)}
                    </td>
                    <td>
                      {session.webshop_session_id === currentSession.id && (
                        <span className="label label-success label-sm">
                          Current order
                        </span>
                      )}
                      {session.webshop_session_id != currentSession.id && (
                        <WebshopActionButton
                          color="white"
                          size="xsmall"
                          onClick={() => onChangeToSession(session)}
                        >
                          Change to this order
                        </WebshopActionButton>
                      )}
                    </td>
                    <td>
                      {session.type === 'webshop_session' && (
                        <WebshopActionButton
                          color="white"
                          size="xsmall"
                          onClick={() => onDeleteWebshopSession(session)}
                        >
                          <span className="glyphicon glyphicon-remove" />
                        </WebshopActionButton>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <ActionsContainer>
            <ActionsLeftSide>
              <WebshopActionButton size="large" onClick={onHide}>
                Close
              </WebshopActionButton>
            </ActionsLeftSide>

            <ActionsRightSide>
              {!allowSelectingCustomer && canMakeDropShippingOrder && (
                <Tooltip
                  id="start-new-dropshipping-order"
                  tip="Start new dropshipping order"
                >
                  <WebshopActionButton
                    size="large"
                    onClick={onClickNewDropShippingOrder}
                  >
                    <span className="glyphicon glyphicon-plus" /> Start new
                    dropshipping order
                  </WebshopActionButton>
                </Tooltip>
              )}

              <Tooltip
                id="start-new-order"
                tip="Click here if you want to start a new order"
              >
                <WebshopActionButton
                  size="large"
                  color="primary"
                  onClick={onClickNewOrder}
                >
                  <span className="glyphicon glyphicon-plus" /> Start new order
                </WebshopActionButton>
              </Tooltip>
            </ActionsRightSide>
          </ActionsContainer>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default WebshopSessionListModal

const ActionsContainer = styled.div`
  display: flex;

  > div {
    flex: 1;
  }
`

const ActionsRightSide = styled.div`
  justify-content: flex-end;
`

const ActionsLeftSide = styled.div`
  justify-content: flex-start;
  text-align: left;
`
