/* @flow */

import React from 'react'
import { FastField, Field } from 'formik'
import get from 'lodash/get'

import { ErrorLabel } from '../Forms'
import FieldTypeSelector from '../../../app/custom-fields/components/FieldTypeSelector'

import type { CustomField } from '../../../app/types'

type Props = {
  customField: CustomField,
  formikFastField?: boolean,
}

const CustomFieldInput = (props: Props) => {
  const { formikFastField, ...restProps } = props

  // FastField re-renders only when the value of field changes and on few other occassions
  // limiting the number of re-renders. Useful when form starts to slow down because of lot
  // of fields, but can block other needed renders, be careful when using it.
  const FieldComponent = formikFastField === true ? FastField : Field

  return <FieldComponent component={renderCustomFieldInput} {...restProps} />
}

export default CustomFieldInput

const renderCustomFieldInput = props => {
  const {
    customField,
    field: { name, value },
    form: { setFieldValue, touched, errors },
    ...rest
  } = props

  return (
    <div>
      <FieldTypeSelector
        name={customField.internal_name}
        type={customField.field_type}
        value={value}
        options={customField.options}
        onChange={newMeta =>
          setFieldValue(name, newMeta[customField.internal_name])
        }
        description={customField.description}
        groupType={customField.group_type}
        {...rest}
      />

      {get(touched, name) && get(errors, name) && (
        <ErrorLabel>{get(errors, name)}</ErrorLabel>
      )}
    </div>
  )
}
