/* @flow */

import React, { useContext, useMemo, memo } from 'react'
import styled, { css } from 'styled-components'
import isPlainObject from 'lodash/isPlainObject'

import HoverImage from '../../HoverImage'
import {
  ProductTableContext,
  TableRow,
  TableColumn,
  createEmptyArray,
} from '../../shared'
import {
  VerticalAttributeSplitRow,
  createFieldId,
  fieldIdToString,
  createDefaultLineData,
  columnConfigToStyle,
  renderValue,
  resolveColliProps,
} from '../shared'
import VerticalAttributeColumnRender from './VerticalAttributeColumnRender'
import { SessionContext, Tooltip } from '../../../../../shared'
import QuantityField from './QuantityField'
import SplitButton from './SplitButton'

const MatrixVariantsSection = ({
  activeField,
  columnValues = {},
  data,
  dataContext,
  editColumn = false,
  hasNoos,
  horizontalAttributeCodes,
  horizontalAttributeValues,
  maxHorizontalColumns,
  noActionColumns,
  onClearDataCache,
  onColumnValueChange,
  onControlKey,
  onEditColumn,
  onUpdateLines,
  product,
  rowGroups,
  setActiveField,
  showNoosLabel,
  splitsDispatcher,
  tableSectionData,
  tableSectionKey,
  totalRowCount,
  verticalAttributes,
  verticalAttributesCodes,
  verticalAttributesValues,
  verticalAttributeKey,
  variantsByHorizontalAttribute,
  variantsById,
}) => {
  const { entity: userEntity, user } = useContext(SessionContext)
  const context = useContext(ProductTableContext)
  const {
    attribute_codes,
    brand,
    brandSettings,
    columns,
    defaultRowData,
    imageSettings,
    horizontalAttribute,
    productImage,
    preview,
    rows,
    variantImages,
    verticalAttributeColumns,
  } = context

  const flattenedNonSubsectionRows = React.useMemo(() => {
    return rowGroups.reduce((carry, rowGroup) => {
      for (let row of rowGroup.subSections[0].rows) {
        carry.push(row)
      }
      return carry
    }, [])
  }, [rowGroups])

  const { hasRowsWithEnabledColli, suffixRenderWidth } = React.useMemo(() => {
    let hasRowsWithEnabledColli = false
    let suffixRenderWidth = 0

    for (let rowGroup of rowGroups) {
      for (let subSection of rowGroup.subSections) {
        for (let row of subSection.rows) {
          if (row.enable_colli) {
            hasRowsWithEnabledColli = true
          }
          if (row.suffix_render_width) {
            suffixRenderWidth = row.suffix_render_width
          }
        }
      }

      break
    }

    return {
      hasRowsWithEnabledColli,
      suffixRenderWidth,
    }
  }, [rowGroups])

  const verticalAttributesLabel = React.useMemo(() => {
    const label = []

    for (let attribute of verticalAttributes) {
      const attributeValueLabel = []

      if (!verticalAttributesValues[attribute]) {
        continue
      }

      if (
        attribute_codes.includes(attribute) &&
        verticalAttributesCodes[attribute]
      ) {
        attributeValueLabel.push(verticalAttributesCodes[attribute])
      }

      const value = verticalAttributesValues[attribute]
      attributeValueLabel.push(value)

      label.push(attributeValueLabel.join(' '))
    }

    return label.join(' ')
  }, [
    attribute_codes,
    verticalAttributes,
    verticalAttributesCodes,
    verticalAttributesValues,
  ])

  // in preview mode we filter out rows without lines
  if (totalRowCount === 0) {
    return null
  }

  const emptyHorizontalArray = createEmptyArray(maxHorizontalColumns)
  const emptyColumnsArray = createEmptyArray(
    maxHorizontalColumns - horizontalAttributeValues.length
  )

  const variants = Object.values(variantsById)

  const keyGen = key => `${verticalAttributeKey}-${key}`
  let attributeColumnWidth = hasRowsWithEnabledColli ? 120 : 75
  if (suffixRenderWidth > 0) {
    attributeColumnWidth += suffixRenderWidth
  }

  const generatedRows = [
    /**
     * verticalAttributeColumns
     */
    verticalAttributeColumns && verticalAttributeColumns.length > 0 ? (
      <VerticalAttributeSplitRow key="vertical-attribute-columns-row">
        <td colSpan={3 + maxHorizontalColumns} />
        <td colSpan={columns.length + noActionColumns}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {verticalAttributeColumns.map(column => {
              return (
                <VerticalAttributeColumnRender
                  brand={brand}
                  column={column}
                  dataContext={dataContext}
                  defaultRowData={defaultRowData}
                  editColumn={editColumn}
                  horizontalAttributeValues={horizontalAttributeValues}
                  onColumnValueChange={onColumnValueChange}
                  onUpdateLines={onUpdateLines}
                  product={product}
                  rowGroups={rowGroups}
                  splitsDispatcher={splitsDispatcher}
                  tableSectionData={tableSectionData}
                  variants={variants}
                />
              )
            })}
          </div>
        </td>
      </VerticalAttributeSplitRow>
    ) : null,

    /**
     * TOP ROW: The once that displays Color, Sizes and Data Column Headers (Unit price, Total Quantity, Total Price)
     */
    <TableVerticalHeaderRow key={keyGen('header-row')}>
      <TableColumn
        colSpan={productImage === true || variantImages === true ? 2 : 1}
      >
        {verticalAttributesLabel}{' '}
        {showNoosLabel && hasNoos && <span>(NOOS)</span>}
      </TableColumn>
      {horizontalAttributeValues.map(attribute => {
        let attributeLabel = []

        if (
          attribute_codes.includes(horizontalAttribute) &&
          horizontalAttributeCodes[attribute]
        ) {
          attributeLabel.push(horizontalAttributeCodes[attribute])
        }

        attributeLabel.push(attribute)

        return (
          <TableColumn key={attribute} style={{ width: attributeColumnWidth }}>
            {attributeLabel.join(' ')}
          </TableColumn>
        )
      })}
      {emptyColumnsArray.map((c, i) => (
        <TableColumn key={`empty-${i}`} />
      ))}
      <TableColumn />
      {columns.map((column, i) => {
        return (
          <TableColumn key={column.key} style={columnConfigToStyle(column)}>
            {column.label}
          </TableColumn>
        )
      })}
      {createEmptyArray(noActionColumns).map((v, i) => (
        <TableColumn key={`actions-${i}`} />
      ))}
    </TableVerticalHeaderRow>,
    /**
     * DATA SECTIONS
     */
    ...rowGroups.reduce((carry, rowGroup, i) => {
      const { subSections } = rowGroup

      const nonEmptySubsections = subSections.filter(
        s => s.subSectionKey !== 'traede_no_split'
      )
      // We want to display the label if any of the subsections are != null
      const isSubsectioned = nonEmptySubsections.length > 0
      let showSubsectionLabel = isSubsectioned

      if (rowGroup.show_label_filter) {
        showSubsectionLabel = rowGroup.show_label_filter({
          subSections,
        })
      }

      let variantColumnRowSpan = totalRowCount
      if (showSubsectionLabel) {
        variantColumnRowSpan += subSections.length
      }

      const pictureColumn =
        (productImage === true || variantImages === true) && i === 0 ? (
          <VariantImageColumn
            style={{
              width: imageSettings.column_width,
              minWidth: imageSettings.column_width - 20,
            }}
            rowSpan={variantColumnRowSpan}
          >
            {variantImages === true && (
              <HoverImage image={variants[0].picture} />
            )}
          </VariantImageColumn>
        ) : null

      for (let [k, subSection] of subSections.entries()) {
        if (showSubsectionLabel) {
          carry.push(
            /**
             * SECTION HEADER ROW (e.g. Batch row)
             */
            <TableDataSectionRow
              key={keyGen(`section-header-row-${subSection.subSectionKey}`)}
            >
              {k === 0 && pictureColumn}
              <SubsectionHeaderColumn>
                {subSection.subSectionLabel}
              </SubsectionHeaderColumn>
              {emptyHorizontalArray.map((v, i) => (
                <SubsectionHeaderColumn key={i} />
              ))}
              <SubsectionHeaderColumn />
              {columns.map(column => (
                <SubsectionHeaderColumn key={column.key} />
              ))}
              {createEmptyArray(noActionColumns).map((v, i) => (
                <SubsectionHeaderColumn key={`actions-${i}`} />
              ))}
            </TableDataSectionRow>
          )
        }

        for (let [k, row] of subSection.rows.entries()) {
          const {
            actions = [],
            assortmentQuantity,
            assortmentVariant,
            label,
            columnKey,
            columnValues,
            isAssortmentRow,
            lines,
            linesByVariantId,
            rowValuesByVariantId,
            rowValuesCollisByVariantId,
            splittable,
          } = row
          const { subSectionKey } = subSection

          const useActions = [...actions].filter(
            a => a.matrix === true || a.matrix === undefined
          )
          const siblingRows = isSubsectioned
            ? subSection.rows.filter(r => r !== row)
            : flattenedNonSubsectionRows.filter(r => r !== row)

          if (splittable) {
            const splittableColumns = columns.filter(
              c => c.editable && c.split_by_values
            )

            if (splittableColumns.length > 0) {
              useActions.unshift({
                key: 'splittable',
                render: () => {
                  if (!row.editable) {
                    return null
                  }

                  const createSplit = column => {
                    splitsDispatcher({
                      column,
                      row,
                      type: 'split',
                      subSectionKey: subSectionKey,
                      verticalAttributeKey,
                    })
                  }

                  return (
                    <SplitButton
                      columns={splittableColumns}
                      onSplit={createSplit}
                      row={row}
                    />
                  )
                },
              })
            }
          }

          const noEmptyActionsColumns = noActionColumns - useActions.length

          carry.push(
            <TableDataSectionRow
              key={keyGen(
                `row-${subSection.subSectionKey}-${row.key}-${row.columnKey}`
              )}
              last={k === subSection.rows.length - 1}
            >
              {(isSubsectioned === false || !showSubsectionLabel) &&
                k === 0 &&
                pictureColumn}
              <SectionHeaderColumn
                className="product-table-hover-column"
                style={{ width: 125 }}
              >
                <SectionHeaderContentContainer>
                  <SectionHeaderLabel>{label}</SectionHeaderLabel>
                </SectionHeaderContentContainer>
              </SectionHeaderColumn>
              {horizontalAttributeValues.map((attribute, i) => {
                const variant = variantsByHorizontalAttribute[attribute]

                const value = variant ? rowValuesByVariantId[variant.id] : null
                const valueColli = variant
                  ? rowValuesCollisByVariantId[variant.id]
                  : null

                const rowValuesSiblings = {}
                for (let siblingRow of siblingRows) {
                  if (!variant) {
                    rowValuesSiblings[siblingRow.key] = {}
                    continue
                  }

                  if (!rowValuesSiblings[siblingRow.key]) {
                    rowValuesSiblings[siblingRow.key] = 0
                  }

                  const siblingRowVariantValue =
                    siblingRow.rowValuesByVariantId[variant.id] || 0

                  if (!isNaN(parseInt(siblingRowVariantValue))) {
                    rowValuesSiblings[siblingRow.key] += siblingRowVariantValue
                  } else {
                    rowValuesSiblings[siblingRow.key] = siblingRowVariantValue
                  }
                }

                // needed before editable_check
                let lines = []
                if (variant) {
                  lines = linesByVariantId[variant.id] || []
                }

                let style = {}
                if (row.style && typeof row.style === 'function') {
                  style = row.style({
                    rowValuesSiblings,
                    matrix: true,
                    value,
                  })
                }

                let mode
                if (!variant) {
                  mode = 'disabled'
                } else if (row.editable === true) {
                  // in order module when making a split price all variants are editable
                  // however, when you make shipments, invoices, PO delivery notes etc.
                  // *from* an order/PO you only want the variants that actually have a
                  // line to be editable
                  let editableCheck = true
                  if (typeof row.editable_check === 'function') {
                    editableCheck = row.editable_check({
                      lines,
                      variant,
                    })
                  }

                  if (
                    editableCheck &&
                    (!row.editable_variants ||
                      row.editable_variants.includes(variant.id))
                  ) {
                    mode = 'editable'
                  } else {
                    mode = 'disabled'
                  }
                }

                let suffixRenderFunction
                if (typeof row.suffix_render === 'function') {
                  suffixRenderFunction = row.suffix_render
                }

                let disabled = false
                if (typeof row.disabled === 'function') {
                  disabled = row.disabled({
                    lines,
                    variant,
                  })
                }

                const { availableColli, defaultColli } = resolveColliProps({
                  brandSettings,
                  customer: dataContext.customer,
                  product,
                  variant,
                  user,
                })

                let assortmentQuantity
                let assortmentName
                if (isAssortmentRow) {
                  assortmentName =
                    assortmentVariant.attributes[horizontalAttribute]

                  if (attribute != assortmentName) {
                    mode = 'assortment'
                    assortmentQuantity =
                      assortmentVariant.assortment[attribute] ?? null
                  }
                }

                const fieldId = variant
                  ? createFieldId(
                      tableSectionKey,
                      product.id,
                      variant.id,
                      subSection.subSectionKey,
                      row.key,
                      row.columnKey
                    )
                  : false
                const showField = fieldId
                  ? activeField &&
                    fieldIdToString(activeField) === fieldIdToString(fieldId)
                  : false

                const onChange = updateData => {
                  onUpdateLines(
                    {
                      createOnMissing: true,
                      defaultRowData: createDefaultLineData(
                        tableSectionData,
                        defaultRowData,
                        subSection,
                        columns,
                        row
                      ),
                      product,
                      variants: [variant],
                      lines: lines,
                      type: 'update',
                      updates: updateData,
                    },
                    row,
                    subSectionKey
                  )
                }

                // we add a class to prevent background on image column
                return (
                  <TableColumn
                    key={attribute}
                    mode={mode}
                    className="product-table-hover-column"
                    style={style}
                  >
                    {mode === 'assortment' && (
                      <AssortmentQuantityContainer>
                        {assortmentQuantity}
                      </AssortmentQuantityContainer>
                    )}
                    {mode !== 'disabled' && mode !== 'assortment' && (
                      <QuantitySuffixContainer
                        hasSuffix={suffixRenderFunction !== undefined}
                        variantsCount={maxHorizontalColumns}
                      >
                        {row.editable !== true &&
                          renderValue({
                            brand,
                            config: {
                              ...row,
                              horizontalAttribute: attribute,
                              rowValuesSiblings,
                            },
                            data: {
                              context: dataContext,
                              data,
                              onClearDataCache,
                              onChange,
                              lines,
                              variant,
                            },
                            value,
                            valueColli,
                            userEntity,
                          })}
                        {row.editable === true && (
                          <QuantityFieldContainer
                            hasSuffix={suffixRenderFunction !== undefined}
                          >
                            <QuantityField
                              availableColli={availableColli}
                              colliIncludedInValue={
                                row.colli_not_included_in_value !== true
                              }
                              defaultColli={defaultColli}
                              disabled={disabled}
                              enableColli={row.enable_colli}
                              id={fieldIdToString(fieldId)}
                              onBlur={() => {
                                // In case we tab an onBlur event will also be fired when we focus away from this input.
                                // In such a case we want to ignore the event to prevent deactivating some other field.
                                if (showField) {
                                  setActiveField(false)
                                }
                              }}
                              onChange={({ quantity, colli }) => {
                                const editProperty =
                                  row.edit_property || row.key
                                const editColliProperty =
                                  row.edit_colli_property

                                let updateData = { [editProperty]: quantity }

                                if (editColliProperty) {
                                  updateData[editColliProperty] = colli
                                }

                                if (row.map_edit) {
                                  updateData = row.map_edit({
                                    data: updateData,
                                    lines,
                                  })
                                }

                                onChange(updateData)
                              }}
                              onControlKey={onControlKey}
                              onLabelClick={() => setActiveField(fieldId)}
                              right={row.right}
                              showField={showField}
                              value={value || ''}
                              valueColli={valueColli}
                            />
                          </QuantityFieldContainer>
                        )}

                        {suffixRenderFunction && (
                          <div
                            style={{
                              width: row.suffix_render_width
                                ? `${row.suffix_render_width}px`
                                : 'auto',
                            }}
                          >
                            {suffixRenderFunction({
                              lines,
                              variant,
                            })}
                          </div>
                        )}
                      </QuantitySuffixContainer>
                    )}
                  </TableColumn>
                )
              })}
              {emptyColumnsArray.map((attribute, i) => {
                return (
                  <TableColumn key={i} className="product-table-hover-column" />
                )
              })}
              <TableColumn className="product-table-hover-column" />
              {columns.map((column, i) => {
                let edit = false
                if (
                  editColumn !== false &&
                  editColumn.columnKey === column.key &&
                  editColumn.rowId === row.id
                ) {
                  edit = true
                }

                const renderData = {
                  columnValues,
                  context: dataContext,
                  editProperty: column.edit_property,
                  editable: column.editable,
                  edit: edit,
                  horizontalAttributeValues,
                  lines,
                  onColumnValueChange: (
                    lineUpdate,
                    splitUpdate,
                    overrideLines
                  ) => {
                    const useSplitUpdate = splitUpdate || lineUpdate

                    const useLines = overrideLines || row.lines

                    // If we have an order without any lines and we change the unit price
                    // then a line will get created from `variants` (because createOnMissing is true)
                    // since assortments are always on their own line we need to then only
                    // create a line for that specific assortment. and vice versa if we adjust
                    // the price of single variants then we should not adjust the prices of assortments
                    let variantsOfRow
                    if (!row.isAssortmentRow) {
                      variantsOfRow = variants.filter(v => !v.assortment_id)
                    } else {
                      variantsOfRow = [row.assortmentVariant]
                    }

                    return onColumnValueChange(
                      row,
                      {
                        [column.key]: [useSplitUpdate],
                      },
                      {
                        createOnMissing: true,
                        defaultRowData: createDefaultLineData(
                          tableSectionData,
                          defaultRowData,
                          subSection,
                          columns,
                          row
                        ),
                        product,
                        variants: variantsOfRow,
                        lines: useLines,
                        type: 'update',
                        updates: lineUpdate,
                      }
                    )
                  },
                  onEdit: editMode => {
                    if (editMode) {
                      splitsDispatcher({
                        type: 'edit_column',
                        row,
                        column,
                      })
                    } else {
                      splitsDispatcher({
                        type: 'cancel_edit_column',
                      })
                    }
                  },
                  product,
                  row,
                  updateLines: onUpdateLines,
                  rowValues: rowValuesByVariantId,
                  variants,
                }

                const shouldRenderForRow = column.rows.includes(row.key)
                const value = row.columnValues[column.key]
                const style = column.style ? column.style(renderData) : {}

                return (
                  <TableColumn
                    className="product-table-hover-column"
                    key={column.key}
                    style={{
                      ...columnConfigToStyle(column),
                      ...style,
                    }}
                  >
                    {shouldRenderForRow &&
                      value &&
                      renderValue({
                        data: renderData,
                        config: column,
                        value: value,
                      })}
                  </TableColumn>
                )
              })}
              {useActions.map(action => {
                return (
                  <TableColumn
                    className="product-table-hover-column"
                    key={`actions-${action.key}`}
                    width="1%"
                  >
                    {action.render({
                      columns,
                      defaultRowData,
                      horizontalAttributeValues,
                      lines,
                      linesByVariantId,
                      row,
                      siblingRows,
                      splitsDispatcher,
                      subSection,
                      subSectionKey: subSection.subSectionKey,
                      subSectionSplitData: subSection.subSectionSplitData,
                      tableSectionData,
                      updateLines: onUpdateLines,
                      variantsByHorizontalAttribute,
                      variants,
                      rowValuesByVariantId,
                    })}
                  </TableColumn>
                )
              })}
              {createEmptyArray(noEmptyActionsColumns).map((v, i) => (
                <TableColumn
                  className="product-table-hover-column"
                  key={`actions-t${i}`}
                />
              ))}
            </TableDataSectionRow>
          )
        }
      }

      return carry
    }, []),
    <VerticalAttributeSplitRow key="vertical-attribute-split-row">
      <td
        colSpan={3 + maxHorizontalColumns + columns.length + noActionColumns}
      />
    </VerticalAttributeSplitRow>,
  ]

  return generatedRows
}

export default memo(MatrixVariantsSection)

// background important is needed to override chrome print styles
const TableVerticalHeaderRow = styled(TableRow)`
  td {
    background: #e2e2e2 !important;
    -webkit-print-color-adjust: exact;
    /* border-top: none because for some reason the border stays on the previous page
       in Chrome when page-breaking right before this section */
    border-top: none;
    border-bottom: none;
    color: black;
    font-size: 11px;
    line-height: 1.55;
    font-weight: bold;
  }
`

const TableDataSubSectionRow = styled(TableRow)`
  td {
    border-bottom: ${({ last = false }) =>
      last ? 'none' : '1px dashed #e6e6e6'};
    border-top: 1px dashed #e6e6e6;
  }
`

const TableDataSectionRow = styled(TableRow)`
  td {
    border-top: ${({ last }: { last: boolean }) =>
      last ? '1px solid #e0e0e0' : 'none'};
  }
`

const SectionHeader = styled.span``

const SectionHeaderColumn = styled(TableColumn)``

const SectionHeaderContentContainer = styled.div`
  display: flex;
`

const SectionHeaderLabel = styled.div`
  flex: 1;
`

// background important is needed to override chrome print styles
const SubsectionHeaderColumn = styled(TableColumn)`
  background: #f9f9f9 !important;
  -webkit-print-color-adjust: exact;
  color: #b5b5b5;
  font-size: 10px;
  font-style: italic;
  font-weight: bold;
  padding-right: 5px;
  text-align: right;
`

const VariantImageColumn = styled(TableColumn)`
  vertical-align: middle;
`

const QuantityFieldContainer = styled.div`
  ${({ hasSuffix }) =>
    hasSuffix
      ? css`
          flex: 1;
          margin-bottom: auto;
        `
      : ''};
`

const QuantitySuffixContainer = styled.div`
  display: ${({ hasSuffix }) => (hasSuffix ? 'flex' : 'block')};
  flex-direction: ${({ variantsCount }) =>
    variantsCount > 5 ? 'column' : 'row'};
`

const AssortmentQuantityContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`
