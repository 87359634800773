/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import moment from 'moment-timezone'

import InputField from '../../Rows/InputField'

import type { ColumnConfig } from '../../types'
import {
  addPrefixIfNoneExists,
  renderDate,
  Tooltip,
} from '../../../../../shared'

type Options = {}

const available: (options: Options) => ColumnConfig = ({}) => ({
  aggregate: 'quantity',
  // we need to add data source here otherwise batch is not going to work
  data_source: 'inventory_level',
  key: 'available',
  label: 'Available',
})

export default available
