/* @flow */

import { CALL_API } from '../../../infrastructure/middleware/api'

import type { BrunoRequestOptions, Id } from '../../types'
import type { NonPersistedDrop } from '../types'

export const CREATE_DROP_REQUEST = 'CREATE_DROP_REQUEST'
export const CREATE_DROP_SUCCESS = 'CREATE_DROP_SUCCESS'
export const CREATE_DROP_FAILURE = 'CREATE_DROP_FAILURE'
export const DELETE_DROP_REQUEST = 'DELETE_DROP_REQUEST'
export const DELETE_DROP_SUCCESS = 'DELETE_DROP_SUCCESS'
export const DELETE_DROP_FAILURE = 'DELETE_DROP_FAILURE'
export const GET_DROPS_REQUEST = 'GET_DROPS_REQUEST'
export const GET_DROPS_SUCCESS = 'GET_DROPS_SUCCESS'
export const GET_DROPS_FAILURE = 'GET_DROPS_FAILURE'
export const UPDATE_DROP_REQUEST = 'UPDATE_DROP_REQUEST'
export const UPDATE_DROP_SUCCESS = 'UPDATE_DROP_SUCCESS'
export const UPDATE_DROP_FAILURE = 'UPDATE_DROP_FAILURE'

export const createDrop = (drop: NonPersistedDrop) => ({
  [CALL_API]: {
    endpoint: '/drops',
    method: 'POST',
    body: {
      drop: drop,
    },
    types: [CREATE_DROP_REQUEST, CREATE_DROP_SUCCESS, CREATE_DROP_FAILURE],
  },
})

export const deleteDrop = (id: Id) => ({
  [CALL_API]: {
    endpoint: `/drops/${id}`,
    method: 'DELETE',
    types: [DELETE_DROP_REQUEST, DELETE_DROP_SUCCESS, DELETE_DROP_FAILURE],
  },
})

export const getDrops = (options?: BrunoRequestOptions) => ({
  [CALL_API]: {
    endpoint: '/drops',
    method: 'GET',
    query: options,
    types: [GET_DROPS_REQUEST, GET_DROPS_SUCCESS, GET_DROPS_FAILURE],
  },
})

export const updateDrop = (id: Id, drop: $Shape<NonPersistedDrop>) => ({
  [CALL_API]: {
    endpoint: `/drops/${id}`,
    method: 'PUT',
    body: {
      drop: drop,
    },
    types: [UPDATE_DROP_REQUEST, UPDATE_DROP_SUCCESS, UPDATE_DROP_FAILURE],
  },
})
