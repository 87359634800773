/* @flow */

import React from 'react'
import { connect } from 'react-redux'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import StockLabel from '../bindings/stock-label'
import locale from '../modules/localization'

import { useConnectedBrand } from '../../app/hooks'
import type { Id, InventoryLocation, StockItem } from '../../app/types'

type StockListProps = {
  entityId: Id,
  label?: boolean,
  showEmpty?: boolean,
  stock: Array<StockItem>,
  style?: Object,
}

const BaseStockList = ({
  brand,
  entityId,
  label = true,
  showEmpty = false,
  stock,
  style = {},
}: StockListProps) => {
  const { defaultLocation, locationsById } = React.useMemo(() => {
    return {
      defaultLocation: brand.inventory_locations.find(
        location => location.default === true
      ),
      locationsById: keyBy(brand.inventory_locations, 'id'),
    }
  }, [brand])

  const stockSorted = React.useMemo(() => {
    let useStock = stock
    if (showEmpty === true && stock.length === 0) {
      useStock = [
        {
          inventory_location_id: defaultLocation.id,
          quantity: 0,
        },
      ]
    }

    return sortBy(useStock, stock => {
      const location = locationsById[stock.inventory_location_id]

      if (!location) {
        return 0
      }

      if (location.default) {
        return -10
      }

      return location.sort
    })
  }, [defaultLocation, locationsById, showEmpty, stock])

  return (
    <ul className="stock-list stock-list-no-dots">
      {stockSorted.map((entry, i) => {
        const location = locationsById[entry.inventory_location_id]

        if (!location) {
          return null
        }

        return (
          <li key={i} style={style}>
            <ColoredStockLabel location={location}>
              {entry.quantity}
              {label === true && ` ${location.name}`}
            </ColoredStockLabel>
          </li>
        )
      })}
    </ul>
  )
}

// For some reason useConnectedBrands does not work here. Probably because a popover is
// rendered outside of the context provider
export const StockList = connect((state, ownProps) => {
  const { entityId } = ownProps
  const {
    brands: { items: brands },
    session: { entity },
  } = state

  let brand
  if (entity.entity_type === 'brand') {
    brand = brands[entity.id]
  } else {
    brand = brands[entityId]
  }

  return {
    brand,
  }
})(BaseStockList)

type ColoredStockLabelProps = {
  children?: React.Node,
  location: InventoryLocation,
}

export const ColoredStockLabel = ({
  children,
  location,
}: ColoredStockLabelProps) => {
  return <span style={{ color: location.color }}>{children}</span>
}

export { StockLabel }
