/* @flow */

import * as React from 'react'
import { Component } from 'react'
import styled from 'styled-components'
import keyBy from 'lodash/keyBy'
import groupBy from 'lodash/groupBy'

import type {
  Report,
  ReportDataEntry,
  ReportDataMeasurement,
  ReportDataProperty,
  Sort,
} from '../types'
import {
  buildMeasurementCatalogue,
  createOptionsKey,
  extractCombinations,
  getPointFromCatalogue,
} from '../shared'

import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  MultiGrid,
  WindowScroller,
} from 'react-virtualized'

import FormatCurrency from '../../../../../infrastructure/components/FormatCurrency'

const STYLE = {
  border: '1px solid #ddd',
}
const STYLE_BOTTOM_LEFT_GRID = {
  borderRight: '2px solid #aaa',
  backgroundColor: '#f9f9f9',
}
const STYLE_TOP_LEFT_GRID = {
  borderBottom: '2px solid #aaa',
  borderRight: '2px solid #aaa',
}
const STYLE_TOP_RIGHT_GRID = {
  borderBottom: '2px solid #aaa',
}
const baseCellStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderBottom: '1px solid #eee',
  borderRight: '1px solid #eee',
  padding: '0px 5px',
}

const CELL_WIDTH = 100
const CELL_HEIGHT_PADDING = 10
const CELL_LINE_HEIGHT = 16
const LETTER_WIDTH = 45
const MAX_BODY_COLUMN_WIDTH = 250

const calculateCellHeight = (labels: Array<String>) =>
  CELL_HEIGHT_PADDING * 2 + CELL_LINE_HEIGHT * labels.length
const calculateCellWidth = (labels: Array<String>) => {
  return labels.reduce((carry, label) => {
    // Get length of numbers
    const asString = label.value.toString ? label.value.toString() : label.value
    const width = asString.length * LETTER_WIDTH
    if (width > carry) {
      return width
    }
    return carry
  }, 0)
}

type Props = {
  data: null | Report,
  onDataGenerated: (tableRows: Array<Object>) => void,
  show: boolean,
}

type State = {
  columnWidths: Array<number>,
  error: null | string,
  noHeaderRows: number,
  noRowColumns: number,
  noColumnHeaderRows: number,
  rowHeights: Array<number>,
  tableRows: Array,
  totalColumns: number,
  totalRows: number,
}

class DataTable extends Component<Props, State> {
  _gridRef: ?MultiGrid

  state = {
    columnWidths: [],
    error: null,
    noHeaderRows: 0,
    noRowColumns: 0,
    noColumnHeaderRows: 0,
    rowHeights: [],
    tableRows: [],
    totalColumns: 0,
    totalRows: 0,
  }

  constructor(props) {
    super(props)

    this._cellCache = new CellMeasurerCache({
      defaultWidth: 100,
      minWidth: 75,
      fixedHeight: true,
    })
  }

  componentDidMount() {
    this._generateRows(this.props.data)
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.data !== this.props.data) {
      this._generateRows(nextProps.data)
    }
  }

  render() {
    const { data, show, ...rest } = this.props
    const {
      columnWidths,
      error,
      noHeaderRows,
      noRowColumns,
      noColumnHeaderRows,
      totalColumns,
      totalRows,
    } = this.state

    if (!data) {
      return null
    }

    if (error) {
      return <div>{error}</div>
    }

    if (data.data.length === 0) {
      return <div>No data was found</div>
    }

    return (
      <Container show={show}>
        <AutoSizer>
          {({ height, width }) => {
            const topRowHeight = calculateCellHeight([1])
            const gridHeight =
              data.columns.length > 0 ? height - topRowHeight : height

            let fixedColumnCount = 0
            // Do not fix the row columns unless there are columns
            if (noRowColumns > 0 && data.columns.length > 0) {
              fixedColumnCount = noRowColumns
            }
            let fixedRowCount = 0
            if (noColumnHeaderRows > 0 && totalRows > noColumnHeaderRows) {
              fixedRowCount = noColumnHeaderRows
            }

            let rowHeaderColumns = []
            let columnHeaderColumns = columnWidths
            if (noRowColumns > 0) {
              rowHeaderColumns = columnWidths.slice(0, noRowColumns)
              columnHeaderColumns = columnWidths.slice(noRowColumns)
            }

            const columnHeaderStyle = { flex: 1 }
            return (
              <div style={{ height, width }}>
                {data.columns.length > 0 && (
                  <div
                    style={{
                      height: topRowHeight,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ ...columnHeaderStyle, textAlign: 'center' }}>
                      <strong>{data.columns[0].label}</strong>
                    </div>
                  </div>
                )}
                <MultiGrid
                  columnWidth={this._cellCache.columnWidth}
                  deferredMeasurementCache={this._cellCache}
                  cellRenderer={this._cellRenderer}
                  columnCount={totalColumns}
                  fixedColumnCount={fixedColumnCount}
                  fixedRowCount={fixedRowCount}
                  height={gridHeight}
                  ref={this._setGridRef}
                  rowHeight={this._getRowHeight}
                  rowCount={totalRows}
                  styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
                  styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
                  styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
                  width={width}
                />
              </div>
            )
          }}
        </AutoSizer>
      </Container>
    )
  }

  _cellRenderer = ({ columnIndex, key, parent, rowIndex, style }) => {
    const { tableRows } = this.state
    const cell = tableRows[rowIndex][columnIndex]
    const { cellStyle: cellStyleOverride = {} } = cell

    const cellStyle = {
      ...baseCellStyle,
      ...style,
      ...cellStyleOverride,
    }

    return (
      <CellMeasurer
        cache={this._cellCache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div key={key} style={cellStyle}>
          {cell.labels.map(label => {
            const labelOptions = label.labelOptions || {}
            const renderOptions = label.renderOptions || {}

            let LabelRender = labelRenderers[label.labelRender]
            if (!LabelRender) {
              LabelRender = Label
            }
            return (
              <LabelRender
                bucketName={label.bucketName}
                options={labelOptions}
                renderOptions={renderOptions}
                value={label.value}
              />
            )
          })}
        </div>
      </CellMeasurer>
    )
  }

  _getColumnWidth = ({ index }: { index: number }) => {
    return this.state.columnWidths[index]
  }

  _getRowHeight = ({ index }: { index: number }) => {
    return this.state.rowHeights[index]
  }

  _generateRows = (report: null | Report) => {
    if (!report) {
      return
    }

    const { columns, data, measurements, rows } = report

    if (!data || data.length === 0) {
      return
    }

    this._cellCache.clearAll()

    const stateChanges = generateRows(columns, data, rows, measurements)

    this.setState(stateChanges, () => {
      if (this._gridRef) {
        this._gridRef.forceUpdateGrids()
        this._gridRef.forceUpdate()
      }
    })
  }

  _setGridRef = (ref: MultiGrid) => {
    this._gridRef = ref
  }
}

export default DataTable

const Container = styled.div`
  display: ${({ show }: { show: boolean }) => (show ? 'flex' : 'none')};
  flex: 1;
`

const TableRow = styled.div`
  display: flex;
`

const ColumnHeader = styled.div`
  text-align: ${({ right = false }: { right: boolean }) =>
    right ? 'right' : 'center'};
`

const positiveNegativeColorScheme = value => {
  if (value > 0.1) {
    return '#329a34'
  } else if (value < 0) {
    return '#f35958'
  }
}

const indexPositiveNegativeColorScheme = value => {
  if (value >= 100) {
    return '#329a34'
  } else {
    return '#f35958'
  }
}

const IndexRenderer = ({ bucketName, value, ...rest }) => {
  return (
    <Label
      bucketName={bucketName}
      colorScheme={indexPositiveNegativeColorScheme}
      label={value}
      value={value}
    />
  )
}
const PercentageRender = ({ bucketName, value, ...rest }) => {
  return (
    <Label
      bucketName={bucketName}
      colorScheme={positiveNegativeColorScheme}
      label={v => v + '%'}
      value={value.toFixed(1)}
    />
  )
}
const HeaderRenderer = ({ bucketName, value, ...rest }) => {
  return (
    <Label
      bucketName={bucketName}
      style={{ fontWeight: 'bold' }}
      label={value}
      value={value}
    />
  )
}
const MoneyRenderer = ({
  bucketName,
  options: { currency },
  renderOptions: { currency: renderOptionsCurrency } = {},
  value,
  ...rest
}) => {
  const useCurrency = renderOptionsCurrency || currency
  return (
    <Label
      bucketName={bucketName}
      label={v => <FormatCurrency currency={useCurrency}>{v}</FormatCurrency>}
      value={value}
      {...rest}
    />
  )
}
const QuantityRenderer = ({
  bucketName,
  value,
}: {
  bucketName?: string,
  value: number,
}) => {
  return (
    <Label
      bucketName={bucketName}
      label={v => v + ' pcs.'}
      value={value.toFixed(0)}
    />
  )
}
const NoValueRenderer = ({ bucketName, value }) => (
  <Label
    bucketName={bucketName}
    style={{ fontStyle: 'italic' }}
    label={value}
    value={value}
  />
)

const labelRenderers = {
  colored_money: props => (
    <MoneyRenderer colorScheme={positiveNegativeColorScheme} {...props} />
  ),
  index: IndexRenderer,
  header: HeaderRenderer,
  money: MoneyRenderer,
  no_value: NoValueRenderer,
  percentage: PercentageRender,
  quantity: QuantityRenderer,
}

type LabelProps = {
  bucketName?: string,
  colorScheme?: Function,
  style?: Object,
  label: string | number | Function,
  value: string | number,
}

const Label = ({
  bucketName,
  colorScheme,
  label = v => v,
  style = {},
  value,
}: LabelProps) => {
  let color = colorScheme ? colorScheme(value) : undefined
  style.color = color

  return (
    <LabelContainer
      style={{ ...style, overflow: 'hidden', maxWidth: MAX_BODY_COLUMN_WIDTH }}
    >
      {bucketName && <span>{bucketName}: </span>}
      {typeof label === 'function' && <span>{label(value)}</span>}
      {typeof label !== 'function' && <span>{label}</span>}
    </LabelContainer>
  )
}

const LabelContainer = styled.div`
  white-space: nowrap;
`

const generatePointKey = dimensions => {
  return Object.keys(dimensions).map(key => `${key}:${dimensions[key]}`)
}

export const generateRows = (
  columns: Array<ReportDataProperty>,
  measurements: Array<ReportDataEntry>,
  rows: Array<ReportDataProperty>,
  measurementConfig: Array<ReportDataMeasurement>
) => {
  const { columnCombinations, rowCombinations } = extractCombinations(
    measurements,
    columns,
    rows
  )

  const combinedDimensions = columns
    .map(c => ({ ...c, type: 'column' }))
    .concat(rows.map(r => ({ ...r, type: 'row' })))

  const measurementCatalogue = buildMeasurementCatalogue(
    measurements,
    combinedDimensions
  )

  const hasMeasurements = measurementConfig.length > 0

  // If there are columns use those, if not use measurements
  const noBodyColumns =
    columnCombinations.length === 0
      ? measurementConfig.length
      : columnCombinations.length
  // Columns with row type headers
  const noRowColumns = rows.length
  const totalColumns = noBodyColumns + noRowColumns

  // If there are columns use those, if not use measurements
  const noColumnHeaderRows =
    columns.length === 0 && hasMeasurements ? 1 : columns.length
  const noBodyRows = rowCombinations.length
  // If there are no columns then we use the row type labels as headers
  const rowHeaderRows = noColumnHeaderRows === 0 && rows.length > 0 ? 1 : 0
  const noHeaderRows = rowHeaderRows + noColumnHeaderRows
  const totalRows = noBodyRows + noHeaderRows

  const tableRows = []

  const columnWidths = []
  const rowHeights = []

  const rowLabelIndex = {}
  const columnLabelIndex = {}

  for (var rowIndex = 0; rowIndex < totalRows; rowIndex++) {
    const rowColumns = []

    rowLabelIndex[rowIndex] = {}

    for (var columnIndex = 0; columnIndex < totalColumns; columnIndex++) {
      let columnData = {
        cellStyle: {},
        labels: [],
      }

      if (columnIndex >= noRowColumns) {
        columnData.cellStyle.alignItems = 'flex-end'
      }

      if (rowIndex < noHeaderRows) {
        const columnType = columns[rowIndex]

        let bucketName, label, labelRender
        // There are more than 3 column header rows, so the ones before the last should be empty
        if (columnIndex < noRowColumns && rowIndex < noHeaderRows - 1) {
          label = ''
          // These are the last column header rows before the body so they will contain the row type labels
        } else if (columnIndex < noRowColumns) {
          const rowType = rows[columnIndex]

          label = rowType.label
          labelRender = 'header'
          // This is when there are no columns but there are measurements
        } else if (columns.length === 0 && measurementConfig.length > 0) {
          const bodyColumnIndex = columnIndex - noRowColumns
          const measurement = measurementConfig[bodyColumnIndex]
          bucketName = measurement.bucket_name
          label = measurement.label
          labelRender = 'header'
          // These are normal column headers
        } else {
          const bodyColumnIndex = columnIndex - noRowColumns
          label = columnCombinations[bodyColumnIndex][columnType.label]

          if (!columnLabelIndex[columnIndex]) {
            columnLabelIndex[columnIndex] = {}
          }

          columnLabelIndex[columnIndex][columnType.label] = label

          if (label === '' || label === null) {
            labelRender = 'no_value'
            label = `No ${columnType.label}`
          }
        }

        columnData.labels = [
          {
            bucketName: bucketName,
            labelRender: labelRender,
            value: label,
          },
        ]
      } else {
        const bodyRowIndex = rowIndex - noHeaderRows

        // Row column
        if (columnIndex < noRowColumns) {
          const rowType = rows[columnIndex]
          let label = rowCombinations[bodyRowIndex][rowType.label]

          rowLabelIndex[rowIndex][rowType.label] = label

          let labelRender
          if (label === '' || label === null) {
            label = `No ${rowType.label}`
            labelRender = 'no_value'
          }

          columnData.labels = [
            {
              labelRender: labelRender,
              value: label,
            },
          ]
        } else {
          const columnLabels = columnLabelIndex[columnIndex]
          const rowLabels = rowLabelIndex[rowIndex]

          columnData.labels = []

          const point = getPointFromCatalogue(
            measurementCatalogue,
            combinedDimensions,
            columnLabels,
            rowLabels
          )

          let measurements
          if (point && point.measures) {
            measurements = point.measures
          }

          if (measurements) {
            const bodyColumnIndex = columnIndex - noRowColumns

            let useMeasurements: Array<ReportDataEntry>
            // When there are now columns we use measurements as columns. However, then we only want to
            // display one measurement per column (since there is a column per measurement)
            if (columns.length === 0) {
              const measureConfig = measurementConfig[bodyColumnIndex]
              useMeasurements = measurements.filter(m => {
                return (
                  m.bucket_id === measureConfig.bucket_id &&
                  m.id === measureConfig.id
                )
              })
            } else {
              useMeasurements = measurements
            }

            columnData.labels = useMeasurements.map(m => {
              return {
                labelOptions: m.options,
                labelRender: m.render,
                renderOptions: m.render_options,
                value: m.value,
              }
            })
          }
        }
      }

      /*
      let width = calculateCellWidth(columnData.labels)
      if (width < 60) {
        width = 60
      } else if (width > 160) {
        width = 160
      }*/
      let height = calculateCellHeight(columnData.labels)

      //const currentWidth = columnWidths[columnIndex]
      const currentHeight = rowHeights[rowIndex]

      /*if (width > currentWidth || !currentWidth) {
        columnWidths[columnIndex] = width
      }*/
      if (height > currentHeight || !currentHeight) {
        rowHeights[rowIndex] = height
      }

      rowColumns.push(columnData)
    }

    tableRows.push(rowColumns)
  }

  // We want all the "body" columns to be of equal width. So here we find the widest one,
  // and replace all the column widths with that one. Though with a maximum of 150.
  const bodyColumnWidths = columnWidths.slice(noRowColumns)
  let maxBodyColumnWidth = bodyColumnWidths.reduce(
    (carry, width) => Math.max(carry, width),
    0
  )
  if (maxBodyColumnWidth > MAX_BODY_COLUMN_WIDTH) {
    maxBodyColumnWidth = MAX_BODY_COLUMN_WIDTH
  }

  for (var i = noRowColumns; i < totalColumns; i++) {
    columnWidths[i] = maxBodyColumnWidth
  }

  let error = null /*
  if (totalColumns > 2000) {
    error = 'Too many columns'
  } else if (totalRows > 2000) {
    error = 'Too many rows'
  }*/

  return {
    columnWidths: columnWidths,
    error: error,
    noRowColumns: noRowColumns,
    noColumnHeaderRows: noColumnHeaderRows,
    noHeaderRows: noHeaderRows,
    rowHeights: rowHeights,
    tableRows: tableRows,
    totalColumns: totalColumns,
    totalRows: totalRows,
  }
}
