import React, { Component } from 'react'
import { connect } from 'react-redux'
import sortBy from 'lodash/sortBy'
import Select from 'react-select'

import { fetchUsersIfNeeded } from '../actions'

type Props = {
  filterInactive?: boolean,
  user_type?: string,
}

class UserSelector extends Component {
  static defaultProps = {
    filterInactive: true,
    placeholder: 'Select agent...',
    simpleValue: true,
    onlyOptions: undefined,
    valueKey: 'id',
  }

  componentDidMount() {
    this.props.dispatch(fetchUsersIfNeeded())
  }

  render() {
    const { placeholder, value, isLoading, users, valueKey, ...rest } =
      this.props

    return (
      <Select
        placeholder={placeholder}
        {...rest}
        value={value}
        valueKey={valueKey}
        labelKey="label"
        options={users}
        onBlur={() => {}}
        isLoading={isLoading}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let users = sortBy(
    filterUsersByTypeAndActiveStatus(
      state.users,
      ownProps.user_type,
      ownProps.value,
      ownProps.filterInactive
    ),
    'firstname'
  )

  if (ownProps.excludeOptions) {
    users = users.filter(user => !ownProps.excludeOptions.includes(user.id))
  }
  if (ownProps.onlyOptions) {
    users = users.filter(user =>
      ownProps.onlyOptions.includes(user[ownProps.valueKey || 'id'])
    )
  }

  return {
    users: users.map(u => ({
      ...u,
      label: `${u.firstname} ${u.lastname !== null ? u.lastname : ''} (${
        u.email
      })`,
    })),
    isLoading: state.users.isFetching,
  }
}

export default connect(mapStateToProps)(UserSelector)

export const filterUsersByTypeAndActiveStatus = (
  users,
  user_type = null,
  originalUserId = null,
  filterInactive = true
) => {
  const filtered = Object.keys(users.items).filter(key => {
    // Don't mutate the original user object
    const user = Object.assign({}, users.items[key])

    const userTypeMatches = !user_type || user.user_type === user_type
    const statusMatches =
      !filterInactive || user.active || user.id === originalUserId

    // make sure we still show deactivated user when its ID is selected as agent
    return userTypeMatches && statusMatches
  })

  return filtered.map(key => users.items[key])
}
