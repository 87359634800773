/* @flow */

import { CALL_API } from '../../../infrastructure/middleware/api'
import isString from 'lodash/isString'

import type { Id } from '../../types'
import type { ThunkAction } from '../../types/redux'

export const CHANGE_BRAND_CONTEXT = 'CHANGE_BRAND_CONTEXT'
export const FETCH_BRANDS_REQUEST = 'FETCH_BRANDS_REQUEST'
export const FETCH_BRANDS_SUCCESS = 'FETCH_BRANDS_SUCCESS'
export const FETCH_BRANDS_FAILURE = 'FETCH_BRANDS_FAILURE'
export const FETCH_SINGLE_BRAND_REQUEST = 'FETCH_SINGLE_BRAND_REQUEST'
export const FETCH_SINGLE_BRAND_SUCCESS = 'FETCH_SINGLE_BRAND_SUCCESS'
export const FETCH_SINGLE_BRAND_FAILURE = 'FETCH_SINGLE_BRAND_FAILURE'
export const GET_AVAILABLE_BRANDS_REQUEST = 'GET_AVAILABLE_BRANDS_REQUEST'
export const GET_AVAILABLE_BRANDS_SUCCESS = 'GET_AVAILABLE_BRANDS_SUCCESS'
export const GET_AVAILABLE_BRANDS_FAILURE = 'GET_AVAILABLE_BRANDS_FAILURE'
export const GET_AVAILABLE_RETAILERS_REQUEST = 'GET_AVAILABLE_RETAILERS_REQUEST'
export const GET_AVAILABLE_RETAILERS_SUCCESS = 'GET_AVAILABLE_RETAILERS_SUCCESS'
export const GET_AVAILABLE_RETAILERS_FAILURE = 'GET_AVAILABLE_RETAILERS_FAILURE'
export const SET_BRANDS = 'SET_BRANDS'

/**
 * Action to be dispatched when brand context needs change
 * brandContext is global in application state
 * @param brandId
 */
export const changeBrandContext = (brandId: Id | null) => {
  if (isString(brandId)) {
    const parsed = parseInt(brandId)

    if (isNaN(parsed)) {
      throw new Error(`changeBrandContext: ${brandId} is not a valid number`)
    }

    brandId = parsed
  }

  return {
    type: CHANGE_BRAND_CONTEXT,
    brandId,
  }
}

export const fetchBrands = () => {
  return {
    [CALL_API]: {
      endpoint: '/brands',
      method: 'GET',
      types: [FETCH_BRANDS_REQUEST, FETCH_BRANDS_SUCCESS, FETCH_BRANDS_FAILURE],
    },
  }
}

export const IS_FETCHING_BRANDS = 'IS_FETCHING_BRANDS'

/**
 * StockLabel runs fetchBrandsIfNeeded on componentDidMount. However, some places (like inventory tables)
 * we render hundreds of these. And the componentDidMount runs before the async fetchBrands has set isFetching = true.
 * This means hundreds of /brands request happen at the same time. By using a sync action we can counter this.
 */
export const isFetchingBrands = (status: boolean) => ({
  type: IS_FETCHING_BRANDS,
  status,
})

export const fetchBrandsIfNeeded = (): ThunkAction => {
  return (dispatch, getState) => {
    const { brands } = getState()

    if (shouldFetchBrands(brands)) {
      dispatch(isFetchingBrands(true))
      return dispatch(fetchBrands())
    }

    return null
  }
}

export const fetchSingleBrandIfNoAlreadyFetching = brandId => {
  return (dispatch, getState) => {
    const { brands } = getState()

    const isFullFetching = brands.isFetching

    if (isFullFetching) {
      return
    }

    const isFetching = brands.singleBrandFetchStatus[brandId] === true

    if (isFetching) {
      return
    }

    return dispatch(fetchSingleBrand(brandId))
  }
}

export const fetchSingleBrand = brandId => {
  return {
    [CALL_API]: {
      endpoint: '/brands',
      query: {
        filter_brand_id: brandId,
      },
      method: 'GET',
      types: [
        { type: FETCH_SINGLE_BRAND_REQUEST, meta: { brandId } },
        { type: FETCH_SINGLE_BRAND_SUCCESS, meta: { brandId } },
        { type: FETCH_SINGLE_BRAND_FAILURE, meta: { brandId } },
      ],
    },
  }
}

/**
 * When doing anonymous settings we still have components that depend on the redux brands.
 * Therefore, while phasing out redux we will mitigate this by setting the brands directly
 * in the redux store
 */
export const setBrands = brands => ({
  type: SET_BRANDS,
  brands,
})

const shouldFetchBrands = brands => {
  if (!brands.hasDoneFullFetch && !brands.isFetching) {
    return true
  }

  return false
}

export const getAvailableBrands = () => {
  return {
    [CALL_API]: {
      endpoint: '/brands/available-brands',
      method: 'GET',
      types: [
        GET_AVAILABLE_BRANDS_REQUEST,
        GET_AVAILABLE_BRANDS_SUCCESS,
        GET_AVAILABLE_BRANDS_FAILURE,
      ],
    },
  }
}

export const getAvailableRetailers = () => {
  return {
    [CALL_API]: {
      endpoint: '/brands/available-retailers',
      method: 'GET',
      types: [
        GET_AVAILABLE_RETAILERS_REQUEST,
        GET_AVAILABLE_RETAILERS_SUCCESS,
        GET_AVAILABLE_RETAILERS_FAILURE,
      ],
    },
  }
}
