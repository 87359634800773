/* @flow */

import * as React from 'react'
import styled from 'styled-components'

import FormatCurrency from '../../../../infrastructure/components/FormatCurrency'

const SalesGoalsTable = ({
  collapsedGroups,
  dashboard,
  settings,
  showComp,
  toggleGroup,
}) => {
  return (
    <StyledTable dashboard={dashboard}>
      <thead>
        <tr>
          <th />
          <th className="number-header">Goal</th>
          <th className="number-header">Reached</th>
          <th className="number-header">Goal {settings.currency}</th>
          <th className="number-header">Reached {settings.currency}</th>
          <th className="percentage-header">Goal %</th>
          <th />
          {showComp && (
            <>
              <th className="number-header">Comp. {settings.currency}</th>
              <th className="percentage-header">
                Comp. {dashboard.comp_type === 'percentage' ? '%' : 'IDX'}
              </th>
            </>
          )}
          <th />
        </tr>
      </thead>
      <tbody>
        <tr key="grand-total" className="total-row">
          <td>Total</td>
          <td className="number-cell" />
          <td className="number-cell" />
          <td className="number-cell">
            <FormatCurrency currency={settings.currency}>
              {dashboard.goal_base_currency}
            </FormatCurrency>
          </td>
          <td className="number-cell">
            <FormatCurrency currency={settings.currency}>
              {dashboard.reached_base_currency}
            </FormatCurrency>
          </td>
          <td className="percentage-cell">
            <FormatPercentage
              totalPrice={dashboard.reached_base_currency}
              goal={dashboard.goal_base_currency}
            />
          </td>
          <td className="progressbar-cell">
            <PercentageProgressBar
              totalPrice={dashboard.reached_base_currency}
              goal={dashboard.goal_base_currency}
            />
          </td>
          {showComp && (
            <>
              <td className="number-cell">
                <FormatCurrency currency={settings.currency}>
                  {dashboard.comp_reached_base_currency}
                </FormatCurrency>
              </td>
              <td className="percentage-cell">
                <CompPercentage type={dashboard.comp_type} data={dashboard} />
              </td>
            </>
          )}
          <td />
        </tr>

        {dashboard.track !== 'total' &&
          dashboard.groups.reduce((carry, group, groupIndex) => {
            const collapsed = collapsedGroups.includes(groupIndex)

            if (dashboard.group_type !== 'none') {
              carry.push(
                <GroupRow
                  baseCurrency={settings.currency}
                  collapsed={collapsed}
                  groupIndex={groupIndex}
                  group={group}
                  toggle={toggleGroup}
                  dashboard={dashboard}
                  showComp={showComp}
                />
              )
            }

            for (let [rowIndex, row] of group.rows.entries()) {
              carry.push(
                <TrackeeRow
                  baseCurrency={settings.currency}
                  collapsed={collapsed}
                  dashboard={dashboard}
                  groupIndex={groupIndex}
                  rowIndex={rowIndex}
                  row={row}
                  showComp={showComp}
                />
              )
            }

            return carry
          }, [])}
      </tbody>
    </StyledTable>
  )
}

export default SalesGoalsTable

const GroupRow = ({
  baseCurrency,
  collapsed,
  groupIndex,
  group,
  toggle,
  dashboard,
  showComp,
}) => {
  return (
    <tr key={groupIndex} className="group-row">
      <td>
        <GroupCollapseExpandButton onClick={() => toggle(groupIndex)}>
          {collapsed ? (
            <span className="fa fa-chevron-right" />
          ) : (
            <span className="fa fa-chevron-down" />
          )}
        </GroupCollapseExpandButton>
        {group.name}
      </td>
      <td className="number-cell">
        {group.goal !== null && group.goal > 0 && (
          <FormatCurrency currency={group.group_currency}>
            {group.goal}
          </FormatCurrency>
        )}
      </td>
      <td className="number-cell">
        {group.reached !== null && group.goal > 0 && (
          <FormatCurrency currency={group.group_currency}>
            {group.reached}
          </FormatCurrency>
        )}
      </td>
      <td className="number-cell">
        <FormatGoal goal={group.goal_base_currency} currency={baseCurrency} />
      </td>
      <td className="number-cell">
        <FormatCurrency currency={baseCurrency}>
          {group.reached_base_currency}
        </FormatCurrency>
      </td>
      <td className="percentage-cell">
        <FormatPercentage
          totalPrice={group.reached_base_currency}
          goal={group.goal_base_currency}
        />
      </td>
      <td className="progressbar-cell">
        <PercentageProgressBar
          totalPrice={group.reached_base_currency}
          goal={group.goal_base_currency}
        />
      </td>
      {showComp && (
        <>
          <td className="number-cell">
            <FormatCurrency currency={baseCurrency}>
              {group.comp_reached_base_currency}
            </FormatCurrency>
          </td>
          <td className="percentage-cell">
            <CompPercentage type={dashboard.comp_type} data={group} />
          </td>
        </>
      )}
      <td />
    </tr>
  )
}

const TrackeeRow = ({
  baseCurrency,
  collapsed,
  dashboard,
  groupIndex,
  onClick,
  row,
  rowIndex,
  showComp,
}) => {
  if (collapsed) {
    return null
  }

  return (
    <tr key={groupIndex + '-' + rowIndex} className="trackee-row">
      <td className="name-cell">{row.trackee}</td>
      {row.error !== null && (
        <>
          <td />
          <td />
          <td className="number-cell">
            <ErrorLabel>{row.error}</ErrorLabel>
          </td>
          <td />
          <td />
          <td />
          <td />
        </>
      )}
      {row.error === null && (
        <>
          <td className="number-cell">
            {row.goal !== null && row.goal > 0 && (
              <FormatCurrency currency={row.trackee_currency}>
                {row.goal}
              </FormatCurrency>
            )}
          </td>
          <td className="number-cell">
            {row.reached !== null && row.goal > 0 && (
              <FormatCurrency currency={row.trackee_currency}>
                {row.reached}
              </FormatCurrency>
            )}
          </td>
          <td className="number-cell">
            <FormatGoal goal={row.goal_base_currency} currency={baseCurrency} />
          </td>
          <td className="number-cell">
            <FormatCurrency currency={baseCurrency}>
              {row.reached_base_currency}
            </FormatCurrency>
          </td>
          <td className="percentage-cell">
            {row.goal_base_currency > 0 && (
              <FormatPercentage
                totalPrice={row.reached_base_currency}
                goal={row.goal_base_currency}
              />
            )}
          </td>
          <td className="progressbar-cell">
            {row.goal_base_currency > 0 && (
              <PercentageProgressBar
                totalPrice={row.reached_base_currency}
                goal={row.goal_base_currency}
              />
            )}
          </td>
          {showComp && (
            <>
              <td className="number-cell">
                <FormatCurrency currency={baseCurrency}>
                  {row.comp_reached_base_currency}
                </FormatCurrency>
              </td>
              <td className="percentage-cell">
                <CompPercentage type={dashboard.comp_type} data={row} />
              </td>
            </>
          )}
          <td className="actions-cell">
            {/*<button type="button" className="btn btn-white" onClick={() => onClick(row)}>Show orders</button>*/}
          </td>
        </>
      )}
    </tr>
  )
}

const StyledTable = styled.table`
  width: 100%;

  tr {
    border-bottom: 1px solid #eaeaea;
  }

  thead {
    th {
      padding: 10px 15px;
      text-transform: uppercase;
      color: #505458;
      font-size: 14px;
    }

    .number-header {
      text-align: right;
    }

    .percentage-header {
      text-align: right;
      padding-right: 0;
    }
  }

  tbody {
    font-size: 12px;
    color: #505458;

    td {
      height: 60px;
      padding: 10px 15px;
    }

    .number-cell {
      text-align: right;
    }

    .percentage-cell {
      text-align: right;
      padding-right: 0;
    }

    .progressbar-cell {
      width: 15%;
    }

    .actions-cell {
      text-align: right;
    }

    .total-row {
      background-color: #f9fafc;
    }

    .group-row {
      background-color: #f9fafc;
    }

    .trackee-row .name-cell {
      padding-left: ${props =>
        props.dashboard.group_type !== 'none' ? '65px' : '40px'};
    }
  }
`

const GroupCollapseExpandButton = styled.button`
  margin: 0;
  padding: 0;
  width: 25px;
  border: none;
  background: none;
  text-align: left;
  color: #b4b9bf;

  &:focus {
    outline: none;
  }
`

const FormatGoal = ({ currency, goal }) => {
  if (goal === 0) {
    return <ErrorLabel>No goal set</ErrorLabel>
  }

  return <FormatCurrency currency={currency}>{goal}</FormatCurrency>
}

const ErrorLabel = styled.span`
  color: #ccd1d6;
`

const FormatPercentage = ({ totalPrice, goal }) => {
  if (goal === 0) {
    return null
  }

  const percentage = Math.round((totalPrice / goal) * 100)

  return (
    <FormatPercentageLabel percentage={percentage}>
      {percentage}%
    </FormatPercentageLabel>
  )
}

const CompPercentage = ({ data, type }) => {
  const value = type === 'percentage' ? data.comp_percentage : data.comp_index

  if (_.isNull(value)) {
    return null
  }

  return (
    <FormatPercentageLabel percentage={value}>
      {Math.round(value)}
      {type === 'percentage' ? '%' : null}
    </FormatPercentageLabel>
  )
}

const FormatPercentageLabel = styled.span`
  color: ${props => percentageToColor(props.percentage)};
  font-weight: bold;
`

export const PercentageProgressBar = ({ totalPrice, goal }) => {
  if (goal === 0) {
    return null
  }

  const percentage = Math.round((totalPrice / goal) * 100)

  return (
    <PercentageProgressBarGoal>
      <PercentageProgressBarPrice percentage={percentage} />
    </PercentageProgressBarGoal>
  )
}

const PercentageProgressBarGoal = styled.div`
  height: 16px;
  border-radius: 5px;
  background-color: #e4eaeb;
`

const PercentageProgressBarPrice = styled.div`
  width: ${props => Math.min(props.percentage, 100)}%;
  height: 16px;
  border-radius: 5px;
  background-color: ${props => percentageToColor(props.percentage)};
`

const percentageToColor = percentage => {
  if (percentage >= 75) {
    return '#17AC25'
  } else if (percentage >= 25) {
    return '#F6C923'
  } else {
    return '#F25300'
  }
}
