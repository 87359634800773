/* @flow */

import React from 'react'

import NavigationRightSide from './NavigationRightSide'

const AgencyNavigationBar = props => {
  return (
    <div className="navbar navbar-default navbar-fixed-top">
      <ul className="nav navbar-nav main-nav">
        <li>
          <a href="/">
            <span
              className="lnr lnr-home"
              style={{ paddingRight: 0 }}
              tabIndex="-1"
            />
          </a>
        </li>
      </ul>
      <NavigationRightSide type="main" {...props} />
    </div>
  )
}

export default AgencyNavigationBar
