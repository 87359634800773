import { isRSAA, CALL_API, getJSON, ApiError } from 'redux-api-middleware'
import {
  isPlainObject,
  isFunction,
  isArray,
  isObject,
  isNull,
  isUndefined,
} from 'lodash'
import querystring from 'qs'
import { setAccessToken } from '../actions/session'
import { TrackError } from '../exceptions/raven'
import msg from '../modules/msg'
import { CALL_API_BATCH, getBatchError, isBatchRSAA } from './batch'
import { showMessage } from '../actions/toast'

export {
  ApiError,
  CALL_API,
  CALL_API_BATCH,
  isRSAA,
  isBatchRSAA,
  getBatchError,
}

const apiBase = process.env.API_BASE_URL

export const CLEAR_REFRESH_TOKEN_PROMISE = 'CLEAR_REFRESH_TOKEN_PROMISE'
export const SAVE_REFRESH_TOKEN_PROMISE = 'SAVE_REFRESH_TOKEN_PROMISE'

let refreshTriesSinceLastSuccess = 0

export default function createApiMiddleware({
  logoutActionCreator,
  refreshActionCreator,
}) {
  return store => next => action => {
    if (!isRSAA(action)) {
      return next(action)
    }

    let {
      endpoint,
      body,
      query,
      headers = {},
      showToastOnValidationError,
      attempt = 1,
      ...rest
    } = action[CALL_API]

    endpoint = apiBase + endpoint

    headers['Content-Type'] = 'application/json'
    headers['X-TraedeAppIdentifier'] =
      'TraedeBrowserClient/' + process.env.VERSION

    if (attempt > 3) {
      throw new Error('Too many attempts')
    }

    const { token } = store.getState().session.accessToken
    if (!isNull(token)) {
      headers.Authorization = `Bearer ${token}`
    }

    let finalOptions = {
      endpoint,
      headers,
      credentials: 'same-origin',
    }

    if (body) {
      finalOptions.body = JSON.stringify(body)
    }

    if (!query) {
      query = {}
    }

    if (rest.method == 'GET') {
      query.rec_v4_redux = '1'
    }

    if (query) {
      finalOptions.endpoint += `?${querystring.stringify(query)}`
    }

    const finalAction = Object.assign({}, action, {
      [CALL_API]: Object.assign({}, rest, finalOptions),
    })

    if (!isArray(action[CALL_API].types)) {
      throw new Error('API action does not contain types.')
    }

    let type = action[CALL_API].types[0]
    if (isObject(type)) {
      type = type.type
    }
    // By creating the error object here we preserve the stack for when reporting to Sentry
    const ErrorObject = new Error(`API Error: ${type}`)

    return next(finalAction)
      .then(response => {
        if (response.error) {
          const refreshEndpoint = refreshActionCreator()[CALL_API].endpoint

          const meta = response.meta
          if (
            meta &&
            meta.apiErrorCallback &&
            meta.apiErrorCallback.code ===
              response.payload.response.errors[0].code
          ) {
            meta.apiErrorCallback.callback()

            return response
          } else if (response.payload.status === 422) {
            store.dispatch(
              showMessage('info', response.payload.response.errors)
            )

            return response
          }

          // The API call returned unauthorized user (access token is expired)
          if (
            response.payload.status === 401 &&
            // The refresh endpoint might return 401, so we skip the check here
            // otherwise we get stuck in an infinite loop
            action[CALL_API].endpoint !== refreshEndpoint &&
            // We should not run the refresh flow when no token was given to begin with
            // (for instance Forgot Password, Login etc.)
            token
          ) {
            // We check if there is already dispatched a call to refresh the token,
            // if so we can simply
            let refreshPromise = store.getState().session.refreshTokenPromise
            if (!refreshPromise && refreshTriesSinceLastSuccess < 5) {
              refreshTriesSinceLastSuccess++

              refreshPromise = requestNewAccessToken(
                store,
                next,
                refreshActionCreator
              )

              // We save it in the store so subsequent actions that are fired before the refresh attempt
              // has finished can be queued
              next({
                type: SAVE_REFRESH_TOKEN_PROMISE,
                promise: refreshPromise,
              })
            }

            // When the refresh attempt is done, we fire all the actions that have been queued until
            // its completion. If, the refresh promise was unsuccessful we logout the user.
            return refreshPromise.then(response => {
              if (!response.error) {
                const newAction = { ...action }

                if (newAction[CALL_API]) {
                  newAction[CALL_API] = {
                    ...newAction[CALL_API],
                    attempt: attempt + 1,
                  }
                }

                return store.dispatch(newAction)
              }

              return (
                store
                  .dispatch(logoutActionCreator())
                  // Ensure subscribers do not get an empty response, e.g. T512
                  .then(response => ({
                    error: true,
                    status:
                      response && response.payload
                        ? response.payload.status
                        : null,
                  }))
              )
            })
          }

          return Promise.reject(response)
        }

        refreshTriesSinceLastSuccess = 0

        return response
      })
      .catch(response => {
        const { body, endpoint, method, query } = finalAction[CALL_API]

        let api_log
        if (response.payload) {
          if (response.payload.response) {
            api_log = response.payload.response.log_id
          }

          if (response.payload.status === 500) {
            TrackError(ErrorObject, {
              request: {
                body,
                endpoint,
                method,
                query,
              },
              api_log,
            })
          }
        }

        return response
      })
  }
}

export const requestNewAccessToken = (store, next, actionCreator) => {
  return (
    store
      .dispatch(actionCreator())
      // Refresh was successful
      .then(response => {
        next({
          type: CLEAR_REFRESH_TOKEN_PROMISE,
        })

        if (response.error) {
          return Promise.reject(response)
        }

        next(
          setAccessToken(
            response.payload.accessToken,
            response.payload.accessTokenExpiration
          )
        )

        return {
          error: false,
        }
      })
      // Refresh was not successful
      .catch(response => {
        next({
          type: CLEAR_REFRESH_TOKEN_PROMISE,
        })

        return {
          error: true,
          status: response && response.payload ? response.payload.status : null,
        }
      })
  )
}

export const addErrorCallback = (action, code, callback) => {
  const types = [...action[CALL_API].types]

  types[2] = { type: types[2], meta: { apiErrorCallback: { code, callback } } }

  return {
    [CALL_API]: {
      ...action[CALL_API],
      types: types,
    },
  }
}
