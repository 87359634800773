/* @flow */

import React from 'react'
import styled from 'styled-components'

import { renderCustomField } from '../custom-fields/shared'

import type {
  ColumnOption,
  ConnectedBrand,
  ColumnOptions,
  CustomField,
  DataTableOptions,
  DataTableTab,
  Filter,
  Meta,
  OptionalColumnConfiguration,
} from '../types'
import { renderDate } from '../shared'

export const createEmptyFilter = attribute => {
  let initialValues: Filter = { key: attribute.property }
  switch (attribute.type) {
    case 'array':
    case 'checkbox':
      initialValues.operator = 'in'
      initialValues.value = []
      break
    case 'bt':
      initialValues.value = []
      break
    case 'string':
      initialValues.operator = 'ct'
      //ref: https://app.shortcut.com/traede/story/41011/filters-when-trying-to-filter-on-ean-with-no-value-it-doesn-t-work
      initialValues.value = ''
      break
    default:
      initialValues.operator = 'eq'
      break
  }

  return initialValues
}

export const createTableOptionsFromDataTableTab = (tab: DataTableTab) => {
  return {
    filters: tab.filters,
    page: 1,
    ...(tab.sort.length > 0
      ? {
          sort: tab.sort[0].key,
          sortDirection: tab.sort[0].direction,
        }
      : {}),
  }
}

export const createDataTableColumnsFromCustomFields = (
  customFields: Array<CustomField>
): Array<ColumnOption> =>
  customFields.map(customField => {
    return {
      label: customField.label,
      render: (object: { meta: Meta }) =>
        object.meta[customField.internal_name],
      sortable: true,
      sortProperty: `meta.${customField.internal_name}`,
      value: `meta.${customField.internal_name}`,
    }
  })

export const dataTableOptionsIsEqual = (
  optionsOne: DataTableOptions,
  optionsTwo: DataTableOptions
) => {
  let isEqual = true
  const keys = Object.keys(optionsOne)
  for (var key of keys) {
    // we do not wish to refetch when columns changes
    if (key === 'columns') {
      continue
    }

    if (optionsOne[key] !== optionsTwo[key]) {
      isEqual = false
      break
    }
  }

  return isEqual
}

export const createFilterGroupsFromDataTableFilters = (
  filters: Array<Filter>,
  fixedFilters?: Array<Filter> = []
) => {
  if (filters.length == 0 && fixedFilters.length == 0) {
    return []
  }

  const simpleFilters = []
  const mappedFilterGroups = []
  const combinedFilters = [...fixedFilters, ...filters]

  for (let filter of combinedFilters) {
    if (filter.type !== 'advanced') {
      simpleFilters.push(filter)
      continue
    }

    for (let filterGroup of filter.filter_groups) {
      mappedFilterGroups.push(filterGroup)
    }
  }

  if (simpleFilters.length > 0) {
    mappedFilterGroups.unshift({
      or: false,
      filters: simpleFilters,
    })
  }

  return mappedFilterGroups
}

export const createRequestOptionsFromDataTableOptions = (
  options: DataTableOptions,
  fixedFilters?: Array<Filter> = [],
  pagination: boolean = true
) => {
  return {
    filter_groups: createFilterGroupsFromDataTableFilters(
      options.filters,
      fixedFilters
    ),
    // allow for showing all
    limit: pagination ? options.perPage : undefined,
    page: pagination ? options.page - 1 : undefined,
    sort: options.sort
      ? [{ key: options.sort, direction: options.sortDirection }]
      : [],
  }
}

export const createColumnOptions = (
  entity: ConnectedBrand,
  columnOptions: ColumnOptions,
  optionalColumnOptions: {
    apps: Array<OptionalColumnConfiguration>,
  },
  customFieldType?: string,
  customFieldSortOrder?: number
) => {
  const allCustomFields =
    entity.entity_type === 'brand' ? entity.custom_fields : []
  const apps = entity.entity_type === 'brand' ? entity.apps : []

  const customFields = customFieldType
    ? allCustomFields.filter(
        c => c.visible === true && c.group_type === customFieldType
      )
    : []

  const entityApps = apps.map(a => a.app)
  const entityCustomFields = customFields.map(c => c.internal_name)

  const newOptions = [...columnOptions]
  const defaultColumns = columnOptions
    .reduce((carry, group) => {
      return carry.concat(group.columns)
    }, [])
    .filter(column => {
      return (
        column.default ||
        // If we cannot toggle a column we need to always show it
        column.toggleable === false
      )
    })
    .map(column => column.value)

  const checkMap = {
    apps: entityApps,
    custom_fields: entityCustomFields,
  }

  const availableColumnOptions = {
    ...optionalColumnOptions,
    custom_fields: customFields.map((c, i) => {
      const firstGroup = columnOptions[0]

      return {
        check: c.internal_name,
        columnOption: {
          label: c.label,
          render: obj => {
            const meta = obj && obj.meta ? obj.meta : {}
            const value = meta[c.internal_name]

            return renderCustomField(c, value)
          },
          sortable: true,
          sortProperty: `meta.${c.internal_name}`,
          sortOrder: customFieldSortOrder + i,
          value: `meta.${c.internal_name}`,
        },
        default: c.datatable_default,
        group: firstGroup.label,
      }
    }),
  }

  for (var key in checkMap) {
    for (var column of availableColumnOptions[key]) {
      if (checkMap[key].indexOf(column.check) !== -1) {
        const groupIndex = columnOptions.findIndex(
          c => c.label === column.group
        )

        if (groupIndex !== -1) {
          newOptions[groupIndex].columns = [...newOptions[groupIndex].columns]

          newOptions[groupIndex].columns.push(column.columnOption)

          if (column.default) {
            defaultColumns.push(column.columnOption.value)
          }
        }
      }
    }
  }

  return {
    columnOptions: newOptions,
    defaultColumns: defaultColumns,
  }
}

export const COLUMN_VISIBILITY = {
  BRAND: 1,
  RETAILER: 2,
  BOTH: 3,
}

export const FakeLinkStyle = styled.span`
  color: #0d638f;
  cursor: pointer;
  text-decoration: underline;
`
