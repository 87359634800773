/* @flow */

import * as React from 'react'

function doRectsCollide(a, b) {
  return !(
    a.top + a.height < b.top ||
    a.top > b.top + b.height ||
    a.left + a.width < b.left ||
    a.left > b.left + b.width
  )
}

export const useGridEditor = (value, onChange, deleteWithFlag = false) => {
  /**
   * react-grid only takes the layout properties
   */
  const { layout, itemData } = React.useMemo(() => {
    const itemData = {}
    const layout = []

    for (let item of value) {
      const key = item.i

      layout.push(item.layout)

      itemData[key] = item
    }

    return {
      layout,
      itemData,
    }
  }, [value])

  const onChangeLayout = React.useCallback(
    layout => {
      const newValue = {
        ...itemData,
      }

      for (let item of layout) {
        newValue[item.i] = {
          ...newValue[item.i],
          layout: item,
        }
      }

      onChange(Object.values(newValue))
    },
    [onChange, itemData]
  )

  const onAddComponent = React.useCallback(
    type => {
      const layoutCopy = [...value]

      let newKey = null
      let currentKey = 0
      while (true) {
        const potentialKey = `item${currentKey}`

        const existingItem = layoutCopy.find(item => item.i === potentialKey)

        if (!existingItem) {
          newKey = potentialKey
          break
        }

        currentKey++
      }

      const newElement = {
        data: {
          body: 'TEXT',
        },
        i: newKey,
        layout: {
          i: newKey,
          x: 0,
          y: 0,
          w: 6,
          h: 6,
          isBounded: true, // todo: make configurable
        },
        type,
      }

      let potentialX = 0
      let potentialY = 0
      while (true) {
        let foundBlocking = false
        for (let data of layoutCopy) {
          const { x, y, w, h } = data.layout

          const rectA = {
            top: y,
            height: h,
            left: x,
            width: w,
          }

          const rectB = {
            top: potentialY,
            height: 6,
            left: potentialX,
            width: 6,
          }

          if (doRectsCollide(rectA, rectB)) {
            foundBlocking = true
            break
          }
        }

        if (!foundBlocking) {
          break
        } else {
          const nextEndX = potentialX + 6
          const isAtEndX = nextEndX > 32

          const nextEndY = potentialY + 6
          const isAtEndY = nextEndY > 32

          if (isAtEndY) {
            potentialX = 0
            potentialY = 0

            break
          }

          // we can safely increase X
          if (!isAtEndX) {
            potentialX++

            // we can safely increase y
          } else {
            potentialX = 0
            potentialY++
          }
        }
      }

      newElement.layout.x = potentialX
      newElement.layout.y = potentialY

      layoutCopy.push(newElement)

      onChange(layoutCopy)
    },
    [value, onChange]
  )

  const onRemoveItem = React.useCallback(
    item => {
      if (deleteWithFlag) {
        const updated = [...value].map(i => {
          const copy = { ...i }

          if (i.i == item.i) {
            copy.delete = true
          }

          return copy
        })

        onChange(updated)
      } else {
        onChange([...value].filter(i => i.i !== item.i))
      }
    },
    [deleteWithFlag, onChange, value]
  )

  const onUpdateItem = React.useCallback(
    (item, formValues, { setSubmitting }) => {
      const updatedItem = formValues.item

      const index = value.findIndex(i => {
        return i.i === item.i
      })

      if (index !== -1) {
        const copy = [...value]

        copy[index] = updatedItem

        onChange(copy)
      }

      setSubmitting(false)
    },
    [onChange, value]
  )

  return {
    layout,
    itemData,
    onChangeLayout,
    onAddComponent,
    onRemoveItem,
    onUpdateItem,
  }
}
