/* @flow */

import {
  getAddresses,
  getForwarders,
  getModeOfTransportations,
  getProductAccountingGroups,
  getEntityUsers,
  getUsers,
  createAddress,
  getMyEntityUser,
  getCustomFonts,
  getMySubUsers,
} from './api'
import { quickCreateHook } from '../hooks'

import type { Address } from '../types'

const {
  hook: useAddresses,
  hookCached: useCachedAddresses,
  clearCache: clearAddressCache,
} = quickCreateHook<Array<Address>>(getAddresses, 'addresses', [])

export { useAddresses, useCachedAddresses, clearAddressCache }

const {
  hook: useMyEntityUser,
  hookCached: useCachedMyEntityUser,
  clearCache: clearMyEntityUserCache,
} = quickCreateHook<Array<Address>>(getMyEntityUser, 'entity_user')

export { useMyEntityUser, useCachedMyEntityUser, clearMyEntityUserCache }

const {
  hook: useMySubUsers,
  hookCached: useCachedMySubUsers,
  clearCache: clearMySubUsersCache,
} = quickCreateHook<Array<Address>>(getMySubUsers, 'sub_users', [])

export { useMySubUsers, useCachedMySubUsers, clearMySubUsersCache }

const {
  hook: useUsers,
  hookCached: useCachedUsers,
  clearCache: clearUsersCache,
} = quickCreateHook<Array<Address>>(getUsers, 'users', [])

export { useUsers, useCachedUsers, clearUsersCache }

const {
  hook: useEntityUsers,
  hookCached: useCachedEntityUsers,
  clearCache: clearEntityUsersCache,
} = quickCreateHook<Array<Address>>(getEntityUsers, 'entity_users', [])

export { useEntityUsers, useCachedEntityUsers, clearEntityUsersCache }

const {
  hook: useForwarders,
  hookCached: useCachedForwarders,
  clearCache: clearForwardersCache,
} = quickCreateHook(getForwarders, 'forwarders', [], {
  v2: true,
})

export { useForwarders, useCachedForwarders, clearForwardersCache }

const {
  hook: useModeOfTransportations,
  hookCached: useCachedModeOfTransportations,
  clearCache: clearModeOfTransportationsCache,
} = quickCreateHook(getModeOfTransportations, 'mode_of_transportations', [], {
  v2: true,
})

export {
  useModeOfTransportations,
  useCachedModeOfTransportations,
  clearModeOfTransportationsCache,
}

const {
  hook: useCustomFonts,
  hookCached: useCachedCustomFonts,
  clearCache: clearCustomFontsCache,
} = quickCreateHook(getCustomFonts, 'fonts', [], {
  v2: true,
})

export { useCustomFonts, useCachedCustomFonts, clearCustomFontsCache }

const {
  hook: useProductAccountingGroups,
  hookCached: useCachedProductAccountingGroups,
  clearCache: clearProductAccountingGroupsCache,
} = quickCreateHook(
  getProductAccountingGroups,
  'product_accounting_groups',
  [],
  {
    v2: true,
  }
)

export {
  useProductAccountingGroups,
  useCachedProductAccountingGroups,
  clearProductAccountingGroupsCache,
}
