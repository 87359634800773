/* @flow */

import * as React from 'react'
import memo from 'memoize-one'
import sortBy from 'lodash/sortBy'

import { HelpIndicator } from '../shared'

import type { InvoiceStatus, OrderStatus } from '../types'

export const INVOICE_STATUS_DRAFT = 'open'
export const INVOICE_STATUS_BOOKED = 'booked'

export const ORDER_STATUS_DRAFT = 'draft'
export const ORDER_STATUS_SELECTION = 'selection'
export const ORDER_STATUS_REQUEST = 'request'
export const ORDER_STATUS_OFFER = 'offer'
export const ORDER_STATUS_ERROR = 'error'
export const ORDER_STATUS_APPROVED = 'approved'

export const isOrderApproved = (status: OrderStatus) =>
  [ORDER_STATUS_APPROVED].includes(status)

export const isOrderNonApproved = (status: OrderStatus) =>
  [
    ORDER_STATUS_DRAFT,
    ORDER_STATUS_ERROR,
    ORDER_STATUS_OFFER,
    ORDER_STATUS_REQUEST,
    ORDER_STATUS_SELECTION,
  ].includes(status)

export const isOrderOpen = (order: { cancelled: boolean, archived: boolean }) =>
  !order.cancelled && !order.archived

export const isOrderSavedAsSelection = (status: OrderStatus) =>
  [ORDER_STATUS_SELECTION].includes(status)

export const isCreditNoteBooked = (status: InvoiceStatus) =>
  [INVOICE_STATUS_BOOKED].includes(status)

export const isInvoiceBooked = (status: InvoiceStatus) =>
  [INVOICE_STATUS_BOOKED].includes(status)

export const mapDropsAndDeliveriesIntoDeliveryLines = memo(drops => {
  const deliveryLines = []

  for (let drop of drops) {
    let deliveries = [
      {
        id: null,
        label: null,
        delivery_date_confirmed: drop.delivery_date_confirmed,
        delivery_date_from: drop.delivery_date_from,
        delivery_date_to: drop.delivery_date_to,
        delivery_date_type: drop.delivery_date_type,
        drop_sort: drop.sort,
        drop_delivery_sort: null,
      },
    ]

    if (drop.deliveries.length > 0) {
      deliveries = drop.deliveries
    }

    for (let delivery of deliveries) {
      let label = [delivery.name ? `${drop.name}:` : drop.name]
      if (delivery.name) {
        label.push(delivery.name)
      }

      const deliveryLineData = {
        drop_key: `${drop.id}-${delivery.id || ''}`,
        label: label.join(' '),
        drop,
        delivery_date_confirmed: delivery.delivery_date_confirmed,
        delivery_date_from: delivery.delivery_date_from,
        delivery_date_to: delivery.delivery_date_to,
        delivery_date_type: delivery.delivery_date_type,
        drop_sort: drop.sort,
        drop_delivery_sort: delivery.sort,
      }

      deliveryLines.push(deliveryLineData)
    }
  }

  const sortedDeliveryLines = sortBy(deliveryLines, [
    'drop_sort',
    'drop_delivery_sort',
  ])

  return sortedDeliveryLines
})

export const OrderEstimatedArrivalHelpIndicator = () => {
  return (
    <HelpIndicator id="order-expected-arrival-tip" size="sm">
      When is the products expected to arrive at their destination
    </HelpIndicator>
  )
}

export const OrderEstimatedDepartureHelpIndicator = () => {
  return (
    <HelpIndicator id="order-expected-departure-tip" size="sm">
      When is the products expected to be sent from your warehouse
    </HelpIndicator>
  )
}
