/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { List } from 'immutable'
import uniq from 'lodash/uniq'
import memoize from 'memoize-one'
import sortBy from 'lodash/sortBy'

import {
  ColorPill,
  ColorText,
} from '../../../infrastructure/components/ColorList'
import { getAttributes } from '../api'

import type { Attribute as AttributeType, Product } from '../../types'

export type SelectedAttributes = { [string]: Array<string> }

type Props = {
  onChange: (products: List<Product>, selected: SelectedAttributes) => void,
  products: List<Product>,
  selected: SelectedAttributes,
}

type State = {
  attributes: Array<AttributeType>,
  isFetching: boolean,
}

class AttributeList extends Component<Props, State> {
  state = {
    attributes: [],
    isFetching: false,
  }

  componentDidMount() {
    this.setState({
      isFetching: true,
    })

    getAttributes().then(response => {
      if (!response.error) {
        this.setState({
          attributes: response.payload.attributes,
          isFetching: false,
        })
      }
    })
  }

  clearAll = () => {
    this.onChange({})
  }

  onChange = (selected: SelectedAttributes) => {
    const products = this.props.products
      .map(product => {
        const variants = product.variants.filter(variant => {
          let differs = false
          for (var attribute in selected) {
            if (
              selected[attribute].length > 0 &&
              selected[attribute].indexOf(variant.attributes[attribute]) === -1
            ) {
              differs = true
            }
          }
          return !differs
        })

        return { ...product, variants }
      })
      .filter(product => {
        return product.variants.length > 0
      })

    this.props.onChange(products, selected)
  }

  toggleAttribute = (attribute: string, value: string) => {
    const selected = { ...this.props.selected }

    if (!selected[attribute]) {
      selected[attribute] = []
    }

    const index = selected[attribute].indexOf(value)
    if (index === -1) {
      selected[attribute].push(value)
    } else {
      selected[attribute] = [...selected[attribute]]
      selected[attribute].splice(index, 1)
    }

    this.onChange(selected)
  }

  toggleAllAttributeValues = (attribute: string) => {
    const selected = { ...this.props.selected }

    if (selected[attribute] && selected[attribute].length > 0) {
      delete selected[attribute]
    } else {
      selected[attribute] = this.state.attributes.find(
        a => a.name === attribute
      ).values
    }

    this.onChange(selected)
  }

  render() {
    const { products, selected } = this.props
    const { attributes, isFetching } = this.state

    if (!attributes) {
      return null
    }

    if (isFetching) {
      return <div>Loading...</div>
    }

    const attributesOrder = generateAttributesOrder(attributes)
    const extractedAttributes = extractAttributes(products, attributesOrder)
    const attributesSortedByValues = sortBy(
      Object.keys(extractedAttributes),
      attribute => {
        return extractedAttributes[attribute].length
      }
    )

    return (
      <div>
        {attributesSortedByValues.map((attribute, i) => {
          const hasSelectedValues = !(
            selected[attribute] && selected[attribute].length > 0
          )

          const attributeLabelText = (
            <span>
              {attribute}:{' '}
              {!hasSelectedValues && (
                <AttributeDeselectAllValuesButton
                  type="button"
                  className="close"
                >
                  <span>×</span>
                </AttributeDeselectAllValuesButton>
              )}
            </span>
          )

          return (
            <AttributeListContainer>
              <Attribute
                onClick={() => this.toggleAllAttributeValues(attribute)}
              >
                <ColorPill
                  index={i}
                  text={attributeLabelText}
                  transparent={hasSelectedValues}
                />
              </Attribute>
              {extractedAttributes[attribute].map(value => {
                const isSelected =
                  selected[attribute] &&
                  selected[attribute].indexOf(value) !== -1
                const transparent = !isSelected

                return (
                  <Attribute
                    onClick={() => this.toggleAttribute(attribute, value)}
                  >
                    <ColorPill
                      index={i}
                      text={value}
                      transparent={transparent}
                    />
                  </Attribute>
                )
              })}
            </AttributeListContainer>
          )
        })}
        {Object.getOwnPropertyNames(selected).length > 0 && (
          <button className="btn btn-xs btn-white" onClick={this.clearAll}>
            Clear all filters
          </button>
        )}
      </div>
    )
  }
}

export default AttributeList

const generateAttributesOrder = memoize(attributes =>
  attributes.reduce((carry, attribute) => {
    if (!carry[attribute.name]) {
      carry[attribute.name] = {}
    }

    return attribute.values.reduce((innerCarry, value, i) => {
      innerCarry[attribute.name][value] = i
      return innerCarry
    }, carry)
  }, {})
)

// AttributesOrder does not change, hence we do not need custom resolver function
const extractAttributes = memoize((products, attributesOrder) =>
  products.reduce((carry, product) => {
    const attributes = product.variants.reduce((carry, variant) => {
      return Object.keys(variant.attributes).reduce((carry, attribute) => {
        if (!carry[attribute]) {
          carry[attribute] = []
        }

        if (carry[attribute].indexOf(variant.attributes[attribute]) === -1) {
          carry[attribute].push(variant.attributes[attribute])
        }

        return carry
      }, carry)
    }, carry)

    Object.keys(attributes).forEach(attribute => {
      const values = attributes[attribute]

      values.sort((a, b) => {
        let aIndex = 0,
          bIndex = 0
        if (attributesOrder[attribute]) {
          aIndex = attributesOrder[attribute][a]
          bIndex = attributesOrder[attribute][b]

          if (aIndex === undefined) {
            aIndex = values.length + 1
          }
          if (bIndex === undefined) {
            bIndex = values.length + 1
          }
        }

        if (aIndex < bIndex) {
          return -1
        } else if (aIndex > bIndex) {
          return 1
        }

        return 0
      })
    })

    return attributes
  }, {})
)

const AttributeListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  margin-left: -15px;
`

const Attribute = styled.div`
  cursor: pointer;
  margin-left: 15px;
`

const AttributeDeselectAllValuesButton = styled.button`
  margin-left: 5px;
`
