/* @flow */

import * as React from 'react'
import styled from 'styled-components'
import { PureComponent } from 'react'
import { FastField, Field, type FieldProps } from 'formik'
import { FormControl, InputGroup } from 'react-bootstrap'
import { ErrorLabel } from '../Forms'

import { HelpIndicator } from '../../../app/shared'

type Props = {
  array?: boolean,
  checkValue?: mixed,
  label: React.Node,
  id: string,
  name: string,
  marginBottom?: boolean,
  tip?: string,
  type?: 'success',
  value?: mixed,
  disabled?: boolean,
}

const CheckboxInput = (props: Props) => {
  const { formikFastField, ...restProps } = props

  // FastField re-renders only when the value of field changes and on few other occassions
  // limiting the number of re-renders. Useful when form starts to slow down because of lot
  // of fields, but can block other needed renders, be careful when using it.
  const FieldComponent = formikFastField === true ? FastField : Field

  return <FieldComponent component={renderCheckboxInput} {...props} />
}

export default CheckboxInput

export const renderCheckboxInput = ({
  array = false,
  checkValue = true,
  marginBottom = true,
  labelStyle = {},
  field: { onBlur, name, onChange, value },
  form: { touched, errors, setFieldValue, values },
  ...props
}: FieldProps & Props) => {
  return (
    <div>
      <div className={`checkbox check-${props.type || 'success'}`}>
        <input
          id={props.id}
          onChange={() =>
            onChangeHandler(name, value, props.value, setFieldValue, array)
          }
          checked={isChecked(value, props.value, array, checkValue)}
          type="checkbox"
          {...props}
        />
        <label
          htmlFor={props.id}
          style={
            !marginBottom ? { marginBottom: 0, ...labelStyle } : labelStyle
          }
        >
          {props.label}
        </label>

        {props.tip && (
          <HelpIndicator id={props.id} size="sm">
            {props.tip}
          </HelpIndicator>
        )}
      </div>

      {touched[name] && errors[name] && <ErrorLabel>{errors[name]}</ErrorLabel>}
    </div>
  )
}

const onChangeHandler = (name, fieldValue, value, setFieldValue, isArray) => {
  if (!isArray) {
    // by specifically checking for true we also enable turning null values into true
    return setFieldValue(name, fieldValue === true ? false : true)
  } else {
    const newValue = [...fieldValue]

    const index = newValue.indexOf(value)
    if (index !== -1) {
      newValue.splice(index, 1)
      return setFieldValue(name, newValue)
    } else {
      newValue.push(value)
      return setFieldValue(name, newValue)
    }
  }
}

const isChecked = (fieldValue, value, isArray, checkValue) => {
  if (!isArray) {
    return fieldValue === checkValue
  } else {
    return fieldValue.indexOf(value) !== -1
  }
}
