import {
  CALL_API,
  CALL_API_BATCH,
} from '../../../infrastructure/middleware/api'

export const SETUP_NEXT_STEP = 'SETUP_NEXT_STEP'
export const SETUP_PREVIOUS_STEP = 'SETUP_PREVIOUS_STEP'
export const CHECK_URL_AVAILABILITY_REQUEST = 'CHECK_URL_AVAILABILITY_REQUEST'
export const CHECK_URL_AVAILABILITY_SUCCESS = 'CHECK_URL_AVAILABILITY_SUCCESS'
export const CHECK_URL_AVAILABILITY_FAILURE = 'CHECK_URL_AVAILABILITY_FAILURE'
export const FINISH_SETUP_REQUEST = 'FINISH_SETUP_REQUEST'
export const FINISH_SETUP_SUCCESS = 'FINISH_SETUP_SUCCESS'
export const FINISH_SETUP_FAILURE = 'FINISH_SETUP_FAILURE'

export const finishSetup = (
  { id: entityId, entity_type },
  userId,
  { company_name, url, firstname, password, password_confirmation, currency }
) => {
  const actions = [
    {
      key: 'entity',
      action: `entities/${entityId}/contact-information`,
      method: 'PUT',
      data: {
        entity: {
          name: company_name,
          url,
        },
      },
    },
    {
      action: `users/${userId}`,
      method: 'PUT',
      key: 'user',
      data: {
        user: {
          firstname,
          password,
          password_confirmation,
        },
      },
    },
    {
      action: `users/${userId}/setup-callback`,
      method: 'POST',
      key: 'callback',
    },
  ]

  if (entity_type === 'brand') {
    actions.push({
      key: 'settings',
      action: `entities/${entityId}/settings`,
      method: 'PUT',
      data: {
        data: {
          currency,
        }
      },
    })
  }

  return {
    [CALL_API_BATCH]: {
      types: [FINISH_SETUP_REQUEST, FINISH_SETUP_SUCCESS, FINISH_SETUP_FAILURE],
      actions,
    },
  }
}

export const setupNextStep = () => ({
  type: SETUP_NEXT_STEP,
})

export const setupPreviousStep = () => ({
  type: SETUP_PREVIOUS_STEP,
})

export const checkUrlAvailability = url => ({
  [CALL_API]: {
    endpoint: '/entities/url-availability',
    method: 'POST',
    body: {
      url: url,
    },
    types: [
      CHECK_URL_AVAILABILITY_REQUEST,
      CHECK_URL_AVAILABILITY_SUCCESS,
      CHECK_URL_AVAILABILITY_FAILURE,
    ],
  },
})
