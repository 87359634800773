/* @flow */

import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import type { RowConfig } from '../../types'

type Options = {
  label?: string,
}

const colli: (options: Options) => RowConfig = ({
  label = 'Available Colli',
}) => ({
  key: 'colli',
  label,
  render: ({ product }) => {
    return product.colli ?_.join(product.colli, ', ') : ''
  },
  type: 'product',
})

export default colli
