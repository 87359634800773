/* @flow */

import type { ConnectedBrand, Id } from '../../types'

import {
  FETCH_BRANDS_REQUEST,
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_FAILURE,
  FETCH_SINGLE_BRAND_REQUEST,
  FETCH_SINGLE_BRAND_SUCCESS,
  FETCH_SINGLE_BRAND_FAILURE,
  IS_FETCHING_BRANDS,
  SET_BRANDS,
} from '../actions/index'
import _ from 'underscore'

type BrandsState = {
  isFetching: boolean,
  items: { [Id]: ConnectedBrand },
  lastFetched: null | number,
}

type FetchBrandsSuccessAction = {
  type: 'FETCH_BRANDS_SUCCESS',
  payload: Array<ConnectedBrand>,
}
type FetchBrandsFailureAction = { type: 'FETCH_BRANDS_FAILURE' }
type FetchBrandsRequestAction = { type: 'FETCH_BRANDS_REQUEST' }
type IsFetchingBrandsAction = { type: 'IS_FETCHING_BRANDS', status: boolean }

type Action =
  | FetchBrandsSuccessAction
  | FetchBrandsFailureAction
  | FetchBrandsRequestAction
  | IsFetchingBrandsAction

export const initialState: BrandsState = {
  isFetching: false,
  items: {},
  lastFetched: null,
  hasDoneFullFetch: false,

  singleBrandFetchStatus: {},
}

/**
 * Reducer to update the collections state tree
 * @param  {object} state  =             initialState
 * @param  {object} action
 * @return {object}
 */
export default function brands(
  state: BrandsState = initialState,
  action: Action
) {
  switch (action.type) {
    case FETCH_BRANDS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        items: _.indexBy(action.payload, 'id'),
        lastFetched: new Date().getTime(),
        hasDoneFullFetch: true,
      })
    case FETCH_BRANDS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        lastFetched: new Date().getTime(),
      })
    case FETCH_BRANDS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case IS_FETCHING_BRANDS:
      return Object.assign({}, state, {
        isFetching: action.status,
      })
    case SET_BRANDS:
      return Object.assign({}, state, {
        isFetching: false,
        items: _.indexBy(action.brands, 'id'),
        lastFetched: new Date().getTime(),
        hasDoneFullFetch: true,
      })

    // FETCH SINGLE BRAND
    case FETCH_SINGLE_BRAND_REQUEST:
      return Object.assign({}, state, {
        singleBrandFetchStatus: {
          ...state.singleBrandFetchStatus,
          [action.meta.brandId]: true,
        },
      })
    case FETCH_SINGLE_BRAND_SUCCESS:
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, _.indexBy(action.payload, 'id')),
        singleBrandFetchStatus: {
          ...state.singleBrandFetchStatus,
          [action.meta.brandId]: false,
        },
      })
    case FETCH_SINGLE_BRAND_FAILURE:
      return Object.assign({}, state, {
        singleBrandFetchStatus: {
          ...state.singleBrandFetchStatus,
          [action.meta.brandId]: false,
        },
      })

    default:
      return state
  }
}
