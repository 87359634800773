import { HIDE_TOAST_MESSAGE, SHOW_TOAST_MESSAGE } from '../actions/toast'
import { ENTER_WEBSHOP, LEAVE_WEBSHOP } from '../../app/shop/actions/index'

const initialState = {
  className: 'toast-backend',
  showMessage: false,
  messages: [],
  type: 'success',
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ENTER_WEBSHOP:
      return Object.assign({}, state, {
        className: 'toast-webshop',
      })
      break
    case HIDE_TOAST_MESSAGE:
      return Object.assign({}, initialState)
      break
    case LEAVE_WEBSHOP:
      return Object.assign({}, state, {
        className: initialState.className,
      })
      break
    case SHOW_TOAST_MESSAGE:
      return Object.assign({}, state, {
        showMessage: true,
        messages: !Array.isArray(action.messages)
          ? [action.messages]
          : action.messages,
        type: action.messageType,
      })
      break
    default:
      return state
  }
}
