/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import type { RowConfig } from '../../types'

type Options = {
  label?: string,
}

const collection: (options: Options) => RowConfig = ({
  label = 'Collection',
}) => ({
  key: 'collection',
  label,
  render: ({ product }) => {
    return product.collection ? product.collection.title : ''
  },
  type: 'product',
})

export default collection
