/* @flow */

import React from 'react'
import type { ValueType } from '../Column'
import styled from 'styled-components'

const NonEditableColumn = ({
  className = '',
  elementType = 'td',
  value,
  ...rest
}: {
  className?: string,
  elementType?: string,
  value: ValueType,
}) => {
  return React.createElement(
    containers[elementType],
    {
      className: `${className} non-editable-column`,
      ...rest,
    },
    value
  )
}

export default NonEditableColumn

const containers = {
  div: styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 7px !important;
  `,
  td: styled.td`
    padding: 0 7px !important;
  `,
}
