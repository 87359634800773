/* @flow */

import React, { useContext } from 'react'

import WebshopAnonymousSession from './WebshopAnonymousSession'
import WebshopSession from './WebshopSession'

import { SessionContext } from '../shared'

const WebshopSessionAuthenticationWrap = (props: Object) => {
  const { user } = useContext(SessionContext)

  return user.id ? (
    <WebshopSession {...props} />
  ) : (
    <WebshopAnonymousSession {...props} />
  )
}

export default WebshopSessionAuthenticationWrap
