/* @flow */

import React from 'react'
import styled from 'styled-components'

import type { RowConfig } from '../../types'

type Options = {
  label?: string,
}

const diff: (options: Options) => RowConfig = ({ label = 'Diff' }) => ({
  key: 'diff',
  label,
  render: ({ value }) => {
    if (!value) {
      return null
    }

    return <Diff>{value}</Diff>
  },
})

const Diff = styled.div`
  color: red;
`

export default diff
