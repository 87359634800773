/* @flow */

import * as React from 'react'
import styled from 'styled-components'
import {
  Link,
  type Location as LocationType,
  type RouterHistory,
} from 'react-router-dom'
import sortBy from 'lodash/sortBy'

import WebshopActionButton from '../WebshopActionButton'
import WebshopLinkButton from '../WebshopLinkButton'
import WebshopSessionManager from '../WebshopSessionManager'
import CloudinaryResource from '../../../../infrastructure/components/CloudinaryResource'
import FormatCurrency from '../../../../infrastructure/components/FormatCurrency'
import { useWebshopNavigationLinks, useWebshopPages } from '../../hooks'
import { SessionContext, Tooltip } from '../../../shared'
import { WebshopContext } from '../../shared'

import {
  HeaderBox,
  HeaderBoxBody,
  HeaderBoxIcon,
  HeaderBoxTitle,
  HeaderBoxContent,
  HeaderBoxAction,
} from '../shared'

import WebshopNavigation from '../Navigation/WebshopNavigation'
import WebshopCustomerSelectorModal from '../WebshopCustomerSelectorModal'

const WebshopHeader = ({
  history,
  brand,
  onSubmitSearch,
  setShowManualEditCustomerModal,
  setShowRetailerSelector,
  setShowSessionListModal,
}) => {
  const { entity } = React.useContext(SessionContext)
  const {
    availableBrand,
    currency,
    favorites,
    query,
    shopUrl,
    refreshWebshopTotals,
    setUseWebshopSessionId,
    totals,
    updateWebshopSession,
    webshopSession,
    webshopSettings,
  } = React.useContext(WebshopContext)

  const navigationFetchArgs = React.useMemo(() => {
    return [brand.id, webshopSession.id]
  }, [brand, webshopSession])

  const [
    navigationLinks,
    isFetchingNavigationLinks,
    { isInitialized: isNavigationLinksInitialized },
  ] = useWebshopNavigationLinks(navigationFetchArgs)

  const sortedNavigationLinks = React.useMemo(() => {
    return sortBy(navigationLinks, 'sort_order')
  }, [navigationLinks])

  if (!isNavigationLinksInitialized) {
    return null
  }

  return (
    <>
      <HeaderContainer fullWidth={webshopSettings.full_width}>
        <WebshopSessionManager
          availableBrand={availableBrand}
          entity={entity}
          setShowManualEditCustomerModal={setShowManualEditCustomerModal}
          setShowRetailerSelector={setShowRetailerSelector}
          setShowSessionListModal={setShowSessionListModal}
          updateWebshopSession={updateWebshopSession}
          webshopSession={webshopSession}
        />

        <HeaderLogo>
          <Link to={shopUrl}>
            <CloudinaryResource
              brandId={brand.id}
              id={brand.logo}
              presets="logo_webshop"
              fallback="logo_black"
            />
          </Link>
        </HeaderLogo>

        <HeaderBoxes>
          <FavoritesBox>
            <HeaderBoxContent>
              <HeaderBoxTitle right>
                <HeaderBoxIcon>
                  <span className="lnr lnr-heart" />
                </HeaderBoxIcon>
                <span>Favorites</span>
              </HeaderBoxTitle>

              <HeaderBoxBody>
                <div>{favorites.total} products</div>
                <div>selected</div>
              </HeaderBoxBody>
              <HeaderBoxAction>
                <WebshopLinkButton
                  to={`${shopUrl}/favorites`}
                  className="webshop-go-to-favorites-btn"
                  color="white"
                  size="small"
                >
                  Go to Favorites
                </WebshopLinkButton>
              </HeaderBoxAction>
            </HeaderBoxContent>
          </FavoritesBox>
          <CartBox>
            <HeaderBoxContent>
              <HeaderBoxTitle right>
                <HeaderBoxIcon>
                  <span className="lnr lnr-cart" />
                </HeaderBoxIcon>
                <span>Cart</span>
              </HeaderBoxTitle>

              <HeaderBoxBody>
                <div>{totals.quantity} pieces</div>
                <div>
                  <FormatCurrency currency={currency}>
                    {totals.price}
                  </FormatCurrency>
                </div>
              </HeaderBoxBody>
              <HeaderBoxAction>
                <WebshopLinkButton
                  to={`${shopUrl}/cart`}
                  className="webshop-go-to-cart-btn"
                  color="white"
                  size="small"
                >
                  Go to Cart
                </WebshopLinkButton>
              </HeaderBoxAction>
            </HeaderBoxContent>
          </CartBox>
        </HeaderBoxes>
      </HeaderContainer>

      <WebshopNavigation
        handleSearchSubmit={onSubmitSearch}
        navigationLinks={sortedNavigationLinks}
        searchQuery={query.q || ''}
        shopUrl={shopUrl}
        brand={brand}
      />
    </>
  )
}

// Need class for custom CSS hook
const HeaderContainer = styled.div.attrs({ className: 'webshop-header' })`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-top: 30px;
  ${({ fullWidth }) =>
    fullWidth === true &&
    `
    padding-left: 15px;
    padding-right: 15px;
  `}
  position: relative;
`

const HeaderBoxes = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  max-width: 230px;

  > * {
    flex: 1 0 0;
    text-align: right;
  }
`

const HeaderLogo = styled.div`
  flex: 1;
  left: 50%;
  margin-left: -125px;
  max-width: 250px;
  position: absolute;

  img {
    max-width: 100%;
  }
`

const FavoritesBox = styled(HeaderBox)``

const CartBox = styled(HeaderBox)`
  ${HeaderBoxBody} {
    white-space: nowrap;
  }
`

export default WebshopHeader
