import React from 'react'
import styled from 'styled-components'
import { createAction } from '../shared'

import { CheckboxInput } from '../../../../../infrastructure/components/Formik'

const RegulateInventoryAction = ({
  callback,
  extraPropsByProduct = {},
  product,
}) => {
  const productProps = extraPropsByProduct[product.id]
  const checked = productProps ? productProps.regulate_inventory : true

  return (
    <CheckboxLine>
      <input
        onChange={e => {
          callback(e.target.checked)
        }}
        type="checkbox"
        name="regulate_inventory"
        checked={checked ?? true}
      />
      <CheckboxLabel>Regulate Inventory</CheckboxLabel>
    </CheckboxLine>
  )
}

const createRegulateInventoryAction = () => {
  return createAction(
    (regulateInventory, { product, lines }, { setExtraPropsByProduct }) => {
      return setExtraPropsByProduct(product.id, {
        regulate_inventory: regulateInventory,
      })
    },
    RegulateInventoryAction,
    [],
    { size: 'xs' }
  )
}

export default createRegulateInventoryAction

const Button = styled.span`
  font-weight: bold;
`

const CheckboxLine = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const CheckboxLabel = styled.span`
  margin-left: 4px;
`
