/* @flow */

import { getSubbrands } from './api'
import { createApiHook, createDataCache } from '../hooks'

import type { Subbrand } from '../types'

const fetchSubbrandsForHook = (options?: Object) =>
  getSubbrands(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.subbrands,
      }
    } else {
      return response
    }
  })

export const useSubbrands = createApiHook<Array<Subbrand>>(
  fetchSubbrandsForHook,
  []
)

const {
  cache: { clearCache },
  hook: useCachedSubbrands,
} = createDataCache(useSubbrands)

export { clearCache, useCachedSubbrands }
