import * as React from 'react'
import { Modal } from 'react-bootstrap'

import ActionButton from '../../../../infrastructure/components/ActionButton'

const DeleteSessionModal = ({
  onHide,
  onSubmit,
  show,
}: {
  onHide: Function,
  onSubmit: Function,
  show: boolean,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete the order?</Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-white" onClick={onHide}>
          Cancel
        </button>
        <ActionButton iconClass="glyphicon glyphicon-ok" onClick={onSubmit}>
          Delete
        </ActionButton>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteSessionModal
