import {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
} from '../actions/index'
import _ from 'underscore'

const initialState = {
  isFetching: false,
  items: {},
}

export default function categories(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        items: _.indexBy(action.payload.categories, 'id'),
      })
      return state
    case FETCH_CATEGORIES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case FETCH_CATEGORIES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
      })
    case CREATE_CATEGORY_SUCCESS:
      if (action.payload.parent) {
        return Object.assign({}, state, {
          items: Object.assign({}, state.items, {
            [action.payload.parent]: Object.assign(
              {},
              state.items[action.payload.parent],
              {
                children: [
                  ...state.items[action.payload.parent].children,
                  action.payload,
                ],
              }
            ),
          }),
        })
      } else {
        return Object.assign({}, state, {
          items: Object.assign({}, state.items, {
            [action.payload.id]: action.payload,
          }),
        })
      }
    default:
      return state
  }
}
