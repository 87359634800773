/* @flow */

import type {
  Id,
  NavigationLink,
  WebshopPage,
  WebshopProductRequestOptions,
} from '../../types'
import {
  CALL_API,
  CALL_API_BATCH,
} from '../../../infrastructure/middleware/api'

export const GET_DISCOUNT_REQUEST = 'GET_DISCOUNT_REQUEST'
export const GET_DISCOUNT_SUCCESS = 'GET_DISCOUNT_SUCCESS'
export const GET_DISCOUNT_FAILURE = 'GET_DISCOUNT_FAILURE'

export const GET_WEBSHOP_PRODUCTS_ATTRIBUTES_REQUEST =
  'GET_WEBSHOP_PRODUCTS_ATTRIBUTES_REQUEST'
export const GET_WEBSHOP_PRODUCTS_ATTRIBUTES_SUCCESS =
  'GET_WEBSHOP_PRODUCTS_ATTRIBUTES_SUCCESS'
export const GET_WEBSHOP_PRODUCTS_ATTRIBUTES_FAILURE =
  'GET_WEBSHOP_PRODUCTS_ATTRIBUTES_FAILURE'
export const GET_WEBSHOP_PRODUCTS_REQUEST = 'GET_WEBSHOP_PRODUCTS_REQUEST'
export const GET_WEBSHOP_PRODUCTS_SUCCESS = 'GET_WEBSHOP_PRODUCTS_SUCCESS'
export const GET_WEBSHOP_PRODUCTS_FAILURE = 'GET_WEBSHOP_PRODUCTS_FAILURE'
export const GET_WEBSHOP_PRODUCT_REQUEST = 'GET_WEBSHOP_PRODUCT_REQUEST'
export const GET_WEBSHOP_PRODUCT_SUCCESS = 'GET_WEBSHOP_PRODUCT_SUCCESS'
export const GET_WEBSHOP_PRODUCT_FAILURE = 'GET_WEBSHOP_PRODUCT_FAILURE'

export const WEBSHOP_PARAMETERS_REQUEST = 'WEBSHOP_PARAMETERS_REQUEST'
export const WEBSHOP_PARAMETERS_SUCCESS = 'WEBSHOP_PARAMETERS_SUCCESS'
export const WEBSHOP_PARAMETERS_FAILURE = 'WEBSHOP_PARAMETERS_FAILURE'

export const WEBSHOP_SETTINGS_REQUEST = 'WEBSHOP_SETTINGS_REQUEST'
export const WEBSHOP_SETTINGS_SUCCESS = 'WEBSHOP_SETTINGS_SUCCESS'
export const WEBSHOP_SETTINGS_FAILURE = 'WEBSHOP_SETTINGS_FAILURE'

export const WEBSHOP_UPDATE_PAGES_REQUEST = 'WEBSHOP_UPDATE_PAGES_REQUEST'
export const WEBSHOP_UPDATE_PAGES_SUCCESS = 'WEBSHOP_UPDATE_PAGES_SUCCESS'
export const WEBSHOP_UPDATE_PAGES_FAILURE = 'WEBSHOP_UPDATE_PAGES_FAILURE'

export const WEBSHOP_NAVIGATION_REQUEST = 'WEBSHOP_NAVIGATION_REQUEST'
export const WEBSHOP_NAVIGATION_SUCCESS = 'WEBSHOP_NAVIGATION_SUCCESS'
export const WEBSHOP_NAVIGATION_FAILURE = 'WEBSHOP_NAVIGATION_FAILURE'

export const WEBSHOP_NAVIGATION_LINK_REQUEST = 'WEBSHOP_NAVIGATION_LINK_REQUEST'
export const WEBSHOP_NAVIGATION_LINK_SUCCESS = 'WEBSHOP_NAVIGATION_LINK_SUCCESS'
export const WEBSHOP_NAVIGATION_LINK_FAILURE = 'WEBSHOP_NAVIGATION_LINK_FAILURE'

export const GET_FAVORITES_REQUEST = 'GET_FAVORITES_REQUEST'
export const GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS'
export const GET_FAVORITES_FAILURE = 'GET_FAVORITES_FAILURE'

export const ADD_FAVORITE_REQUEST = 'ADD_FAVORITE_REQUEST'
export const ADD_FAVORITE_SUCCESS = 'ADD_FAVORITE_SUCCESS'
export const ADD_FAVORITE_FAILURE = 'ADD_FAVORITE_FAILURE'

export const DELETE_FAVORITE_REQUEST = 'DELETE_FAVORITE_REQUEST'
export const DELETE_FAVORITE_SUCCESS = 'DELETE_FAVORITE_SUCCESS'
export const DELETE_FAVORITE_FAILURE = 'DELETE_FAVORITE_FAILURE'

export const CLEAR_FAVORITES_REQUEST = 'CLEAR_FAVORITES_REQUEST'
export const CLEAR_FAVORITES_SUCCESS = 'CLEAR_FAVORITES_SUCCESS'
export const CLEAR_FAVORITES_FAILURE = 'CLEAR_FAVORITES_FAILURE'

export const WEBSHOP_TRACK_OPENED_REQUEST = 'WEBSHOP_TRACK_OPENED_REQUEST'
export const WEBSHOP_TRACK_OPENED_SUCCESS = 'WEBSHOP_TRACK_OPENED_SUCCESS'
export const WEBSHOP_TRACK_OPENED_FAILURE = 'WEBSHOP_TRACK_OPENED_FAILURE'

export const PUSH_RECENT_PRODUCT_REQUEST = 'PUSH_RECENT_PRODUCT_REQUEST'
export const PUSH_RECENT_PRODUCT_SUCCESS = 'PUSH_RECENT_PRODUCT_SUCCESS'
export const PUSH_RECENT_PRODUCT_FAILURE = 'PUSH_RECENT_PRODUCT_FAILURE'

export const GET_RECENT_PRODUCTS_REQUEST = 'GET_RECENT_PRODUCTS_REQUEST'
export const GET_RECENT_PRODUCTS_SUCCESS = 'GET_RECENT_PRODUCTS_SUCCESS'
export const GET_RECENT_PRODUCTS_FAILURE = 'GET_RECENT_PRODUCTS_FAILURE'

export const fetchWebshopParameters = (categoryId: Id) => ({
  [CALL_API_BATCH]: {
    types: [
      WEBSHOP_PARAMETERS_REQUEST,
      WEBSHOP_PARAMETERS_SUCCESS,
      WEBSHOP_PARAMETERS_FAILURE,
    ],
    actions: [
      {
        key: 'brands',
        action: '/categories/' + categoryId + '/subbrands',
      },
      {
        key: 'categories',
        action: '/categories/' + categoryId + '/children',
      },
      {
        key: 'collections',
        action: '/categories/' + categoryId + '/collections',
      },
      {
        key: 'delivery',
        action: '/products/category/' + categoryId + '/delivery-dates',
      },
    ],
  },
})

export const addFavorite = (brandId: Id, productId: Id, splitKey?: string) => ({
  [CALL_API]: {
    endpoint: `/webshop/${brandId}/favorites`,
    method: 'POST',
    body: { product_id: productId, split_key: splitKey },
    types: [ADD_FAVORITE_REQUEST, ADD_FAVORITE_SUCCESS, ADD_FAVORITE_FAILURE],
  },
})

export const deleteFavorite = (
  brandId: Id,
  productId: Id,
  splitKey?: string
) => ({
  [CALL_API]: {
    endpoint: `/webshop/${brandId}/favorites/${productId}`,
    method: 'DELETE',
    body: { split_key: splitKey },
    types: [
      DELETE_FAVORITE_REQUEST,
      DELETE_FAVORITE_SUCCESS,
      DELETE_FAVORITE_FAILURE,
    ],
  },
})

export const clearFavorites = (brandId: Id) => ({
  [CALL_API]: {
    endpoint: `/webshop/${brandId}/favorites`,
    method: 'DELETE',
    types: [
      CLEAR_FAVORITES_REQUEST,
      CLEAR_FAVORITES_SUCCESS,
      CLEAR_FAVORITES_FAILURE,
    ],
  },
})

export const updateWebshopPages = (brandId: Id, data: $Shape<WebshopPage>) => ({
  [CALL_API]: {
    endpoint: `/webshop/${brandId}/pages`,
    method: 'PUT',
    body: { data: data },
    types: [
      WEBSHOP_UPDATE_PAGES_REQUEST,
      WEBSHOP_UPDATE_PAGES_SUCCESS,
      WEBSHOP_UPDATE_PAGES_FAILURE,
    ],
  },
})

export const getWebshopNavigation = (brandId: Id, webshopSessionId: Id) => ({
  [CALL_API]: {
    endpoint: `/webshop/${brandId}/navigation`,
    method: 'GET',
    query: {
      webshop_session_id: webshopSessionId,
    },
    types: [
      WEBSHOP_NAVIGATION_REQUEST,
      WEBSHOP_NAVIGATION_SUCCESS,
      WEBSHOP_NAVIGATION_FAILURE,
    ],
  },
})

export const getWebshopNavigationLink = (
  brandId: Id,
  linkId: Id,
  webshopSessionId: Id
) => ({
  [CALL_API]: {
    endpoint: `/webshop/${brandId}/navigation/${linkId}`,
    method: 'GET',
    query: {
      webshop_session_id: webshopSessionId,
    },
    types: [
      WEBSHOP_NAVIGATION_LINK_REQUEST,
      WEBSHOP_NAVIGATION_LINK_SUCCESS,
      WEBSHOP_NAVIGATION_LINK_FAILURE,
    ],
  },
})

export const getWebshopSettings = (brandId: Id) => ({
  [CALL_API]: {
    endpoint: `/webshop/${brandId}/settings`,
    method: 'GET',
    types: [
      WEBSHOP_SETTINGS_REQUEST,
      WEBSHOP_SETTINGS_SUCCESS,
      WEBSHOP_SETTINGS_FAILURE,
    ],
  },
})

export const getProductsAttributes = (
  brandId: Id,
  options?: WebshopProductRequestOptions
) => ({
  [CALL_API]: {
    endpoint: `/webshop/${brandId}/attributes`,
    method: 'GET',
    query: options,
    types: [
      GET_WEBSHOP_PRODUCTS_ATTRIBUTES_REQUEST,
      GET_WEBSHOP_PRODUCTS_ATTRIBUTES_SUCCESS,
      GET_WEBSHOP_PRODUCTS_ATTRIBUTES_FAILURE,
    ],
  },
})

export const getProducts = (
  brandId: Id,
  options?: WebshopProductRequestOptions
) => ({
  [CALL_API]: {
    endpoint: `/webshop/${brandId}/search`,
    method: 'POST',
    query: options,
    types: [
      GET_WEBSHOP_PRODUCTS_REQUEST,
      GET_WEBSHOP_PRODUCTS_SUCCESS,
      GET_WEBSHOP_PRODUCTS_FAILURE,
    ],
  },
})

export const getProduct = (
  brandId: Id,
  productId: Id,
  options?: WebshopProductRequestOptions
) => ({
  [CALL_API]: {
    endpoint: `/webshop/${brandId}/products/${productId}`,
    method: 'GET',
    query: options,
    types: [
      GET_WEBSHOP_PRODUCT_REQUEST,
      GET_WEBSHOP_PRODUCT_SUCCESS,
      GET_WEBSHOP_PRODUCT_FAILURE,
    ],
  },
})

export const getDiscountPercentage = (brandId: Id) => ({
  [CALL_API]: {
    endpoint: `/brands/${brandId}/discount`,
    method: 'GET',
    types: [GET_DISCOUNT_REQUEST, GET_DISCOUNT_SUCCESS, GET_DISCOUNT_FAILURE],
  },
})

export const trackOpened = (brandId: Id) => ({
  [CALL_API]: {
    endpoint: `/webshop/${brandId}/track-opened`,
    method: 'POST',
    types: [
      WEBSHOP_TRACK_OPENED_REQUEST,
      WEBSHOP_TRACK_OPENED_SUCCESS,
      WEBSHOP_TRACK_OPENED_FAILURE,
    ],
  },
})

export const pushRecentProduct = (brandId: Id, productId: Id) => ({
  [CALL_API]: {
    endpoint: `/webshop/${brandId}/push-recent-product/${productId}`,
    method: 'POST',
    types: [
      PUSH_RECENT_PRODUCT_REQUEST,
      PUSH_RECENT_PRODUCT_SUCCESS,
      PUSH_RECENT_PRODUCT_FAILURE,
    ],
  },
})

export const getRecentProducts = (brandId: Id, productId: Id) => ({
  [CALL_API]: {
    endpoint: `/webshop/${brandId}/get-recent-products?productId=${productId}`,
    method: 'GET',
    types: [
      GET_RECENT_PRODUCTS_REQUEST,
      GET_RECENT_PRODUCTS_SUCCESS,
      GET_RECENT_PRODUCTS_FAILURE,
    ],
  },
})
