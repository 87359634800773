/* @flow */

import * as React from 'react'
import styled from 'styled-components'

import ActionButton, {
  ControlledActionButton,
} from '../../../infrastructure/components/ActionButton'
import { createWebshopButtonStyle, createStyledWebshopButton } from './shared'

import { WebshopContext } from '../shared'

const WebshopActionButton = props => {
  const { webshopDesignSettings } = React.useContext(WebshopContext)

  return (
    <ContextlessWebshopActionButton
      webshopDesignSettings={webshopDesignSettings}
      {...props}
    />
  )
}

export default WebshopActionButton

const StyledActionButton = createStyledWebshopButton(ActionButton)

// using in settings pages
export const ContextlessWebshopActionButton = ({
  color = 'white',
  webshopDesignSettings,
  ...props
}) => {
  const combinedStyle = React.useMemo(() => {
    return createWebshopButtonStyle(color, webshopDesignSettings)
  }, [color, webshopDesignSettings])

  return <StyledActionButton theme={combinedStyle} {...props} />
}

export const ControlledWebshopActionButton = ({
  color = 'white',
  ...props
}) => {
  const { webshopDesignSettings } = React.useContext(WebshopContext)

  const combinedStyle = React.useMemo(() => {
    return createWebshopButtonStyle(color, webshopDesignSettings)
  }, [color, webshopDesignSettings])

  return <StyledControlledActionButton theme={combinedStyle} {...props} />
}

const StyledControlledActionButton = createStyledWebshopButton(
  ControlledActionButton
)
