/* @flow */

import React, { Component, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import difference from 'lodash/difference'
import isArray from 'lodash/isArray'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import keyBy from 'lodash/keyBy'
import uniq from 'lodash/uniq'
import styled from 'styled-components'
import { List, Map } from 'immutable'
import mobile from '../../../../infrastructure/modules/mobile'
import memoize from 'memoize-one'

import FormatCurrency from '../../../../infrastructure/components/FormatCurrency'
import { StockList } from '../../../../infrastructure/components/Stock'
import { toQuantityTotal } from '../../../orders/components/shared'

import {
  ClickLabel,
  showColumn,
  ProductTableContainer,
  Column,
  Row,
  HeaderRow,
  createEmptyArray,
  createVariantPopover,
  defaultColumns,
  ProductTablePropertiesHeader,
  ImageColumn,
  ImageHeaderColumn,
  columnRenderers,
  renderColumn,
  ActionColumn,
  AttributeColumn,
  AttributeHeaderColumn,
  QuantityColumn,
  PriceColumn,
  ProductNameColumn,
  sortProducts,
  generateLineIndexFromVariants,
  getQuantityForVariant,
} from './shared'
import QuantityField from './QuantityField'
import { DiscountedPrice, RrpTip, WhsTip, getPrice } from '../Price'
import shouldUpdate from './utils/shouldUpdate'
import { getAttributes } from './actions'
import { Tooltip } from '../../../shared'

import type { ProductTableAction, RowSize } from './types'
import type { Currency, Quantities, Variant } from '../../../types'

const GROUPBY_DELIMITER = '#!@$%@nnbgf#'
export const HORIZONTAL_ATTRIBUTE_DEFAULT_VALUE = 'auto'

type Props = {
  canOverrideColliOnly: boolean,
  colliOnly?: boolean,
  currency: Currency,
  defaultAttributeLabel: string,
  editableQuantities?: boolean,
  extraPropsByProduct?: Object,
  onQuantityChange?: Function,
  preferColli: boolean,
  priceMap?: Object,
  productActions?: Array<ProductTableAction>,
  rowSize: RowSize,
  quantities: Quantities,
  variant: Variant,
}

type State = {
  horizontalColumn: null | string,
  quantities: Map,
  rows: Array<*>,
}

export default class MatrixProductTable extends Component<Props, State> {
  static defaultProps = {
    colliOnly: false,
    editableQuantities: false,
    onQuantityChange: () => {},
    productActions: [],
    quantities: Map(),
  }

  state = {
    horizontalColumn: null,
    quantities: Map(),
    rows: [],
  }

  componentDidMount() {
    this.updateProducts(this.props, false)
  }

  componentWillReceiveProps(nextProps: Props) {
    this.updateProducts(nextProps, true)
  }

  // When we load the attributes we do not wish to check for changes,
  // since we are making the initial render
  updateProducts(nextProps: Props, checkForChanges: boolean) {
    const {
      brand,
      currency,
      filterEmptyVariants,
      filterEmptyVariantsMap,
      priceMap,
      products,
      options: { columns, custom_order, sort },
      settings,
    } = nextProps
    let changes = {}

    const attributes = brand.attributes
    const attributesMapped = mapAttributes(attributes)

    if (
      !checkForChanges ||
      this.props.brand !== brand ||
      this.props.products !== products ||
      this.props.currency !== currency ||
      this.props.options.custom_order !== custom_order ||
      this.props.options.sort !== sort ||
      this.props.settings !== settings ||
      this.props.options.columns !== columns ||
      this.props.priceMap !== priceMap ||
      this.props.filterEmptyVariants !== filterEmptyVariants ||
      this.props.filterEmptyVariantsMap !== filterEmptyVariantsMap
    ) {
      changes = Object.assign(
        {},
        generateRows(
          products,
          currency,
          custom_order,
          sort,
          settings,
          attributesMapped,
          columns,
          priceMap,
          filterEmptyVariants,
          filterEmptyVariantsMap,
          nextProps.quantities
        )
      )
    }

    // When quantities are loaded
    if (
      !checkForChanges ||
      this.props.quantities !== nextProps.quantities ||
      changes.rows
    ) {
      changes.quantities = splitQuantitiesByVerticalAttributes(
        // Because we key quantities by rows we reset the current quantities
        // when we regenerate rows, since the row keys might change. We only
        // want to cache quantities when only quantities change.
        changes.rows ? Map() : this.state.quantities,
        nextProps.quantities,
        changes.rows ? changes.rows : this.state.rows
      )
    }

    if (Object.getOwnPropertyNames(changes).length > 0) {
      this.setState(changes)
    }
  }

  render() {
    const {
      attributesOrder,
      bordered,
      canOverrideColliOnly,
      compareQuantities,
      contextData,
      currency,
      customerColliOnly,
      defaultAttributeLabel,
      displayCompareQuantity,
      editableQuantities,
      enableColli,
      excludeColumns,
      extraPropsByProduct,
      instance,
      isBrandUser,
      lineIndex,
      markProducts,
      onQuantityChange,
      options,
      postQuantityColumns,
      preferColli,
      productActions,
      productActionsV2,
      quantityLabels,
      quantityStyle,
      regulateInventoryActions,
      renderers,
      rowSize,
      session,
      settings,
      toggleDiscountModal,
      toggleEmptyVariants,
      toggleProductModal,
      ...rest
    } = this.props

    const { horizontalColumn, quantities, rows } = this.state

    return (
      <ProductTableContainer bordered={bordered} {...rest}>
        <tbody>
          {rows.map((row, i) => {
            if (row.type === 'header') {
              return (
                <MatrixProductTablePropertiesHeader
                  contextData={contextData}
                  columns={options.columns}
                  currency={currency}
                  defaultAttributeLabel={defaultAttributeLabel}
                  excludeColumns={excludeColumns}
                  renderers={renderers}
                  attributeColumns={row.data.attributeColumns}
                  attributeColumnWidth={row.data.attributeColumnWidth}
                  emptyColumns={row.data.emptyColumns}
                  extraPropsByProduct={extraPropsByProduct}
                  key={`${row.data.key}_${row.data.headerKey}`}
                  lineIndex={lineIndex}
                  instance={instance}
                  product={row.data.product}
                  productActions={productActions}
                  productActionsV2={productActionsV2}
                  postQuantityColumns={postQuantityColumns}
                  regulateInventoryActions={regulateInventoryActions}
                  repeatRow={row.data.repeatRow}
                  rowSize={rowSize}
                  session={session}
                  settings={settings}
                  toggleEmptyVariants={toggleEmptyVariants}
                  toggleProductModal={toggleProductModal}
                  variants={row.data.variants}
                />
              )
            }
            if (row.type === 'row') {
              const colliOnly =
                !canOverrideColliOnly &&
                (customerColliOnly || row.data.product.order_colli_only)

              return (
                <MatrixProductTableVariantRow
                  attributeColumnWidth={row.data.attributeColumnWidth}
                  colliOnly={colliOnly}
                  columns={options.columns}
                  compareQuantities={compareQuantities}
                  contextData={contextData}
                  currency={currency}
                  displayCompareQuantity={displayCompareQuantity}
                  editableQuantities={editableQuantities}
                  emptyColumns={row.data.emptyColumns}
                  enableColli={enableColli}
                  excludeColumns={excludeColumns}
                  extraProps={
                    extraPropsByProduct
                      ? extraPropsByProduct[row.data.product.id]
                      : undefined
                  }
                  key={row.data.key}
                  markProducts={markProducts}
                  horizontalColumn={horizontalColumn}
                  horizontalColumnValues={row.data.horizontalColumnValues}
                  renderers={renderers}
                  rowSize={rowSize}
                  onQuantityChange={onQuantityChange}
                  preferColli={preferColli}
                  priceMap={row.data.priceMap}
                  productActions={productActions}
                  postQuantityColumns={postQuantityColumns}
                  quantityStyle={quantityStyle}
                  quantityLabels={quantityLabels}
                  session={session}
                  settings={settings}
                  toggleDiscountModal={toggleDiscountModal}
                  toggleProductModal={toggleProductModal}
                  verticalAttributes={row.data.verticalAttributes}
                  // Likely to update
                  lineIndex={lineIndex}
                  product={row.data.product}
                  quantities={quantities.get(i)}
                  variants={row.data.variants}
                />
              )
            }
            if (row.type === 'spacer') {
              return <SpacerRow columnCount={row.data.column_count} />
            }
          })}
        </tbody>
      </ProductTableContainer>
    )
  }
}

MatrixProductTable.PropTypes = {
  options: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.string),
    sort: PropTypes.string,
  }),
  quantities: PropTypes.instanceOf(Map),
}

MatrixProductTable.defaultProps = {
  options: {
    columns: defaultColumns,
    sort: 'compact',
  },
  quantities: Map(),
}

class MatrixProductTablePropertiesHeader extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shouldUpdate(this.props, nextProps)
  }

  render() {
    const {
      attributeColumns,
      attributeColumnWidth,
      currency,
      columns,
      defaultAttributeLabel,
      emptyColumns,
      extraPropsByProduct,
      excludeColumns,
      lineIndex,
      instance,
      product,
      productActions,
      postQuantityColumns,
      renderers,
      repeatRow,
      rowSize,
      settings,
      toggleProductModal,
      variants,
    } = this.props

    const lineIndexForVariants = generateLineIndexFromVariants(
      lineIndex,
      variants,
      currency
    )

    const attributeHeaderColumns = attributeColumns.map((attribute, i) => {
      return renderColumn(
        renderers.attribute_header,
        data => {
          const label = data.attribute ? data.attribute : defaultAttributeLabel

          return (
            <AttributeHeaderColumn width={`${attributeColumnWidth}%`}>
              {!data.tooltip && <span>{label}</span>}
              {data.tooltip && (
                <Tooltip tip={data.tooltip}>
                  <span>{label}</span>
                </Tooltip>
              )}
            </AttributeHeaderColumn>
          )
        },
        {
          attribute: attribute.label,
          tooltip: attribute.tooltip,
        }
      )
    })

    const availableVariantColumns = [
      'image',
      'total_quantity',
      'rec_sales_price',
      'price',
      'total_price',
    ]
    const variantColumnsToBeShowed = availableVariantColumns.filter(column =>
      showColumn(column, columns, excludeColumns)
    )
    const totalVariantColumns =
      variantColumnsToBeShowed.length +
      postQuantityColumns.length +
      emptyColumns +
      productActions.length +
      attributeHeaderColumns.length
    const availableProductColumns = ['name', 'item_number']
    const productColumnsToBeShown = availableProductColumns.filter(column =>
      showColumn(column, columns, excludeColumns)
    )
    const productActionsV2 = this.props.productActionsV2 || []
    const productRowColSpan =
      totalVariantColumns - productColumnsToBeShown.length

    const productActionColumns = productActionsV2.map(Action => {
      return (
        <Action.Component
          callbackVars={[{ product }, instance]}
          product={product}
          extraPropsByProduct={extraPropsByProduct}
        />
      )
    })

    const regulateInventoryActions = this.props.regulateInventoryActions
    const showRegulateInventory = regulateInventoryActions.length > 0

    const regulateInventoryActionsRow = regulateInventoryActions.map(Action => {
      return (
        <Action.Component
          callbackVars={[{ product }, instance]}
          product={product}
          extraPropsByProduct={extraPropsByProduct}
        />
      )
    })

    return [
      <>
        {productColumnsToBeShown.length > 0 && repeatRow !== true && (
          <>
            <HeaderRow
              dark
              className="header-row header-row-dark"
              rowSize={rowSize}
            >
              {showColumn('name', columns, excludeColumns) &&
                renderColumn(
                  renderers.name_header,
                  data => (
                    <ProductNameColumn
                      className="product-name-column product"
                      width={`${attributeColumnWidth}%`}
                    >
                      Product
                    </ProductNameColumn>
                  ),
                  {}
                )}
              {showColumn('item_number', columns, excludeColumns) &&
                renderColumn(
                  renderers.item_number_header,
                  data => (
                    <ProductNameColumn
                      className="product-name-column item-number"
                      width={`${attributeColumnWidth}%`}
                    >
                      Item number
                    </ProductNameColumn>
                  ),
                  {}
                )}
              <ProductNameColumn colSpan={productRowColSpan} />
              {productActionColumns.map(v => (
                <ProductNameColumn />
              ))}
            </HeaderRow>
            <Row class="row" rowSize={rowSize}>
              {showColumn('name', columns, excludeColumns) &&
                renderColumn(
                  renderers.name,
                  data => (
                    <ProductNameColumn width={`${attributeColumnWidth}%`}>
                      <ClickLabel onClick={() => toggleProductModal(product)}>
                        <span style={{ textDecoration: 'underline' }}>
                          {data.name}
                        </span>
                      </ClickLabel>
                    </ProductNameColumn>
                  ),
                  { name: product.name }
                )}
              {showColumn('item_number', columns, excludeColumns) &&
                renderColumn(
                  renderers.item_number,
                  data => (
                    <ProductNameColumn width={`${attributeColumnWidth}%`}>
                      <ClickLabel onClick={() => toggleProductModal(product)}>
                        <span style={{ textDecoration: 'underline' }}>
                          {data.item_number}
                        </span>
                      </ClickLabel>
                    </ProductNameColumn>
                  ),
                  { item_number: product.item_number }
                )}
              <Column colSpan={productRowColSpan}>
                <ProductActionsV2Column>
                  {productActionColumns}
                </ProductActionsV2Column>

                {showRegulateInventory && (
                  <ProductActionsV2Row>
                    {regulateInventoryActionsRow}
                  </ProductActionsV2Row>
                )}
              </Column>
            </Row>
          </>
        )}
      </>,
      <HeaderRow className="header-row" rowSize={rowSize}>
        {showColumn('image', columns, excludeColumns) &&
          renderColumn(
            renderers.image_header,
            data => (
              <ImageHeaderColumn
                className="image"
                imageSettings={data.settings.table_images}
              />
            ),
            { settings }
          )}
        {attributeHeaderColumns}
        {createEmptyArray(emptyColumns).map(i => (
          <AttributeColumn width={`${attributeColumnWidth}%`} />
        ))}
        {postQuantityColumns.map(field => {
          const Component = field.header

          return (
            <PriceColumn>
              <Component />
            </PriceColumn>
          )
        })}
        {showColumn('total_quantity', columns, excludeColumns) &&
          renderColumn(
            renderers.total_quantity_header,
            data => <PriceColumn>Total</PriceColumn>,
            {}
          )}
        {showColumn('rec_sales_price', columns, excludeColumns) &&
          renderColumn(
            renderers.unit_price_header,
            data => (
              <PriceColumn>
                <Tooltip
                  tip={settings.rec_sales_price_label_tooltip}
                  placement="top"
                >
                  <span>{settings.rec_sales_price_label}</span>
                </Tooltip>
              </PriceColumn>
            ),
            {}
          )}
        {showColumn('price', columns, excludeColumns) &&
          renderColumn(
            renderers.unit_price_header,
            data => (
              <PriceColumn>
                <Tooltip tip={settings.sales_price_label_tooltip}>
                  <span>{settings.sales_price_label}</span>
                </Tooltip>
              </PriceColumn>
            ),
            {}
          )}
        {showColumn('total_price', columns, excludeColumns) &&
          renderColumn(
            renderers.total_price_header,
            data => <PriceColumn>Total</PriceColumn>,
            {}
          )}
        {productActions.map(action => (
          <ActionColumn />
        ))}
      </HeaderRow>,
    ]
  }
}

const QUANTITY_FIELD_NARROW_STYLE_THRESHOLD = 6

MatrixProductTablePropertiesHeader.propTypes = {
  attributeColumns: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  emptyColumns: PropTypes.number,
  productActions: PropTypes.array,
}

MatrixProductTablePropertiesHeader.defaultProps = {
  emptyColumns: 0,
  productActions: [],
}

class MatrixProductTableVariantRow extends Component<void, void> {
  static defaultProps = {
    actions: [],
    editableQuantities: false,
    onQuantityChange: () => {},
    productActions: [],
    quantities: Map(),
  }

  shouldComponentUpdate(nextProps) {
    return shouldUpdate(this.props, nextProps)
  }

  render() {
    const {
      attributeColumnWidth,
      colliOnly,
      columns,
      compareQuantities,
      contextData,
      renderers,
      currency,
      displayCompareQuantity,
      editableQuantities,
      emptyColumns,
      enableColli,
      excludeColumns,
      extraProps,
      horizontalColumn,
      horizontalColumnValues,
      lineIndex,
      markProducts,
      onQuantityChange,
      quantities,
      preferColli,
      priceMap,
      product,
      productActions,
      postQuantityColumns,
      rowSize,
      variants,
      settings,
      session,
      toggleDiscountModal,
      toggleProductModal,
      verticalAttributes,
    } = this.props

    const { id: variantId, attributes, prices } = variants[0]

    const firstVariant = variants[0]
    const picture = variants[0].picture

    const lineIndexForVariants = generateLineIndexFromVariants(
      lineIndex,
      variants,
      currency
    )

    const hasCurrency = prices.hasOwnProperty(currency)

    const quantityTotal = variants.reduce((total, variant) => {
      if (quantities.get(variant.id) !== undefined) {
        const q = toQuantityTotal(
          getQuantityForVariant(
            quantities.get(variant.id, Map()),
            prices,
            currency
          )
        )
        if (!isNaN(q)) {
          total += q
        }
      }

      return total
    }, 0)

    let hasAssortment = false
    for (let variant of variants) {
      if (variant.assortment && Object.keys(variant.assortment).length > 0) {
        hasAssortment = true
        break
      }
    }

    const variantsByHorizontal = keyBy(variants, variant => {
      const attribute =
        variant.assortment && Object.keys(variant.assortment).length > 0
          ? 'Quantity'
          : variant.attributes[horizontalColumn]

      // The variants do not always have to horizontal attribute
      return attribute
    })
    const quantityFields = horizontalColumnValues.map(
      ({ label: attribute, tooltip }) => {
        const variant = variantsByHorizontal[attribute]

        let quantity, mark, compareQuantity
        if (variant) {
          quantity = getQuantityForVariant(
            quantities.get(variant.id),
            prices,
            currency
          )
          mark = markProducts.includes(variant.id)
        }

        if (variant && compareQuantities) {
          const comparisonQuantityForVariant = compareQuantities.getIn([
            product.id,
            variant.id,
          ])

          // For something like reservation change preview we only want to view the changed reservations
          if (comparisonQuantityForVariant) {
            compareQuantity = getQuantityForVariant(
              comparisonQuantityForVariant,
              prices,
              currency
            )
          }
        }

        return variant ? (
          renderColumn(
            renderers.horizontal_attributes,
            data => {
              return (
                <AttributeColumn marked={data.mark} width={`${data.width}%`}>
                  {defaultQuantityRenderer(
                    data,
                    settings,
                    null,
                    null,
                    renderers
                  )}
                </AttributeColumn>
              )
            },
            {
              compareQuantity,
              displayCompareQuantity,
              variant,
              product,
              colliOnly,
              columns,
              currency,
              editableQuantities,
              enableColli,
              hasCurrency,
              mark,
              quantity,
              // used by productionproducttable
              quantityObject: quantities.get(variant.id),
              narrowStyle:
                horizontalColumnValues.length >=
                QUANTITY_FIELD_NARROW_STYLE_THRESHOLD,
              onQuantityChange,
              preferColli,
              settings,
              lineIndex: lineIndexForVariants,
              tooltip,
              width: attributeColumnWidth,
            }
          )
        ) : (
          <AttributeColumn marked={mark} width={`${attributeColumnWidth}%`}>
            {/** When having both single and assortment in same table we do not wish to fillout the Assortment column for normal rows.
                To determine if the row is a assortment row we check if its variants contain Quantity attribute. This is a bad idea and
                should be replaced.
           */}
            {attribute === 'Assortment' &&
              (!variantsByHorizontal['Quantity']
                ? ''
                : firstVariant.attributes[horizontalColumn] || '')}
            {(attribute !== 'Assortment' &&
              firstVariant.assortment &&
              firstVariant.assortment[attribute]) ||
              ''}
          </AttributeColumn>
        )
      }
    )

    const verticalAttributeColumns = verticalAttributes.map(({ label: name }) =>
      renderColumn(
        renderers.vertical_attributes,
        data => (
          <AttributeColumn width={`${attributeColumnWidth}%`}>
            {data.name}
          </AttributeColumn>
        ),
        { name: attributes[name] }
      )
    )

    const priceColumns = []
    if (
      showColumn('price', columns, excludeColumns) ||
      showColumn('rec_sales_price', columns, excludeColumns) ||
      showColumn('total_price', columns, excludeColumns)
    ) {
      if (!hasCurrency) {
        // The no price found column should span every price column. So the colSpan is calculated
        // based on the price columns we show
        let colspan = 0
        const lookForColumns = ['price', 'rec_sales_price', 'total_price']
        for (var i in lookForColumns) {
          if (showColumn(lookForColumns[i], columns)) {
            colspan++
          }
        }

        priceColumns.push(
          renderColumn(
            renderers.no_price_found,
            data => (
              <PriceColumn colSpan={colspan}>
                No price was found in {data.currency}
              </PriceColumn>
            ),
            { currency }
          )
        )
      } else {
        if (showColumn('rec_sales_price', columns, excludeColumns)) {
          priceColumns.push(
            renderColumn(
              renderers.price,
              data => {
                return (
                  <PriceColumn>
                    {data.price.rec_sales_price && (
                      <FormatCurrency currency={data.currency}>
                        {data.price.rec_sales_price}
                      </FormatCurrency>
                    )}
                  </PriceColumn>
                )
              },
              { price: prices[currency], currency }
            )
          )
        }

        let priceMapPrice
        let nextPriceMapPrice
        let lowestPriceMapPrice
        if (priceMap) {
          const priceMapPricesSortedByQuantity = sortBy(
            priceMap.base,
            price => -price.quantity
          )
          const eligiblePriceMapPrices = priceMapPricesSortedByQuantity.filter(
            price => price.quantity <= quantityTotal
          )
          const nonEligiblePriceMapPrices =
            priceMapPricesSortedByQuantity.filter(
              price => price.quantity > quantityTotal
            )

          // we grab the price with the quantity closest to the current. By not sorting by lowest price
          // we enable prices to be defined that increase with quantity
          if (eligiblePriceMapPrices.length > 0) {
            priceMapPrice = eligiblePriceMapPrices[0]
            lowestPriceMapPrice =
              eligiblePriceMapPrices[eligiblePriceMapPrices.length - 1]
          }
          if (nonEligiblePriceMapPrices.length > 0) {
            nextPriceMapPrice =
              nonEligiblePriceMapPrices[nonEligiblePriceMapPrices.length - 1]
          }
        }

        if (showColumn('price', columns, excludeColumns)) {
          priceColumns.push(
            renderColumn(
              renderers.price,
              data => {
                let price = getPrice(data.price)
                if (data.price.discount_amount) {
                  price += data.price.discount_amount
                }

                return (
                  <PriceColumn>
                    <FormatCurrency currency={data.currency}>
                      {price}
                    </FormatCurrency>
                  </PriceColumn>
                )
              },
              {
                extraProps,
                price: prices[currency],
                lowestPriceMapPrice,
                priceMapPrice,
                product,
                nextPriceMapPrice,
                currency,
              }
            )
          )
        }

        if (showColumn('total_price', columns, excludeColumns)) {
          priceColumns.push(
            renderColumn(
              renderers.total_price,
              data => (
                <PriceColumn>
                  <DiscountedPrice
                    price={data.price}
                    currency={data.currency}
                    quantity={data.quantityTotal}
                  />
                </PriceColumn>
              ),
              {
                price: prices[currency],
                lowestPriceMapPrice,
                priceMapPrice,
                currency,
                quantityTotal,
              }
            )
          )
        }
      }
    }

    const productActionColumns = productActions.map(Action => {
      return (
        <ActionColumn>
          <Action.Component
            callbackVars={[
              { product, variants, lines: lineIndexForVariants },
              { toggleDiscountModal },
            ]}
            context={contextData}
            session={session}
          />
        </ActionColumn>
      )
    })

    return (
      <Row rowSize={rowSize}>
        {showColumn('image', columns, excludeColumns) &&
          renderColumn(
            renderers.image,
            data => (
              <ImageColumn
                image={data.picture}
                settings={data.settings}
                toggleProductModal={() => toggleProductModal(product)}
              />
            ),
            { picture, settings }
          )}
        {verticalAttributeColumns}
        {quantityFields}
        {createEmptyArray(emptyColumns).map(i => (
          <AttributeColumn width={`${attributeColumnWidth}%`} />
        ))}
        {postQuantityColumns.map(column => {
          const Component = column.component

          return (
            <PriceColumn>
              <Component
                extraProps={extraProps}
                product={product}
                rowVariants={variants}
              />
            </PriceColumn>
          )
        })}
        {showColumn('total_quantity', columns, excludeColumns) &&
          renderColumn(
            renderers.total_quantity,
            data => <PriceColumn>{data.total_quantity}</PriceColumn>,
            { total_quantity: quantityTotal, extraProps, product }
          )}
        {priceColumns}
        {productActionColumns}
      </Row>
    )
  }
}

const SpacerRowStyle = { height: 35, border: 'none' }
const SpacerRow = ({ columnCount }) => {
  return (
    <tr>
      <td colSpan={columnCount} style={SpacerRowStyle} />
    </tr>
  )
}

const stockListStyle = { fontSize: 11 }
export const defaultQuantityRenderer = (
  data,
  settings,
  preFieldSiblings = null,
  postFieldSiblings = null,
  renderers
) => {
  return (
    <div>
      {preFieldSiblings}
      <QuantityField
        colliOnly={data.colliOnly}
        colliSizes={data.product.colli || []}
        compareQuantity={data.compareQuantity}
        editable={data.editableQuantities && data.hasCurrency}
        enableColli={data.enableColli}
        value={data.quantity}
        matrix={true}
        narrowStyle={data.narrowStyle}
        onChange={quantity => data.onQuantityChange(data.variant, quantity)}
        popover={
          settings.show_variant_popover
            ? createVariantPopover(
                data.variant,
                data.currency,
                data.product,
                settings
              )
            : null
        }
        preferColli={data.preferColli}
      />
      {postFieldSiblings}
      {showColumn('inventory', settings.columns) &&
        renderColumn(
          renderers.inventory,
          data => {
            return (
              <StockList
                entityId={data.product.brand_id}
                showEmpty
                stock={data.stock}
                style={stockListStyle}
              />
            )
          },
          {
            product: data.product,
            stock: data.variant.stock
              ? data.variant.stock.filter(s => s.stock_type === 'stock')
              : [],
          }
        )}
    </div>
  )
}

const splitQuantitiesByVerticalAttributes = (
  currentQuantities,
  propQuantities,
  rows
) => {
  return rows.reduce((carry, row, i) => {
    if (row.type === 'row') {
      const changes = row.data.variants.reduce((innerCarry, variant) => {
        const productId = row.data.product.id
        const variantId = variant.id

        const current = currentQuantities.getIn([i, variantId])
        const change = propQuantities.getIn([productId, variantId])

        if (current != change) {
          return innerCarry.setIn([variant.id], change)
        }

        return innerCarry
      }, Map())

      if (!changes.isEmpty()) {
        return carry.mergeIn([i], changes)
      }
    }

    return carry
  }, currentQuantities)
}

const mapAttributes = memoize(attributes => {
  const mapped = {}

  for (let attribute of attributes) {
    const name = attribute.name.toLowerCase()

    const values = sortBy(attribute.values, 'sort_order')

    if (!mapped[name]) {
      mapped[name] = {
        sort_order: [],
        tooltips: {},
      }
    }

    for (let value of attribute.values) {
      mapped[name].sort_order[value.sort_order] = value.name

      if (
        value.show_description === true &&
        typeof value.description === 'string' &&
        value.description.length > 0
      ) {
        mapped[name].tooltips[value.name] = value.description
      }
    }
  }

  return mapped
})

const generateRows = (
  products,
  currency,
  custom_order,
  sort,
  settings,
  attributes,
  columns,
  priceMap,
  filterEmptyVariants,
  filterEmptyVariantsMap,
  quantities
) => {
  products = products.toJS()

  if (filterEmptyVariants) {
    products = products.map(product => {
      if (filterEmptyVariantsMap[product.id] === false) {
        return product
      }

      return {
        ...product,
        variants: product.variants.filter(
          v => quantities.getIn([product.id, v.id, 'quantity'], 0) > 0
        ),
      }
    })
  }

  // Determine which attribute to display horizontally. It can either be a constant set by the brand,
  // but can also we calculated automatically based on what attribute has most different values
  const horizontalColumn = determineHorizontalColumn(
    products,
    settings.horizontal_attribute
  )

  const productsById = keyBy(products, 'id')

  let order, topAttributeColumns
  products = groupBy(products, 'id')

  // We used to have auto sorting but removed it. TODO: REMOVE THIS ONE DAY
  if (sort === 'auto') {
    sort = 'name'
  }

  if (sort === 'custom') {
    order = custom_order
  } else {
    order = sortProducts(products, sort)
  }

  topAttributeColumns = order.reduce((carry, key) => {
    const product = products[key][0]
    const count = calculateTopAttributeColumns(
      product,
      horizontalColumn,
      settings.hide_attributes
    )

    return carry > count ? carry : count
  }, 0)

  const lowercaseHorizontalColumn = horizontalColumn
    ? horizontalColumn.toLowerCase()
    : ''
  const horizontalAttributeConfig = attributes[lowercaseHorizontalColumn] || {
    sort_order: [],
    tooltips: {},
  }

  const rows = order
    .map(groupKey => products[groupKey])
    .reduce((carry, products, i) => {
      const verticalAttributes = getVerticalAttributes(
        products[0],
        horizontalColumn,
        settings.hide_attributes
      ).map(attr => ({ label: attr }))

      let horizontalAttributeValues = getHorizontalAttributeValues(
        products[0],
        horizontalColumn
      )
      horizontalAttributeValues = sortAttributesBySortOrder(
        horizontalAttributeValues,
        horizontalAttributeConfig.sort_order
      ).map(attr => {
        const tooltip = horizontalAttributeConfig.tooltips[attr]

        return { label: attr, tooltip }
      })

      const attributeColumnsCount =
        verticalAttributes.length + horizontalAttributeValues.length

      const attributeColumns = [
        ...verticalAttributes,
        ...horizontalAttributeValues,
      ]
      const emptyColumns = topAttributeColumns - attributeColumnsCount
      // All the attribute columns should be equal width. This will especially matter in print mode,
      // and ensure that one column is not crazy wide in comparison to the rest.
      // The 1 at the end is the product name column
      let columnsCount = attributeColumnsCount + emptyColumns + 1
      if (columns.indexOf('item_number')) {
        columnsCount++
      }

      const attributeColumnWidth = 100 / columnsCount

      // Extract all variants into same array to generate product rows
      const variants = [].concat(...products.map(product => product.variants))
      /**
       * We group all variants together based on their product ID, vertical attributes and price.
       * By doing so we allow the matrix format to work on a product where maye one variant out of 10
       * has a different price. Imagine the following.
       *
       * Color | Small   | Medium  | Large   | Price
       * Black | 1       | 1       | (empty) | 100.00
       * Black | (empty) | (empty) | 1       | 120.00
       */
      const groupedByVerticalAttributesAndPrice = groupBy(variants, variant => {
        return [variant.product_id]
          .concat(
            getVerticalAttributesAndPrice(variant, horizontalColumn, currency)
          )
          .join(GROUPBY_DELIMITER)
      })

      const header = {
        data: {
          attributeColumns,
          attributeColumnWidth,
          emptyColumns,
          headerKey: 0,
          key:
            `header_${products[0].id}_` +
            attributeColumns.map(attr => attr.label).join(','),
          product: products[0],
          variants,
          verticalAttributes,
        },
        type: 'header',
      }

      carry.push(header)

      const newRows = Object.keys(groupedByVerticalAttributesAndPrice).map(
        groupName => {
          const variants = groupedByVerticalAttributesAndPrice[groupName]
          const product = productsById[variants[0].product_id]

          return {
            data: {
              attributeColumnWidth,
              emptyColumns,
              key: `row_${groupName}`,
              horizontalColumnValues: horizontalAttributeValues,
              priceMap: priceMap ? priceMap[variants[0].product_id] : undefined,
              product,
              variants,
              verticalAttributes,
            },
            type: 'row',
          }
        }
      )

      const headerPerRow = 5
      const tenths = Math.ceil(newRows.length / headerPerRow) - 1
      for (var i = 0; i < tenths; i++) {
        const noRow = i + 1
        const index = noRow * headerPerRow
        const headersBefore = i

        const insertAt = noRow + index + headersBefore

        if (insertAt !== newRows.length) {
          newRows.splice(
            insertAt,
            0,
            Object.assign({}, header, {
              // Avoids rows with the same key
              data: Object.assign({}, header.data, {
                headerKey: noRow,
                repeatRow: true,
              }),
            })
          )
        }
      }

      newRows.push({
        data: {
          column_count: 15,
        },
        type: 'spacer',
      })

      const rows = carry.concat(newRows)

      return rows
    }, [])

  return {
    horizontalColumn,
    rows,
  }
}

/**
 * To align non-attribute columns on the right of the table,
 * for instance product actions, prices etc. We need to match the number
 * of attribute columns for all tables.
 *
 * Name | Color | Small  | Medium  | Price | (empty)
 * P#1  | Black | 1      | 1       | 100.0 | [Delete]
 * Name | Small | Medium | (empty) | Price | (empty)
 * P#2  | 1     | 1      | (empty) | 100.0 | [Delete]
 *
 * @param {Object} product
 * @param {string} [horizontalAttribute]
 * @returns {number}
 */
export const calculateTopAttributeColumns = (
  product,
  horizontalAttribute,
  hideAttributes
) => {
  const verticalColumns = getVerticalAttributes(
    product,
    horizontalAttribute,
    hideAttributes
  )

  let horizontalValues = []
  let hasAssortment = false
  for (let variant of product.variants) {
    const assortmentValues = Object.keys(variant.assortment || {})

    // For assortments we do not display the name of the assortment as horizontal
    if (assortmentValues.length > 0) {
      hasAssortment = true
      horizontalValues = horizontalValues.concat(assortmentValues)
    } else {
      horizontalValues.push(variant.attributes[horizontalAttribute])
    }
  }

  if (hasAssortment) {
    horizontalValues.push('Assortment')
  }

  horizontalValues = uniq(horizontalValues.filter(v => v))

  return verticalColumns.length + horizontalValues.length
}

/**
 * Will calculate all the vertical attributes of the product
 *
 * @param {Object} product
 * @param {string} horizontalAttribute
 * @returns {string[]}
 */
const getVerticalAttributes = (product, horizontalAttribute, hideAttributes) =>
  product.attributes.filter(
    a => a !== horizontalAttribute && hideAttributes.indexOf(a) === -1
  )

/**
 *
 * @param {Object} product
 * @param {string} horizontalAttribute
 * @returns {string[]}
 */
const getHorizontalAttributeValues = (product, horizontalAttribute) => {
  let values = product.variants.reduce((carry, variant) => {
    const attribute = variant.attributes[horizontalAttribute]

    if (variant.assortment && Object.keys(variant.assortment).length > 0) {
      for (let attributeValue in variant.assortment) {
        carry.push(attributeValue)
        carry.push('Quantity')
        carry.push('Assortment')
      }
    } else {
      carry.push(attribute)
    }

    return carry
  }, [])

  // Filter undefined
  values.filter(v => v)

  // Filter duplicates
  values = uniq(values)

  return values
}

const sortAttributesBySortOrder = (values, sortOrders) => {
  if (!sortOrders) {
    return values
  }

  values.sort((a, b) => {
    let aIndex = sortOrders.indexOf(a)
    let bIndex = sortOrders.indexOf(b)

    if (a === 'Assortment') {
      return -1
    }
    if (b === 'Assortment') {
      return 1
    }

    // Send to bottom
    if (aIndex === -1) {
      aIndex = 10000
    }
    if (bIndex === -1) {
      bIndex = 10000
    }

    // aIndex will appear first since "sort_order" value is bigger
    if (aIndex > bIndex) {
      return 1
    }

    // bIndex will be sorted to index lower than aIndex
    if (aIndex < bIndex) {
      return -1
    }

    // leave aIndex and bIndex unchanged with respect to each other since aIndex equals bIndex
    return 0
  })

  return values
}

const fallbackToAutoSettingsIfNeeded = (
  horizontal_attribute,
  parsedProducts
) => {
  const attributeExistsInAllProducts = parsedProducts.reduce(
    (exists, product) => {
      if (product.attributes.indexOf(horizontal_attribute) === -1) {
        //exists = false
      }
      return exists
    },
    true
  )

  return attributeExistsInAllProducts
    ? horizontal_attribute
    : HORIZONTAL_ATTRIBUTE_DEFAULT_VALUE
}

const determineHorizontalColumn = (parsedProducts, horizontal_attribute) => {
  let result = fallbackToAutoSettingsIfNeeded(
    horizontal_attribute,
    parsedProducts
  )

  if (result === HORIZONTAL_ATTRIBUTE_DEFAULT_VALUE) {
    return autoDetermineHorizontalColumn(parsedProducts)
  }

  return horizontal_attribute
}

/**
 * When a brand hos not selected a horizontal attribute, we automatically
 * choose the one with the most attribute values.
 *
 * @param {Object[]} product
 * @returns {string} The horizontal column
 */
export const autoDetermineHorizontalColumn = products => {
  // Start by extracting how many occurrences there are of all attribute values.
  let counts = products
    .reduce((carry, products) => carry.concat(products), [])
    .reduce((carry, product) => carry.concat(product.variants), [])
    .reduce((carry, variant) => carry.concat([variant.attributes]), [])
    .reduce((carry, attributes) => {
      return Object.keys(attributes).reduce((innerCarry, attributeName) => {
        if (!Array.isArray(innerCarry[attributeName])) {
          innerCarry[attributeName] = []
        }

        const value = attributes[attributeName]
        if (innerCarry[attributeName].indexOf(value) === -1) {
          innerCarry[attributeName].push(value)
        }

        return innerCarry
      }, carry)
    }, {})

  // Sort all counts to get the highest
  counts = Object.keys(counts).sort(
    (a, b) => counts[b].length - counts[a].length
  )

  return counts[0]
}

/**
 * When doing compact sort, we group products together that have the same headers.
 * The headers are basically all the horizontal attribute values + all the vertical
 * attribute names. For instance
 * Color | Small | Medium | Large
 * Notice how the first entry here is an attribute NAME while the rest are attribute values!
 *
 * @param {Object} product
 * @param {string} horizontalColumn
 * @param {string[]} verticalColumns
 * @returns {string[]}
 */
const getProductGroup = (product, horizontalColumn, verticalColumns) => {
  const values = uniq(
    product.variants.map(variant => variant.attributes[horizontalColumn])
  )
  // This does not determine the order of the horizontal column. It is only
  // for grouping purposes.
  values.sort()

  return [...verticalColumns, ...values]
}

/**
 *
 */
const getVerticalAttributesAndPrice = (variant, horizontalColumn, currency) => {
  const attributes = uniq(
    Object.keys(variant.attributes)
      .filter(name => name !== horizontalColumn)
      .map(name => variant.attributes[name])
  )
  const price = variant.prices[currency] || currency

  return attributes.concat([
    price.sales_price,
    price.offer_price,
    price.rec_sales_price,
    JSON.stringify(variant.assortment),
  ])
}

const ProductActionsV2Column = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const ProductActionsV2Row = styled.div`
  display: flex;
  justify-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding-top: 5px;
`
