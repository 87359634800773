/* @flow */

import api from '../api'

import type { BrunoRequestOptions, Id } from '../types'

export const getCategories = (options?: BrunoRequestOptions) =>
  api.get('/categories', {
    query: options,
  })

export const getCategoryProducts = (categoryId: Id) =>
  api.get(`/categories/${categoryId}/products`)

export const updateCategoryProducts = (categoryId: Id, productIds: Array<Id>) =>
  api.put(`/categories/${categoryId}/products`, {
    body: {
      product_ids: productIds,
    },
  })
