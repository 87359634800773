/* @flow */

import * as React from 'react'
import { type Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import memoize from 'memoize-one'
import type { Location, Match, RouterHistory } from 'react-router-dom'
import qs from 'querystring'
import querystring from 'qs'

import { setBrands } from '../brands/actions'

import { ShowroomContext } from './shared'
import { useAnonymousShowroomSession, usePublicBrand } from './hooks'

type Props = {
  children?: React.Node,
  dispatch: Dispatch<any>,
  history: RouterHistory,
  location: Location,
  match: Match,
}

const ShowroomAnonymousSession = ({
  children,
  dispatch,
  history,
  location,
  match,
}: Props) => {
  const brandId = match.params.brandId

  const options = React.useMemo(
    () => ({
      resource: 'showroom',
      resource_id: match.params.showroomId,
    }),
    []
  )

  const [brand, isFetching, { isInitialized }] = usePublicBrand([
    brandId,
    options,
  ])

  const code = React.useMemo(() => {
    const query = querystring.parse(location.search.substr(1)) || {}
    const code = query.code || undefined

    if (!code) {
      return null
    }

    return code
  }, [location])

  const extraData = React.useMemo(() => {
    return code ? { code } : null
  }, [code])

  const [showroomSession] = useAnonymousShowroomSession([
    brand ? brand.id : null,
    extraData,
  ])

  useEffect(() => {
    if (brand) {
      dispatch(setBrands([brand]))
      return
    }

    if (!brand && isInitialized) {
      const redirect = `${window.location.pathname}${location.search}`

      history.push(
        `/login?${qs.stringify({
          redirect: encodeURI(redirect),
          brand: brandId,
        })}`
      )
    }
  }, [brand, brandId, isInitialized])

  const showroomContext = React.useMemo(() => {
    return {
      brand,
      showroomSession,
      onUpdateShowroomSession: () => {
        /// TODO?
      },
    }
  }, [brand, showroomSession])

  if (!showroomSession || !brand) {
    return null
  }

  return (
    <ShowroomContext.Provider value={showroomContext}>
      {children}
    </ShowroomContext.Provider>
  )
}

export default connect()(ShowroomAnonymousSession)
