/* @flow */

import React from 'react'
import styled from 'styled-components'
import { ButtonGroup, Button } from 'react-bootstrap'
import { Field, type FieldProps } from 'formik'

import { ErrorLabel } from '../Forms'
import { renderBooleanInput } from './BooleanInput'
import { renderFormInput } from './FormInput'
import { renderCheckboxInput } from './CheckboxInput'
import { renderSelectInput } from './SelectInput'
import { renderZalandoInput } from '../../../app/app-store/components/ZalandoInputField'

type Props = {
  bsSize?: string,
  disabled?: boolean,
  falseLabel?: string,
  falseValue?: mixed,
  overrideLabel?: string,
  trueLabel?: string,
  trueValue?: mixed,
}

// we have had some issues with OverrideableInput using type=checkbox. If `type` is passed to <Field /> then
// the prop field.value will be undefined instead of the correct value. So we use render function to avoid passing
// `type` to <Field/>
const OverridableInput = ({ type, ...props }) => {
  return (
    <Field
      render={formikProps => {
        return <InnerRender {...formikProps} {...props} type={type} />
      }}
      {...props}
    />
  )
}

export default OverridableInput

const InnerRender = (props: FieldProps & Props) => {
  const {
    field,
    form,
    defaultValue,
    id,
    overrideLabel = 'Override global setting',
    type,
    ...rest
  } = props

  const [override, setOverride] = React.useState(false)
  const mounted = React.useRef(false)

  const { errors, touched } = form
  const { name, value } = field

  React.useEffect(() => {
    if (!mounted.current) {
      mounted.current = true

      setOverride(value !== null && value !== undefined)
    }
  }, [defaultValue, mounted, setOverride, value])

  const toggle = () => {
    const newValue = !override

    if (newValue === true) {
      form.setFieldValue(name, defaultValue)
    } else {
      form.setFieldValue(name, null)
    }

    setOverride(newValue)
  }

  let Render
  switch (type) {
    case 'boolean':
      Render = renderBooleanInput
      break
    case 'checkbox':
      Render = renderBooleanInput
      break
    case 'input':
      Render = renderFormInput
      break
    case 'select':
      Render = renderSelectInput
      break
    case 'zalando':
      Render = renderZalandoInput
      break
    default:
      throw new Error(`${type} is an invalid renderer`)
  }

  let useValue = override ? value : defaultValue

  const overriddenField = React.useMemo(() => {
    return override
      ? { ...field, value: value }
      : { ...field, value: defaultValue }
  }, [defaultValue, field, override, value])

  return (
    <div>
      <div>
        <Render
          field={overriddenField}
          form={form}
          disabled={!override}
          {...rest}
        />

        {touched[name] && errors[name] && (
          <ErrorLabel>{errors[name]}</ErrorLabel>
        )}
      </div>

      <CheckboxInput>
        <div className="checkbox check-success">
          <input type="checkbox" id={id} checked={override} onChange={toggle} />
          <label htmlFor={id}>{overrideLabel}</label>
        </div>
      </CheckboxInput>
    </div>
  )
}

const CheckboxInput = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`
