/* @flow */

import React from 'react'
import keyBy from 'lodash/keyBy'
import memoize from 'memoize-one'
import isObject from 'lodash/isObject'
import styled from 'styled-components'
import { Map, List } from 'immutable'

import * as Tabs from '../../../../infrastructure/components/Tabs'
import { FormControl } from '../../../../infrastructure/components/Forms'
import AggregateProductTable from './AggregateProductTable'
import MatrixProductTable from './MatrixProductTable'
import { HORIZONTAL_ATTRIBUTE_DEFAULT_VALUE } from './MatrixProductTable'
import TraditionalProductTable from './TraditionalProductTable'
import {
  actionFilterer,
  createLineKeyFromVariant,
  PRODUCT_TABLE_CUSTOM_PRESET,
  updateQuantityForVariant,
} from './shared'
import { connect } from 'react-redux'
import { addCustomCloudinaryPresetIfNeeded } from '../../../../infrastructure/actions/cloudinary'
import {
  IMAGE_RADIUS_CIRCLE,
  IMAGE_RADIUS_ROUNDED,
  IMAGE_SIZE_SMALL,
  IMAGE_SIZE_MEDIUM,
  IMAGE_SIZE_LARGE,
  IMAGE_SIZE_CUSTOM,
} from '../../../settings/brand/constants'
import ProductModal from './ProductModal'
import { getProduct } from '../../../shop/api'
import DiscountModal from './DiscountModal'
import { UserHasPermissions } from '../../../../infrastructure/components/Authorization'
import { isCustomFieldVisible } from '../../../custom-fields/shared'
import TableHeader from './TableHeader'

import type {
  ProductTableAction,
  ProductTableSettings,
  ProductTableProps,
  RowSize,
} from './types'
import type {
  Currency,
  Dispatch,
  Drop,
  Id,
  Product,
  Quantities,
  Session,
  WebshopSession,
} from '../../../types'

type State = {
  discountModalProducts: Array<Product>,
  options: {
    aggregates: Array<string>,
    aggregate_method: 'percentage' | 'quantity',
    bordered: boolean,
    columns: Array<string>,
    custom_order: Array<number>,
    matrix: boolean,
    rehydrateTableOptions: boolean,
    sort: string,
  },
  optionsIsDirty: boolean,
  products: List<Product>,
  productModalProduct: null | Product,
  showDiscountModal: boolean,
  showProductModal: boolean,
}

// Must be before defaultProps defintion (static)
const defaultFetchProduct = (
  brandId: number,
  product: Product,
  currency: Currency,
  session: Session,
  webshopSession: ?WebshopSession
) => {
  return getProduct(brandId, product.id, {
    currency: currency,
    webshop_session_id: webshopSession ? webshopSession.id : undefined,
  })
}

export const transformData = (data: Array<Product>, currency: Currency) =>
  data.map(product => {
    // used by sort function
    if (isObject(product.collection)) {
      product.collection = product.collection.title
    }
    // used by sort function
    if (isObject(product.subbrand)) {
      product.subbrand = product.subbrand.name
    }

    return product
  })

export const defaultSettings: ProductTableSettings = {
  attribute_codes: [],
  bordered: false,
  columns: [
    'name',
    'image',
    'quantity',
    'price',
    'total_price',
    'item_number',
    'collection',
    'subbrand',
    'rec_sales_price',
  ],
  custom_diameter: 200,
  extra_rows: [],
  hide_attributes: [],
  horizontal_attribute: 'auto',
  image_shape: 'circle',
  image_size: 'small',
  matrix: true,
  product_data: ['item_number', 'collection', 'subbrand'],
  product_name_label:
    '{{#name}}{{name}}{{/name}} {{#item_number}}(#{{item_number}}){{/item_number}} {{#collection}}({{collection}}){{/collection}} {{#subbrand}}({{subbrand}}){{/subbrand}}',
  rec_sales_price_label: 'RRP',
  rec_sales_price_label_tooltip: 'Recommended Retail Price',
  sales_price_label: 'WHS',
  sales_price_label_tooltip: 'Wholesale Price',
  show_product_image: true,
  show_variant_images: true,
  show_variant_popover: true,
  show_whs_after_discount: false,
  sort: 'name',
  zoom_custom_height: '',
  zoom_custom_width: '',
  zoom_on_hover: true,
  zoom_size: 'large',
  variant_popover_data: ['inventory', 'sku', 'ean', 'prices', 'colli_prices'],
}

export class ProductTable extends React.Component<ProductTableProps, State> {
  static defaultProps = {
    aggregates: [],
    aggregate_method: 'quantity',
    attributesOrder: {},
    currentDrop: null,
    contextData: {},
    custom_order: [],
    customColumns: [],
    dataTransformer: transformData,
    defaultAttributeLabel: 'Quantity',
    displayCompareQuantity: false,
    drops: [],
    editableQuantities: false,
    enableColli: true,
    excludeColumns: [],
    fetchProduct: defaultFetchProduct,
    filterEmptyVariants: false,
    isFetching: false,
    lineIndex: {},
    markProducts: List(),
    matrix: defaultSettings.matrix,
    onDiscountSet: () => {},
    onDropClick: () => {},
    onProductsAdded: () => {},
    onQuantityChange: () => {},
    onFulfilledChange: () => {},
    onUpdateExtraProps: () => {},
    rehydrateTableOptions: true,
    renderers: {},
    quantities: Map(),
    productActions: [],
    productActionsV2: [],
    products: List(),
    postQuantityColumns: [],
    regulateInventoryActions: [],
    rowSize: 'normal',
    sort: defaultSettings.sort,
    style: {},
    tableActions: [],
    variantActions: [],
  }

  state = {
    discountModalProducts: [],
    filterEmptyVariantsMap: {},
    options: {
      aggregates: this.props.aggregates,
      aggregate_method: this.props.aggregate_method,
      bordered: this.props.settings.bordered,
      columns: this.props.settings.columns,
      custom_order: this.props.custom_order,
      matrix: this.props.settings.matrix,
      rehydrateTableOptions: this.props.rehydrateTableOptions,
      sort: this.props.settings.sort,
    },
    optionsIsDirty: false,
    products: List(),
    productModalProduct: null,
    showDiscountModal: false,
    showProductModal: false,
  }

  componentDidMount() {
    this.setState({
      products: this.transformData(this.props.products),
    })
    this.ensureCustomCloudinaryPreset()
  }

  componentWillReceiveProps(nextProps: ProductTableProps) {
    const changes = {}
    this.ensureCustomCloudinaryPreset()

    // Using Immutable structure we can just check if the reference
    // is the same
    if (nextProps.products !== this.props.products) {
      changes.products = this.transformData(nextProps.products)
    }

    if (nextProps.custom_order !== this.props.custom_order) {
      changes.options = Object.assign({}, this.state.options, {
        custom_order: nextProps.custom_order,
      })
    }

    this.setState(changes)
  }

  componentDidUpdate(prevProps: ProductTableProps, prevState: State) {
    if (
      !this.state.optionsIsDirty &&
      prevProps.settings !== this.props.settings
    ) {
      this.setState(s => {
        return {
          options: {
            ...s.options,
            bordered: this.props.settings.bordered,
            columns: this.props.settings.columns,
            matrix: this.props.settings.matrix,
            sort: this.props.settings.sort,
          },
        }
      })
    }
  }

  ensureCustomCloudinaryPreset = () => {
    const {
      dispatch,
      settings: { custom_diameter, image_size },
    } = this.props

    if (image_size === 'custom') {
      dispatch(
        addCustomCloudinaryPresetIfNeeded(
          PRODUCT_TABLE_CUSTOM_PRESET,
          parseInt(custom_diameter)
        )
      )
    }
  }

  emitProductAdded = (quantities: Quantities, response) => {
    this.props.onProductsAdded(quantities, response)
  }

  onDiscountSet = (quantities: Quantities, products, lineIndex) => {
    this.setState(
      {
        showDiscountModal: false,
      },
      () => {
        this.props.onDiscountSet(quantities, products, lineIndex)
      }
    )
  }

  onQuantityChange = (variant, quantity) => {
    const { currency, currentDrop, lineIndex } = this.props

    let quantities
    if (!currentDrop) {
      quantities = updateQuantityForVariant(
        this.props.quantities,
        variant,
        quantity,
        currency
      )
    } else {
      quantities = updateQuantityForVariant(
        this.props.dropQuantities[currentDrop] || Map(),
        variant,
        quantity,
        currency
      )
    }

    const key = createLineKeyFromVariant(variant, currency)

    this.props.onQuantityChange(
      variant,
      quantity,
      quantities,
      lineIndex[key],
      currentDrop
    )
  }

  onFulfilledChange = (variant, fulfilled) => {
    this.props.onFulfilledChange(variant, fulfilled)
  }

  setExtraPropsByProduct = (productId, data) => {
    const updated = { ...this.props.extraPropsByProduct }

    updated[productId] = { ...(updated[productId] || {}), ...data }

    this.props.onExtraPropsByProductUpdate(
      updated,
      productId,
      data,
      this.props.lineIndex
    )
  }

  setTableOption = changes => {
    this.setState({
      // If we do not do this, connect will override the option changes made on every prop change
      optionsIsDirty: true,
      options: Object.assign({}, this.state.options, changes),
    })
  }

  transformData = (products: List<Product>) => {
    products = products.toJS()
    // Deep copy the data to ensure the incoming data has same format on reevaluation (T161)
    const copy = JSON.parse(JSON.stringify(products))
    return List(
      this.props.dataTransformer(copy, this.props.currency, this.props)
    )
  }

  toggleDiscountModal = (products: List<Product>) => {
    this.setState({
      discountModalProducts: products,
      showDiscountModal: !this.state.showDiscountModal,
    })
  }

  toggleEmptyVariants = product => {
    const { filterEmptyVariants } = this.props

    this.setState(s => {
      const newMap = { ...s.filterEmptyVariantsMap }
      const defaultValue = filterEmptyVariants ? true : false

      if (newMap[product.id] === undefined) {
        newMap[product.id] = !defaultValue
      } else {
        newMap[product.id] = !newMap[product.id]
      }

      return {
        filterEmptyVariantsMap: newMap,
      }
    })
  }

  toggleProductModal = (product: Product) => {
    this.setState({
      productModalProduct:
        !this.state.showProductModal === true ? product : null,
      showProductModal: !this.state.showProductModal,
    })
  }

  render() {
    const {
      attributesOrder,
      brand,
      brandContext,
      canOverrideColliOnly,
      compareQuantities,
      contextData,
      currency,
      currentDrop,
      customerColliOnly,
      customColumns,
      customFields,
      defaultAttributeLabel,
      displayCompareQuantity,
      drops,
      dropQuantities,
      editableQuantities,
      editableFulfillment,
      enableColli,
      excludeColumns,
      extraPropsByProduct,
      fetchProduct,
      filterEmptyVariants,
      isBrandUser,
      isFetching,
      lineIndex,
      markProducts,
      onUpdateExtraProps,
      preferColli,
      priceMap,
      products: propProducts, // do not override products with ...rest
      postQuantityColumns,
      quantities, // do not override quantities with ...rest
      quantityLabels,
      quantityStyle,
      renderers,
      rowSize,
      session,
      settings,
      style,
      tableActions,
      tableKey,
      title,
      webshopSession,
    } = this.props
    const {
      discountModalProducts,
      filterEmptyVariantsMap,
      options,
      products,
      productModalProduct,
      showDiscountModal,
      showProductModal,
    } = this.state

    let TableComponent = TraditionalProductTable
    if (options.aggregates.length > 0) {
      TableComponent = AggregateProductTable
    } else if (options.matrix) {
      TableComponent = MatrixProductTable
    }

    const productActions = actionFilterer(
      this.props.productActions,
      contextData,
      session
    )
    const productActionsV2 = actionFilterer(
      this.props.productActionsV2,
      contextData,
      session
    )
    const regulateInventoryActions = actionFilterer(
      this.props.regulateInventoryActions,
      contextData,
      session
    )
    const variantActions = actionFilterer(
      this.props.variantActions,
      contextData,
      session
    )

    let tabs
    if (drops && drops.length > 1) {
      tabs = (
        <TabsContainer>
          <Tabs.TabsBar>
            {drops.map((drop, i) => {
              return (
                <Tabs.Tab
                  active={drop.id === currentDrop}
                  onClick={() => this.props.onDropClick(drop)}
                >
                  <Tabs.Tab.TopLine />
                  <Tabs.Tab.Label>{drop.name}</Tabs.Tab.Label>
                  <Tabs.Tab.DropdownButton>
                    <span className="fa fa-chevron-down" />
                  </Tabs.Tab.DropdownButton>
                </Tabs.Tab>
              )
            })}
          </Tabs.TabsBar>
        </TabsContainer>
      )
    }

    return (
      <div>
        {tabs}
        <TableHeader
          brand={brand}
          callbackVars={generateTableHeaderCallbackVars(this)}
          contextData={contextData}
          customFields={customFields}
          session={session}
          tableActions={tableActions}
          tableKey={tableKey}
          tableOptions={options}
          title={title}
        />
        {/*
          options = options in the table options bar (sort, columns etc.)
          settings = table settings from the brand settings
        */}
        {showDiscountModal && (
          <DiscountModal
            currency={currency}
            lineIndex={lineIndex}
            onHide={this.toggleDiscountModal}
            products={discountModalProducts}
            onSave={this.onDiscountSet}
            quantities={quantities}
            show={showDiscountModal}
          />
        )}
        {showProductModal && (
          <ProductModal
            fetchProduct={() =>
              fetchProduct(
                brandContext,
                productModalProduct,
                currency,
                session,
                webshopSession
              )
            }
            onHide={this.toggleProductModal}
            show={showProductModal}
          />
        )}
        {isFetching === true && <Loader />}
        {!isFetching && (
          <Container>
            <TableComponent
              brand={brand}
              bordered={options.bordered}
              canOverrideColliOnly={canOverrideColliOnly}
              compareQuantities={compareQuantities}
              contextData={contextData}
              currency={currency}
              customerColliOnly={customerColliOnly}
              customColumns={customColumns}
              customFields={customFields}
              defaultAttributeLabel={defaultAttributeLabel}
              displayCompareQuantity={displayCompareQuantity}
              drops={drops}
              editableQuantities={editableQuantities}
              editableFulfillment={editableFulfillment}
              enableColli={enableColli}
              excludeColumns={excludeColumns}
              extraPropsByProduct={extraPropsByProduct}
              filterEmptyVariants={filterEmptyVariants}
              filterEmptyVariantsMap={filterEmptyVariantsMap}
              instance={this}
              isBrandUser={isBrandUser}
              lineIndex={lineIndex}
              markProducts={markProducts}
              onQuantityChange={this.onQuantityChange}
              onFulfilledChange={this.onFulfilledChange}
              onUpdateExtraProps={onUpdateExtraProps}
              options={options}
              preferColli={preferColli}
              priceMap={priceMap}
              productActions={productActions}
              productActionsV2={productActionsV2}
              products={products}
              postQuantityColumns={postQuantityColumns}
              quantities={
                currentDrop === null
                  ? quantities
                  : dropQuantities[currentDrop] || Map()
              }
              quantityLabels={quantityLabels}
              quantityStyle={quantityStyle}
              regulateInventoryActions={regulateInventoryActions}
              renderers={renderers}
              rowSize={rowSize}
              session={session}
              settings={settings}
              style={style}
              toggleDiscountModal={this.toggleDiscountModal}
              toggleEmptyVariants={this.toggleEmptyVariants}
              toggleProductModal={this.toggleProductModal}
              variantActions={variantActions}
            />
          </Container>
        )}
      </div>
    )
  }
}

const DEFAULT_TABLE_SETTINGS = {
  horizontal_attribute: HORIZONTAL_ATTRIBUTE_DEFAULT_VALUE,
  table_images: {
    image_size: IMAGE_SIZE_SMALL,
    image_shape: IMAGE_RADIUS_CIRCLE,
    image_zoom: false,
    zoom_on_hover: true,
    zoom_size: IMAGE_SIZE_MEDIUM,
    zoom_custom_width: '',
    zoom_custom_height: '',
  },
}

let testing
const mapStateToProps = (
  state,
  { bordered, columns, matrix, settings: inputTableSettings, tableKey, sort }
) => {
  const {
    brands: { items: brands },
    session: { entity, settings, user },
  } = state

  // Make sure to check for entity.id to ensure authentication
  const isBrandUser = entity.id && entity.entity_type === 'brand'

  let attributesOrder,
    brand,
    brandContext,
    canOverrideColliOnly,
    customFields,
    customerColliOnly,
    preferColli,
    allTableSettings
  if (isBrandUser) {
    attributesOrder = settings.attributes_order
    brandContext = entity.id
    brand = brands[brandContext] || {}
    customFields = brand.custom_fields || []
    customerColliOnly = false
    allTableSettings = brand.product_table_settings
    canOverrideColliOnly =
      settings.colli_only_check_permission === true &&
      UserHasPermissions(user, 'orders:colli_only_override')
    preferColli = settings.colli_prefer_colli
  } else {
    brandContext = state.brandContext
    brand = brands[brandContext] || { pivot: {}, settings: {} }
    attributesOrder = brand.settings.attributes_order || {}
    customFields = brand.custom_fields || []
    customerColliOnly = brand.pivot.order_colli_only || false
    allTableSettings = brand.product_table_settings
    canOverrideColliOnly = false
    preferColli = brand.settings.colli_prefer_colli
  }

  // We should only use DB settings whenever there is a tableKey. This is because some places, e.g.
  // order documents have their own settings. E.g. just because you have large images as default
  // for product tables, does not mean you want it for documents.

  let tableSettings
  if (inputTableSettings !== undefined || tableKey !== undefined) {
    tableSettings =
      inputTableSettings ||
      createTableSettings(tableKey, matrix, allTableSettings)
  } else {
    if (allTableSettings['default']) {
      tableSettings = { ...allTableSettings['default'].settings }
    }

    if (!tableSettings) {
      tableSettings = { ...defaultSettings }
    }

    // The following is a hack to allow DocumentSettings to set properties on product tables
    if (columns) {
      tableSettings.columns = columns
    }
    if (matrix !== undefined) {
      tableSettings.matrix = matrix
    }
    if (bordered !== undefined) {
      tableSettings.bordered = bordered
    }
    if (sort !== undefined) {
      tableSettings.sort = sort
    }
  }

  return {
    attributesOrder,
    brand,
    brandContext,
    canOverrideColliOnly,
    customFields: customFields.filter(
      c => isCustomFieldVisible(isBrandUser, c) && c.group_type === 'product'
    ),
    customerColliOnly,
    isBrandUser,
    preferColli,
    settings: tableSettings,
    session: state.session,
  }
}

export default connect(mapStateToProps)(ProductTable)

const createTableSettings = memoize((tableKey, matrix, allTableSettings) => {
  let tableSettings
  if (tableKey) {
    let brandDefaultTableSettings = { ...defaultSettings }
    if (allTableSettings['default']) {
      brandDefaultTableSettings = { ...allTableSettings['default'].settings }
    }

    if (allTableSettings[tableKey]) {
      tableSettings = {
        ...brandDefaultTableSettings,
        ...allTableSettings[tableKey].settings,
      }
    } else {
      tableSettings = brandDefaultTableSettings
    }
  }

  if (!tableSettings) {
    tableSettings = {
      ...defaultSettings,
      matrix: matrix !== undefined ? matrix : defaultSettings.matrix,
    }
  }

  return tableSettings
})

const generateTableHeaderCallbackVars = memoize(instance => {
  return [instance]
})

const Container = styled.div`
  background: #fff;
  position: relative;
  z-index: 1;
`

const Loader = () => <LoaderLabel>Loading...</LoaderLabel>

const LoaderLabel = styled.div`
  font-size: 14px;
`

const TabsContainer = styled.div`
  margin-bottom: -1px;
  position: relative;
  z-index: 0;
`
