import Globalize from './globalize'

const Localization = (func, args) => {
  // TODO: fix webpack
  switch (func) {
    case 'getCurrency':
      return 'USD'
      break
    case 'getLocale':
      return 'en'
      break
    case 'getCountry':
      return Globalize.locale().main('localeDisplayNames/territories/' + args)
      break
    default:
      return Globalize
      break
  }
}

export default Localization
