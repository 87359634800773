// @flow

import * as React from 'react'
import { useEffect } from 'react'
import { type Location, type RouterHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import querystring from 'querystring'
import isEqual from 'lodash/isEqual'

import { downloadPublicFile, msg } from '../shared'
import type { Entity, File, FileBankFileRequestOptions, Id } from '../types'
import {
  addToFileBankSelection,
  downloadFromFileBank,
  removeFromFileBankSelection,
} from './api'
import { useFileBankSelection } from './hooks'

export const FileBankContext = React.createContext({})

export const FileBankContainer = styled.div.attrs({ className: 'container' })`
  display: flex;
  font-family: 'Proxima Nova', 'proxima-nova', sans-serif;
`

export const createFileBankPath = (
  brandId: Id,
  publicLinkToken: null | string,
  additionalPath: string = '',
  additionalQueryParams: Object = {}
) => {
  let path = `/file-bank-new/${brandId}${additionalPath}`

  const queryParams = {}
  if (publicLinkToken) {
    queryParams.access = publicLinkToken
  }
  Object.assign(queryParams, additionalQueryParams)

  const queryParamsString = querystring.stringify(queryParams)

  if (queryParamsString.length > 0) {
    path += '?' + queryParamsString
  }

  return path
}

export const useFileBankCommon = ({
  brand,
  history,
  publicLinkToken,
  searchOptions = null,
}: {
  brand: Entity,
  history: RouterHistory,
  publicLinkToken: null | string,
  searchOptions: null | FileBankFileRequestOptions,
}) => {
  const homePath = createFileBankPath(brand.id, publicLinkToken)

  const onSearch = React.useCallback(
    (query: string) => {
      history.push(
        createFileBankPath(brand.id, publicLinkToken, '/search', {
          q: query,
        })
      )
    },
    [brand, publicLinkToken]
  )

  const selectionRequestArgs = React.useMemo(
    () => [brand.id, publicLinkToken],
    [brand, publicLinkToken]
  )
  const [
    selection,
    isFetchingSelection,
    { refresh: refreshSelection, override: overrideSelection },
  ] = useFileBankSelection(selectionRequestArgs)

  const onToggleSelectedFile = React.useCallback(
    (file: File) => {
      const isSelected = selection
        .map(selectedFile => selectedFile.id)
        .includes(file.id)

      const promise = isSelected
        ? removeFromFileBankSelection(brand.id, {
            public_link_token: publicLinkToken,
            file_ids: [file.id],
          })
        : addToFileBankSelection(brand.id, {
            public_link_token: publicLinkToken,
            file_ids: [file.id],
          })

      return promise.then(response => {
        if (!response.error) {
          overrideSelection(response.payload)
          // refreshSelection()
        }
      })
    },
    [brand, publicLinkToken, refreshSelection, selection]
  )

  const onSelectAll = React.useCallback(() => {
    const searchOptionsToUse = { ...searchOptions }
    delete searchOptionsToUse.limit
    delete searchOptionsToUse.page

    return addToFileBankSelection(brand.id, searchOptionsToUse).then(
      response => {
        if (!response.error) {
          refreshSelection()
        }
      }
    )
  }, [brand, searchOptions])

  const onDeselectAll = React.useCallback(
    (files: Array<File>) => {
      const searchOptionsToUse = { ...searchOptions }
      delete searchOptionsToUse.limit
      delete searchOptionsToUse.page

      return removeFromFileBankSelection(brand.id, searchOptionsToUse).then(
        response => {
          if (!response.error) {
            refreshSelection()
          }
        }
      )
    },
    [brand, searchOptions]
  )

  const onClearSelection = React.useCallback(() => {
    return removeFromFileBankSelection(brand.id, {
      public_link_token: publicLinkToken,
      file_ids: selection.map(f => f.id),
    }).then(response => {
      if (!response.error) {
        refreshSelection()
      }
    })
  }, [brand, publicLinkToken, selection])

  const onDownloadFiles = React.useCallback(
    (files: Array<File>) => {
      return downloadFromFileBank(
        brand.id,
        publicLinkToken,
        files.map(file => file.id)
      ).then(response => {
        if (!response.error) {
          if (response.payload.download_type === 'direct') {
            downloadPublicFile(response.payload.public_file)
          } else {
            msg(
              'success',
              'The File Bank files download is being prepared and will be emailed to you soon!'
            )
          }
        }
      })
    },
    [brand, publicLinkToken]
  )

  return {
    homePath,
    onClearSelection,
    onDeselectAll,
    onDownloadFiles,
    onSearch,
    onSelectAll,
    onToggleSelectedFile,
    selection,
  }
}

export const useFileBankAttributeFilters = ({
  attributes,
  brand,
  currentPath,
  currentQueryParams = {},
  history,
  location,
  publicLinkToken,
}: {
  attributes: null | Array<Object>,
  brand: Entity,
  currentPath: string,
  currentQueryParams?: Object,
  history: RouterHistory,
  location: Location,
  publicLinkToken: null | string,
}) => {
  const [attributeFilters, setAttributeFilters] = React.useState({})

  React.useEffect(() => {
    if (!attributes) {
      return
    }

    const parsedQueryParams = querystring.parse(location.search.substr(1))

    const parsedAttributeFilters = attributes.reduce((carry, attribute) => {
      if (parsedQueryParams[attribute.key]) {
        let attributeValues = parsedQueryParams[attribute.key]

        if (!Array.isArray(attributeValues)) {
          attributeValues = [attributeValues]
        }

        attributeValues = attributeValues.map(v => parseInt(v, 10))

        carry[attribute.key] = attributeValues
      }

      return carry
    }, {})

    if (!isEqual(attributeFilters, parsedAttributeFilters)) {
      setAttributeFilters(parsedAttributeFilters)
    }
  }, [attributes, location, attributeFilters, setAttributeFilters])

  const onAttributeFiltersChange = React.useCallback(
    (changedAttributeFilters: Object) => {
      history.push(
        createFileBankPath(brand.id, publicLinkToken, currentPath, {
          ...currentQueryParams,
          ...changedAttributeFilters,
        })
      )
    },
    [brand, currentPath, currentQueryParams, history, publicLinkToken]
  )

  return {
    attributeFilters,
    onAttributeFiltersChange,
  }
}

const PER_PAGE = 24

export const useFileBankPaginator = ({
  brand,
  currentPath,
  history,
  location,
  publicLinkToken,
}: {
  brand: Entity,
  currentPath: string,
  history: RouterHistory,
  location: Location,
  publicLinkToken: string,
}) => {
  const currentQueryParams = new URLSearchParams(location.search.substr(1))
  const page = parseInt(currentQueryParams.get('p') || 1, 10)

  const onPageChange = React.useCallback(
    (newPage: number) => {
      const newQueryParams = {
        ...Object.fromEntries(currentQueryParams.entries()),
        p: newPage,
      }
      history.push(
        createFileBankPath(
          brand.id,
          publicLinkToken,
          currentPath,
          newQueryParams
        )
      )

      // Scroll to top
      window.scroll(0, 0)
    },
    [brand, currentPath, currentQueryParams, history, publicLinkToken]
  )

  const resetPageIfInvalidForSearchResult = React.useCallback(
    searchResult => {
      if (searchResult) {
        const lastPage = Math.ceil(searchResult.total / PER_PAGE)

        if (isNaN(page) || page < 1 || page > lastPage) {
          currentQueryParams.delete('p')
          history.push(
            createFileBankPath(
              brand.id,
              publicLinkToken,
              currentPath,
              Object.fromEntries(currentQueryParams.entries())
            )
          )
        }
      }
    },
    [brand, currentPath, currentQueryParams, history, publicLinkToken]
  )

  return {
    page,
    perPage: PER_PAGE,
    onPageChange,
    resetPageIfInvalidForSearchResult,
  }
}

export const useFileBankDetailOverlay = ({
  history,
  location,
  onPageChange,
  page,
  searchResult,
}: {
  history: RouterHistory,
  location: Location,
  onPageChange: (page: number) => void,
  page: number,
  searchResult: null | { files: Array<Object>, total: number },
}) => {
  const showDetailOverlayFileId = React.useMemo(() => {
    const queryParams = querystring.parse(location.search.substr(1))

    return queryParams.detail ? parseInt(queryParams.detail, 10) : null
  }, [location])

  const onShowDetailOverlay = React.useCallback(
    (file: null | File | string) => {
      if (file === 'first-on-next-page') {
        history.push(
          location.pathname +
            '?' +
            querystring.stringify({
              ...querystring.parse(location.search.substr(1)),
              p: page + 1,
              detail: 'first-on-page',
            })
        )
        return
      }
      if (file === 'last-on-previous-page') {
        history.push(
          location.pathname +
            '?' +
            querystring.stringify({
              ...querystring.parse(location.search.substr(1)),
              p: page - 1,
              detail: 'last-on-page',
            })
        )
        return
      }

      const queryParams = querystring.parse(location.search.substr(1))

      if (file) {
        queryParams.detail = file.id
      } else {
        delete queryParams.detail
      }

      const queryParamsString = querystring.stringify(queryParams)

      let path = location.pathname
      if (queryParamsString.length > 0) {
        path += '?' + queryParamsString
      }

      history.push(path)
    },
    [history, location]
  )

  useEffect(() => {
    const queryParams = querystring.parse(location.search.substr(1))

    if (searchResult && searchResult.files.length > 0) {
      if (queryParams.detail === 'first-on-page') {
        history.replace(
          location.pathname +
            '?' +
            querystring.stringify({
              ...querystring.parse(location.search.substr(1)),
              detail: searchResult.files[0].id,
            })
        )
      }
      if (queryParams.detail === 'last-on-page') {
        history.replace(
          location.pathname +
            '?' +
            querystring.stringify({
              ...querystring.parse(location.search.substr(1)),
              detail: searchResult.files[searchResult.files.length - 1].id,
            })
        )
      }
    }
  }, [searchResult])

  return { showDetailOverlayFileId, onShowDetailOverlay }
}

export const useFileBankSort = ({
  brand,
  currentPath,
  history,
  location,
  publicLinkToken,
  defaultSort,
}: {
  brand: Entity,
  currentPath: string,
  history: RouterHistory,
  location: Location,
  publicLinkToken: string,
  defaultSort: string,
}) => {
  const currentQueryParams = new URLSearchParams(location.search.substr(1))
  const sort = currentQueryParams.get('sort') || defaultSort

  const onSortChange = React.useCallback(
    (sort: null | string) => {
      const newQueryParams = {
        ...Object.fromEntries(currentQueryParams.entries()),
        sort,
      }
      if (sort === defaultSort) {
        delete newQueryParams.sort
      }

      history.push(
        createFileBankPath(
          brand.id,
          publicLinkToken,
          currentPath,
          newQueryParams
        )
      )
    },
    [brand, currentPath, currentQueryParams, history, publicLinkToken]
  )

  const splitSortToPropertyAndDirection = sort => {
    const sortSplit = sort.split('__')
    return sortSplit.length === 2 ? sortSplit : defaultSort.split('__')
  }

  return {
    sort,
    onSortChange,
    splitSortToPropertyAndDirection,
  }
}

export const fileBankLinkCss = css`
  font-family: 'Proxima Nova', 'proxima-nova', sans-serif;
  color: black;
  background: none;
  border: none;
  padding: 0;
  margin: 0;

  &:hover,
  &:focus {
    color: black;
    text-decoration: underline;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    color: #64676a;
    text-decoration: none;
  }
`

export const isCloudinaryImageAvailable = (mime: string) =>
  mime.startsWith('image/') || mime === 'application/pdf'

export const isCloudinaryVideoAvailable = (mime: string) =>
  mime.startsWith('video/')

export const filesSortOptions = [
  {
    value: 'product_name_then_file_name',
    label: 'Product name (A to Z), then file name (A to Z)',
  },
  {
    value: 'upload_date_desc_then_product_name',
    label: 'Upload date (recent first), then product name (A to Z)',
  },
]

export const illustrationSizes = {
  'full-width': { width: 955, height: 392 },
  'half-width': { width: 465, height: 191 },
}
