import * as React from 'react'
import ReactDOM from 'react-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'

import dragHandleImage from '../../assets/images/reports-drag-handle.svg'

const ColumnsManager = ({ columns, columnOptions, onChange }) => {
  const portalRef = React.useMemo(() => React.createRef(), [])
  React.useEffect(() => {
    portalRef.current = document.createElement('div')
    portalRef.current.classList.add('columns-manager-list-portal')
    document.body.appendChild(portalRef.current)

    return function cleanup() {
      document.body.removeChild(portalRef.current)
    }
  }, [])

  // add columns missing from the columns array
  // this can for instance happen if a new column has been added to
  // the available options
  React.useEffect(() => {
    const currentColumnKeys = columns.map(column => column.key)

    const addColumns = []
    let nextIndex = columns.length
    for (let columnOption of columnOptions) {
      if (!currentColumnKeys.includes(columnOption.value)) {
        addColumns.push({
          key: columnOption.value,
          show: columnOption.toggleable === false ? true : false,
          sortOrder: nextIndex,
        })

        nextIndex++
      }
    }

    if (addColumns.length > 0) {
      onChange([...columns, ...addColumns])
    }
  }, [columns, columnOptions, onChange])

  const columnsWithOptions = React.useMemo(() => {
    const columnOptionsByKey = keyBy(columnOptions, 'value')

    const columnsWithOptions = sortBy(columns, 'sortOrder')
      .map((column, i) => ({
        options: columnOptionsByKey[column.key],
        column,
      }))
      .filter(columnWithOptions => columnWithOptions.options !== undefined)

    return columnsWithOptions
  }, [columns, columnOptions])

  const onDragEnd = React.useCallback(
    result => {
      // dropped outside the list
      if (!result.destination) {
        return
      }

      const after = reorder(
        columns,
        result.source.index,
        result.destination.index
      )

      onChange(reorder(columns, result.source.index, result.destination.index))
    },
    [onChange, columns]
  )

  const toggleChecked = React.useCallback(
    columnBeingToggled => {
      onChange(
        columns.map(column => {
          return column.key === columnBeingToggled.key
            ? { ...column, show: !column.show }
            : column
        })
      )
    },
    [onChange, columns]
  )

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="columns-manager">
          {droppableProvided => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              {columnsWithOptions.map(({ column, options }, index) => (
                <Draggable
                  key={column.key}
                  draggableId={column.key}
                  index={index}
                >
                  {(draggableProvided, draggableSnapshot) => (
                    <ColumnItem
                      column={column}
                      options={options}
                      draggableProvided={draggableProvided}
                      draggableSnapshot={draggableSnapshot}
                      onCheckedToggle={() => toggleChecked(column)}
                      portalRef={portalRef}
                      sortable
                    />
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default ColumnsManager

const GroupLabel = styled.span`
  padding: 10px 10px 0 10px !important;
  display: flex;
  font-weight: bold;
`

const ColumnItem = ({
  column,
  options,
  draggableProvided,
  draggableSnapshot,
  onCheckedToggle,
  portalRef,
  sortable,
}) => {
  const id = `columns-manager-${column.key}`
  const inPortal = draggableSnapshot.isDragging

  const child = (
    <ItemContainer
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      inPortal={inPortal}
    >
      {sortable && (
        <ItemHandle {...draggableProvided.dragHandleProps}>
          <img src={dragHandleImage} alt="Drag icon" title="Drag to reorder" />
        </ItemHandle>
      )}

      <ItemCheckboxContainer>
        <div className="checkbox check-success" style={{ padding: '3px 0' }}>
          <input
            id={id}
            disabled={options.toggleable === false}
            onChange={() => {
              onCheckedToggle(column)
            }}
            checked={column.show}
            type="checkbox"
          />
          <label htmlFor={id} style={{ marginBottom: 0 }}>
            {options.label}{' '}
            {options.group === 'Actions' ? (
              <span className="label label-default label-xxs">Action</span>
            ) : (
              ''
            )}
          </label>
        </div>
      </ItemCheckboxContainer>
    </ItemContainer>
  )

  // Putting the dragged item into portal so it works also in modals/
  // See https://stackoverflow.com/questions/54982182/react-beautiful-dnd-drag-out-of-position-problem
  return inPortal ? ReactDOM.createPortal(child, portalRef.current) : child
}

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ItemHandle = styled.div`
  padding: 4px;
  margin-right: 7px;
  flex: 0 0 auto;
`

const ItemCheckboxContainer = styled.div`
  flex: 1;
`

function reorder(list, startIndex, endIndex) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result.map((item, index) => {
    item.sortOrder = index
    return item
  })
}
