/* @flow */

import { useEffect, useState } from 'react'

import { createApiHook, createDataCache, quickCreateHook } from '../hooks'

import {
  calculateOrdersDropDeliveriesSyncStatus,
  getAssortments,
  getAttributeGroups,
  getAttributes,
  getCostPriceSets,
  getImageImports,
  getImageImportView,
  getProductsAttributes,
  getProduct,
  getProductGroups,
  getProductInsights,
  getProductsListV2,
  getSegmentationRules,
  getSegmentationRule,
  getTags,
  getVariant,
  getVariants,
  getLaunchEvents,
  getLaunchEvent,
} from './api'
import type {
  Assortment,
  Attribute,
  DataTableAttribute,
  Id,
  Product,
  ProductElastic,
  ProductGroup,
  Tag,
  Variant,
} from '../types'

export const useProductsList = createApiHook<{
  products: Array<ProductElastic>,
  total: number,
}>(
  (options?: Object) => {
    return getProductsListV2(options).then(response => {
      if (!response.error) {
        return {
          entity: {
            products: response.payload.products,
            total: response.payload.total,
          },
        }
      } else {
        return response
      }
    })
  },
  { products: [], total: 0 }
)

export const useProductsAttributes = () => {
  const [state, setState] = useState<{
    attributes: Array<DataTableAttribute>,
    isFetching: boolean,
  }>({
    attributes: [],
    isFetching: false,
  })

  useEffect(() => {
    setState(s => ({
      ...s,
      isFetching: true,
    }))

    getProductsAttributes().then(response => {
      if (!response.error) {
        setState({
          isFetching: false,
          attributes: response.payload.attributes,
        })
      } else {
        setState(s => ({
          ...s,
          isFetching: false,
        }))
      }
    })
  }, [])

  return [state.attributes, state.isFetching]
}

const fetchProductForHook =
  (productOnly: boolean) => (productId: Id, options?: Object) => {
    return getProduct(productId, options).then(response => {
      if (!response.error) {
        return {
          entity: productOnly ? response.payload.product : response.payload,
        }
      } else {
        return response
      }
    })
  }

export const useProduct = createApiHook<Product | null>(
  fetchProductForHook(true)
)

type ProductPayload = {
  product: Product,
  generic_b2c_synced: boolean,
  shopify_synced: boolean,
  woocommerce_synced: boolean,
}

export const useProductPayload = createApiHook<ProductPayload | null>(
  fetchProductForHook(false)
)

// SEGMENTATION

export const useSegmentationRules = createApiHook(() => {
  return getSegmentationRules().then(response => {
    if (!response.error) {
      return {
        entity: response.payload.segmentation_rules,
      }
    }

    return response
  })
}, [])

export const useSegmentationRule = createApiHook((id: Id) => {
  return getSegmentationRule(id).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.segmentation_rules,
      }
    }

    return response
  })
})

// VARIANTS
const fetchVariantForHook =
  (variantOnly: boolean) => (variantId: Id, options?: Object) => {
    return getVariant(variantId, options).then(response => {
      if (!response.error) {
        return {
          entity: variantId ? response.payload.variant : response.payload,
        }
      } else {
        return response
      }
    })
  }

export const useVariant = createApiHook<Variant | null>(
  fetchVariantForHook(true)
)

export const useVariants = createApiHook<{
  variants: Array<Variant>,
}>(
  (options?: Object) =>
    getVariants(options).then(response => {
      if (!response.error) {
        return {
          entity: {
            variants: response.payload,
          },
        }
      } else {
        return response
      }
    }),
  { variants: [] }
)

// ATTRIBUTES

const fetchAttributesForHook = (options?: Object) =>
  getAttributes(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.attributes,
      }
    } else {
      return response
    }
  })

export const useAttributes = createApiHook<Array<Attribute>>(
  fetchAttributesForHook,
  []
)

const {
  cache: { clearCache: clearAttributeCache },
  hook: useCachedAttributes,
} = createDataCache(useAttributes)

export { clearAttributeCache, useCachedAttributes }

// PRODUCT GROUPS

const fetchProductGroupsForHook = (options?: Object) =>
  getProductGroups(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload,
      }
    } else {
      return response
    }
  })

export const useProductGroups = createApiHook<Array<ProductGroup>>(
  fetchProductGroupsForHook,
  []
)

const {
  cache: { clearCache: clearProductGroupCache },
  hook: useCachedProductGroups,
} = createDataCache(useProductGroups)

export { clearProductGroupCache, useCachedProductGroups }

// TAGS

const fetchTagsForHook = (options?: Object) =>
  getTags(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.tags,
      }
    } else {
      return response
    }
  })

export const useTags = createApiHook<Array<Tag>>(fetchTagsForHook, [])

const {
  cache: { clearCache: clearTagCache },
  hook: useCachedTags,
} = createDataCache(useTags)

export { clearTagCache, useCachedTags }

// INSIGHTS

const {
  hook: useProductInsights,
  hookCached: useCachedProductInsights,
  clearCache: clearProductInsightsCache,
} = quickCreateHook<Array<Assortment>>(getProductInsights, 'report', null)

export {
  useProductInsights,
  useCachedProductInsights,
  clearProductInsightsCache,
}

// ASSORTMENTS

const {
  hook: useAssortments,
  hookCached: useCachedAssortments,
  clearCache: clearAssortmentsCache,
} = quickCreateHook<Array<Assortment>>(getAssortments, 'assortments', [])

export { useAssortments, useCachedAssortments, clearAssortmentsCache }

// ATTRIBUTE GROPUS

const {
  hook: useAttributeGroups,
  hookCached: useCachedAttributeGroups,
  clearCache: clearAttributeGroupsCache,
} = quickCreateHook<Array<Assortment>>(
  getAttributeGroups,
  'attribute_groups',
  []
)

export {
  useAttributeGroups,
  useCachedAttributeGroups,
  clearAttributeGroupsCache,
}

// COST PRICES

const { hook: useCostPriceSets } = quickCreateHook<Array<Assortment>>(
  getCostPriceSets,
  'cost_price_sets',
  []
)

export { useCostPriceSets }

// LAUNCH EVENTS

const {
  hook: useLaunchEvents,
  hookCached: useCachedLaunchEvents,
  clearCache: clearLaunchEventsCache,
} = quickCreateHook<Array<Assortment>>(getLaunchEvents, 'launch_event', [])

export { useLaunchEvents, useCachedLaunchEvents, clearLaunchEventsCache }

const {
  hook: useLaunchEvent,
  hookCached: useCachedLaunchEvent,
  clearCache: clearLaunchEventCache,
} = quickCreateHook<Array<Assortment>>(getLaunchEvent, null)

export { useLaunchEvent, useCachedLaunchEvent, clearLaunchEventCache }

// IMAGE IMPORTS

const { hook: useImageImports } = quickCreateHook(getImageImports, null, {
  image_import_jobs: [],
  total: 0,
})

export { useImageImports }

const { hook: useImageImportView } = quickCreateHook(
  getImageImportView,
  'image_import',
  null
)

export { useImageImportView }

const { hook: useOrdersDropDeliveriesSyncStatus } = quickCreateHook(
  calculateOrdersDropDeliveriesSyncStatus,
  'status',
  []
)

export { useOrdersDropDeliveriesSyncStatus }
