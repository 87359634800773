/* @flow */

import * as React from 'react'
import { PureComponent } from 'react'
import { Field, type FieldProps } from 'formik'
import { FormControl } from 'react-bootstrap'
import get from 'lodash/get'
import styled from 'styled-components'
import Globalize from '../../modules/globalize'
import { SelectWrapper } from '../Forms'
import { ErrorLabel } from '../Forms'

import type { Currency } from '../../../app/types'
import { SessionContext } from '../../../app/shared'
import { parseNumericString } from '../../utilities'

type Props = {
  currency: Currency,
  markOnFocus?: boolean,
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void,
  onFocus?: (e: SyntheticEvent<HTMLInputElement>) => void,
  onChange: (value: string) => void,
  rightAlign?: boolean,
  value: string,
}

export const PriceInput = React.forwardRef(
  (
    props: {
      ...$Diff<
        Props,
        {
          onChange: (value: string) => void,
        }
      >,
      onChange?: (value: string) => void,
    },
    ref
  ) => <Field innerRef={ref} component={renderPriceInput} {...props} />
)

export const renderPriceInput = ({
  field: { onBlur, name, onChange, value },
  form: { setFieldValue, touched, errors },
  vat,
  onChange: propsOnChange,
  innerRef,
  ...props
}: FieldProps & Props) => {
  return (
    <div>
      <PriceControl
        forwardedRef={innerRef}
        onBlur={onBlur}
        onChange={e => {
          setFieldValue(name, e.target.value)

          if (propsOnChange) {
            e.persist()

            propsOnChange(e)
          }
        }}
        name={name}
        value={value}
        vat={vat}
        {...props}
      />

      {get(touched, name) && get(errors, name) && (
        <ErrorLabel>{get(errors, name)}</ErrorLabel>
      )}
    </div>
  )
}

export default PriceInput

type State = {
  inFocus: boolean,
}

export class PriceControl extends PureComponent<Props, State> {
  state = {
    inFocus: false,
    inclVat: 'ex',
  }

  static contextType = SessionContext

  constructor(props, context) {
    super(props)

    this._inputRef = props.forwardedRef || React.createRef(null)

    if (
      context &&
      context.settings &&
      context.settings.default_b2c_price_fields_incl_vat === true
    ) {
      this.state.inclVat = 'incl'
    }
  }

  render() {
    const {
      forwardedRef,
      currency,
      value: immutableValue,
      onBlur,
      onFocus,
      rightAlign,
      style = {},
      ...inputProps
    } = this.props

    let value = this.props.value
    if (!this.state.inFocus && !isNaN(parseFloat(value))) {
      // we can only parseNumericString when displaying the value out of focus.
      // see comment in _onBlur
      value = parseNumericString(value)

      if (this.props.vat > 0 && this.state.inclVat === 'incl') {
        value = value * (1 + this.props.vat / 100)
      }

      if (currency) {
        value = Globalize.currencyFormatter(currency)(parseFloat(value))
      }
    }

    const useStyle = { ...style }
    if (rightAlign) {
      useStyle['text-align'] = 'right'
    }

    return (
      <InputContainer>
        <FormControl
          onBlur={this._onBlur}
          onFocus={this._onFocus}
          inputRef={this._inputRef}
          type="text"
          style={useStyle}
          value={value}
          {...inputProps}
        />

        {this.props.vat > 0 && (
          <VatContainer>
            <SelectWrapper
              clearable={false}
              onChange={this._onVatChange}
              options={vatOptions}
              simpleValue
              value={this.state.inclVat}
            />
          </VatContainer>
        )}
      </InputContainer>
    )
  }

  _onBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    e.persist()

    // we cannot parseNumericString here. some places, like in PO accounting,
    // we make external calculations whenever a field value change, and we should
    // not change the input value as the user is typing. therefore, changing
    // commas to dots should be a task for the API, but the frontend

    if (this.props.vat > 0 && this.state.inclVat === 'incl') {
      let value = this.props.value

      if (!isNaN(parseFloat(value))) {
        value = value / (1 + this.props.vat / 100)

        this.props.onChange({
          target: {
            value,
          },
        })
      }
    }

    this.setState({
      inFocus: false,
    })

    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
  }

  _onFocus = (e: SyntheticEvent<HTMLInputElement>) => {
    e.persist()

    if (this.props.markOnFocus && this._inputRef.current) {
      setTimeout(() => {
        this._inputRef.current.setSelectionRange(
          0,
          this._inputRef.current.value.length
        )
      }, 100)
    }

    this.setState({
      inFocus: true,
    })

    if (this.props.onFocus) {
      this.props.onFocus(e)
    }
  }

  _onVatChange = newVat => {
    let value = this.props.value

    if (!isNaN(parseFloat(value))) {
      if (this.state.inclVat === 'incl' && newVat === 'ex') {
        value = value * (1 + this.props.vat / 100)

        this.props.onChange({
          target: {
            value,
          },
        })
      } else if (this.state.inclVat === 'ex' && newVat === 'incl') {
        value = value / (1 + this.props.vat / 100)

        this.props.onChange({
          target: {
            value,
          },
        })
      }
    }

    this.setState({
      inclVat: newVat,
    })
  }
}

const InputContainer = styled.div`
  display: flex;
`

const VatContainer = styled.div`
  margin-left: 5px;
  width: 100px;
`

const vatOptions = [
  { value: 'incl', label: 'Incl VAT' },
  { value: 'ex', label: 'Ex VAT' },
]
