import { CALL_API } from '../../../infrastructure/middleware/api'

export const GET_CUSTOM_FIELDS_REQUEST = 'GET_CUSTOM_FIELDS_REQUEST'
export const GET_CUSTOM_FIELDS_SUCCESS = 'GET_CUSTOM_FIELDS_SUCCESS'
export const GET_CUSTOM_FIELDS_FAILURE = 'GET_CUSTOM_FIELDS_FAILURE'
export const getCustomFields = (options = {}) => ({
  [CALL_API]: {
    types: [
      GET_CUSTOM_FIELDS_REQUEST,
      GET_CUSTOM_FIELDS_SUCCESS,
      GET_CUSTOM_FIELDS_FAILURE,
    ],
    method: 'GET',
    endpoint: '/custom-fields',
    query: {
      ...options,
    },
  },
})

export const DELETE_CUSTOM_FIELD_REQUEST = 'DELETE_CUSTOM_FIELD_REQUEST'
export const DELETE_CUSTOM_FIELD_SUCCESS = 'DELETE_CUSTOM_FIELD_SUCCESS'
export const DELETE_CUSTOM_FIELD_FAILURE = 'DELETE_CUSTOM_FIELD_FAILURE'
export const deleteCustomField = id => ({
  [CALL_API]: {
    types: [
      DELETE_CUSTOM_FIELD_REQUEST,
      DELETE_CUSTOM_FIELD_SUCCESS,
      DELETE_CUSTOM_FIELD_FAILURE,
    ],
    method: 'DELETE',
    endpoint: `/custom-fields/${id}`,
  },
})
