/* @flow */

import {
  getAccountingSettings,
  getAccountingSettingsV2,
  getAccountingYears,
  getAgencies,
  getComputers,
  getCountrySettings,
  getCountrySettingsAdminList,
  getCommissionPolicies,
  getCommissionPolicy,
  getDocumentSettings,
  getExchangeRates,
  getImageFilters,
  getExternalDocumentExchanges,
  getInternalCompanies,
  getInternalCompanyRoutingSettings,
  getInventoryAlertPolicies,
  getInventoryAlertPolicy,
  getNumberSeries,
  getOwnEntitySettings,
  getPersistedAttributes,
  getPriceGroup,
  getPriceGroups,
  getPrinters,
  getPrinterSettings,
  getPrinterUsers,
  getProductLocales,
  getReplenishmentSettings,
  getWebshopNavigationLinks,
  getWebshopPages,
} from './api'
import { createApiHook, createDataCache, quickCreateHook } from '../hooks'
import type { ProductLocale } from '../types'

export const useCountrySettings = createApiHook(options => {
  return getCountrySettings(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.settings,
      }
    } else {
      return response
    }
  })
}, [])

const {
  hook: useCountrySettingsAdminList,
  hookCached: useCachedCountrySettingsAdminList,
  clearCache: clearCountrySettingsAdminListCache,
} = quickCreateHook(getCountrySettingsAdminList, 'settings', [])

export {
  useCountrySettingsAdminList,
  useCachedCountrySettingsAdminList,
  clearCountrySettingsAdminListCache,
}

const {
  hook: usePersistedAttributes,
  hookCached: useCachedPersistedAttributes,
  clearCache: clearPersistedAttributesCache,
} = quickCreateHook(getPersistedAttributes, 'attributes', [])

export {
  usePersistedAttributes,
  useCachedPersistedAttributes,
  clearPersistedAttributesCache,
}

export const useProductLocales = createApiHook<Array<ProductLocale>>(
  (options?: Object) =>
    getProductLocales(options).then(response => {
      if (!response.error) {
        return {
          entity: response.payload.product_locales,
        }
      } else {
        return response
      }
    }),
  []
)

const {
  cache: { clearCache: clearProductLocalesCache },
  hook: useCachedProductLocales,
} = createDataCache(useProductLocales)

export { clearProductLocalesCache, useCachedProductLocales }

const getExchangeRatesForHook = (options?: Object) =>
  getExchangeRates(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload,
      }
    } else {
      return response
    }
  })

export const useExchangeRates = createApiHook<Array<ExchangeRate>>(
  getExchangeRatesForHook,
  []
)

const {
  cache: { clearCache: clearExchangeRateCache },
  hook: useCachedExchangeRates,
} = createDataCache(useExchangeRates)

export { clearExchangeRateCache, useCachedExchangeRates }

const getAccountingSettingsForHook = () =>
  getAccountingSettings().then(response => {
    if (!response.error) {
      return {
        entity: response.payload.settings,
      }
    } else {
      return response
    }
  })

const useAccountingSettings = createApiHook(getAccountingSettingsForHook)

export { useAccountingSettings }

const {
  hook: useDocumentSettings,
  hookCached: useCachedDocumentSettings,
  clearCache: clearDocumentSettingsCache,
} = quickCreateHook(getDocumentSettings, 'document_settings', [])

export {
  useDocumentSettings,
  useCachedDocumentSettings,
  clearDocumentSettingsCache,
}

const {
  hook: useWebshopPages,
  hookCached: useCachedWebshopPages,
  clearCache: clearWebshopPages,
} = quickCreateHook(getWebshopPages, null, [])

export { useWebshopPages, useCachedWebshopPages, clearWebshopPages }

const {
  hook: useNumberSeries,
  hookCached: useCachedNumberSeries,
  clearCache: clearNumberSeriesCache,
} = quickCreateHook(getNumberSeries, 'number_series', [])

export { useNumberSeries, useCachedNumberSeries, clearNumberSeriesCache }

const {
  hook: useWebshopNavigationLinks,
  hookCached: useCachedWebshopNavigationLinks,
  clearCache: clearWebshopNavigationLinks,
} = quickCreateHook(getWebshopNavigationLinks, null, [])

export {
  useWebshopNavigationLinks,
  useCachedWebshopNavigationLinks,
  clearWebshopNavigationLinks,
}

const {
  hook: useAgencies,
  hookCached: useCachedAgencies,
  clearCache: clearAgenciesCache,
} = quickCreateHook(getAgencies, 'agencies', [])

export { useAgencies, useCachedAgencies, clearAgenciesCache }

const {
  hook: usePriceGroups,
  hookCached: useCachedPriceGroups,
  clearCache: clearPriceGroupsCache,
} = quickCreateHook(getPriceGroups, 'price_groups', [])

export { usePriceGroups, useCachedPriceGroups, clearPriceGroupsCache }

const {
  hook: usePriceGroup,
  hookCached: useCachedPriceGroup,
  clearCache: clearPriceGroupCache,
} = quickCreateHook(getPriceGroup, 'price_group', null)

export { usePriceGroup, useCachedPriceGroup, clearPriceGroupCache }

const {
  hook: useImageFilters,
  hookCached: useCachedImageFilters,
  clearCache: clearImageFiltersCache,
} = quickCreateHook(getImageFilters, 'image_filters', [])

export { useImageFilters, useCachedImageFilters, clearImageFiltersCache }

const {
  hook: useReplenishmentSettings,
  hookCached: useCachedReplenishmentSettings,
  clearCache: clearReplenishmentSettingsCache,
} = quickCreateHook(getReplenishmentSettings, 'settings', null)

export {
  useReplenishmentSettings,
  useCachedReplenishmentSettings,
  clearReplenishmentSettingsCache,
}

const {
  hook: useInventoryAlertPolicies,
  hookCached: useCachedInventoryAlertPolicies,
  clearCache: clearInventoryAlertPoliciesCache,
} = quickCreateHook(getInventoryAlertPolicies, 'policies', [])

export {
  useInventoryAlertPolicies,
  useCachedInventoryAlertPolicies,
  clearInventoryAlertPoliciesCache,
}

const {
  hook: useInventoryAlertPolicy,
  hookCached: useCachedInventoryAlertPolicy,
  clearCache: clearInventoryAlertPolicyCache,
} = quickCreateHook(getInventoryAlertPolicy, 'policy', null)

export {
  useInventoryAlertPolicy,
  useCachedInventoryAlertPolicy,
  clearInventoryAlertPolicyCache,
}

// PRINTERS

const {
  hook: usePrinters,
  hookCached: useCachedPrinters,
  clearCache: clearPrintersCache,
} = quickCreateHook(getPrinters, 'printers', [])

export { usePrinters, useCachedPrinters, clearPrintersCache }

const {
  hook: usePrinterUsers,
  hookCached: useCachedPrinterUsers,
  clearCache: clearPrinterUsersCache,
} = quickCreateHook(getPrinterUsers, 'printer_users', [])

export { usePrinterUsers, useCachedPrinterUsers, clearPrinterUsersCache }

const {
  hook: usePrinterSettings,
  hookCached: useCachedPrinterSettings,
  clearCache: clearPrinterSettingsCache,
} = quickCreateHook(getPrinterSettings, 'settings', [])

export {
  usePrinterSettings,
  useCachedPrinterSettings,
  clearPrinterSettingsCache,
}

const {
  hook: useComputers,
  hookCached: useCachedComputers,
  clearCache: clearComputersCache,
} = quickCreateHook(getComputers, 'computers', [])

export { useComputers, useCachedComputers, clearComputersCache }

const {
  hook: useAccountingYears,
  hookCached: useCachedAccountingYears,
  clearCache: clearAccountingYearsCache,
} = quickCreateHook(getAccountingYears, 'accounting_years', [], {
  v2: true,
})

export {
  useAccountingYears,
  useCachedAccountingYears,
  clearAccountingYearsCache,
}

const {
  hook: useOwnEntitySettings,
  hookCached: useCachedOwnEntitySettings,
  clearCache: clearOwnEntitySettingsCache,
} = quickCreateHook(getOwnEntitySettings, null, null, {
  v2: true,
})

export {
  useOwnEntitySettings,
  useCachedOwnEntitySettings,
  clearOwnEntitySettingsCache,
}

const {
  hook: useInternalCompanies,
  hookCached: useCachedInternalCompanies,
  clearCache: clearInternalCompaniesCache,
} = quickCreateHook(getInternalCompanies, 'internal_companies', [], {
  v2: true,
})

export {
  useInternalCompanies,
  useCachedInternalCompanies,
  clearInternalCompaniesCache,
}

const {
  hook: useInternalCompanyRoutingSettings,
  hookCached: useCachedInternalCompanyRoutingSettings,
  clearCache: clearInternalCompanyRoutingSettingsCache,
} = quickCreateHook(
  getInternalCompanyRoutingSettings,
  'internal_company_routing_settings',
  null,
  {
    v2: true,
  }
)

export {
  useInternalCompanyRoutingSettings,
  useCachedInternalCompanyRoutingSettings,
  clearInternalCompanyRoutingSettingsCache,
}

const {
  hook: useExternalDocumentExchanges,
  hookCached: useCachedExternalDocumentExchanges,
  clearCache: clearExternalDocumentExchangesCache,
} = quickCreateHook(
  getExternalDocumentExchanges,
  'external_document_exchanges',
  [],
  {
    v2: true,
  }
)

export {
  useExternalDocumentExchanges,
  useCachedExternalDocumentExchanges,
  clearExternalDocumentExchangesCache,
}

const {
  hook: useAccountingSettingsV2,
  hookCached: useCachedAccountingSettingsV2,
  clearCache: clearAccountingSettingsV2Cache,
} = quickCreateHook(getAccountingSettingsV2, 'settings', [], {
  v2: true,
})

export {
  useAccountingSettingsV2,
  useCachedAccountingSettingsV2,
  clearAccountingSettingsV2Cache,
}

const {
  hook: useCommissionPolicies,
  hookCached: useCachedCommissionPolicies,
  clearCache: clearCommissionPoliciesCache,
} = quickCreateHook(getCommissionPolicies, 'policies', [], {
  v2: true,
})

export {
  useCommissionPolicies,
  useCachedCommissionPolicies,
  clearCommissionPoliciesCache,
}

const {
  hook: useCommissionPolicy,
  hookCached: useCachedCommissionPolicy,
  clearCache: clearCommissionPolicyCache,
} = quickCreateHook(getCommissionPolicy, 'policy', null, {
  v2: true,
})

export {
  useCommissionPolicy,
  useCachedCommissionPolicy,
  clearCommissionPolicyCache,
}
