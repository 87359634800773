/* @flow */

import React from 'react'
import styled from 'styled-components'

export const SecondaryMenuHoverContainer = styled.div`
  align-items: center;
  color: white;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;

  &:hover {
    background: ${({ type, useNewColors }) => {
      if (type !== 'main') {
        return '#000'
      }

      return useNewColors ? '#000' : '#344854'
    }};
  }

  > a,
  a:hover {
    color: white;
  }
`
