/* @flow */

import * as React from 'react'

import { TrackError } from '../../../infrastructure/exceptions/raven'

class WarehouseErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    try {
      if (process.env.NODE_ENV !== 'production') {
        console.log(error)
      }

      TrackError(error)
    } catch (ignoredError) {}
  }

  reload = () => {
    window.location.reload()
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          An error occurred.{' '}
          <button
            type="button"
            className="btn btn-success"
            onClick={this.reload}
          >
            Reload app
          </button>
        </div>
      )
    }

    return this.props.children
  }
}

export default WarehouseErrorBoundary
