/* @flow */

import {
  CALL_API,
  CALL_API_BATCH,
} from '../../../infrastructure/middleware/api'

import type { Id } from '../../types'

export const ADD_ORDER_LINES_REQUEST = 'ADD_ORDER_LINES_REQUEST'
export const ADD_ORDER_LINES_SUCCESS = 'ADD_ORDER_LINES_SUCCESS'
export const ADD_ORDER_LINES_FAILURE = 'ADD_ORDER_LINES_FAILURE'
export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST'
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS'
export const CANCEL_ORDER_FAILURE = 'CANCEL_ORDER_FAILURE'
export const CHANGE_ORDER_CONTEXT = 'CHANGE_ORDER_CONTEXT'
export const CHANGE_ORDER_DISCOUNT_REQUEST = 'CHANGE_ORDER_DISCOUNT_REQUEST'
export const CHANGE_ORDER_DISCOUNT_SUCCESS = 'CHANGE_ORDER_DISCOUNT_SUCCESS'
export const CHANGE_ORDER_DISCOUNT_FAILURE = 'CHANGE_ORDER_DISCOUNT_FAILURE'
export const CHANGE_ORDER_EXPECTED_DELIVERY_REQUEST =
  'CHANGE_ORDER_EXPECTED_DELIVERY_REQUEST'
export const CHANGE_ORDER_EXPECTED_DELIVERY_SUCCESS =
  'CHANGE_ORDER_EXPECTED_DELIVERY_SUCCESS'
export const CHANGE_ORDER_EXPECTED_DELIVERY_FAILURE =
  'CHANGE_ORDER_EXPECTED_DELIVERY_FAILURE'
export const CHANGE_ORDER_STATUS_REQUEST = 'CHANGE_ORDER_STATUS_REQUEST'
export const CHANGE_ORDER_STATUS_SUCCESS = 'CHANGE_ORDER_STATUS_SUCCESS'
export const CHANGE_ORDER_STATUS_FAILURE = 'CHANGE_ORDER_STATUS_FAILURE'
export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST'
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS'
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE'
export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE'
export const GET_ORDERS_LIST_REQUEST = 'GET_ORDERS_LIST_REQUEST'
export const GET_ORDERS_LIST_SUCCESS = 'GET_ORDERS_LIST_SUCCESS'
export const GET_ORDERS_LIST_FAILURE = 'GET_ORDERS_LIST_FAILURE'
export const GET_ORDER_LINES_REQUEST = 'GET_ORDER_LINES_REQUEST'
export const GET_ORDER_LINES_SUCCESS = 'GET_ORDER_LINES_SUCCESS'
export const GET_ORDER_LINES_FAILURE = 'GET_ORDER_LINES_FAILURE'
export const LOCK_ORDER_REQUEST = 'LOCK_ORDER_REQUEST'
export const LOCK_ORDER_SUCCESS = 'LOCK_ORDER_SUCCESS'
export const LOCK_ORDER_FAILURE = 'LOCK_ORDER_FAILURE'
export const SPLIT_ORDER_REQUEST = 'SPLIT_ORDER_REQUEST'
export const SPLIT_ORDER_SUCCESS = 'SPLIT_ORDER_SUCCESS'
export const SPLIT_ORDER_FAILURE = 'SPLIT_ORDER_FAILURE'
export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST'
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS'
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE'
export const ORDERS_TABLE_PARAMETERS_REQUEST = 'ORDERS_TABLE_PARAMETERS_REQUEST'
export const ORDERS_TABLE_PARAMETERS_SUCCESS = 'ORDERS_TABLE_PARAMETERS_SUCCESS'
export const ORDERS_TABLE_PARAMETERS_FAILURE = 'ORDERS_TABLE_PARAMETERS_FAILURE'
export const REMOVE_ORDER_LINE_REQUEST = 'REMOVE_ORDER_LINE_REQUEST'
export const REMOVE_ORDER_LINE_SUCCESS = 'REMOVE_ORDER_LINE_SUCCESS'
export const REMOVE_ORDER_LINE_FAILURE = 'REMOVE_ORDER_LINE_FAILURE'
export const REMOVE_ORDER_LINES_REQUEST = 'REMOVE_ORDER_LINES_REQUEST'
export const REMOVE_ORDER_LINES_SUCCESS = 'REMOVE_ORDER_LINES_SUCCESS'
export const REMOVE_ORDER_LINES_FAILURE = 'REMOVE_ORDER_LINES_FAILURE'
export const REOPEN_ORDER_REQUEST = 'REOPEN_ORDER_REQUEST'
export const REOPEN_ORDER_SUCCESS = 'REOPEN_ORDER_SUCCESS'
export const REOPEN_ORDER_FAILURE = 'REOPEN_ORDER_FAILURE'
export const RESEND_ORDER_CONFIRMATION_REQUEST =
  'RESEND_ORDER_CONFIRMATION_REQUEST'
export const RESEND_ORDER_CONFIRMATION_SUCCESS =
  'RESEND_ORDER_CONFIRMATION_SUCCESS'
export const RESEND_ORDER_CONFIRMATION_FAILURE =
  'RESEND_ORDER_CONFIRMATION_FAILURE'
export const SET_ORDER_QUANTITIES_REQUEST = 'SET_ORDER_QUANTITIES_REQUEST'
export const SET_ORDER_QUANTITIES_SUCCESS = 'SET_ORDER_QUANTITIES_SUCCESS'
export const SET_ORDER_QUANTITIES_FAILURE = 'SET_ORDER_QUANTITIES_FAILURE'
export const UNLOCK_ORDER_REQUEST = 'UNLOCK_ORDER_REQUEST'
export const UNLOCK_ORDER_SUCCESS = 'UNLOCK_ORDER_SUCCESS'
export const UNLOCK_ORDER_FAILURE = 'UNLOCK_ORDER_FAILURE'
export const UPDATE_ORDER_PAYMENT_TERM_REQUEST =
  'UPDATE_ORDER_PAYMENT_TERM_REQUEST'
export const UPDATE_ORDER_PAYMENT_TERM_SUCCESS =
  'UPDATE_ORDER_PAYMENT_TERM_SUCCESS'
export const UPDATE_ORDER_PAYMENT_TERM_FAILURE =
  'UPDATE_ORDER_PAYMENT_TERM_FAILURE'
export const UPDATE_ORDER_ADDRESS_REQUEST = 'UPDATE_ORDER_ADDRESS_REQUEST'
export const UPDATE_ORDER_ADDRESS_SUCCESS = 'UPDATE_ORDER_ADDRESS_SUCCESS'
export const UPDATE_ORDER_ADDRESS_FAILURE = 'UPDATE_ORDER_ADDRESS_FAILURE'
export const APPROVE_MANUAL_ORDER = 'APPROVE_MANUAL_ORDER'
export const OPEN_ORDER_SHOW = 'OPEN_ORDER_SHOW'

export const approveManualOrder = () => ({
  type: APPROVE_MANUAL_ORDER,
})

export const openOrderShow = () => ({
  type: OPEN_ORDER_SHOW,
})

export const changeOrderContext = orderId => ({
  type: CHANGE_ORDER_CONTEXT,
  orderId,
})

export const requestGetOrder = (orderId, includes = []) => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}`,
    method: 'GET',
    query: {
      // customer is needed by AddProducts modal in ShowOrder
      // customer is needed by CreateInvoice
      includes: [
        'brand',
        'customer',
        'delivery',
        'shop',
        'invoices',
        ...includes,
      ],
    },
    types: [GET_ORDER_REQUEST, GET_ORDER_SUCCESS, GET_ORDER_FAILURE],
  },
})

const ADD_ORDER_COMMENT_REQUEST = 'ADD_ORDER_COMMENT_REQUEST'
const ADD_ORDER_COMMENT_SUCCESS = 'ADD_ORDER_COMMENT_SUCCESS'
const ADD_ORDER_COMMENT_FAILURE = 'ADD_ORDER_COMMENT_FAILURE'

export const addOrderComment = (orderId, comment, visibleToShop) => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/comments`,
    method: 'POST',
    body: {
      comment: {
        comment,
        visible_for_shops: visibleToShop,
      },
    },
    types: [
      ADD_ORDER_COMMENT_REQUEST,
      ADD_ORDER_COMMENT_SUCCESS,
      ADD_ORDER_COMMENT_FAILURE,
    ],
  },
})

const GET_ORDER_COMMENTS_REQUEST = 'GET_ORDER_COMMENTS_REQUEST'
const GET_ORDER_COMMENTS_SUCCESS = 'GET_ORDER_COMMENTS_SUCCESS'
const GET_ORDER_COMMENTS_FAILURE = 'GET_ORDER_COMMENTS_FAILURE'

export const getOrderComments = orderId => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/comments`,
    method: 'GET',
    types: [
      GET_ORDER_COMMENTS_REQUEST,
      GET_ORDER_COMMENTS_SUCCESS,
      GET_ORDER_COMMENTS_FAILURE,
    ],
  },
})

export const changeOrderDiscount = (orderId, newDiscount) => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/discount`,
    method: 'PUT',
    body: {
      discount: newDiscount,
    },
    types: [
      CHANGE_ORDER_DISCOUNT_REQUEST,
      CHANGE_ORDER_DISCOUNT_SUCCESS,
      CHANGE_ORDER_DISCOUNT_FAILURE,
    ],
  },
})

export const changeOrderHandlingFee = (orderId, handlingFee) => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/handling-fee`,
    method: 'PUT',
    body: {
      handling_fee: handlingFee,
    },
    types: [
      CHANGE_ORDER_DISCOUNT_REQUEST,
      CHANGE_ORDER_DISCOUNT_SUCCESS,
      CHANGE_ORDER_DISCOUNT_FAILURE,
    ],
  },
})

export const changeOrderStatus = (orderId, newStatus) => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/change-status`,
    method: 'POST',
    body: {
      status: newStatus,
    },
    types: [
      CHANGE_ORDER_STATUS_REQUEST,
      CHANGE_ORDER_STATUS_SUCCESS,
      CHANGE_ORDER_STATUS_FAILURE,
    ],
  },
})

export const cancelOrder = (
  orderId: Id,
  regulateInventory: string,
  partialLineReturns: { [lineId: number]: number },
  cancellationReasonId: null | Id
) => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/cancel`,
    method: 'POST',
    body: {
      regulate_inventory: regulateInventory,
      partial_line_returns: partialLineReturns,
      cancellation_reason_id: cancellationReasonId,
    },
    types: [CANCEL_ORDER_REQUEST, CANCEL_ORDER_SUCCESS, CANCEL_ORDER_FAILURE],
  },
})

export const getOrders = (includes = [], filter_groups = [], sort, limit) => ({
  [CALL_API]: {
    endpoint: `/orders`,
    method: 'GET',
    types: [GET_ORDERS_REQUEST, GET_ORDERS_SUCCESS, GET_ORDERS_FAILURE],
    query: {
      includes: includes,
      filter_groups: filter_groups,
      sort: sort,
      limit: limit,
    },
  },
})

export const getOrdersList = ({
  includes = [],
  filter_groups = [],
  page,
  sort,
  limit,
}) => ({
  [CALL_API]: {
    endpoint: `/orders/list`,
    method: 'GET',
    types: [
      GET_ORDERS_LIST_REQUEST,
      GET_ORDERS_LIST_SUCCESS,
      GET_ORDERS_LIST_FAILURE,
    ],
    query: {
      includes: includes,
      filter_groups: filter_groups,
      page,
      sort: sort,
      limit: limit,
    },
  },
})

export const getOrderLines = (orderId, options) => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/lines`,
    method: 'GET',
    query: options,
    types: [
      GET_ORDER_LINES_REQUEST,
      GET_ORDER_LINES_SUCCESS,
      GET_ORDER_LINES_FAILURE,
    ],
  },
})

export const lockOrder = orderId => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/lock`,
    method: 'POST',
    types: [LOCK_ORDER_REQUEST, LOCK_ORDER_SUCCESS, LOCK_ORDER_FAILURE],
  },
})

export const splitOrder = (orderId, lines) => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/split`,
    method: 'POST',
    body: { lines },
    types: [SPLIT_ORDER_REQUEST, SPLIT_ORDER_SUCCESS, SPLIT_ORDER_FAILURE],
  },
})

export const removeOrderLine = (orderId, lineId) => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/lines/${lineId}`,
    method: 'DELETE',
    types: [
      REMOVE_ORDER_LINE_REQUEST,
      REMOVE_ORDER_LINE_SUCCESS,
      REMOVE_ORDER_LINE_FAILURE,
    ],
  },
})

export const removeOrderLines = (orderId, lineIds) => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/lines`,
    method: 'DELETE',
    body: {
      lines: lineIds,
    },
    types: [
      REMOVE_ORDER_LINES_REQUEST,
      REMOVE_ORDER_LINES_SUCCESS,
      REMOVE_ORDER_LINES_FAILURE,
    ],
  },
})

export const reopenOrder = orderId => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/reopen`,
    method: 'POST',
    types: [REOPEN_ORDER_REQUEST, REOPEN_ORDER_SUCCESS, REOPEN_ORDER_FAILURE],
  },
})

export const setOrderQuantities = (orderId, quantities) => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/set-quantities`,
    method: 'POST',
    body: {
      quantities,
    },
    types: [
      SET_ORDER_QUANTITIES_REQUEST,
      SET_ORDER_QUANTITIES_SUCCESS,
      SET_ORDER_QUANTITIES_FAILURE,
    ],
  },
})

export const unlockOrder = orderId => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/unlock`,
    method: 'POST',
    types: [UNLOCK_ORDER_REQUEST, UNLOCK_ORDER_SUCCESS, UNLOCK_ORDER_FAILURE],
  },
})

export const updateOrderPaymentTerms = (orderId, paymentTermId) => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/payment-terms`,
    method: 'PUT',
    body: {
      payment_term_id: paymentTermId,
    },
    types: [
      UPDATE_ORDER_PAYMENT_TERM_REQUEST,
      UPDATE_ORDER_PAYMENT_TERM_SUCCESS,
      UPDATE_ORDER_PAYMENT_TERM_FAILURE,
    ],
  },
})

export const updateOrderAddress = (orderId, type, address) => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/shop-or-delivery`,
    method: 'PUT',
    body: {
      type,
      data: address,
    },
    types: [
      UPDATE_ORDER_ADDRESS_REQUEST,
      UPDATE_ORDER_ADDRESS_SUCCESS,
      UPDATE_ORDER_ADDRESS_FAILURE,
    ],
  },
})

export const requestPlaceOrder = (
  brandId: Id,
  data,
  source,
  webshopSessionId?: Id
) => {
  return {
    [CALL_API]: {
      endpoint: '/orders',
      method: 'POST',
      body: {
        order: data,
        source: source,
        webshop_session_id: webshopSessionId,
      },
      types: [PLACE_ORDER_REQUEST, PLACE_ORDER_SUCCESS, PLACE_ORDER_FAILURE],
    },
  }
}

export const requestSendOrderConfirmation = (orderId, emails, message) => {
  return {
    [CALL_API]: {
      endpoint: `/orders/${orderId}/resend-order-confirmation`,
      method: 'POST',
      body: {
        emails,
        message,
      },
      types: [
        RESEND_ORDER_CONFIRMATION_REQUEST,
        RESEND_ORDER_CONFIRMATION_SUCCESS,
        RESEND_ORDER_CONFIRMATION_FAILURE,
      ],
    },
  }
}

export const requestOrdersTableParameters = dataKey => {
  return {
    [CALL_API]: {
      endpoint: '/orders/table-parameters',
      method: 'GET',
      types: [
        ORDERS_TABLE_PARAMETERS_REQUEST,
        ORDERS_TABLE_PARAMETERS_SUCCESS,
        ORDERS_TABLE_PARAMETERS_FAILURE,
      ],
    },
  }
}

export const addLinesToOrder = (
  orderId,
  lines,
  discount_method,
  discount_value
) => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/lines`,
    method: 'POST',
    body: {
      discount_method,
      discount_value,
      lines,
    },
    types: [
      ADD_ORDER_LINES_REQUEST,
      ADD_ORDER_LINES_SUCCESS,
      ADD_ORDER_LINES_FAILURE,
    ],
  },
})

export const SET_ORDER_LINE_DISCOUNTS_REQUEST =
  'SET_ORDER_LINE_DISCOUNTS_REQUEST'
export const SET_ORDER_LINE_DISCOUNTS_SUCCESS =
  'SET_ORDER_LINE_DISCOUNTS_SUCCESS'
export const SET_ORDER_LINE_DISCOUNTS_FAILURE =
  'SET_ORDER_LINE_DISCOUNTS_FAILURE'

export const setOrderLineDiscounts = (orderId, splits) => ({
  [CALL_API]: {
    endpoint: `/orders/${orderId}/set-discounts`,
    method: 'POST',
    body: {
      splits,
    },
    types: [
      SET_ORDER_LINE_DISCOUNTS_REQUEST,
      SET_ORDER_LINE_DISCOUNTS_SUCCESS,
      SET_ORDER_LINE_DISCOUNTS_FAILURE,
    ],
  },
})

export const CHANGE_ORDER_ARCHIVE_STATUS_REQUEST =
  'CHANGE_ORDER_ARCHIVE_STATUS_REQUEST'
export const CHANGE_ORDER_ARCHIVE_STATUS_SUCCESS =
  'CHANGE_ORDER_ARCHIVE_STATUS_SUCCESS'
export const CHANGE_ORDER_ARCHIVE_STATUS_FAILURE =
  'CHANGE_ORDER_ARCHIVE_STATUS_FAILURE'

export const changeOrderArchiveStatus = (orderIds, archive = true) => ({
  [CALL_API]: {
    endpoint: archive ? '/orders/archive' : '/orders/unarchive',
    method: 'POST',
    body: {
      orders: orderIds,
    },
    types: [
      CHANGE_ORDER_ARCHIVE_STATUS_REQUEST,
      CHANGE_ORDER_ARCHIVE_STATUS_SUCCESS,
      CHANGE_ORDER_ARCHIVE_STATUS_FAILURE,
    ],
  },
})

export const UPDATE_ORDERS_BULK_REQUEST = 'UPDATE_ORDERS_BULK_REQUEST'
export const UPDATE_ORDERS_BULK_SUCCESS = 'UPDATE_ORDERS_BULK_SUCCESS'
export const UPDATE_ORDERS_BULK_FAILURE = 'UPDATE_ORDERS_BULK_FAILURE'

export const updateOrdersBulk = (orders, data) => ({
  [CALL_API]: {
    endpoint: '/orders',
    method: 'PUT',
    body: {
      orders,
      data,
    },
    types: [
      UPDATE_ORDERS_BULK_REQUEST,
      UPDATE_ORDERS_BULK_SUCCESS,
      UPDATE_ORDERS_BULK_FAILURE,
    ],
  },
})
