/* @flow */

import React, { useContext, useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  ControlLabel,
  CheckboxInput,
  FormGroup,
  FormInput,
  SaveButton,
  SelectInput,
  CountrySettingsField,
} from '../../../infrastructure/components/Formik'
import ActionButton from '../../../infrastructure/components/ActionButton'
import { Fieldset } from '../../../infrastructure/components/Forms'
import CountrySelector from '../../../infrastructure/components/CountrySelector'
import { useDebounce } from '../../../infrastructure/hooks'
import { useCancellableRequest } from '../../shared'
import { UserHasPermissions } from '../../../infrastructure/components/Authorization'
import {
  AddressFormFields,
  validationSchema as addressValidationSchema,
} from '../../entities/components/AddressModalForm'
import PaymentTermsSelector from '../../payment-terms/PaymentTermsSelector'
import { isEuCountry } from '../../entities/utilities'
import EuVatValidationLabel from './EuVatValidationLabel'
import { EU_VAT_VALIDATION_VALID } from '../shared'

import {
  NewCustomerUserFormFields,
  createInitialValues as createInitialBuyerValues,
  validationSchema as buyerValidationSchema,
} from './CustomerAddUserModal'
import CustomerGroupSelector from './CustomerGroupSelector'

import type { Customer } from '../../types'
import {
  getNextCustomerNumber,
  validateVatNumber,
  getCustomers,
  createCustomer,
} from '../api'
import { SessionContext, userHasFlag, useRefValue } from '../../shared'

type Props = {
  onHide: Function,
  onSubmit: (values: $Shape<Customer>) => void,
  show: boolean,
}

const NewCustomerModal = ({ onCreated, onHide, show }: Props) => {
  const [nextCustomerNumber, setNextCustomerNumber] = useState('')
  const { user, settings } = useContext(SessionContext)
  const [values, setValues] = React.useState({})

  const checkForExistingCustomerWithVatNumberData =
    useCheckForExistingCustomerWithVatNumber(values.vat_number)
  const checkForExistingCustomerWithVatNumberDataRef = useRefValue(
    checkForExistingCustomerWithVatNumberData
  )
  const [vatNumberErrorData, setVatNumberErrorData] = React.useState(null)

  const onSubmit = React.useCallback(
    (values, { setSubmitting }) => {
      let entity = { name: values.name }
      let addresses = []
      let contact
      let sendActivation = false

      if (values.add_buyer) {
        contact = values.buyer
        sendActivation = values.buyer.send_customer_email
      }

      if (values.add_invoice_address) {
        addresses.push({
          ...values.primary_address,
          primary: true,
          delivery: false,
          title: 'Primary address',
        })
      }
      if (values.add_shipping_address) {
        addresses.push({
          ...values.shipping_address,
          primary: false,
          delivery: true,
          title: 'Shipping address',
        })
      }

      if (values.billing_email) {
        // making changes to the values object can screw up formik validation,
        // so we make a deep copy
        values = { ...values }
        entity.billing_emails = [values.billing_email]
        values.billing_emails = [values.billing_email]
        delete values.billing_email
      }

      return createCustomer(
        values,
        entity,
        addresses,
        contact,
        sendActivation
      ).then(response => {
        setSubmitting(false)

        if (!response.error) {
          if (onCreated) {
            onCreated(response.payload)
          }
        }
      })
    },
    [onCreated]
  )

  const wrappedOnSubmit = React.useCallback(
    (values, formikProps, ...rest) => {
      const {
        hasCheckedForExistingCustomerWithVatNumber,
        existingCustomer,
        isCheckingForExistingCustomerWithVatNumber,
        hasVatNumberEntered,
      } = checkForExistingCustomerWithVatNumberDataRef.current

      if (hasVatNumberEntered) {
        let vatNumberError = null

        if (!hasCheckedForExistingCustomerWithVatNumber) {
          vatNumberError = (
            <span>
              TRAEDE has not yet validated that vat number ${values.vat_number}{' '}
              is unique. Please wait a second and try again. Or continue with
              creating the customer
            </span>
          )
        } else if (existingCustomer) {
          vatNumberError = (
            <span>
              Customer{' '}
              <Link target="_blank" to={`/customers/${existingCustomer.id}`}>
                {existingCustomer.customer_number} {existingCustomer.name}
              </Link>{' '}
              is already created with the VAT number {values.vat_number}. Are
              you sure you want to create a new customer with the same VAT
              number?
            </span>
          )
        }

        if (vatNumberError) {
          formikProps.setSubmitting(false)

          setVatNumberErrorData({
            error: vatNumberError,
            submitData: [values, formikProps, ...rest],
          })

          return
        }
      }

      return onSubmit(values, formikProps, ...rest)
    },
    [
      checkForExistingCustomerWithVatNumberDataRef,
      onSubmit,
      setVatNumberErrorData,
    ]
  )

  const requiredFields = settings.customer_form_required_fields

  const initialValues = React.useMemo(() => {
    const data = {
      name: '',
      customer_number: nextCustomerNumber || '',
      vat_number: '',
      payment_term_id: undefined,
      country: '',
      customer_groups: [],

      telephone: '',
      email: '',
      use_email_as_billing_email:
        settings.use_contact_email_as_billing_email_fallback === true,
      billing_email:
        settings.use_contact_email_as_billing_email_fallback === true
          ? null
          : '',
      use_email_as_buyer_email: true,

      add_buyer: requiredFields.includes('buyer'),
      buyer: createInitialBuyerValues(settings),

      add_invoice_address: requiredFields.includes('primary_address'),
      primary_address: {
        att: requiredFields.includes('primary_address_att') ? '' : null,
        name: '',
        address: '',
        zip: '',
        city: '',
        country: '',
        region: '',
        email: requiredFields.includes('address_email') ? '' : null,
        telephone: requiredFields.includes('address_telephone') ? '' : null,
      },

      add_shipping_address: false,
      shipping_address: {
        name: '',
        address: '',
        zip: '',
        city: '',
        country: '',
        region: '',
        email: requiredFields.includes('address_email') ? '' : null,
        telephone: requiredFields.includes('address_telephone') ? '' : null,
      },
    }

    const entityUser = user.entity_user

    if (entityUser.country) {
      data.country = entityUser.country
    }

    return data
  }, [user, nextCustomerNumber, requiredFields, settings])

  React.useEffect(() => {
    setValues(initialValues)
  }, [initialValues, setValues])

  const validationSchema = React.useMemo(() => {
    const shape = {
      name: Yup.string().required('Please enter a name'),
    }

    if (requiredFields.includes('customer_number')) {
      shape.customer_number = Yup.string().required(
        'Please enter a customer number'
      )
    }
    if (requiredFields.includes('country')) {
      shape.country = Yup.string().required('Please select a country')
    }
    if (requiredFields.includes('vat_number')) {
      shape.vat_number = Yup.string().required('Please enter VAT number')
    }
    if (requiredFields.includes('payment_term_id')) {
      // should accept null, but reject undefined
      shape.payment_term_id = Yup.string()
        .transform(v => (v === null ? 'acceptable' : v))
        .required('Please select Payment terms')
    }

    if (requiredFields.includes('telephone')) {
      shape.telephone = Yup.string().required('Please enter a phone number')
    }
    if (
      requiredFields.includes('contact_email') ||
      (values.use_email_as_billing_email === true &&
        requiredFields.includes('billing_email'))
    ) {
      shape.email = Yup.string().email().required('Please enter an email')
    }
    if (
      requiredFields.includes('billing_email') &&
      values.use_email_as_billing_email === false
    ) {
      shape.billing_email = Yup.string()
        .email()
        .required('Please enter a billing email')
    }

    if (requiredFields.includes('customer_group')) {
      shape.customer_groups = Yup.array()
        .of(Yup.number())
        .required('Please select at least 1 customer group')
        .min(1, 'Please select at least 1 customer group')
    }

    if (values.add_invoice_address) {
      shape.primary_address = addressValidationSchema

      if (requiredFields.includes('primary_address_att')) {
        shape.primary_address = shape.primary_address.shape({
          att: Yup.string().required('Att is required'),
        })
      }
      if (requiredFields.includes('address_email')) {
        shape.primary_address = shape.primary_address.shape({
          email: Yup.string().required('Address email is required'),
        })
      }
      if (requiredFields.includes('address_telephone')) {
        shape.primary_address = shape.primary_address.shape({
          telephone: Yup.string().required('Address telephone is required'),
        })
      }
    }

    if (values.add_shipping_address) {
      shape.shipping_address = addressValidationSchema

      if (requiredFields.includes('address_email')) {
        shape.shipping_address = shape.shipping_address.shape({
          email: Yup.string().required('Address email is required'),
        })
      }

      if (requiredFields.includes('address_telephone')) {
        shape.shipping_address = shape.shipping_address.shape({
          telephone: Yup.string().required('Address telephone is required'),
        })
      }
    }

    if (values.add_buyer) {
      shape.buyer = buyerValidationSchema
    }

    return Yup.object().shape(shape)
  }, [
    requiredFields,
    settings,
    values.add_buyer,
    values.add_invoice_address,
    values.add_shipping_address,
    values.use_email_as_billing_email,
  ])

  useEffect(() => {
    getNextCustomerNumber().then(response => {
      if (!response.error) {
        setNextCustomerNumber(response.payload.next_number)
      }
    })
  }, [])

  return (
    <>
      {vatNumberErrorData && (
        <VatNumberErrorModal
          onHide={() => setVatNumberErrorData(null)}
          onSubmit={onSubmit}
          show={vatNumberErrorData !== null}
          vatNumberErrorData={vatNumberErrorData}
        />
      )}

      <Modal show={show} onHide={onHide} bsSize="large" backdrop="static">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={wrappedOnSubmit}
          validationSchema={validationSchema}
          render={formikProps => (
            <RenderNewCustomerModal
              checkForExistingCustomerWithVatNumberData={
                checkForExistingCustomerWithVatNumberData
              }
              customerNumberIsVatNumber={
                settings.customer_form_customer_number_is_vat_number
              }
              onHide={onHide}
              onValuesChange={setValues}
              requiredFields={requiredFields}
              settings={settings}
              user={user}
              {...formikProps}
            />
          )}
        />
      </Modal>
    </>
  )
}

export default NewCustomerModal

const createPaymentTermSelector = ({
  disabled,
  field: { name, value },
  form: { setFieldValue },
}) => {
  return (
    <PaymentTermsSelector
      disabled={disabled}
      onChange={paymentTerm => {
        setFieldValue(name, paymentTerm)
      }}
      value={value}
    />
  )
}

const RenderNewCustomerModal = ({
  checkForExistingCustomerWithVatNumberData,
  customerNumberIsVatNumber,
  errors,
  handleSubmit,
  isSubmitting,
  onHide,
  onValuesChange,
  requiredFields,
  setFieldValue,
  settings,
  user,
  values,
}) => {
  const [billingEmailDisabled, setBillingEmailDisabled] = React.useState(false)

  React.useEffect(() => {
    if (customerNumberIsVatNumber) {
      setFieldValue('vat_number', values.customer_number)
    }
  }, [customerNumberIsVatNumber, setFieldValue, values.customer_number])

  React.useEffect(() => {
    setFieldValue(`primary_address.name`, values.name)
    setFieldValue(`shipping_address.name`, values.name)
    setFieldValue(`buyer.firstname`, values.name)
  }, [setFieldValue, values.name])

  React.useEffect(() => {
    setFieldValue(`primary_address.country`, values.country)
    setFieldValue(`shipping_address.country`, values.country)
  }, [setFieldValue, values.country])

  React.useEffect(() => {
    setFieldValue(`buyer.email`, values.email)

    if (!settings.use_contact_email_as_billing_email_fallback) {
      setFieldValue('billing_email', values.email)
    }
  }, [setFieldValue, settings, values.email])

  React.useEffect(() => {
    setFieldValue(`primary_address.vat`, values.vat_number)
  }, [setFieldValue, values.vat_number])

  React.useEffect(() => {
    if (settings.use_contact_email_as_billing_email_fallback === true) {
      setBillingEmailDisabled(values.use_email_as_billing_email)

      if (values.use_email_as_billing_email === true) {
        setFieldValue('billing_email', '')
      }
    }
  }, [
    setBillingEmailDisabled,
    setFieldValue,
    settings,
    values.use_email_as_billing_email,
  ])

  React.useEffect(() => {
    onValuesChange(values)
  }, [onValuesChange, values])

  const [
    {
      error: vatNumberValidationError,
      status: vatNumberValidationStatus,
      vat_number: validatedVatNumber,
    },
    setVatNumberValidationStatus,
  ] = React.useState({ error: null, status: null, vat_number: null })

  const selectedCountryIsWithinEu = React.useMemo(() => {
    return values.country ? isEuCountry(values.country) : false
  }, [values.country])

  const onValidateVatNumber = React.useCallback(() => {
    if (!selectedCountryIsWithinEu) {
      return alert('Not an EU country')
    }

    const vatNumber = values.vat_number

    return validateVatNumber(values.country, vatNumber).then(response => {
      if (!response.error) {
        setVatNumberValidationStatus({
          status: response.payload.status,
          vat_number: vatNumber,
          error: null,
        })
      } else {
        setVatNumberValidationStatus({
          status: null,
          vat_number: vatNumber,
          error:
            response.payload &&
            response.payload.errors &&
            response.payload.errors[0]
              ? response.payload.errors[0].detail
              : 'Unknown error',
        })
      }
    })
  }, [
    values.country,
    values.vat_number,
    selectedCountryIsWithinEu,
    setVatNumberValidationStatus,
  ])

  const wrappedSubmit = e => {
    e.stopPropagation()
    handleSubmit(e)
  }

  return (
    <form onSubmit={wrappedSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>Add new customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fieldset stacked>
          <Fieldset.Description title="Basic">
            <p />
          </Fieldset.Description>
          <Fieldset.Form>
            <div className="row">
              {!UserHasPermissions(user, 'customers:number:write') &&
              !requiredFields.includes('customer_number') ? null : (
                <div className="col-xs-6">
                  <FormGroup>
                    <ControlLabel
                      required={requiredFields.includes('customer_number')}
                    >
                      Customer number
                    </ControlLabel>
                    <FormInput
                      name="customer_number"
                      disabled={
                        !UserHasPermissions(user, 'customers:number:write')
                      }
                    />
                  </FormGroup>
                </div>
              )}

              <div className="col-xs-6">
                <FormGroup>
                  <ControlLabel required>Name</ControlLabel>
                  <FormInput name="name" />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <FormGroup>
                  <ControlLabel required={requiredFields.includes('country')}>
                    Country
                  </ControlLabel>
                  <SelectInput
                    name="country"
                    selectComponent={CountrySelector}
                  />
                </FormGroup>
              </div>
              <div className="col-xs-6">
                <FormGroup>
                  <ControlLabel
                    required={requiredFields.includes('vat_number')}
                  >
                    VAT Number
                    {selectedCountryIsWithinEu && (
                      <>
                        {(vatNumberValidationStatus !==
                          EU_VAT_VALIDATION_VALID ||
                          validatedVatNumber !== values.vat_number) && (
                          <ActionButton
                            onClick={onValidateVatNumber}
                            className="btn btn-white btn-xs"
                            style={{ marginRight: 5 }}
                          >
                            <span className="glyphicon glyphicon-refresh" />
                          </ActionButton>
                        )}
                        {!vatNumberValidationError && (
                          <EuVatValidationLabel
                            size="sm"
                            status={vatNumberValidationStatus}
                          />
                        )}
                        {vatNumberValidationError && (
                          <span className="label label-xxs label-important">
                            {vatNumberValidationError}
                          </span>
                        )}
                      </>
                    )}
                  </ControlLabel>
                  <FormInput name="vat_number" />

                  {checkForExistingCustomerWithVatNumberData &&
                    checkForExistingCustomerWithVatNumberData.hasVatNumberEntered &&
                    checkForExistingCustomerWithVatNumberData.existingCustomer && (
                      <VatNumberErrorAlert>
                        <strong>Warning!</strong> Existing customer{' '}
                        <Link
                          target="_blank"
                          to={`/customers/${checkForExistingCustomerWithVatNumberData.existingCustomer.id}`}
                        >
                          #
                          {
                            checkForExistingCustomerWithVatNumberData
                              .existingCustomer.customer_number
                          }{' '}
                          {
                            checkForExistingCustomerWithVatNumberData
                              .existingCustomer.name
                          }
                        </Link>{' '}
                        found with the same VAT number
                      </VatNumberErrorAlert>
                    )}
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <ControlLabel
                  required={requiredFields.includes('customer_group')}
                >
                  Customer group
                </ControlLabel>
                <SelectInput
                  name="customer_groups"
                  multi
                  selectComponent={CustomerGroupSelector}
                  simpleValue
                />
              </div>
              <div className="col-xs-6">
                <FormGroup>
                  <ControlLabel
                    required={requiredFields.includes('payment_term_id')}
                  >
                    Payment terms
                  </ControlLabel>
                  <CountrySettingsField
                    id="new-customer-payment-terms-id-country-settings"
                    input={createPaymentTermSelector}
                    labelKey="name"
                    name="payment_term_id"
                    simpleValue
                    valueKey="id"
                    defaultValue={null}
                    overrideDefaultsWithNull={false}
                  />
                </FormGroup>
              </div>
            </div>
          </Fieldset.Form>
        </Fieldset>

        <Fieldset stacked>
          <Fieldset.Description title="Contact">
            <p />
          </Fieldset.Description>
          <Fieldset.Form>
            <div className="row">
              <div className="col-xs-6">
                <FormGroup>
                  <ControlLabel required={requiredFields.includes('telephone')}>
                    Telephone
                  </ControlLabel>
                  <FormInput name="telephone" />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <FormGroup>
                  <ControlLabel
                    required={requiredFields.includes('contact_email')}
                  >
                    E-mail
                  </ControlLabel>
                  <FormInput name="email" />
                </FormGroup>
              </div>
            </div>
            {settings.use_contact_email_as_billing_email_fallback === true && (
              <div className="row">
                <div className="col-xs-6">
                  <FormGroup>
                    <ControlLabel
                      required={requiredFields.includes('billing_email')}
                    >
                      Billing e-mail
                    </ControlLabel>
                    <FormInput
                      name="billing_email"
                      disabled={billingEmailDisabled}
                    />
                  </FormGroup>
                </div>
                <div className="col-xs-6" style={{ paddingTop: 30 }}>
                  <CheckboxInput
                    name="use_email_as_billing_email"
                    id="use_email_as_billing_email"
                    label="Use email as billing e-mail"
                  />
                </div>
              </div>
            )}
            {settings.use_contact_email_as_billing_email_fallback === false && (
              <>
                <div className="row">
                  <div className="col-xs-6">
                    <FormGroup>
                      <ControlLabel
                        required={requiredFields.includes('billing_email')}
                      >
                        Billing e-mail
                      </ControlLabel>
                      <FormInput name="billing_email" />
                    </FormGroup>

                    {typeof values.billing_email === 'string' &&
                      values.billing_email.length > 0 &&
                      values.email === values.billing_email && (
                        <div className="alert alert-info">
                          Note! The entered billing e-mail is the same as the
                          contact e-mail. Ignore this message if that is
                          intended
                        </div>
                      )}
                  </div>
                </div>
              </>
            )}
          </Fieldset.Form>
        </Fieldset>

        <Fieldset stacked>
          <Fieldset.Description title="Buyer">
            <p />
          </Fieldset.Description>
          <Fieldset.Form>
            <div>
              {!requiredFields.includes('buyer') && (
                <CheckboxInput
                  name="add_buyer"
                  id="add_buyer"
                  label="Add buyer"
                />
              )}

              {values.add_buyer === true && (
                <div>
                  <NewCustomerUserFormFields
                    email={values.buyer.email}
                    namespace="buyer"
                    send_customer_email={values.buyer.send_customer_email}
                    setFieldValue={setFieldValue}
                  />
                </div>
              )}
            </div>
          </Fieldset.Form>
        </Fieldset>

        <Fieldset stacked last>
          <Fieldset.Description title="Addresses">
            <p />
          </Fieldset.Description>
          <Fieldset.Form>
            {!requiredFields.includes('primary_address') && (
              <CheckboxInput
                name="add_invoice_address"
                id="add_invoice_address"
                label="Add invoice address"
              />
            )}

            {values.add_invoice_address === true && (
              <div>
                <AddressFormFields
                  attRequired={requiredFields.includes('primary_address_att')}
                  b2c={false}
                  email={requiredFields.includes('address_email')}
                  emailRequired={requiredFields.includes('address_email')}
                  namespace="primary_address"
                  showFlags={false}
                  title={false}
                  telephone={requiredFields.includes('address_telephone')}
                  telephoneRequired={requiredFields.includes(
                    'address_telephone'
                  )}
                  values={values}
                />
              </div>
            )}

            <CheckboxInput
              name="add_shipping_address"
              id="add_shipping_address"
              label="Add different shipping address"
            />

            {values.add_shipping_address === true && (
              <div>
                <AddressFormFields
                  b2c={false}
                  email={requiredFields.includes('address_email')}
                  emailRequired={requiredFields.includes('address_email')}
                  namespace="shipping_address"
                  showFlags={false}
                  title={false}
                  telephone={requiredFields.includes('address_telephone')}
                  telephoneRequired={requiredFields.includes(
                    'address_telephone'
                  )}
                  values={values}
                />
              </div>
            )}
          </Fieldset.Form>
        </Fieldset>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-white" onClick={onHide}>
          Cancel
        </button>

        <SaveButton submitting={isSubmitting}>Create customer</SaveButton>
      </Modal.Footer>
    </form>
  )
}

const useCheckForExistingCustomerWithVatNumber = vatNumber => {
  // check if we have customers with similar VAT number
  const [hasChecked, setHasChecked] = React.useState(false)
  const [existingCustomer, setExistingCustomer] = React.useState(null)
  const [isChecking, setIsChecking] = React.useState(false)

  const vatNumberDebounced = useDebounce(vatNumber, 500)
  const [doCancellableRequest] = useCancellableRequest()

  const trimmedValue = String(vatNumberDebounced || '').trim()

  // check using debounced value
  React.useEffect(() => {
    if (trimmedValue.length == 0) {
      return
    }

    setIsChecking(true)

    doCancellableRequest(axiosOptions =>
      getCustomers(axiosOptions, {
        filter_groups: [
          {
            filters: [
              {
                key: 'vat_number',
                operator: 'eq',
                value: trimmedValue,
              },
            ],
          },
        ],
      })
    ).then(response => {
      setHasChecked(true)
      setIsChecking(false)

      if (!response.error) {
        const existingCustomer = response.payload[0] || null

        setExistingCustomer(existingCustomer)
      }
    })
  }, [
    doCancellableRequest,
    setExistingCustomer,
    setHasChecked,
    setIsChecking,
    trimmedValue,
  ])

  // reset whenever vatNumber changes
  React.useEffect(() => {
    setHasChecked(false)
    setExistingCustomer(null)
    setIsChecking(false)
  }, [vatNumber, setHasChecked, setExistingCustomer, setIsChecking])

  const returnData = React.useMemo(() => {
    return {
      hasCheckedForExistingCustomerWithVatNumber: hasChecked,
      existingCustomer,
      isCheckingForExistingCustomerWithVatNumber: isChecking,
      hasVatNumberEntered: trimmedValue.length > 0,
    }
  }, [hasChecked, existingCustomer, isChecking, trimmedValue])

  return returnData
}

const VatNumberErrorModal = ({
  onHide,
  onSubmit: propsOnSubmit,
  show,
  vatNumberErrorData,
}: {
  onHide: Function,
  onSubmit: Function,
  show: boolean,
}) => {
  const onSubmit = React.useCallback(() => {
    return propsOnSubmit(...vatNumberErrorData.submitData)
  }, [propsOnSubmit, vatNumberErrorData])

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>VAT Number error</Modal.Title>
      </Modal.Header>
      <Modal.Body>{vatNumberErrorData.error}</Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-white" onClick={onHide}>
          Cancel
        </button>
        <ActionButton
          className="btn btn-success"
          iconClass="glyphicon glyphicon-ok"
          onClick={onSubmit}
        >
          Create customer anyways!
        </ActionButton>
      </Modal.Footer>
    </Modal>
  )
}

const VatNumberErrorAlert = styled.div.attrs({
  className: 'alert alert-danger',
})`
  font-size: 10px;
  padding: 4px 8px 4px 10px;
  margin-top: 7px;
`
