/* @flow */

import * as React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import { Modal } from 'react-bootstrap'

import {
  ControlLabel,
  CustomFieldInput,
  ListFormInput,
  SaveButton,
} from '../../../../infrastructure/components/Formik'

import { SessionContext } from '../../../shared'

const CompositeCustomFieldEditor = ({
  disabled,
  groupType,
  onChange,
  options,
  value,
}) => {
  const [showEditModal, setShowEditModal] = React.useState(false)

  const onSave = React.useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(false)

      onChange(values.value)
      setShowEditModal(false)
    },
    [onChange]
  )

  return (
    <div>
      {showEditModal && (
        <EditCompositeCustomFieldModal
          disabled={disabled}
          initialValues={value}
          groupType={groupType}
          onHide={() => setShowEditModal(false)}
          onSave={onSave}
          options={options}
          show={showEditModal !== false}
        />
      )}

      <div style={{ marginBottom: 5 }}>
        <button
          type="button"
          className="btn btn-white btn-sm"
          onClick={() => setShowEditModal(true)}
        >
          <span className="glyphicon glyphicon-pencil" /> Edit
        </button>
      </div>

      {Array.isArray(value) && value.length > 0 && (
        <ValueList>
          {value.map(value => {
            return <li>{Object.values(value).join(' ')}</li>
          })}
        </ValueList>
      )}
    </div>
  )
}

export default CompositeCustomFieldEditor

const ValueList = styled.ul``

const EditCompositeCustomFieldModal = ({
  disabled,
  initialValues,
  groupType,
  onHide,
  onSave,
  options,
  show,
}: {
  onHide: Function,
  onSave: Function,
  show: boolean,
}) => {
  const { brand } = React.useContext(SessionContext)

  const innerCustomFields = React.useMemo(() => {
    return options
      .map(option => {
        const innerCustomField = brand.custom_fields.find(c => {
          return c.group_type === groupType && c.internal_name === option.value
        })

        return innerCustomField
      })
      .filter(c => c)
  }, [brand.custom_fields, groupType, options])

  return (
    <Modal show={show} onHide={onHide} bsSize="large">
      <Formik
        initialValues={{
          value: initialValues,
        }}
        onSubmit={onSave}
        render={({ handleSubmit, isSubmitting, values }) => {
          const wrappedSubmit = e => {
            e.stopPropagation()
            handleSubmit(e)
          }

          return (
            <form onSubmit={wrappedSubmit}>
              <Modal.Body>
                <ListFormInput
                  addBtnSize="sm"
                  removeBtnSize="sm"
                  addNewLabel="Add new value"
                  addNew
                  disabled={disabled}
                  defaultValue={{}}
                  name="value"
                  values={values.value || []}
                  renderFields={nameCreator => (
                    <>
                      {innerCustomFields.map(innerCustomField => {
                        return (
                          <div key={innerCustomField.internal_name}>
                            <ControlLabel
                              required={
                                innerCustomField.required_for === 'brand'
                              }
                            >
                              {innerCustomField.label}
                            </ControlLabel>
                            <CustomFieldInput
                              customField={innerCustomField}
                              name={nameCreator(innerCustomField.internal_name)}
                              formikFastField
                            />
                          </div>
                        )
                      })}
                    </>
                  )}
                />
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={onHide}
                >
                  Cancel
                </button>
                <SaveButton submitting={isSubmitting}>Save</SaveButton>
              </Modal.Footer>
            </form>
          )
        }}
      />
    </Modal>
  )
}
