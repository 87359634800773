/* @flow */

import loadable from '@loadable/component'

import { FormControl, InputGroup } from 'react-bootstrap'

import AdvancedFieldBlock from './AdvancedFieldBlock'
import BooleanInput from './BooleanInput'
import CheckboxInput from './CheckboxInput'
import CountrySettingsField from './CountrySettingsField'
const ColorInput = loadable(() => import('./ColorInput'))
import CustomFieldInput from './CustomFieldInput'
import DateInput from './DateInput'
const CustomerAddressSelectorInput = loadable(() =>
  import('./CustomerAddressSelectorInput')
)
// Prevent ckeditor from being loaded unless component is used
const EditorInput = loadable(() => import('./EditorInput'))
const FilesInput = loadable(() => import('./FilesInput'))
import FiltersInput from './FiltersInput'
import FormInput from './FormInput'
import ListFormInput from './ListFormInput'
import LocaleInput from './LocaleInput'
import LocalePrioritization from './LocalePrioritization'
import PriceInput from './PriceInput'
import ProductAutoCompleteInput from './ProductAutoCompleteInput'
import { PriceControl } from './PriceInput'
import OverridableInput from './OverridableInput'
import RadioInput from './RadioInput'
import RuleBasedSettingsInput from './RuleBasedSettingsInput'
import SelectInput from './SelectInput'
import TextareaInput from './TextareaInput'
const UploaderInput = loadable(() => import('./UploaderInput'))

import {
  ControlLabel,
  ErrorMessage,
  FormGroup,
  HelpHint,
  SaveButton,
} from './FormElements'

export {
  AdvancedFieldBlock,
  BooleanInput,
  CheckboxInput,
  ControlLabel,
  CountrySettingsField,
  CustomerAddressSelectorInput,
  CustomFieldInput,
  DateInput,
  EditorInput,
  ErrorMessage,
  ColorInput,
  FilesInput,
  FiltersInput,
  FormControl,
  FormInput,
  FormGroup,
  HelpHint,
  InputGroup,
  ListFormInput,
  LocaleInput,
  LocalePrioritization,
  PriceControl,
  PriceInput,
  ProductAutoCompleteInput,
  OverridableInput,
  RadioInput,
  RuleBasedSettingsInput,
  SaveButton,
  SelectInput,
  TextareaInput,
  UploaderInput,
}
