/* @flow */

import * as React from 'react'
import moment from 'moment-timezone'

import { renderDate } from '../../../../../../shared'

const CustomerFacingDeliveryDate = ({
  brand,
  deliveryDate,
  isEstimated = false,
}) => {
  if (!deliveryDate) {
    return null
  }

  const date = moment(deliveryDate)
  addToDisplayDate(date, brand)
  let formatted

  switch (brand.settings.display_delivery_dates_as) {
    default:
    case 'date':
      formatted = renderDate(date)
      break

    case 'week':
      formatted = `week ${date.format('w')}`
      break

    case 'month':
      formatted = date.format('MMM YYYY')
      break
  }

  let prefix = isEstimated ? 'Est. ' : ''

  return (
    <span>
      {prefix}
      {formatted}
    </span>
  )
}

export default CustomerFacingDeliveryDate

const addToDisplayDate = (date, brand) => {
  if (!brand.settings.display_delivery_dates_add_to_date) {
    return date
  }

  let momentAddShortHand
  switch (brand.settings.display_delivery_dates_add_to_date_type) {
    case 'day':
      momentAddShortHand = 'd'

      break

    case 'week':
      momentAddShortHand = 'w'

      break
  }

  if (!momentAddShortHand) {
    return date
  }

  date.add(
    brand.settings.display_delivery_dates_add_to_date,
    momentAddShortHand
  )

  return date
}
