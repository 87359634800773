// @flow

import api from '../api'
import type {
  ApiResponse,
  FileBankFileRequestOptions,
  FileBankFolder,
  Id,
} from '../types'

export const getFileBankFolder = (
  brandId: Id,
  publicLinkToken: null | string,
  folderPath: string[]
): Promise<
  ApiResponse<{
    folder: FileBankFolder,
    subfolders: Array<FileBankFolder>,
    breadcrumbs: Array<FileBankFolder>,
  }>
> =>
  api.get(`/file-bank/${brandId}/folder`, {
    query: {
      folder_path: folderPath,
      public_link_token: publicLinkToken,
    },
  })

export const fileBankSearch = (
  brandId: Id,
  options: FileBankFileRequestOptions
) =>
  api.post(`/file-bank/${brandId}/search`, {
    body: options,
  })

export const getFileBankAttributes = (
  brandId: Id,
  options: FileBankFileRequestOptions
) =>
  api.get(`/file-bank/${brandId}/attributes`, {
    query: options,
  })

export const ensureFileBankSession = (brandId: Id) =>
  api.post(`/file-bank/${brandId}/sessions`, {})

export const ensureAnonymousFileBankSession = (
  brandId: Id,
  publicLinkToken: null | string
) =>
  api.post(`/file-bank/${brandId}/sessions/anonymous`, {
    body: {
      public_link_token: publicLinkToken,
    },
  })

export const getFileBankSelection = (
  brandId: Id,
  publicLinkToken: null | string
) =>
  api.get(`/file-bank/${brandId}/selection`, {
    query: {
      public_link_token: publicLinkToken,
    },
  })

export const addToFileBankSelection = (
  brandId: Id,
  options: FileBankFileRequestOptions
) =>
  api.post(`/file-bank/${brandId}/selection/add`, {
    body: options,
  })

export const removeFromFileBankSelection = (
  brandId: Id,
  options: FileBankFileRequestOptions
) =>
  api.post(`/file-bank/${brandId}/selection/remove`, {
    body: options,
  })

export const downloadFromFileBank = (
  brandId: Id,
  publicLinkToken: null | string,
  fileIds: Array<Id>
) =>
  api.post(`/file-bank/${brandId}/download`, {
    body: {
      public_link_token: publicLinkToken,
      file_ids: fileIds,
    },
  })

export const getFileBankFolders = (brandId: Id, options?: Object) =>
  api.get(`/file-bank/${brandId}/folders`, {
    query: options,
  })

export const updateFileBankFolder = (brandId: Id, id: Id, folderData: Object) =>
  api.put(`/file-bank/${brandId}/folders/${id}`, {
    body: {
      folder: folderData,
    },
  })

export const createFileBankFolder = (brandId: Id, folderData: Object) =>
  api.post(`/file-bank/${brandId}/folders`, {
    body: {
      folder: folderData,
    },
  })

export const deleteFileBankFolder = (brandId: Id, id: Id) =>
  api.delete(`/file-bank/${brandId}/folders/${id}`)
