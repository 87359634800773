/* @flow */

import React, { Component } from 'react'
import styled from 'styled-components'

import ArrayFilter from './Filters/ArrayFilter'
import CheckboxFilter from './Filters/CheckboxFilter'
import DateFilter from './Filters/DateFilter'
import EnumFilter from './Filters/EnumFilter'
import NumericFilter from './Filters/NumericFilter'
import StringFilter from './Filters/StringFilter'

import type { DataTableAttribute, Filter } from '../../../app/types'

type Props = {
  attribute: DataTableAttribute,
  values: $Shape<Filter>,
}

export default class FilterForm extends Component<Props, void> {
  render() {
    const { attribute, dataCache, dataCacheActions, values } = this.props

    switch (attribute.type) {
      case 'enum':
        return (
          <EnumFilter availableValues={attribute.values} value={values.value} />
        )
      case 'checkbox':
        return (
          <CheckboxFilter
            availableValues={attribute.values}
            operator={values.operator}
            value={values.value}
          />
        )
      case 'array':
        return (
          <ArrayFilter
            availableValues={attribute.values}
            operator={values.operator}
            value={values.value}
          />
        )
      case 'number':
        return <NumericFilter operator={values.operator} value={values.value} />
      case 'string':
        return <StringFilter operator={values.operator} value={values.value} />
      case 'date':
        return <DateFilter operator={values.operator} value={values.value} />
      case 'render':
        const RenderComponent = attribute.render

        return (
          <RenderComponent
            dataCache={dataCache}
            dataCacheActions={dataCacheActions}
            operator={values.operator}
            value={values.value}
          />
        )
      default:
        return null
    }
  }
}
