import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
} from '../actions/search'

const initialState = {
  isFetching: false,
  result: [],
}

export default function search(state = initialState, action) {
  switch (action.type) {
    case SEARCH_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case SEARCH_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        result: action.payload,
      })
    case SEARCH_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
      })
    default:
      return state
  }
}
