/* @flow */

import * as React from 'react'
import memoize from 'memoize-one'
import sortBy from 'lodash/sortBy'
import { PureComponent } from 'react'
import styled from 'styled-components'
import { Portal } from 'react-portal'
import onClickOutside from 'react-onclickoutside'

import FilterForm from './FilterForm'
import { createEmptyFilter } from './shared'

import type { DataTableAttribute, Filter } from '../types'

type Props = {
  attributes: Array<DataTableAttribute>,
  enableAdvancedFilter: boolean,
  filters: Array<Filter>,
  onChange: (
    filters: Array<Filter>,
    attribute: DataTableAttribute,
    action: 'add' | 'edit' | 'remove',
    changedFilter: Filter
  ) => void,
  pinnable?: boolean,
}

type State = {
  addFilter: null | Filter,
  addAdvancedFilter: boolean,
  showMenu: boolean,
  leftPosition: number,
  topPosition: number,
}

class FiltersMenu extends PureComponent<Props, State> {
  static defaultProps = {
    enableAdvancedFilter: false,
    pinnable: false,
  }

  state = {
    addFilter: null,
    addAdvancedFilter: false,
    showMenu: false,
    leftPosition: 0,
    topPosition: 0,
  }

  render() {
    const {
      activeAdvancedFilter,
      attributes,
      dataCache,
      dataCacheActions,
      enableAdvancedFilter,
      onPinChange,
      pinnedAttributes,
      pinnable,
    } = this.props
    const {
      addFilter,
      addAdvancedFilter,
      leftPosition,
      showMenu,
      topPosition,
    } = this.state

    const sortedAttributes = sortAttributes(attributes)

    return (
      <Container>
        <AddFilterLink onClick={this._onAddClick}>
          <span className="glyphicon glyphicon-plus" /> Add filter
        </AddFilterLink>
        {showMenu === true && (
          <Portal>
            <MenuWithOutsideClick
              attributes={sortedAttributes}
              enableAdvancedFilter={enableAdvancedFilter}
              onAdd={this._onAddFilter}
              onAddAdvancedFilter={this._onAddAdvancedFilter}
              onHide={this._hideMenu}
              onPinChange={onPinChange}
              pinnedAttributes={pinnedAttributes}
              pinnable={pinnable}
              style={{ left: leftPosition, top: topPosition }}
            />
          </Portal>
        )}
        {addFilter && (
          <FilterForm
            attribute={addFilter.attribute}
            dataCache={dataCache}
            dataCacheActions={dataCacheActions}
            filter={addFilter.values}
            onHide={this._onCancelAddFilter}
            onSubmit={this._onSubmitAddFilter}
          />
        )}
      </Container>
    )
  }

  _hideMenu = () => {
    this.setState({
      showMenu: false,
    })
  }

  _onAddClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    ;(event.currentTarget: HTMLButtonElement)

    const rect = event.currentTarget.getBoundingClientRect()

    const leftPosition = rect.left + window.scrollX
    const topPosition = rect.top + window.scrollY

    this.setState({
      leftPosition: leftPosition,
      showMenu: true,
      topPosition: topPosition + rect.height + 10,
    })
  }

  _onAddAdvancedFilter = (event: SyntheticEvent<HTMLButtonElement>) => {
    ;(event.currentTarget: HTMLButtonElement)

    this.props.onAddAdvancedFilter()
    this.setState({
      showMenu: false,
    })
  }

  _onAddFilter = (property: string) => {
    const { attributes } = this.props

    const attribute = attributes.find(a => a.property === property)

    if (!attribute) {
      return
    }

    this.setState({
      addFilter: {
        attribute,
        values: createEmptyFilter(attribute),
      },
      showMenu: false,
    })
  }

  _onSubmitAddFilter = (values, filter, attribute) => {
    const updatedFilters = [...this.props.filters, values]

    this.props.onChange(updatedFilters, attribute, 'add', values)

    this.setState({
      addFilter: false,
      addAdvancedFilter: false,
    })
  }

  _onCancelAddFilter = () => {
    this.setState({
      addFilter: null,
    })
  }

  _onCancelAddAdvancedFilter = () => {
    this.setState({
      addAdvancedFilter: false,
    })
  }
}

export default FiltersMenu

const sortAttributes = memoize(attributes => sortBy(attributes, 'label'))

const Container = styled.div`
  position: relative;
`

const AddFilterLink = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border: none;
  color: #25a3ce;
  font-size: 12px;
`

type MenuProps = {
  attributes: Array<DataTableAttribute>,
  onAdd: (attribute: string) => void,
  onHide: Function,
  pinnable: boolean,
  style: Object,
}

class Menu extends PureComponent<MenuProps, void> {
  handleClickOutside = event => {
    this.props.onHide()
  }

  render() {
    const {
      attributes,
      enableAdvancedFilter,
      onPinChange,
      pinnedAttributes,
      pinnable,
      style,
    } = this.props

    return (
      <MenuContainer style={style}>
        <MenuOverflow>
          {enableAdvancedFilter && (
            <>
              <AttributeButtonStyle>
                <AttributeButton
                  key="add_advanced_filter"
                  onClick={this.props.onAddAdvancedFilter}
                >
                  Add advanced filter
                </AttributeButton>
              </AttributeButtonStyle>

              <MenuHr />
            </>
          )}

          {attributes.map(attribute => {
            const isPinned =
              pinnedAttributes && pinnedAttributes.includes(attribute.property)
            return (
              <AttributeButtonStyle>
                <AttributeButton
                  key={attribute.property}
                  onClick={() => this.props.onAdd(attribute.property)}
                >
                  {attribute.label}
                </AttributeButton>

                {pinnable && (
                  <PinButton
                    className="filters-menu-pin-button"
                    onClick={() => onPinChange(attribute.property)}
                    pinned={isPinned}
                  >
                    <span className="glyphicon glyphicon-pushpin" />
                  </PinButton>
                )}
              </AttributeButtonStyle>
            )
          })}
        </MenuOverflow>
      </MenuContainer>
    )
  }
}

const MenuWithOutsideClick = onClickOutside(Menu)

// z-index is so it works in modals
const MenuContainer = styled.div`
  background: #fff;
  bottom: 20px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  height: auto;
  min-height: 200px;
  min-width: 200px;
  position: absolute;
  z-index: 9999999999;
`

const MenuHr = styled.hr`
  margin: 0;
`

const MenuOverflow = styled.div`
  flex: 1 1 auto;
  overflow-y: scroll;
`

const AttributeButtonStyle = styled.div`
  background: transparent;
  border: none;
  display: flex;
  padding: 7px 6px;
  width: 100%;

  :hover {
    background-color: #318fb3;
    color: white;

    .filters-menu-pin-button {
      display: block;
    }
  }
`

const AttributeButton = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: none;
  flex: 1;
  outline: 0;
  text-align: left;
`

const PinButton = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: none;
  display: ${({ pinned }) => (pinned ? 'block' : 'none')};
  outline: 0;
`
