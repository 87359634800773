/* @flow */

import * as React from 'react'
import styled from 'styled-components'
import {
  type Location as LocationType,
  type Match,
  type RouterHistory,
} from 'react-router-dom'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { withBreakpoints } from 'react-breakpoints'
import querystring from 'qs'

import WebshopTemplate from './WebshopTemplateV2'

import { useAvailableBrandsList, useFetchBrandIfNeeded } from '../brands/hooks'
import { useLocalStorage } from '../../infrastructure/hooks'
import { mapDropsAndDeliveriesIntoDeliveryLines } from '../orders/shared'
import {
  useWebshopFavorites,
  useWebshopNavigationLinks,
  useWebshopSession,
  useWebshopV2Totals,
} from './hooks'
import { updateWebshopSession } from './api'
import { switchEntity } from '../template/actions'

import { WebshopContext } from './shared'
import { SessionContext, mixpanelTrack } from '../shared'

type Props = {
  children?: React.Node,
  currentBreakpoint: 'mobile' | 'desktop' | 'tablet',
  location: LocationType,
  history: RouterHistory,
  match: Match,
}

const WebshopSessionV2 = ({
  children,
  currentBreakpoint,
  dispatch,
  history,
  location,
  match,
}: Props) => {
  const {
    params: { brandId },
  } = match
  const { brands, entity } = React.useContext(SessionContext)

  const brand = React.useMemo(() => {
    return brands[brandId]
  }, [brands, brandId])

  useFetchBrandIfNeeded(brandId, dispatch)

  React.useEffect(() => {
    mixpanelTrack('webshop.open', {
      brand_id: brandId,
    })
  }, [brandId])

  const webshopSessionLocalStorageId = `webshop_session_${brandId}`
  const [
    storageWebshopSessionId,
    setUseWebshopSessionId,
    isUseWebshopSessionIdInitialized,
  ] = useLocalStorage(webshopSessionLocalStorageId, null)

  // Query string
  const query = React.useMemo(() => {
    return querystring.parse(location.search.slice(1))
  }, [location.search])

  const useWebshopSessionId =
    query.webshop_session_id || storageWebshopSessionId

  React.useEffect(() => {
    if (
      query.webshop_session_id &&
      query.webshop_session_id != storageWebshopSessionId
    ) {
      setUseWebshopSessionId(query.webshop_session_id)

      const newQuery = { ...query }
      delete newQuery.webshop_session_id

      history.replace(`${location.pathname}?${querystring.stringify(newQuery)}`)
    }
  }, [
    location.pathname,
    history,
    query,
    setUseWebshopSessionId,
    storageWebshopSessionId,
  ])

  // Session
  const useWebshopSessionArgs = React.useMemo(
    () => [brandId, null, useWebshopSessionId],
    [brandId, useWebshopSessionId]
  )
  const useWebshopSessionFetchOptions = React.useMemo(
    () => ({
      ignore: !isUseWebshopSessionIdInitialized,
    }),
    [isUseWebshopSessionIdInitialized]
  )

  const [
    webshopSession,
    isFetchingWebshopSession,
    {
      initialized: isWebshopSessionInitialized,
      refresh: refreshWebshopSession,
    },
  ] = useWebshopSession(useWebshopSessionArgs, useWebshopSessionFetchOptions)

  // navigation
  const navigationFetchArgs = React.useMemo(() => {
    return [brand ? brand.id : null, webshopSession ? webshopSession.id : null]
  }, [brand, webshopSession])
  const navigationFetchOptions = React.useMemo(() => {
    return {
      ignore: !brand || !webshopSession,
    }
  }, [brand, webshopSession])
  const [
    navigationLinks,
    isFetchingNavigationLinks,
    {
      isInitialized: isNavigationLinksInitialized,
      refresh: refreshNavigationLinks,
    },
  ] = useWebshopNavigationLinks(navigationFetchArgs, navigationFetchOptions)

  const [{ brands: availableBrands }] = useAvailableBrandsList()
  const availableBrand = React.useMemo(() => {
    return availableBrands.find(b => b.id == brandId)
  }, [availableBrands, brandId])

  const webshopSessionId = webshopSession ? webshopSession.id : null
  const onUpdateWebshopSession = React.useCallback(
    (data, formikProps) => {
      if (!webshopSessionId) {
        return
      }

      return updateWebshopSession(brandId, webshopSessionId, data).then(
        response => {
          if (formikProps) {
            formikProps.setSubmitting(false)
          }

          if (!response.error) {
            return refreshWebshopSession()
          }

          return response
        }
      )
    },
    // use webshop session ID to prevent callback to refresh every time
    // session updates (can cause infinite loop in cart contact step)
    [brandId, refreshWebshopSession, webshopSessionId]
  )

  const [showRetailerSelector, setShowRetailerSelector] = React.useState(false)
  const isLoggingInToCustomerConnectionRef = React.useRef(false)
  const loginToCustomerConnection = React.useCallback(
    connection => {
      const shopId = connection.shop_id

      const complete = () => {
        return onUpdateWebshopSession({
          customer_id: connection.customer_id,
        }).then(response => {
          if (!response.error) {
            setShowRetailerSelector(false)
          }

          return response
        })
      }

      if (entity.id != shopId) {
        // When the switch_entity_customer_id query parameter is set and we are waiting
        // for switch entity request to finish, loginToCustomerConnection can be called
        // and we need to make sure we are not calling switch entity endpoint again.
        if (isLoggingInToCustomerConnectionRef.current) {
          return
        }

        const newQuery = {
          ...query,
          switch_entity_customer_id: connection.customer_id,
        }

        // when we change entity the webshop will reload. we therefore
        // have to "save our intent" in the query string, otherwise
        // it will be a race condition between this call and the useEffect call
        // further down
        history.replace(
          `${location.pathname}?${querystring.stringify(newQuery)}`
        )

        isLoggingInToCustomerConnectionRef.current = true

        return dispatch(switchEntity(shopId)).then(response => {
          isLoggingInToCustomerConnectionRef.current = false
          return response
        })
      } else {
        return complete()
      }
    },
    [
      brandId,
      dispatch,
      entity,
      history,
      location,
      onUpdateWebshopSession,
      query,
    ]
  )

  // ENSURE CUSTOMER
  React.useEffect(() => {
    // Brands do not have availableBrand set so we check only for retailers
    if (brandId == entity.id) {
      return
    }

    if (!webshopSession) {
      return
    }

    if (!availableBrand) {
      return
    }

    const availableConnections = availableBrand.retailer_connections.filter(
      connection => {
        return connection.b2b_access === true
      }
    )
    const availableShopIds = availableConnections
      // we have to check if the sessions shop_id is equal the actual
      // entity we are logged in as
      .filter(connection => connection.shop_id == entity.id)
      .map(connection => connection.shop_id)

    // we are already logged into a customer that is of current entity
    if (
      webshopSession.customer_id &&
      webshopSession.shop_id &&
      webshopSession.shop_id == entity.id &&
      availableShopIds.includes(webshopSession.shop_id)
    ) {
      return
    }

    // This is when we have to switch entity in order to use a different customer
    // It has to happen here since switching entity will reload the entire app
    // and if we dont "store the intent" of the customer switch, then we end up with
    // a race condition where we most likely switch entity without switching the
    // customer of the webshop session
    if (
      query.switch_entity_customer_id &&
      webshopSession.customer_id != query.switch_entity_customer_id
    ) {
      const connection = availableConnections.find(
        connection => connection.customer_id == query.switch_entity_customer_id
      )

      // Update the webshop session customer id only after the switch entity request
      // finishes and the user is logged in to correct shop entity, we dont want to
      // update the webshop session of the wrong shop entity
      if (connection && entity.id == connection.shop_id) {
        onUpdateWebshopSession({
          customer_id: connection.customer_id,
        }).then(response => {
          return response
        })

        return
      }
    }

    if (availableConnections.length > 1) {
      setShowRetailerSelector(true)
    } else {
      loginToCustomerConnection(availableConnections[0])
    }
  }, [
    availableBrand,
    brand,
    brandId,
    entity,
    loginToCustomerConnection,
    query.switch_entity_customer_id,
    onUpdateWebshopSession,
    setShowRetailerSelector,
    webshopSession,
  ])

  // Favorites
  const useWebshopFavoritesOptions = React.useMemo(
    () => ({
      ignore: !brand,
    }),
    [brand]
  )
  const [favorites, isFetchingFavorites, { refresh: refreshWebshopFavorites }] =
    useWebshopFavorites(brandId, useWebshopFavoritesOptions)

  // Totals
  const totalsRequestArgs = React.useMemo(() => {
    return [brandId, webshopSession ? webshopSession.id : null]
  }, [brandId, webshopSession])
  const totalsRequestOptions = React.useMemo(() => {
    return {
      ignore: !webshopSession,
    }
  }, [webshopSession])
  const [totals, isFetchingTotals, { refresh: refreshWebshopTotals }] =
    useWebshopV2Totals(totalsRequestArgs, totalsRequestOptions)

  const currency = webshopSession ? webshopSession.currency : null
  const customer = webshopSession ? webshopSession.customer : null
  const drops = brand ? brand.drops : []
  const isMobile = currentBreakpoint === 'mobile'
  const webshopSettings = brand ? brand.settings.webshop_settings : {}

  const webshopContext = React.useMemo(() => {
    return {
      availableBrand,
      brand,
      currency,
      customer,
      drops,
      deliveryLines: mapDropsAndDeliveriesIntoDeliveryLines(drops),
      favorites,
      isMobile,
      isNavigationLinksInitialized,
      navigationLinks,
      shopUrl: `/shop/${brandId}`,
      totals,
      query,
      refreshNavigationLinks,
      refreshWebshopFavorites,
      refreshWebshopTotals,
      setUseWebshopSessionId,
      updateWebshopSession: onUpdateWebshopSession,
      webshopDesignSettings: brand ? brand.webshop_design_settings : {},
      webshopSession,
      webshopSettings,
    }
  }, [
    availableBrand,
    brand,
    brandId,
    currency,
    customer,
    drops,
    favorites,
    isMobile,
    isNavigationLinksInitialized,
    navigationLinks,
    onUpdateWebshopSession,
    refreshNavigationLinks,
    refreshWebshopTotals,
    refreshWebshopFavorites,
    setUseWebshopSessionId,
    query,
    totals,
    webshopSession,
    webshopSettings,
  ])

  const RetailerSelectorModal = (
    <>
      {' '}
      {availableBrand && (
        <Modal show={showRetailerSelector}>
          <Modal.Body>
            <p>
              {availableBrand.name} has added you as 2 separate customers. You
              must therefore choose which customer you wish to shop as. Do not
              worry, you can change this again later.
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th>Company</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {availableBrand.retailer_connections.map(connection => {
                  return (
                    <tr>
                      <td>{connection.shop_name}</td>
                      <td className="listview-action">
                        <button
                          type="button"
                          className="btn btn-block btn-success btn-sm"
                          onClick={() => loginToCustomerConnection(connection)}
                        >
                          Shop as {connection.shop_name}
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      )}
    </>
  )

  // if we are logged into a shop with no connection to this brand
  if (!brand && showRetailerSelector) {
    return RetailerSelectorModal
  }

  if (!brand || !webshopSession) {
    return null
  }

  return (
    <div>
      {RetailerSelectorModal}
      <WebshopContext.Provider value={webshopContext}>
        <WebshopTemplate
          brand={brand}
          history={history}
          isMobile={isMobile}
          location={location}
          key={webshopSession.id}
          match={match}
          setShowRetailerSelector={setShowRetailerSelector}
        >
          {children}
        </WebshopTemplate>
      </WebshopContext.Provider>
    </div>
  )
}

const WebshopSessionV2WithBreakpoints = withBreakpoints(WebshopSessionV2)

export default connect()(WebshopSessionV2WithBreakpoints)

const ErrorModal = ({
  errorCode,
  show,
}: {
  errorCode: string,
  show: boolean,
}) => {
  return (
    <Modal show={show}>
      <Modal.Body>
        <p>
          An error occurred. This was not supposed to happen. Please contact
          Traede support at{' '}
          <a href="mailto:support@traede.com">support@traede.com</a> or
          telephone (+45) 71 99 00 66 and give them this code: {errorCode}
        </p>
      </Modal.Body>
    </Modal>
  )
}
