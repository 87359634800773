/* @flow */

import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import immer, { original, setAutoFreeze } from 'immer'

export const splitProductsIntoTableSectionsByData = (
  productRows,
  dataByProduct,
  dataConfigs,
  tableSectionConfig,
  tableData
) => {
  const defaultTableSections = [
    {
      tableSectionData: {},
      tableSectionKey: 'traede_no_table_sections',
      tableSectionLabel: null,
      always_show_header: false,
      data_cache: dataByProduct,
      lines: {},
      products: productRows,
    },
  ]

  if (!tableSectionConfig || !tableSectionConfig.data_source) {
    return defaultTableSections
  }

  // sources needs to be an array. check delivery_lines() for more info
  let sources = tableSectionConfig.data_source
  if (!Array.isArray(sources)) {
    sources = [sources]
  }

  const extra_data = tableSectionConfig.extra_data

  const splitsByKey = {}
  for (let product of productRows) {
    for (let sourceConfig of sources) {
      const { source, property } = sourceConfig

      const dataOfProduct = dataByProduct[product.id] || {}

      const dataOfSource = dataOfProduct[source]

      if (!dataOfSource) {
        continue
      }

      for (let [variantId, dataOfVariant] of Object.entries(
        dataOfSource.data
      )) {
        for (let dataEntry of dataOfVariant) {
          const value = dataEntry[property]
          let splitData = {
            [property]: value,
          }

          if (extra_data) {
            for (let extraProperty of extra_data) {
              splitData[extraProperty] = dataEntry[extraProperty]
            }
          }

          const splitKey = JSON.stringify(splitData)

          if (tableSectionConfig.map) {
            splitData = tableSectionConfig.map({
              data: splitData,
              tableData,
              value,
            })
          }

          if (!splitsByKey[splitKey]) {
            splitsByKey[splitKey] = createNewTableSection(
              splitData,
              splitKey,
              tableSectionConfig.label
                ? tableSectionConfig.label({
                    data: splitData,
                    tableData,
                    value,
                  })
                : value,
              {},
              tableSectionConfig.always_show_header || false
            )
          }

          if (!splitsByKey[splitKey].products[product.id]) {
            splitsByKey[splitKey].products[product.id] = product
            splitsByKey[splitKey].data_cache[product.id] = {
              ...(dataByProduct[product.id] || {}),
            }

            // if you have multiple sources it's important that we
            // reset all of them when we initialize our product. e.g.
            // if you have delivery_status + availability then we need to
            // reset both of them so that they can be built up again
            for (let sourceConfig2 of sources) {
              splitsByKey[splitKey].data_cache[product.id][
                sourceConfig2.source
              ] = {
                ...dataByProduct[product.id][sourceConfig2.source],
                data: {},
              }
            }
          }

          if (
            !splitsByKey[splitKey].data_cache[product.id][source].data[
              variantId
            ]
          ) {
            splitsByKey[splitKey].data_cache[product.id][source].data[
              variantId
            ] = []
          }

          /*
          if we do it like this then when you have multiple data sources (like with delivery_status + availability in ShowOrderV3)
          then you will end in an infinite loop since you are pusing to the same array you are iterating over

          splitsByKey[splitKey].data_cache[product.id][source].data[
            variantId
          ].push(dataEntry)
          */
          splitsByKey[splitKey].data_cache[product.id][source].data[variantId] =
            [
              ...splitsByKey[splitKey].data_cache[product.id][source].data[
                variantId
              ],
              dataEntry,
            ]
        }
      }
    }
  }

  const tableSectionValues = Object.values(splitsByKey)

  if (tableSectionValues.length === 0) {
    return defaultTableSections
  }

  const originalProductIdSort = productRows.map(product => product.id)

  return tableSectionValues.map(split => ({
    ...split,
    // we sort to make sure the products hold the same sort inside the table section
    products: sortBy(Object.values(split.products), product =>
      originalProductIdSort.indexOf(product.id)
    ),
  }))
}

export const addLinesToTableSections = (
  tableSections,
  productRows,
  dataByProduct,
  lines,
  linesByProductId,
  tableSectionConfig,
  tableData,
  useLinesReducerV2
) => {
  let useLinesByProductId = linesByProductId
  if (!useLinesReducerV2) {
    useLinesByProductId = groupBy(lines, 'product_id')
  }

  if (!tableSectionConfig || !tableSectionConfig.lines) {
    return [{ ...tableSections[0], lines: useLinesByProductId }]
  }

  let dataSourcesOfConfig = []
  if (tableSectionConfig.data_source) {
    dataSourcesOfConfig = tableSectionConfig.data_source
  }
  if (!Array.isArray(dataSourcesOfConfig)) {
    dataSourcesOfConfig = [dataSourcesOfConfig]
  }

  const dataSourceKeysOfConfig = dataSourcesOfConfig.map(d => d.source)

  // table section label might be a react component, which does not work
  // if immer freezes the object
  setAutoFreeze(false)

  const updatedTableSections = immer(tableSections, updatedTableSections => {
    const property = tableSectionConfig.lines.property
    const extra_data = tableSectionConfig.extra_data

    const sortTableSectionProducts = new Set()
    for (let [productId, linesOfProduct] of Object.entries(
      useLinesByProductId
    )) {
      const product = productRows.find(p => p.id == productId)

      if (!product) {
        continue
      }

      const splitIndexes = []
      for (let line of linesOfProduct) {
        const value = line[property]
        let splitData = {
          [property]: value,
        }

        if (extra_data) {
          for (let extraProperty of extra_data) {
            splitData[extraProperty] = line[extraProperty]
          }
        }

        const splitKey = JSON.stringify(splitData)

        let splitIndex = updatedTableSections.findIndex(
          t => t.tableSectionKey === splitKey
        )

        if (splitIndex === -1) {
          splitIndex = updatedTableSections.length

          if (tableSectionConfig.map) {
            splitData = tableSectionConfig.map({
              data: splitData,
              tableData,
              value,
            })
          }

          updatedTableSections.push(
            createNewTableSection(
              splitData,
              splitKey,
              tableSectionConfig.label
                ? tableSectionConfig.label({
                    data: splitData,
                    tableData,
                    value,
                  })
                : value,
              [],
              tableSectionConfig.always_show_header || false
            )
          )
        }

        if (!splitIndexes.includes(splitIndex)) {
          splitIndexes.push(splitIndex)
        }

        if (!updatedTableSections[splitIndex].lines[line.product_id]) {
          updatedTableSections[splitIndex].lines[line.product_id] = []
        }

        updatedTableSections[splitIndex].lines[line.product_id].push(line)

        const foundProduct = updatedTableSections[splitIndex].products.find(
          p => p.id === line.product_id
        )

        if (!foundProduct) {
          updatedTableSections[splitIndex].products.push(product)

          sortTableSectionProducts.add(splitIndex)
        }
      }

      if (dataByProduct[product.id]) {
        for (let splitIndex of splitIndexes) {
          if (!updatedTableSections[splitIndex].data_cache[product.id]) {
            updatedTableSections[splitIndex].data_cache[product.id] = {}
          }

          for (let [dataSourceKey, dataSource] of Object.entries(
            dataByProduct[product.id]
          )) {
            // If we have created a new table section from lines (e.g. it did not already get created from data source by splitProductsIntoTableSectionsByData)
            // then we should not copy the data of the table_sections data config
            // e.g. if we split lines and inventory status by drop_id we should not copy inventory levels to table sections created
            //      solely from lines (since there was no inventory rows to create that section in the first place)
            if (dataSourceKeysOfConfig.includes(dataSourceKey)) {
              if (
                !updatedTableSections[splitIndex].data_cache[product.id][
                  dataSourceKey
                ]
              ) {
                updatedTableSections[splitIndex].data_cache[product.id][
                  dataSourceKey
                ] = { isFetching: false, data: {} }
              }

              continue
            }

            // add product-level data to the table section that is not related to
            // the table_sections (e.g. pricing)
            updatedTableSections[splitIndex].data_cache[product.id][
              dataSourceKey
            ] = dataSource
          }
        }
      }
    }

    if (sortTableSectionProducts.size > 0) {
      // we sort to make sure the products hold the same sort inside the table section
      const originalProductIdSort = productRows.map(product => product.id)

      for (let key of sortTableSectionProducts) {
        updatedTableSections[key].products = sortBy(
          updatedTableSections[key].products,
          product => originalProductIdSort.indexOf(product.id)
        )
      }
    }

    const defaultIndex = updatedTableSections.findIndex(
      s => s.tableSectionKey === 'traede_no_table_sections'
    )

    if (defaultIndex !== -1 && updatedTableSections.length > 1) {
      const linesOfDefaultTableSection =
        updatedTableSections[defaultIndex].lines

      const noLinesInDefaultSection = Object.keys(
        linesOfDefaultTableSection
      ).reduce(
        (carry, key) => (carry += linesOfDefaultTableSection[key].length),
        0
      )

      if (noLinesInDefaultSection === 0) {
        updatedTableSections.splice(defaultIndex, 1)
      }
    }
  })

  setAutoFreeze(true)

  return updatedTableSections
}

export const hideProductsWithoutLinesIfPreviewMode = (
  tableSections,
  preview
) => {
  if (!preview) {
    return tableSections
  }

  return tableSections
    .map(section => {
      return {
        ...section,
        products: section.products.filter(product => {
          const linesOfProduct = section.lines[product.id] || []

          return linesOfProduct.length > 0
        }),
      }
    })
    .filter(section => section.ensured === true || section.products.length > 0)
}

export const ensureTableSections = (
  calculatedTableSections,
  tableSectionConfig,
  tableData
) => {
  const property = tableSectionConfig.lines.property
  const extra_data = tableSectionConfig.extra_data

  const checkProps = [property].concat(extra_data || [])
  for (let tableSectionToEnsure of tableSectionConfig.ensure) {
    let found = false

    for (let tableSection of calculatedTableSections) {
      let matches = true
      for (let checkProperty of checkProps) {
        if (
          tableSection.tableSectionData[checkProperty] !=
          tableSectionToEnsure[checkProperty]
        ) {
          matches = false
          break
        }
      }

      if (matches) {
        found = true
        break
      }
    }

    if (!found) {
      const value = tableSectionToEnsure[property]
      const splitData = {
        [property]: tableSectionToEnsure[property],
      }

      if (extra_data) {
        for (let extraProperty of extra_data) {
          splitData[extraProperty] = tableSectionToEnsure[extraProperty]
        }
      }

      calculatedTableSections.push(
        createNewTableSection(
          splitData,
          JSON.stringify(splitData),
          tableSectionConfig.label
            ? tableSectionConfig.label({
                data: splitData,
                tableData,
                value,
              })
            : value,
          [],
          tableSectionConfig.always_show_header || false,
          true
        )
      )
    }
  }

  return calculatedTableSections
}

const createNewTableSection = (
  splitData,
  splitKey,
  label,
  products,
  alwaysShowHeader,
  ensured = false
) => {
  return {
    ensured,
    tableSectionData: splitData,
    tableSectionKey: splitKey,
    tableSectionLabel: label,
    data_cache: {},
    lines: {},
    products: products,
    always_show_header: alwaysShowHeader,
  }
}

export const sortTableSections = (
  tableSections,
  tableSectionsConfig,
  dataCacheDataTable
) => {
  if (!tableSectionsConfig || !tableSectionsConfig.sort) {
    return tableSections
  }

  return sortBy(tableSections, section =>
    tableSectionsConfig.sort(section, dataCacheDataTable)
  )
}
