/* @flow */

import { createApiHook, quickCreateHook } from '../hooks'
import type {
  File,
  FileBankFileRequestOptions,
  FileBankFolder,
  FileBankSession,
  Id,
} from '../types'
import {
  getFileBankAttributes,
  getFileBankFolder,
  getFileBankFolders,
  getFileBankSelection,
  ensureAnonymousFileBankSession,
  ensureFileBankSession,
  fileBankSearch,
} from './api'

const {
  hook: useFileBankFolder,
  hookCached: useCachedFileBankFolder,
  clearCache: clearFileBankFolderCache,
} = quickCreateHook<{
  folder: FileBankFolder,
  subfolders: Array<FileBankFolder>,
  breadcrumbs: Array<FileBankFolder>,
}>(getFileBankFolder, null, null)

export { useFileBankFolder, useCachedFileBankFolder, clearFileBankFolderCache }

const fetchFileBankSearchForHook = (
  brandId: Id,
  options: FileBankFileRequestOptions
) =>
  fileBankSearch(brandId, options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload,
      }
    } else {
      return response
    }
  })

export const useFileBankSearch = createApiHook<{
  files: Array<File>,
  total: number,
}>(fetchFileBankSearchForHook, { files: [], total: 0 })

const {
  hook: useFileBankAttributes,
  hookCached: useCachedFileBankAttributes,
  clearCache: clearFileBankAttributesCache,
} = quickCreateHook<
  Array<{ key: string, label: string, options: Array<Object> }>
>(getFileBankAttributes, null, null)

export {
  useFileBankAttributes,
  useCachedFileBankAttributes,
  clearFileBankAttributesCache,
}

export const useAnonymousFileBankSession = createApiHook<FileBankSession>(
  (brandId: Id, publicLinkToken: null | string) => {
    return ensureAnonymousFileBankSession(brandId, publicLinkToken).then(
      response => {
        return !response.error ? { entity: response.payload.session } : response
      }
    )
  }
)

export const useFileBankSession = createApiHook<FileBankSession>(
  (brandId: Id) => {
    return ensureFileBankSession(brandId).then(response => {
      return !response.error ? { entity: response.payload.session } : response
    })
  }
)

export const useFileBankSelection = createApiHook<Array<File>>(
  (brandId: Id, publicLinkToken: null | string) => {
    return getFileBankSelection(brandId, publicLinkToken).then(response => {
      return !response.error ? { entity: response.payload } : response
    })
  }
)

const {
  hook: useFileBankFolders,
  hookCached: useCachedFileBankFolders,
  clearCache: clearFileBankFoldersCache,
} = quickCreateHook<Array<FileBankFolder>>(getFileBankFolders, null, [])

export {
  useFileBankFolders,
  useCachedFileBankFolders,
  clearFileBankFoldersCache,
}
