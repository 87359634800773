/* @flow */

import React from 'react'
import styled from 'styled-components'

import PayingCustomerSwitcher from '../../../../admin/PayingCustomerSwitcher'
import { SecondaryMenuHoverContainer } from './shared'
import { Submenu, FakeSubmenuNavigationLink } from '../shared'

const PayingCustomerSwitcherList = props => {
  return <PayingCustomerSwitcher render={renderList} {...props} />
}

const renderList = ({
  inWebshop,
  payingCustomers,
  onSwitchEntity,
  sessionEntity,
  type,
  useNewColors,
}) => {
  return (
    <PayingCustomerSwitcherListContainer>
      <HoverContainer useNewColors={useNewColors} type={type}>
        <span className="glyphicon glyphicon-fire" />

        <PayingCustomersSubmenu>
          <PayingCustomersContainer>
            {payingCustomers
              .filter(p => p.active)
              .map(p => (
                <FakeSubmenuNavigationLink
                  key={p.entity.id}
                  onClick={() => onSwitchEntity(p.entity.id)}
                >
                  {p.entity.name} ({p.entity.id})
                </FakeSubmenuNavigationLink>
              ))}
          </PayingCustomersContainer>
        </PayingCustomersSubmenu>
      </HoverContainer>
    </PayingCustomerSwitcherListContainer>
  )
}

export default PayingCustomerSwitcherList

const PayingCustomersSubmenu = styled(Submenu)`
  min-width: 200px;
`

const HoverContainer = styled(SecondaryMenuHoverContainer)`
  position: relative;

  &:hover ${PayingCustomersSubmenu} {
    display: block;
  }
`

const PayingCustomerSwitcherListContainer = styled.div`
  display: flex;
  width: 100%;
`

const PayingCustomersContainer = styled.div`
  max-height: 400px;
  overflow-y: scroll;
`
