/* @flow */

import api from '../../api'

import type { ApiResponse, Notifications } from '../../types'

export const getNotifications = (options?: Object) =>
  api.get('/notifications', {
    query: options,
  })

export const updateNotificationsReadStatus = notifications =>
  api.post('/notifications/update-read-status', {
    body: {
      notifications,
    },
  })

export const updateNotificationsSeenStatus = notifications =>
  api.post('/notifications/update-seen-status', {
    body: {
      notifications,
    },
  })
