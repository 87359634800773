/* @flow */

export const generateLinesByProduct = (currentLinesByProduct, lines) => {
  const newLinesByProduct = { ...currentLinesByProduct }

  let changedAnything = false
  for (let line of lines) {
    // we have to ignore deleted lines to avoid infinite loop
    if (line.delete === true) {
      continue
    }

    if (!newLinesByProduct[line.product_id]) {
      changedAnything = true
      newLinesByProduct[line.product_id] = {}
    }

    if (newLinesByProduct[line.product_id][line.id] !== line) {
      changedAnything = true
      newLinesByProduct[line.product_id] = {
        ...newLinesByProduct[line.product_id],
        [line.id]: line,
      }
    }
  }

  // Remove lines from map that were removed from array
  const allLineIds = lines.filter(l => !l.delete).map(l => String(l.id))
  for (let [productId, lines] of Object.entries(newLinesByProduct)) {
    const lineIdsOfProduct = Object.keys(lines)

    for (let lineId of lineIdsOfProduct) {
      // the line was removed from input lines
      if (!allLineIds.includes(lineId)) {
        changedAnything = true
        newLinesByProduct[productId] = { ...newLinesByProduct[productId] }

        delete newLinesByProduct[productId][lineId]
      }
    }
  }

  return changedAnything ? newLinesByProduct : currentLinesByProduct
}

export const prepareProductTableLinesForApi = (
  lines,
  setEmptyPropertiesZero = []
) => {
  return lines.map(line => {
    const updatedLine = { ...line }

    // Since we do not want to auto set a field to zero when users enter (becuase that interferes with their input)
    // we have to set empty values to zero before sending to the API
    for (let property of setEmptyPropertiesZero) {
      if (
        typeof updatedLine[property] === 'string' &&
        updatedLine[property].trim().length === 0
      ) {
        updatedLine[property] = 0
      }
    }

    // in the client when we generate configured variants we give them a generated ID, like 'product001-b-s', however
    // this cannot be used by the API so we replace it with the placeholder ID before we persist it
    if (line.__placeholder_variant) {
      updatedLine.variant_id = line.__placeholder_variant
    }

    if (!line.__new_line) {
      return updatedLine
    }

    delete updatedLine.id

    return updatedLine
  })
}

export const getLinesThatDifferFromPersistedLines = (
  persistedLines,
  updatedLines
) => {
  const created = []
  const updatedOrDeleted = []

  // avoid duplicate count
  const useIds = new Set()

  for (let line of persistedLines) {
    const found = updatedLines.find(l => l === line)

    if (!found) {
      updatedOrDeleted.push(updatedLines.find(l => l.id == line.id))
      useIds.add(line.id)
    }
  }
  for (let line of updatedLines) {
    if (useIds.has(line.id)) {
      continue
    }

    const found = persistedLines.find(l => l === line)

    if (!found) {
      created.push(line)
    }
  }

  return [created, updatedOrDeleted]
}

export const calculateNumberOfLineChanges = (persistedLines, updatedLines) => {
  const [created, updatedOrDeleted] = getLinesThatDifferFromPersistedLines(
    persistedLines,
    updatedLines
  )

  return created.length + updatedOrDeleted.length
}
