/* @flow */

export const EDITABLE_TABLE_SHOW_COLUMN = 'EDITABLE_TABLE_SHOW_COLUMN'
export const EDITABLE_TABLE_HIDE_COLUMN = 'EDITABLE_TABLE_HIDE_COLUMN'

export const showColumn = (tableKey: string, x: number, y: number) => ({
  type: EDITABLE_TABLE_SHOW_COLUMN,
  tableKey,
  // When attributes in jsx the x and y props are going to be strings
  x: String(x),
  y: String(y),
})

export const hideColumn = (tableKey: string) => ({
  type: EDITABLE_TABLE_HIDE_COLUMN,
  tableKey,
})
