/* @flow */

import * as React from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'
import isObject from 'lodash/isObject'
import lodashSortBy from 'lodash/sortBy'
import {
  StockLabel,
  StockList,
} from '../../../../infrastructure/components/Stock'
import { Popover } from 'react-bootstrap'
import FormatCurrency from '../../../../infrastructure/components/FormatCurrency'
import classnames from 'classnames'
import {
  IMAGE_RADIUS_CIRCLE,
  IMAGE_RADIUS_ROUNDED,
  IMAGE_SIZE_SMALL,
  IMAGE_SIZE_MEDIUM,
  IMAGE_SIZE_LARGE,
  IMAGE_SIZE_CUSTOM,
} from '../../../settings/brand/constants'
import CloudinaryResource from '../../../../infrastructure/components/CloudinaryResource'
import _ from 'underscore'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import styled from 'styled-components'
import { Map } from 'immutable'
import type { RowSize } from './types'
import type { Product, OrderLine, Variant } from '../../../types'
import { toQuantityTotal } from '../../../orders/components/shared'

import type { ProductTableProps, ProductTableSettings } from './types'

export const PRODUCT_TABLE_CUSTOM_PRESET = 'product_table_custom'

export const showColumn = (
  key: string,
  columns: Array<string>,
  excludeColumns?: Array<string> = []
) => columns.indexOf(key) !== -1 && excludeColumns.indexOf(key) === -1

export const renderColumn = (
  customRenderer: ?Function,
  defaultRenderer: Function,
  data: Array<Object>
) => (customRenderer ? customRenderer(data) : defaultRenderer(data))

// .map will not work with new Array()
export const createEmptyArray = (length: number) =>
  length > 0 ? new Array(length).join().split(',') : []

export const createVariantPopover = (
  variant: Object,
  currency: string,
  product: Object,
  settings: ProductTableSettings
) => {
  const { sku, ean, stock } = variant
  const price = variant.prices[currency] || false
  const { colli } = product

  const showSku = showColumn('sku', settings.variant_popover_data)
  const showEan = showColumn('ean', settings.variant_popover_data)

  return (
    <Popover id="variant-popover">
      {showColumn('inventory', settings.variant_popover_data) &&
        stock &&
        stock.length > 0 && (
          <div className="popover-section">
            <div className="popover-headline">Inventory</div>
            <StockList entityId={product.brand_id} stock={stock} />
          </div>
        )}

      {(showSku === true || showEan === true) && (
        <div className="popover-section">
          <div className="popover-headline">Tracking</div>
          {showSku && (
            <div className="popover-row">
              <div className="popover-column-property">SKU:</div>
              <div className="popover-column-value">{sku}</div>
            </div>
          )}
          {showEan && (
            <div className="popover-row">
              <div className="popover-column-property">EAN:</div>
              <div className="popover-column-value">{ean}</div>
            </div>
          )}
        </div>
      )}

      {showColumn('prices', settings.variant_popover_data) && (
        <div className="popover-section">
          <div className="popover-headline">Prices</div>
          {price ? (
            <div>
              {price.offer_price && (
                <div className="popover-row">
                  <div className="popover-column-property">Offer Price:</div>
                  <div className="popover-column-value">
                    <pre>
                      <FormatCurrency currency={currency}>
                        {price.offer_price}
                      </FormatCurrency>
                    </pre>
                  </div>
                </div>
              )}
              <div className="popover-row">
                <div className="popover-column-property">Wholesale Price:</div>
                <div
                  className={
                    'popover-column-value ' +
                    (price.offer_price ? 'line-through' : '')
                  }
                >
                  <pre>
                    <FormatCurrency currency={currency}>
                      {price.sales_price}
                    </FormatCurrency>
                  </pre>
                </div>
              </div>
              {price.rec_sales_price && (
                <div className="popover-row">
                  <div className="popover-column-property">
                    Rec. Retail Price:
                  </div>
                  <div className="popover-column-value">
                    <pre>
                      <FormatCurrency currency={currency}>
                        {price.rec_sales_price}
                      </FormatCurrency>
                    </pre>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="popover-section">
              No price was found in {currency}
            </div>
          )}
        </div>
      )}

      {showColumn('colli_prices', settings.variant_popover_data) &&
        colli &&
        colli.map((colliSize, index) => (
          <div key={index} className="popover-section">
            <div className="popover-headline">{`${colliSize}-pieces colli`}</div>
            {price ? (
              <div>
                {price.offer_price && (
                  <div className="popover-row">
                    <div className="popover-column-property">Offer Price:</div>
                    <div className="popover-column-value">
                      <pre>
                        <FormatCurrency currency={currency}>
                          {colliSize * price.offer_price}
                        </FormatCurrency>
                      </pre>
                    </div>
                  </div>
                )}
                <div className="popover-row">
                  <div className="popover-column-property">
                    Wholesale Price:
                  </div>
                  <div
                    className={
                      'popover-column-value ' +
                      (price.offer_price ? 'line-through' : '')
                    }
                  >
                    <pre>
                      <FormatCurrency currency={currency}>
                        {colliSize * price.sales_price}
                      </FormatCurrency>
                    </pre>
                  </div>
                </div>
                {price.rec_sales_price && (
                  <div className="popover-row">
                    <div className="popover-column-property">
                      Rec. Retail Price:
                    </div>
                    <div className="popover-column-value">
                      <pre>
                        <FormatCurrency currency={currency}>
                          {colliSize * price.rec_sales_price}
                        </FormatCurrency>
                      </pre>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="popover-section">
                No price was found in {currency}
              </div>
            )}
          </div>
        ))}
    </Popover>
  )
}

const calculateImageColumnWidth = (image_size, custom_diameter) => {
  let colWidth
  switch (image_size) {
    case IMAGE_SIZE_SMALL:
      colWidth = 45
      break
    case IMAGE_SIZE_MEDIUM:
      colWidth = 100
      break
    case IMAGE_SIZE_LARGE:
      colWidth = 200
      break
    case IMAGE_SIZE_CUSTOM:
      colWidth = parseInt(custom_diameter)
      break
  }

  // We add the 20 to provide som spacing. Otherwise it looks weird with large pictures.
  return colWidth + 20
}

export const ImageHeaderColumn = (props: Object) => {
  const { children, custom_diameter, image_size, ...rest } = props
  const columnWidth = calculateImageColumnWidth(image_size, custom_diameter)

  // See comment below
  let ExpandText = ''
  // Using the Courier New font the width of each letter is approximately 6.25
  const letters = Math.ceil(columnWidth / 6.25)
  for (var i = 0; i < letters; i++) {
    ExpandText += 'E'
  }

  return (
    <Column {...rest} style={{ position: 'relative', width: columnWidth }}>
      <ImageExpandContainer>{ExpandText}</ImageExpandContainer>
      {/* We add the 3 and 5 to provide som artificial padding */}
      <div style={{ position: 'absolute', left: 3, top: 5 }}>{children}</div>
    </Column>
  )
}

/**
 * The image expand container is there to ensure images are rendered on print. The attribute
 * columns combined width is 100% to ensure they are expanding greedily. Unfortunately, since
 * an image tag is ignored by white-space the image column will be collapsed during print.
 * To hack this we put in a div with a word that is approximately as wide as the image column.
 * Because the column is white-space:nowrap; this word cannot be wrapped by the browser and
 * therefore the image is rendered properly.
 */
const ImageExpandContainer = styled.div`
  font-family: 'Courier New', Courier, monospace;
  font-size: 10px;
  visibility: hidden;
`

const IMAGE_SIZES = {
  [IMAGE_SIZE_SMALL]: { height: 45, width: 45 },
  [IMAGE_SIZE_MEDIUM]: { height: 100, width: 100 },
  [IMAGE_SIZE_LARGE]: { height: 200, width: 200 },
}

const resolveImageSize = (
  sizeSetting,
  defaultSize,
  customWidth,
  customHeight
) => {
  if (sizeSetting !== IMAGE_SIZE_CUSTOM) {
    return IMAGE_SIZES[sizeSetting] || IMAGE_SIZES[defaultSize]
  } else {
    return {
      height: customHeight,
      width: customWidth,
    }
  }
}

let cachedXScale, cachedYScale

export const ImageColumn = (props: ProductTableProps) => {
  const { image, settings, toggleProductModal } = props
  const {
    custom_diameter,
    image_shape,
    image_size,
    zoom_size,
    zoom_custom_width,
    zoom_custom_height,
    zoom_on_hover,
  } = settings

  const imageClasses = classnames({
    'round-pic': image_shape === IMAGE_RADIUS_CIRCLE,
    'rounded-pic': image_shape === IMAGE_RADIUS_ROUNDED,
  })

  const nonZoomSize = resolveImageSize(image_size, IMAGE_SIZE_SMALL)
  const zoomSize = resolveImageSize(
    zoom_size,
    IMAGE_SIZE_LARGE,
    zoom_custom_width,
    zoom_custom_height
  )

  const columnWidth = calculateImageColumnWidth(image_size, custom_diameter)

  const useZoomSize =
    zoom_on_hover === true && zoomSize.width > nonZoomSize.width
  const cloudinarySize = useZoomSize ? zoomSize : nonZoomSize

  if (!cachedXScale) {
    cachedXScale = Math.round(zoomSize.width / nonZoomSize.width)
  }
  if (!cachedYScale) {
    cachedYScale = Math.round(zoomSize.height / nonZoomSize.height)
  }

  return (
    <Column style={{ minWidth: columnWidth }}>
      <ClickLabel>
        <HoverImage
          className={imageClasses}
          fallback="product_table"
          id={image}
          onClick={toggleProductModal}
          presets="product_table"
          transformations={{
            height: cloudinarySize.height,
            width: cloudinarySize.width,
          }}
          nonZoomHeight={nonZoomSize.height}
          nonZoomWidth={nonZoomSize.width}
          yScale={cachedYScale}
          zoomOnHover={zoom_on_hover}
          xScale={cachedXScale}
          yScale={cachedYScale}
        />
      </ClickLabel>
    </Column>
  )
}

const HoverImage = styled(CloudinaryResource)`
  height: ${props => `${props.nonZoomHeight}px`};
  transition: transform 0.2s;
  width: ${props => `${props.nonZoomHeight}px`};

  :hover {
    position: relative;
    transform: ${props =>
      props.zoomOnHover
        ? `scaleY(calc(${props.yScale})) scaleX(calc(${props.xScale}))`
        : 'none'};
    z-index: 10000;
  }
`

ImageColumn.PropTypes = {
  imageSettings: PropTypes.object.isRequired,
  image: PropTypes.string.isRequired,
  toggleProductModal: PropTypes.number.isRequired,
}

// jsx requires components to be uppercase, therefore LabelOrComponent
export const createAction = (
  callback: Function,
  LabelOrComponent: React.Node,
  predicates: Array<Function>,
  extraProps: Object = { size: 'xs' }
) => {
  const ProductTableAction = (props: Object) => {
    const { callbackVars, ...rest } = props
    const { className = '', size, ...extraPropsWithoutClassname } = extraProps

    // Sometimes you want to override the callback function at runtime. The sort action is an example of this.
    // This enables interaction with component state.
    const callbackToBeUsed = rest.callback || callback

    // To avoid the callback function to be polluted with event objects when using the default button,
    // we only apply the arguments of the callback on the custom component
    return isString(LabelOrComponent) ||
      React.isValidElement(LabelOrComponent) ? (
      <button
        className={`btn btn-${size} btn-white ${className}`}
        onClick={() => callbackToBeUsed.apply(this, [...callbackVars])}
        type="button"
        {...extraPropsWithoutClassname}
      >
        {LabelOrComponent}
      </button>
    ) : (
      <LabelOrComponent
        callback={(...args) =>
          callbackToBeUsed.apply(this, [...args, ...callbackVars])
        }
        {...rest}
        {...extraProps}
      />
    )
  }

  ProductTableAction.propTypes = {
    callbackVars: PropTypes.array,
  }

  ProductTableAction.defaultProps = {
    callbackVars: [],
  }

  return {
    Component: ProductTableAction,
    predicates,
  }
}
const AllPass = predicates => {
  let i = 0
  const len = predicates.length
  while (i < len) {}
}
export const actionFilterer = (
  actions: Array<Object>,
  contextData: Object,
  session: Object
) => {
  return actions.filter(ActionObject => {
    let predicates = ActionObject.predicates

    if (!predicates) {
      return true
    }

    if (!isArray(predicates)) {
      predicates = [predicates]
    }

    let i = 0
    const len = predicates.length
    while (i < len) {
      if (!predicates[i].call(this, contextData, session)) {
        return false
      }
      i += 1
    }

    return true
  })
}

export const sortProducts = (
  products: { [string]: Array<Product> },
  sortBy: string
): Array<Product> => {
  const productKeys = Object.keys(products)
  const sortFunc = (ids, property) => {
    return ids.sort((a, b) => {
      let aValue = products[a][0][property]

      if (sortBy === 'collection' && isObject(aValue)) {
        aValue = aValue.title
      }
      if (sortBy === 'subbrand' && isObject(aValue)) {
        aValue = aValue.name
      }

      if (typeof aValue === 'string') {
        aValue = aValue.toLocaleLowerCase()
      }

      let bValue = products[b][0][property]

      if (sortBy === 'collection' && isObject(bValue)) {
        bValue = bValue.title
      }
      if (sortBy === 'subbrand' && isObject(bValue)) {
        bValue = bValue.name
      }

      if (typeof bValue === 'string') {
        bValue = bValue.toLocaleLowerCase()
      }

      if (aValue === bValue) {
        return 0
      }

      return aValue < bValue ? -1 : 1
    })
  }

  switch (sortBy) {
    default:
    case 'name':
      return sortFunc(productKeys, 'name')
    case 'item_number':
      return sortFunc(productKeys, 'item_number')
    case 'collection':
      return sortFunc(productKeys, 'collection')
    case 'subbrand':
      return sortFunc(productKeys, 'subbrand')
    case 'warehouse_location':
      return lodashSortBy(productKeys, productId => {
        const product = products[productId][0]

        if (product.variants && product.variants.length > 0) {
          return product.variants[0].location
        }

        return null
      })
    case 'warehouse_location_integer':
      return lodashSortBy(productKeys, productId => {
        const product = products[productId][0]

        if (product.variants && product.variants.length > 0) {
          return parseInt(product.variants[0].location, 10)
        }

        return 0
      })
  }
}

const createWrapperElement = (elementType, wrapperClass) => {
  const Component = (props: Object) => {
    const { className, children, ...rest } = props

    return React.createElement(
      elementType,
      {
        className: `${className} ${wrapperClass}`,
        ...rest,
      },
      children
    )
  }

  Component.defaultProps = { className: '' }

  return Component
}

type ProductTableContainerProps = {
  bordered: boolean,
}

export const ProductTableContainer = styled.table`
  width: 100%;

  td {
    border: ${({ bordered }: ProductTableContainerProps) =>
      bordered ? '1px solid #e5e9ec' : '0'};
  }
`

export const Column = styled.td`
  padding-left: 3px;
  padding-right: 3px;
  white-space: nowrap;
`

// $FlowIssue
export const AttributeColumn = styled(Column)`
  background: ${({ marked }: { marked: boolean }) =>
    marked ? 'rgba(255, 204, 121, 0.60)' : 'inherit'};
  white-space: normal;
`

// $FlowIssue
export const AttributeHeaderColumn = styled(AttributeColumn)`
  white-space: normal;
`

// $FlowIssue
export const ProductNameColumn = styled(Column)`
  white-space: normal;
`

// $FlowIssue
export const QuantityColumn = styled(Column)`
  width: 1%;
`

// $FlowIssue
export const PriceColumn = styled(Column)`
  padding-left: 6px;
  padding-right: 6px;
  text-align: right;
  width: 1%;
`

// $FlowIssue
export const ActionColumn = styled(Column)`
  width: 1%;

  @media print {
    display: none;
  }
`

const calculateVerticalPadding = rowSize =>
  ({ small: 3, normal: 5, large: 7 }[rowSize] + 'px')

type RowProps = {
  bold?: boolean,
  rowSize?: RowSize,
}

export const Row = styled.tr`
  border-top: 1px solid #f4f4f4;
  -webkit-print-color-adjust: exact;
  font-weight: ${({ bold = false }: RowProps) => (bold ? 'bold' : 'normal')};
  page-break-inside: avoid !important;

  td {
    padding-bottom: ${({ rowSize = 'normal' }: RowProps) =>
      calculateVerticalPadding(rowSize)};
    padding-top: ${({ rowSize = 'normal' }: RowProps) =>
      calculateVerticalPadding(rowSize)};
  }

  @media print {
    td {
      padding: 0 2px;
    }
  }
`

// $FlowIssue
// The !important on background color is so colors are rendered in print mode
export const HeaderRow = styled(Row)`
  td {
    background-color: ${({ dark = false }: { dark: boolean }) =>
      dark ? '#e6e6e6 !important' : '#f7f7f7 !important'};
    -webkit-print-color-adjust: exact;
    border-bottom: 1px solid #e5e9ec;
    font-weight: 800;
  }
`

export const generateQuantitiesFromLines = (
  lines: Array<Line>,
  quantityProperty: string = 'quantity'
) => {
  // Group the variants in case we have price splits
  const linesByVariant = groupBy(lines, line => line.variant_id)

  return lines.reduce((carry, line) => {
    const quantity = linesByVariant[line.variant_id].reduce(
      (carry, l) => (carry += l[quantityProperty]),
      0
    )

    let variantQuantity = Map({
      colli: 1,
      quantity: quantity,
    })

    if (linesByVariant[line.variant_id].length > 1) {
      variantQuantity = variantQuantity.set(
        'price_splits',
        linesByVariant[line.variant_id].reduce((innerCarry, line) => {
          const key = createLineKeyPriceIdentifier(line.net_price)

          if (!innerCarry.has(key)) {
            innerCarry = innerCarry.set(
              createLineKeyPriceIdentifier(line.net_price),
              Map({
                colli: 1,
                quantity: 0,
              })
            )
          }

          const currentQuantity = innerCarry.getIn([key, 'quantity'])

          return innerCarry.setIn(
            [key, 'quantity'],
            currentQuantity + line[quantityProperty]
          )
        }, Map())
      )
    }

    return carry.setIn([line.product_id, line.variant_id], variantQuantity)
  }, Map())
}

export const generateQuantitiesFromLinesForcePriceSplits = (
  lines: Array<Line>,
  quantityProperty: string = 'quantity'
) => {
  // Group the variants in case we have price splits
  const linesByVariant = groupBy(lines, line => line.variant_id)

  return lines.reduce((carry, line) => {
    const quantity = linesByVariant[line.variant_id].reduce(
      (carry, l) => (carry += l[quantityProperty]),
      0
    )
    let variantQuantity = Map({
      colli: 1,
      quantity: quantity,
    })

    variantQuantity = variantQuantity.set(
      'price_splits',
      linesByVariant[line.variant_id].reduce((innerCarry, line) => {
        const key = createLineKeyPriceIdentifier(line.net_price)

        if (!innerCarry.has(key)) {
          innerCarry = innerCarry.set(
            createLineKeyPriceIdentifier(line.net_price),
            Map({
              colli: 1,
              quantity: 0,
            })
          )
        }

        const currentQuantity = innerCarry.getIn([key, 'quantity'])

        return innerCarry.setIn(
          [key, 'quantity'],
          currentQuantity + line[quantityProperty]
        )
      }, Map())
    )

    return carry.setIn([line.product_id, line.variant_id], variantQuantity)
  }, Map())
}

export const generateProductsFromLines = (
  lines: Array<Line>,
  currency: string,
  subtractAvailabilityProperty = 'invoiced'
) =>
  lines
    .reduce((carry, line) => {
      if (!carry.has(line.product_id)) {
        carry = carry.set(
          line.product_id,
          Object.assign({}, line.product, {
            variants: [],
          })
        )
      }

      // some times we do not want to use subtractAvailabilityProperty, e.g. when creating shipment delivery notes
      let available = line.quantity - (line[subtractAvailabilityProperty] || 0)
      if (available < 0) {
        available = 0
      }

      const prices = line.variant.prices || {}
      const lineCurrencyPrice = prices[currency] || {}
      carry.get(line.product_id).variants.push(
        Object.assign({}, line.variant, {
          available,
          invoiced: line.invoiced,
          quantity: line.quantity,
          prices: Object.assign({}, prices, {
            [currency]: {
              discount_amount: line.discount_amount,
              discount_percentage: line.discount_percentage,
              price_before_discount: line.price_before_discount,
              sales_price: line.net_price, //.sales_price,
              offer_price: null, //line.discount_amount > 0 ? line.net_price : null, //.sales_price !== line.discount_amount > 0 ? line.price_before_discount : line.net_price ? line.discount_amount > 0 ? line.price_before_discount : line.net_price : null,
              rec_sales_price: lineCurrencyPrice.rec_sales_price,
            },
          }),
        })
      )

      return carry
    }, Map())
    .toList()
    .filter(product => product.variants.length > 0)

export const generateLineIndexFromVariants = (
  lineIndex: { [number]: number },
  variants: Array<Variant>,
  currency: string
) =>
  variants.reduce((carry, variant) => {
    if (!variant.prices[currency]) {
      return carry
    }
    const key = createLineKeyFromVariant(variant, currency)
    if (lineIndex[key]) {
      carry[key] = lineIndex[key]
    }
    return carry
  }, {})

const LINE_KEY_DELIMITER = '-'
export const createLineKeyFromOrderLine = (line, priceProperty = 'net_price') =>
  createLineKey(
    line.variant_id,
    createLineKeyPriceIdentifier(line[priceProperty])
  )
export const createLineKeyFromVariantAndPrice = (variantId, price) =>
  createLineKey(variantId, createLineKeyPriceIdentifier(price))
export const createLineKeyFromVariant = (variant: Variant, currency: string) =>
  createLineKey(
    variant.id,
    createLineKeyPriceIdentifier(variant.prices[currency].sales_price)
  )
export const createLineKeyPriceIdentifier = (price: number) =>
  price ? price.toFixed(2) : 0
export const createLineKey = (variantIdentifier, priceIdentificer) =>
  [variantIdentifier, priceIdentificer].join(LINE_KEY_DELIMITER)

export const getQuantityForVariant = (quantities = Map(), prices, currency) => {
  const priceSplits = quantities.getIn(['price_splits'], false)
  if (priceSplits && prices[currency]) {
    const price = createLineKeyPriceIdentifier(prices[currency].sales_price)

    return priceSplits.getIn([price], Map())
  }

  return quantities
}

export const removeQuantityForVariant = (
  currentQuantities,
  variant,
  currency
) => {
  const productId = variant.product_id
  const variantId = variant.id

  let quantities = currentQuantities

  const priceSplits = quantities.getIn([productId, variantId, 'price_splits'])
  if (!priceSplits) {
    quantities = quantities.deleteIn([productId, variantId])
  } else {
    quantities = quantities.deleteIn([
      productId,
      variantId,
      'price_splits',
      createLineKeyPriceIdentifier(variant.prices[currency].sales_price),
    ])

    const remainingVariantQuantities = quantities.getIn(
      [productId, variantId, 'price_splits'],
      Map()
    )
    if (remainingVariantQuantities.size > 0) {
      quantities = quantities.setIn(
        [productId, variantId, 'quantity'],
        remainingVariantQuantities.reduce(
          (carry, split) => (carry += split.get('quantity')),
          0
        )
      )
    } else {
      quantities = quantities.deleteIn([productId, variantId])
    }
  }

  if (quantities.get(productId, Map()).size === 0) {
    return quantities.delete(productId)
  }

  return quantities
}

export const updateQuantityForVariant = (
  currentQuantities,
  variant,
  quantity,
  currency
) => {
  const productId = variant.product_id
  const variantId = variant.id

  let quantities = currentQuantities
    // We start by merging because there might be some properties like production's ordered and stock we want to merge in
    .mergeIn([productId, variantId], quantity)

  // set quantity for price
  quantities = quantities.mergeIn(
    [
      productId,
      variantId,
      'price_splits',
      createLineKeyPriceIdentifier(variant.prices[currency].sales_price),
    ],
    quantity
  )

  // set total for the variant
  quantities = quantities.setIn(
    [productId, variantId, 'quantity'],
    quantities
      .getIn([productId, variantId, 'price_splits'])
      .reduce((carry, split) => (carry += split.get('quantity')), 0)
  )

  // set overall variant colli
  quantities = quantities.setIn(
    [productId, variantId, 'colli'],
    quantity.get('colli')
  )

  return quantities
}

export const ClickLabel = styled.span`
  cursor: pointer;
`
