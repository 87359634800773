/* @flow */

import * as React from 'react'
import { PureComponent } from 'react'
import { FastField, Field, type FieldProps } from 'formik'
import get from 'lodash/get'
import { FormControl, InputGroup } from 'react-bootstrap'
import { ErrorLabel } from '../Forms'

type Props = {
  disabled?: boolean,
  formikFastField?: boolean,
  markOnFocus?: boolean,
  noError?: boolean,
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void,
  onChange: (value: string) => void,
  rightAddon?: string,
  rightAlign?: boolean,
  value: string,
}

const FormInput = React.forwardRef(
  (
    props: {
      ...$Diff<Props, { onChange: (value: string) => void }>,
      onChange?: (value: string) => void,
    },
    ref
  ) => {
    const { formikFastField, ...restProps } = props

    // FastField re-renders only when the value of field changes and on few other occassions
    // limiting the number of re-renders. Useful when form starts to slow down because of lot
    // of fields, but can block other needed renders, be careful when using it.
    const FieldComponent = formikFastField === true ? FastField : Field

    return (
      <FieldComponent innerRef={ref} component={renderFormInput} {...props} />
    )
  }
)
export default FormInput

export const renderFormInput = ({
  field: { onBlur, name, onChange, value },
  form: { setFieldValue, touched, errors },
  onChange: propsOnChange,
  markOnFocus,
  onFocus: propsOnFocus,
  rightAddon,
  rightAlign,
  type,
  innerRef,
  style = {},
  ...props
}: FieldProps & Props) => {
  const inputRef = innerRef || React.useRef(null)
  const wrappedFocus = () => {
    if (markOnFocus && inputRef.current) {
      const previousType = inputRef.current.type

      // hack because number type input fields do not support selection
      inputRef.current.type = 'text'
      inputRef.current.setSelectionRange(0, inputRef.current.value.length)
      inputRef.current.type = previousType
    }

    if (propsOnFocus) {
      propsOnFocus()
    }
  }

  const useStyle = { ...style }
  if (rightAlign) {
    useStyle['text-align'] = 'right'
  }

  let control = (
    <FormControl
      type={type}
      disabled={props.disabled}
      inputRef={inputRef}
      onBlur={onBlur}
      onChange={e => {
        setFieldValue(name, e.target.value)

        if (propsOnChange) {
          propsOnChange(e)
        }
      }}
      onFocus={wrappedFocus}
      name={name}
      style={useStyle}
      value={value}
      {...props}
    />
  )

  if (rightAddon) {
    control = (
      <InputGroup>
        {control}
        <InputGroup.Addon className="primary">{rightAddon}</InputGroup.Addon>
      </InputGroup>
    )
  }

  return (
    <div>
      {control}

      {!props.noError && get(touched, name) && get(errors, name) && (
        <ErrorLabel>{get(errors, name)}</ErrorLabel>
      )}
    </div>
  )
}
