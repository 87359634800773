/* @flow */

import React, { useEffect, useState, useContext } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import { FiX } from 'react-icons/fi'

import TraedeLogo from '../../../../assets/images/logo.png'
import NavigationRightSide from './NavigationRightSide'
import MobileNavigation from '../../../../infrastructure/components/Mobile/MobileNavigation'

import { getAvailableBrands } from '../../../brands/api'
import { changeWebshopCurrency } from '../../../shop/actions'
import { WebshopContext } from '../../../shopV2/shared'

type Props = {}

const ShopNavigationBar = props => {
  const {
    brands,
    brandContext,
    currentBreakpoint,
    dispatch,
    entity,
    fullWidth,
    inWebshop,
    webshopCurrency,
    webshopV2,
  } = props
  const webshopV2Context = useContext(WebshopContext)
  const [availableBrands, setAvailableBrands] = useState([])
  const [showMobileNavBar, setShowMobileNavBar] = React.useState(false)

  const toggleMobileNavBar = React.useCallback(() => {
    setShowMobileNavBar(s => !s)
  }, [setShowMobileNavBar])

  const onHideMobileNavigation = React.useCallback(() => {
    setShowMobileNavBar(false)
  }, [setShowMobileNavBar])

  useEffect(() => {
    getAvailableBrands().then(response => {
      if (!response.error) {
        const brands = response.payload.brands.filter(brand => {
          return (
            brand.retailer_connections.filter(c => c.b2b_access === true)
              .length > 0
          )
        })
        setAvailableBrands(sortBy(brands, 'name'))
      }
    })
  }, [])

  const setCurrency = currency => dispatch(changeWebshopCurrency(currency))

  const noBorderStyle = { borderRight: '0px' }

  // When used with shopV1, current open brand id is taken from Redux state's brandContext,
  // when with shopV2 then brand id is taken from WebshopContext
  let currentBrandId = brandContext
  if (webshopV2) {
    currentBrandId = webshopV2Context.availableBrand
      ? webshopV2Context.availableBrand.id
      : null
  }

  const currentBrand = brands[currentBrandId]
  const currencies = currentBrand ? currentBrand.currencies : []

  let showCurrencySelector = true
  if (
    currentBrand &&
    currentBrand.settings.webshop_settings.splash_show === true &&
    currentBrand.settings.webshop_settings.splash_fields.indexOf('currency') !==
      -1
  ) {
    showCurrencySelector = false
  }

  const isMobile = currentBreakpoint === 'mobile'

  if (inWebshop && isMobile) {
    return null
  }

  const brand = availableBrands.find(({ id }) => id === currentBrandId)
  const hasFileBank = brand
    ? brand.retailer_connections.filter(c => c.file_bank === true).length > 0
    : false

  return (
    <>
      {entity.entity_type === 'shop' && isMobile && (
        <>
          {showMobileNavBar && (
            <MobileNavigation
              containerStyle={{ top: 30 }}
              header={
                <>
                  <div>NAVIGATION</div>

                  <div
                    className="close"
                    onClick={() => setShowMobileNavBar(false)}
                  >
                    <FiX size={16} style={{ verticalAlign: 'middle' }} />
                  </div>
                </>
              }
              headerStyle={{ height: 50 }}
              onHide={() => setShowMobileNavBar(false)}
              show={showMobileNavBar}
              wrapperClass="normal-scroll"
            >
              <MobileNavigation.ItemList>
                <li>
                  <Link to="/" onClick={onHideMobileNavigation}>
                    <span>Home</span>
                  </Link>
                </li>
                <li>
                  <Link to="/orders" onClick={onHideMobileNavigation}>
                    <span>My orders</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/selections"
                    style={noBorderStyle}
                    onClick={onHideMobileNavigation}
                  >
                    <span>My selections</span>
                  </Link>
                </li>
                <li>
                  <Link to="/invoices" onClick={onHideMobileNavigation}>
                    <span>My invoices</span>
                  </Link>
                </li>
                <li>
                  {hasFileBank && currentBrand && (
                    <Link
                      to={`/file-bank/${currentBrand.id}`}
                      style={noBorderStyle}
                      onClick={onHideMobileNavigation}
                    >
                      <span>File bank</span>
                    </Link>
                  )}
                </li>
              </MobileNavigation.ItemList>
            </MobileNavigation>
          )}
        </>
      )}

      <Container>
        <InnerContainer className={fullWidth ? 'full-width' : 'container'}>
          {entity.entity_type === 'brand' && (
            <LeftSideContainer>
              <Link to="/" className="webshop-top-bar-nav-element">
                <span className="fa fa-mail-reply" />
                <img src={TraedeLogo} alt="Traede" className="back-button" />
              </Link>
            </LeftSideContainer>
          )}

          {entity.entity_type === 'shop' && (
            <>
              {isMobile && (
                <MobileNavigationHeader>
                  <MobileNavigationToggle onClick={toggleMobileNavBar}>
                    <span className="glyphicon glyphicon-menu-hamburger" />
                  </MobileNavigationToggle>
                </MobileNavigationHeader>
              )}

              {!isMobile && (
                <LeftSideContainer>
                  <Link to="/" className="webshop-top-bar-nav-element">
                    <span className="lnr lnr-home" />
                    <span>Home</span>
                  </Link>
                  <Link to="/orders" className="webshop-top-bar-nav-element">
                    <span className="lnr lnr-file-empty" />
                    <span>My orders</span>
                  </Link>
                  <Link
                    to="/selections"
                    className="webshop-top-bar-nav-element"
                    style={noBorderStyle}
                  >
                    <span className="lnr lnr-pointer-up" />
                    <span>My selections</span>
                  </Link>
                  <Link to="/invoices" className="webshop-top-bar-nav-element">
                    <span className="lnr lnr-file-empty" />
                    <span>My invoices</span>
                  </Link>
                  {hasFileBank && currentBrand && (
                    <Link
                      to={`/file-bank/${currentBrand.id}`}
                      className="webshop-top-bar-nav-element"
                      style={noBorderStyle}
                    >
                      <span className="lnr lnr-cloud-upload" />
                      <span>File bank</span>
                    </Link>
                  )}
                </LeftSideContainer>
              )}
            </>
          )}

          <RightSideContainer>
            <div
              id="traede-webshop-actions-portal"
              style={{ float: 'right' }}
            />

            {/* For public webshops it does not make sense to show brand selector. So to hack it we simply hide it if you
              only have 1 connection */}
            {entity.entity_type === 'shop' && availableBrands.length > 1 && (
              <NavigationDropDownButton
                title={
                  currentBrand ? `Brand: ${currentBrand.name}` : 'Shop products'
                }
              >
                {availableBrands.map(brand => {
                  return (
                    <MenuItem key={brand.id}>
                      <Link to={`/shop/${brand.id}`}>{brand.name}</Link>
                    </MenuItem>
                  )
                })}
              </NavigationDropDownButton>
            )}

            {inWebshop && showCurrencySelector && !webshopV2 && (
              <NavigationDropDownButton title={`Currency ${webshopCurrency}`}>
                {currencies.map(currency => (
                  <MenuItem
                    key={currency}
                    onClick={() => setCurrency(currency)}
                  >
                    {currency}
                  </MenuItem>
                ))}
              </NavigationDropDownButton>
            )}

            {inWebshop && !showCurrencySelector && (
              <CurrencyLabel>Currency: {webshopCurrency}</CurrencyLabel>
            )}

            <NavigationRightSide type="shop" {...props} />
          </RightSideContainer>
        </InnerContainer>
      </Container>
    </>
  )
}

const ConnectedShopNavigation = connect(
  (state: Object): ShopNavigationBarStateProps => ({
    brandContext: state.brandContext,
    webshopCurrency: state.webshop.currency,
  })
)(ShopNavigationBar)

export default ConnectedShopNavigation

const Container = styled.div`
  background: black;
  color: white;
  display: flex;
  font-size: 12px;
  height: 35px;
  position: relative;
  z-index: 10000;
`

const InnerContainer = styled.div`
  display: flex;

  &.full-width {
    flex: 1;
  }
`

const LeftSideLinks = styled(Link)``

const LeftSideContainer = styled.div`
  display: flex;

  > ${LeftSideLinks} {
    display: block;
  }
`

const RightSideContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

const NavigationDropDownButton = styled(DropdownButton)`
  border-radius: 0;
  background: transparent;
  border-left: 1px solid #3e4149;
  border-right: 1px solid #3e4149;
  color: white;
  font-size: 12px;
  height: 35px;

  &:hover,
  &:active,
  &:focus {
    background: transparent;
    color: white;
  }
`

const CurrencyLabel = styled.div`
  align-items: center;
  display: flex;
`

const MobileNavigationHeader = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`

const MobileNavigationToggle = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: 1px solid white;
  border-radius: 5px;
  color: #ccc;
  font-size: 18px;
  padding: 4px 15px;
  outline: 0;
`
