/* @flow */

import * as React from 'react'
import { Field } from 'formik'
import styled, { css } from 'styled-components'
import sortBy from 'lodash/sortBy'
import { Dropdown, MenuItem, Modal } from 'react-bootstrap'
import { Formik } from 'formik'
import get from 'lodash/get'

import ActionButton from '../ActionButton'
import { generateAutoTranslations } from '../../localization/api'

import { ControlLabel } from './FormElements'
import { useCachedProductLocales } from '../../../app/settings/hooks'
import type { Id } from '../../../app/types'
import { Tooltip, stripHtml } from '../../../app/shared'

type Props = {
  children?: React.Node,
  // We make locale prop controlled. This is a bit more work but there are 2 important things here:
  // (1) This will allow the user to change locale once and then input all the form values for that
  //     locale without having to change the individual selectors
  // (2) If we have a non-default locale selected when saving we have no way of changing back to default
  //     locale if it was local state. And then Formik wont reset its value.
  currentLocale: null | Id,
  name: string,
  namePrefix?: string,
  onLocaleChange: Function,
  translationsKey: string,
  translationType: string,
  values: Object,
}

const LocaleInput = ({
  allowAutoTranslation = false,
  checkPrefix = '',
  children,
  currentLocale,
  name,
  namePrefix = '',
  onLocaleChange: propsOnLocaleChange,
  setFieldValue,
  translationsKey,
  translationType,
  values,
  className,
  ...restProps
}: Props) => {
  const [productLocales, isFetching] = useCachedProductLocales()
  const [showAutoTranslateModal, setShowAutoTranslateModal] =
    React.useState(false)

  React.useEffect(() => {
    let labelComponent
    React.Children.forEach(children, child => {
      if (child.type === ControlLabel) {
        labelComponent = child
      }
    })

    if (!labelComponent) {
      throw new Error("You need a ControlLabel in LocaleInput's")
    }
  }, [children])

  const localeOptions = React.useMemo(() => {
    const options = [{ label: 'Default', value: null }]

    const sortedLocales = sortBy(productLocales, 'title')

    for (let locale of sortedLocales) {
      options.push({
        label: locale.title,
        value: locale.id,
      })
    }

    return options
  }, [productLocales])

  const calculateTranslatedFieldName = React.useCallback(
    locale => {
      return `${namePrefix}${translationsKey}.${locale}.${name}`
    },
    [namePrefix, name, translationsKey]
  )

  const onLocaleChange = React.useCallback(
    eventKey => {
      if (eventKey === 'auto_translate') {
        setShowAutoTranslateModal(true)
      } else {
        return propsOnLocaleChange(eventKey)
      }
    },
    [propsOnLocaleChange, setShowAutoTranslateModal]
  )

  const onSaveAutoTranslateModal = React.useCallback(
    translationsByLocaleId => {
      for (let [productLocaleId, translation] of Object.entries(
        translationsByLocaleId
      )) {
        setFieldValue(
          calculateTranslatedFieldName(productLocaleId),
          translation
        )
      }

      setShowAutoTranslateModal(false)
    },
    [
      calculateTranslatedFieldName,
      productLocales,
      setFieldValue,
      setShowAutoTranslateModal,
    ]
  )

  const currentLocaleOption = React.useMemo(() => {
    return localeOptions.find(option => option.value === currentLocale)
  }, [localeOptions, currentLocale])

  const currentTranslationsValues = React.useMemo(
    () => values[translationsKey] || {},
    [translationsKey, values]
  )

  const { markShouldWarn, markRequired } = React.useMemo(() => {
    const markShouldWarn = []
    const markRequired = []

    for (let locale of productLocales) {
      const shouldWarn = locale.should_warn.includes(translationType)
      const isRequired = locale.required.includes(translationType)

      if (!shouldWarn && !isRequired) {
        continue
      }

      const currentLocaleValues = currentTranslationsValues[locale.id] || {}
      const currentValue = currentLocaleValues[name]

      if (!currentValue) {
        if (shouldWarn) {
          markShouldWarn.push(locale.id)
        }
        if (isRequired) {
          markRequired.push(locale.id)
        }
      }
    }

    return {
      markShouldWarn,
      markRequired,
    }
  }, [currentTranslationsValues, productLocales, name, translationType])

  return (
    <>
      {showAutoTranslateModal !== false && (
        <AutoTranslateModal
          onHide={() => setShowAutoTranslateModal(false)}
          originalValue={get(values, `${checkPrefix}${name}`)}
          productLocales={productLocales}
          onSave={onSaveAutoTranslateModal}
          show={showAutoTranslateModal}
        />
      )}

      <LocaleInputContainer className={className}>
        {React.Children.map(children, child => {
          if (child.type === ControlLabel) {
            return React.createElement(
              LocaleInputLabel,
              {
                allowAutoTranslation,
                currentLocaleOption,
                localeOptions,
                name: namePrefix + name,
                markRequired,
                markShouldWarn,
                onLocaleChange,
                translationsKey,
                ...child.props,
              },
              child.props.children
            )
          }

          // assume this is an input field
          if (
            child.props.name === checkPrefix + namePrefix + name &&
            currentLocale !== null
          ) {
            const restProps = { ...child.props }
            delete restProps.key
            delete restProps.name

            const newName = calculateTranslatedFieldName(currentLocale)
            const productLocale = productLocales.find(
              productLocale => productLocale.id === currentLocale
            )

            return React.createElement(
              child.type,
              {
                // force remount
                key: newName,
                name: newName,
                productLocale,
                ...restProps,
              },
              child.props.children
            )
          }

          return child
        })}
      </LocaleInputContainer>
    </>
  )
}

export default LocaleInput

const WARNING_COLOR = 'orange'
const REQUIRED_COLOR = 'red'

const LocaleInputLabel = React.memo(
  ({
    allowAutoTranslation,
    children,
    currentLocaleOption,
    localeOptions,
    markRequired,
    markShouldWarn,
    name,
    onLocaleChange,
    translationsKey,
    required,
    style = {},
    ...rest
  }) => {
    let buttonColor
    if (markRequired.length > 0) {
      buttonColor = REQUIRED_COLOR
    } else if (markShouldWarn.length > 0) {
      buttonColor = WARNING_COLOR
    }
    return (
      <ControlLabel style={{ width: '100%', ...style }} {...rest}>
        <ControlLabelInnerContainer>
          <ControlLabelLabelContainer>
            {children} {required && <span className="form-required">*</span>}
          </ControlLabelLabelContainer>

          {localeOptions.length > 1 && (
            <ControlLabelLocaleSelectorContainer>
              <Dropdown
                id={`${translationsKey}.${name}.locale_selector`}
                onSelect={onLocaleChange}
              >
                <Dropdown.Toggle bsStyle="white" bsSize="xsmall">
                  <span style={{ color: buttonColor }}>
                    <span className="glyphicon glyphicon-globe" />{' '}
                    {currentLocaleOption && currentLocaleOption.label}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {localeOptions.map(option => {
                    const markAsRequired = markRequired.includes(option.value)
                    const markAsWarning = markShouldWarn.includes(option.value)

                    return (
                      <MenuItem eventKey={option.value}>
                        {!markAsRequired && !markAsWarning && (
                          <span>{option.label}</span>
                        )}
                        {markAsRequired && (
                          <Tooltip
                            id="locale-input-required"
                            placement="right"
                            tip="You have to fill out this locale to save"
                          >
                            <RequiredLabel>
                              <span className="glyphicon glyphicon-remove" />{' '}
                              {option.label}
                            </RequiredLabel>
                          </Tooltip>
                        )}
                        {!markAsRequired && markAsWarning && (
                          <Tooltip
                            id="locale-input-warning"
                            placement="right"
                            tip="Warning: This locale should be filled out"
                          >
                            <WarningLabel>
                              <span className="fa fa-warning" /> {option.label}
                            </WarningLabel>
                          </Tooltip>
                        )}
                      </MenuItem>
                    )
                  })}
                  {allowAutoTranslation && <MenuItem divider />}
                  {allowAutoTranslation && (
                    <MenuItem eventKey="auto_translate">
                      <span className="label label-success">
                        Auto translate
                      </span>
                    </MenuItem>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </ControlLabelLocaleSelectorContainer>
          )}
        </ControlLabelInnerContainer>
      </ControlLabel>
    )
  }
)

const LocaleInputContainer = styled.div``

const ControlLabelInnerContainer = styled.div`
  align-items: center;
  display: flex;
`

const ControlLabelLabelContainer = styled.div`
  flex: 1;
`

const ControlLabelLocaleSelectorContainer = styled.div``

const RequiredLabel = styled.span`
  color: ${REQUIRED_COLOR};
`

const WarningLabel = styled.span`
  color: ${WARNING_COLOR};
`

const AutoTranslateModal = ({
  productLocales,
  originalValue,
  onHide,
  onSave,
  show,
}: {
  onHide: Function,
  onSave: Function,
  show: boolean,
}) => {
  const [hasTranslated, setHasTranslated] = React.useState(false)
  const [checkedLocales, setCheckedLocales] = React.useState(
    productLocales.map(l => l.id)
  )
  const [translations, setTranslations] = React.useState({})

  const onGenerate = React.useCallback(() => {
    const languages = new Set()
    for (let productLocale of productLocales) {
      if (!checkedLocales.includes(productLocale.id)) {
        continue
      }

      languages.add(productLocale.locale)
    }

    return generateAutoTranslations(
      stripHtml(originalValue),
      Array.from(languages)
    ).then(response => {
      if (!response.error) {
        setTranslations(s => {
          const copy = { ...s }

          for (let productLocale of productLocales) {
            if (!checkedLocales.includes(productLocale.id)) {
              continue
            }

            const translation =
              response.payload.translations[productLocale.locale]

            if (translation) {
              copy[productLocale.id] = translation
            }
          }

          return copy
        })

        setHasTranslated(true)
      }
    })
  }, [
    checkedLocales,
    originalValue,
    productLocales,
    setHasTranslated,
    setTranslations,
  ])

  const onUseTranslations = React.useCallback(() => {
    const translationsByLocaleId = {}

    for (let productLocale of productLocales) {
      if (!checkedLocales.includes(productLocale.id)) {
        continue
      }

      const translation = translations[productLocale.id]

      if (translation) {
        translationsByLocaleId[productLocale.id] = translation
      }
    }

    return onSave(translationsByLocaleId)
  }, [onSave, checkedLocales, productLocales, translations])

  const toggleLocale = React.useCallback(
    Id => {
      setCheckedLocales(s => {
        const checked = [...s]

        const index = checked.indexOf(Id)
        if (index === -1) {
          checked.push(Id)
        } else {
          checked.splice(index, 1)
        }

        return checked
      })
    },
    [setCheckedLocales]
  )

  return (
    <Modal show={show} onHide={onHide} dialogClassName="xxl-modal">
      <Modal.Header closeButton>
        <Modal.Title>Auto translate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-condensed">
          <thead>
            <tr>
              <th className="listview-action" />
              <th width="150">Locale</th>
              <th>Translated</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td />
              <td></td>
              <td>
                <p>{originalValue}</p>
              </td>
            </tr>

            {productLocales.map(locale => {
              const translatedValue = translations[locale.id]

              const isChecked = checkedLocales.includes(locale.id)
              // some trickery to get textarea to play nice with react
              const useValue =
                translatedValue === null || translatedValue === undefined
                  ? ''
                  : translatedValue

              return (
                <tr key={locale.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={checkedLocales.includes(locale.id)}
                      onChange={() => toggleLocale(locale.id)}
                    />
                  </td>
                  <td>{locale.title}</td>
                  <td>
                    <textarea
                      onChange={e => {
                        const value = e.target.value

                        if (!isChecked) {
                          toggleLocale(locale.id)
                        }

                        setTranslations(s => ({
                          ...s,
                          [locale.id]: value,
                        }))
                      }}
                      value={isChecked ? useValue : ''}
                      rows="5"
                      style={{ width: '100%' }}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-white" onClick={onHide}>
          Cancel
        </button>
        {hasTranslated && (
          <ActionButton className="btn btn-white" onClick={onGenerate}>
            Re-generate translations
          </ActionButton>
        )}
        {!hasTranslated && (
          <ActionButton className="btn btn-success" onClick={onGenerate}>
            Generate translations
          </ActionButton>
        )}
        {hasTranslated && (
          <ActionButton className="btn btn-success" onClick={onUseTranslations}>
            Use translations
          </ActionButton>
        )}
      </Modal.Footer>
    </Modal>
  )
}
