/* @flow */

import * as React from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'

import { renderDate } from '../../shared'

type Props = {}

const DropDeliveryDate = ({
  confirmed,
  fromDate,
  prefix,
  toDate,
  type = 'dates',
}) => {
  const start = confirmed ? 'Delivered' : 'Expected delivery'

  let prefixComponent = prefix ? <span>{prefix}</span> : null

  switch (type) {
    case 'month':
      let monthOne = fromDate ? moment(fromDate).format('MMMM YYYY') : null
      let monthTwo = toDate ? moment(toDate).format('MMMM YYYY') : null

      if (monthOne === monthTwo) {
        monthTwo = null
      }

      if (monthOne && monthTwo) {
        return (
          <>
            {prefixComponent}
            <span>{start} between</span>
            <span>
              {' '}
              {monthOne} and {monthTwo}
            </span>
          </>
        )
      }

      if (monthOne) {
        return (
          <>
            {prefixComponent}
            <span>{start}</span>
            <span> {monthOne}</span>
          </>
        )
      }

      if (monthTwo) {
        return (
          <>
            {prefixComponent}
            <span>{start}</span>
            <span> {monthTwo}</span>
          </>
        )
      }

    case 'week':
      let weekOne = fromDate ? moment(fromDate).format('w') : null
      let weekTwo = toDate ? moment(toDate).format('w') : null

      if (weekOne === weekTwo) {
        weekTwo = null
      }

      if (weekOne && weekTwo) {
        return (
          <>
            {prefixComponent}
            <span>{start} between</span>
            <span>
              {' '}
              week {weekOne} and week {weekTwo}
            </span>
          </>
        )
      }

      if (weekOne) {
        return (
          <>
            {prefixComponent}
            <span>{start}</span>
            <span> week {weekOne}</span>
          </>
        )
      }

      if (weekTwo) {
        return (
          <>
            {prefixComponent}
            <span>{start}</span>
            <span> week {weekTwo}</span>
          </>
        )
      }

    case 'date':
      if (!fromDate || !toDate) {
        return null
      }

      return (
        <>
          {prefixComponent}
          <span>{start} between</span>
          <span>
            {' '}
            {renderDate(fromDate)} and {renderDate(toDate)}
          </span>
        </>
      )
    default:
      return null
  }

  return null
}

export default DropDeliveryDate
