import moment from 'moment-timezone'

const formats = {
  date: {
    day_month: 'DD/MM',
    month_year: 'MMM YYYY',
    short: 'L',
    week: 'ww',
  },
  datetime: {
    short: 'L, LT',
  },
  skeleton: {
    h: 'ha',
    MEd: 'ddd, DD/MM',
    MMM: 'MMM',
  },
  raw: {
    'yyyy-MM-dd': 'YYYY-MM-DD',
    'MMM yy': 'MM YYYY',
    'MMMM yyyy': 'MMMM YYYY',
  },
  time: {
    short: 'H:mm',
  },
}

const Globalize = function () {}

const formatCurrency = (value, currency, decimalPoints = 2) => {
  return number_format(value, decimalPoints, '.', ',') + ' ' + currency
}

Globalize.prototype = {
  currencyFormatter: currency => {
    return (input, decimalPoints) => {
      return formatCurrency(input, currency, decimalPoints)
    }
  },
  // short
  dateFormatter: ({ date, datetime, raw, skeleton, time }) => {
    return input => {
      if (datetime) {
        return moment(input).format(formats.datetime[datetime])
      } else if (skeleton) {
        return moment(input).format(formats.skeleton[skeleton])
      } else if (date) {
        return moment(input).format(formats.date[date])
      } else if (raw) {
        return moment(input).format(formats.raw[raw])
      } else if (time) {
        return moment(input).format(formats.time[time])
      }
    }
  },
  dateParser: () => {
    return input => {
      return moment.utc(input).toDate()
    }
  },
  formatCurrency: formatCurrency,
  numberFormatter: (options = {}) => {
    const maximumFractionDigits =
      options.maximumFractionDigits !== 'undefined'
        ? options.maximumFractionDigits
        : 2
    return value => {
      return number_format(value, maximumFractionDigits, '.', ',')
    }
  },
}

const instance = new Globalize()

export default instance

function number_format(number, decimals, decPoint, thousandsSep) {
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
  var n = !isFinite(+number) ? 0 : +number
  var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  var sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep
  var dec = typeof decPoint === 'undefined' ? '.' : decPoint
  var s = ''
  var toFixedFix = function (n, prec) {
    var k = Math.pow(10, prec)
    return '' + (Math.round(n * k) / k).toFixed(prec)
  }
  // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}
