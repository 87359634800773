import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import querystring from 'qs'
import { GlobalHotKeys, configure as configureHotKeys } from 'react-hotkeys'

import ErrorBoundary from '../../infrastructure/components/ErrorBoundary'
import Navigation from './components/Navigation'
import Spotlight from './components/Spotlight'
import { toggleTemplateBlank } from './actions/template'

configureHotKeys({
  ignoreEventsCondition: event => {
    const { target } = event

    // Forward slash on numerical keyboard is sent as "divide" with key code 111
    const isForwardSlash = event.keyCode === 191 || event.keyCode === 111

    // Ignore forward slash when some input is focused, but cmd/ctrl+s or escape should be
    // matched even when in input
    if (target && target.tagName && isForwardSlash) {
      const tagName = target.tagName.toLowerCase()

      return (
        ['input', 'select', 'textarea'].includes(tagName) ||
        target.isContentEditable
      )
    } else {
      return false
    }
  },
})

const Template = ({
  location,
  dispatch,
  children,
  entityType,
  showLoader,
  settingsPage = false,
  SideBar = false,
  blank = false,
}: {
  dispatch: Dispatch,
}) => {
  const [showSpotlight, setShowSpotlight] = React.useState(false)

  const shouldBeBlank = React.useMemo(() => {
    return querystring.parse(location.search.substr(1)).blank === '1'
  }, [location.search])

  React.useEffect(() => {
    dispatch(toggleTemplateBlank(shouldBeBlank))
  }, [shouldBeBlank])

  React.useEffect(() => {
    if (!showSpotlight) {
      return
    }

    const input =
      document.getElementsByClassName('react-autosuggest__input')[0] || null
    input && input.focus()
  }, [showSpotlight])

  const toggleSpotlight = React.useCallback(() => {
    setShowSpotlight(!showSpotlight)
  }, [showSpotlight, setShowSpotlight])

  const globalHotKeysProps = React.useMemo(() => {
    return {
      keyMap: {
        // ctrl and command so it works on Mac as well as Windows or Linux
        SEARCH: ['ctrl+s', 'command+s', '/'],
        ESCAPE: 'escape',
      },
      handlers: {
        SEARCH: event => {
          // Right now spotlight search is for brands only
          if (entityType === 'brand') {
            event.preventDefault()
            setShowSpotlight(true)
          }
        },
        ESCAPE: () => {
          setShowSpotlight(false)
        },
      },
    }
  }, [entityType, setShowSpotlight])

  if (shouldBeBlank) {
    return <div>{children}</div>
  }

  return (
    <GlobalHotKeys {...globalHotKeysProps}>
      <div className={`template-${entityType}`}>
        <Navigation
          toggleSpotlight={toggleSpotlight}
          settingsPage={settingsPage}
        />
        {showSpotlight && <Spotlight onClose={toggleSpotlight} />}
        <div
          className="template-loader"
          style={{ display: showLoader ? 'block' : 'none' }}
        >
          <div className="loader">
            <div className="flipper">
              <div className="front" />
              <div className="back" />
            </div>
          </div>
          <div className="loader-title">Loading...</div>
        </div>
        <div className="template-main-container">
          <ErrorBoundary message="An error occurred loading the component">
            {SideBar ? (
              <div className="row with-settings">
                <div className="col-md-2 col-xs-3">
                  <SideBar entityType={entityType} />
                </div>
                {children}
              </div>
            ) : (
              children
            )}
          </ErrorBoundary>
        </div>
      </div>
    </GlobalHotKeys>
  )
}

export default connect(state => ({
  blank: state.template.blank,
  entityType: state.session.entity.entity_type,
  showLoader: state.template.showLoader,
}))(Template)
