/* @flow */

import * as React from 'react'
import { useContext } from 'react'
import { connect } from 'react-redux'

import type { App, Entity, ConnectedBrand } from '../../types'
import { SessionContext } from '../../shared'

type Props = {
  app: string | Array<string>,
  children?: React.Node,
  overrideEntity?: Entity | ConnectedBrand,
  render?: React.ComponentType<any>,
  not?: boolean,
}

export const AppContext = ({
  app,
  children,
  overrideEntity,
  render: Renderer,
  renderFunc,
  not = false,
  ...rest
}: Props) => {
  const { entity } = useContext(SessionContext)

  let apps = []
  if (entity) {
    apps = entity.apps
  }
  // entity might be null, since app context can be run in an unauthenticated setting
  if (overrideEntity && overrideEntity.apps) {
    apps = overrideEntity.apps
  }

  if (!Array.isArray(app)) {
    app = [app]
  }

  let hasOneOfApps = false

  const installedAppNames = apps.map(app => app.app)
  installedAppNames.forEach(a => {
    if (app.indexOf(a) !== -1) {
      hasOneOfApps = true
    }
  })

  if (not) {
    hasOneOfApps = !hasOneOfApps
  }

  const appObject = React.useMemo(() => {
    return apps.find(a => app.indexOf(a.app) !== -1)
  }, [app, apps])

  if (hasOneOfApps && Renderer) {
    return <Renderer app={appObject} {...rest} />
  } else if (hasOneOfApps && renderFunc) {
    return renderFunc({ app: appObject })
  }

  return hasOneOfApps && children ? children : null
}

export default AppContext
