/* @flow */

import * as React from 'react'
import uuid from 'uuid'
import groupBy from 'lodash/groupBy'
import { original, produce } from 'immer'

import { TableControlsState, TableControlsChangeFunction } from './types'
import type { Line } from '../../../types'

import { SessionContext, useRefValue } from '../../../shared'

const linesReducer = (state: Array<Line>, action) => {
  let updatedLines
  switch (action.type) {
    case 'set':
      return action.lines
    case 'delete':
      const deleteLineIds = action.lines.map(l => l.id)

      updatedLines = [...state].map(l => {
        if (!deleteLineIds.includes(l.id)) {
          return l
        }

        /**
         * We mark both new lines and persisted lines as delete=true. This is because
         * lines might be persisted inside the ProductTable's local state. Therefore,
         * we must tell the ProductTable to ignore it. Before persisting to API
         * prepareProductTableLinesForApi will remove all new lines anyway
         */
        return {
          ...l,
          delete: true,
        }
      })

      return updatedLines
    case 'push':
      return [...state, ...action.lines]
    case 'update':
      updatedLines = [...state]

      let useLines = action.lines
      if (action.createOnMissing === true && useLines.length === 0) {
        useLines = action.variants.map(v => {
          const line = {
            ...action.defaultRowData,
            id: uuid(),
            product_id: v.product_id,
            variant_id: v.id,
            meta: {},
            __new_line: true,
            configurator_values: v.__configurator_values || [],
          }

          if (v.__placeholder_variant) {
            // when we push the lines back to the API we need to swap IDs
            line.__placeholder_variant = v.__placeholder_variant
          }

          return line
        })
      }

      useLines = useLines.map(line => {
        const updatedLine = { ...line }

        if (action.updates) {
          for (let [updateKey, updateValue] of Object.entries(action.updates)) {
            if (updateKey === '__partial_line_meta') {
              if (!updatedLine.meta) {
                updatedLine.meta = {}
              }

              updatedLine.meta = {
                ...updatedLine.meta,
                ...updateValue,
              }
            } else {
              updatedLine[updateKey] = updateValue
            }
          }
        }

        return updatedLine
      })

      for (let line of useLines) {
        const index = updatedLines.findIndex(l => l.id === line.id)

        if (index === -1) {
          updatedLines.push(line)
        } else {
          updatedLines[index] = line
        }
      }

      return updatedLines
    case 'set_configured_variants':
      updatedLines = [...state]

      for (let update of action.updates) {
        const line = update.line
        const index = updatedLines.findIndex(l => l.id === line.id)

        if (index !== -1) {
          updatedLines[index] = {
            ...line,
            variant_id: update.new_variant.id,
            // when we push the lines back to the API we need to swap IDs
            __placeholder_variant: update.placeholder_variant.id,
          }
        }
      }

      return updatedLines
    default:
      return state
  }
}

/**
 * The reason why we use a reducer, is that if we use a normal callback it will
 * cause re-renders deep down on every quantity change. This could be fixed by
 * memoizing the callback using useCallback. However, since the callback depends
 * on lines, useCallback would have to run every time lines change and thus we
 * are back to re-renders on every keystroke. The official react documentation
 * recommends solving this issue using a reducer, since the dispatch function
 * never changes. This also means that every state holder would have to implement
 * this reducer for holding lines state.
 *
 * Read more at: https://reactjs.org/docs/hooks-faq.html#how-to-avoid-passing-callbacks-down
 * Read more at: https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback
 */
export const useLinesReducer = (lines: Array<Line>) => {
  return React.useReducer(linesReducer, lines)
}

const linesReducerV2 = (state: Array<Line>, action) => {
  let updatedLines
  switch (action.type) {
    case 'set':
      return {
        lines: action.lines,
        linesByProductId: groupBy(action.lines, 'product_id'),
        maxSort: Math.max(...action.lines.map(line => line.sort || 0)) || 0,
      }

    case 'delete':
      const deleteLineIds = action.lines.map(l => l.id)

      updatedLines = [...state.lines].map(l => {
        if (!deleteLineIds.includes(l.id)) {
          return l
        }

        /**
         * We mark both new lines and persisted lines as delete=true. This is because
         * lines might be persisted inside the ProductTable's local state. Therefore,
         * we must tell the ProductTable to ignore it. Before persisting to API
         * prepareProductTableLinesForApi will remove all new lines anyway
         */
        return {
          ...l,
          delete: true,
        }
      })

      return updateLinesByProductId(state, updatedLines, action.lines)
    case 'update':
      updatedLines = [...state.lines]

      let useLines = action.lines
      if (action.createOnMissing === true && useLines.length === 0) {
        useLines = action.variants.map(v => {
          const line = {
            ...action.defaultRowData,
            id: uuid(),
            product_id: v.product_id,
            variant_id: v.id,
            meta: action.defaultRowData.meta || {},
            __new_line: true,
            configurator_values: v.__configurator_values || [],
            sort: state.maxSort + 1,
          }

          state.maxSort += 1

          if (v.__placeholder_variant) {
            // when we push the lines back to the API we need to swap IDs
            line.__placeholder_variant = v.__placeholder_variant
          }

          return line
        })
      }

      useLines = useLines.map(line => {
        const updatedLine = { ...line }

        if (action.updates) {
          for (let [updateKey, updateValue] of Object.entries(action.updates)) {
            if (updateKey === '__partial_line_meta') {
              if (!updatedLine.meta) {
                updatedLine.meta = {}
              }

              updatedLine.meta = {
                ...updatedLine.meta,
                ...updateValue,
              }
            } else {
              updatedLine[updateKey] = updateValue
            }
          }
        }

        return updatedLine
      })

      for (let line of useLines) {
        const index = updatedLines.findIndex(l => l.id === line.id)

        if (index === -1) {
          updatedLines.push({
            ...line,
            sort: state.maxSort + 1,
          })

          state.maxSort += 1
        } else {
          updatedLines[index] = line
        }
      }

      return updateLinesByProductId(state, updatedLines, useLines)

    case 'push':
      const linesToAdd = action.lines.map(line => {
        if (!line.sort) {
          line.sort = state.maxSort + 1
          state.maxSort += 1
        }

        return line
      })

      updatedLines = [...state.lines, ...linesToAdd]

      return updateLinesByProductId(state, updatedLines, linesToAdd)

    case 'set_configured_variants':
      updatedLines = [...state.lines]

      for (let update of action.updates) {
        const line = update.line
        const index = updatedLines.findIndex(l => l.id === line.id)

        if (index !== -1) {
          updatedLines[index] = {
            ...line,
            variant_id: update.new_variant.id,
            // when we push the lines back to the API we need to swap IDs
            __placeholder_variant: update.placeholder_variant.id,
          }
        }
      }

      return updateLinesByProductId(state, updatedLines, useLines)
    default:
      return state
  }
}

export const useLinesReducerV2 = (lines: Array<Line>) => {
  const initialState = React.useMemo(() => {
    return {
      lines,
      linesByProductId: groupBy(lines, 'product_id'),
    }
  }, [lines])

  return React.useReducer(linesReducerV2, initialState)
}

const updateLinesByProductId = (state, updatedLines, actionLines) => {
  const updatedProductIds = new Set()
  for (let line of actionLines) {
    updatedProductIds.add(line.product_id)
  }

  const updatedLinesOfUpdatedProducts = updatedLines.filter(line =>
    updatedProductIds.has(line.product_id)
  )

  return {
    ...state,
    lines: updatedLines,
    linesByProductId: {
      ...state.linesByProductId,
      ...groupBy(updatedLinesOfUpdatedProducts, 'product_id'),
    },
  }
}

export const useProductIds = lines => {
  return React.useMemo(() => {
    return Array.from(
      lines.reduce((carry, line) => {
        carry.add(line.product_id)
        return carry
      }, new Set())
    )
  }, [lines])
}

const productTableControlsReducer = (
  state: TableControlsState,
  action: TableControlsChangeFunction
) => {
  switch (action.type) {
    case 'update_toggles':
      return produce(state, draft => {
        for (let productId of action.product_ids) {
          const togglesOfProduct = draft.togglesByProduct[productId] || {}

          for (let [updateKey, updateValue] of Object.entries(action.data)) {
            if (updateValue !== togglesOfProduct[updateKey]) {
              if (!draft.togglesByProduct[productId]) {
                draft.togglesByProduct[productId] = {}
              }

              draft.togglesByProduct[productId][updateKey] = updateValue
            }
          }
        }
      })

    case 'update_quick_toggles':
      return {
        ...state,
        quickToggles: action.data,
      }
      break

    case 'update_summary_table':
      return {
        ...state,
        summaryTable: {
          ...state.summaryTable,
          ...action.data,
        },
      }
      break

    default:
      return state
  }
}

export const useProductTableControls = (
  initialState?: TableControlsState,
  tableId
) => {
  const { user } = React.useContext(SessionContext)

  const inputStateWithDefaults = React.useMemo(() => {
    let quickToggles = []
    if (
      tableId &&
      user &&
      user.product_table_settings &&
      user.product_table_settings[tableId]
    ) {
      quickToggles = user.product_table_settings[tableId].quick_toggles || []
    }

    return {
      togglesByProduct: {},
      quickToggles: quickToggles,
      summaryTable: {
        show: false,
      },
      ...(initialState || {}),
    }
  }, [initialState, tableId, user])

  return React.useReducer(productTableControlsReducer, inputStateWithDefaults)
}

export const useSummaryProductTableState = (
  currentQuery,
  onUpdateQueryString,
  tableId,
  quantityColumns,
  mainQuantityColumn,
  unitPriceColumn,
  totalPriceColumn
) => {
  const { brand } = React.useContext(SessionContext)

  const productTableSetting = React.useMemo(() => {
    let defaultSettings = brand.product_table_settings.default

    let overrideSettings = {}
    if (tableId && brand.product_table_settings[tableId]) {
      overrideSettings = brand.product_table_settings[tableId].settings
    }

    return {
      ...defaultSettings.settings,
      ...overrideSettings,
    }
  }, [brand.product_table_settings, tableId])

  const defaultShow = productTableSetting
    ? productTableSetting.use_summary_table
    : false

  const summaryProductTableState = React.useMemo(() => {
    let show = defaultShow
    if (currentQuery.summary_table_show !== undefined) {
      show = currentQuery.summary_table_show == 1 ? true : false
    }

    return {
      attributes_filter: currentQuery.summary_table_attributes_filter,
      product_id_filter: currentQuery.summary_table_product_id,
      show,
      options: {
        quantityColumns,
        mainQuantityColumn,
        unitPriceColumn,
        totalPriceColumn,
      },
    }
  }, [
    currentQuery.summary_table_product_id,
    currentQuery.summary_table_attributes_filter,
    currentQuery.summary_table_show,
    defaultShow,
  ])
  const summaryProductTableRefValue = useRefValue(summaryProductTableState)

  const toggleSummaryProductTable = React.useCallback(() => {
    onUpdateQueryString({
      summary_table_show: summaryProductTableRefValue.current.show ? 0 : 1,
    })
  }, [onUpdateQueryString, summaryProductTableRefValue])

  const toggleSummaryProductTableProductFilter = React.useCallback(
    (productRow, attributesFilter) => {
      if (
        summaryProductTableRefValue.current.product_id_filter ===
        productRow.product.id
      ) {
        onUpdateQueryString({
          summary_table_product_id: null,
          summary_table_attributes_filter: null,
        })
      } else {
        onUpdateQueryString({
          summary_table_product_id: productRow.product.id,
          summary_table_attributes_filter: attributesFilter,
        })
      }
    },
    [onUpdateQueryString, summaryProductTableRefValue]
  )

  const onGoBackToSummary = React.useCallback(() => {
    onUpdateQueryString({
      summary_table_product_id: null,
      summary_table_attributes_filter: null,
    })
  }, [onUpdateQueryString, summaryProductTableRefValue])

  const onShowAllLines = React.useCallback(() => {
    onUpdateQueryString({
      summary_table_product_id: null,
      summary_table_attributes_filter: null,
      summary_table_show: 0,
    })
  }, [onUpdateQueryString])

  const summaryProductTableActions = React.useMemo(() => {
    return {
      back_to_summary: onGoBackToSummary,
      show_all_lines: onShowAllLines,
      toggle_table: toggleSummaryProductTable,
      toggle_product: toggleSummaryProductTableProductFilter,
    }
  }, [
    onShowAllLines,
    toggleSummaryProductTable,
    toggleSummaryProductTableProductFilter,
    onGoBackToSummary,
  ])

  return {
    summaryProductTableActions,
    summaryProductTableState,
  }
}
