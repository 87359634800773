/* @flow */

import * as React from 'react'
import { PureComponent } from 'react'
import styled from 'styled-components'
import { Field, type FieldProps } from 'formik'
import get from 'lodash/get'
import { ErrorLabel } from '../Forms'

import ProductAutoCompleteInput from '../../../app/products/components/ProductAutoCompleteInput'

type Props = {
  disabled?: boolean,
  markOnFocus?: boolean,
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void,
  onChange: (value: string) => void,
  rightAddon?: string,
  value: string,
}

const FormikProductAutoCompleteInput = React.forwardRef(
  (
    props: {
      ...$Diff<Props, { onChange: (value: string) => void }>,
      onChange?: (value: string) => void,
    },
    ref
  ) => {
    return (
      <Field
        innerRef={ref}
        component={renderFormikProductAutoCompleteInput}
        {...props}
      />
    )
  }
)
export default FormikProductAutoCompleteInput

export const renderFormikProductAutoCompleteInput = ({
  field: { onBlur, name, onChange, value },
  form: { setFieldValue, touched, errors },
  brandId,
  onChange: propsOnChange,
  markOnFocus,
  onFocus: propsOnFocus,
  rightAddon,
  type,
  innerRef,
  ...props
}: FieldProps & Props) => {
  const inputRef = innerRef || React.useRef(null)
  const [selectedSuggestion, setSelectedSuggestion] = React.useState(null)

  const wrappedOnChange = React.useCallback(
    suggestion => {
      setFieldValue(name, suggestion ? suggestion.id : null)

      setSelectedSuggestion(suggestion ? suggestion : null)

      if (propsOnChange) {
        propsOnChange(e)
      }
    },
    [name, setFieldValue, propsOnChange]
  )

  const onUnset = React.useCallback(() => {
    setSelectedSuggestion(null)
    setFieldValue(name, null)
  }, [name, setSelectedSuggestion, setFieldValue])

  return (
    <div>
      <ProductAutoCompleteInput
        brandId={brandId}
        dataMode="list"
        onSelect={wrappedOnChange}
      />

      {selectedSuggestion && (
        <SelectedSuggestionLabel>
          {selectedSuggestion.name} (#{selectedSuggestion.item_number}){' '}
          <UnsetButton onClick={onUnset}>
            <span className="glyphicon glyphicon-remove" />
          </UnsetButton>
        </SelectedSuggestionLabel>
      )}

      {get(touched, name) && get(errors, name) && (
        <ErrorLabel>{get(errors, name)}</ErrorLabel>
      )}
    </div>
  )
}

const SelectedSuggestionLabel = styled.div``

const UnsetButton = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: none;
  outline: 0;
`
