/* @flow */

import api from '../api'

import type { ApiResponse, BrunoRequestOptions, Id, Collection } from '../types'

export const getCollections = (
  options?: BrunoRequestOptions
): Promise<ApiResponse<{ collections: Array<Collection> }>> =>
  api.get('/collections')

export const deleteCollection = (id: Id): Promise<ApiResponse<null>> =>
  api.delete(`/collections/${id}`)
