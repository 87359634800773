/* @flow */

import type { ReportDataEntry, ReportDataProperty } from './types'

export const buildMeasurementCatalogue = (data, dimensions) => {
  let catalogue = {}

  for (var i = 0; i < data.length; i++) {
    const point = data[i]

    catalogue = addPointToCatalogue(point, dimensions.slice(), catalogue)
  }

  return catalogue
}

const addPointToCatalogue = (point, dimensions, catalogue = {}) => {
  if (dimensions.length === 0) {
    return point
  }

  const nextDimension = dimensions.shift()

  const dimensionValue = point.dimensionsByLabel[nextDimension.label]

  if (!catalogue[dimensionValue]) {
    catalogue[dimensionValue] = {}
  }

  catalogue[dimensionValue] = addPointToCatalogue(
    point,
    dimensions,
    catalogue[dimensionValue]
  )

  return catalogue
}

export const getPointFromCatalogue = (
  catalogue,
  dimensions: Array<ReportDataProperty>,
  columnLabels?: { [string]: string },
  rowLabels?: { [string]: string }
) => {
  return iterateCatalogueForPoint(
    catalogue,
    dimensions.slice(),
    columnLabels,
    rowLabels
  )
}

const iterateCatalogueForPoint = (
  catalogue,
  dimensions,
  columnLabels,
  rowLabels
) => {
  if (dimensions.length === 0) {
    return catalogue
  }

  const nextDimension = dimensions.shift()

  const dimensionValue =
    nextDimension.type === 'column'
      ? columnLabels[nextDimension.label]
      : rowLabels[nextDimension.label]

  if (!catalogue[dimensionValue]) {
    return null
  }

  return iterateCatalogueForPoint(
    catalogue[dimensionValue],
    dimensions,
    columnLabels,
    rowLabels
  )
}

export const createOptionsKey = (
  property: string,
  options?: Object
): string => {
  if (!options || (Array.isArray(options) && options.length === 0)) {
    return property
  }

  return `${property}.${Object.values(options).join('.')}`
}

/**
 * This method will extract all combinations from a given data axis (rows or columns).
 *
 * [
 *   { label: 'Collection', value: 'collection', values: ['AW17', 'AW18'] },
 *   { label: 'Agent', value: 'agent', values: ['', 'Signe Bruun'] }
 * ]
 *
 * will become
 *
 * [
 *   { Collection: 'AW17', Agent: '' },
 *   { Collection: 'AW17', Agent: 'Signe Bruun' },
 *   { Collection: 'AW18', Agent: '' },
 *   { Collection: 'AW18', Agent: 'Signe Bruun' }
 * ]
 *
 * so there is one entry per combination of values. The output is also sorted by the users
 * sorting configuration. Lastly, doesMeasurementWithCombinationExist will filter out all
 * those combinations that had no measurement.
 */
export const extractCombinations = (
  data: Array<ReportDataEntry>,
  columns: Array<ReportDataProperty>,
  rows: Array<ReportDataProperty>
) => {
  const columnCombinations = []
  const rowCombinations = []

  const usedColumns = new Set()
  const usedRows = new Set()
  return data.reduce(
    (carry, entry) => {
      const column = {}
      const row = {}

      let columnFingerPrint = ''
      let rowFingerPrint = ''
      for (var i = 0; i < columns.length; i++) {
        const label = columns[i].label
        const value = entry.dimensionsByLabel[label]

        columnFingerPrint += label + value

        column[columns[i].label] = value
      }
      for (var k = 0; k < rows.length; k++) {
        const label = rows[k].label
        const value = entry.dimensionsByLabel[label]

        rowFingerPrint += label + value

        row[rows[k].label] = value
      }

      if (
        Object.getOwnPropertyNames(column).length > 0 &&
        !usedColumns.has(columnFingerPrint)
      ) {
        usedColumns.add(columnFingerPrint)
        carry.columnCombinations.push(column)
      }
      if (
        Object.getOwnPropertyNames(row).length > 0 &&
        !usedRows.has(rowFingerPrint)
      ) {
        usedRows.add(rowFingerPrint)
        carry.rowCombinations.push(row)
      }

      return carry
    },
    {
      columnCombinations: [],
      rowCombinations: [],
    }
  )
}
