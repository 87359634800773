export const SET_CLOUDINARY_SETTINGS = 'SET_CLOUDINARY_SETTINGS'
export const ADD_CUSTOM_CLOUDINARY_PRESET = 'ADD_CUSTOM_CLOUDINARY_PRESET'

export const setCloudinarySettings = (
  cloudName,
  productPresets,
  otherPresets,
  fallbacks
) => ({
  type: SET_CLOUDINARY_SETTINGS,
  cloudName,
  productPresets,
  otherPresets,
  fallbacks,
})

export const addCustomCloudinaryPresetIfNeeded = (presetName, diameter) => {
  return (dispatch, getState) => {
    const { cloudinary } = getState()
    if (cloudinary.otherPresets[presetName]) {
      if (cloudinary.otherPresets[presetName].width === parseInt(diameter)) {
        return null
      }
    }
    return dispatch(addCustomCloudinaryPreset(presetName, diameter))
  }
}

export const addCustomCloudinaryPreset = (presetName, diameter) => ({
  type: ADD_CUSTOM_CLOUDINARY_PRESET,
  diameter: diameter,
  presetName,
})
