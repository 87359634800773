/* @flow */

import React, { PureComponent } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import classnames from 'classnames'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'

import {
  CheckboxInput,
  FormGroup,
  ControlLabel,
  HelpHint,
  SelectInput,
  FormInput,
  SaveButton,
} from '../../../infrastructure/components/Formik'
import CountrySelector from '../../../infrastructure/components/CountrySelector'
import StatesSelector from '../../../infrastructure/components/StatesSelector'
import AppContext from '../../app-store/components/AppContext'

import type { Address } from '../../types'
import type { AddressAction } from './types'

export const FORM_KEY = 'addresses_form'

type Props = {
  handleSubmit: () => void,
  integrationId?: boolean,
  mode: (mode: AddressAction) => void,
  onHide: () => void,
  onSubmit: (values: $Shape<Address>) => void,
  show: boolean,
  showFlags?: boolean,
  submitting: boolean,
  voecNumber?: boolean,
}

class AddressModal extends PureComponent<Props, void> {
  render() {
    const {
      initialValues,
      integrationId,
      mode,
      onHide,
      onSubmit,
      show,
      showFlags,
      submitting,
      voecNumber,
    } = this.props

    let saveButtonLabel = mode === 'add' ? 'Add Address' : 'Yes, Save!'

    let ModalTitle =
      mode === 'edit'
        ? 'Edit address'
        : mode == 'update'
        ? 'Add a new address'
        : 'Add custom address to order'

    return (
      <Modal show={show} backdrop="static" onHide={onHide} bsSize="lg">
        <Formik
          enableReinitialize
          initialValues={
            initialValues || {
              ean: '',
              vat: '',
              name: '',
              address: '',
              address_2: '',
              zip: '',
              city: '',
              country: '',
              region: '',
              emai: '',
              telephone: '',
              note: '',
              eori_number: '',
            }
          }
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          render={({ handleSubmit, isSubmitting, values }) => {
            const wrappedSubmit = e => {
              e.stopPropagation()
              handleSubmit(e)
            }

            return (
              <form onSubmit={wrappedSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>{ModalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <AddressFormFields
                    email
                    integrationId={integrationId}
                    showFlags={showFlags}
                    telephone
                    notes
                    voecNumber={voecNumber}
                    values={values}
                    title={mode !== 'customize'}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <div className="row">
                    <div className="col-md-12 text-right">
                      <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-default"
                      >
                        No, cancel.
                      </button>
                      <SaveButton submitting={isSubmitting}>
                        {saveButtonLabel}
                      </SaveButton>
                    </div>
                  </div>
                </Modal.Footer>
              </form>
            )
          }}
        />
      </Modal>
    )
  }
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  address: Yup.string().required('You need to provide an address'),
  zip: Yup.string().required('You need to provide a zip code'),
  city: Yup.string().required('You need to provide city'),
  country: Yup.string().required('You need to provide country'),
})

export default AddressModal

const AddressTitleTooltip = (
  <Tooltip id="address_title">
    It is a good idea to give your address a title like "Invoice address" or
    "Delivery address". This makes it easier to tell them apart when making
    orders.
  </Tooltip>
)

export const AddressFormFields = ({
  attRequired = false,
  b2c = false,
  email = false,
  emailRequired = false,
  integrationId = false,
  namespace = '',
  showFlags = true,
  telephone = false,
  telephoneRequired = false,
  title = true,
  notes = false,
  voecNumber = false,
  values = {},
}: {
  attRequired?: boolean,
  email?: boolean,
  emailRequired?: boolean,
  namespace?: string,
  showFlags?: boolean,
  telephone?: boolean,
  telephoneRequired?: boolean,
  title?: boolean,
  voecNumber?: boolean,
}) => {
  // When used as a part of a larger form some of the fields might conflict with the parent form.
  const name = key => (namespace ? `${namespace}.${key}` : key)

  const source = namespace ? values[namespace] : values
  const selectedCountry = source && source['country'] ? source['country'] : null

  return (
    <div>
      {showFlags === true && (
        <FlagButtonContainer>
          <CheckboxInput
            id="address-form-make-primary"
            name={name('primary')}
            label={
              <span>
                <span className="glyphicon glyphicon-home" /> This address is
                primary <u>invoice</u> address
              </span>
            }
          />

          <CheckboxInput
            id="address-form-make-delivery"
            name={name('delivery')}
            label={
              <span>
                <span className="glyphicon glyphicon-envelope" /> This address
                is primary <u>delivery</u> address
              </span>
            }
          />
        </FlagButtonContainer>
      )}
      {title && (
        <FormGroup controlId="title">
          <ControlLabel>Address title</ControlLabel>
          <HelpHint>
            <OverlayTrigger placement="top" overlay={AddressTitleTooltip}>
              <div className="btn btn-xs">What is this?</div>
            </OverlayTrigger>
          </HelpHint>
          <FormInput type="text" name={name('title')} />
        </FormGroup>
      )}
      <AddressFields
        attRequired={attRequired}
        b2c={b2c}
        namespace={namespace}
        selectedCountry={selectedCountry}
        voecNumber={voecNumber}
      />
      {(email === true || telephone === true) && (
        <div className="row">
          {email && (
            <div className="col-md-6">
              <FormGroup controlId="email">
                <ControlLabel required={emailRequired}>Email</ControlLabel>
                <FormInput name={name('email')} />
              </FormGroup>
            </div>
          )}
          {telephone && (
            <div className="col-md-6">
              <FormGroup controlId="telephone">
                <ControlLabel required={telephoneRequired}>
                  Phone Number
                </ControlLabel>
                <FormInput type="text" name={name('telephone')} />
              </FormGroup>
            </div>
          )}
        </div>
      )}
      {notes === true && (
        <div className="row">
          {notes && (
            <div className="col-md-6">
              <FormGroup controlId="email">
                <ControlLabel>Notes</ControlLabel>
                <FormInput name={name('notes')} />
              </FormGroup>
            </div>
          )}
        </div>
      )}
      {integrationId && (
        <div className="row">
          <div className="col-xs-6">
            <FormGroup controlId="integration_id">
              <ControlLabel>Integration ID</ControlLabel>
              <FormInput type="text" name={name('integration_id')} />
            </FormGroup>
          </div>
        </div>
      )}
    </div>
  )
}

const countriesWithStates = ['US', 'CA']

export const AddressFields = ({
  attRequired = false,
  b2c = false,
  companyIdFields = true,
  namespace,
  voecNumber = false,
  selectedCountry,
}: {
  attRequired?: boolean,
  b2c?: boolean,
  companyIdFields?: boolean,
  namespace: string,
  voecNumber?: boolean,
  selectedCountry?: string,
}) => {
  const name = key => (namespace ? `${namespace}.${key}` : key)

  const firstRowSize = companyIdFields ? 'col-sm-3' : 'col-sm-6'

  return (
    <div>
      <div className="row">
        <div className={firstRowSize}>
          <FormGroup controlId={name('name')}>
            <ControlLabel required>
              {!b2c && `Company name`}
              {b2c && `Name`}
            </ControlLabel>
            <FormInput type="text" name={name('name')} />
          </FormGroup>
        </div>
        <div className={firstRowSize}>
          <FormGroup controlId={name('att')}>
            <ControlLabel required={attRequired}>
              {!b2c && `Att`}
              {b2c && `Company`}
            </ControlLabel>
            <FormInput type="text" name={name('att')} />
          </FormGroup>
        </div>
        {companyIdFields === true && (
          <>
            <div className={firstRowSize}>
              <FormGroup controlId={name('vat')}>
                <ControlLabel>VAT number</ControlLabel>
                <FormInput type="text" name={name('vat')} />
              </FormGroup>
            </div>
            <div className={firstRowSize}>
              <FormGroup controlId={name('ean')}>
                <ControlLabel>EAN number</ControlLabel>
                <FormInput type="text" name={name('ean')} />
              </FormGroup>
            </div>
          </>
        )}
      </div>

      <div className="row">
        <div className="col-md-6">
          <FormGroup controlId={name('address')}>
            <ControlLabel required>Address</ControlLabel>
            <FormInput type="text" name={name('address')} />
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup controlId={name('address_2')}>
            <ControlLabel>Address 2</ControlLabel>
            <FormInput type="text" name={name('address_2')} />
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-3">
          <FormGroup controlId={name('zip')}>
            <ControlLabel required>Zip Code</ControlLabel>
            <FormInput type="text" name={name('zip')} />
          </FormGroup>
        </div>
        <div className="col-sm-3">
          <FormGroup controlId={name('city')}>
            <ControlLabel required>City</ControlLabel>
            <FormInput type="text" name={name('city')} />
          </FormGroup>
        </div>

        <div className="col-sm-3">
          {selectedCountry && countriesWithStates.includes(selectedCountry) ? (
            <FormGroup controlId={name('region')}>
              <ControlLabel>Region</ControlLabel>
              <SelectInput
                name={name('region')}
                selectComponent={StatesSelector}
                selectedCountry={selectedCountry}
              />
            </FormGroup>
          ) : (
            <FormGroup controlId={name('region')}>
              <ControlLabel>Region</ControlLabel>
              <FormInput type="text" name={name('region')} />
            </FormGroup>
          )}
        </div>
        <div className="col-sm-3">
          <FormGroup controlId={name('country')}>
            <ControlLabel required>Country</ControlLabel>
            <SelectInput
              name={name('country')}
              selectComponent={CountrySelector}
            />
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-md-3">
          <FormGroup controlId={name('eori_number')}>
            <ControlLabel>EORI Number</ControlLabel>
            <FormInput type="text" name={name('eori_number')} />
          </FormGroup>
        </div>
        {voecNumber && (
          <div className="col-md-3">
            <FormGroup controlId={name('voec_number')}>
              <ControlLabel>VOEC Number</ControlLabel>
              <FormInput type="text" name={name('voec_number')} />
            </FormGroup>
          </div>
        )}
      </div>
    </div>
  )
}

const FlagButtonContainer = styled.div`
  background: #f9f9f9;
  padding: 3px 8px;
  margin-bottom: 10px;
`
