/* @flow */

import * as React from 'react'
import { List, Map } from 'immutable'
import { createApiHook, quickCreateHook } from '../hooks'

import { getPrice } from '../products/components/Price'
import { toQuantityTotal } from '../orders/components/shared'

import {
  clearCart,
  ensureAnonymousWebshopSession,
  getProductsAttributes,
  getProducts,
  getPublicBrand,
  getCartRows,
  getWebshopPagesForSession,
  getWebshopPayStatus,
  getWebshopProductLabels,
  removeCartRows,
  updateCart,
} from './api'

import { msg } from '../shared'
import type {
  ConnectedBrand,
  Currency,
  Id,
  WebshopProductLabel,
  WebshopSession,
} from '../types'

export const usePublicBrand = createApiHook<ConnectedBrand | null>(
  (id?: Id, options?: Object) => {
    return getPublicBrand(id, options).then(response => {
      if (response.status === 200) {
        return {
          entity: response.payload.brand,
        }
      } else {
        return {
          error: true,
        }
      }
    })
  }
)

const {
  hook: useAnonymousWebshopSession,
  hookCached: useCachedAnonoymousWebshopSession,
  clearCache: clearAnonymousWebshopSessionCache,
} = quickCreateHook<WebshopSession>(
  ensureAnonymousWebshopSession,
  'session',
  null
)

export {
  useAnonymousWebshopSession,
  useCachedAnonoymousWebshopSession,
  clearAnonymousWebshopSessionCache,
}

export const useProductsAttributes = createApiHook<Object | null>(
  (brandId, options) => {
    return getProductsAttributes(brandId, options).then(response => {
      if (response.status === 200) {
        return {
          entity: response.payload,
        }
      } else {
        return {
          error: true,
        }
      }
    })
  }
)

const fetchWebshopProductLabelsForHook = (options?: Object) =>
  getWebshopProductLabels().then(response => {
    if (!response.error) {
      return {
        entity: response.payload.webshop_product_labels,
      }
    } else {
      return response
    }
  })

export const useWebshopProductLabels = createApiHook<
  Array<WebshopProductLabel>
>(fetchWebshopProductLabelsForHook, [])

const fetchWebshopPayStatusForHook = (brandId, webshopSessionId, token) =>
  getWebshopPayStatus(brandId, webshopSessionId, token).then(response => {
    if (!response.error) {
      return {
        entity: response.payload,
      }
    } else {
      return response
    }
  })

export const useWebshopPayStatus = createApiHook<Object | null>(
  fetchWebshopPayStatusForHook
)

const {
  hook: useCartRows,
  hookCached: useCachedCartRows,
  clearCache: clearCartRows,
} = quickCreateHook<CartRow>(getCartRows, 'rows', [])

export { useCartRows, useCachedCartRows, clearCartRows }

const {
  hook: useShopProducts,
  hookCached: useCachedShopProducts,
  clearCache: clearShopProducts,
} = quickCreateHook<Product>(getProducts, 'products', [])

export { useShopProducts, useCachedShopProducts, clearShopProducts }

const {
  hook: useWebshopPagesForSession,
  hookCached: useCachedWebshopPagesForSession,
  clearCache: clearWebshopPagesForSession,
} = quickCreateHook<Product>(getWebshopPagesForSession, null, [])

export {
  useWebshopPagesForSession,
  useCachedWebshopPagesForSession,
  clearWebshopPagesForSession,
}

const useCartActions = (
  brand: ConnectedBrand,
  currency: Currency,
  webshopSessionId: Id,
  fetchWebshopTotals: Function,
  productId?: Id
) => {
  const [quantities, setQuantities] = React.useState(Map())
  const cartRowsFetchArgs = React.useMemo(() => {
    return [brand.id, currency, webshopSessionId, productId]
  }, [brand, currency, webshopSessionId, productId])
  const [fetchedRows, isFetching, { isInitialized, refresh }] =
    useCartRows(cartRowsFetchArgs)
  const [updatedVariants, setUpdatedVariants] = React.useState({})

  const rows = React.useMemo(
    () =>
      List(
        fetchedRows.map(row => {
          // Used by OrderProductTable
          row.net_price = getPrice(row.variant.prices[currency])
          return row
        })
      ),
    [currency, fetchedRows]
  )

  const onClearCart = React.useCallback(
    callback => {
      return clearCart(brand.id, currency, webshopSessionId).then(response => {
        if (!response.error) {
          refresh()

          fetchWebshopTotals()

          callback()
        }
      })
    },
    [brand, currency, fetchWebshopTotals, refresh, webshopSessionId]
  )

  const onRemoveVariants = React.useCallback(
    ({ linesToRemove, productToRemove, variantsToRemove }) => {
      return removeCartRows(
        brand.id,
        currency,
        Object.values(linesToRemove),
        webshopSessionId
      ).then(response => {
        if (!response.error) {
          refresh()

          fetchWebshopTotals()
        }
      })
    },
    [
      brand,
      currency,
      fetchWebshopTotals,
      quantities,
      refresh,
      setQuantities,
      webshopSessionId,
    ]
  )

  const saveCart = React.useCallback(() => {
    const asVariantQuantityMap = Object.keys(updatedVariants).reduce(
      (carry, variantId) => {
        carry[variantId] = {
          quantity: updatedVariants[variantId].quantity,
          colli: updatedVariants[variantId].colli,
        }
        return carry
      },
      {}
    )

    return updateCart(
      brand.id,
      currency,
      asVariantQuantityMap,
      webshopSessionId
    ).then(response => {
      if (!response.error) {
        // Here we group all the changes that were zero. These should be removed from the table.
        const removesGroupByProductId = Object.keys(updatedVariants).reduce(
          (carry, variantId) => {
            const { productId, quantity } = updatedVariants[variantId]

            if (quantity === 0) {
              if (!carry[productId]) {
                carry[productId] = []
              }

              // Make sure int for Immutable
              carry[productId].push(parseInt(variantId))
            }

            return carry
          },
          {}
        )

        let updatedQuantities = quantities

        Object.keys(removesGroupByProductId).forEach(productId => {
          updatedQuantities = removeVariantsFromQuantities(
            updatedQuantities,
            parseInt(productId), // Make sure int for Immutable
            removesGroupByProductId[productId]
          )
        })

        setQuantities(updatedQuantities)
        setUpdatedVariants({})

        msg('success', 'The cart was saved')

        fetchWebshopTotals()
      }
    })
  }, [
    fetchWebshopTotals,
    quantities,
    setQuantities,
    setUpdatedVariants,
    updatedVariants,
    webshopSessionId,
  ])

  const onQuantitiesChange = React.useCallback(
    (variant, quantity, quantities) => {
      setUpdatedVariants(s => {
        const copy = { ...s }

        copy[variant.id] = {
          productId: variant.product_id,
          variantId: variant.id,
          quantity: quantity.get('quantity'),
          colli: quantity.get('colli'),
        }

        return copy
      })

      setQuantities(quantities)
    },
    [saveCart, setQuantities, setUpdatedVariants]
  )

  React.useEffect(() => {
    if (Object.getOwnPropertyNames(updatedVariants).length === 0) {
      return
    }

    let timer = setTimeout(() => {
      saveCart()
    }, 2000)

    return () => {
      clearTimeout(timer)
    }
  }, [updatedVariants, saveCart])

  React.useEffect(() => {
    setQuantities(
      rows.reduce((carry, row) => {
        return carry.setIn(
          [row.product_id, row.variant_id],
          Map({
            colli: row.colli,
            quantity: row.quantity,
          })
        )
      }, Map())
    )
  }, [rows, setQuantities])

  const totals = React.useMemo(() => {
    return rows.reduce(
      (carry, row) => {
        if (!row.variant || !row.product) {
          return carry
        }

        const price = row.variant.prices[currency]
        const quantity = quantities.getIn([row.product.id, row.variant.id])
        if (price && quantity) {
          carry.quantity += toQuantityTotal(quantity)
          carry.price += getPrice(price) * toQuantityTotal(quantity)
        }
        return carry
      },
      {
        quantity: 0,
        price: 0,
      }
    )
  }, [currency, quantities, rows])

  return {
    clearCart: onClearCart,
    isInitialized,
    onQuantitiesChange,
    onRemoveVariants,
    refresh,
    setUpdatedVariants,
    quantities,
    rows,
    totals,
  }
}

export { useCartActions }

const removeVariantsFromQuantities = (
  quantities: Quantities,
  productId: number,
  variantIds: Array<number>
): Quantities => {
  let updatedQuantities = variantIds.reduce((carry, variantId) => {
    return carry.deleteIn([productId, variantId])
  }, quantities)

  if (updatedQuantities.get(productId).size === 0) {
    updatedQuantities = updatedQuantities.delete(productId)
  }

  return updatedQuantities
}
