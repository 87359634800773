/* @flow */

import * as React from 'react'
import styled from 'styled-components'

const defaultColor = '#5E5E5E'

export const colors = {
  '0': '#0aa699',
  '1': '#0090d9',
  '2': '#e6bf21',
  '3': '#f35958',
  '4': '#1f3853',
}

const getColorIndex = index => (colors[index] ? colors[index] : defaultColor)

export const ColorText = ({
  index = 0,
  text,
}: {
  index: number,
  text: React.Node,
}) => {
  const color = getColorIndex(index)
  return <span style={{ color }}>{text}</span>
}

export const ColorPill = ({
  index = 0,
  text,
  transparent = false,
}: {
  index: number,
  text: React.Node,
  transparent: boolean,
}) => {
  let color = getColorIndex(index)
  let textColor = 'white'

  if (transparent) {
    color = 'none'
    text = <ColorText index={index} text={text} />
  }

  return <Pill style={{ background: color, color: textColor }}>{text}</Pill>
}

const Pill = styled.div`
  border-radius: 10px;
  padding: 3px 5px;
`
