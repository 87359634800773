import React, { useMemo, useState, useCallback } from 'react'
import styled from 'styled-components'

import { shouldNotifyAboutCookiesUsage, isCookiesPermissionGiven, setAllowCookies } from './helpers'

const message = 'This site uses cookies. If you\'re agree to our use of cookies, please close this message and continue to use this site.'

const CookiesBanner = ({ brand }) => {
  const isPermissionGiven = isCookiesPermissionGiven();

  const [showBanner, setBannerVisibility] = useState(!isPermissionGiven);

  const shouldNotify = useMemo(() => {
    return shouldNotifyAboutCookiesUsage(brand)
  }, [brand]);

  const handleAllowCookies = useCallback(() => {
    setBannerVisibility(false);
    setAllowCookies();
  }, []);

  if (!shouldNotify || isPermissionGiven || !showBanner) return null;

  return (
    <BannerContainer>
      <BannerInnerContainer>
        <BannerTextContainer>{message}</BannerTextContainer>
        <BannerActionContainer>
          <button
            type="button"
            className="btn btn-info"
            onClick={handleAllowCookies}
          >
            <i className="glyphicon glyphicon-check" /> Accept & close
          </button>
        </BannerActionContainer>
      </BannerInnerContainer>
    </BannerContainer>
  )
}

const BannerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 20px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 7px #808080;
`;

const BannerInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BannerTextContainer = styled.div``;

const BannerActionContainer = styled.div`
  margin-left: 10px;
`;

export default CookiesBanner;
