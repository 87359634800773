/* @flow */

import api from '../api'

export const getOrderChannels = options =>
  api.get('/order-channels', {
    query: options,
  })

export const getOrderChannel = (channelId, options) =>
  api.get(`/order-channels/${channelId}`, {
    query: options,
  })

export const createOrderChannel = data =>
  api.post(`/order-channels`, {
    body: {
      order_channel: data,
    },
  })

export const updateOrderChannel = (id, data) =>
  api.put(`/order-channels/${id}`, {
    body: {
      order_channel: data,
    },
  })

export const updateOrderChannelProductMapping = (
  orderChannelId,
  productId,
  productOrderChannel,
  attributes
) =>
  api.put(`/order-channels/${orderChannelId}/products/${productId}/mapping`, {
    body: {
      product_order_channel: productOrderChannel,
      attributes,
    },
  })

export const updateOrderChannelsProductAvailability = availability =>
  api.post(`/order-channels/products`, {
    body: {
      availability,
    },
  })

export const getOrderChannelsProductAttributes = (orderChannelId, productId) =>
  api.get(`/order-channels/${orderChannelId}/products/${productId}/attributes`)

export const getOrderChannelsProductMapping = (
  orderChannelId,
  productId,
  body
) =>
  api.post(`/order-channels/${orderChannelId}/products/${productId}/mapping`, {
    body,
  })

export const updateOrderChannelsProductAttributes = attributes =>
  api.put(`/order-channels/products/attributes`, {
    body: {
      attributes,
    },
  })

export const getOrderChannelProductSettings = (productId, options) =>
  api.get(`/order-channels/products/${productId}/settings`, {
    query: options,
  })

export const getOrderChannelsProductSyncStatus = productIds =>
  api.post(`/order-channels/products/sync-status`, {
    body: {
      product_ids: productIds,
    },
  })

export const pushOrderChannelProductsCollection = orderChannelId =>
  api.post(`/order-channels/${orderChannelId}/products/push-collection`)

export const getOrderChannelFieldMappings = orderChannelId =>
  api.get(`/order-channels/${orderChannelId}/field-mappings`)

export const updateOrderChannelFieldMappings = (
  orderChannelId,
  fieldMappingSets
) =>
  api.put(`/order-channels/${orderChannelId}/field-mappings`, {
    body: {
      field_mapping_sets: fieldMappingSets,
    },
  })

export const getOrderChannelClassifications = orderChannelId =>
  api.get(`/order-channels/${orderChannelId}/classifications`)

export const getOrderChannelSchema = (orderChannelId, classification) =>
  api.get(`/order-channels/${orderChannelId}/schema`, {
    query: {
      classification,
    },
  })

export const getOrderChannelProductFields = orderChannelId =>
  api.get(`/order-channels/${orderChannelId}/product-fields`)
