/* @flow */

import * as React from 'react'

import weight from './weight'

const weightKg = options => {
  return weight({
    ...options,
    weight_type: 'kg',
  })
}

export default weightKg
