/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import UnitPrice from '../../UnitPrice'
import UnitPriceV2 from '../../UnitPriceV2'

import { createConfiguredVariantId } from '../../../../../shared'
import type { ColumnConfig } from '../../types'

type Price = {
  price_before_discount: number,
  discount_amount: number,
  discount_percentage: number,
  net_price: number,
}

type Options = {
  editable?: boolean,
  editProperty?: string,
  linePriceExtractor: () => Price,
  rows: Array<string>,
  showDiscount?: boolean,
  valueToNewLine?: () => void,
}

const defaultPrice = {
  price_before_discount: 0,
  discount_amount: 0,
  discount_percentage: 0,
  net_price: 0,
}

const unit_price: (options: Options) => ColumnConfig = ({
  allowUpdatingNetPrice = true,
  allowUpdatingDiscountPercentage = true,
  aggregate_product = 'multi',
  editable = false,
  editProperty = 'net_price',
  employeeDiscount = false,
  employeeDiscountEditable = true,
  // some places, like with POs the price format in data and lines are different
  dataPriceExtractor = defaultLinePriceExtractor,
  inclVat = false,
  linePriceExtractor = defaultLinePriceExtractor,
  mapPropertiesOnModalSave,
  priceProperty,
  rows,
  showDiscount = true,
  valueToNewLine,
  v2 = false,
}) => {
  const useDataPriceExtractor = price =>
    dataPriceExtractor(price, inclVat, employeeDiscount)
  const useLinePriceExtractor = line =>
    linePriceExtractor(line, inclVat, employeeDiscount)

  return {
    aggregate_product,
    aggregate_render: 'money',
    aggregate_value: ({ columnValue }) => {
      return columnValue ? columnValue.net_price : 0
    },
    data_source: 'pricing',
    default_value: [defaultPrice],
    editable: editable,
    edit_property: editProperty,
    key: 'unit_price',
    label: 'Unit price',
    rows: rows,
    right: true,
    showDiscount,
    split_by_values: true,
    render: ({
      context,
      config,
      currency,
      data,
      edit,
      editProperty,
      editable,
      lines,
      onColumnValueChange,
      onEdit,
      product,
      columnValues,
      variants,
      value,
    }) => {
      const PriceComponent = v2 ? UnitPriceV2 : UnitPrice

      return (
        <PriceComponent
          allowUpdatingDiscountPercentage={allowUpdatingDiscountPercentage}
          allowUpdatingNetPrice={allowUpdatingNetPrice}
          context={context}
          currency={currency}
          dataPriceExtractor={useDataPriceExtractor}
          edit={edit}
          editProperty={editProperty}
          editable={config.editable}
          employeeDiscountEditable={employeeDiscountEditable}
          lines={lines}
          mapPropertiesOnModalSave={mapPropertiesOnModalSave}
          onChange={onColumnValueChange}
          onEdit={onEdit}
          price={value}
          priceProperty={priceProperty}
          product={product}
          showDiscount={showDiscount}
          variants={variants}
        />
      )
    },
    value: ({ data, line, variant }) => {
      if (line) {
        return [useLinePriceExtractor(line)]
      }

      const pricingData =
        data.pricing && data.pricing[0] ? data.pricing[0] : null

      if (!pricingData) {
        return false
      }

      let priceIndex
      if (variant.__is_configured_variant) {
        let id = createConfiguredVariantId(variant.__configurator_values)

        priceIndex = pricingData.configured_variants[id]
      } else {
        priceIndex = pricingData.variants[variant.id]
      }

      // 0 evaluates falsely
      if (priceIndex === undefined || priceIndex === false) {
        return false
      }

      const price = pricingData.prices[priceIndex]

      return [useDataPriceExtractor(price)]
    },
    value_to_new_line: valueToNewLine,
  }
}

export default unit_price

export const defaultLinePriceExtractor = (line, inclVat, employeeDiscount) => {
  let priceBeforeDiscount = line.price_before_discount
  let discountAmount = line.discount_amount
  let netPrice = line.net_price

  if (inclVat) {
    if (priceBeforeDiscount > 0 && line.vat_amount > 0) {
      priceBeforeDiscount += line.vat_amount
    }
    if (discountAmount > 0 && line.vat_amount > 0) {
      discountAmount += line.vat_amount
    }
    if (netPrice > 0 && line.vat_amount > 0) {
      netPrice += line.vat_amount
    }
  }

  const data = {
    price_before_discount: priceBeforeDiscount,
    discount_amount: discountAmount,
    discount_percentage: line.discount_percentage,
    // needed for when doing splits in showorder v3 to make sure
    // we calculate the column columnKey
    discount_method: line.discount_method,
    net_price: netPrice,
  }

  if (employeeDiscount) {
    data.employee_discount = line.employee_discount || false
  }

  return data
}
