import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
} from '../actions/index'
import { transformData } from '../components/ProductTable'

export const initialState = {
  isAdding: false,
  isFetching: false,
  didInvalidate: false,
  items: {},
  variants: {},
}

/**
 * Reducer to update the product state tree
 * @param  {object} state  =             initialState
 * @param  {object} action
 * @return {object}
 */
export default function products(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case GET_PRODUCTS_SUCCESS:
      const transformed = transformData(action.payload.products).reduce(
        (carry, product) => {
          carry[product.id] = product
          return carry
        },
        {}
      )
      const variants = Object.keys(transformed).reduce((carry, productId) => {
        return transformed[productId].variants.reduce((innerCarry, variant) => {
          innerCarry[variant.id] = variant
          return innerCarry
        }, carry)
      }, {})

      return Object.assign({}, state, {
        isFetching: false,
        items: transformed,
        variants,
      })
    case GET_PRODUCTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
      })
    default:
      return state
  }
}
