/* @flow */

import * as React from 'react'
import MatrixRowsRenderer from './MatrixRowsRenderer'
import TraditionalRowsRenderer from './TraditionalRowsRenderer'

const RowsRenderer = ({ matrix, ...rest }: { matrix: boolean, ... }) => {
  return matrix ? (
    <MatrixRowsRenderer {...rest} />
  ) : (
    <TraditionalRowsRenderer {...rest} />
  )
}

export default RowsRenderer
