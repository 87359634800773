/* @flow */

import { createApiHook, createDataCache } from '../../hooks'

import { getDrops } from '../api'
import type { Drop } from '../../types'

const fetchDropsForHook = (options?: Object) =>
  getDrops(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.drops,
      }
    } else {
      return response
    }
  })

export const useDrops = createApiHook<Array<Drop>>(fetchDropsForHook, [])

const {
  cache: { clearCache },
  hook: useCachedDrops,
} = createDataCache(useDrops)

export { clearCache, useCachedDrops }
