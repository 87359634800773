/* @flow */

import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import sortBy from 'lodash/sortBy'

import { getCollections } from '../api'
import { createApiHook, createDataCache } from '../../hooks'
import type { BrunoRequestOptions, Collection } from '../../types'

const fetchCollectionsForHook = (options?: BrunoRequestOptions) =>
  getCollections(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.collections,
      }
    } else {
      return response
    }
  })

export const useCollections = createApiHook<Array<Collection>>(
  fetchCollectionsForHook,
  []
)

const {
  cache: { clearCache },
  hook: useCachedCollections,
} = createDataCache(useCollections)

export { clearCache }

const CollectionSelector = props => {
  const [collections, { isFetching }] = useCachedCollections()

  return <DatalessCollectionSelector collections={collections} {...props} />
}

export default CollectionSelector

export const DatalessCollectionSelector = ({
  collections,
  excludeOptions,
  isFetching,
  ...props
}) => {
  const options = useMemo(() => {
    let options = sortBy(collections, 'title')

    if (excludeOptions) {
      options = options.filter(
        collection => !excludeOptions.includes(collection.id)
      )
    }

    return options
  }, [collections, excludeOptions])

  return (
    <Select
      isLoading={isFetching}
      labelKey="title"
      placeholder="Select collection..."
      options={options}
      valueKey="id"
      {...props}
    />
  )
}
