/* @flow */

import api from '../api'

import type {
  Address,
  ApiResponse,
  BrunoRequestOptions,
  Customer,
  CustomerGroup,
  DataTableRequestOptions,
  Entity,
  Filter,
  Id,
  PriceList,
  PriceListPrice,
  Prospect,
  SortDirection,
  User,
} from '../types'

// Base
export type RequestCustomer = {|
  ...Customer,
  customer_groups: Array<Id>,
|}

export type RequestCustomerUser = {
  email: string,
  firstname: string,
  title: string,
  telephone: string,
  invite_email_subject: string,
  invite_email_body: string,
}

export const createCustomer = (
  customer: RequestCustomer,
  entity: { name: string, billing_emails: Array<string> },
  addresses?: Array<Address>,
  contact?: { firstname: string, email: string },
  sendInvitation?: boolean
) =>
  api.post('/customers', {
    body: {
      customer,
      entity,
      contact,
      addresses,
      send_customer_email: sendInvitation,
    },
  })

export const getCustomer = (customerId: Id, query?: Object) =>
  api.get(`/customers/${customerId}`, {
    query,
  })

export const getCustomers = (axiosOptions, options) =>
  api.get(`/customers`, {
    query: options,
    ...axiosOptions,
  })

export const getCustomerHealth = (customerId: Id) =>
  api.get(`/customers/${customerId}/health`)

export const getCustomersList = (
  options?: BrunoRequestOptions
): Promise<ApiResponse<{ customers: Array<Customer>, total: number }>> =>
  api.get('/customers/list', {
    query: options,
  })

export const updateCustomer = (
  customerId: Id,
  customer: $Shape<RequestCustomer>
) =>
  api.put(`/customers/${customerId}`, {
    body: {
      customer,
    },
  })

export const updateCustomersBulk = (customers: Array<Id>, data: Object) =>
  api.put('/customers', {
    body: {
      customers,
      data,
    },
  })

export const getNextCustomerNumber = () =>
  api.get(`/customers/next-customer-number`)

export const getCustomersAttributes = () =>
  api.get('/customers/table-parameters')

export const exportCustomers = (
  columns: Array<string>,
  filters: Array<Filter>,
  sort: Array<{ key: string, direction: SortDirection }>,
  splitByAddresses?: boolean
) =>
  api.post(`/customers/export`, {
    body: {
      columns,
      filters,
      sort,
      split_by_addresses: splitByAddresses,
    },
  })

export const checkNewCustomerUserEmail = (email: string) =>
  api.post(`/customers/check-new-customer-user-email`, {
    body: {
      email,
    },
  })

export const getCustomerUsersStatus = (axiosOptions, customerId) =>
  api.get(`/customers/${customerId}/users/status`, {
    ...axiosOptions,
  })

// Consignment

export const activateConsignmentLocation = (customerId: Id) =>
  api.post(`/consignment/${customerId}/activate-location`)

// Agencies

export const getAgencies = () => api.get('/agencies')

// Users

export const createCustomerUser = (
  customerId: Id,
  user: RequestCustomerUser,
  sendInvitation?: boolean
) =>
  api.post(`/customers/${customerId}/users`, {
    body: {
      contact: user,
      send_customer_email: sendInvitation,
    },
  })

export const resendActivationEmail = (
  customerId: Id,
  userId: Id,
  force,
  emailAdjustmentData
) =>
  api.post(`/customers/${customerId}/users/${userId}/resend-activation-mail`, {
    body: {
      force,
      email_adjustment_data: emailAdjustmentData,
    },
  })

// Addresses

export const getCustomerAddresses = (customerId: Id) =>
  api.get(`/customers/${customerId}/addresses`)

export const createCustomerAddress = (
  customerId: Id,
  address: $Shape<Address>
) =>
  api.post(`/customers/${customerId}/addresses`, {
    body: {
      address,
    },
  })

export const deleteCustomerAddress = (customerId: Id, addressId: Id) =>
  api.delete(`/customers/${customerId}/addresses/${addressId}`)

export const mergeCustomerAddresses = (
  customerId: Id,
  addressesToMerge: Array<Id>,
  receivingAddressId: Id
) =>
  api.post(`/customers/${customerId}/addresses/merge`, {
    body: {
      merge_addresses: addressesToMerge,
      receiving_address: receivingAddressId,
    },
  })

export const updateCustomerAddress = (
  customerId: Id,
  addressId: Id,
  address: $Shape<Address>
) =>
  api.put(`/customers/${customerId}/addresses/${addressId}`, {
    body: {
      address,
    },
  })

// Groups

export const getCustomerGroups = (options?: BrunoRequestOptions) =>
  api.get('/customer-groups', {
    query: options,
  })

// Mailchimp

export const syncCustomerGroupToMailchimp = (customerGroupId: Id) =>
  api.post(`/apps/mailchimp/customer-groups/${customerGroupId}/sync`)

// Price Lists

export const getPriceList = (
  id: Id,
  options?: BrunoRequestOptions
): Promise<ApiResponse<{ price_list: PriceList }>> =>
  api.get(`/price-lists/${id}`, {
    query: options,
  })

export const getPriceLists = (
  options?: BrunoRequestOptions
): Promise<ApiResponse<{ price_lists: Array<PriceList> }>> =>
  api.get('/price-lists', {
    query: options,
  })

export const createPriceList = (
  data: $Shape<PriceList>
): Promise<ApiResponse<PriceList>> =>
  api.post('/price-lists', {
    body: {
      price_list: data,
    },
  })

export const updatePriceList = (
  id: Id,
  data: $Shape<PriceList>
): Promise<ApiResponse<PriceList>> =>
  api.put(`/price-lists/${id}`, {
    body: {
      price_list: data,
    },
  })

export const deletePriceList = (id: Id): Promise<ApiResponse<null>> =>
  api.delete(`/price-lists/${id}`)

export const downloadPriceListSheet = (
  id: Id,
  variants?: boolean
): Promise<ApiResponse<{ name: string, code: string }>> =>
  api.post(`/price-lists/${id}/download-sheet`, {
    body: {
      variants: variants ? 'true' : undefined,
    },
  })

export const importPriceListPrices = (
  id: Id,
  updateFile: string,
  variants?: boolean
): Promise<ApiResponse<null>> =>
  api.post(`/price-lists/${id}/import-prices`, {
    body: {
      file: updateFile,
      variants: variants ? 'true' : undefined,
    },
  })

export const pushCustomerEaccounting = (customerId: Id) =>
  api.post(`/eaccounting/customers/${customerId}/push`)

export const pushCustomerEconomic = (customerId: Id) =>
  api.post(`/economic/customers/${customerId}/push-customer`)

export const pushCustomerFortnox = (customerId: Id) =>
  api.post(`/apps/fortnox/customers/${customerId}/push`)

export const pushCustomerExact = (customerId: Id) =>
  api.post(`/apps/exact/customers/${customerId}/push`)

export const pushCustomerTripletex = (customerId: Id) =>
  api.post(`/apps/tripletex/customers/${customerId}/push`)

export const pushCustomerQuickbooks = (customerId: Id) =>
  api.post(`/apps/quickbooks/customers/${customerId}/push`)

export const pushCustomerXero = (customerId: Id) =>
  api.post(`/apps/xero/customers/${customerId}/push`)

// Public registration

export const registerCustomerAccount = (brandId: Id, account) =>
  api.post(`/register/${brandId}`, {
    body: {
      account,
    },
  })

export const registerCustomerAccept = (brandId: Id, customerId) =>
  api.post(`/register/accept/${brandId}`, {
    body: {
      customerId: customerId,
    },
  })

export const registerCustomerDecline = (brandId: Id, customerId) =>
  api.post(`/register/decline/${brandId}`, {
    body: {
      customerId: customerId,
    },
  })

export const registerCustomerSkip = (brandId: Id, customerId) =>
  api.post(`/register/skip/${brandId}`, {
    body: {
      customerId: customerId,
    },
  })

// Emails

export const sendCustomerActivationEmailBulk = (
  customers: Array<{ customer_id: Id }>
) =>
  api.post('/customers/send-activation', {
    body: {
      customers,
    },
  })

export const sendCustomerPasswordReminderBulk = (
  customers: Array<{ customer_id: Id }>
) =>
  api.post('/customers/send-password-reminder', {
    body: {
      customers,
    },
  })

// Insights

export const getCustomerInsights = (
  customerId: Id,
  topCategoriesOptions,
  topProductsOptions
) =>
  api.post(`/customers/${customerId}/insights`, {
    body: {
      top_categories: topCategoriesOptions,
      top_products: topProductsOptions,
    },
  })

export const getCustomerInsightsTopCategories = (
  customerId: Id,
  topCategoriesOptions
) =>
  api.post(`/customers/${customerId}/insights/top-categories`, {
    body: {
      options: topCategoriesOptions,
    },
  })

export const getCustomerInsightsTopProducts = (
  customerId: Id,
  topProductsOptions
) =>
  api.post(`/customers/${customerId}/insights/top-products`, {
    body: {
      options: topProductsOptions,
    },
  })

export const validateCustomerVat = (customerId: Id) =>
  api.post(`/customers/${customerId}/validate-vat`)

export const validateVatNumber = (country, vatNumber) =>
  api.post(`/customers/validate-vat-number`, {
    body: {
      country,
      vat_number: vatNumber,
    },
  })

export const ensurePublicDataAccessCode = (customerId: Id) =>
  api.post(`/customers/${customerId}/ensure-public-data-access-code`)

export const getCustomerPrices = (
  customerId: Id,
  productIds: Array<Id>,
  configuredVariants,
  useOfferPrices,
  dropId
) =>
  api.post(`/customers/${customerId}/prices`, {
    body: {
      product_ids: productIds,
      configured_variants: configuredVariants,
      use_offer_prices: useOfferPrices,
      drop_id: dropId,
    },
  })

// Prospects

export const getProspectList = (
  options?: DataTableRequestOptions
): Promise<ApiResponse<{ prospects: Array<Prospect>, total: number }>> =>
  api.get('/customers/prospects', {
    query: options,
  })

export const getProspectAttributes = () =>
  api.get('/customers/prospects/attributes')
