import moment from 'moment-timezone'
import { ApiError, CALL_API, CALL_API_BATCH } from '../middleware/api'
import { SetUserContext } from '../exceptions/raven'
import { push } from 'react-router-redux'
import localStorage from '../modules/localStorage'
import LogRocket from 'logrocket'
import mixpanel from 'mixpanel-browser'
import querystring from 'qs'

const urlQuery = querystring.parse(window.location.search.slice(1))
const isPdfGeneration = urlQuery.traede_pdf_generation !== undefined

export const ACCESS_TOKEN_STORAGE_KEY = 'traede_access_token'
export const ADMIN_EMAIL_STORAGE_KEY = 'admin_email'
export const FORCE_REFRESH_SESSION = 'FORCE_REFRESH_SESSION'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_ADMIN_EMAIL = 'SET_ADMIN_EMAIL'
export const SET_SESSION = 'SET_SESSION'

export const USER_LOGOUT_START = 'USER_LOGOUT_START'
export const USER_LOGOUT_FINISH = 'USER_LOGOUT_FINISH'
export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE'

export const REFRESH_SESSION_REQUEST = 'REFRESH_SESSION_REQUEST'
export const REFRESH_SESSION_SUCCESS = 'REFRESH_SESSION_SUCCESS'
export const REFRESH_SESSION_FAILURE = 'REFRESH_SESSION_FAILURE'

export const ME_REQUEST = 'ME_REQUEST'
export const ME_SUCCESS = 'ME_SUCCESS'
export const ME_FAILURE = 'ME_FAILURE'

export const TOKEN_REFRESH_REQUEST = 'TOKEN_REFRESH_REQUEST'
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS'
export const TOKEN_REFRESH_FAILURE = 'TOKEN_REFRESH_FAILURE'

export const getMe = () => ({
  [CALL_API]: {
    endpoint: '/me',
    method: 'GET',
    types: [ME_REQUEST, ME_SUCCESS, ME_FAILURE],
  },
})

export const refreshSession = () => {
  return dispatch => {
    return dispatch(getMe()).then(response => {
      // Since this is a batch response we have to check for ApiError
      if (
        response &&
        response.payload &&
        !(response.payload instanceof ApiError)
      ) {
        const { customer, entity, settings, user } = response.payload
        dispatch(setSession(entity, settings, user, customer))
        // If the refresh was not successful (this does not include 401s as
        // these are caught by the API middleware) we log out the user.
      } else {
        return dispatch(logoutUser())
      }
    })
  }
}

export const logrocketIdentify = (user, entity, subUser) => {
  try {
    if (
      process.env.NODE_ENV === 'production' &&
      process.env.LOG_ROCKET_CLIENT &&
      user &&
      user.id &&
      entity &&
      entity.id
    ) {
      let id = user.id.toString()
      let name = user.firstname
      if (subUser) {
        id += `-${subUser.id.toString()}}`

        name += ` (sub user: ${subUser.name})`
      }

      LogRocket.identify(id, {
        name: name,
        email: user.email,

        entityId: entity.id,
        company: entity.name,
        companyType: entity.entity_type,
      })
    }
  } catch (e) {
    console.error('Could not idenitfy logrocket', e)
  }
}

export const setSession = (entity, settings, user, customer) => {
  SetUserContext(user, entity, settings)
  moment.locale(user.locale)
  moment.tz.setDefault(user.timezone)

  if (!isPdfGeneration) {
    try {
      logrocketIdentify(user, entity, null)
    } catch (e) {
      console.error('Log rocket ID error', e)
    }

    if (process.env.NODE_ENV === 'production' && process.env.MIXPANEL_TOKEN) {
      mixpanel.identify(user.id.toString())

      if (entity.entity_type === 'brand') {
        mixpanel.register({
          brand_id: entity.id,
          brand_url: entity.url,
        })
      }

      mixpanel.people.set({
        $email: user.email,
        name: user.firstname,
        email: user.email,
        userType: user.user_type,

        entityId: entity.id,
        company: entity.name,
        companyType: entity.entity_type,
      })
    }
  }

  return {
    type: SET_SESSION,
    customer,
    entity,
    settings,
    user,
  }
}

export const setAccessToken = (accessToken, accessTokenExpiration) => {
  localStorage.setItem(
    ACCESS_TOKEN_STORAGE_KEY,
    JSON.stringify({
      token: accessToken,
      expiration: accessTokenExpiration,
    })
  )

  return {
    type: SET_ACCESS_TOKEN,
    accessToken,
    accessTokenExpiration,
  }
}

export const setAdminEmail = email => {
  localStorage.setItem(ADMIN_EMAIL_STORAGE_KEY, JSON.stringify(email))

  return {
    type: SET_ADMIN_EMAIL,
    email,
  }
}

export const logoutUser = () => {
  return dispatch => {
    // Some times, for whatever reason, some component
    // that relies on session data will re-render before the login
    // view is pushed. We therefore set a global logout state,
    // that renders a blank canvas (so all child views relying on
    // session data are unmounted) before we actually do the
    // clearing of session data. That way we do not run into
    // TypeErrors on logout.
    dispatch({
      type: USER_LOGOUT_START,
    })
    return dispatch({
      [CALL_API]: {
        endpoint: '/logout',
        method: 'POST',
        types: [USER_LOGOUT_REQUEST, USER_LOGOUT_SUCCESS, USER_LOGOUT_FAILURE],
      },
    }).then(response => {
      localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY)
      localStorage.removeItem('admin_email')

      SetUserContext()

      mixpanel.reset()

      dispatch(push('/'))
      dispatch({
        type: USER_LOGOUT_FINISH,
      })
    })
  }
}

export const attemptAccessTokenRefresh = () => ({
  [CALL_API]: {
    endpoint: '/login/refresh',
    method: 'POST',
    types: [
      TOKEN_REFRESH_REQUEST,
      TOKEN_REFRESH_SUCCESS,
      TOKEN_REFRESH_FAILURE,
    ],
  },
})
