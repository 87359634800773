/* @flow */

import * as React from 'react'
import styled from 'styled-components'

import { openPdf } from './actions'

import { Tooltip } from '../shared'
import type { Id } from '../types'

type Props = {
  children?: React.Node,
  documentId: Id,
  regenerate?: boolean,
}

const OpenPdfButton = ({
  children,
  documentId,
  regenerate = false,
  render = defaultRender,
  ...rest
}: Props) => {
  const open = () => {
    return openPdf(documentId, regenerate === true ? 1 : 0)
  }

  return render({
    children,
    documentId,
    open,
    ...rest,
  })
}

export default OpenPdfButton

const defaultRender = ({ children, documentId, open, size = '' }) => {
  return (
    <Tooltip id={`download-document-${documentId}`} tip="Download PDF">
      <button
        type="button"
        className={`btn btn-white btn-${size}`}
        onClick={open}
      >
        {children}
      </button>
    </Tooltip>
  )
}
