import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import memoize from 'memoize-one'
import { Creatable } from 'react-select'
import { Field } from 'redux-form'
import {
  Button,
  ButtonGroup,
  Checkbox,
  ControlLabel as BaseControlLabel,
  FormControl as BaseFormControl,
  FormGroup,
  InputGroup,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import Globalize from '../modules/globalize'
import isArray from 'lodash/isArray'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import CountrySelector from './CountrySelector'
import styled from 'styled-components'

export const RenderFormInput = props => {
  const { input, meta, type, leftAddon, rightAddon, ...rest } = props

  let control = <FormControl type={type} {...input} {...rest} />

  if (leftAddon) {
    control = (
      <InputGroup>
        <InputGroup.Addon className="primary">{leftAddon}</InputGroup.Addon>
        {control}
      </InputGroup>
    )
  }

  if (rightAddon) {
    control = (
      <InputGroup>
        {control}
        <InputGroup.Addon className="primary">{rightAddon}</InputGroup.Addon>
      </InputGroup>
    )
  }

  return (
    <div>
      {control}

      {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
    </div>
  )
}

export const FormInput = props => {
  const { component, ...rest } = props
  return <Field component={component} {...rest} />
}

FormInput.defaultProps = {
  component: RenderFormInput,
}

export const RenderTextareaInput = props => {
  const { input, meta, type, ...rest } = props

  return (
    <div>
      <textarea {...input} {...rest} />
      {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
    </div>
  )
}

export const FormTextarea = props => {
  return <Field component={RenderTextareaInput} {...props} />
}

export const FormControl = props => {
  const {
    bsClass = BaseFormControl.defaultProps.bsClass,
    className = '',
    onFocus: propsOnFocus,
    markOnFocus,
    ...rest
  } = props

  const inputRef = React.useRef(null)
  const wrappedFocus = React.useCallback(() => {
    if (markOnFocus && inputRef.current) {
      inputRef.current.setSelectionRange(0, inputRef.current.value.length)
    }

    if (propsOnFocus) {
      propsOnFocus()
    }
  }, [propsOnFocus, markOnFocus, inputRef])

  return (
    <BaseFormControl
      inputRef={inputRef}
      onFocus={wrappedFocus}
      bsClass={`${bsClass} ${className}`}
      {...rest}
    />
  )
}

export const ControlLabel = props => {
  const { children, required, ...rest } = props

  let label = children
  if (required) {
    label = [label, <RequiredLabel />]
  }

  return <BaseControlLabel {...rest}>{label}</BaseControlLabel>
}

ControlLabel.defaultProps = {
  required: false,
}

ControlLabel.propTypes = {
  required: PropTypes.bool,
}

const RequiredLabel = props => <span className="form-required">*</span>

export {
  Button,
  Checkbox,
  FormGroup,
  InputGroup,
  Modal,
  OverlayTrigger,
  RequiredLabel,
  Tooltip,
}

export const HelpHint = props => {
  return <span className="help">{props.children}</span>
}

export const emptyString = input => {
  return typeof input !== 'string' || input.trim().length === 0
}

export const hasValue = input => {
  return isNumber(input) || (isString(input) && input.trim().length > 0)
}

export const isValidFloat = input => {
  return !isNaN(parseFloat(input))
}

const createRenderSelectInputComponent = SelectComponent => {
  const Component = props => {
    // CountrySelector select box will not open if we pass redux-form's onFocus to the react-select component
    // We fix this issue by exporting it here, so onFocus=undefined on SelectWrapper
    const {
      input: { onFocus, onBlur, ...restInput },
      meta,
      type,
      ...rest
    } = props

    // Multi values are saved as joined strings. When splitting the IDs will
    // be strings. We therefore have to parse them as int's
    if (rest.multi && isArray(restInput.value) && rest.multiParseInt) {
      restInput.value = restInput.value.map(value => parseInt(value))
    }

    return (
      <div>
        <SelectComponent {...restInput} {...rest} />
        {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
      </div>
    )
  }

  Component.propTypes = {
    multiParseInt: PropTypes.bool,
  }

  Component.defaultProps = {
    multiParseInt: true,
  }

  return Component
}

export const SELECT_DELIMITER = '!@#$%^&^%$#@@!'

export const SelectWrapper = props => {
  const {
    create,
    value,
    onBlur,
    onFocus,
    multi,
    simpleValue = true,
    onChange,
    ...otherProps
  } = props // onBlur and value was on this.props.fields.myField in MyForm
  // we make sure to extract simpleValue and onChange in order to override
  // otherwise ...otherProps will override our custom methods

  const [createdValues, setCreatedValues] = React.useState(value)

  const parseValue = newValue => {
    if (simpleValue && multi) {
      return (newValue.length ? newValue.split(SELECT_DELIMITER) : []).map(
        value => (props.multiParseInt ? parseInt(value) : value)
      )
    }

    return newValue
  }

  const wrappedOnChange = value => {
    const parsedValue = parseValue(value)

    if (create) {
      setCreatedValues(parsedValue)
    }

    return onChange(parsedValue)
  }

  const { options, ...otherPropsExOptions } = otherProps

  const optionsIncludingCreatedOptions = React.useMemo(() => {
    if (create !== true) {
      return options
    }

    return options.concat(
      createdValues.map(val => ({ label: val, value: val }))
    )
  }, [create, options, createdValues])

  // This is copied from the SelectInput component. We need it for Bulkeditor tags to work
  const optionProps = {}
  if (optionsIncludingCreatedOptions) {
    optionProps.options = optionsIncludingCreatedOptions
  }

  const defaultValue = multi ? [] : ''

  let Component = props.Component
  if (!Component) {
    Component = create ? Creatable : Select
  }

  return (
    <Component
      value={value || defaultValue} // because react-select doesn't like the initial value of undefined
      onBlur={() => onBlur(value)} // just pass the current value (updated on change) on blur
      multi={multi}
      simpleValue={simpleValue}
      delimiter={SELECT_DELIMITER}
      onChange={value => wrappedOnChange(value)}
      onFocus={() => onFocus()}
      {...optionProps}
      {...otherPropsExOptions}
    />
  )
}

SelectWrapper.defaultProps = {
  create: false,
  onBlur: () => {},
  onFocus: () => {},
}

type SelectInputProps = {
  SelectInput: React.Component<any, any>,
}

export class SelectInput extends PureComponent<SelectInputProps, void> {
  static defaultProps = {
    SelectComponent: SelectWrapper,
  }

  render() {
    const { component, SelectComponent, ...rest } = this.props

    return (
      <Field
        component={this._createSelectInputComponent(SelectComponent)}
        {...rest}
      />
    )
  }

  // This will ensure that the created component is not re-mounted over and over again every
  // time the props change
  _createSelectInputComponent = memoize(createRenderSelectInputComponent)
}

export const CountryInput = ({
  SelectComponent,
  ...props
}: {
  SelectComponent?: any,
}) => {
  return <SelectInput SelectComponent={CountrySelector} {...props} />
}

export const SaveButton = props => {
  const {
    type,
    className,
    disabled,
    submitting,
    icon: defaultIcon,
    children,
    ...rest
  } = props
  const icon = submitting ? 'fa fa-spinner fa-spin' : defaultIcon
  return (
    <button
      type={type}
      className={'btn btn-success ' + className}
      disabled={disabled === true || submitting === true}
      {...rest}
    >
      <span className={icon} /> {children}
    </button>
  )
}

SaveButton.defaultProps = {
  className: '',
  icon: 'glyphicon glyphicon-ok',
  onClick: () => {},
  type: 'submit',
}

export const Fieldset = props => {
  const {
    children,
    last,
    overflowable = false,
    stacked = false,
    ...rest
  } = props
  return (
    <fieldset {...rest} className={last ? 'last' : ''}>
      <FieldsetContainer overflowable={overflowable} stacked={stacked}>
        {React.Children.map(children, child => {
          return React.cloneElement(child, {
            overflowable,
            stacked,
          })
        })}
      </FieldsetContainer>
    </fieldset>
  )
}

const FieldsetContainer = styled.div`
  display: ${props => (props.stacked ? 'block' : 'flex')};

  @media (max-width: 1399px) {
    display: block;
    margin-top: 0;
    margin-right: -20px;
    margin-bottom: 20px;
    margin-left: -20px;
    padding-top: 0;
    padding-right: ${props => (props.overflowable ? 0 : '25px')};
    padding-bottom: 10px;
    padding-left: 25px;
  }
`

Fieldset.defaultProps = {
  last: false,
}

Fieldset.Description = props => {
  const { children, danger, required = false, title, ...restProps } = props
  return (
    <FieldsetDescriptionContainer {...restProps}>
      <h4 className={danger ? 'color-red' : ''}>
        {title}
        {required && <RequiredLabel />}
      </h4>
      {children}
    </FieldsetDescriptionContainer>
  )
}

const FieldsetDescriptionContainer = styled.div`
  flex: 1;
  min-width: calc(100% / 6);

  @media (max-width: 1399px) {
    float: none;
    margin-bottom: 15px;
    margin-left: -25px;
    margin-right: -25px;
    padding: 0 25px 5px 25px;
    width: auto;
  }
`

Fieldset.Description.propTypes = {
  danger: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

Fieldset.Description.defaultProps = {
  danger: false,
}

Fieldset.Form = ({
  children,
  overflowable = false,
  flexWidth = 5,
  stacked,
}) => {
  const style = React.useMemo(
    () => ({
      flex: flexWidth,
      overflowX: overflowable ? 'scroll' : 'visible',
      paddingLeft: stacked ? 0 : '20px',
    }),
    [overflowable, flexWidth, stacked]
  )

  return (
    <FieldsetFormsContainer style={style}>{children}</FieldsetFormsContainer>
  )
}

const FieldsetFormsContainer = styled.div`
  @media (max-width: 1399px) {
    float: none;
    padding-left: 0;
    width: 100%;
  }
`

export class PriceControl extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      inFocus: false,
      value: props.value,
    }

    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.toggleInFocus = this.toggleInFocus.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    // Second is special case because in JS 0 == "" === true
    if (
      nextProps.value !== this.state.value ||
      (nextProps.value === 0 && this.state.value === '')
    ) {
      this.setState({
        value: nextProps.value,
      })
    }
  }

  onBlur(e) {
    e.persist()

    this.toggleInFocus(() => {
      this.props.onBlur(e)
    })
  }

  onChange(e) {
    e.persist()

    this.setState(
      {
        value: e.target.value,
      },
      () => {
        this.props.onChange(e)
      }
    )
  }

  toggleInFocus(callback) {
    this.setState({
      inFocus: !this.state.inFocus,
    })

    if (callback) {
      callback()
    }
  }

  render() {
    const {
      type,
      value: propsValue,
      currency,
      onChange,
      onFocus,
      onBlur,
      ...rest
    } = this.props

    let value = this.state.value
    if (!this.state.inFocus && isNumeric(value)) {
      value = Globalize.currencyFormatter(currency)(parseFloat(value))
    }

    return (
      <FormControl
        type="text"
        value={value}
        onChange={this.onChange}
        onFocus={() => this.toggleInFocus()}
        onBlur={this.onBlur}
        {...rest}
      />
    )
  }
}

PriceControl.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

PriceControl.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  value: 0,
}

export const validate = {
  empty: value => !value || value.length < 1,
}

const renderBooleanInput = props => {
  const {
    input: { onChange, value },
    meta,
    bsSize,
    disabled,
    falseLabel,
    trueLabel,
    trueValue,
    falseValue,
  } = props

  return (
    <div>
      <ButtonGroup bsSize={bsSize}>
        <Button
          disabled={disabled}
          bsStyle={value === falseValue || value === '' ? 'success' : ''}
          onClick={() => onChange(falseValue)}
        >
          {falseLabel}
        </Button>
        <Button
          disabled={disabled}
          bsStyle={value === trueValue ? 'success' : ''}
          onClick={() => onChange(trueValue)}
        >
          {trueLabel}
        </Button>
      </ButtonGroup>
      {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
    </div>
  )
}

export const BooleanInput = props => {
  const { ...rest } = props
  return <Field component={renderBooleanInput} {...rest} />
}

BooleanInput.propTypes = {
  bsSize: PropTypes.string,
  disabled: PropTypes.bool,
  falseLabel: PropTypes.string,
  trueLabel: PropTypes.string,
}

BooleanInput.defaultProps = {
  bsSize: 'large',
  disabled: false,
  falseLabel: 'No',
  trueLabel: 'Yes',
  falseValue: false,
  trueValue: true,
}

export const ErrorLabel = styled.span.attrs({ className: 'error ' })``

export const renderPriceInput = props => {
  const { input, meta, ...rest } = props

  return (
    <div>
      <PriceControl onChange={input.onChange} value={input.value} {...rest} />

      {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
    </div>
  )
}

export const PriceInput = props => {
  const { component, ...rest } = props
  return <Field component={renderPriceInput} {...rest} />
}
