/* @flow */

import * as React from 'react'
import styled from 'styled-components'

import TraditionalVariantRow from './TraditionalVariantRow'
import TraditionalVariantsHeaderRow from './TraditionalVariantsHeaderRow'
import { ProductTableContext } from '../../../shared'
import { flattenRows } from '../../shared'

import type { Section } from '../../../types'
import type { Product } from '../../../../../../types'

type Props = {
  generatedSections: Array<Section>,
  isFirstProductRow: boolean,
  isLastProductRow: boolean,
  noActionColumns: number,
  product: Product,
}

const TraditionalRowsRenderer = ({
  generatedSections,
  noActionColumns,
  product,
  ...restProps
}: Props) => {
  const context = React.useContext(ProductTableContext)
  const { columns, noProductHeadersMode, rows } = context

  const [flattenedRows, flattenedActions] = React.useMemo(
    () => flattenRows(rows),
    [rows]
  )

  const generatedRows = []

  // In no product headers mode we dont want the top row displayed for each product
  if (!noProductHeadersMode) {
    generatedRows.push(
      // TOP ROW: The one that displays Color, Sizes and Data Column Headers (Unit price, Total Quantity, Total Price)
      <TraditionalVariantsHeaderRow
        key={`header-row-${product.id}`}
        id={`header-row-${product.id}`}
        noActionColumns={noActionColumns}
      />
    )
  }

  // For traditional table every section is a variant
  for (let [i, section] of generatedSections.entries()) {
    generatedRows.push(
      <TraditionalVariantRow
        flattenedActions={flattenedActions}
        flattenedRows={flattenedRows}
        hasNoos={section.hasNoos}
        isLast={i === generatedSections.length - 1}
        key={`section-${i}`}
        product={product}
        section={section}
        {...restProps}
      />
    )
  }

  return <SectionTbody>{generatedRows}</SectionTbody>
}

export default (React.memo<Props>(
  TraditionalRowsRenderer
): React.AbstractComponent<Props, mixed>)

const SectionTbody = styled.tbody`
  page-break-inside: avoid;
`
