import { resolver } from 'cloudinary-url-resolver'
import { setCloudinarySettings } from '../actions/cloudinary'

export const otherPresets = {
  logo_invoice: {
    height: 65,
    // will keep original size but make sure it does not exceed 65
    crop: 'limit',
    // so it looks good also in print
    dpr: 4,
  },
  logo_linesheet_middle: {
    height: 50,
    // will keep original size but make sure it does not exceed 65
    crop: 'limit',
  },
  logo_linesheet_corner: {
    height: 18,
    // will keep original size but make sure it does not exceed 65
    crop: 'limit',
    dpr: 4,
  },
  logo_webshop: {
    height: 75,
    // will keep original size but make sure it does not exceed 75
    crop: 'limit',
    dpr: 4,
  },
  logo_webshop_mobile_navigation: {
    height: 50,
    // will keep original size but make sure it does not exceed 75
    crop: 'limit',
  },
  logo_login: {
    height: 65,
    crop: 'limit',
    dpr: 4,
  },
  logo_filebank: {
    height: 55,
    width: 130,
    // will keep original size but make sure it does not exceed height and width
    crop: 'limit',
    dpr: 4,
  },
  registration_page: {
    dpr: 4,
    height: 40,
    // will keep original size but make sure it does not exceed 75
    crop: 'limit',
  },
  user_top_bar: {
    crop: 'fill',
    height: 35,
    width: 35,
  },
  showroom_image_preview: {
    crop: 'limit',
    height: 350,
    width: 350,
  },
  user_label_popover: {
    crop: 'fill',
    height: 110,
    width: 110,
  },
  production_file_explorer_thumb: {
    crop: 'fill',
    height: 190,
    width: 170,
  },
  production_part_summary_thumb: {
    crop: 'fill',
    height: 160,
    width: 160,
  },
  production_product_add_part_thumb: {
    crop: 'fill',
    height: 175,
    width: 170,
  },
  app: {
    background: 'white',
    crop: 'pad',
    height: 80,
  },
  brand_list_grid_logo: {
    height: 70,
    width: 220,
    crop: 'fit',
  },
  profile_title: {
    crop: 'fill',
    width: 80,
    height: 100,
  },
  email_logo: {
    height: 75,
    crop: 'limit',
  },
  webshop_landing_image: {
    crop: 'fill',
    dpr: '2.0',
  },
}

export const productPresets = {
  campaign_1_per_page: {
    crop: 'fill',
    width: 720,
    height: 1015,
  },
  campaign_1_per_page_landscape: {
    crop: 'fill',
    width: 290,
    height: 390,
  },
  campaign_1_per_page_with_extra_page: {
    crop: 'fill',
    width: 720,
    height: 1015,
  },
  campaign_1_per_page_with_extra_page_landscape: {
    crop: 'fill',
    width: 290,
    height: 390,
  },
  campaign_1_per_page_with_mosaic: {
    crop: 'fill',
    width: 720,
    height: 1015,
  },
  campaign_1_per_page_with_mosaic_1_column: {
    crop: 'fill',
    width: 720,
    height: 500,
  },
  campaign_1_per_page_with_mosaic_2_column: {
    crop: 'fill',
    width: 350,
    height: 500,
  },
  campaign_1_per_page_with_mosaic_landscape: {
    crop: 'fill',
    width: 290,
    height: 390,
  },
  campaign_2_per_page: {
    crop: 'fill',
    width: 290,
    height: 390,
  },
  campaign_2_per_page_landscape: {
    crop: 'fill',
    width: 410,
    height: 510,
  },
  campaign_4_per_page: {
    crop: 'fill',
    width: 230,
    height: 310,
  },
  campaign_4_per_page_landscape: {
    crop: 'fill',
    width: 220,
    height: 300,
  },
  campaign_6_per_page: {
    crop: 'fill',
    width: 200,
    height: 290,
  },
  campaign_6_per_page_landscape: {
    crop: 'fill',
    width: 150,
    height: 190,
  },
  campaign_8_per_page: {
    crop: 'fill',
    width: 145,
    height: 200,
  },
  campaign_8_per_page_landscape: {
    crop: 'fill',
    width: 160,
    height: 200,
  },
  campaign_12_per_page: {
    crop: 'fill',
    width: 130,
    height: 190,
  },
  campaign_12_per_page_landscape: {
    crop: 'fill',
    width: 130,
    height: 190,
  },
  campaign_email_2_per_row: {
    crop: 'lfill',
    width: 350,
    height: 465,
  },
  campaign_email_3_per_row: {
    crop: 'lfill',
    width: 233,
    height: 310,
  },
  campaign_email_4_per_row: {
    crop: 'lfill',
    width: 175,
    height: 233,
  },
  ordersheet_image: {
    crop: 'fill',
    width: 100,
    height: 130,
  },
  linesheet: {
    crop: 'fill',
    height: 1140,
    width: 960,
  },
  linesheet_list: {
    crop: 'fill',
    height: 200,
    width: 140,
  },
  image_bank_slider: {
    crop: 'fill',
    height: 350,
    width: 350,
  },
  image_bank_thumb: {
    crop: 'fill',
    height: 40,
    width: 40,
  },
  product_carousel_large: {
    crop: 'fill',
    height: 485,
    width: 350,
  },
  product_carousel_thumb: {
    crop: 'fill',
    height: 67,
    width: 67,
  },
  product_edit_image_modal: {
    crop: 'fill',
    height: 150,
    width: 150,
  },
  product_grid_normal: {
    crop: 'fill',
    width: 130,
    height: 160,
  },
  product_grid_large: {
    crop: 'fill',
    width: 160,
    height: 190,
  },
  product_search: {
    crop: 'fill',
    height: 375,
    width: 300,
    quality: 'auto:best',
  },
  product_recent: {
    crop: 'fill',
    height: 340,
    width: 240,
  },
  product_search_preview_thumbnail: {
    crop: 'fill',
    height: 45,
    width: 45,
    quality: 'auto:best',
  },
  table_xsmall: {
    crop: 'fill',
    height: 20,
    width: 20,
  },
  table_small: {
    crop: 'fill',
    height: 30,
    width: 30,
  },
  table: {
    crop: 'fill',
    height: 45,
    width: 45,
  },
  table_extra: {
    crop: 'fill',
    height: 70,
    width: 70,
  },
  table_medium: {
    crop: 'fill',
    height: 100,
    width: 100,
  },
  table_large: {
    crop: 'fill',
    height: 200,
    width: 200,
  },
  product_table: {
    crop: 'fill',
  },
  webshop_product_carousel_large: {
    crop: 'fill',
    // some products like octaevo https://app.traede.com/shop/126375/show/94864 gets blurry when resized
    dpr: '2.0',
    height: 485,
    width: 350,
    quality: 'auto:best',
  },
  webshop_product_carousel_mobile: {
    crop: 'fill',
    dpr: '2.0',
    height: 955,
    width: 690,
    quality: 'auto:best',
  },
  webshop_product_carousel_thumb: {
    crop: 'fill',
    dpr: '2.0',
    height: 97,
    width: 97,
    quality: 'auto:best',
  },

  warehouse_table_list: {
    crop: 'fill',
    height: 60,
    width: 60,
  },
  warehouse_single_product: {
    crop: 'fill',
    height: 200,
  },
  warehouse_location_variants_list: {
    crop: 'fill',
    height: 45,
    width: 45,
  },
}

export default function (store) {
  const cloudName = 'traede'

  const fallbacks = {
    user_table: require('../../assets/images/user_table.png'),
    user_top_bar: require('../../assets/images/user_top_bar.png'),
    ordersheet: require('../../assets/images/ordersheet.png'),
    product_carousel: require('../../assets/images/product_carousel_thumb.png'),
    product_carousel_thumb: require('../../assets/images/product_carousel_thumb.png'),
    product_search: require('../../assets/images/product_search.png'),
    product_table: require('../../assets/images/product_table.png'),
    logo_black: require('../../assets/images/logo_black.png'),
    logo_black_v2: require('../../assets/images/logo_black_v2.svg'),
    linesheet_list: require('../../assets/images/linesheet_list.jpg'),
    webshop_product_carousel_mobile: require('../../assets/images/product_carousel_large.png'),
  }

  resolver.settings.cloud_name = cloudName

  store.dispatch(
    setCloudinarySettings(cloudName, productPresets, otherPresets, fallbacks)
  )
}
