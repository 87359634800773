/* @flow */

import moment from 'moment'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import styled from 'styled-components'
import React, { useEffect, useState, PureComponent } from 'react'
import DatePicker from '../../../../infrastructure/bindings/datepicker'
import CountrySelector from '../../../../infrastructure/components/CountrySelector'
import { SelectWrapper } from '../../../../infrastructure/components/Forms'
import { Radio, Tooltip, FormControl, OverlayTrigger } from 'react-bootstrap'
import { PriceControl } from '../../../../infrastructure/components/Formik'
import CompositeCustomFieldEditor from './CompositeCustomFieldEditor'

const isValidDate = (input, format) => moment(input, format, true).isValid()

class FieldTypeSelector extends PureComponent {
  handleChange = e => {
    this.props.onChange({ [this.props.name]: e.target.value })
  }

  handleCheckboxChange = () => {
    this.props.onChange({
      [this.props.name]: this.props.value === true ? false : true,
    })
  }

  handleRawTextInputChange = e => {
    this.props.onChange({ [this.props.name]: e.target.value })
  }

  handleTextInputChange = value => {
    this.props.onChange({ [this.props.name]: value })
  }

  handleSelectChange = newValue => {
    this.props.onChange({ [this.props.name]: newValue })
  }

  handleDatePickerChange(newValue, dateFormat) {
    if (dateFormat === 'YYYY-MM') {
      // make sure we save last day of the month when we use monthly datepicker
      newValue = isValidDate(newValue)
        ? moment(newValue).format('YYYY-MM-') +
          moment(newValue, 'YYYY-MM').daysInMonth()
        : null
    }

    this.props.onChange({ [this.props.name]: newValue })
  }

  render() {
    const {
      activeSaveMode = false,
      description,
      disabled,
      groupType,
      label,
      name,
      options,
      tooltipPlacement,
      type,
      value,
    } = this.props

    let Selector = null

    if (type === 'text') {
      Selector = (
        <TextInput
          activeSaveMode={activeSaveMode}
          disabled={disabled}
          onChange={this.handleTextInputChange}
          value={value}
        />
      )
    }

    if (type === 'textarea') {
      Selector = (
        <TextInput
          activeSaveMode={activeSaveMode}
          disabled={disabled}
          onChange={this.handleTextInputChange}
          value={value}
          isTextArea={true}
        />
      )
    }

    if (type === 'fixed_text') {
      Selector = (
        <TextInput
          activeSaveMode={activeSaveMode}
          disabled
          value={options[0]}
        />
      )
    }

    if (type === 'checkbox') {
      const id = uuid() // TODO: Better way?
      Selector = (
        <div className="checkbox check-success">
          <input
            id={id}
            type="checkbox"
            checked={value === true}
            onChange={this.handleCheckboxChange}
          />
          <label htmlFor={id}>{label || ''}</label>
        </div>
      )
    }

    if (type === 'radio') {
      Selector = (
        <div>
          {options.map(option => {
            return (
              <Radio
                disabled={disabled}
                onChange={this.handleChange}
                checked={value === option.value}
                value={option.value}
                inline
              >
                {option.value}
              </Radio>
            )
          })}
        </div>
      )
    }

    if (type === 'select' || type === 'multi_select') {
      Selector = (
        <SelectWrapper
          multi={type === 'multi_select'}
          disabled={disabled}
          value={value}
          onChange={this.handleSelectChange}
          options={options.map(option => ({
            label: option.label || option.value,
            value: option.value,
          }))}
        />
      )
    }

    if (type === 'country') {
      Selector = (
        <CountrySelector
          disabled={disabled}
          value={value}
          onChange={this.handleSelectChange}
        />
      )
    }

    if (type === 'datepicker') {
      const dateFormat = options.find(option => option.type === 'monthly')
        ? 'YYYY-MM'
        : 'YYYY-MM-DD'
      Selector = (
        <DatePicker
          date={value}
          dateFormat={dateFormat}
          disabled={disabled}
          onChange={date => this.handleDatePickerChange(date, dateFormat)}
        />
      )
    }

    if (type === 'money') {
      Selector = (
        <PriceControl
          currency={options[0]}
          disabled={disabled}
          onChange={this.handleRawTextInputChange}
          value={value}
        />
      )
    }

    if (type === 'composite') {
      Selector = (
        <CompositeCustomFieldEditor
          disabled={disabled}
          groupType={groupType}
          onChange={this.handleSelectChange}
          options={options}
          value={value}
        />
      )
    }

    if (description && description.length > 0) {
      Selector = (
        <OverlayTrigger
          placement={tooltipPlacement}
          overlay={tooltip(description)}
        >
          {Selector}
        </OverlayTrigger>
      )
    }

    return Selector
  }
}

FieldTypeSelector.defaultProps = {
  description: '',
  disabled: false,
  tooltipPlacement: 'left',
  value: '',
}

FieldTypeSelector.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  tooltipPlacement: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default FieldTypeSelector

const tooltip = message => (
  <Tooltip id={`tooltip${Date.now()}`}>{message}</Tooltip>
)

const TextInput = ({
  // For ShowOrder we do not want to save the change on every keystroke
  activeSaveMode = false,
  disabled,
  onChange,
  value: inputValue,
  isTextArea = false,
}) => {
  const [value, setValue] = useState(inputValue)

  function emitChanges() {
    return onChange(value)
  }

  function handleChange(e) {
    // we previously only used this state for activeSaveMode=true, but this caused
    // the cursor to jump to the end of the input field when editing the middle of a string
    // ref: https://github.com/facebook/react/issues/955#issuecomment-469352730
    setValue(e.target.value)

    if (!activeSaveMode) {
      onChange(e.target.value)
    }
  }

  useEffect(() => {
    setValue(inputValue)
  }, [inputValue])

  const showSaveButton = activeSaveMode === true && value != inputValue

  return (
    <TextInputContainer>
      {!isTextArea && (
        <TextInputFormControl
          disabled={disabled}
          onChange={handleChange}
          type="text"
          value={value}
        />
      )}
      {isTextArea && (
        <TextInputFormControl
          disabled={disabled}
          onChange={handleChange}
          as="textarea"
          type="textarea"
          value={value}
        />
      )}
      {showSaveButton && (
        <button
          type="button"
          className="btn btn-xs btn-success"
          onClick={emitChanges}
        >
          Save
        </button>
      )}
    </TextInputContainer>
  )
}

const TextInputContainer = styled.div`
  display: flex;
`

const TextInputFormControl = styled(FormControl)`
  flex: 1;
  margin-right: 5px;
`
