import React from 'react'
import { connect } from 'react-redux'
import getDefaultLabels from '../../app/inventory/helpers/labels'
import { fetchBrandsIfNeeded } from '../../app/brands/actions'

const defaultLabels = getDefaultLabels()

class StockLabel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      type: props.type,
    }
  }

  componentDidMount() {
    // Important to only fetch brands if needed otherwise the brand list
    // will be overridden all the time which causes chaos (for instance in NewOrder)
    this.props.dispatch(fetchBrandsIfNeeded())
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      type: nextProps.type,
    })
  }

  determineLabel(stockType) {
    const { labels = defaultLabels } = this.props

    let label = defaultLabels[stockType]
    if (labels[stockType]) {
      label = labels[stockType]
    }

    return label
  }

  render() {
    let label = this.determineLabel(this.state.type)

    return <span>{label}</span>
  }
}

StockLabel.defaultProps = {
  type: 'stock',
}

export default connect(state => {
  const entityType = state.session.entity.entity_type

  let labels
  if (entityType === 'brand') {
    labels = state.session.settings.stock_settings.stock_labels
  } else if (entityType === 'shop') {
    const brandContext = state.brandContext
    const brand = state.brands.items[brandContext]

    if (brand) {
      labels = brand.settings.stock_settings.stock_labels
    } else {
      labels = defaultLabels
    }
  }

  return {
    labels,
  }
})(StockLabel)
