/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { getCustomerPrices } from '../../../../../customers/api'
import { getOrderPrices } from '../../../../../orders/api'

import { createConfiguredVariantId } from '../../../../../shared'

type Options = {
  use_offer_prices?: boolean,
  type: 'order' | 'customer',
}

const pricing: (options: Options) => ColumnConfig = ({
  use_offer_prices = true,
  type,
}) => ({
  data_level: 'product',
  resolve: ({ context, event }) => {
    const { missing_config_variants, missing_products } = event

    const configuredVariants = missing_config_variants.map(c => {
      const configuratorValues = c.__configurator_values

      return {
        id: createConfiguredVariantId(configuratorValues),
        product_id: c.product_id,
        placeholder_variant: c.__placeholder_variant,
        configurator_values: configuratorValues,
      }
    })

    const missingProductIds = missing_products.map(p => p.id)

    const request =
      type === 'order'
        ? getOrderPrices(
            context.order_id,
            missingProductIds,
            configuredVariants
          )
        : getCustomerPrices(
            context.customer_id,
            missingProductIds,
            configuredVariants,
            use_offer_prices
          )

    return request.then(response => {
      if (response.error) {
        return []
      }

      return response.payload.prices
    })
  },
})

export default pricing
