/* @flow */

import { Map } from 'immutable'
import {
  EDITABLE_TABLE_HIDE_COLUMN,
  EDITABLE_TABLE_SHOW_COLUMN,
} from './actions'

const initialState = {
  tables: Map(),
}

type Coordinates = Map<'x' | 'y', number>

type ReducerState = {
  tables: Map<string, Coordinates>,
}

export default function reducer(
  state: ReducerState = initialState,
  action: Object
) {
  switch (action.type) {
    case EDITABLE_TABLE_SHOW_COLUMN:
      return Object.assign({}, state, {
        tables: state.tables.set(
          action.tableKey,
          Map({
            x: action.x,
            y: action.y,
          })
        ),
      })
    case EDITABLE_TABLE_HIDE_COLUMN:
      return Object.assign({}, state, {
        tables: state.tables.delete(action.tableKey),
      })
    default:
      return state
  }
}
