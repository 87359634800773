/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import type { RowConfig } from '../../types'

type Options = {
  label?: string,
}

const subbrand: (options: Options) => RowConfig = ({ label = 'Subbrand' }) => ({
  key: 'subbrand',
  label,
  render: ({ product }) => {
    return product.subbrand ? product.subbrand.name : ''
  },
  type: 'product',
})

export default subbrand
