import { CHANGE_ORDER_CONTEXT } from '../actions/index'

const initialState = null

export default function orderContext(state = initialState, action) {
  switch (action.type) {
    case CHANGE_ORDER_CONTEXT:
      return action.orderId
    default:
      return state
  }
}
