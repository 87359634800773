/* @flow */

import React from 'react'
import styled from 'styled-components'

const FixedElement = ({
  children
}) => {
  const [content, setContent] = React.useState(false)
  const [height, setHeight] = React.useState(0)

  React.useEffect(() => {
    if (!content) {
      return
    }

    setHeight(content.offsetHeight)
  }, [content, children])

  return (
    <div>
      <div style={{height}} />

      <Content ref={node => setContent(node)} >
        {children}
      </Content>
    </div>
  )
}

export default FixedElement

const Content = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 900;
  background-color: #f8f8fa;
`
