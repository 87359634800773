import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export default function Address(props) {
  if (!props.address) {
    return <div />
  }

  const {
    address: {
      address,
      address_2,
      att,
      zip,
      city,
      region,
      country,
      telephone,
      vat,
      ean,
      email,
      eori_number,
      voec_number,
    },
    center,
    entity,
    inline,
    inlineSeparator = ' / ',
    onlyAddress = false,
  } = props

  const attLine = att ? <span>(att: {att})</span> : null

  let name
  if (entity) {
    name = entity.name
  }

  let combinedAddress = address
  if (address_2 && address_2.length > 0) {
    combinedAddress += ` ${address_2}`
  }

  // TODO: Fix country label
  return (
    <address>
      <p className={center ? 'text-center' : ''}>
        {name && (
          <Line inline={inline} bold slash={false}>
            {name} {attLine}
          </Line>
        )}
        <Line
          inline={inline}
          predicate={Boolean(combinedAddress)}
          inlineSeparator={inlineSeparator}
        >
          {combinedAddress}
        </Line>
        <Line
          inline={inline}
          predicate={Boolean(zip && city)}
          inlineSeparator={inlineSeparator}
        >{`${zip} ${city} ${region || ''}`}</Line>
        <Line
          inline={inline}
          predicate={Boolean(country)}
          inlineSeparator={inlineSeparator}
        >{`${country}`}</Line>
      </p>
      {onlyAddress === false && (
        <p className={center ? 'text-center' : ''}>
          <Line
            inline={inline}
            predicate={Boolean(vat)}
            slash={false}
          >{`VAT no: ${vat}`}</Line>
          <Line
            inline={inline}
            predicate={Boolean(ean)}
            slash={false}
            inlineSeparator={inlineSeparator}
          >{`EAN no: ${ean}`}</Line>
          <Line
            inline={inline}
            predicate={Boolean(eori_number)}
            slash={false}
            inlineSeparator={inlineSeparator}
          >{`EORI no: ${eori_number}`}</Line>
          <Line
            inline={inline}
            predicate={Boolean(voec_number)}
            slash={false}
            inlineSeparator={inlineSeparator}
          >{`VOEC no: ${voec_number}`}</Line>
          <Line
            inline={inline}
            predicate={Boolean(telephone)}
            inlineSeparator={inlineSeparator}
          >
            <span className="glyphicon glyphicon-phone" />
            <span>{telephone}</span>
          </Line>
          <Line
            inline={inline}
            predicate={Boolean(email)}
            inlineSeparator={inlineSeparator}
          >
            <span>{email}</span>
          </Line>
        </p>
      )}
    </address>
  )
}

Address.propTypes = {
  address: PropTypes.object,
  entity: PropTypes.object,
  inline: PropTypes.bool,
}

Address.defaultProps = {
  inline: true,
}

const Line = styled.div`
  display: ${props =>
    props.predicate ? (props.inline ? 'inline' : 'block') : 'none'};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};

  &:before {
    content: '${props => (props.inline ? props.inlineSeparator : '')}';
  }
`

Line.propTypes = {
  bold: PropTypes.bool,
  center: PropTypes.bool,
  predicate: PropTypes.bool,
  slash: PropTypes.bool,
}

Line.defaultProps = {
  bold: false,
  center: true,
  predicate: true,
  slash: true,
}
