/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import groupBy from 'lodash/groupBy'

import type { RowConfig } from '../../types'

type Options = {
  label?: string,
}

const categories: (options: Options) => RowConfig = ({
  label = 'Category',
}) => ({
  key: 'categories',
  label,
  render: ({ product }) => {
    return <Render product={product} />
  },
  type: 'product',
})

export default categories

const Render = ({ product }) => {
  const topCategories = product.categories
    ? product.categories.filter(category => category.parent === null)
    : []

  const subCategoriesByTopCategory = groupBy(
    product.categories.filter(category => category.parent !== null),
    'parent'
  )

  const categories = []
  for (let topCategory of topCategories) {
    const subCategoriesOfTopCategory =
      subCategoriesByTopCategory[topCategory.id] || []

    if (subCategoriesOfTopCategory.length === 0) {
      categories.push(topCategory.name)
    } else {
      for (let subCategory of subCategoriesOfTopCategory) {
        categories.push(`${topCategory.name} - ${subCategory.name}`)
      }
    }
  }

  return <span>{categories.join(', ')}</span>
}
