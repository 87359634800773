/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { renderCustomField } from '../../../../../custom-fields/shared'

import type { RowConfig } from '../../types'
import type { CustomField } from '../../../../../types'

type Options = {
  customField: CustomField,
}

const custom_field: (options: Options) => RowConfig = ({ customField }) => {
  return {
    data_source:
      customField.group_type === 'product' ? '__product' : '__variant',
    key: `${customField.group_type}_${customField.internal_name}`,
    label: customField.label,
    render: ({ value }) => {
      if (!value) {
        return null
      }

      return renderCustomField(customField, value)
    },
    value: ({ product, variant }) => {
      const meta =
        customField.group_type === 'product' ? product.meta : variant.meta

      return meta[customField.internal_name]
    },
  }
}

export default custom_field
