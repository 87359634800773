/* @flow */

import React from 'react'
import styled from 'styled-components'
import isPlainObject from 'lodash/isPlainObject'
import uniq from 'lodash/uniq'

import FormatCurrency from '../../../../../infrastructure/components/FormatCurrency'
import { UserHasPermissions } from '../../../../../infrastructure/components/Authorization'

import type {
  BrandSettings,
  Customer,
  Id,
  Product,
  User,
  Variant,
} from '../../../../types'

export const VerticalAttributeSplitRow = styled.tr`
  td {
    height: 20px;
  }
`

export const flattenRows = rows => {
  const flattenedRows = []
  let flattenedActions = []
  for (let rowGroup of rows) {
    const rowsOfRowGroup =
      rowGroup.type === 'group' ? rowGroup.configs : [rowGroup]

    for (let row of rowsOfRowGroup) {
      flattenedRows.push(row)

      const actions = [...(row.actions || [])].filter(
        a => a.matrix === false || a.matrix === undefined
      )

      flattenedActions = flattenedActions.concat(actions)
    }
  }

  return [flattenedRows, flattenedActions]
}

export const columnConfigToStyle = config => {
  const style = {
    width: config.width || 100,
  }

  if (config.fluidWidth) {
    style.width = 'auto'
  }

  if (config.right) {
    style['textAlign'] = 'right'
  }

  return style
}

export const rowsToValue = (rows, columnOrRow) => {
  if (columnOrRow.aggregate) {
    return rows.reduce((carry, row) => row[columnOrRow.aggregate], 0)
  } else {
    return rows[0][columnOrRow.property || columnOrRow.key]
  }
}

export const renderValue = ({
  brand,
  config,
  data,
  value,
  valueColli,
  userEntity,
}) => {
  switch (config.render) {
    case 'money':
      return (
        <FormatCurrency currency={data.context.currency}>
          {value[0]}
        </FormatCurrency>
      )
    default:
      if (typeof config.render === 'function') {
        return config.render({
          ...data,
          brand,
          config,
          value,
          valueColli,
          userEntity,
        })
      }

      if (config.enable_colli && valueColli > 1) {
        const showValue = config.colli_not_included_in_value
          ? value
          : value / valueColli

        return `${showValue} × ${valueColli} pcs`
      }

      return value
  }
}

type FieldId = {
  table_section_key: string,
  product_id: Id,
  variant_id: Id,
  subsection_key: string,
  row_key: string,
  column_key: string,
}

export const createFieldId = (
  tableSectionKey: string,
  productId: Id,
  variantId: Id,
  subSectionKey: string,
  rowKey: string,
  columnKey: string
): FieldId => ({
  table_section_key: tableSectionKey,
  product_id: productId,
  variant_id: variantId,
  subsection_key: subSectionKey,
  row_key: rowKey,
  column_key: columnKey,
})
export const fieldIdToString = (fieldId: FieldId) =>
  `${fieldId.table_section_key}-${fieldId.product_id}-${fieldId.variant_id}-${fieldId.subsection_key}-${fieldId.row_key}-${fieldId.column_key}`

export const createDefaultLineData = (
  tableSectionData,
  defaultRowData,
  subSection,
  columns,
  row
) => {
  const columnValues = columns
    .filter(c => c.split_by_values)
    .reduce((carry, column) => {
      let columnValue = row.columnValues[column.key]
        ? row.columnValues[column.key][0]
        : [null]

      // Sometimes the value of a column does not match the line. E.g.
      // a PO line is { unit_price: null } but a normal price is { net_price: null }
      if (column.value_to_new_line) {
        columnValue = column.value_to_new_line(columnValue)
      }

      if (isPlainObject(columnValue)) {
        const updated = {
          ...carry,
        }

        for (let [columnKey, columnValue] of Object.entries(columnValue)) {
          if (columnKey !== 'meta') {
            updated[columnKey] = columnValue
            continue
          }

          // special case: if a column is a meta field, then we need to merge it
          // with other custom fields. e.g. if we are showing a product table
          // with 2 order line custom field columns, then we need both custom fields
          // to be combined in the same `meta` key
          if (!updated.meta) {
            updated.meta = {}
          }

          updated.meta = {
            ...updated.meta,
            ...columnValue,
          }
        }

        return updated
      }

      carry[column.key] = columnValue

      return carry
    }, {})

  return {
    ...tableSectionData,
    ...defaultRowData,
    ...(subSection.splitKey === 'traede_no_split'
      ? {}
      : subSection.subSectionSplitData),
    ...columnValues,
  }
}

export const resolveColliProps = ({
  brandSettings,
  customer,
  product,
  variant,
  user,
}: {
  brandSettings: BrandSettings,
  customer: ?Customer,
  product: Product,
  variant: Variant,
  user: ?User,
}): {
  availableColli: number[],
  defaultColli: number | null,
} => {
  let availableColli = [
    ...((variant && variant.colli !== null ? variant.colli : product.colli) ||
      []),
  ]

  const canOverrideColliOnly =
    brandSettings.colli_only_check_permission === true &&
    user &&
    UserHasPermissions(user, 'orders:colli_only_override')

  const colliOnlyCustomer = customer ? customer.order_colli_only : false
  const colliOnly = colliOnlyCustomer || product.order_colli_only

  if (!colliOnly || canOverrideColliOnly) {
    availableColli.push(1)
  }

  availableColli = uniq(availableColli)

  // Numerical sort
  availableColli.sort((a, b) => a - b)

  let defaultColli = availableColli.length > 0 ? availableColli[0] : null

  if (brandSettings.colli_prefer_colli === true) {
    const colliAboveOne = availableColli.filter(c => c > 1)

    if (colliAboveOne.length > 0) {
      defaultColli = colliAboveOne[0]
    }
  }

  return {
    availableColli,
    defaultColli,
  }
}
