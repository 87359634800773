// @flow

import * as React from 'react'
import JsBarcode from 'jsbarcode'

const EanBarcode = ({
  format = 'ean13',
  ean,
}: {
  format: 'ean13' | 'CODE128B',
  ean: ?string,
}) => {
  const showBarcode = React.useCallback(
    element => {
      if (element !== null && ean) {
        JsBarcode(element, ean, {
          format: format,

          // See https://github.com/lindell/JsBarcode/wiki/Options
          height: 25,
          margin: 3,
          width: 1,
          fontSize: 12,
        })
      }
    },
    [format, ean]
  )

  return (
    <BarcodeErrorBoundary>
      {ean ? <img ref={showBarcode} style={{ display: 'block' }} /> : null}
    </BarcodeErrorBoundary>
  )
}

export default EanBarcode

class BarcodeErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}
