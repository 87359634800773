/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import moment from 'moment-timezone'

import InputField from '../../Rows/InputField'

import type { ColumnConfig } from '../../types'
import {
  addPrefixIfNoneExists,
  renderDate,
  Tooltip,
} from '../../../../../shared'

type Options = {
  editable?: boolean,
}

const allocation_status: (options: Options) => ColumnConfig = ({
  editable = true,
}) => ({
  // we need to add data source here otherwise batch is not going to work
  data_source: 'inventory_level',
  key: 'allocation_status',
  label: 'Allocation',
  render: ({ brand, data, lines, onChange, value, variant, ...rest }) => {
    return (
      <MemoizedAllocationStatus
        brand={brand}
        data={data}
        editable={editable}
        lines={lines}
        onChange={onChange}
        value={value}
        variant={variant}
      />
    )
  },
})

export default allocation_status

const AllocationStatus = ({ brand, data, lines, onChange, variant, value, editable }) => {
  const [edit, setEdit] = React.useState(false)

  const line = lines[0]

  const onChangeHandler = React.useCallback(
    e => {
      onChange({
        should_reserve: e.target.value,
      })
    },
    [onChange]
  )

  const onControlKey = React.useCallback(
    eventKey => {
      switch (eventKey) {
        case 'Enter':
        case 'Escape':
        case 'Tab':
          setEdit(false)
          break
      }
    },
    [setEdit]
  )

  const inventoryLocation = React.useMemo(() => {
    if (!line || !line.inventory_location_id) {
      return brand.inventory_locations.find(i => i.default)
    }

    return brand.inventory_locations.find(
      l => l.id == line.inventory_location_id
    )
  }, [brand.inventory_locations, line])

  const shouldReserve = line ? line.should_reserve : 0
  const currentlyAllocated = line ? line.allocated_from_inventory : 0

  let available = null
  if (data && data.inventory_level && data.inventory_level[variant.id]) {
    available = data.inventory_level[variant.id].reduce(
      (carry, level) => (carry += level.quantity),
      0
    )
  }

  return (
    <Container>
      {edit === false && (
        <Tooltip
          tip={`${currentlyAllocated} reserved. Should reserve ${shouldReserve}`}
          id="product-table-allocation-status"
        >
          <span>
            <CurrentlyAllocatedLabel>
              {currentlyAllocated}
            </CurrentlyAllocatedLabel>{' '}
            / {shouldReserve}
            {editable && (
              <EditButton onClick={() => setEdit(true)}>
                <span className="glyphicon glyphicon-pencil" />
              </EditButton>
            )}
          </span>
        </Tooltip>
      )}
      {edit === true && (
        <FormContainer>
          <ControlLabel>Should reserve</ControlLabel>
          <InputField
            onBlur={() => setEdit(false)}
            onChange={onChangeHandler}
            onControlKey={onControlKey}
            value={shouldReserve}
          />
        </FormContainer>
      )}
      {available !== null && (
        <AvailableLabel color={inventoryLocation.color}>
          {available} available
        </AvailableLabel>
      )}
    </Container>
  )
}

const MemoizedAllocationStatus = React.memo(AllocationStatus)

const Container = styled.div``

const CurrentlyAllocatedLabel = styled.span`
  font-weight: bold;
`

const AvailableLabel = styled.div`
  color: ${({ color }) => color};
  font-size: 10px;
`

const EditButton = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: none;
  font-size: 10px;
  outline: 0;
`

const FormContainer = styled.div``

const ControlLabel = styled.label`
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 0;
`
