import React, { useMemo } from 'react'
import styled from 'styled-components'

const InventoryIndicator = ({ brand, rule }) => {
  if (!rule) {
    return null
  }

  if (rule.label) {
    return (
      <div
        style={
          rule.color
            ? {
                color: rule.color,
              }
            : {}
        }
      >
        {rule.label}
      </div>
    )
  }

  if (!rule.color) {
    return null
  }

  return (
    <Indicator
      style={{
        backgroundColor: rule.color,
      }}
    />
  )
}

const Indicator = styled.div`
  margin-left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
`

export default InventoryIndicator
