/* @flow */

import React, { useMemo } from 'react'

import { useCachedCountries } from '../localization'

const CountrySelector = ({ country, ...props }) => {
  const [countries, { isFetching }] = useCachedCountries()

  if (isFetching) {
    return null
  }

  const label = countries[country]

  return label ? label : country
}

export default CountrySelector
