/* @flow */

import api from '../api'

export const getWarehouseLocationScreenData = (
  axiosOptions,
  warehouseLocationId,
  inventoryLocationId
) =>
  api.get(`/warehouse/warehouse-location-screen-data`, {
    query: {
      inventory_location_id: inventoryLocationId,
      warehouse_location_id: warehouseLocationId,
    },
    ...axiosOptions,
  })

export const getWarehouseVariantScreenData = (
  axiosOptions,
  variantId,
  inventoryLocationId
) =>
  api.get(`/warehouse/variant-screen-data`, {
    query: {
      variant_id: variantId,
      inventory_location_id: inventoryLocationId,
    },
    ...axiosOptions,
  })

export const warehouseInventoryScan = (axiosOptions, barcode) =>
  api.post(`/warehouse/inventory-scan`, {
    body: {
      barcode,
    },
    ...axiosOptions,
  })
