/* @flow */

import React, { memo } from 'react'
import styled from 'styled-components'

import { actionFilterer } from './shared'

const TableHeader = ({
  brand,
  callbackVars,
  contextData,
  customFields,
  session,
  tableActions,
  tableKey,
  tableOptions,
  title,
}) => {
  if (!title && tableActions.length === 0) {
    return null
  }

  let tableHeader = null
  const filteredTableActions = actionFilterer(
    tableActions,
    contextData,
    session
  )

  return (
    <TableHeaderContainer>
      {title && <TableHeaderTitle>{title}</TableHeaderTitle>}
      {filteredTableActions.length > 0 &&
        filteredTableActions.map((Action, i) => (
          <TableHeaderAction
            key={`${tableKey}-table-actions-${i}`}
            className="print-hide"
          >
            <Action.Component
              brand={brand}
              callbackVars={callbackVars}
              customFields={customFields}
              context={contextData}
              session={session}
              tableOptions={tableOptions}
              tableKey={tableKey}
            />
          </TableHeaderAction>
        ))}
    </TableHeaderContainer>
  )
}

export default memo(TableHeader)

const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  padding: 10px 0;
`

const TableHeaderTitle = styled.div`
  flex: 1;
  font-size: 22px;
  line-height: 22px;
`

const TableHeaderAction = styled.div`
  margin-left: 10px;
`
