exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._11GVmT7DDIKCEhSAVUHIrG {\n\tflex: 1 1 auto;\n}\n\n.Jr0FUULkaBx_OluT2JVZ6 {\n\t/* min height is needed, otherwise AutoSizer cannot calculate height in new order */\n\tmin-height: 5px;\n\twidth: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"WindowScrollerWrapper": "_11GVmT7DDIKCEhSAVUHIrG",
	"BodyGrid": "Jr0FUULkaBx_OluT2JVZ6"
};