// @flow

import * as React from 'react'
import styled from 'styled-components'

import {
  ProductTableContext,
  TableRow,
  TableColumn,
  createEmptyArray,
} from '../../../shared'
import { columnConfigToStyle, flattenRows } from '../../shared'

type Props = {
  id: string,
  noActionColumns: number,
}

const TraditionalVariantsHeaderRow = ({ id, noActionColumns }: Props) => {
  const context = React.useContext(ProductTableContext)
  const { columns, productImage, variantImages, rows } = context

  const [flattenedRows] = React.useMemo(() => flattenRows(rows), [rows])

  return (
    <VariantsHeaderRow key={id} data-testid={id}>
      {(productImage === true || variantImages === true) && <TableColumn />}
      <TableColumn>Product</TableColumn>
      {flattenedRows.map(row => {
        return (
          <TableColumn key={row.key} style={columnConfigToStyle(row)}>
            {row.label}
          </TableColumn>
        )
      })}
      {columns.map(column => {
        return (
          <TableColumn key={column.key} style={columnConfigToStyle(column)}>
            {column.label}
          </TableColumn>
        )
      })}
      {createEmptyArray(noActionColumns).map((v, i) => (
        <TableColumn key={`actions-${i}`} />
      ))}
    </VariantsHeaderRow>
  )
}

export default TraditionalVariantsHeaderRow

// background important is needed to override chrome print styles
const VariantsHeaderRow = styled(TableRow)`
  td {
    background: #e2e2e2 !important;
    -webkit-print-color-adjust: exact;
    color: black;
    font-size: 11px;
    font-weight: bold;
  }
`
