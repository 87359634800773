/* @flow */

import { createFilterGroupsFromDataTableFilters } from '../../../datatables_v2/shared'

import { getProductsListV2 } from '../../api'
import { getProducts } from '../../../shop/api'
import { getProducts as getProductsV2 } from '../../../shopV2/api'

export const ROW_OVERSCAN = 5

export const fetchProducts = (
  brandId,
  mode,
  filters,
  limit,
  sort,
  start,
  extraData = {},
  query,
  shopV2,
  useCustomProductSort = false
) => {
  switch (mode) {
    case 'list':
      const fetchArgs = {
        filter_groups: createFilterGroupsFromDataTableFilters(filters),
        limit,
        start,
        query,
        ...extraData,
      }

      if (sort) {
        fetchArgs.sort = sort
      }

      return getProductsListV2(fetchArgs)
    case 'shop':
      if (sort) {
        extraData.sort = sort[0].key
        extraData.direction = sort[0].direction
      }

      // when doing free text search we want to ignore filters
      if (query) {
        filters = {
          query,
        }
      }

      return getProducts(brandId, {
        filters,
        limit,
        start,
        webshop_v2: shopV2 === true ? 1 : 0,
        ...extraData,
      })

    case 'shop_v2':
      if (sort) {
        extraData.sort = sort[0].key
        extraData.direction = sort[0].direction
      }

      if (useCustomProductSort) {
        extraData.use_custom_product_sort = 1
      }

      // when doing free text search we want to ignore filters
      if (query) {
        filters = {
          query,
        }
      }

      return getProductsV2(brandId, {
        filters,
        limit,
        start,
        webshop_v2: true,
        ...extraData,
      })
  }
}
