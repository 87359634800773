import React from 'react'

import WebshopHeader from './WebshopHeader'
import WebshopMobileHeader from './WebshopMobileHeader'

import { WebshopContext } from '../../shared'

const WebshopHeaderWrapper = props => {
  const { isMobile } = React.useContext(WebshopContext)

  const Component = isMobile ? WebshopMobileHeader : WebshopHeader

  return <Component {...props} />
}

export default WebshopHeaderWrapper
