/* @flow */

import React from 'react'
import styled from 'styled-components'

import { ProductTableContext, createEmptyArray } from './shared'
import { flattenRows } from './Rows/shared'

type Props = {
  label: string,
}

const TableSectionHeader = ({
  label,
  maxHorizontalColumns,
  noActionColumns,
  tableSection,
  tableSectionsConfig,
}: Props) => {
  const { actions, columns, matrix, productImage, variantImages, rows } =
    React.useContext(ProductTableContext)

  const flattenedRows = React.useMemo(
    () => (matrix ? [] : flattenRows(rows)[0]),
    [matrix, rows]
  )

  let colSpan = columns.length + noActionColumns
  if (productImage || variantImages) {
    colSpan += 1
  }
  if (matrix) {
    colSpan += maxHorizontalColumns + 2
  } else {
    colSpan += flattenedRows.length + 1
  }

  return (
    <tbody>
      <Row>
        <td colSpan={colSpan}>
          <Label>{label}</Label>
        </td>
      </Row>
      {tableSectionsConfig.sub_header && (
        <SubHeaderRow>
          <td colSpan={colSpan}>
            {tableSectionsConfig.sub_header({ tableSection })}
          </td>
        </SubHeaderRow>
      )}
      <SpacingRow>
        <td colSpan={colSpan}>&nbsp;</td>
      </SpacingRow>
    </tbody>
  )
}

export default TableSectionHeader

// background important is needed to override chrome print styles
const Row = styled.tr`
  td {
    background: black !important;
    -webkit-print-color-adjust: exact;
    padding: 5px 10px;
  }
`

const SubHeaderRow = styled.tr`
  td {
    background: #white !important;
    -webkit-print-color-adjust: exact;
    font-size: 11px;
    padding: 5px 10px;
  }
`

const SpacingRow = styled.tr`
  td {
    font-size: 10px;
    height: 10px;
    padding: 0;
  }
`

const Label = styled.div`
  -webkit-print-color-adjust: exact;
  color: white !important;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;

  a {
    -webkit-print-color-adjust: exact;
    color: white !important;
    text-decoration: underline;
  }

  @media print {
    * {
      -webkit-print-color-adjust: exact;
      color: white !important;
    }

    a {
      text-decoration: none;
    }
  }
`
