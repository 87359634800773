/* @flow */

import * as React from 'react'
import sortBy from 'lodash/sortBy'
import styled from 'styled-components'
import {
  DropdownButton as BootstrapDropdownButton,
  MenuItem,
} from 'react-bootstrap'

import { updateUser } from '../../../../../entities/api'
import { isCustomFieldVisible } from '../../../../../custom-fields/shared'

import { ProductTableContext } from '../../shared'
import {
  refreshSession,
  SessionContext,
  useRefValue,
  mixpanelTrack,
} from '../../../../../shared'

const createSummaryTableAction = ({
  key = 'summary_table_toggle',
  mixpanelEvent,
}) => {
  return {
    key,
    render: ({
      brand,
      summaryProductTableActions,
      summaryProductTableState,
    }) => {
      return (
        <SummaryToggleComponent
          brand={brand}
          mixpanelEvent={mixpanelEvent}
          summaryProductTableActions={summaryProductTableActions}
          summaryProductTableState={summaryProductTableState}
        />
      )
    },
  }
}

export default createSummaryTableAction

const SummaryToggleComponent = ({
  brand,
  mixpanelEvent,
  summaryProductTableActions,
  summaryProductTableState,
  tableId,
}) => {
  const isActive =
    summaryProductTableState && summaryProductTableState.show === true

  const onToggle = React.useCallback(() => {
    const newStatus = !isActive

    summaryProductTableActions.toggle_table()

    if (mixpanelEvent) {
      mixpanelTrack(mixpanelEvent, {
        tableId,
        show: newStatus,
      })
    }
  }, [isActive, mixpanelEvent, summaryProductTableActions])

  return (
    <Container>
      {summaryProductTableState.product_id_filter && (
        <button
          type="button"
          className="btn btn-sm btn-white"
          onClick={() => summaryProductTableActions.back_to_summary()}
        >
          Back to summary
        </button>
      )}

      {!summaryProductTableState.show && (
        <button
          type="button"
          className="btn btn-sm btn-white"
          onClick={onToggle}
        >
          Show summary
        </button>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  margin-left: 10px;
`
