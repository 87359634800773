/* @flow */

import { useEffect, useState } from 'react'

import { createApiHook, quickCreateHook } from '../../hooks'
import type { DataTableAttribute, Id, Order } from '../../types'
import {
  getOrder,
  getOrdersAttributes,
  getOrderOverviewForDocument,
  getOrderLinesWithDeliveryStatus,
  getOrderEdiActions,
  getOrderUiView,
  getPrepayment,
  createOrderOverview,
  generateOrderAccountingAnalysis,
  calculateOrderIncomingAvailableDistribution,
} from '../api'

export const useOrdersAttributes = () => {
  const [state, setState] = useState<{
    attributes: Array<DataTableAttribute>,
    isFetching: boolean,
  }>({
    attributes: [],
    isFetching: false,
  })

  useEffect(() => {
    setState(s => ({
      ...s,
      isFetching: true,
    }))

    getOrdersAttributes().then(response => {
      if (!response.error) {
        setState({
          isFetching: false,
          attributes: response.payload.attributes,
        })
      } else {
        setState(s => ({
          ...s,
          isFetching: false,
        }))
      }
    })
  }, [])

  return [state.attributes, state.isFetching]
}

const fetchOrderForHook = (orderId: Id, options?: Object) => {
  return getOrder(orderId, options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload,
      }
    } else {
      return response
    }
  })
}

export const useOrder = createApiHook<Order | null>(fetchOrderForHook)

const { hook: useIncomingAvailableDistributionCalculation } = quickCreateHook(
  calculateOrderIncomingAvailableDistribution,
  'distribution',
  null
)

export { useIncomingAvailableDistributionCalculation }

const { hook: useOrderLinesWithDeliveryStatus } = quickCreateHook(
  getOrderLinesWithDeliveryStatus,
  'lines',
  []
)

export { useOrderLinesWithDeliveryStatus }

const { hook: useOrderEdiActions } = quickCreateHook(
  getOrderEdiActions,
  'actions',
  null
)

export { useOrderEdiActions }

const { hook: useCreateOrderOverview } = quickCreateHook(
  createOrderOverview,
  null,
  {
    agents: [],
    order_overview: null,
    orders: [],
  },
  {
    v2: true,
  }
)

export { useCreateOrderOverview }

const { hook: useOrderUiView } = quickCreateHook(getOrderUiView, null, {
  risk: false,
  manual_check: false,
  edi_actions: {},
})

export { useOrderUiView }

const { hook: useOrderAccountingAnalysis } = quickCreateHook(
  generateOrderAccountingAnalysis,
  'analysis',
  null,
  {
    v2: true,
  }
)

export { useOrderAccountingAnalysis }

const { hook: useOrderOverviewForDocument } = quickCreateHook(
  getOrderOverviewForDocument,
  null,
  {
    document: null,
    order_overview: null,
  },
  { v2: true }
)

export { useOrderOverviewForDocument }
