/* @flow */

import React from 'react'
import { Link } from 'react-router-dom'

import FormatCurrency from '../../../infrastructure/components/FormatCurrency'

import { PercentageProgressBar } from '../../reports/components/SalesGoals/SalesGoalsTable'

const CustomerBudgetStatus = ({ customer, salesGoals }) => {
  return (
    <div>
      {salesGoals.length === 0 && (
        <div className="alert alert-info">
          Create some sales goals for {customer.name} and they will appear here.{' '}
          <Link className="btn btn-xs btn-white" to="/reports/sales-goals">
            Go to sales goals
          </Link>
        </div>
      )}
      {salesGoals.length > 0 && (
        <table className="table table-condensed">
          <tbody>
            {salesGoals.map(goal => {
              return (
                <tr>
                  <td width="130">{goal.dashboard}</td>
                  <td width="200">
                    <PercentageProgressBar
                      goal={goal.goal}
                      totalPrice={goal.reached}
                    />
                  </td>
                  <td>
                    <FormatCurrency currency={goal.currency}>
                      {goal.reached}
                    </FormatCurrency>
                    {' / '}
                    <br />
                    <FormatCurrency currency={goal.currency}>
                      {goal.goal}
                    </FormatCurrency>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default CustomerBudgetStatus
