/* @flow */

import React, { Component } from 'react'
import styled from 'styled-components'

import { FormInput, RadioInput, SelectInput } from '../../Formik'
import { FieldGroup } from './shared'

import type { FilterOperator } from '../../../../app/types'

type Props = {
  availableValues: Array<{ label: string, value: string }>,
  operator: FilterOperator,
  value: Array<string>,
}

export default class ArrayFilter extends Component<Props, void> {
  render() {
    const { availableValues, operator, value } = this.props

    return (
      <FieldGroup>
        <div className="react-select--small">
          <SelectInput
            options={[{ label: 'No value', value: '__traede_null' }].concat(
              availableValues.map(value => ({ label: value, value: value }))
            )}
            multi
            name="value"
            simpleValue
          />
        </div>
      </FieldGroup>
    )
  }
}
