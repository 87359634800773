/* @flow */

import React, { PureComponent } from 'react'
import styled from 'styled-components'
import onClickOutside from 'react-onclickoutside'
import { Formik, type FormikProps } from 'formik'

import BaseFilterForm from '../../infrastructure/components/FilterForm'
import {
  CheckboxInput,
  FormGroup,
} from '../../infrastructure/components/Formik'

import type { DataTableAttribute, Filter } from '../types'

type Props = {
  attribute: DataTableAttribute,
  filter: Filter,
  onHide: Function,
  onSubmit: (
    values: $Shape<Filter>,
    filter: Filter,
    attribute: DataTableAttribute
  ) => void,
  style: Object,
}

class FilterForm extends PureComponent<Props, void> {
  handleClickOutside = event => {
    this.props.onHide()
  }

  render() {
    const { attribute, filter } = this.props

    return (
      <Container>
        {attribute && (
          <Formik
            initialValues={filter}
            onSubmit={this._onSubmit}
            render={this._renderForm}
          />
        )}
      </Container>
    )
  }

  _renderForm = ({ errors, handleSubmit, values }) => {
    const { attribute, dataCache, dataCacheActions } = this.props

    // This form might be nested within another form. To prevent this submission
    // to bubble up and submit the wrapping form we stop propagation here.
    // Rolecenter is an example of such form
    const wrappedSubmit = e => {
      e.stopPropagation()
      handleSubmit(e)
    }

    return (
      <form onSubmit={wrappedSubmit} style={{ width: '100%' }}>
        <FormContainer>
          <div>
            <BaseFilterForm
              attribute={attribute}
              dataCache={dataCache}
              dataCacheActions={dataCacheActions}
              values={values}
            />
          </div>
          <CheckboxInput id="filter-form-not" name="not" label="Not" />
        </FormContainer>
        <SubmitButton type="submit">Save</SubmitButton>
      </form>
    )
  }

  _onSubmit = (values: $Shape<Filter>, ...args) => {
    this.props.onSubmit(values, this.props.filter, this.props.attribute)
  }
}

const FilterFormWithOutsideClick = onClickOutside(FilterForm)

export default FilterFormWithOutsideClick

const Container = styled.div`
  background: #fff;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  height: auto;
  min-width: 300px;
  position: absolute;
  z-index: 10000;
`

const FormContainer = styled.div`
  padding: 10px 10px 0 10px;
`

const SubmitButton = styled.button`
  background: transparent;
  border: none;
  border-top: 1px solid #e8e8e8;
  outline: none;
  color: #2b7fad;
  padding: 7px 0;
  width: 100%;

  &:hover {
    color: #07537d;
    font-weight: bold;
  }
`
