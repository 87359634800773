/* @flow */

import {
  calculateBulkShippingOptions,
  calculateShipmentAvailability,
  calculateShipmentSettings,
  calculateSmartShipExpedition,
  getColliTypes,
  getDeliveryDateUpdates,
  getShipment,
  getShipmentForScanner,
  getShipmentUiView,
  getShipmentsList,
  getShipmentsAttributes,
  getShipmentDeliveryNote,
  getShippingPlan,
  getShippingPlans,
  getShippingMethods,
  getDeliveredWithoutInvoice,
  getPackingRoutes,
  getPackingRoute,
  getPackingRouteForScanner,
  getShippingCodeConversions,
  getWarehouseAdjustmentCodes,
  getWarehouseEvents,
  getWarehouseLocation,
  getWarehouseLocations,
} from './api'

import { createApiHook, quickCreateHook } from '../hooks'

import type {
  BrunoRequestOptions,
  DataTableAttribute,
  ShippingMethod,
  Shipment,
} from '../types'

const fetchShipmentAttributesForHook = (options?: Object) =>
  getShipmentsAttributes(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.attributes,
      }
    } else {
      return response
    }
  })

export const useShipmentAttributes = createApiHook<DataTableAttribute | null>(
  fetchShipmentAttributesForHook,
  []
)

const fetchShippingMethodsForHook = (options?: BrunoRequestOptions) =>
  getShippingMethods(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.shipping_methods,
      }
    } else {
      return response
    }
  })

export const useShippingMethods = createApiHook<Array<ShippingMethod>>(
  fetchShippingMethodsForHook,
  []
)

const {
  hook: useShipmentsList,
  hookCached: useCachedShipmentsList,
  clearCache: clearShipmentsListCache,
} = quickCreateHook(getShipmentsList, 'shipments', [])
export { useShipmentsList, useCachedShipmentsList, clearShipmentsListCache }

const {
  hook: useShipment,
  hookCached: useCachedShipment,
  clearCache: clearShipmentCache,
} = quickCreateHook<Shipment>(getShipment, 'shipment', null)

export { useShipment, useCachedShipment, clearShipmentCache }

const {
  hook: useDeliveryDateUpdates,
  hookCached: useCachedDeliveryDateUpdates,
  clearCache: clearDeliveryDateUpdatesCache,
} = quickCreateHook(getDeliveryDateUpdates, 'updates', [])

export {
  useDeliveryDateUpdates,
  useCachedDeliveryDateUpdates,
  clearDeliveryDateUpdatesCache,
}

const {
  hook: useShipmentDeliveryNote,
  hookCached: useCachedShipmentDeliveryNote,
  clearCache: clearShipmentDeliveryNoteCache,
} = quickCreateHook(
  getShipmentDeliveryNote,
  'order_shipment_delivery_note',
  null
)

export {
  useShipmentDeliveryNote,
  useCachedShipmentDeliveryNote,
  clearShipmentDeliveryNoteCache,
}

const {
  hook: useSmartShipExpeditionCalculation,
  hookCached: useCachedSmartShipExpeditionCalculation,
  clearCache: clearSmartShipExpeditionCalculation,
} = quickCreateHook(calculateSmartShipExpedition, 'expedition', [])

export {
  useSmartShipExpeditionCalculation,
  useCachedSmartShipExpeditionCalculation,
  clearSmartShipExpeditionCalculation,
}

const {
  hook: useColliTypes,
  hookCached: useCachedColliTypes,
  clearCache: clearColliTypes,
} = quickCreateHook(getColliTypes, 'colli_types', [])

export { useColliTypes, useCachedColliTypes, clearColliTypes }

const { hook: useDeliveredWithoutInvoice } = quickCreateHook(
  getDeliveredWithoutInvoice,
  'deliveries',
  []
)

export { useDeliveredWithoutInvoice }

const { hook: usePackingRoutes } = quickCreateHook(
  getPackingRoutes,
  'packing_routes',
  []
)

export { usePackingRoutes }

const { hook: usePackingRoute } = quickCreateHook(
  getPackingRoute,
  'packing_route',
  null
)

export { usePackingRoute }

const { hook: useShipmentSettings } = quickCreateHook(
  calculateShipmentSettings,
  null,
  {
    inventory_location_id: null,
    shipping_method_id: null,
  }
)

export { useShipmentSettings }

const { hook: useShipmentAvailability } = quickCreateHook(
  calculateShipmentAvailability,
  'availability',
  []
)

export { useShipmentAvailability }

// Warehousing

const { hook: useShipmentForScanner } = quickCreateHook(
  getShipmentForScanner,
  'shipment',
  null
)

export { useShipmentForScanner }

const { hook: useWarehouseEvents } = quickCreateHook(
  getWarehouseEvents,
  'events',
  []
)

export { useWarehouseEvents }

const { hook: usePackingRouteForScanner } = quickCreateHook(
  getPackingRouteForScanner,
  'packing_route',
  null
)

export { usePackingRouteForScanner }

const { hook: useShipmentUiView } = quickCreateHook(getShipmentUiView, null, {
  order_channel_actions: {},
})

export { useShipmentUiView }

const { hook: useShippingPlan } = quickCreateHook(getShippingPlan, 'plan', null)

export { useShippingPlan }

const { hook: useShippingPlans } = quickCreateHook(
  getShippingPlans,
  'plans',
  []
)

export { useShippingPlans }

const {
  hook: useWarehouseAdjustmentCodes,
  hookCached: useCachedWarehouseAdjustmentCodes,
  clearCache: clearWarehouseAdjustmentCodesCache,
} = quickCreateHook(getWarehouseAdjustmentCodes, 'codes', [], {
  v2: true,
})

export {
  useWarehouseAdjustmentCodes,
  useCachedWarehouseAdjustmentCodes,
  clearWarehouseAdjustmentCodesCache,
}

const {
  hook: useWarehouseLocation,
  hookCached: useCachedWarehouseLocation,
  clearCache: clearWarehouseLocationCache,
} = quickCreateHook(getWarehouseLocation, 'location', null, {
  v2: true,
})

export {
  useWarehouseLocation,
  useCachedWarehouseLocation,
  clearWarehouseLocationCache,
}

const {
  hook: useWarehouseLocations,
  hookCached: useCachedWarehouseLocations,
  clearCache: clearWarehouseLocationsCache,
} = quickCreateHook(getWarehouseLocations, 'locations', [], {
  v2: true,
})

export {
  useWarehouseLocations,
  useCachedWarehouseLocations,
  clearWarehouseLocationsCache,
}

const {
  hook: useShippingCodeConversions,
  hookCached: useCachedShippingCodeConversions,
  clearCache: clearShippingCodeConversionsCache,
} = quickCreateHook(getShippingCodeConversions, 'conversions', [], {
  v2: true,
})

export {
  useShippingCodeConversions,
  useCachedShippingCodeConversions,
  clearShippingCodeConversionsCache,
}

const {
  hook: useCalculateBulkShippingOptions,
  hookCached: useCachedCalculateBulkShippingOptions,
  clearCache: clearCalculateBulkShippingOptionsCache,
} = quickCreateHook(calculateBulkShippingOptions, 'options', [], {
  v2: true,
})

export {
  useCalculateBulkShippingOptions,
  useCachedCalculateBulkShippingOptions,
  clearCalculateBulkShippingOptionsCache,
}
