/* @flow */

import React from 'react'
import styled from 'styled-components'
import sortBy from 'lodash/sortBy'
import { Field, type FieldProps } from 'formik'

import { useCachedProductLocales } from '../../../app/settings/hooks'

import SelectInput from './SelectInput'
import { ErrorLabel } from '../Forms'

type Props = {
  id: string,
}

const LocalePrioritization = (props: Props) => {
  return <Field component={renderLocalePrioritization} {...props} />
}

export default LocalePrioritization

const renderLocalePrioritization = ({
  field: { name, value },
  form: { setFieldValue, touched, errors },
  id,
  ...rest
}) => {
  const [productLocales, isFetching] = useCachedProductLocales()

  const options = React.useMemo(() => {
    let options = sortBy(
      productLocales.map(l => ({
        label: l.title,
        value: l.id,
      })),
      'title'
    )

    return options
  }, [productLocales])

  const valueWithoutDefault = React.useMemo(() => {
    return value.filter(v => v !== null)
  }, [value])

  const toggleDefault = React.useCallback(() => {
    const valueCopy = [...value]

    const index = valueCopy.findIndex(v => v === null)
    if (index === -1) {
      valueCopy.push(null)
    } else {
      valueCopy.splice(index, 1)
    }

    setFieldValue(name, valueCopy)
  }, [name, setFieldValue, value])

  return (
    <div>
      <Container>
        <SelectorContainer>
          <SelectInput
            options={options}
            simpleValue
            multi
            multiParseInt
            name={name}
            value={valueWithoutDefault}
            {...rest}
          />
        </SelectorContainer>

        <div>
          <Checkbox>
            <input
              id={id}
              type="checkbox"
              onChange={toggleDefault}
              checked={value.includes(null)}
            />
            <label htmlFor={id}>Fallback to default</label>
          </Checkbox>
        </div>
      </Container>

      {touched[name] && errors[name] && <ErrorLabel>{errors[name]}</ErrorLabel>}
    </div>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
`

const SelectorContainer = styled.div`
  flex: 1;
  margin-right: 10px;
`

const Checkbox = styled.div.attrs({ className: 'checkbox check-success' })`
  label {
    margin-bottom: 0;
  }
`
