/* @flow */

import * as React from 'react'
import styled from 'styled-components'
import {
  Link,
  type Location as LocationType,
  type RouterHistory,
} from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import {
  FiShoppingCart,
  FiHeart,
  FiLayers,
  FiShoppingBag,
} from 'react-icons/fi'

import CloudinaryResource from '../../../../infrastructure/components/CloudinaryResource'
import { useWebshopNavigationLinks, useWebshopPages } from '../../hooks'
import { SessionContext } from '../../../shared'
import { WebshopContext } from '../../shared'

import WebshopMobileNavigation from '../Navigation/WebshopMobileNavigation'

const WebshopMobileHeader = ({
  history,
  brand,
  onSubmitSearch,
  setShowManualEditCustomerModal,
  setShowRetailerSelector,
  setShowSessionListModal,
}) => {
  const { entity } = React.useContext(SessionContext)
  const {
    availableBrand,
    currency,
    favorites,
    query,
    shopUrl,
    refreshWebshopTotals,
    setUseWebshopSessionId,
    totals,
    updateWebshopSession,
    webshopSession,
    webshopSettings,
  } = React.useContext(WebshopContext)

  const { customer, drop } = webshopSession

  const navigationFetchArgs = React.useMemo(() => {
    return [brand.id, webshopSession.id]
  }, [brand, webshopSession])

  const [
    navigationLinks,
    isFetchingNavigationLinks,
    { isInitialized: isNavigationLinksInitialized },
  ] = useWebshopNavigationLinks(navigationFetchArgs)

  const sortedNavigationLinks = React.useMemo(() => {
    return sortBy(navigationLinks, 'sort_order')
  }, [navigationLinks])

  const [showMobileNavigation, setShowMobileNavigation] = React.useState(false)

  const availableConnections = availableBrand
    ? availableBrand.retailer_connections.filter(
        connection => connection.b2b_access
      )
    : []

  if (!isNavigationLinksInitialized) {
    return null
  }

  return (
    <>
      <HeaderContainer fullWidth={webshopSettings.full_width}>
        <HeaderLogo>
          <Link to={shopUrl}>
            <CloudinaryResource
              brandId={brand.id}
              id={brand.logo}
              presets="logo_webshop"
              fallback="logo_black"
            />
          </Link>
        </HeaderLogo>

        <HeaderActions>
          <HeaderButtonAction onClick={() => setShowSessionListModal(true)}>
            <FiShoppingBag />
          </HeaderButtonAction>

          <HeaderLinkAction to={`${shopUrl}/favorites`}>
            <FiHeart />
          </HeaderLinkAction>

          <HeaderLinkAction to={`${shopUrl}/cart`}>
            <FiShoppingCart />
          </HeaderLinkAction>
        </HeaderActions>
      </HeaderContainer>

      <NavigationContainer fullWidth={webshopSettings.full_width}>
        <WebshopMobileNavigation
          brand={brand}
          handleSearchSubmit={onSubmitSearch}
          navigationLinks={sortedNavigationLinks}
          onHide={() => setShowMobileNavigation(false)}
          onShow={() => setShowMobileNavigation(true)}
          searchQuery={query.q || ''}
          shopUrl={shopUrl}
          show={showMobileNavigation}
        />
      </NavigationContainer>
    </>
  )
}

// Need class for custom CSS hook
const HeaderContainer = styled.div.attrs({ className: 'webshop-header' })`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-top: 15px;
  position: relative;
  ${({ fullWidth }) =>
    fullWidth === true &&
    `
    padding-left: 15px;
    padding-right: 15px;
  `}
`

const NavigationContainer = styled.div`
  ${({ fullWidth }) =>
    fullWidth === true &&
    `
    padding-left: 15px;
    padding-right: 15px;
  `}
`

const HeaderLogo = styled.div`
  flex: 0 1 250px;
  min-width: 140px;
  margin-right: 30px;

  img {
    /* Using !important so we override any max-width set from the image settings */
    max-width: 100% !important;
  }
`

const HeaderActions = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;

  > * {
    margin-left: 20px;
  }
`

const HeaderLinkAction = styled(Link)`
  display: block;
  color: black;
  font-size: 24px;
  line-height: 1;
  color: #000000;

  &:hover,
  &:focus {
    color: #23527c;
  }
`

const HeaderButtonAction = styled.button`
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: black;
  font-size: 24px;
  line-height: 1;
  color: #000000;

  &:hover,
  &:focus {
    color: #23527c;
  }
`

export default WebshopMobileHeader
