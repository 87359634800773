/* @flow */

import React, { useState, type ComponentType } from 'react'
import { PureComponent } from 'react'
import styled from 'styled-components'
import { Field, type FieldProps } from 'formik'
import { FormControl, InputGroup } from 'react-bootstrap'
import { ErrorLabel } from '../Forms'

import { renderBooleanInput as BooleanInput } from './BooleanInput'
import { renderCheckboxInput as CheckboxInput } from './CheckboxInput'
import { renderFormInput as FormInput } from './FormInput'
import { renderPriceInput as PriceInput } from './PriceInput'
import { renderSelectInput as SelectInput } from './SelectInput'

type Props = {
  defaultValue?: mixed,
  id: string,
  input: string | ComponentType<any>,
}

const CountrySettingsField = (props: Props) => {
  return <Field component={renderCountrySettingsField} {...props} />
}

export default CountrySettingsField

export const renderCountrySettingsField = ({
  overrideDefaultsWithNull = true,
  defaultCustomValue = overrideDefaultsWithNull ? null : undefined,
  defaultValue = overrideDefaultsWithNull ? null : undefined,
  id,
  input,
  overrideCheckboxLabel = 'Use country default',
  ...props
}: FieldProps & Props) => {
  const {
    field: { name, value },
    form: { touched, errors, setFieldValue, values },
  } = props
  const [customValue, setCustomValue] = useState(value !== defaultValue)

  function toggle() {
    if (customValue === true) {
      setCustomValue(false)
      setFieldValue(name, defaultValue)
    } else {
      setCustomValue(true)
      setFieldValue(name, defaultCustomValue)
    }
  }

  let InputComponent
  if (input === 'select') {
    InputComponent = SelectInput
  } else if (input === 'input') {
    InputComponent = FormInput
  } else if (input === 'price') {
    InputComponent = PriceInput
  } else if (input === 'checkbox') {
    InputComponent = CheckboxInput
    props.id = id + '-checkbox'
  } else if (input === 'boolean') {
    InputComponent = BooleanInput
    props.id = id + '-checkbox'
  } else {
    InputComponent = input
  }

  return (
    <Container>
      <FieldsContainer>
        <FieldContainer>
          <InputComponent disabled={customValue === false} {...props} />
        </FieldContainer>
        <CheckboxContainer>
          <div className="checkbox check-success">
            <input
              id={id}
              onChange={toggle}
              checked={!customValue}
              type="checkbox"
            />
            <label htmlFor={id}>{overrideCheckboxLabel}</label>
          </div>
        </CheckboxContainer>
      </FieldsContainer>
      {touched[name] && errors[name] && <ErrorLabel>{errors[name]}</ErrorLabel>}
    </Container>
  )
}

const Container = styled.div``

const FieldsContainer = styled.div`
  align-items: center;
  display: flex;
`

const FieldContainer = styled.div`
  flex: 1;
`

const CheckboxContainer = styled.div`
  margin-left: 10px;
  padding-top: 2px;
`
