import { userHasFlag } from '../../../app/shared'
import localStorage from '../../modules/localStorage'

const LOCAL_STORAGE_ALLOW_COOKIE_USAGE = 'webshop/cookie_allowed';

export const shouldNotifyAboutCookiesUsage = brand => {
  return brand.settings.webshop_settings.notify_about_cookie_usage;
}

export const isCookiesPermissionGiven = () => {
  const allowedCookieUsage = localStorage.getItem(LOCAL_STORAGE_ALLOW_COOKIE_USAGE);
  return allowedCookieUsage === 'true';
}

export const setAllowCookies = () => {
  localStorage.setItem(LOCAL_STORAGE_ALLOW_COOKIE_USAGE, 'true');
}
