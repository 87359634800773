/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import type { ColumnConfig } from '../../types'

const return_portal_claim_cause: (options: Options) => ColumnConfig = ({
  editable = false,
  rows,
}) => ({
  // if there is no default value then the row will unmount and mount all the time
  default_value: [{ return_portal_claim_cause: null }],
  editable: editable,
  key: 'return_portal_claim_cause',
  label: 'Claim cause',
  rows: rows,
  split_by_values: true,
  value: ({ line }) => [
    { return_portal_claim_cause: line ? line.return_portal_claim_cause : null },
  ],
  render: ({ value }) => {
    if (!value || !value[0] || !value[0].return_portal_claim_cause) {
      return null
    }

    return value[0].return_portal_claim_cause.description
  },
})

export default return_portal_claim_cause
