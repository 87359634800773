// @flow

import flatMap from 'lodash/flatMap'
import merge from 'lodash/merge'
import {
  OPEN_INVENTORY,
  FETCH_INVENTORY_REQUEST,
  FETCH_INVENTORY_SUCCESS,
  SAVE_INVENTORY_CHANGES_REQUEST,
  SAVE_INVENTORY_CHANGES_SUCCESS,
  CHANGE_STOCK,
} from '../actions'
import type { Product, Variant } from '../../products/types'
import keyBy from 'lodash/keyBy'

type InventoryStock = {
  [number]: {
    // Variant.id
    [string]: {
      // inventory_location_id
      quantity: number,
    },
  },
}

type State = {
  inventory: Array<Product>,
  isFetching: boolean,
  stock: InventoryStock,
  stockChanges: InventoryStock,
  isSaving: boolean,
}

const initialState = {
  inventory: [],
  isFetching: false,
  stock: {},
  stockChanges: {},
  isSaving: false,
}

export default function inventory(state: State = initialState, action) {
  switch (action.type) {
    case OPEN_INVENTORY:
      return Object.assign({}, state, {
        stockChanges: {},
      })
    case FETCH_INVENTORY_REQUEST:
      return Object.assign({}, state, {
        inventory: [],
        isFetching: true,
      })
    case FETCH_INVENTORY_SUCCESS:
      return Object.assign({}, state, {
        inventory: keyAttributes(action.payload),
        isFetching: false,
        stock: getInventoryStock(action.payload, action.meta.locations),
      })
    case CHANGE_STOCK:
      return Object.assign({}, state, {
        stockChanges: action.payload.stockChanges,
      })
    case SAVE_INVENTORY_CHANGES_REQUEST:
      return Object.assign({}, state, {
        isSaving: true,
      })
    case SAVE_INVENTORY_CHANGES_SUCCESS:
      return Object.assign({}, state, {
        stock: merge({}, state.stock, state.stockChanges),
        stockChanges: {},
        isSaving: false,
      })

    default:
      return state
  }
}

const getInventoryStock = (
  inventory: Array<Product>,
  locations
): InventoryStock => {
  const allVariants: Array<Variant> = flatMap(
    inventory,
    product => product.variants
  )

  const inventoryStock = {}
  allVariants.forEach(variant => {
    const variantStock = {}
    locations.forEach(location => {
      const stockItem = variant.stock.find(
        item => item.inventory_location_id === location.id
      )
      const quantity = stockItem ? stockItem.quantity : 0
      variantStock[location.id] = { quantity }
    })

    inventoryStock[variant.id] = variantStock
  })

  return inventoryStock
}

const keyAttributes = (inventory: Array<Product>): Array<Product> => {
  return inventory.map(product => {
    product.variants = product.variants.map(variant => {
      variant.attribute_values = keyBy(variant.attribute_values, 'attribute_id')
      return variant
    })
    return product
  })
}
