/* @flow */

import React, { useMemo } from 'react'
import Select from 'react-select'
import sortBy from 'lodash/sortBy'

import { useCachedDrops } from '../hooks'

const DropSelector = props => {
  const [drops, { isFetching }] = useCachedDrops(dropFetchArgs)

  return (
    <DatalessDropSelector drops={drops} isFetching={isFetching} {...props} />
  )
}

export default DropSelector

const dropFetchArgs = {
  includes: ['deliveries'],
}

export const DatalessDropSelector = ({
  drops,
  excludeOptions,
  includeArchived = false,
  isFetching,
  value,
  ...props
}) => {
  const options = useMemo(() => {
    let options = sortBy(drops, 'name')

    let valueAsArray = value
    // we dont want to filter out a selected option
    if (!Array.isArray(valueAsArray)) {
      valueAsArray = [valueAsArray]
    }
    valueAsArray = valueAsArray.map(id => parseInt(id))

    if (excludeOptions) {
      options = options.filter(
        drop =>
          valueAsArray.includes(drop.id) || !excludeOptions.includes(drop.id)
      )
    }

    if (!includeArchived) {
      options = options.filter(
        drop => valueAsArray.includes(drop.id) || !drop.archived
      )
    }

    return options
  }, [drops, includeArchived, excludeOptions, value])

  return (
    <Select
      isLoading={isFetching}
      labelKey="name"
      placeholder="Select drop..."
      options={options}
      valueKey="id"
      value={value}
      {...props}
    />
  )
}
