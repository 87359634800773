/* @flow */

import type { Entity, Order, Session, User } from '../../../../types'
import { UserHasPermissions } from '../../../../../infrastructure/components/Authorization'

export const IsNotB2COrderPredicate = (
  { order }: { order: Order },
  session: Session
) => {
  // This predicate is used for discount modal and such. And in NewOrder, order is empty
  return !order || order.type !== 'b2c'
}
export const IsBrandPredicate = (
  context: Object,
  { entity }: { entity: Entity }
) => entity.entity_type === 'brand'
export const OpenOrderPredicate = (
  { order }: { order: Order },
  session: Session
) => order && !order.cancelled
export const UnlockedOrderPredicate = (
  { order }: { order: Order },
  session: Session
) => {
  return !order || (order.locked === false || order.locked === undefined)
}
export const CanWriteProductsPredicate = (
  context: Object,
  { user }: { user: User }
) => UserHasPermissions(user, 'orders:lines:write')
export const CanDiscountProductsPredicate = (
  context: Object,
  { user }: { user: User }
) => {
  return UserHasPermissions(
    user,
    ['orders:discounts:percentage', 'orders:discounts:monetary'],
    true
  )
}

export const ProductTableOptionActionCallback = (
  changes: { [string]: mixed },
  instance: { setTableOption: (changes: { [string]: mixed }) => void }
) => {
  instance.setTableOption(changes)
}
