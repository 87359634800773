/* @flow */

import React from 'react'
import styled, { css } from 'styled-components'
import { Formik } from 'formik'

import FormatCurrency from '../../../../infrastructure/components/FormatCurrency'
import { FormInput } from '../../../../infrastructure/components/Formik'

import type { Product, Variant } from '../../../types'

type Props = {
  context: Object,
  edit?: boolean,
  editable?: boolean,
  onEdit: (editMode: boolean) => void,
  product: Product,
  variants: Array<Variant>,
}

const LineText = ({
  context,
  edit = false,
  editable = false,
  onChange,
  onEdit,
  product,
  variants,
  value,
}: Props) => {
  const initialValues = React.useMemo(() => {
    return {
      value: value[0] ? value[0].text : null,
    }
  }, [value])

  const onSubmit = React.useCallback(
    (values, { setSubmitting }) => {
      onChange({ text: values.value })

      setSubmitting(false)
    },
    [onChange, value]
  )

  return (
    <LineTextContainer>
      {editable === true && edit === false && (
        <LineTextEditButton onClick={() => onEdit(true)}>
          <span className="glyphicon glyphicon-pencil" />
        </LineTextEditButton>
      )}

      {edit === false && <div>{value[0] ? value[0].text : ''}</div>}
      {edit === true && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, isSubmitting, setFieldValue, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <LineTextInputContainer>
                  <CancelButton onClick={() => onEdit(false)} />

                  <SavePencilButton size="small" submitting={isSubmitting} />

                  <StyledFormInput markOnFocus name="value" />
                </LineTextInputContainer>
              </form>
            )
          }}
        />
      )}
    </LineTextContainer>
  )
}

export default LineText

const LineTextContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const LineTextEditButton = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border: none;
  font-size: 10px;
  outline: 0;
`

const LineTextInputContainer = styled.div`
  display: flex;
  min-width: 165px;
`

const LineTextFormSaveButton = styled.button.attrs({
  type: 'submit',
})`
  background: transparent;
  border: none;
  outline: 0;
`

const StyledFormInput = styled(FormInput).attrs({ bsSize: 'sm' })`
  border: none;
  box-shadow: none;
  border-bottom: 1px dashed #d8d8d8;
  outline: 0;
  text-align: right;

  &:focus {
    border-color: inherit;
    outline: 0;
    box-shadow: none;
  }
`

export const CancelButton = ({ size = 'normal', ...rest }) => {
  return (
    <IconButton size={size} type="button" {...rest}>
      <span className="glyphicon glyphicon-remove" />
    </IconButton>
  )
}

export const SavePencilButton = ({ submitting, size = 'normal', ...rest }) => {
  return (
    <IconButton disabled={submitting} size={size} type="submit" {...rest}>
      {!submitting && <span className="glyphicon glyphicon-ok" />}
      {submitting && <span className="fa fa-spinner fa-spin" />}
    </IconButton>
  )
}

const IconButton = styled.button`
  background: none;
  border: none;
  float: ${({ float }) => float};
  outline: 0;

  ${({ size }: { size: 'normal' | 'small' }) => sizeToStyle(size)};
`

const sizeToStyle = size => {
  switch (size) {
    case 'normal':
      return css``

    case 'small':
      return css`
        font-size: 11px;
        font-weight: normal;
      `
  }
}
