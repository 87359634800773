/* @flow */

import * as React from 'react'
import memo from 'memoize-one'
import querystring from 'qs'
import { SessionContext } from '../shared'
import { connect } from 'react-redux'
import { usePublicBrand, useShowroomSession } from './hooks'
import { useEffect } from 'react'
import {
  type Location as LocationType,
  type Match,
  type RouterHistory,
} from 'react-router-dom'
import { useAvailableBrandsList, useFetchBrandIfNeeded } from '../brands/hooks'
import { setBrands } from '../brands/actions'
import { switchEntity } from '../template/actions'

import { showroomSessionSetCustomer } from './api'
import { ShowroomContext } from './shared'

import type { Dispatch } from '../types'
import NoAccessToShowroom from './NoAccessToShowroom'
import { resolver as baseCloudinaryImageResolver } from 'cloudinary-url-resolver'

type Props = {
  children?: React.Node,
  dispatch: Dispatch<any>,
  location: LocationType,
  history: RouterHistory,
  match: Match,
}

const ShowroomSession = ({
  children,
  dispatch,
  history,
  location,
  match,
}: Props) => {
  const brandId = match.params.brandId
  const showroomId = match.params.showroomId

  const query = React.useMemo(() => {
    return querystring.parse(window.location.search.slice(1))
  }, [location.search])

  // Session

  const showroomSessionArgs = React.useMemo(() => {
    return [brandId, showroomId, query.resume || null]
  }, [brandId, showroomId, query.resume])
  const [
    showroomSession,
    isShowroomSessionFetching,
    { refresh: refreshShowroomSession },
  ] = useShowroomSession(showroomSessionArgs)

  // Brands

  useFetchBrandIfNeeded(brandId, dispatch)

  const { brands, entity } = React.useContext(SessionContext)
  const [brand, setBrand] = React.useState(null)

  // Public brand

  const options = React.useMemo(
    () => ({
      resource: 'showroom',
      resource_id: match.params.showroomId,
    }),
    []
  )

  const [{ brands: availableBrands }] = useAvailableBrandsList()
  const availableBrand = React.useMemo(() => {
    return availableBrands.find(b => b.id == brandId)
  }, [availableBrands, brandId])

  const [publicBrand, isFetching] = usePublicBrand([brandId, options])

  // TODO: we should have a generic method that can update various properties
  // on showroom session. Right now it only supports customerId
  const onUpdateShowroomSession = React.useCallback(
    values => {
      if (!brand || !values.customer_id) {
        return
      }

      return showroomSessionSetCustomer(brand.id, values.customer_id).then(
        response => {
          if (!response.error) {
            refreshShowroomSession()
          }

          return response
        }
      )
    },
    [brand, refreshShowroomSession]
  )

  useEffect(() => {
    const availableBrands = []

    if (publicBrand) {
      availableBrands.push(publicBrand)
    }
    if (brand) {
      availableBrands.push(brand)
    }

    dispatch(setBrands(availableBrands))
  }, [brand, publicBrand, setBrands])

  // Set brand

  React.useEffect(() => {
    if (!brands[brandId]) {
      if (availableBrand) {
        const availableShopEntityIds = availableBrand.retailer_connections
          .filter(connection => {
            return connection.b2b_access === true
          })
          .map(conn => conn.shop_id)

        if (
          !availableShopEntityIds.includes(entity.id) &&
          availableShopEntityIds.length > 0
        ) {
          dispatch(switchEntity(availableShopEntityIds[0]))
        }
      }

      return
    }

    setBrand(brands[brandId])
  }, [availableBrand, brands, brandId, entity, setBrand])

  // Has loaded
  const [hasLoaded, setHasLoaded] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setHasLoaded(true)
    }, 1000)
  }, [setHasLoaded])

  const showroomContext = React.useMemo(() => {
    return {
      brand,
      showroomSession,
      onUpdateShowroomSession,
    }
  }, [brand, showroomSession, onUpdateShowroomSession])

  // Children

  if (!showroomSession) {
    return null
  }

  if (!brand) {
    return hasLoaded ? (
      <NoAccessToShowroom
        brand={brand}
        imageResolver={cloudinaryImageResolver}
      />
    ) : null
  }

  return (
    <ShowroomContext.Provider value={showroomContext}>
      {children}
    </ShowroomContext.Provider>
  )
}
export default connect()(ShowroomSession)

const cloudinaryImageResolver = (image, presets) => {
  return baseCloudinaryImageResolver(`${image}.jpg`, presets)
}
