// @flow

import * as React from 'react'
import styled from 'styled-components'
import { FiX } from 'react-icons/fi'

type Props = {
  children: React.Node,
  footerButtonLabel?: string,
  onHide: () => any,
  show: boolean,
  title?: string,
}

const WebshopMobileModal = ({
  children,
  footerButtonLabel = 'Close',
  onHide,
  show,
  title = '',
}: Props) => {
  // Disable scrolling of content under overlay
  React.useEffect(() => {
    if (show) {
      document.body.style.setProperty('overflow', 'hidden')
    } else {
      document.body.style.removeProperty('overflow')
    }

    return () => {
      document.body.style.removeProperty('overflow')
    }
  }, [show])

  return (
    <OverlayContainer show={show}>
      <Content>
        <HeaderContainer>
          <Header>{title}</Header>
          <CloseButton type="button" onClick={onHide}>
            <FiX size={16} style={{ display: 'block' }} />
          </CloseButton>
        </HeaderContainer>

        <InnerContent>{children}</InnerContent>

        <FooterButton type="button" onClick={onHide}>
          {footerButtonLabel}
        </FooterButton>
      </Content>

      <Overlay onClick={onHide} />
    </OverlayContainer>
  )
}

export default WebshopMobileModal

const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow-x: hidden;

  background-color: #fff;

  transition: all 0.5s;
  transform: translateY(100%);

  display: flex;
  flex-direction: column;
  justify-content: stretch;
`

const InnerContent = styled.div`
  flex: 1;
  padding: 10px;
  overflow: scroll;
`

const Overlay = styled.div`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(20px);
  background: rgba(0, 0, 0, 0.1);
  transition: opacity 0.5s;
  opacity: 0;
`

const OverlayContainer = styled.div.attrs(({ show }) => ({
  className: `mobile-webshop-filter-overlay-container ${show ? 'show' : ''}`,
}))`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  transition: z-index 1s;

  &.show {
    z-index: 99;
    transition: none;

    ${Content} {
      transform: translateY(0%);
    }

    ${Overlay} {
      opacity: 1;
    }
  }
`

const HeaderContainer = styled.div`
  flex: 0 0 auto;
  height: 50px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid rgba(0, 0, 0, 0.05);
`

const Header = styled.h3`
  margin: 0;
  padding: 0;

  font-family: 'Proxima Nova', 'proxima-nova', sans-serif;
  font-size: 14px;
  line-height: 1.15;
  font-weight: 700;
  color: #000;
  letter-spacing: 1px;
  text-transform: uppercase;
`

const CloseButton = styled.button`
  padding: 10px;
  margin: 0 -10px 0 0;
  background: transparent;
  border: none;
  color: #000;
`

const FooterButton = styled.button`
  flex: 0 0 auto;
  display: block;
  height: 50px;
  background: #000;
  border: none;

  font-family: 'Proxima Nova', 'proxima-nova', sans-serif;
  font-size: 14px;
  line-height: 1.15;
  font-weight: 700;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
`
