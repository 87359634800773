/* @flow */

import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

type Props = {}

const MobileNavigation = ({
  containerStyle,
  children,
  header,
  headerStyle,
  onHide,
  show,
  wrapperClass,
}) => {
  const classes = classnames({
    'mobile-navigation-container': true,
    show: show,
  })

  // Disable scrolling of content under overlay
  React.useEffect(() => {
    if (show) {
      document.body.style.setProperty('overflow', 'hidden')
    } else {
      document.body.style.removeProperty('overflow')
    }
    return () => {
      document.body.style.removeProperty('overflow')
    }
  }, [show])

  return (
    <Container style={containerStyle} className={classes}>
      <Content>
        <Header style={headerStyle}>{header}</Header>

        <NavigationWrapper className={wrapperClass}>
          {children}
        </NavigationWrapper>
      </Content>

      <Overlay onClick={onHide} />
    </Container>
  )
}

export default MobileNavigation

const Header = styled.div`
  width: 100%;
  height: 80px;
  padding: 10px;
  background-color: #ebe8e8;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 50px;
    max-width: calc(100% - 20px);
  }

  .showroom-mobile-logo {
    padding: 3px;
    border-radius: 3px;
    background-color: #fff;
    text-align: center;
  }

  .close {
    opacity: 1;
    color: #000;
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
  }
`

const MobileNavigationItemList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    cursor: pointer;
    border-bottom: 1px solid rgba(1, 1, 1, 0.1);

    &:last-child {
      border-bottom: 0;
    }

    > * {
      display: block;
      color: #000;
      padding: 15px;
      letter-spacing: 1px;
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .icon {
      display: inline-block;
      width: 27px;
    }
  }
`

MobileNavigation.ItemList = MobileNavigationItemList

const MobileNavigationItemListHeader = styled.div``

MobileNavigation.ItemListHeader = MobileNavigationItemListHeader

const NavigationWrapper = styled.div`
  flex-grow: 1;
  width: calc(100% + 10px);
  padding-right: 10px;
  overflow-y: scroll;
`

const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 380px;
  max-width: 100%;
  height: 100%;
  z-index: 2;
  overflow-x: hidden;

  background-color: #fff;

  transition: all 0.5s;
  transform: translateX(-100%);

  display: flex;
  flex-direction: column;
`

const Overlay = styled.div`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(20px);
  background: rgba(0, 0, 0, 0.1);
  transition: opacity 0.5s;
  opacity: 0;
`

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  transition: z-index 1s;

  &.show {
    z-index: 99;
    transition: none;

    ${Content} {
      transform: translateX(0%);
    }

    ${Overlay} {
      opacity: 1;
    }
  }
`

const FakeLink = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: none;
  outline: 0;
  font-size: 13px;
  padding: 15px;
`

MobileNavigation.FakeLink = FakeLink

const MobileNavigationSubItemList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    background: #f8f8f8;
    border-bottom: 1px solid rgba(1, 1, 1, 0.1);
    cursor: pointer;

    &:last-child {
      border-bottom: 0;
    }

    > * {
      display: block;
      color: #000;
      padding: 12px 5px 12px 25px;
      letter-spacing: 1px;
    }

    a {
      font-size: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .icon {
      display: inline-block;
      width: 27px;
    }
  }
`

MobileNavigation.SubItemList = MobileNavigationSubItemList
