/* @flow */

import React from 'react'
import styled from 'styled-components'

import FieldTypeSelector from '../../../custom-fields/components/FieldTypeSelector'
import { renderCustomField } from '../../../custom-fields/shared'

const CustomFieldRender = ({
  customField,
  editable,
  edit,
  onChange,
  onEdit,
  value,
}) => {
  const [newValue, setNewValue] = React.useState(value)

  React.useEffect(() => {
    setNewValue(value)
  }, [setNewValue, value])

  let onClick
  if (editable) {
    onClick = () => onEdit(true)
  }

  const clear = () => {
    setNewValue(value)

    onEdit(false)
  }

  return (
    <Container>
      {!edit && (
        <Label editable={editable} onClick={onClick}>
          {renderCustomField(customField, value)}
        </Label>
      )}
      {edit && (
        <EditContainer>
          <Button onClick={clear}>
            <span className="glyphicon glyphicon-remove" />
          </Button>
          <Button
            onClick={() =>
              onChange({ meta: { [customField.internal_name]: newValue } })
            }
          >
            <span className="glyphicon glyphicon-ok" />
          </Button>

          <FieldTypeSelector
            description={customField.description}
            name={customField.internal_name}
            onChange={newValue =>
              setNewValue(newValue[customField.internal_name])
            }
            options={customField.options}
            type={customField.field_type}
            value={newValue}
          />
        </EditContainer>
      )}
    </Container>
  )
}

export default CustomFieldRender

const Container = styled.div`
  bottom: 0;
  display: flex;
  left: 0;
  padding: 0 2px;
  position: absolute;
  right: 0;
  top: 0;
`

const Label = styled.div`
  align-items: center;
  cursor: ${({ editable }) => (editable ? 'text' : 'inherit')};
  display: flex;
  height: 100%;
  flex: 1;
`

const EditContainer = styled.div`
  align-items: center;
  display: flex;
`

const Button = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border: none;
  font-size: 10px;
  outline: 0;
`
