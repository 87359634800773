/* @flow */

import React, { Component, useMemo } from 'react'
import Select from 'react-select'
import sortBy from 'lodash/sortBy'

import { getCustomerGroups } from '../actions/customer_groups'
import { useCachedCustomerGroups } from '../hooks'

import type { CustomerGroup } from '../../types'

const CustomerGroupSelector = (props: any) => {
  const [customerGroups, isFetching] = useCachedCustomerGroups()

  return (
    <DatalessCustomerGroupSelector
      customerGroups={customerGroups}
      isFetching={isFetching}
      {...props}
    />
  )
}

export default CustomerGroupSelector

export const DatalessCustomerGroupSelector = ({
  customerGroups,
  isFetching,
  ...props
}) => {
  const options = useMemo(() => {
    let options = sortBy(customerGroups, 'name')

    return options
  }, [customerGroups])

  return (
    <Select
      isLoading={isFetching}
      labelKey="name"
      placeholder="Select customer group..."
      options={options}
      valueKey="id"
      {...props}
    />
  )
}
