/* @flow */

import unit_price from './unit_price'

const unit_price_no_discount = (options = {}) =>
  unit_price({
    priceProperty: 'net_price',
    showDiscount: false,
    ...options,
  })

export default unit_price_no_discount
