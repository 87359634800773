/* @flow */

import { createApiHook, createDataCache } from '../../app/hooks'
import { getCountries, getStates } from './api'

const fetchCountriesForHook = () =>
  getCountries().then(response => {
    if (!response.error) {
      return {
        entity: response.payload,
      }
    } else {
      return response
    }
  })

const useCountries = createApiHook(fetchCountriesForHook, [])

const {
  cache: { clearCache: clearCountriesCache },
  hook: useCachedCountries,
} = createDataCache(useCountries)

const fetchStatesForHook = () =>
  getStates().then(response => {
    if (!response.error) {
      return {
        entity: response.payload,
      }
    } else {
      return response
    }
  })

const useStates = createApiHook(fetchStatesForHook, [])

const {
  cache: { clearCache: clearStatesCache },
  hook: useCachedStates,
} = createDataCache(useStates)  

export {
  // countries
  clearCountriesCache,
  getCountries,
  useCountries,
  useCachedCountries,
  // states
  useCachedStates,
  clearStatesCache,
}
