/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import type { RowConfig } from '../../types'

type Options = {
  colli_not_included_in_value?: boolean,
  editable?: boolean,
  enable_colli?: boolean,
  expand_assortments?: boolean,
  label?: string,
}

const inventory_sub_levels: (options: Options) => RowConfig = ({
  label = 'Warehouse levels',
}) => ({
  editable: false,
  key: 'inventory_sub_levels',
  label,
  render: ({ lines }) => {
    const line = lines[0] ? lines[0] : null

    if (!line || !line.sub_levels || line.sub_levels.length == 0) {
      return null
    }

    return (
      <div>
        {line.sub_levels.map(subLevel => {
          if (!subLevel.warehouse_location) {
            return null
          }

          return (
            <div>
              {subLevel.quantity} x {subLevel.warehouse_location.location}
            </div>
          )
        })}
      </div>
    )
  },
})

export default inventory_sub_levels
