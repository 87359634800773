/* @flow */

import * as React from 'react'
import styled, { css } from 'styled-components'
import { type Match } from 'react-router-dom'
import uuid from 'uuid'

import {
  FiHome,
  FiTruck,
  FiLayers,
  FiSearch,
  FiMenu,
  FiPackage,
  FiCornerUpLeft,
} from 'react-icons/fi'
import { useLocalStorage } from '../../../infrastructure/hooks'
import { useBarcode, useSubUser } from '../hooks'
import { getShipment, pickShipment } from '../../shipments/api'
import { useComputers } from '../../settings/hooks'
import Button from '../components/Button'

import FixedElement from '../components/FixedElement'
import WarehouseSubUserList from './WarehouseSubUserList'

import { WarehouseContext } from '../shared'
import { SessionContext, msg } from '../../shared'

type Props = {
  children?: React.Node,
  match: Match,
}

export const navigate = (history, to, callback) => {
  history.push(to)
  callback && callback()
}

const WarehouseTemplate = ({ children, history, location }: Props) => {
  const [activeIndicatorOffset, setActiveIndicatorOffset] = React.useState(0)
  const [debugScanner, setDebugScanner] = React.useState(false)
  const [scannerActivated, setScannerActivated] = React.useState(false)
  const [barcode, setBarcode] = React.useState(null)
  const { brand, entity, user } = React.useContext(SessionContext)

  const isReturnsSupported = React.useMemo(() => {
    return brand ? brand.apps.find(app => app.app === 'Coolrunner') : false
  }, [brand])

  const dashboardButton = React.useRef(null)
  const shipmentsButton = React.useRef(null)
  const inventoryButton = React.useRef(null)
  const productionOrdersButton = React.useRef(null)
  const searchButton = React.useRef(null)
  const navigationButton = React.useRef(null)
  const returnsButton = React.useRef(null)

  const navigateTo = to => {
    navigate(history, to)
  }

  const activeNavigation = React.useMemo(() => {
    if (/^\/warehouse$/.test(location.pathname)) {
      return 'dashboard'
    } else if (/^\/warehouse\/shipments/.test(location.pathname)) {
      return 'shipments'
    } else if (/^\/warehouse\/scan/.test(location.pathname)) {
      return 'shipments'
    } else if (/^\/warehouse\/inventory/.test(location.pathname)) {
      return 'inventory'
    } else if (/^\/warehouse\/search/.test(location.pathname)) {
      return 'search'
    } else if (/^\/warehouse\/navigation/.test(location.pathname)) {
      return 'navigation'
    } else if (/^\/warehouse\/production-orders/.test(location.pathname)) {
      return 'production-orders'
    } else if (/^\/warehouse\/returns/.test(location.pathname)) {
      return 'returns'
    }
  }, [location.pathname])

  React.useLayoutEffect(() => {
    let button = null

    switch (activeNavigation) {
      case 'dashboard':
        button = dashboardButton
        break
      case 'shipments':
        button = shipmentsButton
        break
      case 'inventory':
        button = inventoryButton
        break
      case 'search':
        button = searchButton
        break
      case 'navigation':
        button = navigationButton
        break
      case 'production-orders':
        button = productionOrdersButton
        break
      case 'returns':
        button = returnsButton
        break
      default:
        setActiveIndicatorOffset(-200)
        return
    }

    setActiveIndicatorOffset(
      button.current.offsetLeft + button.current.offsetWidth / 2
    )
  }, [
    activeNavigation,
    setActiveIndicatorOffset,

    dashboardButton,
    shipmentsButton,
    inventoryButton,
    searchButton,
    navigationButton,
    returnsButton,
  ])

  // Barcode events

  React.useEffect(() => {
    // Activate scanner

    if (typeof Android != 'undefined') {
      Android.useJavaScriptCallbackZebraScanner(true)
      setScannerActivated(true)
    }

    // Callback

    window.barcodeScanResult = barcode => {
      if (debugScanner) {
        alert(`Scanned ${barcode}`)
      }

      setBarcode({ barcode })
    }
  }, [setBarcode, debugScanner, setScannerActivated])

  const testScan = React.useCallback(() => {
    setBarcode({ barcode: 'ship-1899889' })
  }, [setBarcode])

  const [
    computers,
    isFetchingComputers,
    { isInitialized: isComputersInitialized },
  ] = useComputers()
  const [
    storageComputer,
    setComputerData,
    isComputerStorageInitialized,
    { clearStoredData: clearStoredComputerData },
  ] = useLocalStorage('warehouse_computer', {
    computer_id: null,
  })

  let storageComputerId = null
  if (storageComputer) {
    storageComputerId = storageComputer.computer_id
  }

  const selectedComputer = React.useMemo(() => {
    return computers.find(computer => computer.id === storageComputerId) || null
  }, [storageComputerId, computers])

  const setSelectedComputer = React.useCallback(
    computer => {
      setComputerData({ computer_id: computer ? computer.id : null })
    },
    [setComputerData]
  )

  React.useEffect(() => {
    if (!isComputersInitialized || !isComputerStorageInitialized) {
      return
    }

    if (storageComputerId) {
      const storedComputer = computers.find(c => c.id == storageComputerId)

      if (storedComputer) {
        return
      }
    }

    if (user.entity_user && user.entity_user.computer_id) {
      setComputerData({ computer_id: user.entity_user.computer_id })
    } else {
      const defaultComputer = computers.find(
        computer => computer.default_computer
      )

      setComputerData({
        computer_id: defaultComputer ? defaultComputer.id : null,
      })
    }
  }, [
    computers,
    user,
    storageComputerId,
    isComputersInitialized,
    isComputerStorageInitialized,
    setComputerData,
  ])

  const [
    { device_id: fallbackDeviceId },
    setFallbackDeviceId,
    isFallbackDeviceIdInitialized,
  ] = useLocalStorage('warehouse_device_id', {
    device_id: `traede_device_${uuid()}`,
  })

  const deviceId = React.useMemo(() => {
    if (window.Android && window.Android.getUniqueIdentifier) {
      return window.Android.getUniqueIdentifier()
    }

    return fallbackDeviceId
  }, [fallbackDeviceId])

  const [
    subUser,
    shouldShowSubUserList,
    setSubUser,
    subUsers,
    unsetMySubUserDevice,
    isSubUsersInitialized,
  ] = useSubUser(entity, user, deviceId, isFallbackDeviceIdInitialized)

  const warehouseContextValue = React.useMemo(() => {
    return {
      barcode,
      clearStoredComputerData,
      computers,
      debugScanner,
      deviceId,
      isSubUsersInitialized,
      selectedComputer,
      setBarcode,
      setSelectedComputer,
      setDebugScanner,
      setSubUser,
      subUser,
      subUsers,
      unsetMySubUserDevice,
    }
  }, [
    barcode,
    clearStoredComputerData,
    computers,
    debugScanner,
    deviceId,
    isSubUsersInitialized,
    selectedComputer,
    setBarcode,
    setSelectedComputer,
    setDebugScanner,
    setSubUser,
    subUser,
    subUsers,
    unsetMySubUserDevice,
  ])

  const claim = React.useCallback(
    shipment => {
      if (!shipment) {
        return
      }

      pickShipment(shipment.id, true).then(() => {
        navigate(history, `/warehouse/shipments/scan/${shipment.id}`)
      })
    },
    [navigate, history]
  )

  React.useEffect(() => {
    if (!barcode) {
      return
    }

    console.log('Template scan', barcode)

    const [type, id] = String(barcode.barcode).split('-')

    if (!type || !id) {
      return
    }

    switch (type) {
      case 'ship':
        getShipment(id).then(response => {
          if (response.error) {
            if (response.status == 404) {
              msg('error', `No shipment found on this account`)
            }

            return response
          }

          const payload = response.payload
          const { shipment } = payload

          claim(shipment)
        })
        break
    }
  }, [barcode, claim, navigate, history])

  return (
    <WarehouseContext.Provider value={warehouseContextValue}>
      <Container>
        <Content>
          <InnerContentContainer>
            {shouldShowSubUserList && (
              <div>
                <FixedElement>
                  <Header>
                    <HeaderContainer>
                      <Headline>Select user</Headline>
                    </HeaderContainer>
                  </Header>
                </FixedElement>

                <WarehouseSubUserList />
              </div>
            )}
            {!shouldShowSubUserList && children}
          </InnerContentContainer>
        </Content>

        {!shouldShowSubUserList && (
          <Navigation>
            <NavigationItemsContainer>
              <NavigationItemButton
                ref={dashboardButton}
                onClick={() => navigateTo('/warehouse')}
                active={activeNavigation === 'dashboard'}
              >
                <FiHome style={{ verticalAlign: 'middle' }} />
              </NavigationItemButton>

              <NavigationItemButton
                ref={shipmentsButton}
                onClick={() => navigateTo('/warehouse/shipments')}
                active={activeNavigation === 'shipments'}
              >
                <FiTruck style={{ verticalAlign: 'middle' }} />
              </NavigationItemButton>

              <NavigationItemButton
                ref={inventoryButton}
                onClick={() => navigateTo('/warehouse/inventory')}
                active={activeNavigation === 'inventory'}
              >
                <FiLayers style={{ verticalAlign: 'middle' }} />
              </NavigationItemButton>

              <NavigationItemButton
                ref={productionOrdersButton}
                onClick={() => navigateTo('/warehouse/production-orders')}
                active={activeNavigation === 'production-orders'}
              >
                <FiPackage style={{ verticalAlign: 'middle' }} />
              </NavigationItemButton>

              <NavigationItemButton
                ref={returnsButton}
                onClick={() => navigateTo('/warehouse/returns')}
                active={activeNavigation === 'returns'}
              >
                <FiCornerUpLeft style={{ verticalAlign: 'middle' }} />
              </NavigationItemButton>

              <NavigationItemButton
                ref={searchButton}
                onClick={() => navigateTo('/warehouse/search')}
                active={activeNavigation === 'search'}
              >
                <FiSearch style={{ verticalAlign: 'middle' }} />
              </NavigationItemButton>

              <NavigationItemButton
                ref={navigationButton}
                onClick={() => navigateTo('/warehouse/navigation')}
                active={activeNavigation === 'navigation'}
              >
                <FiMenu style={{ verticalAlign: 'middle' }} />
              </NavigationItemButton>
            </NavigationItemsContainer>

            <NavigationActiveIndicatorContainer>
              <NavigationActiveIndicator offset={activeIndicatorOffset} />
            </NavigationActiveIndicatorContainer>
          </Navigation>
        )}

        {debugScanner && (
          <DevPanel>
            <div>
              {scannerActivated && <div>Scanner activated</div>}
              {!scannerActivated && <div>Scanner not activated!</div>}
            </div>

            <div style={{ marginTop: 5 }}>
              {barcode !== null && <div>Barcode: {barcode.barcode}</div>}
              {barcode == null && <div>Barcode: None</div>}
            </div>
          </DevPanel>
        )}
      </Container>
    </WarehouseContext.Provider>
  )
}

export default WarehouseTemplate

const Container = styled.div`
  display: flex;
  position: absolute;
  font-family: 'Lato', sans-serif;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  flex-direction: column;

  input[type='text'] {
    width: 100%;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.5px;
    padding: 12px;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
  }
`

// Table
export const TableList = styled.div`
  background-color: #fff;
  margin-bottom: 8px;
`
export const TableListRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid #ececec;

  &:last-child {
    border-bottom: 1px solid #ececec;
  }
`

export const TableListTitle = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
`

export const TableListItem = styled.div`
  flex-grow: 1;
  color: #a59c9c;
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  letter-spacing: 0.5px;
`

export const TableListSection = styled.div``

export const TableListSectionHeader = styled.div`
  background: #cdcfd0;
  color: black;
  display: flex;
  font-size: 14px;
  padding: 7px 20px;
`

export const TableListSectionHeaderLeftSide = styled.div`
  flex: 1;
`

export const TableListSectionHeaderRightSide = styled.div`
  display: flex;
  justify-content: flex-end;
`

// Font
export const Badge = styled.div`
  color: #252525;
  display: inline-block;
  font-weight: 500;
  letter-spacing: 1.25px;
  border-radius: 3px;
  padding: 1px 3px 1px 3px;
  margin: ${({ noMargin = false }) => (noMargin ? '0' : '0 3px 3px 0')};

  ${({ size = 'normal' }) => badgeSize(size)};
  ${({ intent = 'default' }) => badgeStyle(intent)};

  &.bold {
    font-weight: 600;
  }
`

const badgeStyle = intent => {
  switch (intent) {
    default:
    case 'default':
      return css`
        background-color: #fbebec;
      `
    case 'warning':
      return css`
        background: orange;
      `

    case 'success':
      return css`
        background: #cdffc8;
      `

    case 'neutral':
      return css`
        background: #f8f8f8;
      `
  }
}

const badgeSize = size => {
  switch (size) {
    default:
    case 'default':
      return css`
        font-size: 10px;
      `

    case 'medium':
      return css`
        font-size: 14px;
      `

    case 'big':
      return css`
        font-size: 18px;
      `
  }
}

export const HeadlineOne = styled.div`
  color: #252525;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.25px;
  text-align: center;
`

export const HeadlineThree = styled.div`
  color: #252525;
  flex-grow: 1;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.25px;

  &.bold {
    font-weight: 600;
  }
`

export const HeadlineFour = styled.div`
  color: #252525;
  flex-grow: 1;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.25px;
`

// Grid
export const Grid = styled.div`
  display: flex;

  &.align-items-center {
    align-items: center;
  }
`

export const GridItem = styled.div`
  position: relative;

  &.grow-1 {
    flex-grow: 1;
  }

  &.text-right {
    text-align: right;
  }
`

// Horizontal slider
export const HorizontalSlider = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 10px 20px;
`

export const HorizontalSliderElement = styled.div`
  width: calc(100vw - 60px);
  flex: 0 0 auto;
  padding-right: 10px;

  &:last-child {
    padding-right: 20px;
  }
`

// Header

export const Header = styled.div`
  background-color: #fff;
  margin-bottom: 6px;
`

export const HeaderContainer = styled.div.attrs({ className: 'clearfix' })`
  align-items: center;
  display: flex;
  height: 42px;
  padding: 0 10px;
  position: relative;
`

export const Headline = styled.div`
  color: #252525;
  flex-grow: 1;
  font-size: 12px;
  font-weight: 600;
  left: 0;
  letter-spacing: 1.25px;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1;
`

export const HeaderButton = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: none;
  color: #000;
  font-size: 19px;
  outline: 0;
  padding: 10px;
  position: absolute;
  z-index: 3;
  left: 0;

  ${({ right }) =>
    right
      ? css`
          left: auto;
          right: 0;
        `
      : ''};
`

// Tabs
export const Tabs = styled.div`
  display: flex;
`

export const Tab = styled.div`
  cursor: pointer;
  flex-grow: 1;
  color: #b9b7c7;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  padding: 6px 0;

  &.active {
    color: #000;
    font-weight: 600;
    border-bottom: 2px solid #7963f5;
  }
`

// Card

export const Card = styled.div`
  background-color: #fff;
  margin-bottom: 8px;

  &.no-background {
    background-color: transparent;
  }

  &.rounded-corners {
    border-radius: 4px;
  }
`

export const CardContent = styled.div`
  padding: 8px 15px;

  &.p-0,
  &.py-0,
  &.pt-0 {
    padding-top: 0;
  }

  &.p-0,
  &.py-0,
  &.pb-0 {
    padding-bottom: 0;
  }

  &.p-0,
  &.px-0,
  &.pl-0 {
    padding-left: 0;
  }

  &.p-0,
  &.px-0,
  &.pr-0 {
    padding-right: 0;
  }
`

export const CardTitle = styled.div`
  color: #252525;
  flex-grow: 1;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.25px;
  margin-bottom: 8px;
`

// List
export const List = styled.div`
  margin-bottom: 8px;
`

export const ListItem = styled.div`
  border-top: 1px solid #f0f0f0;
  padding: 5px 15px;

  &:first-child {
    border-top: 0;
  }

  &.lg {
    padding: 10px 15px;
  }
`

export const ListItemSubtitleList = styled.ul`
  font-size: 11px;
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;

  > li:not(:last-child)::after {
    content: '·';
    margin: 0 7px;
  }
`

export const ListItemSubtitleListEntry = styled.li`
  padding: 0;
`

const Content = styled.div`
  flex: 1;
  position: relative;
  overflow-y: scroll;
  background-color: #f8f8fa;
`

const InnerContentContainer = styled.div`
  z-index: 0;
`

const Navigation = styled.div`
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  font-size: 22px;
  height: 50px;
  z-index: 999;
  box-shadow: 0px 0 32px -20px rgba(0, 0, 0, 1);
`

const NavigationItemsContainer = styled.div`
  display: flex;
  flex: 1;
`

const NavigationActiveIndicatorContainer = styled.div`
  background: #f9f9f9;
  border-radius: 3px;
  height: 2px;
`

const NavigationActiveIndicator = styled.div`
  background: #31146a;
  left: ${({ offset }: { offset: number }) => `${offset}px`};
  height: 3px;
  position: absolute;
  transition: left 0.3s ease 0s;
  width: 50px;
  transform: translateX(-50%);
`

const NavigationItemButton = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: none;
  color: ${({ active }: { active: boolean }) =>
    active ? '#31146a' : '#afafaf'};
  display: block;
  flex: 1;
  font-weight: ${({ active }: { active: boolean }) =>
    active ? 'bold' : 'normal'};
  font-size: 20px;
  padding: 0 10px;
  outline: 0;
  width: 50px;
`

const DevPanel = styled.div`
  background: red;
  border-radius: 50px;
  bottom: 70px;
  padding: 5px 10px;
  position: absolute;
  right: 10px;
  z-index: 1000;
`

export const ModalActionsContainer = styled.div`
  display: flex;
  margin-left: -10px;
  padding: 10px;

  > * {
    flex: 1;
    margin-left: 10px;
  }
`
