import {
  CALL_API,
  CALL_API_BATCH,
} from '../../../infrastructure/middleware/api'

export const SAVE_SETTINGS_REQUEST = 'SAVE_SETTINGS_REQUEST'
export const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS'
export const SAVE_SETTINGS_FAILURE = 'SAVE_SETTINGS_FAILURE'

export const SAVE_WEBSHOP_SETTINGS_REQUEST = 'SAVE_WEBSHOP_SETTINGS_REQUEST'
export const SAVE_WEBSHOP_SETTINGS_SUCCESS = 'SAVE_WEBSHOP_SETTINGS_SUCCESS'
export const SAVE_WEBSHOP_SETTINGS_FAILURE = 'SAVE_WEBSHOP_SETTINGS_FAILURE'

export const REQUEST_FETCH_ROLES = 'REQUEST_FETCH_ROLES'
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS'
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE'

export const REQUEST_DELETE_ROLE = 'REQUEST_DELETE_ROLE'
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS'
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE'

export const REQUEST_UPDATE_ROLES = 'REQUEST_UPDATE_ROLES'
export const UPDATE_ROLES_SUCCESS = 'UPDATE_ROLES_SUCCESS'
export const UPDATE_ROLES_FAILURE = 'UPDATE_ROLES_FAILURE'

export const RENAME_ATTRIBUTE_VALUES_REQUEST = 'RENAME_ATTRIBUTE_VALUES_REQUEST'
export const RENAME_ATTRIBUTE_VALUES_SUCCESS = 'RENAME_ATTRIBUTE_VALUES_SUCCESS'
export const RENAME_ATTRIBUTE_VALUES_FAILURE = 'RENAME_ATTRIBUTE_VALUES_FAILURE'

export const GET_TABLET_SETTINGS_REQUEST = 'GET_TABLET_SETTINGS_REQUEST'
export const GET_TABLET_SETTINGS_SUCCESS = 'GET_TABLET_SETTINGS_SUCCESS'
export const GET_TABLET_SETTINGS_FAILURE = 'GET_TABLET_SETTINGS_FAILURE'
export const UPDATE_TABLET_SETTINGS_REQUEST = 'UPDATE_TABLET_SETTINGS_REQUEST'
export const UPDATE_TABLET_SETTINGS_SUCCESS = 'UPDATE_TABLET_SETTINGS_SUCCESS'
export const UPDATE_TABLET_SETTINGS_FAILURE = 'UPDATE_TABLET_SETTINGS_FAILURE'

export const requestSaveSettings = (entityId, settings) => {
  return {
    [CALL_API]: {
      endpoint: `/entities/${entityId}/settings`,
      method: 'PUT',
      body: {
        data: settings,
      },
      types: [
        SAVE_SETTINGS_REQUEST,
        SAVE_SETTINGS_SUCCESS,
        SAVE_SETTINGS_FAILURE,
      ],
    },
  }
}

export const requestSaveWebshopSettings = (entityId, settings) => {
  return {
    [CALL_API]: {
      endpoint: `/webshop/${entityId}/settings`,
      method: 'PUT',
      body: {
        data: settings,
      },
      types: [
        SAVE_WEBSHOP_SETTINGS_REQUEST,
        SAVE_WEBSHOP_SETTINGS_SUCCESS,
        SAVE_WEBSHOP_SETTINGS_FAILURE,
      ],
    },
  }
}

export const fetchRoles = () => ({
  [CALL_API_BATCH]: {
    types: [
      REQUEST_FETCH_ROLES,
      {
        type: FETCH_ROLES_SUCCESS,
        payload: (action, state, responses) => {
          return {
            roles: responses.roles.roles,
            modules: responses.modules,
            users: responses.users.users,
          }
        },
      },
      FETCH_ROLES_FAILURE,
    ],
    actions: [
      {
        key: 'roles',
        action: '/roles',
        method: 'GET',
        data: { includes: ['entityUsers:ids'] },
      },
      { key: 'users', action: '/users', method: 'GET' },
      { key: 'modules', method: 'GET', action: '/roles/modules' },
    ],
  },
})

export const shouldFetchRoles = roles => {
  return Object.keys(roles.roles).length === 0 && !roles.isFetching
}

export const fetchRolesIfNeeded = () => {
  return (dispatch, getState) => {
    const { roles } = getState().settings

    return shouldFetchRoles(roles) ? dispatch(fetchRoles()) : Promise.resolve()
  }
}

export const requestDeleteRole = roleId => {
  return {
    [CALL_API]: {
      endpoint: `/roles/${roleId}`,
      method: 'DELETE',
      types: [REQUEST_DELETE_ROLE, DELETE_ROLE_SUCCESS, DELETE_ROLE_FAILURE],
    },
  }
}

export const requestSaveRoles = roles => {
  return {
    [CALL_API]: {
      endpoint: `/roles`,
      method: 'PUT',
      body: {
        roles,
      },
      types: [REQUEST_UPDATE_ROLES, UPDATE_ROLES_SUCCESS, UPDATE_ROLES_FAILURE],
    },
  }
}

export const renameAttributeValues = changes => {
  return {
    [CALL_API]: {
      endpoint: '/attributes/rename-values',
      method: 'POST',
      body: {
        changes: changes,
      },
      types: [
        RENAME_ATTRIBUTE_VALUES_REQUEST,
        RENAME_ATTRIBUTE_VALUES_SUCCESS,
        RENAME_ATTRIBUTE_VALUES_FAILURE,
      ],
    },
  }
}
