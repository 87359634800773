import * as React from 'react'
import { Formik } from 'formik'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import sortBy from 'lodash/sortBy'

import {
  ControlLabel,
  FormGroup,
  SaveButton,
} from '../../infrastructure/components/Formik'

import ColumnsManager from './ColumnsManager'

const ColumnsManagerModal = ({
  columns,
  columnOptions,
  onHide,
  show,
  onSave,
}) => {
  const submit = (values, { setSubmitting }) => {
    onSave(values.columns)
    setSubmitting(false)
    onHide()
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Formik
        initialValues={{ columns }}
        enableReinitialize
        onSubmit={submit}
        render={({ setFieldValue, handleSubmit, isSubmitting, values }) => {
          const onResetToDefaults = () => {
            const newColumns = sortBy(columnOptions, 'sortOrder').map(
              (option, i) => {
                const column = {
                  key: option.value,
                  show: option.default || false,
                  sortOrder: i,
                }

                if (option.toggleable === false) {
                  column.show = true
                }

                return column
              }
            )

            setFieldValue('columns', newColumns)
          }

          return (
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Manage columns</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormGroup>
                  <ControlLabel>Select &amp; arrange columns</ControlLabel>
                  <ColumnsManager
                    columnOptions={columnOptions}
                    columns={values.columns}
                    onChange={columns => {
                      setFieldValue('columns', columns)
                    }}
                  />
                </FormGroup>
              </Modal.Body>
              <Modal.Footer>
                <ActionsContainer>
                  <LeftSideActions>
                    <button
                      type="button"
                      className="btn btn-white"
                      onClick={onResetToDefaults}
                    >
                      Reset to defaults
                    </button>
                  </LeftSideActions>
                  <RightSideActions>
                    <button
                      type="button"
                      className="btn btn-white"
                      onClick={onHide}
                    >
                      Cancel
                    </button>

                    <SaveButton submitting={isSubmitting}>Save</SaveButton>
                  </RightSideActions>
                </ActionsContainer>
              </Modal.Footer>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default ColumnsManagerModal

const ActionsContainer = styled.div`
  display: flex;
`

const LeftSideActions = styled.div``

const RightSideActions = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  > * {
    margin-left: 10px;
  }
`
