/* @flow */

import * as React from 'react'
import sortBy from 'lodash/sortBy'
import styled from 'styled-components'
import {
  DropdownButton as BootstrapDropdownButton,
  MenuItem,
} from 'react-bootstrap'

import { updateUser } from '../../../../../entities/api'
import { isCustomFieldVisible } from '../../../../../custom-fields/shared'

import { ProductTableContext } from '../../shared'
import {
  refreshSession,
  SessionContext,
  useRefValue,
  mixpanelTrack,
} from '../../../../../shared'

const createAvailabilityModeAction = ({
  availabilityModeState,
  key = 'availability_mode',
  mixpanelEvent,
  setAvailabilityMode,
}) => {
  return {
    key,
    render: ({ brand, tableId }) => {
      return (
        <AvailabilityModeToggleComponent
          availabilityModeState={availabilityModeState}
          brand={brand}
          mixpanelEvent={mixpanelEvent}
          setAvailabilityMode={setAvailabilityMode}
          tableId={tableId}
        />
      )
    },
  }
}

export default createAvailabilityModeAction

const AvailabilityModeToggleComponent = ({
  availabilityModeState,
  brand,
  mixpanelEvent,
  setAvailabilityMode,
  tableId,
}) => {
  const { entity, user } = React.useContext(SessionContext)

  const onToggle = React.useCallback(
    newMode => {
      const newUserProductTableSettings = {
        [tableId]: {
          ...(user.product_table_settings[tableId] || {}),
          availability_mode: newMode,
        },
      }

      setAvailabilityMode(s => ({
        ...s,
        mode: newMode,
      }))

      updateUser(user.id, {
        product_table_settings: newUserProductTableSettings,
      }).then(response => {
        if (!response.error) {
          refreshSession()
        }
      })

      if (mixpanelEvent) {
        mixpanelTrack(mixpanelEvent, {
          tableId,
          mode: newMode,
        })
      }
    },
    [mixpanelEvent, setAvailabilityMode, tableId, user]
  )

  return (
    <DropdownContainer>
      <BootstrapDropdownButton
        id={`${tableId}-availability-mode-toggle`}
        title="Availability mode"
        bsSize="sm"
        bsStyle="white"
      >
        <DropDownOption
          key="delivery_status_and_availability"
          onClick={() => onToggle('delivery_status_and_availability')}
          selected={
            availabilityModeState.mode === 'delivery_status_and_availability'
          }
          label="Show delivery status and availability"
        />
        <DropDownOption
          key="delivery_status"
          onClick={() => onToggle('delivery_status')}
          selected={availabilityModeState.mode === 'delivery_status'}
          label="Show only delivery status"
        />
        <DropDownOption
          key="availability"
          onClick={() => onToggle('availability')}
          selected={availabilityModeState.mode === 'availability'}
          label="Show only availability"
        />
      </BootstrapDropdownButton>
    </DropdownContainer>
  )
}

const DropdownContainer = styled.div`
  .dropdown.open > ul.dropdown-menu {
    width: 260px;
  }

  .dropdown-menu > li > a {
    padding: 3px 6px;
  }
`

const DropDownOption = ({ onClick, selected, label }) => {
  return (
    <li onClick={onClick}>
      <a href="javascript:;">
        <DropdownButtonContainer>
          <Checkmark>
            {selected && <span className="glyphicon glyphicon-ok" />}
          </Checkmark>
          <ColumnName>{label}</ColumnName>
        </DropdownButtonContainer>
      </a>
    </li>
  )
}

const DropdownButtonContainer = styled.div`
  display: flex;
`

const Checkmark = styled.div`
  margin-right: 5px;
  width: 15px;
`

const ColumnName = styled.div`
  flex: 1;
`
