/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const AnonymousNavigation = ({ fullWidth }: NavigationBarProps) => (
  <div>
    <div className="webshop-top-bar">
      <div className={fullWidth ? '' : 'container'}>
        <div className="row">
          <div className="col-md-12">
            <div className="pull-right">
              <AnonymousLinksLabel>
                Already have an account? <Link to="/login">Log in</Link>{' '}
                <Link to="/register">Register new account</Link>
              </AnonymousLinksLabel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default AnonymousNavigation

const AnonymousLinksLabel = styled.div`
  margin: 8px 0;

  a {
    color: white;
    text-decoration: underline;
    padding: 10px 4px;

    &:hover,
    &:active {
      color: #c2c2c2;
    }
  }
`
