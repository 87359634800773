import {
  CALL_API,
  CALL_API_BATCH,
} from '../../../infrastructure/middleware/api'

export const ENTER_WEBSHOP = 'ENTER_WEBSHOP'
export const LEAVE_WEBSHOP = 'LEAVE_WEBSHOP'
export const CHANGE_WEBSHOP_CURRENCY = 'CHANGE_WEBSHOP_CURRENCY'
export const WEBSHOP_TOTALS_REQUEST = 'WEBSHOP_TOTALS_REQUEST'
export const WEBSHOP_TOTALS_SUCCESS = 'WEBSHOP_TOTALS_SUCCESS'
export const WEBSHOP_TOTALS_FAILURE = 'WEBSHOP_TOTALS_FAILURE'
export const WEBSHOP_FAVORITES_REQUEST = 'WEBSHOP_FAVORITES_REQUEST'
export const WEBSHOP_FAVORITES_SUCCESS = 'WEBSHOP_FAVORITES_SUCCESS'
export const WEBSHOP_FAVORITES_FAILURE = 'WEBSHOP_FAVORITES_FAILURE'
export const ADD_PRODUCT_TO_CART_REQUEST = 'ADD_PRODUCT_TO_CART_REQUEST'
export const ADD_PRODUCT_TO_CART_SUCCESS = 'ADD_PRODUCT_TO_CART_SUCCESS'
export const ADD_PRODUCT_TO_CART_FAILURE = 'ADD_PRODUCT_TO_CART_FAILURE'
export const APPROVE_WEBSHOP_ORDER = 'APPROVE_WEBSHOP_ORDER'
export const FETCH_WEBSHOP_LANDING_REQUEST = 'FETCH_WEBSHOP_LANDING_REQUEST'
export const FETCH_WEBSHOP_LANDING_SUCCESS = 'FETCH_WEBSHOP_LANDING_SUCCESS'
export const FETCH_WEBSHOP_LANDING_FAILURE = 'FETCH_WEBSHOP_LANDING_FAILURE'
export const SAVE_WEBSHOP_LANDING_REQUEST = 'SAVE_WEBSHOP_LANDING_REQUEST'
export const SAVE_WEBSHOP_LANDING_SUCCESS = 'SAVE_WEBSHOP_LANDING_SUCCESS'
export const SAVE_WEBSHOP_LANDING_FAILURE = 'SAVE_WEBSHOP_LANDING_FAILURE'
export const GET_WEBSHOP_CURRENCIES_REQUEST = 'GET_WEBSHOP_CURRENCIES_REQUEST'
export const GET_WEBSHOP_CURRENCIES_SUCCESS = 'GET_WEBSHOP_CURRENCIES_SUCCESS'
export const GET_WEBSHOP_CURRENCIES_FAILURE = 'GET_WEBSHOP_CURRENCIES_FAILURE'
export const GET_WEBSHOP_SESSIONS_REQUEST = 'GET_WEBSHOP_SESSIONS_REQUEST'
export const GET_WEBSHOP_SESSIONS_SUCCESS = 'GET_WEBSHOP_SESSIONS_SUCCESS'
export const GET_WEBSHOP_SESSIONS_FAILURE = 'GET_WEBSHOP_SESSIONS_FAILURE'
export const ENSURE_WEBSHOP_SESSION_REQUEST = 'ENSURE_WEBSHOP_SESSION_REQUEST'
export const ENSURE_WEBSHOP_SESSION_SUCCESS = 'ENSURE_WEBSHOP_SESSION_SUCCESS'
export const ENSURE_WEBSHOP_SESSION_FAILURE = 'ENSURE_WEBSHOP_SESSION_FAILURE'
export const UPDATE_WEBSHOP_SESSION_REQUEST = 'UPDATE_WEBSHOP_SESSION_REQUEST'
export const UPDATE_WEBSHOP_SESSION_SUCCESS = 'UPDATE_WEBSHOP_SESSION_SUCCESS'
export const UPDATE_WEBSHOP_SESSION_FAILURE = 'UPDATE_WEBSHOP_SESSION_FAILURE'

// Mixpanel
export const OPEN_QUICK_ORDER = 'OPEN_QUICK_ORDER'
export const ADD_TO_CART_QUICK_ORDER = 'ADD_TO_CART_QUICK_ORDER'
export const ADD_WEBSHOP_FILTER = 'ADD_WEBSHOP_FILTER'
export const ADD_TO_FAVORITES = 'ADD_TO_FAVORITES'
export const OPEN_FAVORITES = 'OPEN_FAVORITES'

export const addWebshopFilter = () => ({
  type: ADD_WEBSHOP_FILTER,
})

export const addToFavorites = () => ({
  type: ADD_TO_FAVORITES,
})

export const addToCartQuickOrder = () => ({
  type: ADD_TO_CART_QUICK_ORDER,
})

export const openQuickOrder = () => ({
  type: OPEN_QUICK_ORDER,
})

export const openFavorites = () => ({
  type: OPEN_FAVORITES,
})

export const enterWebshop = brandId => ({
  type: ENTER_WEBSHOP,
})

export const leaveWebshop = brandId => ({
  type: LEAVE_WEBSHOP,
})

export const changeWebshopCurrency = newCurrency => {
  return {
    type: CHANGE_WEBSHOP_CURRENCY,
    currency: newCurrency,
  }
}

export const fetchWebshopTotals = (brandId, currency, webshopSessionId) => ({
  [CALL_API]: {
    endpoint: `/cart/${brandId}/totals`,
    method: 'GET',
    query: {
      currency,
      webshop_session_id: webshopSessionId,
    },
    types: [
      WEBSHOP_TOTALS_REQUEST,
      WEBSHOP_TOTALS_SUCCESS,
      WEBSHOP_TOTALS_FAILURE,
    ],
  },
})

export const fetchWebshopFavorites = (brandId, currency) => ({
  [CALL_API]: {
    endpoint: `/webshop/${brandId}/favorites/`,
    method: 'GET',
    query: {
      currency,
    },
    types: [
      WEBSHOP_FAVORITES_REQUEST,
      WEBSHOP_FAVORITES_SUCCESS,
      WEBSHOP_FAVORITES_FAILURE,
    ],
  },
})

export const getWebshopCurrencies = entityId => ({
  [CALL_API]: {
    endpoint: `/webshop/${entityId}/currencies`,
    method: 'GET',
    types: [
      GET_WEBSHOP_CURRENCIES_REQUEST,
      GET_WEBSHOP_CURRENCIES_SUCCESS,
      GET_WEBSHOP_CURRENCIES_FAILURE,
    ],
  },
})

export const requestAddProductToCart = (brandId, data) => ({
  [CALL_API]: {
    endpoint: `/cart/${brandId}`,
    method: 'PUT',
    body: {
      data: [data],
    },
    types: [
      ADD_PRODUCT_TO_CART_REQUEST,
      ADD_PRODUCT_TO_CART_SUCCESS,
      ADD_PRODUCT_TO_CART_FAILURE,
    ],
  },
})

export const approveWebshopOrder = () => ({
  type: APPROVE_WEBSHOP_ORDER,
})

export const fetchWebshopLanding = brandId => ({
  [CALL_API_BATCH]: {
    types: [
      FETCH_WEBSHOP_LANDING_REQUEST,
      FETCH_WEBSHOP_LANDING_SUCCESS,
      FETCH_WEBSHOP_LANDING_FAILURE,
    ],
    actions: [
      { key: 'settings', action: `/webshop/${brandId}/landing`, method: 'GET' },
      { key: 'templates', action: '/webshop/landing-templates', method: 'GET' },
    ],
  },
})

export const saveWebshopLanding = (settings, layout) => ({
  [CALL_API]: {
    endpoint: '/webshop/landing',
    method: 'PUT',
    body: {
      settings,
      layout,
    },
    types: [
      SAVE_WEBSHOP_LANDING_REQUEST,
      SAVE_WEBSHOP_LANDING_SUCCESS,
      SAVE_WEBSHOP_LANDING_FAILURE,
    ],
  },
})

export const ensureWebshopSession = (brandId, extraData) => ({
  [CALL_API]: {
    endpoint: `/webshop/${brandId}/sessions`,
    method: 'POST',
    body: {
      extra_data: extraData,
    },
    types: [
      ENSURE_WEBSHOP_SESSION_REQUEST,
      ENSURE_WEBSHOP_SESSION_SUCCESS,
      ENSURE_WEBSHOP_SESSION_FAILURE,
    ],
  },
})

export const getWebshopSessions = () => ({
  [CALL_API]: {
    endpoint: '/webshop/sessions',
    method: 'GET',
    types: [
      GET_WEBSHOP_SESSIONS_REQUEST,
      GET_WEBSHOP_SESSIONS_SUCCESS,
      GET_WEBSHOP_SESSIONS_FAILURE,
    ],
  },
})

export const updateWebshopSession = (brandId, id, data) => ({
  [CALL_API]: {
    endpoint: `/webshop/${brandId}/sessions/${id}`,
    method: 'PUT',
    body: {
      session: data,
    },
    types: [
      UPDATE_WEBSHOP_SESSION_REQUEST,
      UPDATE_WEBSHOP_SESSION_SUCCESS,
      UPDATE_WEBSHOP_SESSION_FAILURE,
    ],
  },
})
