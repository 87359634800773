/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import type { ColumnConfig } from '../../types'

type Options = {
  editable?: boolean,
  editProperty?: string,
  key: string,
  label: string,
  rows: Array<string>,
}

const checkbox: (options: Options) => ColumnConfig = ({
  editable = false,
  editProperty,
  key,
  label,
  rows,
  ...rest
}) => ({
  editable: editable,
  edit_property: editProperty || key,
  key: key,
  label: label,
  rows: rows,
  split_by_values: true,
  render: ({ editProperty, editable, onColumnValueChange, value }) => {
    if (!editable) {
      return value[0] === true ? 'Yes' : 'No'
    }

    return (
      <input
        type="checkbox"
        checked={value[0]}
        onChange={() => {
          onColumnValueChange({
            [editProperty]: value[0] === true ? false : true,
          })
        }}
      />
    )
  },
  value: ({ line }) => {
    return line ? [line[key]] : [false]
  },
  ...rest,
})

export default checkbox
