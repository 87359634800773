/* @flow */

import React from 'react'
import uuid from 'uuid'
import merge from 'lodash/merge'
import immer from 'immer'

import { createColumnKey } from './shared'

import type {
  ColumnConfig,
  UserSplitReducerState,
  UserSplit,
  UserSplitAction,
} from '../../types'

export const useSplits = (
  columns: Array<ColumnConfig>,
  initialEditColumn: boolean = false,
  initialSplits: Array<UserSplit> = []
) => {
  const reducerFunction = React.useCallback(
    (state: UserSplitReducerState, action: UserSplitAction) => {
      return splitsReducer(columns, state, action)
    },
    [columns]
  )

  return React.useReducer<UserSplitReducerState, UserSplitAction>(
    reducerFunction,
    {
      editColumn: initialEditColumn,
      splits: initialSplits,
    }
  )
}

export const splitsReducer = (
  columns: Array<ColumnConfig>,
  state: UserSplitReducerState,
  action: UserSplitAction
) => {
  const splittableColumns = columns.filter(c => c.split_by_values)

  switch (action.type) {
    case 'delete':
      return {
        ...state,
        splits: state.splits.filter(
          s =>
            s.editableVariants !== action.row.editable_variants ||
            s.rowKey !== action.row.key ||
            s.columnKey !== action.row.columnKey ||
            s.subSectionKey !== action.subSectionKey
        ),
      }
    case 'cancel_edit_column':
      return {
        editColumn: false,
        splits: state.splits,
      }
    case 'edit_column':
      return {
        editColumn: {
          columnKey: action.column.key,
          rowId: action.row.id,
        },
        splits: state.splits,
      }
    case 'split':
      const columnValues = action.row.columnValues

      // When dealing with splits its only the value of the split_by_values
      // columns that are actually used
      const columnValuesOfSplittableColumns = {}
      for (let column of splittableColumns) {
        columnValuesOfSplittableColumns[column.key] = columnValues[column.key]
      }

      const newSplit = {
        assortmentVariant: action.row.assortmentVariant,
        assortmentVariantId: action.row.assortmentVariantId,
        column: action.column.key,
        columnKey: createColumnKey(columnValuesOfSplittableColumns),
        columnValues: columnValuesOfSplittableColumns,
        draft: true,
        editableVariants: action.row.editable_variants,
        id: uuid(),
        isAssortmentRow: action.row.isAssortmentRow,
        rowKey: action.row.key,
        subSectionKey: action.subSectionKey,
        verticalAttributeKey: action.verticalAttributeKey,
      }

      let newEditColumn = action.column.split_by_values
        ? action.column
        : splittableColumns[0]
      if (newEditColumn) {
        newEditColumn = {
          columnKey: newEditColumn.key,
          rowId: newSplit.id,
        }
      }

      return {
        editColumn: newEditColumn,
        splits: [...state.splits, newSplit],
      }
    case 'update':
      const splitIndex = state.splits.findIndex(s => s.id === action.id)

      if (splitIndex === -1) {
        return state
      }

      const updatedState = immer(state.splits, updatedSplits => {
        const split = updatedSplits[splitIndex]

        for (let [k, v] of Object.entries(action.data.columnValues)) {
          split.columnValues[k] = v
        }

        split.columnKey = createColumnKey(split.columnValues)

        if (split.columnValues[split.column]) {
          split.draft = false
        }
      })

      return {
        editColumn: false,
        splits: updatedState,
      }
    default:
      return state
  }
}
