/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import type { RowConfig } from '../../types'

type Options = {
  label?: string,
}

const sku: (options: Options) => RowConfig = ({ label = 'SKU' }) => ({
  data_source: '__variant',
  key: 'sku',
  label,
  render: ({ value, config }) => {
    if (!value) {
      return null
    }

    // When rendering matrix product table, we want to make sure that the row is not cut off,
    // so the SKUs might need to get broken to multiple lines. When using traditional, on the
    // other hand, we always want to have SKUs on one line as there should be enough horizontal
    // space and brands were asking for that non-breaking SKU.
    const isMatrix = config.horizontalAttribute !== undefined
    const style = isMatrix
      ? { wordBreak: 'break-all' }
      : { whiteSpace: 'nowrap' }

    return <span style={style}>{value}</span>
  },
})

export default sku
