/* @flow */

// REMOTE DATA CONFIGURATION
// =========================
// The proposed configuration has 3 parameters:
// * data_level
// * listen
// * change_scope
//
// data_level: What level of the table is this data for? Three possible values: table, product, variant
// =============
// variant: the values are per variant, e.g. inventory status
// product: the values are per product. This could be something like total sold pieces, or product-wide prices etc.
// table:   the values cover the whole table, this is probably only used for totals
//
// listen: When should we update its current value?
// =============
// lines:   we should fetch the values whenever a line updates or a new line is added
// variant: we should fetch the values whenever a variant updates or a new variant is added
// product: we should fetch the values whenever a product updates or a new product is added
//
// change_scope: at what scope should a change ripple?
// this can be used for instance if variant prices are a function of total product quantity.
// the default value is same as the listen parameter, e.g. if a line changes it has the scope of the line/variant
// =============
// product: a variant-level listen change should cause the rest of the product's variants to refetch
// table:   a variant- or product-level listen change should cause the rest of the table's products to refetch

export const createDataFetchPromises = ({
  dataConfigs,
  dataContext,
  dataCache,
  lines,
  products,
}) => {
  // 1. Determine what have changed, this could be divided into
  //    1. New/updated lines
  //    2. New/updated products
  //    3. New/updated variants
  //
  // 2. Determine what should be re-fetched based on what changed
  //
  // 3. IF, promise has not been cancelled, write data to data cache,
  //    and databyproduct object

  const linesThatShouldFetch = []
  const productsThatShouldFetch = []
  const variantsThatShouldFetch = []

  dataCache.purgeCacheIfDataContextChanged(dataContext)

  for (let line of lines) {
    if (!dataCache.hasFetchedDataForLine(line)) {
      linesThatShouldFetch.push(line)
      dataCache.setHasFetchedDataForLine(line)
    }
  }

  for (let product of products) {
    if (!dataCache.hasFetchedDataForProduct(product)) {
      productsThatShouldFetch.push(product)
      dataCache.setHasFetchedDataForProduct(product)
    }

    for (let variant of product.variants) {
      if (!dataCache.hasFetchedDataForVariant(variant)) {
        variantsThatShouldFetch.push(variant)
        dataCache.setHasFetchedDataForVariant(variant)
      }
    }
  }

  dataCache.setLastDataContext(dataContext)

  const newPromises = []

  for (let [dataKey, dataConfig] of Object.entries(dataConfigs)) {
    let shouldFetch = false

    if (
      linesThatShouldFetch.length > 0 &&
      dataConfig.listen_for_line_changes === true
    ) {
      shouldFetch = true
    } else if (productsThatShouldFetch.length > 0) {
      shouldFetch = true
    } else if (variantsThatShouldFetch.length > 0) {
      shouldFetch = true
    }

    if (!shouldFetch) {
      continue
    }

    const missingLines = []
    const missingProducts = []
    const missingConfigVariants = []
    const missingVariants = []
    const addedLines = new WeakSet()
    const addedProducts = new WeakSet()
    const addedVariants = new WeakSet()
    const addedConfigVariants = new WeakSet()
    const variantIds = new Set()

    const addVariant = variant => {
      if (variant.__placeholder_variant) {
        if (!addedConfigVariants.has(variant)) {
          missingConfigVariants.push(variant)
          addedConfigVariants.add(variant)
        }
      } else {
        if (!addedVariants.has(variant)) {
          missingVariants.push(variant)
          addedVariants.add(variant)
        }
      }

      variantIds.add(variant.id)
    }

    for (let line of linesThatShouldFetch) {
      missingLines.push(line)
      addedLines.add(line)

      variantIds.add(line.variant_id)
    }
    for (let product of productsThatShouldFetch) {
      missingProducts.push(product)
      addedProducts.add(product)

      for (let variant of product.variants) {
        addVariant(variant)
      }
    }
    for (let variant of variantsThatShouldFetch) {
      addVariant(variant)
    }

    if (dataConfig.change_scope) {
      switch (dataConfig.change_scope) {
        case 'product':
          const productIdsOfUpdatedVariantsOrLines = new Set()

          for (let line of linesThatShouldFetch) {
            productIdsOfUpdatedVariantsOrLines.add(line.product_id)
          }
          for (let variant of variantsThatShouldFetch) {
            productIdsOfUpdatedVariantsOrLines.add(variant.product_id)
          }

          for (let productId of productIdsOfUpdatedVariantsOrLines) {
            const linesOfProduct = lines.filter(l => l.product_id == productId)

            for (let line of linesOfProduct) {
              if (!addedLines.has(line)) {
                missingLines.push(line)
                addedLines.add(line)
              }
            }

            const product = products.find(p => p.id == productId)

            if (!addedProducts.has(product)) {
              missingProducts.push(product)
              addedProducts.add(product)
            }

            for (let variant of product.variants) {
              addVariant(variant)
            }
          }

          break
      }
    }

    const variantKeys = Array.from(variantIds).map(id => `${dataKey}-${id}`)
    const promiseKey = `${dataKey}-${JSON.stringify(variantKeys)}`

    const event = {
      missing_config_variants: missingConfigVariants,
      missing_lines: missingLines,
      missing_products: missingProducts,
      missing_variants: missingVariants,
    }

    const promise = dataConfig.resolve({
      context: dataContext,
      event: event,
      lines,
    })

    newPromises.push({
      dataKey,
      event,
      promiseKey,
      promise,
      variantKeys,
    })
  }

  return newPromises
}
