import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { createAction } from '../shared'
import {
  IsBrandPredicate,
  IsNotB2COrderPredicate,
  CanDiscountProductsPredicate,
  UnlockedOrderPredicate,
} from './shared'

import { SelectWrapper } from '../../../../../infrastructure/components/Forms'

const claimTypeOptions = [
  { label: 'Return', value: 'return' },
  { label: 'Claim', value: 'claim' },
]

const ClaimTypeAction = ({ callback, extraPropsByProduct = {}, product }) => {
  const productProps = extraPropsByProduct[product.id]
  const value = productProps ? productProps.claim_type : 'return'

  return (
    <div style={{ minWidth: '120px' }}>
      <SelectWrapper
        clearable={false}
        onChange={type => callback(type)}
        options={claimTypeOptions}
        simpleValue
        value={value}
      />
    </div>
  )
}

const createClaimTypeAction = () => {
  return createAction(
    (type, { product, lines }, { setExtraPropsByProduct }) => {
      return setExtraPropsByProduct(product.id, { claim_type: type })
    },
    ClaimTypeAction,
    [],
    { size: 'xs' }
  )
}

export default createClaimTypeAction

const Button = styled.span`
  font-weight: bold;
`
