/* @flow */

export const linePriceExtractor = line => {
  return {
    // PO lines do not have price_before_discount, however we need it for the unit price column component
    // Normally we just use unit_price, but this creates a problem when you split an existing line into
    // a new price. This is because the "split row" will get price_before_discount from the original line,
    // and then when you enter a quantity the new line is normally created without price_before_discount.
    // The end result is 3 lines instead of 2. This is a workaround to fix that. So `price_before_discount`
    // should only exist on "new lines created from existing line split". Otherwise it should be `unit_price`.
    price_before_discount: line.price_before_discount || line.unit_price,
    discount_amount: line.discount_amount || 0,
    discount_percentage: line.discount_percentage || 0,
    net_price: line.unit_price,
    discount_method: line.discount_method,
  }
}
