/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import type { RowConfig } from '../../types'

type Options = {
  colli_not_included_in_value?: boolean,
  editable?: boolean,
  enable_colli?: boolean,
  expand_assortments?: boolean,
  label?: string,
}

const quantity: (options: Options) => RowConfig = ({
  colli_not_included_in_value = false,
  editable = false,
  enable_colli = false,
  expand_assortments = false,
  label = 'Quantity',
}) => ({
  colli_not_included_in_value,
  editable,
  enable_colli,
  expand_assortments,
  key: 'quantity',
  label,
})

export default quantity
