/* @flow */

import * as React from 'react'

import ProductTableRowsDeliveryList from './Components/ProductTableRowsDeliveryList'
import InventoryIndicator from './InventoryIndicator'

import type { ColumnConfig } from '../../types'

type Options = {
  isCustomerFacingDeliveryStatus?: boolean,
}

const availability: (options: Options) => ColumnConfig = ({
  defaultRender,
  label = 'Available',
  isCustomerFacingDeliveryStatus = false,
}) => ({
  data_source: 'availability',
  key: 'availability',
  label,
  render: ({
    brand,
    context,
    data,
    onClearDataCache,
    lines,
    variant,
    value,
    userEntity,
  }) => {
    return (
      <AvailabilityStatus
        brand={brand}
        context={context}
        data={data}
        defaultRender={defaultRender}
        isCustomerFacingDeliveryStatus={isCustomerFacingDeliveryStatus}
        lines={lines}
        onClearDataCache={onClearDataCache}
        userEntity={userEntity}
        value={value}
        variant={variant}
      />
    )
  },
  style: ({ matrix }) => {
    const style = {}

    if (matrix) {
      style.verticalAlign = 'top'
    }
    if (!isCustomerFacingDeliveryStatus) {
      style.width = '150px'
    }

    return style
  },
})

export default availability

const AvailabilityStatus = ({
  brand,
  defaultRender,
  isCustomerFacingDeliveryStatus,
  value,
  userEntity,
}) => {
  const status = value ? value[0] : null

  const available = React.useMemo(() => {
    if (!status) {
      return 0
    }

    return status.immediately_available + status.available_from_incoming
  }, [status])

  const inventoryIndicatorRule = React.useMemo(() => {
    if (!isCustomerFacingDeliveryStatus) {
      return null
    }

    return brand.settings.stock_settings.inventory_indicator_colors.find(
      rule => {
        const from = parseInt(rule.from)
        const to = parseInt(rule.to)

        if (from && to) {
          return from <= available && to >= available
        } else if (from) {
          return from <= available
        } else {
          return to >= available
        }
      }
    )
  }, [
    brand.settings.stock_settings.inventory_indicator_colors,
    available,
    isCustomerFacingDeliveryStatus,
  ])

  if (!status) {
    if (defaultRender) {
      return defaultRender()
    }

    return null
  }

  if (status.deliveries.length === 0 && !status.lead_time) {
    return null
  }

  if (isCustomerFacingDeliveryStatus && inventoryIndicatorRule) {
    return <InventoryIndicator brand={brand} rule={inventoryIndicatorRule} />
  }

  return (
    <ProductTableRowsDeliveryList
      brand={brand}
      isCustomerFacingDeliveryStatus={isCustomerFacingDeliveryStatus}
      status={status}
      userEntity={userEntity}
    />
  )
}
