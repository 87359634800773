// @flow

import * as React from 'react'
import styled from 'styled-components'
import memo from 'memoize-one'

export const WebshopContext = React.createContext({
  brand: null,
  currency: null,
  customer: null,
  favorites: [],
  webshopDesignSettings: null,
  webshopSession: null,
})

export const DEFAULT_LIMIT = 16
export const DEFAULT_PAGE = 1
export const DEFAULT_DIRECTION = 'asc'
export const DEFAULT_FILTER_VALUES = {
  subbrands: [],
  collections: [],
  categories: [],
  drops: [],
}

export const DEFAULT_CONTROLS = {
  limit: DEFAULT_LIMIT,
}

export const Loader = ({ children }: { children: React.Node }) => (
  <div className="loader-wrapper">
    <div className="loader loader-mini">
      <div className="flipper">
        <div className="front" />
        <div className="back" />
      </div>
    </div>
    <div className="loader-title loader-mini-title">{children}</div>
  </div>
)

export const NAVIGATION_LINK_TYPE_SEARCH = 'search'
export const NAVIGATION_LINK_TYPE_PAGE = 'page'
export const NAVIGATION_LINK_TYPE_BESTSELLERS = 'bestsellers'
export const NAVIGATION_LINK_TYPE_FILE_BANK = 'file_bank'
export const NAVIGATION_LINK_TYPE_CAMPAIGN_PDF = 'campaign_pdf'
export const NAVIGATION_LINK_TYPE_SPRII = 'sprii'

export const navigationLinkAvailableSortOptions = [
  'bestsellers',
  'newest',
  'newarrivals',
  'name_asc',
  'name_desc',
  'price_asc',
  'price_desc',
  'number_asc',
  'number_desc',
]

export const allSortOptions = [
  { value: 'score', label: 'Relevant' },
  { value: 'newest', label: 'Newest' },
  { value: 'newarrivals', label: 'New arrivals' },
  { value: 'bestsellers', label: 'Bestsellers' },
  { value: 'name_asc', label: 'Name, A to Z' },
  { value: 'name_desc', label: 'Name, Z to A' },
  { value: 'price_asc', label: 'Price, low to high' },
  { value: 'price_desc', label: 'Price, high to low' },
  { value: 'number_asc', label: 'Item number, low to high' },
  { value: 'number_desc', label: 'Item number, high to low' },
]

export const TopBarPortalButton = styled.button`
  border: none;
  border-radius: 0;
  background: transparent;
  border-left: 1px solid #3e4149;
  border-right: 1px solid #3e4149;
  color: white;
  font-size: 12px;
  height: 35px;

  &:hover,
  &:active,
  &:focus {
    background: transparent;
    color: white;
  }
`

export const createDefaultFilterValues = memo(filterOptions => {
  const defaultValues = {
    ...DEFAULT_FILTER_VALUES,
  }

  for (let key in filterOptions) {
    if (/^attribute\./.test(key)) {
      defaultValues[key] = []
    } else if (/^product_custom_field\./.test(key)) {
      defaultValues[key] = []
    } else if (/^variant_custom_field\./.test(key)) {
      defaultValues[key] = []
    }
  }

  return defaultValues
})

export const combineFixedFiltersWithFilterValues = (
  fixedFilters,
  filterValues
) => {
  const filters = Object.assign({}, fixedFilters)

  // For collections and subbrands, we can safely replace the filtering
  // from link configuration with the one from user if there is any,
  // because it will always result in subset of products.
  if (filterValues.collections && filterValues.collections.length > 0) {
    filters.collections = filterValues.collections
  }
  if (filterValues.drops && filterValues.drops.length > 0) {
    filters.drops = filterValues.drops
  }
  if (filterValues.drop_deliveries && filterValues.drop_deliveries.length > 0) {
    filters.drop_deliveries = filterValues.drop_deliveries
  }
  if (filterValues.subbrands && filterValues.subbrands.length > 0) {
    filters.subbrands = filterValues.subbrands
  }

  for (let filterKey in filterValues) {
    if (
      /^attribute\.[^\.]+$/.test(filterKey) ||
      /^product_custom_field\.[^\.]+$/.test(filterKey) ||
      /^variant_custom_field\.[^\.]+$/.test(filterKey) ||
      /^noos$/.test(filterKey)
    ) {
      if (Array.isArray(filterValues[filterKey])) {
        if (filterValues[filterKey].length > 0) {
          filters[filterKey] = filterValues[filterKey]
        }
      } else if (filterValues[filterKey]) {
        filters[filterKey] = filterValues[filterKey]
      }
    }
  }

  // If user is filtering by webshop categories and there are also some
  // categories in link configuration, we need to make sure the both
  // filtering is respected by adding them into AND clause
  const filterValuesCategories = filterValues.categories || []
  if (
    filterValuesCategories.length > 0 &&
    filters.categories &&
    filters.categories.length > 0
  ) {
    filters.categories = {
      AND: [filters.categories, filterValuesCategories],
    }
  } else if (filterValuesCategories.length > 0) {
    filters.categories = filterValuesCategories
  }

  return filters
}

export const createCartPersistenceKey = row => {
  return `${row.variant_id}-${row.batch}-${row.drop_id}-${row.drop_delivery_id}`
}
