/* @flow */

import * as React from 'react'
import styled, { css } from 'styled-components'
import { Formik, Field } from 'formik'
import { NavLink } from 'react-router-dom'
import { Glyphicon } from 'react-bootstrap'

import CloudinaryResource from '../../../../infrastructure/components/CloudinaryResource'
import MobileNavigation from '../../../../infrastructure/components/Mobile/MobileNavigation'

import { FiX, FiChevronLeft, FiChevronRight } from 'react-icons/fi'

import {
  NAVIGATION_LINK_TYPE_SEARCH,
  NAVIGATION_LINK_TYPE_PAGE,
  NAVIGATION_LINK_TYPE_BESTSELLERS,
  NAVIGATION_LINK_TYPE_FILE_BANK,
} from '../../shared'

type Props = {
  handleSearchSubmit: Function,
  searchQuery: string,
}

const WebshopMobileNavigation = ({
  brand,
  handleSearchSubmit,
  navigationLinks,
  onHide,
  onShow,
  searchQuery,
  shopUrl,
  show,
}: Props) => {
  return (
    <MobileNavigationContainer>
      <MobileActionsContainer>
        <MobileNavigationActivator onClick={onShow}>
          <i className="fa fa-bars" />
        </MobileNavigationActivator>

        <Formik
          enableReinitialize
          initialValues={{
            query: searchQuery,
          }}
          onSubmit={values => {
            if (values.query !== '') {
              handleSearchSubmit(values.query)
            }
          }}
          render={({ values, handleSubmit, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <MobileNavigationQueryContainer>
                  <QueryInput
                    onChange={e => setFieldValue('query', e.target.value)}
                    value={values.query}
                  />
                </MobileNavigationQueryContainer>
                {values.query && values.query.length > 0 && (
                  <QueryClearButton onClick={() => setFieldValue('query', '')}>
                    <FiX />
                  </QueryClearButton>
                )}
              </form>
            )
          }}
        />
      </MobileActionsContainer>
      <NavigationContainer>
        <MobileNavigation
          header={
            <>
              {brand.logo !== null && (
                <CloudinaryResource
                  brandId={brand.id}
                  id={brand.logo}
                  presets="logo_webshop_mobile_navigation"
                />
              )}

              <div className="close" onClick={onHide}>
                <FiX size={16} style={{ verticalAlign: 'middle' }} />
              </div>
            </>
          }
          onHide={onHide}
          show={show}
          wrapperClass="normal-scroll"
        >
          <MobileNavigation.ItemList>
            <li key={0}>
              <NavLink to={shopUrl} activeClassName="active" onClick={onHide}>
                <span>Home</span>
                <FiChevronRight size={12} style={{ verticalAlign: 'middle' }} />
              </NavLink>
            </li>

            {navigationLinks.map(link => {
              let href = shopUrl
              if (
                link.type === NAVIGATION_LINK_TYPE_SEARCH ||
                link.type === NAVIGATION_LINK_TYPE_BESTSELLERS
              ) {
                href = `${shopUrl}/search/${link.id}`
              } else if (link.type === NAVIGATION_LINK_TYPE_PAGE) {
                href = `${shopUrl}/page/${link.settings.page_id}`
              } else if (link.type === 'linesheet') {
                href = `${shopUrl}/linesheet/${link.linesheet_public_id}`
              } else if (link.type === NAVIGATION_LINK_TYPE_FILE_BANK) {
                href = `/file-bank/${brand.id}`
              }

              return (
                <li key={link.id}>
                  <NavLink to={href} activeClassName="active" onClick={onHide}>
                    <span>{link.text}</span>
                    <FiChevronRight
                      size={12}
                      style={{ verticalAlign: 'middle' }}
                    />
                  </NavLink>
                </li>
              )
            })}

            <li>
              <NavLink to="/" activeClassName="active" onClick={onHide}>
                <span>Back to Traede</span>
                <FiChevronLeft size={12} style={{ verticalAlign: 'middle' }} />
              </NavLink>
            </li>
          </MobileNavigation.ItemList>
        </MobileNavigation>
      </NavigationContainer>
    </MobileNavigationContainer>
  )
}

export default WebshopMobileNavigation

const NavigationContainer = styled.nav.attrs({
  className: 'webshop-main-navigation-mobile',
})`
  ${MobileNavigation.ItemList} {
    text-transform: uppercase;
  }
`

const MobileNavigationContainer = styled.div``

const MobileActionsContainer = styled.div`
  display: flex;

  form {
    flex: 1;
    display: flex;
  }
`

const MobileNavigationActivator = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: 0;
  color: black;
  outline: 0;
  font-size: 26px;
  padding: 5px;
`

const MobileNavigationQueryContainer = styled.div`
  flex: 1;
  margin-left: 20px;
`

const QueryInput = styled.input.attrs({
  placeholder: 'Search',
  'aria-label': 'Search',
  type: 'search',
})`
  &[type='text'],
  &[type='search'] {
    border: none;
    border-bottom: 2px solid black;
    font-family: 'Proxima Nova', 'proxima-nova', sans-serif;
    font-size: 16px;
    width: 100%;
  }
`

const QueryClearButton = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: 0;
  color: black;
  outline: 0;
  font-size: 26px;
  padding: 5px;
`
