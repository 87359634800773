/* @flow */

import * as React from 'react'

const createSelectAllProductAction = ({
  key = 'product_select_all',
  set_property = 'quantity',
  source_property,
}) => {
  return {
    key,
    render: ({ lines, updateLines }) => {
      const onClick = () => {
        const updatedLines = []
        for (let line of lines) {
          updatedLines.push({
            ...line,
            [set_property]: line[source_property],
          })
        }

        updateLines({
          lines: updatedLines,
          type: 'update',
        })
      }

      return (
        <button
          type="button"
          className="btn btn-white btn-xs"
          onClick={onClick}
        >
          All
        </button>
      )
    },
  }
}

export default createSelectAllProductAction
