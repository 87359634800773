/* @flow */

import React from 'react'
import { ButtonGroup, Button } from 'react-bootstrap'
import { Field, type FieldProps } from 'formik'

import { ErrorLabel } from '../Forms'

type Props = {
  bsSize?: string,
  disabled?: boolean,
  falseLabel?: string,
  trueLabel?: string,
  falseValue?: mixed,
  trueValue?: mixed,
}

const BooleanInput = (props: Props) => {
  return <Field component={renderBooleanInput} {...props} />
}

export default BooleanInput

export const renderBooleanInput = (props: FieldProps & Props) => {
  const {
    field: { name, value },
    form: { setFieldValue, touched, errors },
    bsSize,
    disabled = false,
    falseLabel = 'No',
    trueLabel = 'Yes',
    falseValue = false,
    trueValue = true,
  } = props

  return (
    <div>
      <ButtonGroup bsSize={bsSize}>
        <Button
          disabled={disabled}
          bsStyle={value === falseValue || value === '' ? 'success' : ''}
          onClick={() => setFieldValue(name, falseValue)}
        >
          {falseLabel}
        </Button>
        <Button
          disabled={disabled}
          bsStyle={value === trueValue ? 'success' : ''}
          onClick={() => setFieldValue(name, trueValue)}
        >
          {trueLabel}
        </Button>
      </ButtonGroup>
      {touched[name] && errors[name] && <ErrorLabel>{errors[name]}</ErrorLabel>}
    </div>
  )
}
