import {
  REQUEST_FETCH_ROLES,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILURE,
  REQUEST_UPDATE_ROLES,
  UPDATE_ROLES_SUCCESS,
  UPDATE_ROLES_FAILURE,
} from '../actions/index'
import _ from 'underscore'

const initialState = {
  roles: {
    isFetching: false,
    isSaving: false,
    roles: [],
    users: [],
    modules: [],
  },
}

export default function settings(state = initialState, action) {
  switch (action.type) {
    case REQUEST_FETCH_ROLES:
      return Object.assign({}, state, {
        roles: Object.assign({}, state.roles, {
          isFetching: true,
        }),
      })
    case FETCH_ROLES_SUCCESS:
      return Object.assign({}, state, {
        roles: Object.assign({}, state.roles, {
          isFetching: false,
          roles: _.indexBy(action.payload.roles, 'id'),
          users: action.payload.users,
          modules: action.payload.modules,
        }),
      })
    case FETCH_ROLES_FAILURE:
      return Object.assign({}, state, {
        roles: Object.assign({}, state.roles, {
          isFetching: false,
        }),
      })
    case REQUEST_UPDATE_ROLES:
      return Object.assign({}, state, {
        roles: Object.assign({}, state.roles, {
          isSaving: true,
        }),
      })
    case UPDATE_ROLES_SUCCESS:
    case UPDATE_ROLES_FAILURE:
      return Object.assign({}, state, {
        roles: Object.assign({}, state.roles, {
          isSaving: false,
        }),
      })
    default:
      return state
  }
}
