/* @flow */

import * as React from 'react'
import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  DropdownButton as BootstrapDropdownButton,
  MenuItem,
} from 'react-bootstrap'
import styled from 'styled-components'

type Value = string | number

type Props = {
  buttonBsSize: string,
  children?: React.Node,
  id: string,
  multi?: boolean,
  onChange: (value: Array<Value> | Value) => void,
  options: Array<{ label: string, value: Value }>,
  selected: Array<Value> | Value,
  title: string,
}

export class CheckmarkDropdown extends PureComponent<Props, void> {
  static defaultProps = {
    buttonBsSize: 'small',
    multi: true,
    selected: [],
  }

  render() {
    const { children, id, buttonBsSize, multi, selected, title } = this.props

    const buttonProps: { [string]: string } = {
      bsStyle: 'white',
      title,
      id,
    }

    // When buttonBsSize is 'default', we won't pass anything to
    // Bootstrap's DropdownButton, because it does not have any
    // explicit size for it
    if (buttonBsSize !== 'default') {
      buttonProps.bsSize = buttonBsSize
    }

    return (
      <DropdownContainer>
        <BootstrapDropdownButton {...buttonProps}>
          {React.Children.map(children, child => {
            return child
              ? React.cloneElement(child, {
                  multi: child.props.controlled ? child.props.multi : multi,
                  selected: child.props.controlled
                    ? child.props.selected
                    : selected,
                  toggle: child.props.controlled
                    ? child.props.toggle
                    : this._toggleColumn,
                })
              : null
          })}
        </BootstrapDropdownButton>
      </DropdownContainer>
    )
  }

  _toggleColumn = (key: Value) => {
    const { multi, onChange } = this.props

    if (multi) {
      // $FlowIssue
      const currentValue: Array<Value> = this.props.selected
      const newValue = [...currentValue]
      const index = newValue.indexOf(key)

      if (index === -1) {
        newValue.push(key)
      } else {
        newValue.splice(index, 1)
      }

      onChange(newValue)
    } else {
      onChange(key)
    }
  }
}

type CheckmarkDropdownButtonProps = {
  label: string | React.Node,
  multi?: boolean,
  selected?: Array<Value> | Value,
  toggle?: (value: Value) => void,
  value: Value,
}

export const CheckmarkDropdownButton = ({
  label,
  multi,
  selected,
  toggle,
  value,
}: CheckmarkDropdownButtonProps) => {
  return (
    <li onClick={() => toggle(value)}>
      <a href="javascript:;">
        <DropdownButtonContainer>
          <Checkmark>
            {multi === true && selected.indexOf(value) !== -1 && (
              <span className="glyphicon glyphicon-ok" />
            )}
            {multi === false && selected === value && (
              <span className="glyphicon glyphicon-ok" />
            )}
          </Checkmark>
          <ColumnName>{label}</ColumnName>
        </DropdownButtonContainer>
      </a>
    </li>
  )
}

const DropdownContainer = styled.div`
  .dropdown-menu > li > a {
    padding: 3px 6px;
  }
`

const DropdownButtonContainer = styled.div`
  display: flex;
`

const Checkmark = styled.div`
  margin-right: 5px;
  width: 15px;
`

const ColumnName = styled.div`
  flex: 1;
`

export const CheckmarkDropdownSeparator = styled.li.attrs({
  className: 'divider',
})``

export const CheckmarkDropdownGroup = styled.li.attrs({
  className: 'dropdown-header',
})`
  padding: 10px 10px 0 10px !important;
`

export class SaveableCheckmarkDropdown extends PureComponent<Props, void> {
  componentDidMount() {
    this._restoreStateFromStorage()
  }

  render() {
    const { children, onChange, ...rest } = this.props

    return (
      <CheckmarkDropdown onChange={this._toggleColumn} {...rest}>
        {this.props.children}
      </CheckmarkDropdown>
    )
  }

  _restoreStateFromStorage = () => {
    const storage = localStorage.getItem(`${this.props.id}_list`)

    if (!storage) {
      return
    }

    this.props.onChange(JSON.parse(storage))
  }

  _saveStateToStorage = (columns: Array<Value> | Value) => {
    localStorage.setItem(`${this.props.id}_list`, JSON.stringify(columns))
  }

  _toggleColumn = (columns: Array<Value> | Value) => {
    this._saveStateToStorage(columns)
    this.props.onChange(columns)
  }
}
