import { CALL_API } from '../../../infrastructure/middleware/api'

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE'

export const SIGN_DPA_REQUEST = 'SIGN_DPA_REQUEST'
export const SIGN_DPA_SUCCESS = 'SIGN_DPA_SUCCESS'
export const SIGN_DPA_FAILURE = 'SIGN_DPA_FAILURE'

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'

export const RESEND_ACTIVATION_EMAIL_REQUEST = 'RESEND_ACTIVATION_EMAIL_REQUEST'
export const RESEND_ACTIVATION_EMAIL_SUCCESS = 'RESEND_ACTIVATION_EMAIL_SUCCESS'
export const RESEND_ACTIVATION_EMAIL_FAILURE = 'RESEND_ACTIVATION_EMAIL_FAILURE'

export const SET_USER_STATUS_REQUEST = 'SET_USER_STATUS_REQUEST'
export const SET_USER_STATUS_SUCCESS = 'SET_USER_STATUS_SUCCESS'
export const SET_USER_STATUS_FAILURE = 'SET_USER_STATUS_FAILURE'

export const fetchUsers = (options = {}) => {
  return {
    [CALL_API]: {
      endpoint: '/users',
      method: 'GET',
      query: {
        ...options,
      },
      types: [GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE],
    },
  }
}

export const shouldFetchUsers = users => {
  return Object.keys(users.items).length === 0 && !users.isFetching
}

export const fetchUsersIfNeeded = () => {
  return (dispatch, getState) => {
    const { users } = getState()

    return shouldFetchUsers(users) ? dispatch(fetchUsers()) : null
  }
}

export const CREATE_CUSTOMER_USER_REQUEST = 'CREATE_CUSTOMER_USER_REQUEST'
export const CREATE_CUSTOMER_USER_SUCCESS = 'CREATE_CUSTOMER_USER_SUCCESS'
export const CREATE_CUSTOMER_USER_FAILURE = 'CREATE_CUSTOMER_USER_FAILURE'

export const requestCreateCustomerUser = (customerId, data) => {
  return {
    [CALL_API]: {
      endpoint: `/customers/${customerId}/users`,
      method: 'POST',
      body: data,
      types: [
        {
          type: CREATE_CUSTOMER_USER_REQUEST,
          meta: {
            customerId,
          },
        },
        {
          type: CREATE_CUSTOMER_USER_SUCCESS,
          meta: {
            customerId,
          },
        },
        CREATE_CUSTOMER_USER_FAILURE,
      ],
    },
  }
}

export const SET_ENTITY_USER_STATUS_REQUEST = 'SET_ENTITY_USER_STATUS_REQUEST'
export const SET_ENTITY_USER_STATUS_SUCCESS = 'SET_ENTITY_USER_STATUS_SUCCESS'
export const SET_ENTITY_USER_STATUS_FAILURE = 'SET_ENTITY_USER_STATUS_FAILURE'

export const setActiveStatus = (entityUserId, status) => {
  return {
    [CALL_API]: {
      endpoint: `/entity-users/${entityUserId}`,
      method: 'PUT',
      body: {
        entity_user: {
          active: status,
        },
      },
      types: [
        SET_ENTITY_USER_STATUS_REQUEST,
        SET_ENTITY_USER_STATUS_SUCCESS,
        SET_ENTITY_USER_STATUS_FAILURE,
      ],
    },
  }
}

export const FETCH_ENTITY_USERS_REQUEST = 'FETCH_ENTITY_USERS_REQUEST'
export const FETCH_ENTITY_USERS_SUCCESS = 'FETCH_ENTITY_USERS_SUCCESS'
export const FETCH_ENTITY_USERS_FAILURE = 'FETCH_ENTITY_USERS_FAILURE'

export const fetchEntityUsers = (options = {}) => {
  return {
    [CALL_API]: {
      endpoint: '/entity-users',
      method: 'GET',
      query: {
        ...options,
        includes: ['inventoryLocations', 'user', 'roles', 'user.homeEntity'],
      },
      types: [
        FETCH_ENTITY_USERS_REQUEST,
        FETCH_ENTITY_USERS_SUCCESS,
        FETCH_ENTITY_USERS_FAILURE,
      ],
    },
  }
}

export const FETCH_ENTITY_USER_CREATE_OPTIONS_REQUEST =
  'FETCH_ENTITY_USER_CREATE_OPTIONS_REQUEST'
export const FETCH_ENTITY_USER_CREATE_OPTIONS_SUCCESS =
  'FETCH_ENTITY_USER_CREATE_OPTIONS_SUCCESS'
export const FETCH_ENTITY_USER_CREATE_OPTIONS_FAILURE =
  'FETCH_ENTITY_USER_CREATE_OPTIONS_FAILURE'

export const fetchEntityUserCreateOptions = email => {
  return {
    [CALL_API]: {
      endpoint: '/entity-users/create-options',
      method: 'GET',
      query: {
        email,
      },
      types: [
        FETCH_ENTITY_USER_CREATE_OPTIONS_REQUEST,
        FETCH_ENTITY_USER_CREATE_OPTIONS_SUCCESS,
        FETCH_ENTITY_USER_CREATE_OPTIONS_FAILURE,
      ],
    },
  }
}

export const CREATE_ENTITY_USER_REQUEST = 'CREATE_ENTITY_USER_REQUEST'
export const CREATE_ENTITY_USER_SUCCESS = 'CREATE_ENTITY_USER_SUCCESS'
export const CREATE_ENTITY_USER_FAILURE = 'CREATE_ENTITY_USER_FAILURE'

export const createEntityUser = data => {
  return {
    [CALL_API]: {
      endpoint: '/entity-users',
      method: 'POST',
      body: data,
      types: [
        CREATE_ENTITY_USER_REQUEST,
        CREATE_ENTITY_USER_SUCCESS,
        CREATE_ENTITY_USER_FAILURE,
      ],
    },
  }
}

export const signDpa = () => ({
  [CALL_API]: {
    endpoint: `/entities/sign-dpa`,
    method: 'POST',
    types: [SIGN_DPA_REQUEST, SIGN_DPA_SUCCESS, SIGN_DPA_FAILURE],
  },
})

export const SAVE_ENTITY_USER_REQUEST = 'SAVE_ENTITY_USER_REQUEST'
export const SAVE_ENTITY_USER_SUCCESS = 'SAVE_ENTITY_USER_SUCCESS'
export const SAVE_ENTITY_USER_FAILURE = 'SAVE_ENTITY_USER_FAILURE'

export const saveEntityUser = (entityUserId, data) => ({
  [CALL_API]: {
    endpoint: `/entity-users/${entityUserId}`,
    method: 'PUT',
    types: [
      SAVE_ENTITY_USER_REQUEST,
      SAVE_ENTITY_USER_SUCCESS,
      SAVE_ENTITY_USER_FAILURE,
    ],
    body: data,
  },
})

export const resendActivationEmail = entityUserId => ({
  [CALL_API]: {
    endpoint: `/entity-users/${entityUserId}/resend-activation`,
    method: 'POST',
    types: [
      RESEND_ACTIVATION_EMAIL_REQUEST,
      RESEND_ACTIVATION_EMAIL_SUCCESS,
      RESEND_ACTIVATION_EMAIL_FAILURE,
    ],
  },
})
