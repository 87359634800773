/* @flow */

import React from 'react'

export const useCustomCss = (css: string | null) => {
  const element = React.useRef(null)

  React.useEffect(() => {
    if (element.current) {
      element.current.remove()
    }

    if (css && css.length !== 0) {
      const head = document.head || document.getElementsByTagName('head')[0]

      if (head) {
        const style = document.createElement('style')

        head.appendChild(style)

        style.type = 'text/css'
        if (style.styleSheet) {
          // This is required for IE8 and below.
          style.styleSheet.cssText = css
        } else {
          style.appendChild(document.createTextNode(css))
        }

        element.current = style
      }
    }

    return () => {
      if (element.current) {
        element.current.remove()
      }
    }
  }, [css])

  return [element.current]
}
