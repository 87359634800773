/* @flow */

import api from '../../../app/api'

import type {
  ApiResponse,
  BrunoRequestOptions,
  EmailTemplate,
  EmailTemplateGroup,
  Id,
} from '../../../app/types'

export const generateEmailSenderSettings = settings =>
  api.post(`/messaging/generate-email-sender-settings`, {
    body: {
      settings,
    },
  })

export const getEmailsCreateData = (
  trigger: string,
  systemType: string,
  emailableType: string,
  emailableId: Id
) =>
  api.get('/mail/emails-create-data', {
    query: {
      trigger,
      system_type: systemType,
      emailable_type: emailableType,
      emailable_id: emailableId,
    },
  })

export const getEmailTemplateGroups = (
  context: string | null = null
): Promise<ApiResponse<Array<EmailTemplateGroup>>> =>
  api.get('/mail/template-groups', {
    query: {
      context,
    },
  })

export const getEmailTriggers = (context: string | null = null) =>
  api.get('/mail/triggers', {
    query: {
      context,
    },
  })

export const createEmailTemplate = (
  templateGroupId: string | null,
  customizationLevel: string,
  customizationValue: Id | string | null,
  data: $Shape<EmailTemplate>
) =>
  api.post(`/mail/templates`, {
    body: {
      template_group_id: templateGroupId,
      customization_level: customizationLevel,
      customization_value: customizationValue,
      email_template: data,
    },
  })

export const updateEmailTemplate = (
  templateId: Id,
  data: $Shape<EmailTemplate>
) =>
  api.put(`/mail/templates/${templateId}`, {
    body: {
      email_template: data,
    },
  })

export const deleteEmailTemplate = (templateId: Id) =>
  api.delete(`/mail/templates/${templateId}`)
