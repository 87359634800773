/* @flow */

import React from 'react'
import styled from 'styled-components'

type Props = {
  currentPage: number,
  onPageChange: Function,
  perPage: number,
  size: 'small' | 'normal',
  total: number,
}

class Paginator extends React.Component<Props, void> {
  static defaultProps = {
    size: 'normal',
  }

  render() {
    const { total, perPage, currentPage, size, onPageChange } = this.props

    let pagesTotal = Math.ceil(total / perPage)

    let nextPage = currentPage + 1,
      prevPage = currentPage - 1

    let pages = [currentPage]

    const pagesPerSide = 4
    const noNextPages =
      pagesTotal - currentPage > pagesPerSide ? 4 : pagesTotal - currentPage
    const noPrevPages =
      noNextPages === pagesPerSide
        ? pagesPerSide
        : pagesPerSide - noNextPages + pagesPerSide

    for (var i = currentPage - noPrevPages; i < currentPage; i++) {
      pages.unshift(i)
    }
    for (var i = currentPage + 1; i <= currentPage + noNextPages; i++) {
      pages.push(i)
    }

    pages = pages.filter(p => p > 0)
    const lastPage = pages[pages.length - 1]

    for (
      var i = lastPage + 1;
      i < pagesTotal && pages.length < 1 + 2 * pagesPerSide;
      i++
    ) {
      pages.push(i)
    }
    // explicitly specify comparator function for sort
    // from lowest to greatest number
    pages.sort((a, b) => a - b)

    let pageButtonClassName = 'btn btn-white'
    if (size === 'small') {
      pageButtonClassName += ' btn-sm'
    }

    return (
      <Container>
        {currentPage > 1 && (
          <PageButton
            onClick={() => onPageChange(1)}
            className={pageButtonClassName}
          >
            First
          </PageButton>
        )}
        {currentPage > 1 && (
          <PageButton
            disabled={currentPage === 1}
            onClick={this._prev}
            className={pageButtonClassName}
          >
            Prev
          </PageButton>
        )}
        {pages.map(page => (
          <PageButton
            key={page}
            active={currentPage === page}
            disabled={currentPage === page}
            onClick={() => onPageChange(page)}
            className={pageButtonClassName}
          >
            {page}
          </PageButton>
        ))}
        {currentPage < pagesTotal && (
          <PageButton
            className={pageButtonClassName}
            disabled={currentPage === pagesTotal}
            onClick={this._next}
          >
            Next
          </PageButton>
        )}
        {currentPage < pagesTotal && (
          <PageButton
            className={pageButtonClassName}
            onClick={() => onPageChange(pagesTotal)}
          >
            Last
          </PageButton>
        )}
      </Container>
    )
  }

  _prev = () => {
    this.props.onPageChange(this.props.currentPage - 1)
  }

  _next = () => {
    this.props.onPageChange(this.props.currentPage + 1)
  }
}

export default Paginator

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: -10px;
`

const Label = styled.div`
  margin: 0 10px;
`

const PageButton = styled.button`
  border: ${({ active }: { active: boolean }) =>
    active ? '2px solid #e6bb6a !important' : null};
  margin-left: 10px;
`
