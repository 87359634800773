/* @flow */

import React, { useMemo } from 'react'
import { SelectWrapper } from '../../../infrastructure/components/Forms'

import { useClaimCauses } from '../hooks'

const ClaimCauseSelector = props => {
  const [causes, isFetching] = useClaimCauses()
  const options = useMemo(() =>
    causes.map(cause => ({ label: cause.description, value: cause.id }))
  )

  return <SelectWrapper isFetching={isFetching} options={options} {...props} />
}

export default ClaimCauseSelector
