import { CALL_API } from '../../../infrastructure/middleware/api'

export const CHANGE_CUSTOMER_CONTEXT = 'CHANGE_CUSTOMER_CONTEXT'
export const EMPTY_CUSTOMERS = 'EMPTY_CUSTOMERS'

export const CUSTOMERS_TABLE_PARAMETERS_REQUEST =
  'CUSTOMERS_TABLE_PARAMETERS_REQUEST'
export const CUSTOMERS_TABLE_PARAMETERS_SUCCESS =
  'CUSTOMERS_TABLE_PARAMETERS_SUCCESS'
export const CUSTOMERS_TABLE_PARAMETERS_FAILURE =
  'CUSTOMERS_TABLE_PARAMETERS_FAILURE'
export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST'
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS'
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE'
export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST'
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS'
export const GET_CUSTOMERS_FAILURE = 'GET_CUSTOMERS_FAILURE'
export const NEXT_CUSTOMER_NUMBER_REQUEST = 'NEXT_CUSTOMER_NUMBER_REQUEST'
export const NEXT_CUSTOMER_NUMBER_SUCCESS = 'NEXT_CUSTOMER_NUMBER_SUCCESS'
export const NEXT_CUSTOMER_NUMBER_FAILURE = 'NEXT_CUSTOMER_NUMBER_FAILURE'
export const RESEND_ACTIVATION_MAIL_REQUEST = 'RESEND_ACTIVATION_MAIL_REQUEST'
export const RESEND_ACTIVATION_MAIL_SUCCESS = 'RESEND_ACTIVATION_MAIL_SUCCESS'
export const RESEND_ACTIVATION_MAIL_FAILURE = 'RESEND_ACTIVATION_MAIL_FAILURE'
export const SAVE_CUSTOMER_REQUEST = 'SAVE_CUSTOMER_REQUEST'
export const SAVE_CUSTOMER_SUCCESS = 'SAVE_CUSTOMER_SUCCESS'
export const SAVE_CUSTOMER_FAILURE = 'SAVE_CUSTOMER_FAILURE'

export const ACTIVATE_CUSTOMER_REQUEST = 'ACTIVATE_CUSTOMER_REQUEST'
export const ACTIVATE_CUSTOMER_SUCCESS = 'ACTIVATE_CUSTOMER_SUCCESS'
export const ACTIVATE_CUSTOMER_FAILURE = 'ACTIVATE_CUSTOMER_FAILURE'

export const DEACTIVATE_CUSTOMER_REQUEST = 'DEACTIVATE_CUSTOMER_REQUEST'
export const DEACTIVATE_CUSTOMER_SUCCESS = 'DEACTIVATE_CUSTOMER_SUCCESS'
export const DEACTIVATE_CUSTOMER_FAILURE = 'DEACTIVATE_CUSTOMER_FAILURE'

export const PUSH_CUSTOMER_ECONOMIC_REQUEST = 'PUSH_CUSTOMER_ECONOMIC_REQUEST'
export const PUSH_CUSTOMER_ECONOMIC_SUCCESS = 'PUSH_CUSTOMER_ECONOMIC_SUCCESS'
export const PUSH_CUSTOMER_ECONOMIC_FAILURE = 'PUSH_CUSTOMER_ECONOMIC_FAILURE'

export const activateCustomer = customerId => ({
  [CALL_API]: {
    endpoint: `/customers/${customerId}/activate`,
    method: 'POST',
    types: [
      ACTIVATE_CUSTOMER_REQUEST,
      ACTIVATE_CUSTOMER_SUCCESS,
      ACTIVATE_CUSTOMER_FAILURE,
    ],
  },
})

export const deactivateCustomer = customerId => ({
  [CALL_API]: {
    endpoint: `/customers/${customerId}/deactivate`,
    method: 'POST',
    types: [
      DEACTIVATE_CUSTOMER_REQUEST,
      DEACTIVATE_CUSTOMER_SUCCESS,
      DEACTIVATE_CUSTOMER_FAILURE,
    ],
  },
})

export const requestSaveCustomer = (customerId, data) => {
  return {
    [CALL_API]: {
      endpoint: `/customers/${customerId}`,
      method: 'PUT',
      body: data,
      types: [
        SAVE_CUSTOMER_REQUEST,
        SAVE_CUSTOMER_SUCCESS,
        SAVE_CUSTOMER_FAILURE,
      ],
    },
  }
}

export const requestCreateCustomer = data => {
  return {
    [CALL_API]: {
      endpoint: '/customers',
      method: 'POST',
      body: data,
      types: [
        CREATE_CUSTOMER_REQUEST,
        CREATE_CUSTOMER_SUCCESS,
        CREATE_CUSTOMER_FAILURE,
      ],
    },
  }
}

export const requestNextCustomerNumber = () => {
  return {
    [CALL_API]: {
      endpoint: '/customers/next-customer-number',
      method: 'GET',
      types: [
        NEXT_CUSTOMER_NUMBER_REQUEST,
        NEXT_CUSTOMER_NUMBER_SUCCESS,
        NEXT_CUSTOMER_NUMBER_FAILURE,
      ],
    },
  }
}

export const requestCustomersTableParameters = dataKey => {
  return {
    [CALL_API]: {
      endpoint: '/customers/table-parameters',
      method: 'GET',
      types: [
        CUSTOMERS_TABLE_PARAMETERS_REQUEST,
        CUSTOMERS_TABLE_PARAMETERS_SUCCESS,
        CUSTOMERS_TABLE_PARAMETERS_FAILURE,
      ],
    },
  }
}

export const EXPORT_CUSTOMERS_REQUEST = 'EXPORT_CUSTOMERS_REQUEST'
export const EXPORT_CUSTOMERS_SUCCESS = 'EXPORT_CUSTOMERS_SUCCESS'
export const EXPORT_CUSTOMERS_FAILURE = 'EXPORT_CUSTOMERS_FAILURE'
export const requestExportCustomers = (filters, sort) => ({
  [CALL_API]: {
    endpoint: '/reports/customers',
    method: 'POST',
    body: {
      filters,
      sort,
    },
    types: [
      EXPORT_CUSTOMERS_REQUEST,
      EXPORT_CUSTOMERS_SUCCESS,
      EXPORT_CUSTOMERS_FAILURE,
    ],
  },
})

export const requestResendActivationMail = (customerId, userId) => {
  return {
    [CALL_API]: {
      endpoint: `/customers/${customerId}/users/${userId}/resend-activation-mail`,
      method: 'POST',
      types: [
        RESEND_ACTIVATION_MAIL_REQUEST,
        RESEND_ACTIVATION_MAIL_SUCCESS,
        RESEND_ACTIVATION_MAIL_FAILURE,
      ],
    },
  }
}

export const requestPushCustomerEconomic = customerId => ({
  [CALL_API]: {
    endpoint: `/economic/customers/${customerId}/push-customer`,
    method: 'POST',
    types: [
      PUSH_CUSTOMER_ECONOMIC_REQUEST,
      PUSH_CUSTOMER_ECONOMIC_SUCCESS,
      PUSH_CUSTOMER_ECONOMIC_FAILURE,
    ],
  },
})

export const GET_CUSTOMERS_LIST_REQUEST = 'GET_CUSTOMERS_LIST_REQUEST'
export const GET_CUSTOMERS_LIST_SUCCESS = 'GET_CUSTOMERS_LIST_SUCCESS'
export const GET_CUSTOMERS_LIST_FAILURE = 'GET_CUSTOMERS_LIST_FAILURE'

export const getCustomersList = options => ({
  [CALL_API]: {
    endpoint: '/customers/list',
    method: 'GET',
    query: options,
    types: [
      GET_CUSTOMERS_LIST_REQUEST,
      GET_CUSTOMERS_LIST_SUCCESS,
      GET_CUSTOMERS_LIST_FAILURE,
    ],
  },
})

export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST'
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS'
export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE'

export const getCustomer = (customerId, options) => ({
  [CALL_API]: {
    endpoint: `/customers/${customerId}`,
    method: 'GET',
    query: options,
    types: [GET_CUSTOMER_REQUEST, GET_CUSTOMER_SUCCESS, GET_CUSTOMER_FAILURE],
  },
})

export const DELETE_CUSTOMER_USER_REQUEST = 'DELETE_CUSTOMER_USER_REQUEST'
export const DELETE_CUSTOMER_USER_SUCCESS = 'DELETE_CUSTOMER_USER_SUCCESS'
export const DELETE_CUSTOMER_USER_FAILURE = 'DELETE_CUSTOMER_USER_FAILURE'

export const deleteCustomerUser = (customerId, userId) => ({
  [CALL_API]: {
    endpoint: `/customers/${customerId}/users/${userId}`,
    method: 'DELETE',
    types: [
      DELETE_CUSTOMER_USER_REQUEST,
      DELETE_CUSTOMER_USER_SUCCESS,
      DELETE_CUSTOMER_USER_FAILURE,
    ],
  },
})

export const UPDATE_CUSTOMERS_BULK_REQUEST = 'UPDATE_CUSTOMERS_BULK_REQUEST'
export const UPDATE_CUSTOMERS_BULK_SUCCESS = 'UPDATE_CUSTOMERS_BULK_SUCCESS'
export const UPDATE_CUSTOMERS_BULK_FAILURE = 'UPDATE_CUSTOMERS_BULK_FAILURE'

export const updateCustomersBulk = (customers, data) => ({
  [CALL_API]: {
    endpoint: '/customers',
    method: 'PUT',
    body: {
      customers,
      data,
    },
    types: [
      UPDATE_CUSTOMERS_BULK_REQUEST,
      UPDATE_CUSTOMERS_BULK_SUCCESS,
      UPDATE_CUSTOMERS_BULK_FAILURE,
    ],
  },
})
