/* @flow */

import * as React from 'react'
import { type Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import memoize from 'memoize-one'
import type { Location, Match, RouterHistory } from 'react-router-dom'
import qs from 'querystring'

import { setBrands } from '../brands/actions'
import WebshopTemplate from './WebshopTemplate'

import { changeWebshopCurrency, fetchWebshopTotals } from './actions/index'

import { useAnonymousWebshopSession, usePublicBrand } from './hooks'
import { updateWebshopSession } from './api'
import { WebshopContext } from './shared'

type Props = {
  children?: React.Node,
  dispatch: Dispatch<any>,
  history: RouterHistory,
  location: Location,
  match: Match,
}

const WebshopAnonymousSession = ({
  children,
  currency,
  dispatch,
  history,
  location,
  match,
  totals,
}: Props) => {
  const brandId = match.params.brandId
  const [brand, isFetching, { isInitialized }] = usePublicBrand([brandId])

  // We can either hit this route from a shop URL (/shop/{brandId}) or custom domain (b2b.toms.dk)
  const sessionRequestArgs = React.useMemo(() => [brand ? brand.id : null], [
    brand,
  ])
  const [
    webshopSession,
    isFetchingWebshopSession,
    { refresh: refreshWebshopSession },
  ] = useAnonymousWebshopSession(sessionRequestArgs, {
    ignore: brand == null,
  })

  // We still have components that are connected to the redux store and depend on brands
  // having being fetched that way. Examples are ProductTable and StockList. To accomodate this
  // while we phase out redux we will set the brands directly in the redux store here
  useEffect(() => {
    if (brand) {
      dispatch(setBrands([brand]))
      // Somebody landed on this page but its not a public webshop, we should redirect
      // to login screen
    } else if (!brand && isInitialized) {
      const redirect = `${window.location.pathname}${location.search}`
      history.push(`/login?${qs.stringify({ redirect: encodeURI(redirect) })}`)
    }
  }, [brand, isInitialized])

  const webshopSessionId = webshopSession ? webshopSession.id : null

  const onFetchWebshopTotals = React.useCallback(() => {
    dispatch(fetchWebshopTotals(brand.id, currency, webshopSessionId))
  }, [brand, dispatch, currency, webshopSessionId])

  const setCurrency = React.useCallback(
    newCurrency => {
      dispatch(changeWebshopCurrency(newCurrency))
    },
    [dispatch]
  )

  const onUpdateWebshopSession = React.useCallback(
    values => {
      return updateWebshopSession(brand.id, webshopSessionId, values).then(
        response => {
          if (!response.error) {
            refreshWebshopSession()

            // refetch shipping
            onFetchWebshopTotals()
          }
        }
      )
    },
    [brand, refreshWebshopSession, onFetchWebshopTotals, webshopSessionId]
  )

  if (!webshopSession || !brand) {
    return null
  }

  /*
    Force us to remount the webshoptemplate when the session is changed.
    We do this to e.g. re-fech the navigation when drop changes
  */
  return (
    <WebshopContext.Provider
      value={createWebshopContext(
        brand,
        currency,
        onFetchWebshopTotals,
        setCurrency,
        totals,
        onUpdateWebshopSession,
        webshopSession
      )}
    >
      <WebshopTemplate
        brand={brand}
        history={history}
        location={location}
        /*onUpdateCustomerConnection={this._toggleCustomerSelector}
      onUpdateSession={this._showWebshopSplash}*/
        match={match}
        webshopSettings={brand.settings.webshop_settings}
        webshopSession={webshopSession}
      >
        {children}
      </WebshopTemplate>
    </WebshopContext.Provider>
  )
}

export default connect(state => ({
  currency: state.webshop.currency,
  totals: state.webshop.totals,
}))(WebshopAnonymousSession)

const createWebshopContext = memoize(
  (
    brand,
    currency,
    fetchWebshopTotals,
    setCurrency,
    totals,
    updateWebshopSession,
    webshopSession
  ) => ({
    brand,
    currency,
    fetchWebshopTotals,
    setCurrency,
    totals,
    updateWebshopSession,
    webshopSession,
  })
)
