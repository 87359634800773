/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import type { ColumnConfig } from '../../types'

const return_portal_refund_method: (options: Options) => ColumnConfig = ({
  editable = false,
  rows,
}) => ({
  // if there is no default value then the row will unmount and mount all the time
  default_value: [{ return_portal_refund_method: null }],
  editable: editable,
  key: 'return_portal_refund_method',
  label: 'Claim cause',
  rows: rows,
  split_by_values: true,
  value: ({ line }) => [
    {
      return_portal_refund_method: line
        ? line.return_portal_refund_method
        : null,
    },
  ],
  render: ({ value }) => {
    if (!value || !value[0] || !value[0].return_portal_refund_method) {
      return null
    }

    return value[0].return_portal_refund_method.description
  },
})

export default return_portal_refund_method
