import { CALL_API } from '../../../infrastructure/middleware/api'

import type { ElasticFilters } from '../../products/types'

export const OPEN_INVENTORY = 'OPEN_INVENTORY'
export const FETCH_INVENTORY_REQUEST = 'FETCH_INVENTORY_REQUEST'
export const FETCH_INVENTORY_SUCCESS = 'FETCH_INVENTORY_SUCCESS'
export const FETCH_INVENTORY_FAILURE = 'FETCH_INVENTORY_FAILURE'
export const SAVE_INVENTORY_CHANGES_REQUEST = 'SAVE_INVENTORY_CHANGES_REQUEST'
export const SAVE_INVENTORY_CHANGES_SUCCESS = 'SAVE_INVENTORY_CHANGES_SUCCESS'
export const SAVE_INVENTORY_CHANGES_FAILURE = 'SAVE_INVENTORY_CHANGES_FAILURE'
export const CHANGE_STOCK = 'CHANGE_STOCK'

export const openInventory = () => ({
  type: OPEN_INVENTORY,
})

export const fetchInventory = (options, locations) => {
  return {
    [CALL_API]: {
      endpoint: '/inventory',
      method: 'GET',
      query: options,
      types: [
        FETCH_INVENTORY_REQUEST,
        { type: FETCH_INVENTORY_SUCCESS, meta: { locations } },
        FETCH_INVENTORY_FAILURE,
      ],
    },
  }
}

export const saveInventoryChanges = () => {
  return (dispatch, getState) => {
    const { stockChanges } = getState().inventory

    return dispatch({
      [CALL_API]: {
        endpoint: '/inventory',
        method: 'PUT',
        body: {
          inventory: stockChanges,
        },
        types: [
          SAVE_INVENTORY_CHANGES_REQUEST,
          SAVE_INVENTORY_CHANGES_SUCCESS,
          SAVE_INVENTORY_CHANGES_FAILURE,
        ],
      },
    })
  }
}

export const changeStock = ({ variantId, inventoryLocationId, quantity }) => {
  return (dispatch, getState) => {
    const { stock, stockChanges } = getState().inventory

    const updated = Object.assign({}, stockChanges, {
      [variantId]: Object.assign({}, stockChanges[variantId], {
        [inventoryLocationId]: { quantity },
      }),
    })

    // If the new quantity is the same as the initial quantity for this
    // stock type, remove that stock type from changes
    if (
      stock[variantId][inventoryLocationId].quantity ===
      updated[variantId][inventoryLocationId].quantity
    ) {
      delete updated[variantId][inventoryLocationId]
    }

    return dispatch({
      type: CHANGE_STOCK,
      payload: {
        stockChanges: updated,
      },
    })
  }
}
