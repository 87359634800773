/* @flow */

import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'

import { Tooltip } from '../../../shared'

const SortProductsAction = (setSort, sort) => {
  return {
    key: 'sort_products',
    render: props => {
      return <Render setSort={setSort} sort={sort} {...props} />
    },
  }
}

export default SortProductsAction

const Render = ({ setSort, sort }) => {
  return (
    <Container>
      <Label>Sort:</Label>
      <SelectContainer className="react-select--micro">
        <Select
          clearable={false}
          onChange={setSort}
          options={sortingOptions}
          simpleValue
          value={sort}
        />
      </SelectContainer>
    </Container>
  )
}

const sortingOptions = [
  { label: 'Newest', value: 'newest' },
  { label: 'Product name', value: 'name' },
  { label: 'Item number', value: 'item_number' },
]

const Container = styled.div`
  align-items: center;
  display: flex;
  width: 200px;
`

const Label = styled.div`
  color: black;
  font-weight: bold;
  margin-right: 5px;
`

const SelectContainer = styled.div`
  flex: 1;
`
