/* @flow */

import React from 'react'
import styled from 'styled-components'

import type { Customer } from '../types'

export const priorities = {
  '1': 'A',
  '2': 'B',
  '3': 'C',
  '4': 'D',
  '5': 'E',
  '6': 'F',
  '7': 'G',
  '8': 'H',
}

export const priorityOptions = Object.keys(priorities).map(value => ({
  value,
  label: priorities[value],
}))

export const PriorityLabel = ({
  priority,
  size = 'normal',
  ...rest
}: {
  priority: $Keys<typeof priorities>,
  size: 'normal' | 'small' | 'xsmall',
}) => {
  return (
    <PriorityContainer priority={priority} size={size} {...rest}>
      {priorities[priority]}
    </PriorityContainer>
  )
}

const fontSizes = {
  normal: 16,
  small: 14,
  xsmall: 12,
}

const heights = {
  normal: 22,
  small: 16,
  xsmall: 14,
}

const widths = {
  normal: 24,
  small: 18,
  xsmall: 15,
}

const PriorityContainer = styled.div`
  background: ${({ priority }: { priority: number }) =>
    priorityToBackground(priority)};
  border-radius: 4px;
  color: white;
  font-size: ${({ size = 'normal' }) => fontSizes[size] + 'px'};
  line-height: ${({ size = 'normal' }) => heights[size] + 'px'};
  height: ${({ size = 'normal' }) => heights[size] + 'px'};
  text-align: center;
  width: ${({ size = 'normal' }) => widths[size] + 'px'};
`

const priorityToBackground = priority => {
  let background = '#f35958'
  if (priority > 6 && priority <= 8) {
    background = '#d0841d'
  } else if (priority > 4 && priority <= 6) {
    background = '#fdd01c'
  } else if (priority > 2 && priority <= 4) {
    background = '#c0ca59'
  } else if (priority <= 2) {
    background = '#329a34'
  }
  return background
}

export const resolveCountrySettings = (
  countrySettingsRegions,
  customer,
  property,
  lastResortValue,
  fallback = null
) => {
  const customerValue = customer[property]

  if (customerValue !== null) {
    return customerValue
  }

  const country = customer.country

  if (country) {
    let foundCountrySettings = null
    let foundCountrySettingsRegion = null
    for (let region of countrySettingsRegions) {
      if (region.resolved_countries.includes(country)) {
        foundCountrySettingsRegion = region

        for (let countrySettings of region.countries) {
          if (countrySettings.country === country) {
            foundCountrySettings = countrySettings
          }
        }

        break
      }
    }

    if (foundCountrySettings) {
      if (foundCountrySettings[property] !== null) {
        return foundCountrySettings[property]
      }
    }

    if (foundCountrySettingsRegion) {
      if (foundCountrySettingsRegion[property] !== null) {
        return foundCountrySettingsRegion[property]
      }
    }
  }

  const restOfWorldRegionSettings = countrySettingsRegions.find(
    region =>
      region.region_type === 'auto' &&
      region.region_type_auto_type === 'rest_of_world'
  )

  if (restOfWorldRegionSettings) {
    if (restOfWorldRegionSettings[property] !== null) {
      return restOfWorldRegionSettings[property]
    }
  }

  if (fallback !== null) {
    return fallback[property]
  }

  return lastResortValue
}

export const isCustomerBlocked = (customer: Customer) => {
  return ['blocked_orders', 'blocked_shipments'].includes(customer.blocked)
}

export const hasExceededCreditLimit = (customer: Customer) => {
  // TODO: fill actual logic
  return true
}

export const EU_VAT_VALIDATION_FORMAT_INVALID = 'format_invalid'
export const EU_VAT_VALIDATION_INVALID = 'invalid'
export const EU_VAT_VALIDATION_VALID = 'valid'
