/* @flow */

import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'

import { CustomFieldInput } from '../../../../../../infrastructure/components/Formik'
import { renderCustomField } from '../../../../../custom-fields/shared'

import type { ColumnConfig } from '../../types'

const custom_field: (options: Options) => ColumnConfig = ({
  customField,
  editable = false,
  rows,
  ...rest
}) => {
  const key = `meta_${customField.internal_name}`

  return {
    // if there is no default value then the row will unmount and mount all the time
    default_value: [{ [key]: null }],
    editable: editable,
    key: key,
    label: customField.label,
    rows: rows,
    split_by_values: true,
    value: ({ columnValues, line, ...rest }) => {
      let value = null
      if (
        line &&
        !Array.isArray(line.meta) &&
        line.meta[customField.internal_name]
      ) {
        value = line.meta[customField.internal_name]
      }

      return [{ [customField.internal_name]: value }]
    },
    render: ({
      config,
      edit,
      onColumnValueChange,
      onEdit,
      product,
      variants,
      value,
    }) => {
      return (
        <CustomFieldForm
          customField={customField}
          edit={edit}
          editable={config.editable}
          onChange={onColumnValueChange}
          onEdit={onEdit}
          product={product}
          value={value}
          valueKey={customField.internal_name}
          variants={variants}
        />
      )
    },
    // as described further down, the split data needs to be just {[customField.internal_name]: value} in order
    // for column keys to be correctly calculated. however, when we create a line we need to value to be added
    // to the `meta` key on the line, so this function will do that without changing the structure of the split data.
    value_to_new_line: columnValue => ({
      meta: columnValue,
    }),
  }
}

export default custom_field

const CustomFieldForm = ({
  customField,
  context,
  edit = false,
  editable = false,
  onChange,
  onEdit,
  product,
  variants,
  value,
  valueKey,
}: Props) => {
  const initialValues = React.useMemo(() => {
    return {
      value: value[0] ? value[0][valueKey] : null,
    }
  }, [valueKey, value])

  const onSubmit = React.useCallback(
    (values, { setSubmitting }) => {
      onChange(
        // update line data
        {
          __partial_line_meta: { [customField.internal_name]: values.value },
        },
        // split data: this needs to match the value of the `value` function. The `value` function will
        // return something like [{ [customField.internal_name]: value }], so we need the split data to
        // return the same structure so that when a line is added it will not create double product table
        // rows. The `meta` key will be added by the `value_to_new_line` function.
        {
          [customField.internal_name]: values.value,
        }
      )

      setSubmitting(false)
    },
    [customField, onChange, value]
  )

  return (
    <LineTextContainer>
      {editable === true && edit === false && (
        <LineTextEditButton onClick={() => onEdit(true)}>
          <span className="glyphicon glyphicon-pencil" />
        </LineTextEditButton>
      )}

      {edit === false && (
        <div>
          {renderCustomField(customField, value[0] ? value[0][valueKey] : '')}
        </div>
      )}
      {edit === true && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, isSubmitting, setFieldValue, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <LineTextInputContainer>
                  <CancelButton onClick={() => onEdit(false)} />

                  <SavePencilButton size="small" submitting={isSubmitting} />

                  <CustomFieldInput
                    customField={customField}
                    markOnFocus
                    name="value"
                  />
                </LineTextInputContainer>
              </form>
            )
          }}
        />
      )}
    </LineTextContainer>
  )
}

const LineTextContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const LineTextEditButton = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border: none;
  font-size: 10px;
  outline: 0;
`

const LineTextInputContainer = styled.div`
  display: flex;
  min-width: 165px;
`

const LineTextFormSaveButton = styled.button.attrs({
  type: 'submit',
})`
  background: transparent;
  border: none;
  outline: 0;
`

const StyledFormInput = styled(CustomFieldInput).attrs({ bsSize: 'sm' })`
  border: none;
  box-shadow: none;
  border-bottom: 1px dashed #d8d8d8;
  outline: 0;
  text-align: right;

  &:focus {
    border-color: inherit;
    outline: 0;
    box-shadow: none;
  }
`

export const CancelButton = ({ size = 'normal', ...rest }) => {
  return (
    <IconButton size={size} type="button" {...rest}>
      <span className="glyphicon glyphicon-remove" />
    </IconButton>
  )
}

export const SavePencilButton = ({ submitting, size = 'normal', ...rest }) => {
  return (
    <IconButton disabled={submitting} size={size} type="submit" {...rest}>
      {!submitting && <span className="glyphicon glyphicon-ok" />}
      {submitting && <span className="fa fa-spinner fa-spin" />}
    </IconButton>
  )
}

const IconButton = styled.button`
  background: none;
  border: none;
  float: ${({ float }) => float};
  outline: 0;

  ${({ size }: { size: 'normal' | 'small' }) => sizeToStyle(size)};
`

const sizeToStyle = size => {
  switch (size) {
    case 'normal':
      return css``

    case 'small':
      return css`
        font-size: 11px;
        font-weight: normal;
      `
  }
}
