/* @flow */

import React, { PureComponent } from 'react'
import sortBy from 'lodash/sortBy'

import {
  CheckmarkDropdown,
  CheckmarkDropdownButton,
} from '../../../../../infrastructure/components/Dropdown'
import { createAction } from '../shared'
import { ProductTableOptionActionCallback } from './shared'
import type { App, ConnectedBrand, CustomField } from '../../../../types'

type Props = {
  brand: ConnectedBrand,
  callback: (key: string, value: Array<string>) => void,
  customFields?: Array<CustomField>,
  tableKey: string,
  tableOptions: {
    columns: Array<string>,
    rehydrateTableOptions: boolean,
  },
}

class ColumnsComponent extends PureComponent<Props, void> {
  static defaultProps = {
    customFields: [],
  }

  componentDidMount() {
    if (this.props.tableOptions.rehydrateTableOptions) {
      this.restoreStateFromStorage()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tableOptions.columns !== this.props.tableOptions.columns) {
      this.saveStateToStorage(nextProps)
    }
  }

  restoreStateFromStorage() {
    const storage = localStorage.getItem(this.props.tableKey)

    if (!storage) {
      return
    }

    const columns = JSON.parse(storage)

    this.props.callback(columns)
  }

  saveStateToStorage(props: Props) {
    localStorage.setItem(
      props.tableKey,
      JSON.stringify(props.tableOptions.columns)
    )
  }

  toggleColumn = (columns: Array<string>) => {
    this.props.callback(columns)
  }

  render() {
    const {
      brand,
      customFields,
      tableOptions: { columns },
    } = this.props

    let columnOptions = createColumnOptions(customFields, brand.apps)

    columnOptions = sortBy(columnOptions, 'label')

    return (
      <CheckmarkDropdown
        onChange={this.toggleColumn}
        id="product_table_columns"
        selected={columns}
        title="Columns"
      >
        {columnOptions.map((option, i) => (
          <CheckmarkDropdownButton
            key={option.value}
            value={option.value}
            label={option.label}
          />
        ))}
      </CheckmarkDropdown>
    )
  }
}

export const createColumnsAction = (callback = () => {}) => {
  return createAction((columns, instance) => {
    ProductTableOptionActionCallback({ columns }, instance)
    callback(columns)
  }, ColumnsComponent)
}

export default createColumnsAction

const Columns = [
  'collection',
  'ean',
  'barcode',
  'item_number',
  'name',
  'image',
  'price',
  'quantity',
  'rec_sales_price',
  'sku',
  'subbrand',
  'total_price',
  'total_quantity',
  'inventory',
  'available_colli',
  'weight',
]
const ColumnLabels = {
  available_colli: 'Available colli',
  collection: 'Collection',
  ean: 'EAN',
  barcode: 'Barcode',
  item_number: 'Item number',
  name: 'Product name',
  quantity: 'Quantity',
  image: 'Picture',
  price: 'Price',
  rec_sales_price: 'Rec. sales price',
  sku: 'SKU',
  inventory: 'Inventory',
  subbrand: 'Subbrand',
  total_price: 'Total price',
  total_quantity: 'Total quantity',
  weight: 'Unit weight',
}

export const createColumnOptions = (
  customFields: Array<CustomField>,
  apps: Array<App> = []
) => {
  const newColumns = Columns.map(key => ({
    label: ColumnLabels[key],
    value: key,
  })).concat(
    customFields.map(c => ({
      label: c.label,
      value: `meta.${c.internal_name}`,
    }))
  )

  const productionApp = apps.find(app => app.app === 'Production')
  if (productionApp) {
    newColumns.push({
      label: 'PO Delivery',
      value: 'production_delivery',
    })
  }

  return sortBy(newColumns, 'label')
}
