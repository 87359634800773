/* @flow */

import * as React from 'react'
import { ControlLabel as BaseControlLabel, FormGroup } from 'react-bootstrap'
import { ErrorMessage as FormikErrorMessage } from 'formik'

import { ErrorLabel } from '../Forms'

const ControlLabel = ({
  children,
  required = false,
  ...rest
}: {
  children: React.Node,
  required?: boolean,
}) => {
  let label = children
  if (required) {
    label = [label, <RequiredLabel />]
  }

  return <BaseControlLabel {...rest}>{label}</BaseControlLabel>
}

const HelpHint = ({ children }: { children?: React.Node }) => {
  return <span className="help">{children}</span>
}

const RequiredLabel = props => <span className="form-required">*</span>

const SaveButton = ({
  className = 'btn btn-success',
  children,
  disabled,
  icon: defaultIconClass,
  submitting,
  type = 'submit',
  ...rest
}: {
  className?: string,
  children?: React.Node,
  disabled?: boolean,
  icon?: string,
  submitting: boolean,
  type?: string,
}) => {
  const icon = submitting ? 'fa fa-spinner fa-spin' : defaultIconClass

  return (
    <button
      type={type}
      className={className}
      disabled={disabled === true || submitting === true}
      {...rest}
    >
      <span className={icon} /> {children}
    </button>
  )
}

const ErrorMessage = ({ children, ...rest }) => {
  return (
    <FormikErrorMessage {...rest}>
      {children ? children : DefaultErrorErrorMessage}
    </FormikErrorMessage>
  )
}

const DefaultErrorErrorMessage = error => <ErrorLabel>{error}</ErrorLabel>

export { ControlLabel, HelpHint, ErrorMessage, FormGroup, SaveButton }
