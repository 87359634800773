export const SHOW_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE'
export const HIDE_TOAST_MESSAGE = 'HIDE_TOAST_MESSAGE'

export const hideMessage = () => ({
  type: HIDE_TOAST_MESSAGE,
})

export const showMessage = (messageType, messages) => {
  return dispatch => {
    dispatch({
      type: SHOW_TOAST_MESSAGE,
      messageType,
      messages,
    })

    if (messageType === 'success') {
      setTimeout(() => {
        dispatch(hideMessage())
      }, 3000)
    }
  }
}
