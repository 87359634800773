/* @flow */

import * as React from 'react'

import { getBulkJob } from '../api/bulk_jobs'
import useRefValue from './ref_value'

const useBulkJobs = (options = {}) => {
  const interval = options.interval || 2000

  const [bulkJobs, setBulkJobs] = React.useState([])
  const bulkJobsRef = useRefValue(bulkJobs)

  const addBulkJob = React.useCallback(
    bulkJobId => {
      return getBulkJob(bulkJobId).then(response => {
        if (!response.error) {
          setBulkJobs(s => [...s, response.payload.bulk_job])
        }

        return response
      })
    },
    [setBulkJobs]
  )

  React.useEffect(() => {
    let intervalInstance = setInterval(() => {
      const currentBulkJobs = bulkJobsRef.current || []

      const nonFinishedBulkJobs = currentBulkJobs.filter(
        bulkJob => bulkJob.status !== 'processed'
      )

      if (nonFinishedBulkJobs.length === 0) {
        return
      }

      const nonFinishedBulkJobIds = nonFinishedBulkJobs.map(
        bulkJob => bulkJob.id
      )

      Promise.all(nonFinishedBulkJobIds.map(id => getBulkJob(id))).then(
        responses => {
          const updatedBulkJobs = responses.map(
            response => response.payload.bulk_job
          )

          setBulkJobs(s => {
            const copy = [...s]

            for (let updatedBulkJob of updatedBulkJobs) {
              const indexOfBulkJob = copy.findIndex(
                bulkJob => bulkJob.id === updatedBulkJob.id
              )

              copy[indexOfBulkJob] = updatedBulkJob
            }

            return copy
          })
        }
      )
    }, interval)

    return () => {
      if (intervalInstance) {
        clearInterval(intervalInstance)
      }
    }
  }, [interval, bulkJobsRef])

  return { bulkJobs, addBulkJob }
}

export default useBulkJobs
