/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import FormatCurrency from '../../../../../../infrastructure/components/FormatCurrency'

import { createConfiguredVariantId } from '../../../../../shared'
import type { ColumnConfig } from '../../types'

type Price = {
  price_before_discount: number,
  discount_amount: number,
  discount_percentage: number,
  net_price: number,
}

type Options = {
  checkLineForPrice?: boolean,
  editable?: boolean,
  editProperty?: string,
  linePriceExtractor: () => Price,
  rows: Array<string>,
  valueToNewLine?: () => void,
}

const defaultPrice = {
  rec_sales_price: null,
}

const rec_sales_price: (options: Options) => ColumnConfig = ({
  checkLineForPrice = false,
  editProperty = 'net_price',
  rows,
}) => ({
  data_source: 'pricing',
  default_value: [defaultPrice],
  key: 'rec_sales_price',
  label: 'RRP',
  rows: rows,
  right: true,
  split_by_values: true,
  render: ({ context, value }) => {
    if (!value || !value[0]) {
      return null
    }

    const usePrice = value[0]

    return (
      <FormatCurrency
        currency={context.currency}
        style={{ whiteSpace: 'nowrap' }}
      >
        {usePrice}
      </FormatCurrency>
    )
  },
  value: ({ data, line, product, variant }) => {
    // when making backend orders rec_sales_price is part of the line
    // returned by the API
    if (checkLineForPrice && line) {
      return [line.rec_sales_price || null]
    }

    const pricingData = data.pricing && data.pricing[0] ? data.pricing[0] : null

    if (!pricingData) {
      return false
    }

    let priceIndex
    if (variant.__is_configured_variant) {
      let id = createConfiguredVariantId(variant.__configurator_values)

      priceIndex = pricingData.configured_variants[id]
    } else {
      priceIndex = pricingData.variants[variant.id]
    }

    // 0 evaluates falsely
    if (priceIndex === undefined || priceIndex === false) {
      return false
    }

    const price = pricingData.prices[priceIndex]

    return [price.rec_sales_price]
  },
})

export default rec_sales_price
