/* @flow */

import * as React from 'react'
import { type Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import memoize from 'memoize-one'
import type { Location, Match, RouterHistory } from 'react-router-dom'
import qs from 'querystring'

import { setBrands } from '../brands/actions'
import WebshopTemplate from './WebshopTemplateV2'

import { useAnonymousWebshopSession, usePublicBrand } from './hooks'

type Props = {
  children?: React.Node,
  dispatch: Dispatch<any>,
  history: RouterHistory,
  location: Location,
  match: Match,
}

const WebshopAnonymousSession = ({
  children,
  dispatch,
  history,
  location,
  match,
}: Props) => {
  const brandId = match.params.brandId
  const [brand, isFetching, { isInitialized }] = usePublicBrand([brandId])

  // We can either hit this route from a shop URL (/shop/{brandId}) or custom domain (b2b.toms.dk)
  const [webshopSession] = useAnonymousWebshopSession([brand ? brand.id : null])

  // We still have components that are connected to the redux store and depend on brands
  // having being fetched that way. Examples are ProductTable and StockList. To accomodate this
  // while we phase out redux we will set the brands directly in the redux store here
  useEffect(() => {
    if (brand) {
      dispatch(setBrands([brand]))
      // Somebody landed on this page but its not a public webshop, we should redirect
      // to login screen
    } else if (!brand && isInitialized) {
      const redirect = `${window.location.pathname}${location.search}`
      history.push(`/login?${qs.stringify({ redirect: encodeURI(redirect) })}`)
    }
  }, [brand, isInitialized])

  if (!webshopSession || !brand) {
    return null
  }

  /*
    Force us to remount the webshoptemplate when the session is changed.
    We do this to e.g. re-fech the navigation when drop changes
  */
  return (
    <WebshopTemplate
      brand={brand}
      history={history}
      location={location}
      key={webshopSession.updated_at}
      /*onUpdateCustomerConnection={this._toggleCustomerSelector}
      onUpdateSession={this._showWebshopSplash}*/
      match={match}
      webshopSettings={brand.settings.webshop_settings}
      webshopSession={webshopSession}
    >
      {React.Children.map(children, child => {
        // Because the child is a React Router Route we wrap the component in a HoC that will pass down
        // the WebshopSession since Route components do not pass props
        return React.cloneElement(child, {
          component: renderWithSession(child, brand, webshopSession),
        })
      })}
    </WebshopTemplate>
  )
}

export default connect()(WebshopAnonymousSession)

const renderWithSession = memoize((child, brand, webshopSession) => {
  const ChildComponent = child.props.component
  return props => (
    <ChildComponent brand={brand} webshopSession={webshopSession} {...props} />
  )
})
