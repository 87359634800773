/* @flow */

import { createApiHook, createDataCache } from '../../hooks'

import { getTags } from '../api'

const fetchTagsForHook = (options?: Object) =>
  getTags(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.tags,
      }
    } else {
      return response
    }
  })

export const useTags = createApiHook<Array<Tag>>(fetchTagsForHook, [])

const {
  cache: { clearCache },
  hook: useCachedTags,
} = createDataCache(useTags)

export { clearCache, useCachedTags }
