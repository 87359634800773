/* @flow */

import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

export const Submenu = styled.div`
  background-color: #fff;
  border-radius: 3px;
  border: none;
  box-shadow: 0px 0px 5px rgba(86, 96, 117, 0.2);
  display: none;
  float: left;
  font-size: 13px;
  left: ${({ side = 'left' }) => (side === 'left' ? 0 : 'auto')};
  right: ${({ side = 'left' }) => (side === 'right' ? 0 : 'auto')};
  min-width: 160px;
  padding: 0px;
  position: absolute;
  top: 100%;
  z-index: 1000;

  &::before {
    border-bottom-color: rgba(0, 0, 0, 0.2);
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: '';
    display: inline-block;
    position: absolute;
    left: 9px;
    top: -7px;
  }

  &::after {
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: '';
    display: inline-block;
    left: 10px;
    position: absolute;
    right: auto;
    top: -6px;
  }

  .divider {
    background-color: #eaedf0;
    border-bottom: 0px;
    height: 1px;
    margin: 0px !important;
  }
`

const SubmenuNavigationLinkStyle = css`
  color: #333;
  display: block;
  padding: 10px;

  &:hover {
    color: #333;
    background-color: #f5f7f9;
  }
`

export const FakeSubmenuNavigationLink = styled.div`
  ${SubmenuNavigationLinkStyle};
  cursor: pointer;

  a,
  a:hover {
    color: inherit;
  }
`

export const SubmenuNavigationLink = styled(Link)`
  ${SubmenuNavigationLinkStyle};
`
