/* @flow */

import React, { useContext } from 'react'

import ShowroomAnonymousSession from './ShowroomAnonymousSession'
import ShowroomSession from './ShowroomSession'

import { SessionContext } from '../shared'

const ShowroomSessionAuthenticationWrap = (props: Object) => {
  const { user } = useContext(SessionContext)

  return user.id ? (
    <ShowroomSession {...props} />
  ) : (
    <ShowroomAnonymousSession {...props} />
  )
}

export default ShowroomSessionAuthenticationWrap
