/* @flow */

import React from 'react'
import styled from 'styled-components'
import sortBy from 'lodash/sortBy'
import { connect } from 'react-redux'
import Autosuggest from 'react-autosuggest'
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'
import { withRouter, type RouterHistory } from 'react-router-dom'
import classnames from 'classnames'

import CloudinaryResource from '../../../../infrastructure/components/CloudinaryResource'
import { useDebounce } from '../../../../infrastructure/hooks'
import { pushRecentSelection, search } from '../../api'
import type { Dispatch, Id } from '../../../types'
import { useCancellableRequest } from '../../../shared'
import spotlightSuggestionTypes from './spotlightSuggestionTypes'

export type SpotlightSuggestion = {
  id: Id,
  score: number,
  type: string,
  type_label: string,
  title: string,
  subtitle: string,
  image: string | null,
  resultEntity: Object,
}

type Props = {
  dispatch: Dispatch,
  history: RouterHistory,
  onClose: Function,
}

const MAX_RESULTS = 10
const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)

const Spotlight = ({ dispatch, history, onClose }: Props) => {
  // input query is raw current input value
  const [inputQuery, setInputQuery] = React.useState('')
  // search query is updated every time react-autosuggest tells us to refetch results
  // this is later debounced
  const [searchQuery, setSearchQuery] = React.useState('')
  // result search query is the query we have the current result for
  const [resultSearchQuery, setResultSearchQuery] = React.useState('')
  const [result, setResult] = React.useState([])
  const [isFetching, setIsFetching] = React.useState(false)

  const debouncedSearchQuery = useDebounce(searchQuery, 300)

  const [doCancellableRequest] = useCancellableRequest()

  // debounced search query is updated which means we should request results
  React.useEffect(() => {
    setIsFetching(true)

    const fetchedSearchQuery = debouncedSearchQuery

    doCancellableRequest(axiosOptions =>
      search(axiosOptions, fetchedSearchQuery)
    ).then(response => {
      setIsFetching(false)

      if (!response.error) {
        setResult(response.payload)
        setResultSearchQuery(fetchedSearchQuery)
      }
    })
  }, [
    doCancellableRequest,
    debouncedSearchQuery,
    setIsFetching,
    setResult,
    setResultSearchQuery,
  ])

  const onInputChange = React.useCallback(
    (event, { newValue }) => {
      if (newValue !== undefined) {
        setInputQuery(newValue)
      }
    },
    [setInputQuery]
  )

  const onSuggestionsUpdateRequested = React.useCallback(
    ({ value }) => {
      return setSearchQuery(value)
    },
    [setSearchQuery]
  )

  const inputProps = React.useMemo(() => {
    return {
      value: inputQuery,
      onChange: onInputChange,
      placeholder: 'Search for anything...',
      autoFocus: true,
    }
  }, [inputQuery, onInputChange])

  const suggestionRows = React.useMemo(() => {
    const flattened = Object.keys(result).reduce((carry, type) => {
      const suggestionType = spotlightSuggestionTypes[type]

      return carry.concat(
        suggestionType && suggestionType.mapResultsToSuggestions
          ? suggestionType.mapResultsToSuggestions(type, result[type])
          : []
      )
    }, [])

    const filtered = flattened.filter(
      row => row.type === 'brands' || row.score > 0.3
    )

    const scored = sortBy(filtered, row => {
      if (row.type === 'brands') {
        return -100000000000
      }

      return -row.score
    })

    const sliced = scored.slice(0, MAX_RESULTS)

    // needed for shortcut
    const rowsWithIndex = []
    for (let [i, row] of sliced.entries()) {
      rowsWithIndex.push({
        ...row,
        shortcut: i === 9 ? '0' : i + 1,
      })
    }

    return rowsWithIndex
  }, [result])

  const onSuggestionSelected = React.useCallback(
    (events, { suggestion }) => {
      let recentSelectionId = suggestion.id
      if (suggestion.type === 'invoices') {
        recentSelectionId = `${suggestion.resultEntity.invoice_type}__${suggestion.id}`
      }

      pushRecentSelection(suggestion.type, recentSelectionId)

      const suggestionType = spotlightSuggestionTypes[suggestion.type]
      if (suggestionType && suggestionType.onSuggestionSelected) {
        suggestionType.onSuggestionSelected(suggestion, history, dispatch)
      }

      onClose()
    },
    [dispatch, history, onClose]
  )

  const getSuggestionValue = React.useCallback(
    suggestion => suggestion.text,
    []
  )

  const shouldRenderSuggestions = React.useCallback(() => true, [])

  const renderSuggestionsContainer = React.useCallback(
    ({ containerProps, children, query }) => {
      const isLoading = isFetching || inputQuery !== resultSearchQuery

      // Do not show anything when loading initial recents
      if (isLoading && query === '') {
        return null
      }

      if (isLoading) {
        return <SpotlightLoading>Loading...</SpotlightLoading>
      }

      if (query !== '' && suggestionRows.length === 0) {
        return (
          <SpotlightNothingFound>No matching items found</SpotlightNothingFound>
        )
      }

      return (
        <div {...containerProps}>
          {query === '' && children !== null && (
            <SpotlightRecentTitle>Recently opened</SpotlightRecentTitle>
          )}
          {children}
        </div>
      )
    },
    [isFetching, resultSearchQuery, inputQuery, suggestionRows]
  )

  return (
    <SpotlightWrapper>
      <SpotlightContainer>
        <Autosuggest
          alwaysRenderSuggestions={true}
          getSuggestionValue={getSuggestionValue}
          highlightFirstSuggestion={true}
          inputProps={inputProps}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsFetchRequested={onSuggestionsUpdateRequested}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={renderSuggestionsContainer}
          shouldRenderSuggestions={shouldRenderSuggestions}
          suggestions={suggestionRows}
        />
        {inputQuery === '' && (
          <SpotlightTips>
            <strong>Tip:</strong> Press{' '}
            <strong>{isMacLike ? '⌘ + S' : 'Ctrl + S'}</strong> to reach search
            faster
          </SpotlightTips>
        )}
      </SpotlightContainer>
      <SpotlightBackground onClick={onClose} />
    </SpotlightWrapper>
  )
}

const ConnectedSpotlight = withRouter(connect()(Spotlight))

export default ConnectedSpotlight

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const titleMatches = AutosuggestHighlightMatch(suggestion.title, query, {
    insideWords: true,
  })
  const titleParts = AutosuggestHighlightParse(suggestion.title, titleMatches)

  const subtitleMatches = AutosuggestHighlightMatch(
    suggestion.subtitle,
    query,
    {
      insideWords: true,
    }
  )
  const subtitleParts = AutosuggestHighlightParse(
    suggestion.subtitle,
    subtitleMatches
  )

  return (
    <a
      className={classnames({
        'template-searchbar__suggestion': true,
        'is-highlighted': isHighlighted,
      })}
    >
      <div className="template-searchbar__image">
        <CloudinaryResource
          className="round-pic"
          id={suggestion.image}
          presets="table"
          fallback="product_table"
        />
      </div>
      <div className="template-searchbar__title">
        <div>
          <SuggestionTitle>
            {titleParts.map((part, index) => {
              const className = part.highlight ? 'highlight' : null

              return (
                <span className={className} key={index}>
                  {part.text}
                </span>
              )
            })}

            <SuggestionType>{suggestion.type_label}</SuggestionType>
          </SuggestionTitle>
        </div>

        <SuggestionSubtitle>
          {subtitleParts.map(part => {
            return (
              <span className={part.highlight ? 'highlight' : null}>
                {part.text}
              </span>
            )
          })}
        </SuggestionSubtitle>
      </div>
    </a>
  )
}

const SpotlightWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
`

const SpotlightBackground = styled.div`
  cursor: pointer;
  position: absolute;
  content: '';
  width: 100vw;
  height: 100vh;
  background: rgba(56, 85, 102, 0.7);
  z-index: 99;
`

const SpotlightTips = styled.div`
  background: #d2d420;
  padding: 3px 5px;
`

const SpotlightContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 20vh;
  width: 500px;
  transform: translateX(-50%);
  z-index: 101;
  max-height: 80vh;

  & .react-autosuggest__input {
    width: 100%;
    padding: 25px;
    box-shadow: 0 3px 11px rgba(0, 0, 0, 0.12) !important;
    margin-bottom: 15px;
  }

  & .react-autosuggest__suggestions-container--open {
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 3px 11px rgba(0, 0, 0, 0.12) !important;
    max-height: calc(80vh - 115px);
    overflow-y: scroll;
    margin-bottom: 15px;

    & .react-autosuggest__section-title {
      color: #696767;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 10px 20px 5px 20px;
    }

    & .react-autosuggest__suggestions-list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        position: relative;
        padding: 0;
        margin: 0;
        cursor: pointer;

        .template-searchbar__suggestion {
          padding: 8px 20px;
        }

        img {
          max-width: 30px;
        }
      }
    }
  }
`

const SuggestionTitle = styled.span`
  display: inline-block;
  color: #385566;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;

  & .highlight {
    background: #d2d420;
    font-weight: 600;
    padding: 0 2px;
  }
`

const SuggestionSubtitle = styled.div`
  color: #888888;
  font-size: 10px;
  margin-top: -3px;
`

const SuggestionType = styled.span`
  background: #efefef;
  border-radius: 10px;
  color: #6b6b6b;
  font-size: 9px;
  font-weight: bold;
  margin-left: 6px;
  padding: 2px 4px;
  text-transform: uppercase;
`

const SpotlightRecentTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
  padding: 7px 20px 5px;
`

const SpotlightLoading = styled.div`
  font-size: 13px;
  padding: 7px 20px 5px;

  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 3px 11px rgba(0, 0, 0, 0.12) !important;
  margin-bottom: 15px;
`

const SpotlightNothingFound = styled.div`
  font-size: 13px;
  padding: 7px 20px 5px;

  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 3px 11px rgba(0, 0, 0, 0.12) !important;
  margin-bottom: 15px;
`
