/* @flow */

import * as React from 'react'
import { Field } from 'formik'
import styled from 'styled-components'

import RuleBasedSettingsForm from '../../../app/entities/components/RuleBasedSettingsForm'

type Props = {
  addNewLabel: string,
  defaultValue: { [string]: mixed },
  name: string,
}

const RuleBasedSettingsInput = ({ name, ...rest }: Props) => {
  return (
    <Field
      name={name}
      render={({
        field: { value }, // { name, value, onChange, onBlur }
        form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }) => {
        return (
          <div>
            <RuleBasedSettingsForm
              onChange={value => setFieldValue(name, value)}
              rules={value}
              {...rest}
            />
          </div>
        )
      }}
    />
  )
}

export default RuleBasedSettingsInput

const InputsContainer = styled.div`
  margin-top: 10px;
`

const RowContainer = styled.div`
  display: flex;
  margin-left: -10px;

  > * {
    flex: 1;
    margin-left: 10px;
  }
`

const RowContainerRemoteButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 0;
`
