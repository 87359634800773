import { CALL_API } from '../../../infrastructure/middleware/api'

export const GET_BANK_ACCOUNTS_REQUEST = 'GET_BANK_ACCOUNTS_REQUEST'
export const GET_BANK_ACCOUNTS_SUCCESS = 'GET_BANK_ACCOUNTS_SUCCESS'
export const GET_BANK_ACCOUNTS_FAILURE = 'GET_BANK_ACCOUNTS_FAILURE'
export const getBankAccounts = (options = {}) => ({
  [CALL_API]: {
    types: [
      GET_BANK_ACCOUNTS_REQUEST,
      GET_BANK_ACCOUNTS_SUCCESS,
      GET_BANK_ACCOUNTS_FAILURE,
    ],
    method: 'GET',
    endpoint: '/bank-accounts',
    query: {
      ...options,
    },
  },
})

export const CREATE_BANK_ACCOUNT_REQUEST = 'CREATE_BANK_ACCOUNT_REQUEST'
export const CREATE_BANK_ACCOUNT_SUCCESS = 'CREATE_BANK_ACCOUNT_SUCCESS'
export const CREATE_BANK_ACCOUNT_FAILURE = 'CREATE_BANK_ACCOUNT_FAILURE'
export const createBankAccount = data => ({
  [CALL_API]: {
    body: {
      bank_account: data,
    },
    types: [
      CREATE_BANK_ACCOUNT_REQUEST,
      CREATE_BANK_ACCOUNT_SUCCESS,
      CREATE_BANK_ACCOUNT_FAILURE,
    ],
    method: 'POST',
    endpoint: '/bank-accounts',
  },
})

export const UPDATE_BANK_ACCOUNT_REQUEST = 'UPDATE_BANK_ACCOUNT_REQUEST'
export const UPDATE_BANK_ACCOUNT_SUCCESS = 'UPDATE_BANK_ACCOUNT_SUCCESS'
export const UPDATE_BANK_ACCOUNT_FAILURE = 'UPDATE_BANK_ACCOUNT_FAILURE'
export const updateBankAccount = (id, data) => ({
  [CALL_API]: {
    body: {
      bank_account: data,
    },
    types: [
      UPDATE_BANK_ACCOUNT_REQUEST,
      UPDATE_BANK_ACCOUNT_SUCCESS,
      UPDATE_BANK_ACCOUNT_FAILURE,
    ],
    method: 'PUT',
    endpoint: `/bank-accounts/${id}`,
  },
})

export const DELETE_BANK_ACCOUNT_REQUEST = 'DELETE_BANK_ACCOUNT_REQUEST'
export const DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS'
export const DELETE_BANK_ACCOUNT_FAILURE = 'DELETE_BANK_ACCOUNT_FAILURE'
export const deleteBankAccount = id => ({
  [CALL_API]: {
    types: [
      DELETE_BANK_ACCOUNT_REQUEST,
      {
        type: DELETE_BANK_ACCOUNT_SUCCESS,
        meta: {
          id,
        },
      },
      DELETE_BANK_ACCOUNT_FAILURE,
    ],
    method: 'DELETE',
    endpoint: `/bank-accounts/${id}`,
  },
})

export const shouldFetchBankAccounts = () => {
  return (dispatch, getState) => {
    const { misc } = getState()

    if (
      misc.bank_accounts.items.length === 0 &&
      !misc.bank_accounts.isFetching
    ) {
      dispatch(getBankAccounts())
    }
  }
}
