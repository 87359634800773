/* @flow */

import groupBy from 'lodash/groupBy'

// DataCache data is formatted as
// {
//   [productId]: {
//      [dataKey]: {
//          isFetching: boolean,
//          rows: Array<Row>
//      }
//   }
// }

class DataCache {
  constructor(props) {
    this.reset()
  }

  reset() {
    this.dataCache = {
      table: {},
      product: {},
    }
    this.dataByDataKey = {}
    this.lastDataContext = undefined
    this.tableData = {}
    this.fetchedDataForLines = new WeakMap()
    this.fetchedDataForProducts = new WeakMap()
    this.fetchedDataForVariants = new WeakMap()
    this.promisesByDataKey = {}
    this.promisesByPromiseKey = {}
    this.openPromisesByVariantKey = new Map()
    this.isResolving = false
    this.resolveCallback = null
    this.queuedPromiseResolves = []
    this.initializedByDataKey = {}
  }

  isInitialized(dataConfigs) {
    let isInitialized = true

    for (let dataKey in dataConfigs) {
      if (this.initializedByDataKey[dataKey] !== true) {
        isInitialized = false
        break
      }
    }

    return isInitialized
  }

  getDataCacheData(products, dataConfigs) {
    for (let dataKey in dataConfigs) {
      const dataConfig = dataConfigs[dataKey]
      const defaultValue = dataConfig.array === true ? [] : null
      const promisesOfDataKey = this.promisesByDataKey[dataKey] || {}
      const openPromises = Object.keys(promisesOfDataKey).length > 0

      if (dataConfig.data_level === 'table') {
        const data = this.tableData[dataKey]

        this.dataCache.table[dataKey] = {
          ...(this.dataCache.table[dataKey] || {}),
          isFetching: openPromises,
          data: data || defaultValue,
        }
      } else {
        const data = this.dataByDataKey[dataKey] || []
        const dataGroupedByProduct = groupBy(data, 'product_id')

        for (let product of products) {
          if (!this.dataCache.product[product.id]) {
            this.dataCache.product[product.id] = {}
            // make sure it re-renders
          } else {
            this.dataCache.product[product.id] = {
              ...this.dataCache.product[product.id],
            }
          }

          const dataForProduct =
            dataGroupedByProduct[product.id] || defaultValue

          this.dataCache.product[product.id][dataKey] = {
            ...(this.dataCache.product[product.id][dataKey] || {}),
            isFetching: openPromises,
            data:
              dataConfig.data_level === 'product'
                ? dataForProduct
                : groupBy(dataForProduct, 'variant_id'),
          }
        }
      }
    }

    this.dataCache = { ...this.dataCache }

    // TODO: FIX
    return JSON.parse(JSON.stringify(this.dataCache))
  }

  hasFetchedDataForLine(line) {
    return this.fetchedDataForLines.has(line)
  }

  hasFetchedDataForProduct(product) {
    return this.fetchedDataForProducts.has(product)
  }

  hasFetchedDataForVariant(variant) {
    return this.fetchedDataForVariants.has(variant)
  }

  queuePromiseResolve(data) {
    this.queuedPromiseResolves.push(data)

    if (!this.isResolving) {
      this.startResolving()
    }
  }

  purgeCacheIfDataContextChanged(dataContext) {
    if (this.lastDataContext !== dataContext) {
      this.reset()
    }
  }

  setLastDataContext(dataContext) {
    this.lastDataContext = dataContext
  }

  startResolving() {
    this.isResolving = true

    while (this.queuedPromiseResolves.length > 0) {
      const { data, dataConfig, promiseData } =
        this.queuedPromiseResolves.shift()

      const { dataKey, promise, promiseKey } = promiseData

      // a newer promise for this data key exists
      const currentPromise = this.promisesByPromiseKey[promiseKey]
      if (currentPromise && currentPromise !== promise) {
        continue
      }

      if (dataConfig.data_level === 'table') {
        this.tableData[dataKey] = data
        this.initializedByDataKey[dataKey] = true
      } else if (dataConfig.data_level === 'product') {
        const currentDataByProduct = groupBy(
          this.dataByDataKey[dataKey] || [],
          'product_id'
        )
        const newCurrentDataByProduct = groupBy(data, 'product_id')

        for (let [k, v] of Object.entries(newCurrentDataByProduct)) {
          currentDataByProduct[k] = v
        }

        let newData = []
        for (let [k, v] of Object.entries(currentDataByProduct)) {
          newData = newData.concat(v)
        }

        this.dataByDataKey[dataKey] = newData
        this.initializedByDataKey[dataKey] = true
      } else {
        const currentDataByVariant = groupBy(
          this.dataByDataKey[dataKey] || [],
          'variant_id'
        )
        const newCurrentDataByVariant = groupBy(data, 'variant_id')

        for (let [k, v] of Object.entries(newCurrentDataByVariant)) {
          currentDataByVariant[k] = v
        }

        let newData = []
        for (let [k, v] of Object.entries(currentDataByVariant)) {
          newData = newData.concat(v)
        }

        this.dataByDataKey[dataKey] = newData
        this.initializedByDataKey[dataKey] = true
      }

      delete this.promisesByDataKey[dataKey][promiseKey]
    }

    this.isResolving = false

    let hasOpenPromises = false
    for (let [dataKey, promisesOfDataKey] of Object.entries(
      this.promisesByDataKey
    )) {
      if (Object.keys(promisesOfDataKey).length > 0) {
        hasOpenPromises = true
        break
      }
    }

    if (this.resolveCallback && hasOpenPromises == false) {
      this.resolveCallback()
    }
  }

  setHasFetchedDataForLine(line) {
    this.fetchedDataForLines.set(line)
  }

  setHasFetchedDataForProduct(product) {
    this.fetchedDataForProducts.set(product)
  }

  setHasFetchedDataForVariant(variant) {
    this.fetchedDataForVariants.set(variant)
  }

  setNewPromises(dataConfigs, newPromises, resolveCallback) {
    this.resolveCallback = resolveCallback

    for (let promiseData of newPromises) {
      const { dataKey, promise, promiseKey } = promiseData

      if (!this.promisesByDataKey[dataKey]) {
        this.promisesByDataKey[dataKey] = {}
      }

      this.promisesByDataKey[dataKey][promiseKey] = promise
      this.promisesByPromiseKey[promiseKey] = promise

      const dataConfig = dataConfigs[dataKey]

      // TODO: What should happen here
      if (!dataConfig) {
        continue
      }

      promise.then(fetchedData => {
        this.queuePromiseResolve({
          data: fetchedData,
          dataConfig,
          promiseData,
        })
      })
    }
  }
}

export default DataCache
