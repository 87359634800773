/* @flow */

import { useEffect, useState } from 'react'

import { quickCreateHook } from '../../hooks'
import {
  getInvoiceAttributes,
  getCreditNote,
  getInvoice,
  getConsolidatedInvoice,
} from '../api'

import type { DataTableAttribute, CreditNote, Invoice } from '../../types'

export const useInvoiceAttributes = () => {
  const [state, setState] = useState<{
    attributes: Array<DataTableAttribute>,
    isFetching: boolean,
  }>({
    attributes: [],
    isFetching: false,
  })

  useEffect(() => {
    setState(s => ({
      ...s,
      isFetching: true,
    }))

    getInvoiceAttributes().then(response => {
      if (!response.error) {
        setState({
          isFetching: false,
          attributes: response.payload.attributes,
        })
      } else {
        setState(s => ({
          ...s,
          isFetching: false,
        }))
      }
    })
  }, [])

  return [state.attributes, state.isFetching]
}

const {
  hook: useCreditNote,
  hookCached: useCachedCreditNote,
  clearCache: clearCreditNoteCache,
} = quickCreateHook<CreditNote>(getCreditNote, 'credit_note', null)

export { useCreditNote, useCachedCreditNote, clearCreditNoteCache }

const {
  hook: useInvoice,
  hookCached: useCachedInvoice,
  clearCache: clearInvoiceCache,
} = quickCreateHook<Invoice>(getInvoice, 'invoice', null)

export { useInvoice, useCachedInvoice, clearInvoiceCache }

const {
  hook: useConsolidatedInvoice,
  hookCached: useCachedConsolidatedInvoice,
  clearCache: clearConsolidatedInvoiceCache,
} = quickCreateHook(getConsolidatedInvoice, 'consolidated_invoice', null)

export {
  useConsolidatedInvoice,
  useCachedConsolidatedInvoice,
  clearConsolidatedInvoiceCache,
}
