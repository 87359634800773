/* @flow */

import * as React from 'react'
import PropTypes from 'prop-types'
import locale from '../modules/localization'
import Globalize from '../modules/globalize'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'

import type { Currency } from '../../app/types'

type Props = {
  children?: React.Node,
  currency: Currency,
  render?: (value: string, props: Object) => React.Node,
}

function FormatCurrency(props: Props) {
  const {
    currency = locale('getCurrency'),
    children,
    decimalPoints = 2,
    ...rest
  } = props
  const input = format(currency, children, decimalPoints)

  if (props.render) {
    return props.render(input, rest)
  }

  return <span {...rest}>{input}</span>
}

export default FormatCurrency

export const formatNumber = (currency = locale('getCurrency'), input) => {
  let value = format(currency, input).replace(
    new RegExp(` ${currency}`, 'g'),
    ''
  )

  if (String(value.slice(-2)) === '00') {
    return String(value).substr(0, String(value).length - 3)
  }

  return value
}

export const format = (
  currency = locale('getCurrency'),
  input,
  decimalPoints
) => {
  const formatter = Globalize.currencyFormatter(currency)

  let parsedInput = input
  // parseFloat will parse numbers with , in them. We therefore check before formatting.
  // So we only format, when the input is a valid float.
  if (
    isNumber(parsedInput) ||
    (isString(parsedInput) &&
      parsedInput.indexOf(',') === -1 &&
      !isNaN(parseFloat(parsedInput)))
  ) {
    parsedInput = formatter(parseFloat(parsedInput), decimalPoints)
  }

  return parsedInput
}
