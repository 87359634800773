import { resolveCloudinaryResource } from '../CloudinaryResource'

const resolveSingleCustomFont = async font => {
  const styles = font.config.styles.map(({ file, fontStyle }) => {
    const { url } = resolveCloudinaryResource({
      id: file.path,
      isFile: true,
    })

    return { url, fontStyle, family: font.name }
  })

  await Promise.all(
    styles.map(({ url, fontStyle, family }) => {
      return new FontFace(family, `url(${url})`, fontStyle)
        .load()
        .then(function (loadedFont) {
          document.fonts.add(loadedFont)
        })
    })
  )

  return { family: font.name, category: 'sans-serif' }
}

const resolveSingleGoogleFont = async ({
  font,
  weight = [400, 700],
  subsets,
  display,
}) => {
  const family = font.config.family.replace(/ +/g, '+')
  const weights = weight.join(',')
  const familyString = weights ? `${family}:${weights}` : family

  const link = document.createElement('link')
  link.rel = 'stylesheet'
  link.href = `https://fonts.googleapis.com/css?family=${familyString}`

  if (subsets && Array.isArray(subsets) && subsets.length > 0) {
    link.href += `&subset=${subsets.join(',')}`
  }

  if (display) {
    link.href += `&display=${display}`
  }

  document.head.appendChild(link)

  return { family: font.config.family, category: font.config.category }
}

const resolveMultipleGoogleFonts = ({ fonts, subsets, display }) => {
  const families = fonts
    .reduce((acc, font) => {
      const family = font.font.replace(/ +/g, '+')
      const weights = (font.weights || []).join(',')

      return [...acc, family + (weights && `:${weights}`)]
    }, [])
    .join('|')

  const link = document.createElement('link')
  link.rel = 'stylesheet'
  link.href = `https://fonts.googleapis.com/css?family=${families}`

  if (subsets && Array.isArray(subsets) && subsets.length > 0) {
    link.href += `&subset=${subsets.join(',')}`
  }

  if (display) {
    link.href += `&display=${display}`
  }

  document.head.appendChild(link)
}

export const resolveSingleFont = async font => {
  if (font.font_type === 'custom') {
    return resolveSingleCustomFont(font)
  }

  if (font.font_type === 'google_font') {
    return resolveSingleGoogleFont({ font, subsets: font.config.subsets })
  }
}

export const resolveMultipleFontsInBackground = ({
  googleFonts = [],
  customFonts = [],
}) => {
  if (googleFonts.length > 0) {
    return resolveMultipleGoogleFonts({ fonts: googleFonts })
  }

  if (customFonts.length > 0) {
    customFonts.forEach(font => resolveSingleCustomFont(font))
  }
}
