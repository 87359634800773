/* @flow */

import React, { Component } from 'react'
import styled from 'styled-components'

import { RadioInput } from '../../Formik'

type Props = {
  availableValues: Array<{ label: string, value: string }>,
  value: string,
}

export default class EnumFilter extends Component<Props, void> {
  render() {
    const { availableValues, value } = this.props

    return (
      <div>
        {availableValues.map(({ label, value }) => {
          return (
            <RadioInput
              id={`filter_radio_${value}`}
              name="value"
              label={label}
              value={value}
            />
          )
        })}
      </div>
    )
  }
}
