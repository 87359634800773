/* @flow */

import { quickCreateHook } from '../hooks'

import {
  getOrderChannel,
  getOrderChannels,
  getOrderChannelClassifications,
  getOrderChannelFieldMappings,
  getOrderChannelProductFields,
  getOrderChannelSchema,
  getOrderChannelsProductAttributes,
  getOrderChannelsProductMapping,
  getOrderChannelProductSettings,
  getOrderChannelsProductSyncStatus,
} from './api'

const {
  hook: useOrderChannel,
  hookCached: useCachedOrderChannel,
  clearCache: clearOrderChannelCache,
} = quickCreateHook(getOrderChannel, 'order_channel')

export { useOrderChannel, useCachedOrderChannel, clearOrderChannelCache }

const {
  hook: useOrderChannels,
  hookCached: useCachedOrderChannels,
  clearCache: clearOrderChannelsCache,
} = quickCreateHook(getOrderChannels, 'order_channels', [])

export { useOrderChannels, useCachedOrderChannels, clearOrderChannelsCache }

const {
  hook: useOrderChannelsProductAttributes,
  hookCached: useCachedOrderChannelsProductAttributes,
  clearCache: clearOrderChannelsProductAttributesCache,
} = quickCreateHook(getOrderChannelsProductAttributes, 'attributes', [])

export {
  useOrderChannelsProductAttributes,
  useCachedOrderChannelsProductAttributes,
  clearOrderChannelsProductAttributesCache,
}

const {
  hook: useOrderChannelsProductMapping,
  hookCached: useCachedOrderChannelsProductMapping,
  clearCache: clearOrderChannelsProductMappingCache,
} = quickCreateHook(getOrderChannelsProductMapping, 'mapping', {
  schema: null,
  resolved_values: null,
})

export {
  useOrderChannelsProductMapping,
  useCachedOrderChannelsProductMapping,
  clearOrderChannelsProductMappingCache,
}

const {
  hook: useOrderChannelProductSettings,
  hookCached: useCachedOrderChannelProductSettings,
  clearCache: clearOrderChannelProductSettingsCache,
} = quickCreateHook(
  getOrderChannelProductSettings,
  'product_order_channels',
  []
)

export {
  useOrderChannelProductSettings,
  useCachedOrderChannelProductSettings,
  clearOrderChannelProductSettingsCache,
}

const {
  hook: useOrderChannelsProductSyncStatus,
  hookCached: useCachedOrderChannelsProductSyncStatus,
  clearCache: clearOrderChannelsProductSyncStatusCache,
} = quickCreateHook(getOrderChannelsProductSyncStatus, 'sync_status', [])

export {
  useOrderChannelsProductSyncStatus,
  useCachedOrderChannelsProductSyncStatus,
  clearOrderChannelsProductSyncStatusCache,
}

const {
  hook: useOrderChannelFieldMappings,
  hookCached: useCachedOrderChannelFieldMappings,
  clearCache: clearOrderChannelFieldMappingsCache,
} = quickCreateHook(getOrderChannelFieldMappings, 'field_mappings', [])

export {
  useOrderChannelFieldMappings,
  useCachedOrderChannelFieldMappings,
  clearOrderChannelFieldMappingsCache,
}

const {
  hook: useOrderChannelProductFields,
  hookCached: useCachedOrderChannelProductFields,
  clearCache: clearOrderChannelProductFieldsCache,
} = quickCreateHook(getOrderChannelProductFields, 'product_fields', [])

export {
  useOrderChannelProductFields,
  useCachedOrderChannelProductFields,
  clearOrderChannelProductFieldsCache,
}

const {
  hook: useOrderChannelClassifications,
  hookCached: useCachedOrderChannelClassifications,
  clearCache: clearOrderChannelClassificationsCache,
} = quickCreateHook(getOrderChannelClassifications, 'classifications', [])

export {
  useOrderChannelClassifications,
  useCachedOrderChannelClassifications,
  clearOrderChannelClassificationsCache,
}

const {
  hook: useOrderChannelSchema,
  hookCached: useCachedOrderChannelSchema,
  clearCache: clearOrderChannelSchemaCache,
} = quickCreateHook(getOrderChannelSchema, 'schema', [])

export {
  useOrderChannelSchema,
  useCachedOrderChannelSchema,
  clearOrderChannelSchemaCache,
}
