/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import sortBy from 'lodash/sortBy'
import { Field } from 'redux-form'

import { ErrorLabel } from '../../infrastructure/components/Forms'

import type { Supplier } from '../types'
import { useCachedSuppliers } from './hooks'

type Props = {
  value?: number,
}

const SupplierSelector = (props: Props) => {
  const [suppliers, isFetching] = useCachedSuppliers()

  return (
    <DatalessSupplierSelector
      isFetching={isFetching}
      suppliers={suppliers}
      {...props}
    />
  )
}

export default SupplierSelector

export const DatalessSupplierSelector = ({
  suppliers,
  isFetching,
  ...rest
}) => {
  const options = sortBy(suppliers, 'name')
    .filter(s => s.active)
    .map(supplier => ({
      // in case we use simpleValue=false
      ...supplier,
      label: supplier.name,
      value: supplier.id,
    }))

  return (
    <Select
      onBlur={() => {}}
      isLoading={isFetching}
      placeholder="Select supplier..."
      options={options}
      {...rest}
    />
  )
}

const renderSupplierSelector = ({
  input: { onChange, value },
  meta: { error, touched },
  ...rest
}) => {
  return (
    <div>
      <SupplierSelector {...rest} onChange={onChange} value={value} />
      {touched && error && <ErrorLabel>{error}</ErrorLabel>}
    </div>
  )
}

export const SupplierSelectorInput = props => (
  <Field component={renderSupplierSelector} {...props} />
)
