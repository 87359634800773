/* @flow */

import React, { useMemo } from 'react'
import sortBy from 'lodash/sortBy'
import Select from 'react-select'

import { useCustomersList, useCachedCustomersList } from '../hooks'

export const useCustomersForCustomerSelector = ({
  b2c = false,
  cached = false,
  consignmentLocations = false,
  customeFilter,
  excludeOptions,
}) => {
  return [options, isFetching]
}

const CustomerSelector = ({
  cached = false,
  consignmentLocations = false,
  customFilter,
  excludeOptions,
  ...props
}) => {
  const [customers, { isFetching }] = cached
    ? useCachedCustomersList()
    : useCustomersList()

  return (
    <DatalessCustomerSelector
      customers={customers}
      isFetching={isFetching}
      consignmentLocations={consignmentLocations}
      {...props}
    />
  )
}

export default CustomerSelector

export const DatalessCustomerSelector = ({
  b2c = false,
  consignmentLocations = false,
  customers,
  customFilter,
  excludeOptions,
  isFetching,
  ...props
}) => {
  const options = useMemo(() => {
    const options = customers
      .filter(customer => {
        if (!customer.active) {
          return false
        }

        if (b2c && !customer.is_b2c_customer) {
          return false
        }

        if (consignmentLocations && customer.consignment_location_id == null) {
          return false
        }

        if (excludeOptions && excludeOptions.includes(customer.id)) {
          return false
        }

        if (customFilter && !customFilter(customer)) {
          return false
        }

        return true
      })
      .map(customer => ({
        ...customer,
        label: `${customer.name} (#${customer.customer_number || ''})`,
      }))

    return sortBy(options, customer => customer.name.toLowerCase())
  }, [customers, b2c, consignmentLocations, customFilter, excludeOptions])

  return (
    <Select
      isLoading={isFetching}
      labelKey="label"
      placeholder="Select customer..."
      options={options}
      valueKey="id"
      {...props}
    />
  )
}
