import {
  SHOW_LOADER,
  HIDE_LOADER,
  TOGGLE_FILTER_MANAGER_VISIBILITY,
  TOGGLE_TEMPLATE_BLANK,
} from '../actions/template'

const initialState = {
  blank: false,
  showFilterManager: true,
  showLoader: false,
}

export default function template(state = initialState, action) {
  switch (action.type) {
    case HIDE_LOADER:
      return Object.assign({}, state, {
        showLoader: false,
      })
    case SHOW_LOADER:
      return Object.assign({}, state, {
        showLoader: true,
      })
    case TOGGLE_FILTER_MANAGER_VISIBILITY:
      let { isVisible } = action

      return Object.assign({}, state, {
        showFilterManager: isVisible,
      })
    case TOGGLE_TEMPLATE_BLANK:
      return Object.assign({}, state, {
        blank: action.blank,
      })
    default:
      return state
  }
}
