/* @flow */

import * as React from 'react'
import { PureComponent } from 'react'
import { Field, FieldArray, type FieldProps } from 'formik'
import { FormControl, InputGroup } from 'react-bootstrap'

import { ErrorLabel } from '../Forms'
import { FiltersList, FiltersMenu } from '../../../app/datatables_v2'
import { getProductsAttributes } from '../../../app/products/api'

import type { DataTableAttribute, Filter } from '../../../app/types'

type Props = {
  name: string,
  setFieldValue: (name: string, value: mixed) => void,
  values: Array<Filter>,
}

const FiltersInput = (props: Props) => {
  return <Field component={RenderFiltersInput} {...props} />
}

export default FiltersInput

type State = {
  activeFilter: ?Filter,
}

class RenderFiltersInput extends PureComponent<FieldProps & Props, State> {
  state = {
    activeFilter: null,
  }

  _prevFetchedType: ?string = null

  render() {
    const {
      attributes,
      field: { name, value },
      form: { errors, touched },
      ...rest
    } = this.props
    const { activeFilter } = this.state

    return (
      <div>
        <FieldArray
          name={name}
          render={arrayHelpers => {
            return (
              <FiltersList
                activeFilter={activeFilter}
                attributes={attributes}
                filters={value}
                onClickFilter={this._toggleFilter}
                onChange={this._onChange}
                onHideFilter={() => this._toggleFilter(false)}
              >
                <FiltersMenu
                  attributes={attributes}
                  filters={value}
                  onChange={this._onChange}
                />
              </FiltersList>
            )
          }}
          {...rest}
        />

        {touched[name] && errors[name] && (
          <ErrorLabel>{errors[name]}</ErrorLabel>
        )}
      </div>
    )
  }

  _onChange = (
    filters: Array<Filter>,
    attribute: DataTableAttribute,
    action: 'add' | 'edit' | 'remove',
    changedFilter: Filter
  ) => {
    this.props.form.setFieldValue(this.props.field.name, filters)
    this.setState({
      showAddFilter: false,
      activeFilter: false,
    })
  }

  _toggleFilter = (filter: Filter | false) => {
    this.setState({
      activeFilter: filter,
    })
  }
}
