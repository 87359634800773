/* @flow */

import * as React from 'react'
import { ListGroup, ListGroupItem, Modal } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import debounce from 'lodash/debounce'

import {
  CheckboxInput,
  ControlLabel,
  EditorInput,
  FormInput,
  FormGroup,
  TextareaInput,
  SaveButton,
} from '../../../infrastructure/components/Formik'
import { emptyString } from '../../../infrastructure/components/Forms'
import { useAdjustEmailFormFields } from '../../../infrastructure/components/Email/AdjustEmailFormFields'
import { checkNewCustomerUserEmail } from '../api'

import { SessionContext } from '../../shared'

const CustomerAddUserModal = ({
  onHide,
  onSubmit,
  show,
}: {
  onHide: Function,
  onSubmit: Function,
  show: boolean,
}) => {
  const { settings } = React.useContext(SessionContext)

  const initialValues = React.useMemo(() => {
    return createInitialValues(settings)
  }, [settings])

  return (
    <Modal show={show} onHide={onHide} bsSize="large">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        render={({ handleSubmit, isSubmitting, values, setFieldValue }) => {
          const wrappedSubmit = e => {
            e.stopPropagation()
            handleSubmit(e)
          }

          return (
            <form onSubmit={wrappedSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Add new B2B buyer</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <NewCustomerUserFormFields
                  email={values.email}
                  send_customer_email={values.send_customer_email}
                  setFieldValue={setFieldValue}
                />
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={onHide}
                >
                  Cancel
                </button>
                <SaveButton submitting={isSubmitting}>Add buyer</SaveButton>
              </Modal.Footer>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default CustomerAddUserModal

export const createInitialValues = () => {
  return {
    email: '',
    firstname: '',
    send_customer_email: true,
    invite_email_subject: '',
    invite_email_body: '',
    use_new_form: true,
  }
}

export const NewCustomerUserFormFields = ({
  email,
  namespace = '',
  position = false,
  send_customer_email,
  telephone = false,
  setFieldValue,
}: {
  email: string,
  namespace?: string,
  position?: boolean,
  send_customer_email: boolean,
  telephone?: boolean,
  setFieldValue: Function,
}) => {
  const { entity } = React.useContext(SessionContext)

  const name = React.useCallback(
    key => (namespace ? `${namespace}.${key}` : key),
    [namespace]
  )

  const [isCheckingNewCustomerUserEmail, setIsCheckingNewCustomerUserEmail] =
    React.useState(false)
  const [isExistingUser, setIsExistingUser] = React.useState(false)

  const callCheckNewCustomerUserEmail = React.useCallback(
    emailToCheck => {
      checkNewCustomerUserEmail(emailToCheck).then(response => {
        setIsCheckingNewCustomerUserEmail(false)
        if (!response.error) {
          setIsExistingUser(response.payload.user_exists_and_activated)
        }
      })
    },
    [
      checkNewCustomerUserEmail,
      setIsExistingUser,
      setIsCheckingNewCustomerUserEmail,
    ]
  )
  const callCheckNewCustomerUserEmailDebounced = React.useMemo(
    () => debounce(callCheckNewCustomerUserEmail, 700),
    [callCheckNewCustomerUserEmail]
  )

  const isEmailFilled = email.trim() !== ''

  React.useEffect(() => {
    if (isEmailFilled) {
      setIsCheckingNewCustomerUserEmail(true)
      callCheckNewCustomerUserEmailDebounced(email)
    }
  }, [
    callCheckNewCustomerUserEmailDebounced,
    email,
    isEmailFilled,
    setIsCheckingNewCustomerUserEmail,
  ])

  const adjustEmailFormFieldsParams = React.useMemo(() => {
    let systemType = 'customers.invitation_new_user'
    let trigger = 'customers.invitation_new_user'
    if (isExistingUser) {
      systemType = 'customers.invitation_existing_user'
      trigger = 'customers.invitation_existing_user'
    }

    return {
      brandId: entity.id,
      emailableId: entity.id,
      emailableType: 'entity',
      systemType,
      trigger,
    }
  }, [isExistingUser, entity])

  const {
    initialValues: adjustEmailFormFieldsInitialValues,
    isLoaded: isAdjustEmailFormFieldsLoaded,
    sendEmailDefaultValue,
  } = useAdjustEmailFormFields(adjustEmailFormFieldsParams)

  React.useEffect(() => {
    if (
      isEmailFilled &&
      adjustEmailFormFieldsInitialValues.email !== undefined
    ) {
      setFieldValue(name('send_customer_email'), sendEmailDefaultValue)
      setFieldValue(
        name('invite_email_subject'),
        adjustEmailFormFieldsInitialValues.email.subject
      )
      setFieldValue(
        name('invite_email_body'),
        adjustEmailFormFieldsInitialValues.email.body
      )
    }
  }, [
    isEmailFilled,
    sendEmailDefaultValue,
    adjustEmailFormFieldsInitialValues,
    name,
  ])

  const isLoadingEmailSection =
    isCheckingNewCustomerUserEmail || !isAdjustEmailFormFieldsLoaded

  return (
    <div>
      <FormGroup>
        <div className="form-group">
          <div className="row">
            <div className="col-xs-6">
              <ControlLabel required>First Name</ControlLabel>
              <FormInput
                type="text"
                name={name('firstname')}
                placeholder="First name"
              />
            </div>
            <div className="col-xs-6">
              <ControlLabel required>Email</ControlLabel>
              <FormInput
                type="email"
                name={name('email')}
                placeholder="Email address"
              />
            </div>
          </div>
        </div>
        {(telephone === true || position === true) && (
          <div className="row">
            {position && (
              <div className="col-xs-6">
                <ControlLabel>Position</ControlLabel>
                <FormInput
                  type="text"
                  name={name('title')}
                  placeholder="Position"
                />
              </div>
            )}
            {telephone === true && (
              <div className="col-xs-6">
                <ControlLabel>Telephone</ControlLabel>
                <FormInput
                  type="tel"
                  name={name('telephone')}
                  placeholder="Telephone"
                />
              </div>
            )}
          </div>
        )}
      </FormGroup>
      {!entity.freemium && (
        <>
          <FormGroup>
            {!isEmailFilled && (
              <ListGroup>
                <ListGroupItem bsStyle="warning">
                  To send invitation email, fill out the email field first
                </ListGroupItem>
              </ListGroup>
            )}
            {isEmailFilled && isLoadingEmailSection && <div>Loading ...</div>}
            {isEmailFilled && !isLoadingEmailSection && (
              <>
                <CheckboxInput
                  id="send-customer-email"
                  name={name('send_customer_email')}
                  label="Send invitation e-mail to the buyer"
                />
                <ListGroup>
                  <ListGroupItem bsStyle="warning">
                    {send_customer_email && isExistingUser && (
                      <>
                        An invite will be sent to existing Traede account{' '}
                        {email}
                      </>
                    )}
                    {send_customer_email && !isExistingUser && (
                      <>
                        An invite as an account activation email will be sent to{' '}
                        {email}
                      </>
                    )}
                    {!send_customer_email && (
                      <p style={{ marginBottom: 0 }}>
                        {isExistingUser ? (
                          <>
                            An invite will not be sent to the existing Traede
                            account {email}.
                          </>
                        ) : (
                          <>
                            An invite as an account activation email will not be
                            sent to
                            {email}.
                          </>
                        )}{' '}
                        You can change the default behaviour in{' '}
                        <a href="/settings/emails" target="_blank">
                          Email settings
                        </a>
                      </p>
                    )}
                  </ListGroupItem>
                </ListGroup>

                {send_customer_email && (
                  <>
                    <FormGroup>
                      <ControlLabel>Subject</ControlLabel>
                      <FormInput name={name('invite_email_subject')} />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Body</ControlLabel>
                      <EditorInput
                        name={name('invite_email_body')}
                        height={150}
                      />
                    </FormGroup>
                  </>
                )}
              </>
            )}
          </FormGroup>
        </>
      )}
    </div>
  )
}

const firstnameErrorMessage = 'Please the name of the person you want to invite'
const emailErrorMessage =
  'Please enter the e-mail of the person you want to invite'

export const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .label('first name')
    .required(firstnameErrorMessage)
    .min(1)
    .typeError(firstnameErrorMessage),
  email: Yup.string()
    .label('e-mail')
    .required(emailErrorMessage)
    .min(1)
    .typeError(emailErrorMessage),
})
