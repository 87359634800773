// @flow

import * as React from 'react'

import {
  createWebshopSession,
  deleteWebshopSession,
  ensureWebshopSessionFromOrderSelection,
} from '../../api'
import { useWebshopSessionList } from '../../hooks'
import { msg } from '../../../shared'

export const useWebshopSessionListActions = ({
  allowSelectingCustomer,
  availableConnections,
  brand,
  currentSession,
  onHide,
  setUseWebshopSessionId,
  updateWebshopSession,
}) => {
  const [
    sessions,
    isFetchingWebshopSessionList,
    { isInitialized, refresh: refreshSessions },
  ] = useWebshopSessionList(brand.id)

  const [showNewOrderModal, setShowNewOrderModal] = React.useState(false)
  const [showDeleteOrderModal, setShowDeleteOrderModal] = React.useState(false)

  const startNewSession = React.useCallback(
    (values, formikProps) => {
      return createWebshopSession(brand.id, values).then(response => {
        if (formikProps && formikProps.setSubmitting) {
          formikProps.setSubmitting(false)
        }

        if (!response.error) {
          setShowNewOrderModal(false)
          onHide()

          setUseWebshopSessionId(response.payload.id)
        }
      })
    },
    [brand, setShowNewOrderModal, onHide, setUseWebshopSessionId]
  )

  const onDeleteWebshopSession = React.useCallback(
    session => {
      const deleteId = session.id
      const otherSession = sessions.find(
        session => session.id != deleteId && session.type === 'webshop_session'
      )

      return deleteWebshopSession(brand.id, session.id).then(response => {
        if (!response.error) {
          msg('success', 'The order was deleted')

          refreshSessions()

          if (deleteId == currentSession.id) {
            setUseWebshopSessionId(otherSession ? otherSession.id : null)
          }
        }
      })
    },
    [brand, refreshSessions, sessions, currentSession, setUseWebshopSessionId]
  )

  const onClickNewOrder = React.useCallback(() => {
    if (allowSelectingCustomer) {
      setShowNewOrderModal(true)
    } else {
      return startNewSession({ customer_id: currentSession.customer_id })
    }
  }, [
    allowSelectingCustomer,
    currentSession,
    startNewSession,
    setShowNewOrderModal,
  ])

  const onChangeToSession = React.useCallback(
    session => {
      if (session.type === 'webshop_session') {
        return setUseWebshopSessionId(session.id)
      }

      // is order selection
      // should always go to API in case we need to create new session
      // (e.g. if selection was made by brand user but finished by customer)
      return ensureWebshopSessionFromOrderSelection(session.brand_id, [
        session.order_id,
      ]).then(response => {
        if (!response.error) {
          setUseWebshopSessionId(response.payload.id)
        }
      })
    },
    [setUseWebshopSessionId]
  )

  const onClickNewDropShippingOrder = React.useCallback(() => {
    return startNewSession({
      customer_id: currentSession.customer_id,
      dropshipping: true,
    })
  }, [currentSession, startNewSession])

  const canMakeDropShippingOrder = React.useMemo(() => {
    return (
      availableConnections.filter(
        connection => connection.dropshipping === true
      ).length > 0
    )
  }, [availableConnections])

  const [oneSessionHasBrand, oneSessionHasDrop] = React.useMemo(() => {
    let oneSessionHasBrand = false
    let oneSessionHasDrop = false

    for (let session of sessions) {
      if (session.subbrand_name) {
        oneSessionHasBrand = true
      }
      if (session.drop_name) {
        oneSessionHasDrop = true
      }
    }

    return [oneSessionHasBrand, oneSessionHasDrop]
  }, [sessions])

  const onConvertToDropshippingOrder = React.useCallback(() => {
    return updateWebshopSession({
      dropshipping: true,
    }).then(() => {
      refreshSessions()
    })
  }, [refreshSessions, updateWebshopSession])

  const onConvertToNormalOrder = React.useCallback(() => {
    return updateWebshopSession({
      dropshipping: false,
    }).then(() => {
      refreshSessions()
    })
  }, [refreshSessions, updateWebshopSession])

  return {
    canMakeDropShippingOrder,
    onChangeToSession,
    onClickNewDropShippingOrder,
    onClickNewOrder,
    onConvertToDropshippingOrder,
    onConvertToNormalOrder,
    onDeleteWebshopSession,
    oneSessionHasBrand,
    oneSessionHasDrop,
    refreshSessions,
    sessions,
    setShowDeleteOrderModal,
    setShowNewOrderModal,
    showDeleteOrderModal,
    showNewOrderModal,
    startNewSession,
  }
}
