/* @flow */

import * as React from 'react'
import styled from 'styled-components'

import WebshopActionButton from '../WebshopActionButton'
import FormatCurrency from '../../../../infrastructure/components/FormatCurrency'
import WebshopCustomerSelectorModal from '../WebshopCustomerSelectorModal'
import WebshopMobileModal from '../WebshopMobileModal'
import DeleteSessionModal from './DeleteSessionModal'
import { useWebshopSessionListActions } from './shared'

import { Tooltip, renderDateTime } from '../../../shared'
import type { ConnectedBrand, Entity, WebshopSession } from '../../../types'
import { WebshopContext } from '../../shared'

type Props = {
  allowSelectingCustomer: boolean,
  brand: ConnectedBrand,
  currentSession: WebshopSession,
  entity: Entity,
  onHide: Function,
  show: boolean,
  webshopSettings: WebshopSettings,
}

const WebshopMobileSessionListModal = ({
  allowSelectingCustomer,
  availableConnections,
  brand,
  currentSession,
  entity,
  onHide,
  setUseWebshopSessionId,
  show,
  webshopSettings,
}: Props) => {
  const { updateWebshopSession } = React.useContext(WebshopContext)

  const {
    canMakeDropShippingOrder,
    onChangeToSession,
    onClickNewDropShippingOrder,
    onClickNewOrder,
    onConvertToDropshippingOrder,
    onConvertToNormalOrder,
    onDeleteWebshopSession,
    oneSessionHasBrand,
    oneSessionHasDrop,
    refreshSessions,
    sessions,
    setShowDeleteOrderModal,
    setShowNewOrderModal,
    showDeleteOrderModal,
    showNewOrderModal,
    startNewSession,
  } = useWebshopSessionListActions({
    allowSelectingCustomer,
    availableConnections,
    brand,
    currentSession,
    onHide,
    setUseWebshopSessionId,
    updateWebshopSession,
  })

  React.useEffect(() => {
    if (show) {
      refreshSessions()
    }
  }, [refreshSessions, show])

  return (
    <div>
      {showNewOrderModal && (
        <WebshopCustomerSelectorModal
          currentCustomerId={null}
          onHide={() => setShowNewOrderModal(false)}
          onSave={startNewSession}
          show={showNewOrderModal}
          title="Start a new order"
        />
      )}

      {showDeleteOrderModal !== false && (
        <DeleteSessionModal
          onHide={() => setShowDeleteOrderModal(false)}
          onSave={() => onDeleteWebshopSession(showDeleteOrderModal)}
          show={showDeleteOrderModal !== false}
        />
      )}

      <WebshopMobileModal
        title="Current unfinished orders"
        show={show}
        onHide={onHide}
      >
        <SessionListTable>
          <thead>
            <tr>
              <th>
                No.
                {allowSelectingCustomer && (
                  <>
                    {' / '}
                    {entity.entity_type === 'shop' ? 'Shopping as' : 'Customer'}
                  </>
                )}
              </th>
              <SessionQuantityAndPriceHeaderCell>
                Total quantity / price
              </SessionQuantityAndPriceHeaderCell>
            </tr>
          </thead>
          <tbody>
            {sessions.map(session => {
              return (
                <React.Fragment key={session.id}>
                  <tr>
                    <SessionTitleCell>
                      <PrimaryLabel>
                        #{session.session_number}
                        {allowSelectingCustomer
                          ? ' / ' + session.customer_name
                          : ''}
                      </PrimaryLabel>

                      {session.type === 'order_selection' && (
                        <span className="label label-warning label-sm">
                          Selection
                        </span>
                      )}
                      {session.dropshipping && (
                        <span className="label label-warning label-sm">
                          Dropshipping
                        </span>
                      )}

                      {oneSessionHasBrand && (
                        <SecondaryLabel>
                          Brand: {session.subbrand_name}
                        </SecondaryLabel>
                      )}
                      {oneSessionHasDrop && (
                        <SecondaryLabel>
                          Drop: {session.drop_name}
                        </SecondaryLabel>
                      )}
                    </SessionTitleCell>
                    <SessionQuantityAndPriceCell>
                      <PrimaryLabel>
                        {session.quantity || 0}
                        {session.currency && (
                          <>
                            {' / '}
                            <FormatCurrency currency={session.currency}>
                              {session.price || 0}
                            </FormatCurrency>
                          </>
                        )}
                      </PrimaryLabel>
                      <SecondaryLabel>
                        Started at {renderDateTime(session.created_at)}
                      </SecondaryLabel>
                    </SessionQuantityAndPriceCell>
                  </tr>
                  <tr>
                    <SessionActionsCell colSpan={2}>
                      <SessionActionsContainer>
                        {session.id === currentSession.id && (
                          <>
                            <span className="label label-success label-sm">
                              Current order
                            </span>
                            {currentSession.customer &&
                              currentSession.customer.dropshipping === true && (
                                <>
                                  {!session.dropshipping && (
                                    <WebshopActionButton
                                      size="xsmall"
                                      color="white"
                                      onClick={onConvertToDropshippingOrder}
                                    >
                                      Convert to dropshipping order
                                    </WebshopActionButton>
                                  )}
                                  {session.dropshipping && (
                                    <WebshopActionButton
                                      size="xsmall"
                                      color="white"
                                      onClick={onConvertToNormalOrder}
                                    >
                                      Convert to normal order
                                    </WebshopActionButton>
                                  )}
                                </>
                              )}
                          </>
                        )}
                        {session.id != currentSession.id && (
                          <WebshopActionButton
                            size="xsmall"
                            color="white"
                            onClick={() => onChangeToSession(session)}
                          >
                            Change to this order
                          </WebshopActionButton>
                        )}
                        <WebshopActionButton
                          size="xsmall"
                          color="white"
                          onClick={() => onDeleteWebshopSession(session)}
                        >
                          <span className="glyphicon glyphicon-remove" />
                        </WebshopActionButton>
                      </SessionActionsContainer>
                    </SessionActionsCell>
                  </tr>
                </React.Fragment>
              )
            })}
          </tbody>
        </SessionListTable>

        {!allowSelectingCustomer && canMakeDropShippingOrder && (
          <WebshopActionButton
            block
            color="white"
            onClick={onClickNewDropShippingOrder}
          >
            <span className="glyphicon glyphicon-plus" /> Start new dropshipping
            order
          </WebshopActionButton>
        )}

        <WebshopActionButton block color="primary" onClick={onClickNewOrder}>
          <span className="glyphicon glyphicon-plus" /> Start new order
        </WebshopActionButton>
      </WebshopMobileModal>
    </div>
  )
}

export default WebshopMobileSessionListModal

const SessionListTable = styled.table.attrs({
  className: 'table table-condensed',
})``

const SessionTitleCell = styled.td`
  vertical-align: top !important;
`

const SessionQuantityAndPriceHeaderCell = styled.th`
  text-align: right;
`

const SessionQuantityAndPriceCell = styled.td`
  text-align: right;
`

const SessionActionsCell = styled.td`
  border: none !important;
`

const SessionActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`

const PrimaryLabel = styled.div`
  font-weight: bold;
`

const SecondaryLabel = styled.div`
  color: #909090;
`
