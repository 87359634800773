/* @flow */

import { useEffect, useState } from 'react'

import { createApiHook, createDataCache, quickCreateHook } from '../hooks'

import {
  getAgencies,
  getCustomer,
  getCustomerGroups,
  getCustomersAttributes,
  getCustomersList,
  getCustomerUsersStatus,
  getPriceLists,
  getProspectAttributes,
} from './api'
import type {
  BrunoRequestOptions,
  Customer,
  CustomerGroup,
  DataTableAttribute,
  Entity,
  EntityUser,
  Id,
} from '../types'

const fetchCustomerForHook = (customerId: Id, options?: BrunoRequestOptions) =>
  getCustomer(customerId, options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload,
      }
    } else {
      return response
    }
  })

export const useCustomer = createApiHook<Customer | null>(fetchCustomerForHook)

const fetchCustomerGroupsForHook = (options?: BrunoRequestOptions) =>
  getCustomerGroups(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.customer_groups,
      }
    } else {
      return response
    }
  })

export const useCustomerGroups = createApiHook<Array<CustomerGroup>>(
  fetchCustomerGroupsForHook,
  []
)

const {
  cache: { clearCache: clearCustomerGroupsCache },
  hook: useCachedCustomerGroups,
} = createDataCache(useCustomerGroups)

export { clearCustomerGroupsCache, useCachedCustomerGroups }

const {
  hook: useCustomersList,
  hookCached: useCachedCustomersList,
  clearCache: clearCustomersListCache,
} = quickCreateHook<Array<Customer>>(getCustomersList, 'customers', [])

export { useCustomersList, useCachedCustomersList, clearCustomersListCache }

const {
  hook: useCustomersAttributes,
  hookCached: useCachedCustomersAttributes,
  clearCache: clearCustomersAttributesCache,
} = quickCreateHook<Array<DataTableAttribute>>(
  getCustomersAttributes,
  'attributes',
  []
)

export {
  useCustomersAttributes,
  useCachedCustomersAttributes,
  clearCustomersAttributesCache,
}

const {
  hook: useProspectAttributes,
  hookCached: useCachedProspectAttributes,
  clearCache: clearProspectAttributesCache,
} = quickCreateHook<Array<DataTableAttribute>>(
  getProspectAttributes,
  'attributes',
  []
)

export {
  useProspectAttributes,
  useCachedProspectAttributes,
  clearProspectAttributesCache,
}

const {
  hook: usePriceLists,
  hookCached: useCachedPriceLists,
  clearCache: clearPriceListsCache,
} = quickCreateHook<Array<DataTableAttribute>>(getPriceLists, 'price_lists', [])

export { usePriceLists, useCachedPriceLists, clearPriceListsCache }

const {
  hook: useCustomerUsersStatus,
  hookCached: useCachedCustomerUsersStatus,
  clearCache: clearCustomerUsersStatusCache,
} = quickCreateHook<Array<DataTableAttribute>>(
  getCustomerUsersStatus,
  'users',
  [],
  { v2: true }
)

export {
  useCustomerUsersStatus,
  useCachedCustomerUsersStatus,
  clearCustomerUsersStatusCache,
}
