/* @flow */

import * as React from 'react'
import { PureComponent } from 'react'
import { FastField, Field, type FieldProps } from 'formik'
import Select, { Creatable } from 'react-select'
import get from 'lodash/get'
import { ErrorLabel } from '../Forms'

type BaseProps = {
  create?: boolean,
  onBlur?: () => void,
  onChange: (value: string) => void,
  noError?: boolean,
  formikFastField?: boolean,
}

type SingleProps = BaseProps & {
  multi?: false,
  value: string,
}

type MultiProps = BaseProps & {
  multi: true,
  value: Array<string>,
}

type PropsWithoutOptions = SingleProps | MultiProps

type WithSelectComponent = PropsWithoutOptions & {
  selectComponent: React.ComponentType<{
    onChange: (value: mixed) => void,
    options: Array<Object>,
    value?: mixed,
  }>,
}

type WithOptions = PropsWithoutOptions & {
  options: Array<Object>,
}

type Props = WithSelectComponent | WithOptions

const SelectInput = (props: {
  ...$Diff<Props, { onChange: (value: string) => void }>,
  onChange?: (value: string) => void,
}) => {
  const { formikFastField, ...restProps } = props

  // FastField re-renders only when the value of field changes and on few other occassions
  // limiting the number of re-renders. Useful when form starts to slow down because of lot
  // of fields, but can block other needed renders, be careful when using it.
  const FieldComponent = formikFastField === true ? FastField : Field

  return <FieldComponent component={renderSelectInput} {...restProps} />
}

const MULTI_DELIMITER = '!@#$%^&^%$#@@!'

export default SelectInput

export const renderSelectInput = ({
  selectComponent: SelectComponent = Select,
  field: { onBlur, name, onChange, value },
  form: { setFieldValue, touched, errors },
  onChange: propsOnChange,
  ...props
}: FieldProps & Props) => {
  const [createdValues, setCreatedValue] = React.useState(value)

  const onChangeWrapper = React.useCallback(
    value => {
      let parsedValue
      if (props.multi && props.simpleValue && !Array.isArray(value)) {
        parsedValue = value.split(MULTI_DELIMITER).filter(v => v)
      } else {
        parsedValue = value
      }

      setFieldValue(name, parsedValue)

      if (props.create === true) {
        setCreatedValue(parsedValue)
      }

      if (propsOnChange) {
        propsOnChange(parsedValue)
      }
    },
    [
      name,
      props.create,
      props.multi,
      props.simpleValue,
      setFieldValue,
      setCreatedValue,
    ]
  )

  const { options, ...restProps } = props

  const optionsIncludingCreatedOptions = React.useMemo(() => {
    if (props.create !== true) {
      return options
    }

    return options.concat(
      createdValues.map(val => ({ label: val, value: val }))
    )
  }, [props.create, options, createdValues])

  // This is important. If we simply set the prop options, then we will override
  // options set by custom selectors, like SegmentationBuySideTypeSelector
  const optionProps = {}
  if (optionsIncludingCreatedOptions) {
    optionProps.options = optionsIncludingCreatedOptions
  }

  return (
    <div>
      <SelectComponent
        delimiter={MULTI_DELIMITER}
        id={name}
        joinValues={false}
        onBlur={onBlur}
        onChange={onChangeWrapper}
        value={value}
        {...optionProps}
        {...restProps}
      />

      {!props.noError &&
        get(touched, name) &&
        get(errors, name) &&
        typeof get(errors, name) === 'string' && (
          <ErrorLabel>{get(errors, name)}</ErrorLabel>
        )}
    </div>
  )
}
