import React from 'react'
import { connect } from 'react-redux'
import Autosuggest from 'react-autosuggest'
import { withRouter } from 'react-router-dom'
import CloudinaryResource from 'infrastructure/components/CloudinaryResource'
import { requestSearch } from 'template/actions/search'

const DEFAULT_SEARCH_STRING_LENGTH = 3

class SearchBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: '',
      result: props.result,
      isFetching: props.isFetching,
    }

    this.onChange = this.onChange.bind(this)
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(
      this
    )
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
    this.onSuggestionsUpdateRequested = this.onSuggestionsUpdateRequested.bind(
      this
    )
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      result: nextProps.result,
      isFetching: nextProps.isFetching,
    })
  }

  loadSuggestions(value) {
    this.props.dispatch(requestSearch(value))
  }

  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    })
  }

  onSuggestionsClearRequested() {
    this.setState({
      value: '',
      result: [],
    })
  }

  onSuggestionSelected(event, { suggestion }) {
    const { history } = this.props

    switch (suggestion.type) {
      case 'customers':
        history.push(`/customers/${suggestion.id}`)
        break
      // If we simply use history.push all the data (e.g. image upload in product edit) will
      // not be replaced. So we use window.location.href to ensure full page refresh.
      case 'products':
        window.location.href = `/products/${suggestion.id}`
        break
    }

    this.props.onSuggestionSelected()
  }

  onSuggestionsUpdateRequested({ value }) {
    this.loadSuggestions(value)
  }

  render() {
    const { value, result, isFetching } = this.state

    const inputProps = {
      value,
      onChange: this.onChange,
      placeholder: 'Search for anything...',
    }

    return (
      <Autosuggest
        multiSection={true}
        suggestions={mapResults(result)}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionsFetchRequested={this.onSuggestionsUpdateRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        inputProps={inputProps}
      />
    )
  }
}

const mapResults = results => {
  return Object.keys(results)
    .map(type => {
      return {
        type,
        items: mapItems(type, results[type].hits),
      }
    })
    .filter(results => results.items.length > 0)
}

const mapItems = (type, items) => {
  switch (type) {
    case 'customers':
      return mapCustomerItems(type, items)
    case 'products':
      return mapProductItems(type, items)
  }
}

const mapCustomerItems = (type, items) => {
  return items.map(customer => {
    return Object.assign(addBaseProperties(type, customer), {
      title: customer._source.name,
      image: null,
    })
  })
}

const mapProductItems = (type, items) => {
  return items.map(product => {
    return Object.assign(addBaseProperties(type, product), {
      title: product._source.name,
      image: product._source.image,
    })
  })
}

const addBaseProperties = (type, item) => ({
  type,
  id: item._id,
})

const getSectionSuggestions = section => {
  return section.items
}

const getSuggestionValue = suggestion => {
  return suggestion.title
}

const renderSectionTitle = section => {
  return <div>{section.type}</div>
}

const renderSuggestion = suggestion => {
  return (
    <a className="template-searchbar__suggestion">
      <div className="template-searchbar__image">
        <CloudinaryResource
          className="round-pic"
          id={suggestion.image}
          presets="table"
          fallback="product_table"
        />
      </div>
      <div className="template-searchbar__title">
        <div>
          <strong>{suggestion.title}</strong>
        </div>
      </div>
    </a>
  )
}

const mapStateToProps = state => {
  return {
    isFetching: state.search.isFetching,
    result: state.search.result,
  }
}

export default connect(mapStateToProps)(withRouter(SearchBar))
