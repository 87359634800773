/* @flow */

import React, { Component } from 'react'
import EditableColumn, {
  createEditableInput,
  createEditableInputRender,
  DefaultInput,
  DefaultLabel,
} from '../Column'
import { PriceControl } from '../../Forms'
import { isValidCurrency } from '../../CurrencySelector'
import FormatCurrency from '../../FormatCurrency'

type Props = {
  currency: string,
  error?: string,
}

type State = {
  input: Function | React.Component<*, *, *>,
  label: Function | React.Component<*, *, *>,
}

class EditablePriceColumn extends Component<*, Props, State> {
  props: Props

  state = {
    input: DefaultInput,
    label: DefaultLabel,
  }

  constructor(props: Props) {
    super(props)

    this.state = calculateInputTypes(props.currency)
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.currency !== nextProps.currency) {
      this.setState(calculateInputTypes(nextProps.currency))
    }
  }

  render() {
    const { error, ...props } = this.props
    const { label, input } = this.state

    return (
      <EditableColumn
        error={error}
        LabelComponent={label}
        InputComponent={input}
        {...props}
      />
    )
  }
}

const currencyRenderer = createEditableInputRender(EditablePriceColumn)
export const EditablePriceColumnInput = createEditableInput(currencyRenderer)

export default EditablePriceColumn

const calculateInputTypes = (currency: string) => {
  if (!isValidCurrency(currency)) {
    return {
      input: DefaultInput,
      label: DefaultLabel,
    }
  } else {
    return {
      input: ({ setRef, ...props }: { setRef: Function }) => (
        <PriceControl currency={currency} inputRef={setRef} {...props} />
      ),
      label: ({ children, ...props }: { children?: React.Children }) => (
        <FormatCurrency currency={currency} {...props}>
          {children}
        </FormatCurrency>
      ),
    }
  }
}
