/* @flow */

import React from 'react'
import styled, { css } from 'styled-components'

const TabsBar = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`

const TabTopLine = styled.div`
  left: 0;
  height: 5px;
  position: absolute;
  right: 0;
  top: 0;
`

const TabLabel = styled.h2`
  display: inline;
  padding: 0 15px;
  font-size: 16px;
  line-height: 1;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TabDropdownButton = styled.button.attrs({ type: 'button' })`
  position: relative;
  top: -2px;
  margin: 0 0 0 -11px;
  padding: 11px;
  border: 0;
  background: none;
  font-size: 12px;
  line-height: 1;
  color: #c8d2df;

  &:focus {
    outline: none;
  }

  &:hover {
    color: #333;
  }
`

const TabButton = styled.button.attrs({ type: 'button' })`
  height: 40px;
  flex: 0 1 auto;
  padding: 5px 0 0 0;
  position: relative;
  margin: 0 10px 0 0;
  border: ${({ useNewColors }) =>
    useNewColors ? '1px solid #e1e1e1' : '1px solid #e3e7e9'};
  border-top: 0;
  background-color: #f8f8f8;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  ${TabLabel} {
    color: ${({ useNewColors }) => (useNewColors ? '#b3b3b3' : '#a4b0c1')};
  }

  ${TabTopLine} {
    background-color: ${({ useNewColors }) =>
      useNewColors ? '#dadada' : '#c8d2df'};
  }

  ${props =>
    props.active &&
    css`
      background-color: #fff;
      border-bottom-color: transparent;

      ${TabLabel} {
        color: #333;
      }

      ${TabTopLine} {
        background-color: ${({ useNewColors }) =>
          useNewColors ? '#5f5f5f' : '#1db0e1'};
      }
    `}
`

const TabsBarButton = styled.button.attrs({ type: 'button' })`
  margin: 5px 0 0 0;
  padding: 0 5px 3px 5px;
  border: 0;
  background: none;
  font-size: 14px;
  color: #c8d2df;

  &:focus {
    outline: none;
  }

  &:hover {
    color: #333;
  }
`

class Tab extends React.Component {
  static DropdownButton = TabDropdownButton
  static Label = TabLabel
  static TopLine = TabTopLine

  render() {
    return <TabButton {...this.props}>{this.props.children}</TabButton>
  }
}

export { TabsBarButton, TabsBar, Tab }
