/* @flow */

import * as React from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'

import WebshopActionButton from './WebshopActionButton'
import WebshopSaveButton from './WebshopSaveButton'
import {
  ControlLabel,
  FormGroup,
  SelectInput,
} from '../../../infrastructure/components/Formik'

import CustomerSelectorWithQuickCreateAction from '../../customers/components/CustomerSelectorWithQuickCreateAction'

import type { WebshopSession } from '../../types'

const WebshopCustomerSelectorModal = ({
  currentCustomerId,
  onHide,
  onSave,
  show,
  title,
}: {
  currentCustomerId: ?Id,
  onHide: Function,
  onSave: Function,
  show: boolea,
  title: string,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Formik
        initialValues={{
          customer_id: currentCustomerId,
        }}
        onSubmit={onSave}
        validationSchema={validationSchema}
        render={({ handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormGroup>
                  <ControlLabel required>Customer</ControlLabel>

                  <SelectInput
                    name="customer_id"
                    selectComponent={CustomerSelectorWithQuickCreateAction}
                    simpleValue
                  />
                </FormGroup>
              </Modal.Body>
              <Modal.Footer>
                {onHide && (
                  <WebshopActionButton
                    color="white"
                    size="large"
                    onClick={onHide}
                  >
                    Cancel
                  </WebshopActionButton>
                )}

                <WebshopSaveButton
                  color="primary"
                  size="large"
                  submitting={isSubmitting}
                >
                  Save
                </WebshopSaveButton>
              </Modal.Footer>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default WebshopCustomerSelectorModal

const validationSchema = Yup.object().shape({
  customer_id: Yup.number().required().typeError('Select a customer'),
})
