/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import FormatWeight from '../../../../../../infrastructure/components/FormatWeight'

import type { RowConfig } from '../../types'

type Options = {
  label?: string,
}

const weight: (options: Options) => RowConfig = ({
  data_source = '__product',
  label = 'weight',
  weight_type = 'kg',
}) => ({
  data_source,
  key: 'weight',
  label,
  render: ({ value }) => {
    if (!value || !value.weight || !value.weight_type) {
      return null
    }

    return (
      <FormatWeight
        renderType={weight_type}
        value={value.weight}
        valueType={value.weight_type}
      />
    )
  },
  value: ({ product, variant }) => {
    const data = {
      weight: null,
      weight_type: 'g',
    }

    if (variant && variant.weight && variant.weight_type) {
      data.weight = variant.weight
      data.weight_type = variant.weight_type
    } else if (product && product.weight && product.weight_type) {
      data.weight = product.weight
      data.weight_type = product.weight_type
    }

    return data
  },
})

export default weight
