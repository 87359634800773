/* @flow */

import React from 'react'
import _ from 'underscore'

const DEFAULT_SPINNER_CLASS = 'fa fa-spinner fa-spin splash-spinner'

export default class ActionButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      processing: false,
    }

    this.toggleState = this.toggleState.bind(this)
    this.processClick = this.processClick.bind(this)
    this.afterClickAction = this.toggleState.bind(this)
  }

  componentWillUnmount() {
    this.afterClickAction = _.noop
  }

  toggleState(callback = () => {}) {
    this.setState(
      {
        processing: !this.state.processing,
      },
      callback
    )
  }

  processClick(e) {
    if (this.props.stopPropagation) {
      e.stopPropagation()
    }

    const { onClick } = this.props
    const maybePromise = onClick()

    this.toggleState(() => {
      Promise.resolve(maybePromise)
        .then(() => this.afterClickAction())
        .catch(reason => {
          this.setState({
            processing: false,
          })

          return Promise.reject(reason)
        })
    })
  }

  render() {
    let { style, children, className, iconClass, disabled, onClick, ...rest } =
      this.props

    const { processing } = this.state

    return (
      <ControlledActionButton
        className={className}
        disabled={processing || disabled}
        iconClass={iconClass}
        onClick={this.processClick}
        processing={processing}
        style={style}
        {...rest}
      >
        {children}
      </ControlledActionButton>
    )
  }
}

ActionButton.defaultProps = {
  className: null,
  iconClass: null,
}

export const ControlledActionButton = ({
  processing,
  style,
  children,
  className,
  iconClass,
  disabled,
  onClick,
  ...rest
}) => {
  iconClass = processing ? DEFAULT_SPINNER_CLASS : iconClass

  return (
    <button
      type="button"
      style={style}
      disabled={processing || disabled}
      className={className}
      onClick={onClick}
      {...rest}
    >
      <span className={iconClass} /> {children}
    </button>
  )
}
