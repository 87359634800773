/* @flow */

import * as React from 'react'

import { FakeLinkStyle } from '../../../app/shared'

const AdvancedFieldBlock = ({ autoShow = false, children, style }) => {
  const [show, setShow] = React.useState(autoShow)

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <FakeLinkStyle onClick={() => setShow(s => !s)}>
          {show === false && (
            <span>
              {' '}
              <span className="glyphicon glyphicon-chevron-up" /> Show advanced
            </span>
          )}{' '}
          {show === true && (
            <span>
              <span className="glyphicon glyphicon-chevron-down" />
              Hide advanced
            </span>
          )}
        </FakeLinkStyle>
      </div>

      {show === true && children}
    </div>
  )
}

export default AdvancedFieldBlock
