/* @flow */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DatalessProductSelector } from '../../products/components/ProductSelector'
import ProductTable, {
  transformData,
} from '../../products/components/ProductTable'
import memo from 'memoize-one'
import {
  ControlLabel,
  FormControl,
  FormGroup,
  SelectWrapper,
} from '../../../infrastructure/components/Forms'
import { getPrice } from '../../products/components/Price'
import _ from 'underscore'
import { ButtonGroup, Button } from 'react-bootstrap'
import msg from '../../../infrastructure/modules/msg'
import CollectionSelector from '../../collections/components/CollectionSelector'
import CategorySelector from '../../categories/components/CategorySelector'
import styled from 'styled-components'
import { List, Map } from 'immutable'
import AttributeList from '../../products/components/AttributeList'
import {
  extractVariants,
  calculateProductTableTotals,
  removeProductsFromQuantities,
} from './shared'
import CustomerCard from '../../customers/components/CustomerCard'

const FILTER_FILTER = 'filter'
const FILTER_MARK = 'mark'

class ProductAdder extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filterStyle: FILTER_MARK,
      markProducts: List(),
      order: [],
      quantities: props.quantities,
      quickQuantity: props.quickQuantity,
      preloadingProducts: false,
      selectedAttributes: {},
      selectedCollection: null,
      selectedCollectionArray: [],
      selectedCategory: null,
      selectedCategoryArray: [],
      selectedProducts: props.selectedProducts,
      productsFiltered: props.selectedProducts,
      showCustomerCard: false,
      showQuantities: props.showQuantities,
      variants: props.variants,
    }

    this.handleCollectionSelectorChange =
      this.handleCollectionSelectorChange.bind(this)
    this.handleCategorySelectorChange =
      this.handleCategorySelectorChange.bind(this)
    this.handleProductSelectorChange =
      this.handleProductSelectorChange.bind(this)
    this.onAttributeListChange = this.onAttributeListChange.bind(this)
    this.onClearQuick = this.onClearQuick.bind(this)
    this.onQuantityChange = this.onQuantityChange.bind(this)
    this.onQuickAddQuantityChange = this.onQuickAddQuantityChange.bind(this)
    this.onQuickAddQuantity = this.onQuickAddQuantity.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    let changes = {}

    // Happens when we reset the component from the outside
    if (this.state.showQuantities !== nextProps.showQuantities) {
      changes.quantities = Map()
      changes.showQuantities = nextProps.showQuantities
    }

    if (changes.showQuantities === false) {
      // This will reset the ProductSelector input
      changes = Object.assign({}, changes, calculateStateOnProductChange([]))
    }

    if (Object.getOwnPropertyNames(changes).length > 0) {
      this.setState(changes, () => {
        if (typeof changes.showQuantities !== 'undefined') {
          this.props.onShowQuantitiesChange(changes.showQuantities)
        }
      })
    }
  }

  componentWillUnmount() {
    // If you go from step 2 -> 3 we should hide the quantities area if you go back to 2
    this.props.onShowQuantitiesChange(false)
  }

  // A parent consumer, for instance NewOrder, needs to be continuously updated on the
  // quantities and totals to display them in preview and to save the quantities for
  // the API call to save the order.
  emitQuantityChange() {
    const { currency } = this.props
    const { quantities, productsFiltered, variants } = this.state

    const totals = calculateProductTableTotals(
      this.props.productCatalogue,
      variants,
      quantities,
      currency
    )

    this.props.onQuantityChange(quantities, productsFiltered, totals)
  }

  handleCollectionSelectorChange(collection) {
    this.setState({
      selectedCollection: collection,
      selectedCollectionArray: collection ? [collection.id] : [],
    })
  }

  handleCategorySelectorChange(category) {
    this.setState({
      selectedCategory: category,
      selectedCategoryArray: category ? [category.id] : [],
    })
  }

  // When the product selector changes we create a new List of products where variants
  // will be filtered (productsFiltered). We also extract the variants of the selected
  // products. Lastly, if the selected product is not empty we show the "quantities area".
  handleProductSelectorChange(products) {
    console.log('selector change', products)
    this.props.onProductChange(products)

    /*
    const oldShowQuantities = this.state.showQuantities

    const changes = calculateStateOnProductChange(products)

    // We check to see if any products have been de-selected. We must remove the quantities
    // in such case.
    const currentProductIds = this.state.selectedProducts.map(product => product.id)
    const newProductIds = products.map(product => product.id)
    const removed = currentProductIds.filter(id => !newProductIds.includes(id))

    if (removed.size > 0) {
      changes.quantities = removed.reduce((carry, id) => carry.delete(id), this.state.quantities)
    }

    this.setState(
      changes,
      () => {
        // Since the selectedProducts have changed it might be some quantities were removed.
        // We therefore re-emit the quantities and totals.
        this.emitQuantityChange()

        // Sync state with parents if showQuantities changed.
        if (oldShowQuantities !== this.state.showQuantities) {
          this.props.onShowQuantitiesChange(this.state.showQuantities)
        }
      }
    );*/
  }

  onAttributeListChange(products, selected) {
    let anyValues = false
    for (var i in selected) {
      if (selected[i].length > 0) {
        anyValues = true
      }
    }

    let markProducts = List()
    if (anyValues) {
      markProducts = products.reduce(
        (carry, product) =>
          carry.concat(product.variants.map(variant => variant.id)),
        List()
      )
    }

    this.setState({
      markProducts,
      productsFiltered: products,
      selectedAttributes: selected,
      variants: extractVariants(List(products)),
    })
  }

  // This will clear the inputted quantity in all the filtered variants
  onClearQuick() {
    this.setState({
      // Will remove all marked variants from the quantities
      quantities: removeProductsFromQuantities(
        this.state.quantities,
        this.state.productsFiltered
      ),
    })
  }

  onFilterStyleChange(newStyle) {
    this.setState({
      filterStyle: newStyle,
    })
  }

  // Editing quantities directly in the product table
  onQuantityChange(variant, quantity, quantities) {
    this.setState(
      {
        quantities: quantities,
      },
      this.emitQuantityChange
    )
  }

  // The quick add input field is the input field that will add a given quantity in to all filtered variants
  onQuickAddQuantityChange(e) {
    this.setState({
      quickQuantity: e.target.value,
    })
  }

  onQuickAddQuantity() {
    const { currency } = this.props
    const { filterStyle, quantities, quickQuantity } = this.state

    const productWithVariants = createProductWithVariants(
      this.props.selectedProducts,
      this.props.loadedProducts
    )
    const productsFiltered =
      filterStyle === FILTER_MARK
        ? productWithVariants
        : this.state.productsFiltered

    // If the entered quantity is not a number we simply stop the function
    if (isNaN(parseInt(quickQuantity))) {
      return
    }

    // We add the quick quantity to existing quantities.
    // This includes adding to existing, adding missing variants and ignoring quantities
    // not part of productsFiltered
    const newQuantities = productsFiltered.reduce((carry, product) => {
      const productQuantities = carry.get(product.id, Map())

      const newProductQuantities = product.variants.reduce(
        (innerCarry, variant) => {
          const variantQuantity = innerCarry.get(
            variant.id,
            Map({ quantity: 0, colli: 1 })
          )

          // We do not want to add quick quantities on disabled quantity fields
          if (!variant.prices[currency]) {
            return innerCarry
          }

          return innerCarry.set(
            variant.id,
            variantQuantity.set(
              'quantity',
              variantQuantity.get('quantity') + parseInt(quickQuantity)
            )
          )
        },
        carry.get(product.id, Map())
      )

      return productQuantities === newProductQuantities
        ? carry
        : carry.set(product.id, newProductQuantities)
    }, quantities)
    console.log('QUICK ADDD', newQuantities.toJS())
    // Set the new quantities and reset the field
    this.setState(
      {
        quantities: newQuantities,
        quickQuantity: '',
      },
      () => {
        this.emitQuantityChange()
      }
    )
  }

  toggleCustomerCard = () => {
    this.setState({
      showCustomerCard:
        this.state.showCustomerCard !== false ? false : this.props.customerId,
    })
  }

  render() {
    const {
      availableProducts,
      currency,
      customerId,
      headline,
      isProductsLoading,
      loadedProducts,
      products,
      onQuantityChange,
      selectedProducts,
      showQuantities,
      title,
      ...rest
    } = this.props

    const {
      filterStyle,
      markProducts,
      quantities,
      quickQuantity,
      productsFiltered,
      selectedAttributes,
      selectedCollection,
      selectedCollectionArray,
      selectedCategory,
      selectedCategoryArray,
      showCustomerCard,
    } = this.state

    const productWithVariants = createProductWithVariants(
      selectedProducts,
      loadedProducts
    )

    console.log('RENDERRRR', showQuantities, productWithVariants)
    return (
      <Container>
        {customerId && (
          <CustomerCard
            customerIds={[customerId]}
            current={showCustomerCard}
            onCustomerChange={id => this.toggleCustomerCard()}
            onHide={this.toggleCustomerCard}
            show={showCustomerCard !== false}
          />
        )}
        <FilterAreaContainer>
          <Headline>
            <h4>{title}</h4>
          </Headline>

          <AdderContainer>
            <AdderField>
              <CollectionsCategoriesContainer>
                <div>
                  <label>Collection</label>
                  <CollectionSelector
                    value={selectedCollection}
                    onChange={this.handleCollectionSelectorChange}
                  />
                </div>
                <div>
                  <label>Category</label>
                  <CategorySelector
                    value={selectedCategory}
                    onChange={this.handleCategorySelectorChange}
                  />
                </div>
              </CollectionsCategoriesContainer>
            </AdderField>
            <AdderField style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <label>Product(s)</label>
                <DatalessProductSelector
                  collections={selectedCollectionArray}
                  categories={selectedCategoryArray}
                  isLoading={isProductsLoading}
                  products={availableProducts}
                  multi
                  value={selectedProducts}
                  onChange={this.handleProductSelectorChange}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  marginLeft: 10,
                }}
              >
                {customerId && (
                  <button
                    type="button"
                    className="btn btn-white"
                    onClick={this.toggleCustomerCard}
                  >
                    Customer Card
                  </button>
                )}
              </div>
            </AdderField>
          </AdderContainer>

          {showQuantities && (
            <div>
              <hr />

              <AddProductTableContainer>
                <ControlLabel>
                  You can filter the products before you add them to the order
                  below
                </ControlLabel>
                <FiltersContainer>
                  <ButtonGroup vertical>
                    <button
                      type="button"
                      className={
                        'btn btn-white ' +
                        (filterStyle === FILTER_FILTER ? 'active' : '')
                      }
                      onClick={() => this.onFilterStyleChange(FILTER_FILTER)}
                    >
                      Filter Variants:
                    </button>
                    <button
                      type="button"
                      className={
                        'btn btn-white ' +
                        (filterStyle === FILTER_MARK ? 'active' : '')
                      }
                      onClick={() => this.onFilterStyleChange(FILTER_MARK)}
                    >
                      Mark Variants:
                    </button>
                  </ButtonGroup>
                  <AttributeListContainer>
                    <AttributeList
                      onChange={this.onAttributeListChange}
                      products={productWithVariants}
                      selected={selectedAttributes}
                    />
                  </AttributeListContainer>
                </FiltersContainer>
              </AddProductTableContainer>

              <QuickAddActions>
                <QuantityPanelInputContainer>
                  <FormControl
                    onChange={this.onQuickAddQuantityChange}
                    placeholder="Quantity"
                    value={quickQuantity}
                  />
                  <button
                    className="btn btn-success"
                    onClick={this.onQuickAddQuantity}
                  >
                    <span className="glyphicon glyphicon-plus" /> Add{' '}
                    {quickQuantity && `(${quickQuantity})`} to all filtered
                    variants
                  </button>
                </QuantityPanelInputContainer>
                <button className="btn btn-white" onClick={this.onClearQuick}>
                  Clear all quantities from filtered variants
                </button>
              </QuickAddActions>
            </div>
          )}
        </FilterAreaContainer>

        {showQuantities && (
          <ProductTableContainer>
            <ProductTable
              currency={currency}
              markProducts={filterStyle === FILTER_MARK ? markProducts : List()}
              products={createProductsImmutable(
                filterStyle === FILTER_MARK
                  ? productWithVariants
                  : productsFiltered
              )}
              editableQuantities
              matrix={true}
              onQuantityChange={this.onQuantityChange}
              quantities={quantities}
              tableKey="product_adder"
            />
          </ProductTableContainer>
        )}
      </Container>
    )
  }
}

const createProductWithVariants = memo((selectedProducts, loadedProducts) => {
  return selectedProducts
    .map(product => {
      return loadedProducts.find(p => p.id === product.id)
    })
    .filter(p => p)
})

const filterProducts = memo((products, selectedAttributes) => {
  return products
})

const createProductsImmutable = memo(products => List(products))

ProductAdder.propTypes = {
  currency: PropTypes.string,
  headline: PropTypes.string,
  onCurrencyChange: PropTypes.func,
  onQuantityChange: PropTypes.func,
  onShowQuantitiesChange: PropTypes.func,
  quantities: PropTypes.object,
  quickQuantity: PropTypes.string,
  selectedProducts: PropTypes.instanceOf(List),
  showQuantities: PropTypes.bool,
  style: PropTypes.object,
  title: PropTypes.string,
  variants: PropTypes.object,
}

ProductAdder.defaultProps = {
  onCurrencyChange: () => {},
  onQuantityChange: () => {},
  onShowQuantitiesChange: () => {},
  productCatalogue: {},
  quantities: Map(),
  quickQuantity: '',
  selectedProducts: List(),
  showQuantities: false,
  style: {},
  title: 'Add Products to Order',
  variants: {},
}

export { ProductAdder }

export default connect(state => ({
  entity: state.session.entity,
  productCatalogue: state.products.items,
}))(ProductAdder)

export const calculateStateOnProductChange = products => ({
  selectedProducts: List(products),
  productsFiltered: List(products),
  showQuantities: products.length > 0,
  variants: extractVariants(List(products)),
})

const Container = styled.div``

const QuantityPanelContainer = styled.div`
  display: flex;
`

const AttributeListContainer = styled.div`
  flex: 1;
  margin-left: 15px;
`

const QuickAddActions = styled.div`
  bottom: -18px;
  display: flex;
  left: 0;
  justify-content: center;
  margin-left: -20px;
  position: absolute;
  right: 0;

  > * {
    margin-left: 20px;
  }
`

const QuantityPanelInputContainer = styled.div`
  display: flex;

  input {
    height: 36px;
    margin-left: 3px;
    min-width: 90px;
    position: relative;
    z-index: 0;
  }

  button {
    margin-left: -3px;
    min-width: 240px;
    position: relative;
    z-index: 1;
  }
`

const AdderContainer = styled.div`
  display: flex;
  margin-left: -15px;
`

const AdderField = styled(FormGroup)`
  flex: 1;
  margin-left: 15px;
`

const CollectionsCategoriesContainer = styled.div`
  display: flex;
  margin-left: -15px;

  > div {
    flex: 1;
    margin-left: 15px;
  }
`

const AdderButton = styled.div`
  flex: 1;
`

const Headline = styled.div`
  margin-bottom: 10px;

  > h1,
  > h2,
  > h3,
  > h4 {
    display: inline;
  }
`

const AddProductTableContainer = styled.div`
  margin-top: 20px;
`

const FiltersContainer = styled.div`
  display: flex;
`

const FilterAreaContainer = styled.div`
  background: white;
  border-bottom: 1px solid #e3e7e9;
  padding: 20px 20px 30px 20px;
  position: relative;
`

const ProductTableContainer = styled.div`
  background: #fafbfb;
  padding: 50px 20px 20px 20px;
`
