/* @flow */

import { createApiHook, createDataCache, quickCreateHook } from '../hooks'

import {
  getShowroom,
  getShowroomSelections,
  getShowroomByHash,
  getShowroomProducts,
  getShowrooms,
  getShowroomSelection,
  getShowroomTrackingV2Totals,
  getTrackingAttributes,
} from './api'

import type { ConnectedBrand, Id, WebshopSession } from '../types'
import {
  ensureAnonymousShowroomSession,
  ensureShowroomSession,
  getPublicBrand,
} from './api'

const {
  hook: useShowrooms,
  hookCached: useCachedShowrooms,
  clearCache: clearShowroomsCache,
} = quickCreateHook(getShowrooms, 'showrooms', [])
export { useShowrooms, useCachedShowrooms, clearShowroomsCache }

const {
  hook: useShowroomSelections,
  hookCached: useCachedShowroomSelections,
  clearCache: clearShowroomSelectionsCache,
} = quickCreateHook(getShowroomSelections, 'selections', [])

export {
  useShowroomSelections,
  useCachedShowroomSelections,
  clearShowroomSelectionsCache,
}

export const usePublicBrand = createApiHook<ConnectedBrand | null>(
  (id?: Id, options?: Array) => {
    return getPublicBrand(id, options).then(response => {
      if (response.status === 200) {
        return {
          entity: response.payload.brand,
        }
      } else {
        return {
          error: true,
        }
      }
    })
  }
)

export const useAnonymousShowroomSession = createApiHook<WebshopSession | null>(
  (brandId, extraData) => {
    if (!brandId) {
      return
    }

    return ensureAnonymousShowroomSession(brandId, extraData).then(response => {
      return !response.error ? { entity: response.payload.session } : response
    })
  }
)

const { hook: useShowroomSession } = quickCreateHook(
  ensureShowroomSession,
  'session',
  null
)

export { useShowroomSession }

// Use showroom products

const fetchShowroomProductsForHook = () => (
  showroomId: Id,
  showroomHash,
  options?: Object
) => {
  return getShowroomProducts(showroomId, showroomHash, options).then(
    response => {
      if (response.error) {
        return response
      }

      return {
        entity: response.payload.products,
      }
    }
  )
}

export const useShowroomProducts = createApiHook(
  fetchShowroomProductsForHook(),
  []
)

// Use showroom

const fetchShowroomForHook = (showroomOnly: boolean) => (
  showroomId: Id,
  options?: Object
) => {
  return getShowroom(showroomId, options).then(response => {
    if (!response.error) {
      return {
        entity: showroomOnly ? response.payload.showroom : response.payload,
      }
    } else {
      return response
    }
  })
}

export const useShowroom = createApiHook(fetchShowroomForHook(true))

// Use showroom from hash

const fetchShowroomByHashForHook = (showroomOnly: boolean) => (
  showroomId: Id,
  showroomHash,
  options?: Object
) => {
  return getShowroomByHash(showroomId, showroomHash, options).then(response => {
    if (!response.error) {
      return {
        entity: showroomOnly ? response.payload.showroom : response.payload,
      }
    }

    return response
  })
}

export const useShowroomByHash = createApiHook(fetchShowroomByHashForHook(true))

// Use showroom selection

const fetchShowroomSelectionForHook = (showroomOnly: boolean) => (
  showroomSelectionId: Id,
  options?: Object
) => {
  return getShowroomSelection(showroomSelectionId, options).then(response => {
    if (response.error) {
      return response
    }

    return {
      entity: showroomOnly
        ? response.payload.showroom_selection
        : response.payload,
    }
  })
}

export const useShowroomSelection = createApiHook(
  fetchShowroomSelectionForHook(true)
)

// Tracking

const {
  hook: useTrackingAttributes,
  hookCached: useCachedTrackingAttributes,
  clearCache: clearTrackingAttributesCache,
} = quickCreateHook(getTrackingAttributes, 'attributes', [])

export {
  useTrackingAttributes,
  useCachedTrackingAttributes,
  clearTrackingAttributesCache,
}

const fetchShowroomTrackingV2TotalsForHook = () => (showroomId: Id) => {
  return getShowroomTrackingV2Totals(showroomId).then(response => {
    if (!response.error) {
      return {
        entity: response.payload,
      }
    } else {
      return response
    }
  })
}

export const useShowroomTrackingV2Totals = createApiHook(
  fetchShowroomTrackingV2TotalsForHook(true)
)
