// @flow

import * as React from 'react'
import { Component } from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import styled, { css } from 'styled-components'
import Select from 'react-select'
import { Map, Set } from 'immutable'

import mobile from '../../../../infrastructure/modules/mobile'
import { toQuantityTotal } from '../../../orders/components/shared'
import { type Quantity } from '../../../types'

type QuantityFieldProps = {
  className: string,
  colliOnly: boolean,
  colliSizes: Array<number>,
  editable: boolean,
  enableColli: boolean,
  matrix: boolean,
  narrowStyle: boolean,
  onBlur: () => void,
  onChange: (quantity: Quantity) => void,
  preferColli?: boolean,
  popover?: React.Node,
  value: Quantity,
}

type QuantityFieldState = {
  availableColli: Set<number>,
}

export default class QuantityField extends Component<
  QuantityFieldProps,
  QuantityFieldState
> {
  static defaultProps = {
    className: '',
    colliOnly: false,
    colliSizes: [],
    editable: true,
    enableColli: true,
    matrix: false,
    narrowStyle: false,
    onBlur: () => {},
    onChange: () => {},
    preferColli: false,
    value: Map(),
  }

  constructor(props: QuantityFieldProps) {
    super(props)

    this.state = {
      availableColli: getAvailableColli(
        props.colliOnly,
        props.colliSizes,
        props.enableColli
      ),
    }
  }

  componentWillReceiveProps(nextProps: QuantityFieldProps) {
    this.setState({
      availableColli: getAvailableColli(
        nextProps.colliOnly,
        nextProps.colliSizes,
        nextProps.enableColli
      ),
    })
  }

  handleResetClick = () => {
    this.props.onChange(
      Map({
        quantity: 1,
        colli: getDefaultColliSize(
          this.state.availableColli,
          this.props.preferColli
        ),
      })
    )
  }

  render() {
    const {
      className,
      editable,
      matrix,
      narrowStyle,
      onBlur,
      onChange,
      preferColli,
      value: passedValue,
      ...rest
    } = this.props

    const { availableColli } = this.state

    if (availableColli.size === 0) {
      return (
        <QuantityFieldMessage>No colli sizes available</QuantityFieldMessage>
      )
    }

    const value = Map({
      quantity: passedValue.get('quantity', 0),
      colli: passedValue.get(
        'colli',
        getDefaultColliSize(availableColli, preferColli)
      ),
    })

    if (!availableColli.includes(value.get('colli'))) {
      return (
        <QuantityFieldMessage>
          Cannor order in {value.get('colli')}p colli (was{' '}
          {value.get('quantity')}&nbsp;×&nbsp;{value.get('colli')}p){' '}
          <button
            className="btn btn-xs btn-white"
            onClick={this.handleResetClick}
          >
            Reset
          </button>
        </QuantityFieldMessage>
      )
    }

    if (mobile.mobile()) {
      return (
        <PlusMinusQuantityField
          availableColli={availableColli}
          editable={editable}
          narrowStyle={narrowStyle}
          onChange={onChange}
          value={value}
        />
      )
    }

    return (
      <InputQuantityField
        availableColli={availableColli}
        className={className}
        editable={editable}
        matrix={matrix}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        {...rest}
      />
    )
  }
}

const QuantityFieldMessage = styled.div`
  white-space: normal;
`

const getDefaultColliSize = (
  availableColli: Set<number>,
  preferColli?: boolean = false
) => {
  if (preferColli) {
    const colliAboveOne = availableColli.filter(c => c > 1).first()

    return colliAboveOne ? colliAboveOne : availableColli.first()
  } else {
    return availableColli.first()
  }
}

type InputQuantityFieldProps = {
  availableColli: Set<number>,
  className: string,
  editable: boolean,
  matrix: boolean,
  onBlur: () => void,
  onChange: (value: Quantity) => void,
  popover?: React.Node,
  style?: any,
  value: Quantity,
}

type InputQuantityFieldState = {
  quantity: string | number,
}

class InputQuantityField extends Component<
  InputQuantityFieldProps,
  InputQuantityFieldState
> {
  constructor(props: InputQuantityFieldProps) {
    super(props)

    this.state = {
      quantity: displayQuantity(props.value.get('quantity')),
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        quantity: displayQuantity(nextProps.value.get('quantity')),
      })
    }
  }

  handleQuantityBlur = () => {
    if (!isValidQuantityInput(this.state.quantity)) {
      this.setState(
        {
          quantity: displayQuantity(parseQuantityInput(this.state.quantity)),
        },
        () => {
          this.props.onChange(
            this.props.value.set(
              'quantity',
              parseQuantityInput(this.state.quantity)
            )
          )
          this.props.onBlur()
        }
      )
    } else {
      this.props.onBlur()
    }
  }

  handleQuantityChange = (e: Object) => {
    this.setState({ quantity: e.target.value }, () => {
      // Propagate the quantity only when it is a valid number, otherwise
      // it will be propagated on blur
      if (isValidQuantityInput(this.state.quantity)) {
        this.props.onChange(
          this.props.value.set(
            'quantity',
            parseQuantityInput(this.state.quantity)
          )
        )
      }
    })
  }

  handleColliChange = (option: { value: number, label: string }) => {
    this.props.onChange(this.props.value.set('colli', option.value))
  }

  renderColliSelectValue = option => {
    return `× ${option.value}pcs`
  }

  render() {
    const {
      availableColli,
      className,
      editable,
      matrix,
      onBlur,
      onChange,
      popover,
      value,
      style,

      // TODO: do we need rest here?
      ...rest
    } = this.props
    const { quantity } = this.state

    const colliOptions = availableColli.toArray().map(size => ({
      value: size,
      label: size === 1 ? '× 1 piece' : `× ${size}-pieces colli`,
    }))

    const wrapInPopover = children =>
      popover ? (
        <OverlayTrigger
          trigger={['hover', 'focus']}
          rootClose={mobile.mobile()}
          placement="bottom"
          overlay={popover}
        >
          {children}
        </OverlayTrigger>
      ) : (
        children
      )

    return (
      <InputContainer matrix={matrix} style={style}>
        <div className="print-show">{toQuantityTotal(value)}</div>
        <div className="print-hide">
          <QuantityColliInputWrapper>
            {wrapInPopover(
              <InputField
                type="text"
                className={className + ' form-control'}
                value={quantity}
                disabled={!editable}
                onBlur={this.handleQuantityBlur}
                onChange={this.handleQuantityChange}
                {...rest}
              />
            )}
            {availableColli.size === 1 && availableColli.first() !== 1 && (
              <ColliLabel>× {availableColli.first()}p</ColliLabel>
            )}
            {availableColli.size > 1 && (
              <ColliSelectWrapper>
                <Select
                  clearable={false}
                  onChange={this.handleColliChange}
                  options={colliOptions}
                  searchable={false}
                  value={value.get('colli')}
                  valueRenderer={this.renderColliSelectValue}
                />
              </ColliSelectWrapper>
            )}
          </QuantityColliInputWrapper>
        </div>
      </InputContainer>
    )
  }
}

// For Traditional style product tables we want the quantity field to have at least some width.
// Otherwise it is going to look really small in a table width a lot of space.
const InputContainer = styled.div`
  min-width: ${props => (props.matrix ? 0 : '180px')};
`

InputContainer.defaultProps = {
  matrix: QuantityField.defaultProps.matrix,
}

const QuantityColliInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const InputField = styled.input`
  // In webarch there is a padding rule on input[type=text], which
  // is why we need to add it here to override.
  // TODO: Remove when webarch styles are gone.
  &[type='text'] {
    border: 1px solid #cecece;
    display: block;
    max-width: 80px;
    width: 100%;
    min-height: 28px;
    padding: 3px 6px;
  }
`

const ColliLabel = styled.div`
  max-width: 80px;
  width: 100%;
  line-height: 28px;
  padding-left: 5px;
  background-color: #e5e9ec;
  border-color: #e5e9ec;
  border-radius: 2px;
`

const ColliSelectWrapper = styled.div`
  max-width: 80px;
  width: 100%;

  & .Select-menu-outer {
    width: 130px;
  }

  & .Select-control {
    height: 28px;
    background-color: #e5e9ec;
    border-color: #e5e9ec;
    border-radius: 2px;

    .Select-value {
      padding-left: 5px;
      padding-right: 5px;
      line-height: 28px;
    }

    .Select-arrow-zone {
      padding-right: 2px;
      width: 10px;
      top: 2px;
    }

    .Select-input {
      height: 26px;
    }
  }
`

type PlusMinusQuantityFieldProps = {
  availableColli: Set<number>,
  editable: boolean,
  onChange: (value: Quantity) => void,
  narrowStyle: boolean,
  value: Quantity,
}

export class PlusMinusQuantityField extends Component<PlusMinusQuantityFieldProps> {
  handlePlusClick = () => {
    const { value, onChange } = this.props
    onChange(value.set('quantity', value.get('quantity', 0) + 1))
  }

  handleMinusClick = () => {
    const { value, onChange } = this.props
    onChange(value.set('quantity', Math.max(value.get('quantity', 0) - 1, 0)))
  }

  handleColliSelectClick(colli: number) {
    this.props.onChange(
      Map({
        quantity: 1,
        colli,
      })
    )
  }

  render() {
    const { availableColli, editable, onChange, narrowStyle, value } =
      this.props

    if (availableColli.size > 1 && value.get('quantity') === 0) {
      return (
        <PlusMinusContainer narrowStyle={narrowStyle}>
          {availableColli.toArray().map(colli => (
            <PlusMinusButton
              signOnly={false}
              className="btn btn-xs"
              onClick={() => this.handleColliSelectClick(colli)}
              narrowStyle={narrowStyle}
              disabled={!editable}
            >
              +{colli}
            </PlusMinusButton>
          ))}
        </PlusMinusContainer>
      )
    }

    let colli
    if (availableColli.size === 1) {
      colli = availableColli.first()
    } else {
      colli = value.get('colli')
    }

    const signOnly = colli === 1

    const label = (
      <PlusMinusValueLabel key="label" narrowStyle={narrowStyle}>
        {toQuantityTotal(value)}
      </PlusMinusValueLabel>
    )

    const minusButton = (
      <PlusMinusButton
        key="minus-button"
        signOnly={signOnly}
        className="btn btn-xs"
        onClick={this.handleMinusClick}
        narrowStyle={narrowStyle}
        disabled={!editable || value.get('quantity') === 0}
      >
        -{signOnly ? '' : colli}
      </PlusMinusButton>
    )

    const plusButton = (
      <PlusMinusButton
        key="plus-button"
        signOnly={signOnly}
        className="btn btn-xs"
        onClick={this.handlePlusClick}
        narrowStyle={narrowStyle}
        disabled={!editable}
      >
        +{signOnly ? '' : colli}
      </PlusMinusButton>
    )

    return (
      <PlusMinusContainer narrowStyle={narrowStyle}>
        {narrowStyle
          ? [plusButton, label, minusButton]
          : [label, minusButton, plusButton]}
      </PlusMinusContainer>
    )
  }
}

const PlusMinusContainer = styled.div`
  display: flex;

  flex-direction: ${props => (props.narrowStyle ? 'column' : 'row')};
  align-items: ${props => (props.narrowStyle ? 'left' : 'center')};
`

const PlusMinusButton = styled.button`
  flex: 1;
  line-height: 1;

  ${props =>
    props.narrowStyle
      ? css`
          height: 24px;
          min-width: 30px;
          margin: 1px 0;
          font-size: ${props => (props.signOnly ? '18px' : '12px')};
        `
      : css`
          height: 36px;
          min-width: 28px;
          margin: 0 1px;
          font-size: ${props => (props.signOnly ? '22px' : '14px')};
        `}
`

const PlusMinusValueLabel = styled.div`
  flex: 1;
  text-align: center;

  ${props =>
    props.narrowStyle
      ? css`
          height: 24px;
          min-width: 30px;
          margin: 1px 0;
          line-height: 24px;
        `
      : css`
          padding: 0 3px;
        `}
`

const getAvailableColli = (
  colliOnly: boolean,
  colliSizes: Array<number>,
  enableColli: boolean
) => {
  if (!enableColli) {
    // If colli is disabled, we show the same thing as if colli was
    // enabled and there is just one colli 1 option
    return Set([1])
  }

  let availableColli = Set(colliSizes)

  if (!colliOnly) {
    availableColli = availableColli.add(1)
  }

  return availableColli.sort()
}

const parseQuantityInput = (input: string | number) => {
  let quantity = parseInt(input) || 0

  if (quantity < 0) {
    quantity = 0
  }

  return quantity
}

const isValidQuantityInput = (input: string | number) => {
  const quantity = parseQuantityInput(input)

  return String(quantity) === String(input)
}

const displayQuantity = (quantity: number) => {
  if (quantity === 0 || quantity === undefined) {
    return ''
  }

  return quantity
}
