import { CALL_API } from '../../../infrastructure/middleware/api'

export const GET_COLLECTION_REQUEST = 'GET_COLLECTION_REQUEST'
export const GET_COLLECTION_SUCCESS = 'GET_COLLECTION_SUCCESS'
export const GET_COLLECTION_FAILURE = 'GET_COLLECTION_FAILURE'

export const GET_COLLECTIONS_LIST_REQUEST = 'GET_COLLECTIONS_LIST_REQUEST'
export const GET_COLLECTIONS_LIST_SUCCESS = 'GET_COLLECTIONS_LIST_SUCCESS'
export const GET_COLLECTIONS_LIST_FAILURE = 'GET_COLLECTIONS_LIST_FAILURE'

export const CREATE_COLLECTION_REQUEST = 'CREATE_COLLECTION_REQUEST'
export const CREATE_COLLECTION_SUCCESS = 'CREATE_COLLECTION_SUCCESS'
export const CREATE_COLLECTION_FAILURE = 'CREATE_COLLECTION_FAILURE'

export const UPDATE_COLLECTION_REQUEST = 'UPDATE_COLLECTION_REQUEST'
export const UPDATE_COLLECTION_SUCCESS = 'UPDATE_COLLECTION_SUCCESS'
export const UPDATE_COLLECTION_FAILURE = 'UPDATE_COLLECTION_FAILURE'

export const FETCH_COLLECTIONS_REQUEST = 'FETCH_COLLECTIONS_REQUEST'
export const FETCH_COLLECTIONS_SUCCESS = 'FETCH_COLLECTIONS_SUCCESS'
export const FETCH_COLLECTIONS_FAILURE = 'FETCH_COLLECTIONS_FAILURE'

export const SET_COLLECTION_STATUS_REQUEST = 'SET_COLLECTION_STATUS_REQUEST'
export const SET_COLLECTION_STATUS_SUCCESS = 'SET_COLLECTION_STATUS_SUCCESS'
export const SET_COLLECTION_STATUS_FAILURE = 'SET_COLLECTION_STATUS_FAILURE'

export const getCollection = collectionId => ({
  [CALL_API]: {
    endpoint: `/collections/${collectionId}`,
    method: 'GET',
    types: [
      GET_COLLECTION_REQUEST,
      GET_COLLECTION_SUCCESS,
      GET_COLLECTION_FAILURE,
    ],
  },
})

export const createCollection = data => ({
  [CALL_API]: {
    endpoint: '/collections',
    method: 'POST',
    body: { collection: data },
    types: [
      CREATE_COLLECTION_REQUEST,
      CREATE_COLLECTION_SUCCESS,
      CREATE_COLLECTION_FAILURE,
    ],
  },
})

export const updateCollection = (collectionId, data) => ({
  [CALL_API]: {
    endpoint: `/collections/${collectionId}`,
    method: 'PUT',
    body: { collection: data },
    types: [
      UPDATE_COLLECTION_REQUEST,
      UPDATE_COLLECTION_SUCCESS,
      UPDATE_COLLECTION_FAILURE,
    ],
  },
})

export const getCollectionsList = (options = {}) => ({
  [CALL_API]: {
    endpoint: '/collections',
    method: 'GET',
    query: {
      ...options,
    },
    types: [
      GET_COLLECTIONS_LIST_REQUEST,
      GET_COLLECTIONS_LIST_SUCCESS,
      GET_COLLECTIONS_LIST_SUCCESS,
    ],
  },
})

export const fetchCollections = brandId => ({
  [CALL_API]: {
    endpoint: `/brands/${brandId}/collections`,
    method: 'GET',
    types: [
      {
        type: FETCH_COLLECTIONS_REQUEST,
        meta: {
          brandId,
        },
      },
      {
        type: FETCH_COLLECTIONS_SUCCESS,
        meta: {
          brandId,
        },
      },
      FETCH_COLLECTIONS_FAILURE,
    ],
  },
})

export const setActiveStatus = (collectionId, status) => ({
  [CALL_API]: {
    endpoint: `/collections/${collectionId}/${
      status === false ? 'deactivate' : 'activate'
    }`,
    method: 'POST',
    types: [
      SET_COLLECTION_STATUS_REQUEST,
      SET_COLLECTION_STATUS_SUCCESS,
      SET_COLLECTION_STATUS_FAILURE,
    ],
  },
})

export const shouldFetchCollections = (collections, brandId) => {
  return brandId && !collections[brandId]
}

export const fetchCollectionsIfNeeded = brandId => {
  return (dispatch, getState) => {
    const { collections } = getState()

    return shouldFetchCollections(collections.brands, brandId)
      ? dispatch(fetchCollections(brandId))
      : null
  }
}
