/* @flow */

import { useEffect, useState } from 'react'

import { createApiHook, createDataCache, quickCreateHook } from '../hooks'

import { getCategories, getCategoryProducts } from './api'
import type { BrunoRequestOptions, Category, Id } from '../types'

const fetchCategoriesForHook = (options?: BrunoRequestOptions) => {
  console.log('FETCH', options)
  return getCategories(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.categories,
      }
    } else {
      return response
    }
  })
}

export const useCategories = createApiHook<Array<Category>>(
  fetchCategoriesForHook,
  []
)

const { hook: useCategoriesProduct } = quickCreateHook<Customer | null>(
  getCategoryProducts,
  null,
  null
)

const {
  cache: { clearCache: clearCategoriesCache },
  hook: useCachedCategories,
} = createDataCache(useCategories)

export { clearCategoriesCache, useCachedCategories, useCategoriesProduct }
