import React from 'react'
import PropTypes from 'prop-types'

export default class FulfilledField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lastBlur: props.value,
      value: props.value,
    }

    this.ensureInteger = this.ensureInteger.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        lastBlur: nextProps.value,
        value: nextProps.value,
      })
    }
  }

  changeValue(newValue) {
    this.setState(
      {
        value: newValue,
      },
      () => {
        if (!isNaN(parseInt(newValue))) {
          this.props.onChange(parseInt(this.state.value))
        }
      }
    )
  }

  ensureInteger(e) {
    if (e.target.value === '') {
      this.changeValue(this.state.lastBlur)
    } else {
      this.setState({
        lastBlur: e.target.value,
      })

      this.props.onBlur.call(this, e)
    }
  }

  onChange(e) {
    const { maxValue } = this.props

    let givenValue = e.target.value

    if (givenValue > maxValue) {
      givenValue = maxValue
    }

    if (givenValue < 0) {
      givenValue = 0
    }

    this.changeValue(givenValue)
  }

  render() {
    // export onChange, onBlur, value to ensure they are not overridden by ...rest
    const {
      editable,
      className,
      onBlur,
      onChange,
      value: defaultValue,
      maxValue,
      ...rest
    } = this.props

    const { value } = this.state

    return (
      <input
        type="number"
        className={className + ' form-control product-table__fulfilled-field'}
        value={value}
        min={0}
        max={maxValue}
        disabled={!editable}
        onBlur={this.ensureInteger}
        onChange={this.onChange}
        {...rest}
      />
    )
  }
}

FulfilledField.propTypes = {
  editable: PropTypes.bool,
  value: PropTypes.number,
}

FulfilledField.defaultProps = {
  className: '',
  editable: false,
  onBlur: () => {},
  onChange: () => {},
  value: 0,
}
