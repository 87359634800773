/* @flow */

import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'

import FieldTypeSelector from '../../../../../custom-fields/components/FieldTypeSelector'

import type { ColumnConfig } from '../../types'

const custom_field: (options: Options) => ColumnConfig = ({
  customField,
  editable = false,
  rows,
  ...rest
}) => {
  const key = `meta_${customField.internal_name}`

  return {
    // if there is no default value then the row will unmount and mount all the time
    default_value: [{ [key]: null }],
    editable: editable,
    key: key,
    label: customField.label,
    rows: rows,
    split_by_values: true,
    value: ({ lines }) => {
      let value = null
      for (let line of lines) {
        if (
          line &&
          !Array.isArray(line.meta) &&
          line.meta[customField.internal_name]
        ) {
          value = line.meta[customField.internal_name]
          break
        }
      }

      return [{ [key]: value }]
    },
    render: ({
      config,
      edit,
      onColumnValueChange,
      onEdit,
      product,
      variants,
      value,
    }) => {
      return (
        <CustomFieldForm
          customField={customField}
          edit={edit}
          editable={config.editable}
          onChange={onColumnValueChange}
          onEdit={onEdit}
          product={product}
          value={value}
          valueKey={key}
          variants={variants}
        />
      )
    },
  }
}

export default custom_field

const CustomFieldForm = ({
  customField,
  context,
  edit = false,
  editable = false,
  onChange,
  onEdit,
  product,
  variants,
  value,
  valueKey,
}: Props) => {
  const currentValue = React.useMemo(() => {
    return value[0] ? value[0][valueKey] : null
  }, [valueKey, value])

  const onChangeValue = React.useCallback(
    value => {
      onChange({
        __partial_line_meta: { [customField.internal_name]: value },
      })
    },
    [customField, onChange]
  )

  return (
    <div>
      <FieldTypeSelector
        name={customField.internal_name}
        type={customField.field_type}
        value={currentValue}
        options={customField.options}
        onChange={newValue =>
          onChangeValue(newValue[customField.internal_name])
        }
        description={customField.description}
      />
    </div>
  )
}
