import { CALL_API } from 'infrastructure/middleware/api'
import {
  refreshSession,
  setAccessToken,
  setAdminEmail,
} from 'infrastructure/actions/session'
import { resetState } from 'app/rootReducer'
import { switchIdentity } from '../../admin/actions'

import { clearProductGroupCache } from '../../products/hooks'

export const INVITE_BRAND_REQUEST = 'INVITE_BRAND_REQUEST'
export const INVITE_BRAND_SUCCESS = 'INVITE_BRAND_SUCCESS'
export const INVITE_BRAND_FAILURE = 'INVITE_BRAND_FAILURE'

export const inviteBrand = email => ({
  [CALL_API]: {
    endpoint: '/brands/invite-brand',
    method: 'POST',
    body: {
      message: email.message,
      to: email.to,
    },
    types: [INVITE_BRAND_REQUEST, INVITE_BRAND_SUCCESS, INVITE_BRAND_FAILURE],
  },
})

export const GET_USER_ENTITIES_REQUEST = 'GET_USER_ENTITIES_REQUEST'
export const GET_USER_ENTITIES_SUCCESS = 'GET_USER_ENTITIES_SUCCESS'
export const GET_USER_ENTITIES_FAILURE = 'GET_USER_ENTITIES_FAILURE'

export const getUserEntities = () => ({
  [CALL_API]: {
    endpoint: '/entities/my-available-entities',
    method: 'GET',
    types: [
      GET_USER_ENTITIES_REQUEST,
      GET_USER_ENTITIES_SUCCESS,
      GET_USER_ENTITIES_FAILURE,
    ],
  },
})

export const SWITCH_ENTITY_REQUEST = 'SWITCH_ENTITY_REQUEST'
export const SWITCH_ENTITY_SUCCESS = 'SWITCH_ENTITY_SUCCESS'
export const SWITCH_ENTITY_FAILURE = 'SWITCH_ENTITY_FAILURE'

export const switchEntity = (entityId, history, redirect = '/') => {
  return (dispatch, getState) => {
    /**
     * The current URL might be something like /customers/edit/123. This will cause an
     * exception as the data is not there, so we redirect first.

     * You do always want to redirect, e.g. when you go to /shop/1234 but you need to login
     * another entity to view the shop we change the entity before rendering the shop and thus
     * do not need to redirect
     */
    if (history && redirect) {
      history.push(redirect)
    }

    const adminEmail = getState().session.adminEmail

    return dispatch({
      [CALL_API]: {
        endpoint: '/login/switch-entity',
        method: 'POST',
        body: {
          entity_id: entityId,
        },
        types: [
          SWITCH_ENTITY_REQUEST,
          SWITCH_ENTITY_SUCCESS,
          SWITCH_ENTITY_FAILURE,
        ],
      },
    }).then(response => {
      clearProductGroupCache()

      dispatch(
        switchIdentity(
          response.payload.accessToken,
          response.payload.accessTokenExpiration,
          adminEmail
        )
      )

      return dispatch(refreshSession())
    })
  }
}
