/* @flow */

import { useEffect, useState } from 'react'

import { getClaimCauses } from '../api'

import type { ClaimCause } from '../../types'

let fetchRequest

export const useClaimCauses = () => {
  const [state, setState] = useState<{
    causes: Array<ClaimCause>,
    isFetching: boolean,
  }>({
    causes: [],
    isFetching: false,
  })

  useEffect(() => {
    setState(s => ({
      ...s,
      isFetching: true,
    }))

    if (!fetchRequest) {
      fetchRequest = getClaimCauses()
    }

    fetchRequest.then(response => {
      if (!response.error) {
        setState({
          isFetching: false,
          causes: response.payload.claim_causes,
        })
      } else {
        setState(s => ({
          ...s,
          isFetching: false,
        }))
      }
    })
  }, [])

  return [state.causes, state.isFetching]
}
