import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { createAction } from '../shared'
import {
  IsBrandPredicate,
  IsNotB2COrderPredicate,
  CanDiscountProductsPredicate,
  UnlockedOrderPredicate,
} from './shared'

import ClaimCauseSelector from '../../../../orders/components/ClaimCauseSelector'

const ClaimCauseAction = ({ callback, extraPropsByProduct = {}, product }) => {
  const productProps = extraPropsByProduct[product.id]
  const value = productProps ? productProps.claim_cause_id : null

  return (
    <div style={{ minWidth: '120px' }}>
      <ClaimCauseSelector
        onChange={type => callback(type)}
        placeholder="Claim cause"
        simpleValue
        value={value}
      />
    </div>
  )
}

const createClaimCauseAction = () => {
  return createAction(
    (type, { product, lines }, { setExtraPropsByProduct }) => {
      return setExtraPropsByProduct(product.id, { claim_cause_id: type })
    },
    ClaimCauseAction,
    [],
    { size: 'xs' }
  )
}

export default createClaimCauseAction

const Button = styled.span`
  font-weight: bold;
`
