import React from 'react'
import styled, { keyframes } from 'styled-components'

import { FiLoader } from 'react-icons/fi'

const Button = ({
  children,
  color = 'primary',
  onClick,
  disabled,
  submitting,
  type,
  style,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const classNames = []

  const isDisabled = disabled === true || isSubmitting

  if (isDisabled) {
    classNames.push('disabled')
  }

  if (isSubmitting) {
    classNames.push('submitting')
  }

  const wrappedOnClick = React.useCallback(
    e => {
      if (!onClick) {
        return
      }

      e.persist()

      const maybePromise = onClick(e)

      if (maybePromise && maybePromise.then) {
        setIsSubmitting(true)

        maybePromise
          .then(() => {
            setIsSubmitting(false)
          })
          .catch(reason => {
            setIsSubmitting(false)

            return Promise.reject(reason)
          })
      }
    },
    [onClick, setIsSubmitting]
  )

  React.useEffect(() => {
    setIsSubmitting(submitting)
  }, [submitting, setIsSubmitting])

  return (
    <ButtonWrapper
      color={color}
      type={type || 'button'}
      onClick={!isDisabled ? wrappedOnClick : undefined}
      disabled={isDisabled}
      className={classNames.join(' ')}
      style={style}
    >
      <Content className="buttonContent">
        <div>{children}</div>
      </Content>

      <Loading className="loaderIcon">
        <FiLoader style={{ verticalAlign: 'middle' }} />
      </Loading>
    </ButtonWrapper>
  )
}

export default Button

const colorToHex = color => {
  let bgHex = '#6333eb'
  let colorHex = '#fff'
  switch (color) {
    case 'default':
      bgHex = '#777777'
      colorHex = '#fff'
      break

    case 'white':
      bgHex = '#fff'
      colorHex = '#575757'
      break

    case 'red':
      bgHex = '#ac3434'
      colorHex = 'white'
      break
  }

  return [bgHex, colorHex]
}

const ButtonWrapper = styled.button`
  position: relative;
  color: ${({ color }) => colorToHex(color)[1]};
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.75px;
  background-color: ${({ color }) => colorToHex(color)[0]};
  display: block;
  padding: 11px;
  text-align: center;
  border-radius: 0;
  width: 100%;
  border: 0;
  outline: 0;
  box-shadow: none;

  transition: all 0.4s;

  &.disabled {
    opacity: 0.75;
  }

  &.submitting {
    .buttonContent {
      transform: scale(0.1);
      opacity: 0;
    }

    .loaderIcon {
      opacity: 1;
      transform: scale(1);
    }
  }
`

const Content = styled.div`
  transition: all 0.75s;
`

const Spinner = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`

const Loading = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  transform: scale(0.1);
  transition: all 0.75s;
  transform-origin: center 0px;

  > * {
    font-size: 25px;
    animation: ${Spinner} 2s linear infinite;
    margin-left: -10px;
    margin-top: -20px;
  }
`
