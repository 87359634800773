/* @flow */

import * as React from 'react'
import { PureComponent } from 'react'
import { Field, type FieldProps } from 'formik'
import { FormControl, InputGroup } from 'react-bootstrap'
import { ErrorLabel } from '../Forms'

import { HelpIndicator } from '../../../app/shared'

type Props = {
  disabled?: boolean,
  help?: string,
  label: string,
  id: string,
  name: string,
  noError?: boolean,
  type?: 'success',
  value: string | number | boolean,
}

const RadioInput = (props: Props) => {
  return <Field component={renderRadioInput} {...props} />
}

export default RadioInput

export const renderRadioInput = ({
  field: { onBlur, name, onChange, value: fieldValue },
  form: { touched, errors, setFieldValue },
  onChange: propsOnChange,
  ...props
}: FieldProps & Props) => {
  const onChangeWrapper = React.useCallback(() => {
    setFieldValue(name, props.value)

    if (propsOnChange) {
      propsOnChange(props.value)
    }
  }, [name, props.value, setFieldValue, propsOnChange])

  return (
    <div>
      <div className={`radio radio-${props.type || 'success'}`}>
        <input
          disabled={props.disabled}
          id={props.id}
          onChange={onChangeWrapper}
          checked={fieldValue === props.value}
          type="radio"
          value={props.value}
        />
        <label htmlFor={props.id}>
          {props.label}
          {props.help && (
            <span style={{ marginLeft: 4 }}>
              <HelpIndicator id={props.id} bsSize="sm">
                {props.help}
              </HelpIndicator>
            </span>
          )}
        </label>
      </div>

      {!props.noError && touched[name] && errors[name] && (
        <ErrorLabel>{errors[name]}</ErrorLabel>
      )}
    </div>
  )
}
