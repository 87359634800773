import React from 'react'

import WebshopMobileSessionListModal from './WebshopMobileSessionListModal'
import WebshopSessionListModal from './WebshopSessionListModal'

import { WebshopContext } from '../../shared'

const WebshopSessionListModalWrapper = props => {
  const { isMobile } = React.useContext(WebshopContext)

  const Component = isMobile
    ? WebshopMobileSessionListModal
    : WebshopSessionListModal

  return <Component {...props} />
}

export default WebshopSessionListModalWrapper
