export const VISIBILITY_OPTION_BRAND = 'brand'
export const VISIBILITY_OPTION_BOTH = 'both'

export const IMAGES_BANK_VISIBILITY_OPTIONS = [
  {
    value: VISIBILITY_OPTION_BRAND,
    label: 'Brand accounts only',
  },
  {
    value: VISIBILITY_OPTION_BOTH,
    label: 'Brand accounts and retailers',
  },
]

// image sizes
export const IMAGE_SIZE_SMALL = 'small'
export const IMAGE_SIZE_MEDIUM = 'medium'
export const IMAGE_SIZE_LARGE = 'large'
export const IMAGE_SIZE_CUSTOM = 'custom'
// image shape
export const IMAGE_RADIUS_CIRCLE = 'circle'
export const IMAGE_RADIUS_ROUNDED = 'rounded'
export const IMAGE_RADIUS_SQUARE = 'square'

export const PRODUCT_TABLE_IMAGE_SIZE_OPTIONS = [
  {
    value: IMAGE_SIZE_SMALL,
    label: 'Small',
  },
  {
    value: IMAGE_SIZE_MEDIUM,
    label: 'Medium',
  },
  {
    value: IMAGE_SIZE_LARGE,
    label: 'Large',
  },
  {
    value: IMAGE_SIZE_CUSTOM,
    label: 'Custom',
  },
]

export const PRODUCT_TABLE_IMAGE_SHAPE_OPTIONS = [
  {
    value: IMAGE_RADIUS_CIRCLE,
    label: 'Circle',
  },
  {
    value: IMAGE_RADIUS_ROUNDED,
    label: 'Rounded',
  },
  {
    value: IMAGE_RADIUS_SQUARE,
    label: 'Square',
  },
]

export const NEW_ROLE_TEMPLATE = {
  id: 'new_role_',
  entity_id: null,
  name: 'New Role ',
  permissions: [],
  slug: 'New Role - ',
  locked: false,
  users: [],
}
