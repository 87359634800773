/* @flow */

import { createApiHook } from '../../hooks'
import type {
  BrunoRequestOptions,
  DataTableAttribute,
  Id,
  Prepayment,
} from '../../types'
import {
  getPrepayment,
  getPrepaymentAttributes,
  getPrepaymentPayStatus,
  getUnpaidCreditCardPrepayment,
} from '../api'

const fetchPrepaymentForHook = (
  prepaymentId: Id,
  options?: BrunoRequestOptions
) => {
  return getPrepayment(prepaymentId, options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload,
      }
    } else {
      return response
    }
  })
}

export const usePrepayment = createApiHook<Prepayment | null>(
  fetchPrepaymentForHook
)

const fetchPrepaymentAttributesForHook = (options?: Object) =>
  getPrepaymentAttributes(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.attributes,
      }
    } else {
      return response
    }
  })

export const usePrepaymentAttributes = createApiHook<Array<DataTableAttribute>>(
  fetchPrepaymentAttributesForHook,
  []
)

const fetchPrepaymentPayStatusForHook = (prepaymentId: Id, token: string) => {
  return getPrepaymentPayStatus(prepaymentId, token).then(response => {
    if (!response.error) {
      return {
        entity: response.payload,
      }
    } else {
      return response
    }
  })
}

export const usePrepaymentPayStatus = createApiHook<Object | null>(
  fetchPrepaymentPayStatusForHook
)

const fetchUnpaidCreditCardPrepaymentForHook = (orderId: Id) => {
  return getUnpaidCreditCardPrepayment(orderId).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.unpaid_credit_card_prepayment,
      }
    } else {
      return response
    }
  })
}

export const useUnpaidCreditCardPrepayment = createApiHook<Object | null>(
  fetchUnpaidCreditCardPrepaymentForHook
)
