/* @flow */

import api from '../api'

import type { ApiResponse, Id, PublicFileResponse } from '../types'

export const checkDocumentStatus = (
  id: Id
): Promise<ApiResponse<{ status: 'not_queued' | 'generating' | 'done' }>> =>
  api.post(`/documents/${id}/pdf-status`)

export const downloadDocument = (
  id: Id
): Promise<ApiResponse<PublicFileResponse>> =>
  api.get(`/documents/${id}/download`)

export const getDocuments = (key: string, value: mixed) =>
  api.get('/documents', {
    query: {
      key,
      value,
    },
  })

export const getDocument = (id: Id) => api.get(`/documents/${id}`)

export const regenerateDocument = (id: Id) =>
  api.post(`/documents/${id}/regenerate`)

export const resendDocument = (
  id: Id,
  recipients: Array<{ email: string, name?: string }>,
  message?: ?string
) =>
  api.post(`/documents/${id}/resend-document`, {
    body: {
      recipients,
      message,
    },
  })

export const sendDocument = (
  id: Id,
  recipients: Array<{ email: string, name?: string }>,
  subject: string,
  message?: ?string
) =>
  api.post(`/documents/${id}/send`, {
    body: {
      recipients,
      message,
      subject,
    },
  })

export const createDocumentPdfLink = (id: Id, regenerate) =>
  api.post(`/documents/${id}/create-pdf-link`, {
    body: {
      regen: regenerate,
    },
  })

export const getPrimaryDocument = (dataType: string, id: Id) =>
  api.get(`/documents/primary-document`, {
    query: {
      data_type: dataType,
      id: id,
    },
  })

export const createPrimaryDocumentPdfLink = (dataType: string, id: Id) =>
  api.post(`/documents/create-primary-pdf-link`, {
    body: {
      data_type: dataType,
      id,
    },
  })

export const getDocumentsByPrimaryContext = (dataType: string, id: Id) =>
  api.get('/documents/by-primary-context', {
    query: {
      data_type: dataType,
      id,
    },
  })

export const invalidateDocumentsByDataType = (dataType: string, id: Id) =>
  api.post(`/documents/invalidate-documents-by-data-type`, {
    body: {
      data_type: dataType,
      id,
    },
  })
