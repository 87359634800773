/* @flow */

import * as React from 'react'
import { Component } from 'react'
import styled from 'styled-components'
import keyBy from 'lodash/keyBy'
import chunk from 'lodash/chunk'
import uniq from 'lodash/uniq'
import memo from 'memoize-one'

import {
  getProductsList,
  getProductsListV2,
  getProducts as getProductsDirect,
  getProductTableProducts,
} from '../api'
import { getProducts } from '../../shop/api'

import { SessionContext } from '../../shared'
import type { Customer, Id, Product, Variant } from '../../types'

export type ProductCacheType = { [string]: Product }
export type VariantCacheType = { [string]: Variant }

type Props = {
  brandId: Id,
  customer?: null | Customer,
  dropId?: null | Id,
  mode: 'direct' | 'inventory' | 'list' | 'shop' | 'product_table',
  onLoad?: Function,
  productIds: Array<Id>,
  render: React.ComponentType<{
    isFetching: boolean,
    isInitialized: boolean,
    keyCreator: (id: Id) => string,
    productCache: ProductCacheType,
    renderData?: Object,
    variantCache: VariantCacheType,
  }>,
  requestData?: Object,
  renderData?: Object,
}

type State = {
  isInitialized: boolean,
  openProductPromises: { [string]: Promise<{ products: Array<Product> }> },
  productCache: ProductCacheType,
  products: Array<Product>,
  variantCache: VariantCacheType,
}

export default class ProductCache extends Component<Props, State> {
  static defaultProps = {
    customer: null,
    dropId: null,
    mode: 'shop',
    requestData: {},
    renderData: {},
  }

  static contextType = SessionContext

  state = {
    isInitialized: false,
    openProductPromises: {},
    productCache: {},
    products: [],
    variantCache: {},
  }

  componentDidMount() {
    this._ensureProductsAreLoaded()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // When request data changes or database changes the returned
    // products will be completely different. So we purge the current cache
    // and refetch again
    if (
      prevProps.requestData !== this.props.requestData ||
      prevProps.mode !== this.props.mode
    ) {
      this._purgeAndReloadCache()
    } else if (
      prevProps.dropId !== this.props.dropId ||
      prevProps.productIds !== this.props.productIds
    ) {
      this._ensureProductsAreLoaded()
    }
  }

  reload = () => {
    this._purgeAndReloadCache()
  }

  render() {
    const {
      dropId,
      render: RenderComponent,
      productIds,
      renderData,
      renderIsFunction,
    } = this.props
    const { isInitialized, openProductPromises, productCache, variantCache } =
      this.state

    if (!isInitialized) {
      return null
    }

    const props = {
      isFetching: Object.keys(openProductPromises).length > 0,
      keyCreator: this._keyCreator,
      productCache: productCache,
      productIds: productIds,
      products: createProductsArray(productIds, this._keyCreator, productCache),
      renderData: renderData,
      variantCache: variantCache,
    }

    if (renderIsFunction) {
      return this.props.render(props)
    }

    return <RenderComponent {...props} />
  }

  _ensureProductsAreLoaded = () => {
    const { brandId, customer, dropId, mode, onLoad, productIds, requestData } =
      this.props
    const { brands } = this.context
    const { productCache, openProductPromises } = this.state

    const idsWithoutOpenPromiseOrCache = productIds.filter(id => {
      const cacheKey = this._keyCreator(id)

      return !productCache[cacheKey] && !openProductPromises[cacheKey]
    })

    const brand = brands[brandId]

    let productsPromise
    if (idsWithoutOpenPromiseOrCache.length > 0) {
      const chunked = chunk(idsWithoutOpenPromiseOrCache, 50)

      for (let chunk of chunked) {
        const wrapThen = response => ({
          ids: chunk,
          response,
        })

        switch (mode) {
          case 'list':
            productsPromise = getProductsListV2({
              ...requestData,
              filter_groups: [
                {
                  not: false,
                  filters: [
                    {
                      key: 'id',
                      operator: 'in',
                      value: chunk,
                    },
                  ],
                },
              ],
            }).then(wrapThen)
            break
          case 'inventory':
            productsPromise = getProductsList({
              ...requestData,
              product_id: chunk,
            }).then(wrapThen)
            break
          case 'direct':
            let includes = ['variants']
            if (requestData.includes) {
              includes = [...includes, ...requestData.includes]
            }

            productsPromise = getProductsDirect({
              ...requestData,
              filter_groups: [
                {
                  not: false,
                  filters: [
                    {
                      key: 'id',
                      operator: 'in',
                      value: chunk,
                    },
                  ],
                },
              ],
              includes,
            }).then(wrapThen)
            break

          case 'product_table':
            const customerId = customer ? customer.id : null

            productsPromise = getProductTableProducts(
              brandId,
              customerId,
              chunk,
              [],
              requestData
            ).then(wrapThen)
            break

          case 'shop':
          default:
            productsPromise = getProducts(brandId, {
              ...requestData,
              product_id: chunk,
            }).then(wrapThen)
            break
        }

        productsPromise.then(({ response, ids: idsOfPromise }) => {
          if (!response.error) {
            this.setState(
              s => {
                const openPromises = { ...s.openProductPromises }
                const productCache = { ...s.productCache }
                const variantCache = { ...s.variantCache }

                const productsById = keyBy(response.payload.products, 'id')
                for (var id of chunk) {
                  const cacheKey = this._keyCreator(id)

                  delete openPromises[cacheKey]

                  if (productsById[id]) {
                    productCache[cacheKey] = sortVariantsByAttributes(
                      productsById[id],
                      brand
                    )

                    for (let variant of productsById[id].variants) {
                      variantCache[this._keyCreator(variant.id)] = variant
                    }
                  }
                }

                return {
                  productCache: productCache,
                  products: Object.values(productCache),
                  openProductPromises: openPromises,
                  variantCache: variantCache,
                }
              },
              () => {
                if (onLoad) {
                  onLoad({
                    productCache: this.state.productCache,
                    products: this.state.products,
                    variantCache: this.state.variantCache,
                  })
                }
              }
            )
          } else {
            this.setState(s => {
              const openPromises = { ...s.openProductPromises }
              for (var id of idsOfPromise) {
                const cacheKey = this._keyCreator(id)

                delete openPromises[cacheKey]
              }

              return {
                openProductPromises: openPromises,
              }
            })
          }
        })

        this.setState(s => ({
          ...s,
          isInitialized: true,
          openProductPromises: chunk.reduce(
            (carry, id) => {
              carry[this._keyCreator(id)] = productsPromise
              return carry
            },
            { ...s.openProductPromises }
          ),
        }))
      }
    } else {
      this.setState(s => ({
        ...s,
        isInitialized: true,
      }))
    }
  }

  _keyCreator = (id: Id) => {
    // $FlowIssue
    return createProductCacheKey(id, this.props.dropId)
  }

  _purgeAndReloadCache = () => {
    this.setState(
      {
        productCache: {},
        variantCache: {},
      },
      this._ensureProductsAreLoaded
    )
  }
}

const createProductCacheKey = (id: Id, dropId: null | Id) =>
  `${dropId || 'null'}-${id}`

const sortVariantsByAttributes = (product, brand) => {
  const defaultProductTableSettings = brand.product_table_settings['default']

  if (!defaultProductTableSettings) {
    return product
  }

  const horizontalAttribute =
    defaultProductTableSettings.settings.horizontal_attribute

  const valuesByAttribute = {}
  let productAttributes = product.attributes
  if (!Array.isArray(productAttributes)) {
    productAttributes = Object.values(productAttributes)
  }

  for (let attribute of productAttributes) {
    valuesByAttribute[attribute] = new Set()
  }
  for (let variant of product.variants) {
    for (let [k, v] of Object.entries(variant.attributes)) {
      valuesByAttribute[k].add(v)
    }
  }

  const attributes =
    product.type === 'configurable'
      ? product.attributes
      : sortAttributes(
          [...productAttributes],
          horizontalAttribute,
          valuesByAttribute
        )

  let variants = [...product.variants]
  if (brand.settings.use_attributes_order) {
    variants = sortVariantsByCustomSortOrder(variants, attributes, brand)
  } else {
    variants = sortVariantsByName(variants, attributes)
  }

  return {
    ...product,
    attributes,
    variants,
  }
}

const createProductsArray = memo((productIds, keyCreator, productCache) => {
  return uniq(productIds.map(id => parseInt(id)).filter(id => !isNaN(id)))
    .map(id => {
      return productCache[keyCreator(id)]
    })
    .filter(product => product)
})

const sortAttributes = (attributes, horizontalAttribute, valuesByAttribute) => {
  attributes.sort((a, b) => {
    if (a === horizontalAttribute) {
      return 1
    }

    if (b === horizontalAttribute) {
      return -1
    }

    if (!valuesByAttribute) {
      return 0
    }

    const aCount = valuesByAttribute[a].size
    const bCount = valuesByAttribute[b].size

    return aCount - bCount
  })

  return attributes
}

const sortVariantsByCustomSortOrder = (variants, attributes, brand) => {
  const attributesOrder = brand.settings.attributes_order

  variants.sort((a, b) => {
    if (a.placeholder_variant) {
      return 1
    }
    if (b.placeholder_variant) {
      return 1
    }

    for (let attribute of attributes) {
      const aValue = a.attributes[attribute] ? a.attributes[attribute] : false
      const bValue = b.attributes[attribute] ? b.attributes[attribute] : false

      let aOrder = false
      let bOrder = false
      if (attributesOrder[attribute]) {
        aOrder = attributesOrder[attribute].indexOf(aValue)
        bOrder = attributesOrder[attribute].indexOf(bValue)
      }

      if (aOrder === -1) {
        aOrder = 100000
      }
      if (bOrder === -1) {
        bOrder = 100000
      }

      if (aOrder > bOrder) {
        return 1
      } else if (aOrder < bOrder) {
        return -1
      }

      const aValueLowercase = aValue !== false ? aValue.toLowerCase() : aValue
      const bValueLowercase = bValue !== false ? bValue.toLowerCase() : bValue

      if (aValueLowercase > bValueLowercase) {
        return 1
      } else if (aValueLowercase < bValueLowercase) {
        return -1
      }
    }

    return 0
  })

  return variants
}

const sortVariantsByName = (variants, attributes) => {
  variants.sort((a, b) => {
    if (a.placeholder_variant) {
      return 1
    }
    if (b.placeholder_variant) {
      return 1
    }

    for (let attribute of attributes) {
      const aValue = a.attributes[attribute]
        ? a.attributes[attribute].toLowerCase()
        : false
      const bValue = b.attributes[attribute]
        ? b.attributes[attribute].toLowerCase()
        : false

      if (aValue > bValue) {
        return 1
      } else if (aValue < bValue) {
        return -1
      }
    }

    return 0
  })

  return variants
}
