/* @flow */

import * as React from 'react'
import styled from 'styled-components'
import uuid from 'uuid'
import useViewportSizes from 'use-viewport-sizes'
import { Formik, FieldArray } from 'formik'
import { type Location as LocationType } from 'react-router-dom'
import { Modal } from 'react-bootstrap'

import ActionButton from '../../../infrastructure/components/ActionButton'
import WebshopActionButton from './WebshopActionButton'
import CloudinaryResource from '../../../infrastructure/components/CloudinaryResource'
import * as Tabs from '../../../infrastructure/components/Tabs'
import { PermissionContext } from '../../../infrastructure/components/Authorization'
import DropSelector from '../../orders/components/DropSelector'
import WebshopSessionManager from './WebshopSessionManager'
import {
  CheckboxInput,
  ControlLabel,
  FormGroup,
  FormInput,
  SelectInput,
  SaveButton,
} from '../../../infrastructure/components/Formik'
import { useGridEditor } from '../../../infrastructure/components/GridEditor/hooks'

import {
  useWebshopSplash,
  useWebshopSplashForSession,
  useWebshopSessionList,
} from '../hooks'
import { createWebshopSession, updateWebshopSplashBulk } from '../api'

import { SessionContext, msg } from '../../shared'
import { WebshopContext } from '../shared'
import type { ConnectedBrand } from '../../types'

const GridEditor = React.lazy(() =>
  import('../../../infrastructure/components/GridEditor')
)

const GridEditorFormComponents = {
  drop_link: React.lazy(() =>
    import(
      '../../../infrastructure/components/GridEditor/GridItems/GridItemWebshopDropLinkForm'
    )
  ),
  image: React.lazy(() =>
    import(
      '../../../infrastructure/components/GridEditor/GridItems/GridItemImageForm'
    )
  ),
  text: React.lazy(() =>
    import(
      '../../../infrastructure/components/GridEditor/GridItems/GridItemTextForm'
    )
  ),
}

type Props = {
  brand: ConnectedBrand,
  location: LocationType,
}

const WebshopSplash = ({
  brand,
  history,
  location,
  setUseWebshopSessionId,
  setShowManualEditCustomerModal,
  setShowRetailerSelector,
  setShowSessionListModal,
}: Props) => {
  const {
    availableBrand,
    shopUrl,
    updateWebshopSession,
    webshopSession,
    webshopSettings,
  } = React.useContext(WebshopContext)
  const { entity } = React.useContext(SessionContext)
  const [{ editMode, editSplash, editSplashItem }, setEditState] =
    React.useState({
      editMode: false,
      editSplash: null,
      editSplashItem: null,
    })

  const [
    allWebshopSessions,
    isFetchingWebshopSessionList,
    { isInitialized: isWebshopSessionListInitialized, refresh },
  ] = useWebshopSessionList(brand.id)

  const [viewportWidth, viewportHeight] = useViewportSizes()
  const webshopSplashFetchArgs = React.useMemo(() => {
    return [brand.id, webshopSession.id]
  }, [brand, webshopSession])
  const [
    webshopSplash,
    isFetchingWebshopSplash,
    {
      isInitialized: isWebshopSplashInitialized,
      refresh: refreshSessionSplash,
    },
  ] = useWebshopSplashForSession(webshopSplashFetchArgs)

  if (!isWebshopSessionListInitialized || !isWebshopSplashInitialized) {
    return null
  }

  return (
    <Container>
      <HeaderContainer fullWidth={webshopSettings.full_width}>
        <WebshopSessionManagerContainer>
          <WebshopSessionManager
            availableBrand={availableBrand}
            entity={entity}
            setShowManualEditCustomerModal={setShowManualEditCustomerModal}
            setShowRetailerSelector={setShowRetailerSelector}
            setShowSessionListModal={setShowSessionListModal}
            updateWebshopSession={updateWebshopSession}
            webshopSession={webshopSession}
          />
        </WebshopSessionManagerContainer>

        <LogoContainer>
          <CloudinaryResource
            brandId={brand.id}
            id={brand.logo}
            presets="logo_webshop"
            fallback="logo_black"
          />
        </LogoContainer>
      </HeaderContainer>

      {editMode && (
        <div>
          <React.Suspense fallback={<div>Loading...</div>}>
            <EditForm
              activeSplash={webshopSplash}
              editMode={editMode}
              editSplash={editSplash}
              editSplashItem={editSplashItem}
              refreshSessionSplash={refreshSessionSplash}
              setEditState={setEditState}
              viewportWidth={viewportWidth}
            />
          </React.Suspense>
        </div>
      )}

      {!editMode && (
        <div>
          <NonEditRender
            activeSplash={webshopSplash}
            allWebshopSessions={allWebshopSessions}
            brand={brand}
            entity={entity}
            history={history}
            shopUrl={shopUrl}
            setEditState={setEditState}
            setUseWebshopSessionId={setUseWebshopSessionId}
            viewportWidth={viewportWidth}
            updateWebshopSession={updateWebshopSession}
            webshopSession={webshopSession}
            webshopSettings={webshopSettings}
          />
        </div>
      )}
    </Container>
  )
}

export default WebshopSplash

const Container = styled.div``

const HeaderContainer = styled.div`
  padding: 20px 0;
  position: relative;
  ${({ fullWidth }) =>
    fullWidth === true &&
    `
    padding-left: 15px;
    padding-right: 15px;
  `}
`

const LogoContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
`

const WebshopSessionManagerContainer = styled.div``

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  ${({ fullWidth }) =>
    fullWidth === true &&
    `
    padding-left: 15px;
    padding-right: 15px;
  `}
`

const ContentContainer = styled.div`
  ${({ fullWidth }) =>
    fullWidth === true &&
    `
    padding-left: 15px;
    padding-right: 15px;
  `}
`

const NonEditRender = ({
  activeSplash,
  allWebshopSessions,
  brand,
  entity,
  history,
  shopUrl,
  setEditState,
  setUseWebshopSessionId,
  updateWebshopSession,
  webshopSession,
  webshopSettings,
}) => {
  const gridItems = React.useMemo(() => {
    if (!activeSplash) {
      return []
    }

    return activeSplash.grid_items.map(gridItem => {
      const copy = { ...gridItem }

      copy.i = copy.layout.i

      return copy
    })
  }, [activeSplash])

  const { layout, itemData } = useGridEditor(gridItems)

  const onDropSubbrandClick = React.useCallback(
    inputData => {
      let useExistingSession = true
      for (let [k, v] of Object.entries(inputData)) {
        // Should not have key
        if (inputData[k] == null && webshopSession[k] != null) {
          useExistingSession = false
          break
        } else if (inputData[k] != null && webshopSession[k] != v) {
          useExistingSession = false
          break
        }
      }

      if (useExistingSession) {
        const updateData = {}
        for (let [k, v] of Object.entries(inputData)) {
          if (v !== webshopSession[k]) {
            updateData[k] = v
          }
        }

        const updatePromise =
          Object.getOwnPropertyNames(updateData).length > 0
            ? updateWebshopSession(updateData)
            : Promise.resolve({})

        return updatePromise.then(response => {
          if (!response.error) {
            history.push(`${shopUrl}/landing`)
          }
        })
      }

      const otherWebshopSessionMatching = allWebshopSessions.find(session => {
        if (
          session.type != 'webshop_session' ||
          session.customer_id !== webshopSession.customer_id
        ) {
          return false
        }

        let matches = true
        for (let [k, v] of Object.entries(inputData)) {
          if (v != session[k]) {
            matches = false
            break
          }
        }

        return matches
      })

      if (otherWebshopSessionMatching) {
        setUseWebshopSessionId(otherWebshopSessionMatching.id)

        history.push(`${shopUrl}/landing`)

        return
      }

      return createWebshopSession(brand.id, {
        ...inputData,
        customer_id: webshopSession.customer_id,
      }).then(response => {
        if (!response.error) {
          setUseWebshopSessionId(response.payload.id)

          history.push(`${shopUrl}/landing`)
        }
      })
    },
    [
      allWebshopSessions,
      brand,
      history,
      setUseWebshopSessionId,
      shopUrl,
      updateWebshopSession,
      webshopSession,
    ]
  )

  const extraComponentData = React.useMemo(() => {
    return {
      onDropSubbrandClick,
    }
  }, [onDropSubbrandClick])

  return (
    <div style={{ paddingBottom: 150 }}>
      {entity.id == brand.id && (
        <PermissionContext permission="webshop:splash">
          <ActionContainer fullWidth={webshopSettings.full_width}>
            <button
              type="button"
              className="btn btn-white"
              onClick={() =>
                setEditState({
                  editMode: true,
                  editSplash: null,
                  editSplashItem: null,
                })
              }
            >
              <span className="glyphicon glyphicon-pencil" /> Edit layout and
              pictures
            </button>
          </ActionContainer>
        </PermissionContext>
      )}

      <ContentContainer fullWidth={webshopSettings.full_width}>
        <WrappedGridEditor
          editable={false}
          extraComponentData={extraComponentData}
          layout={layout}
          itemData={itemData}
        />
      </ContentContainer>
    </div>
  )
}

const EditForm = ({
  activeSplash,
  editMode,
  editSplash,
  editSplashItem,
  refreshSessionSplash,
  setEditState,
}) => {
  const fetchAllSplashFetchArgs = React.useMemo(() => {
    return {
      includes: ['gridItems'],
    }
  }, [])
  const [allWebshopSplash, isFetchingSplash, { isInitialized, refresh }] =
    useWebshopSplash(fetchAllSplashFetchArgs)
  const [showDeleteSplashModal, setShowDeleteSplashModal] =
    React.useState(false)

  React.useEffect(() => {
    if (!editSplash && isInitialized) {
      setEditState(s => ({
        ...s,
        editSplash: activeSplash
          ? allWebshopSplash.find(splash => splash.id === activeSplash.id)
          : allWebshopSplash[0],
      }))
    }
  }, [activeSplash, editSplash, isInitialized, setEditState, allWebshopSplash])

  const initialValues = React.useMemo(() => {
    return {
      splash: allWebshopSplash.map(splash => {
        const copy = { ...splash }

        copy.grid_items = copy.grid_items.map(gridItem => {
          const copyGridItem = { ...gridItem }

          copyGridItem.i = copyGridItem.layout.i

          return copyGridItem
        })

        return copy
      }),
    }
  }, [allWebshopSplash])

  const onSave = React.useCallback(
    (values, { setSubmitting }) => {
      const mapped = values.splash.map(splash => {
        const copy = { ...splash }

        if (copy.__new) {
          delete copy.id
          delete copy.__new
        }

        copy.grid_items = copy.grid_items.map(item => {
          return {
            id: item.id,
            type: item.type,
            data: item.data,
            layout: item.layout,
            delete: item.delete,
          }
        })

        return copy
      })

      return updateWebshopSplashBulk(mapped).then(response => {
        setSubmitting(false)

        if (!response.error) {
          refresh()
          refreshSessionSplash()

          msg('success', 'The splash were saved!')

          setEditState({
            editMode: false,
            editSplash: null,
            editSplashItem: null,
          })
        }
      })
    },
    [refresh, refreshSessionSplash, setEditState]
  )

  if (!isInitialized) {
    return null
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSave}
        render={formProps => {
          return (
            <EditFormComponent
              editSplash={editSplash}
              editSplashItem={editSplashItem}
              setEditState={setEditState}
              setShowDeleteSplashModal={setShowDeleteSplashModal}
              showDeleteSplashModal={showDeleteSplashModal}
              {...formProps}
            />
          )
        }}
      />
    </div>
  )
}

const EditFormComponent = ({
  editSplash,
  editSplashItem,
  handleSubmit,
  isSubmitting,
  setEditState,
  setFieldValue,
  setShowDeleteSplashModal,
  showDeleteSplashModal,
  values,
}) => {
  const activeSplashIndex = editSplash
    ? values.splash.findIndex(splash => splash.id == editSplash.id)
    : -1

  const updatedWebshopSplash = values.splash[activeSplashIndex]

  const name = `splash.${activeSplashIndex}.grid_items`

  const onChange = React.useCallback(
    layout => {
      setFieldValue(name, layout)
    },
    [name, setFieldValue]
  )

  const {
    layout,
    itemData,
    onAddComponent,
    onChangeLayout,
    onRemoveItem,
    onUpdateItem,
  } = useGridEditor(
    updatedWebshopSplash ? updatedWebshopSplash.grid_items : [],
    onChange,
    true // deleteWithFlag
  )

  let EditorFormComponent = null
  if (editSplashItem) {
    EditorFormComponent = GridEditorFormComponents[editSplashItem.type]
  }

  const onDelete = () => {
    setFieldValue(`splash.${activeSplashIndex}.delete`, true)

    setShowDeleteSplashModal(false)
  }

  const onGridItemSave = React.useCallback(
    (...args) => {
      const response = onUpdateItem(editSplashItem, ...args)

      setEditState(s => ({
        ...s,
        editSplashItem: null,
      }))

      return response
    },
    [editSplashItem, onUpdateItem, setEditState]
  )

  return (
    <form onSubmit={handleSubmit}>
      {showDeleteSplashModal !== false && (
        <DeleteSplashModal
          onHide={() => setShowDeleteSplashModal(false)}
          onSubmit={onDelete}
          show={showDeleteSplashModal}
        />
      )}

      {editSplashItem !== null && (
        <SplashGridItemModal
          editSplashItem={editSplashItem}
          onHide={() =>
            setEditState(s => ({
              ...s,
              editSplashItem: null,
            }))
          }
          onSave={onGridItemSave}
          show={editSplashItem !== null}
        />
      )}

      <FieldArray
        name="splash"
        render={({ push }) => {
          const onNew = () => {
            const newSplash = {
              id: uuid(),
              __new: true,
              label: 'New splash',
              active: false,
              grid_items: [],
            }

            push(newSplash)

            setEditState(s => ({
              ...s,
              editSplash: newSplash,
            }))
          }

          return (
            <div>
              <Tabs.TabsBar style={{ marginTop: 0 }}>
                {values.splash
                  .filter(splash => !splash.delete)
                  .map(webshopSplash => {
                    return (
                      <Tabs.Tab
                        active={
                          editSplash && editSplash.id === webshopSplash.id
                        }
                        key={webshopSplash.id}
                        onClick={() => {
                          setEditState(s => {
                            return {
                              ...s,
                              editSplash: webshopSplash,
                            }
                          })
                        }}
                      >
                        <Tabs.Tab.TopLine />
                        <Tabs.Tab.Label>{webshopSplash.label}</Tabs.Tab.Label>

                        {webshopSplash.active && (
                          <span
                            className="label label-xxs"
                            style={{ marginLeft: -10, marginRight: 5 }}
                          >
                            Active
                          </span>
                        )}
                      </Tabs.Tab>
                    )
                  })}

                <Tabs.TabsBarButton onClick={onNew}>
                  <span className="fa fa-plus" /> Add new splash page
                </Tabs.TabsBarButton>

                <FormActionsContainer>
                  <button
                    type="button"
                    className="btn btn-white"
                    onClick={() => setShowDeleteSplashModal(activeSplashIndex)}
                  >
                    Delete
                  </button>

                  <button
                    type="button"
                    className="btn btn-white"
                    onClick={() =>
                      setEditState({
                        editMode: false,
                        editSplash: null,
                        editSplashItem: null,
                      })
                    }
                  >
                    Cancel
                  </button>

                  <SaveButton
                    className="btn btn-success"
                    submitting={isSubmitting}
                  >
                    Save
                  </SaveButton>
                </FormActionsContainer>
              </Tabs.TabsBar>

              {editSplash && (
                <>
                  <FormHeaderContainer>
                    <div className="row">
                      <div className="col-xs-3">
                        <FormGroup>
                          <ControlLabel required>Label</ControlLabel>

                          <FormInput
                            name={`splash.${activeSplashIndex}.label`}
                          />
                        </FormGroup>

                        <FormGroup>
                          <CheckboxInput
                            id={`splash-${editSplash}-active`}
                            name={`splash.${activeSplashIndex}.active`}
                            label="Active"
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </FormHeaderContainer>

                  <EditorContainer>
                    <EditorToolbar>
                      <EditorToolbarButton
                        onClick={() => onAddComponent('text')}
                      >
                        Add text
                      </EditorToolbarButton>
                      <EditorToolbarButton
                        onClick={() => onAddComponent('video')}
                      >
                        Add video
                      </EditorToolbarButton>
                      <EditorToolbarButton
                        onClick={() => onAddComponent('image')}
                      >
                        Add image
                      </EditorToolbarButton>
                      <EditorToolbarButton
                        onClick={() => onAddComponent('drop_link')}
                      >
                        Add drop link
                      </EditorToolbarButton>
                    </EditorToolbar>

                    <EditorContent>
                      <WrappedGridEditor
                        editable
                        layout={layout}
                        itemData={itemData}
                        onChange={onChangeLayout}
                        onEditItem={item =>
                          setEditState(s => ({
                            ...s,
                            editSplashItem: item,
                          }))
                        }
                        onRemoveItem={onRemoveItem}
                      />
                    </EditorContent>
                  </EditorContainer>
                </>
              )}
            </div>
          )
        }}
      />
    </form>
  )
}

const FormHeaderContainer = styled.div`
  margin-top: 20px;
`

const FormActionsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  > button {
    margin-left: 10px;
  }
`

const EditorContainer = styled.div`
  margin-top: 20px;
`

const EditorContent = styled.div``

const WrappedGridEditor = ({ ...rest }) => {
  return (
    <GridEditor cols={64} marginX={6} marginY={6} rowHeight={12} {...rest} />
  )
}

const DeleteSplashModal = ({
  onHide,
  onSubmit,
  show,
}: {
  onHide: Function,
  onSubmit: Function,
  show: boolean,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <p>Are you sure you want to delete the splash?</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-white" onClick={onHide}>
          Cancel
        </button>
        <ActionButton
          className="btn btn-danger"
          iconClass="glyphicon glyphicon-ok"
          onClick={onSubmit}
        >
          Delete
        </ActionButton>
      </Modal.Footer>
    </Modal>
  )
}

const EditorToolbar = styled.div`
  display: flex;
  margin-bottom: 20px;

  > * {
    margin-right: 10px;
  }
`

const EditorToolbarButton = styled.button.attrs({
  className: 'btn btn-white btn',
  type: 'button',
})``

const SplashGridItemModal = ({
  editSplashItem,
  onHide,
  onSave,
  show,
}: {
  onHide: Function,
  onSave: Function,
  show: boolean,
}) => {
  let EditorFormComponent = null
  if (editSplashItem) {
    EditorFormComponent = GridEditorFormComponents[editSplashItem.type]
  }

  return (
    <Modal show={show} onHide={onHide} enforceFocus={false} backdrop="static">
      <Formik
        initialValues={{
          item: editSplashItem,
        }}
        onSubmit={onSave}
        render={({ handleSubmit, isSubmitting }) => {
          const wrappedSubmit = e => {
            e.stopPropagation()
            handleSubmit(e)
          }

          return (
            <form onSubmit={wrappedSubmit}>
              <Modal.Body>
                {editSplashItem && (
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <EditorFormComponent height={300} item={editSplashItem} />
                  </React.Suspense>
                )}
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={onHide}
                >
                  Cancel
                </button>
                <SaveButton submitting={isSubmitting}>Save</SaveButton>
              </Modal.Footer>
            </form>
          )
        }}
      />
    </Modal>
  )
}
