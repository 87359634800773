/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { PermissionContext } from '../../../infrastructure/components/Authorization'
import FormatCurrency from '../../../infrastructure/components/FormatCurrency'
import Timeline from '../../../infrastructure/components/Timeline'
import { getCustomer } from '../actions'
import { getOrders } from '../../orders/actions'

import type { Currency, Customer } from '../../types'

import CustomerBudgetStatus from './CustomerBudgetStatus'
import OrderStatusLabel from '../../orders/components/OrderStatusLabel'
import InlineReports from '../../reports/components/Generator/InlineReports'
import { getCustomerSalesGoals } from '../../reports/api'

import { renderDateTime } from '../../shared'

type Props = {
  customerIds: Array<number>,
  current: number,
  onCustomerChange: Function,
  onHide: Function,
  show: boolean,
}

type State = {
  salesGoals: Array<{
    currency: Currency,
    dashboard: string,
    goal: number,
    reached: number,
  }>,
  customer: ?Customer,
  isFetching: boolean,
  orders: Array<Object>,
}

class CustomerCard extends Component<Props, State> {
  static defaultProps = {
    customerIds: [],
    current: null,
  }

  state = {
    salesGoals: [],
    customer: null,
    orders: [],
    isFetching: false,
  }

  componentDidMount() {
    this._fetchCustomer(this.props)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.current !== this.props.current) {
      this._fetchCustomer(nextProps)
    }
  }

  render() {
    const { customerIds, current, onHide, show, user } = this.props
    const { customer, isFetching, orders, salesGoals } = this.state

    const currentIndex = customerIds.indexOf(current)

    let previous = false
    let next = false

    if (currentIndex > 0) {
      previous = customerIds[currentIndex - 1]
    }
    if (currentIndex < customerIds.length - 1) {
      next = customerIds[currentIndex + 1]
    }

    let primaryAddress
    if (customer && customer.addresses) {
      primaryAddress = customer.addresses.filter(a => a.primary === true)[0]
    }
    let reportContexts = []
    if (customer) {
      reportContexts.push({
        default: true,
        key: 'customer_id',
        name: 'Customer',
        value: customer.id,
      })
    }
    if (primaryAddress) {
      reportContexts.push({
        key: 'country',
        name: `Country (${primaryAddress.country})`,
        value: primaryAddress.country,
      })
    }
    if (customer && customer.customer_groups) {
      for (var customerGroup of customer.customer_groups) {
        reportContexts.push({
          key: 'customer_group_ids',
          name: customerGroup.name,
          value: customerGroup.id,
        })
      }
    }

    return (
      <CustomModal
        show={show}
        onHide={onHide}
        dialogClassName="customer-card-modal"
      >
        {(previous || next) && (
          <Modal.Header style={{ position: 'relative' }}>
            {customer && (
              <Modal.Title>
                {`#${customer.customer_number}`} {customer.entity.name}
              </Modal.Title>
            )}
            <ControlsContainer>
              <LeftControl>
                {previous && (
                  <button
                    type="button"
                    className="btn btn-white btn-sm"
                    onClick={() => this.props.onCustomerChange(previous)}
                  >
                    <span className="glyphicon glyphicon-arrow-left" /> Previous
                  </button>
                )}
              </LeftControl>
              <RightControl>
                {next && (
                  <button
                    type="button"
                    className="btn btn-white btn-sm"
                    onClick={() => this.props.onCustomerChange(next)}
                  >
                    <span className="glyphicon glyphicon-arrow-right" /> Next
                  </button>
                )}
              </RightControl>
            </ControlsContainer>
          </Modal.Header>
        )}
        <Modal.Body>
          {isFetching && <span>Loading...</span>}
          {!isFetching && customer && (
            <div>
              <CustomerTitle />
              <SplitContainer style={{ marginBottom: 20 }}>
                <PermissionContext permission="reports:sales-goals:read">
                  <BudgetContainer>
                    <CustomerBudgetStatus
                      customer={customer}
                      salesGoals={salesGoals}
                    />
                  </BudgetContainer>
                </PermissionContext>
                <PermissionContext permission="reports:browse">
                  <InlineReportsContainer>
                    <InlineReports contexts={reportContexts} />
                  </InlineReportsContainer>
                </PermissionContext>
              </SplitContainer>
              <SplitContainer>
                <TimelineContainer>
                  <Timeline
                    chunkSize={100}
                    context={{ customer_id: current }}
                    height={982}
                    title="Timeline"
                  />
                </TimelineContainer>
                <ProfileContainer>
                  {/* <CustomerDataContainer>
                      <Title>Data</Title>

                      <CustomerEditContainer>
                        <Link className="btn btn-white btn-sm" to={`/customers/edit/${customer.id}`}>
                          <span className="glyphicon glyphicon-pencil"></span> Edit data
                        </Link>
                      </CustomerEditContainer>

                      <table className="table table-striped table-condensed" style={{marginTop: 10}}>
                        <tbody>
                          <tr>
                            <td width="200">Name:</td>
                            <td width="200">Name:</td>
                          </tr>
                        </tbody>
                      </table>
                    </CustomerDataContainer> */}

                  <CustomerOrdersContainer>
                    <Title>
                      Latest orders{' '}
                      {orders.length > 0 && (
                        <span>{`(${orders.length} orders)`}</span>
                      )}
                    </Title>

                    <table
                      className="table table-striped table-condensed"
                      style={{ marginTop: 10 }}
                    >
                      <thead>
                        <tr>
                          <th>Order number</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Total price</th>
                          <th className="listview-action" />
                        </tr>
                      </thead>
                      <tbody>
                        {orders.length === 0 && (
                          <tr>
                            <td colSpan={4}>No orders was made yet</td>
                          </tr>
                        )}
                        {orders.map(order => (
                          <tr>
                            <td>{`#${order.order_number}`}</td>
                            <td>{renderDateTime(order.approved_at)}</td>
                            <td>
                              <OrderStatusLabel
                                brandId={order.brand_id}
                                order={order}
                              />
                            </td>
                            <td>
                              <FormatCurrency currency={order.currency}>
                                {order.net_price}
                              </FormatCurrency>
                            </td>
                            <td>
                              <Link
                                className="btn btn-xs btn-white"
                                to={`/orders/show/${order.id}`}
                              >
                                <i className="glyphicon glyphicon-search" />{' '}
                                Show
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CustomerOrdersContainer>
                </ProfileContainer>
              </SplitContainer>
            </div>
          )}
        </Modal.Body>
      </CustomModal>
    )
  }

  _fetchCustomer = ({ current }) => {
    const { dispatch } = this.props

    if (!current) {
      return
    }

    this.setState({
      isFetching: true,
    })

    const options = {
      includes: ['addresses', 'entity'],
    }

    const customerPromise = dispatch(
      getCustomer(current, {
        includes: ['addresses', 'customerGroups', 'entity'],
      })
    )
    const ordersPromise = dispatch(
      getOrders(
        [],
        [
          {
            filters: [{ key: 'customer_id', value: current, operator: 'eq' }],
          },
        ],
        [{ key: 'created_at', direction: 'desc' }]
      )
    )
    const salesGoalsPromise = getCustomerSalesGoals(current)

    Promise.all([customerPromise, ordersPromise, salesGoalsPromise]).then(
      ([customerResponse, orderResponse, salesGoalsResponse]) => {
        const changes = { isFetching: false }

        if (!customerResponse.error && !orderResponse.error) {
          changes.customer = customerResponse.payload
          changes.orders = orderResponse.payload.orders
        }
        if (!salesGoalsResponse.error) {
          changes.salesGoals = salesGoalsResponse.payload.goals
        }

        this.setState(changes)
      }
    )
  }
}

export default connect()(CustomerCard)

const ControlsContainer = styled.div`
  display: flex;
  left: 10px;
  position: absolute;
  right: 10px;
  top: 15px;
`

const CustomModal = styled(Modal)`
  .customer-card-modal {
    width: 1160px;
  }
`

const BudgetContainer = styled.div`
  margin-right: 30px;
  width: 450px;
`

const InlineReportsContainer = styled.div`
  display: flex;
  height: 300px;
  flex: 1;
`

const Control = styled.div`
  position: absolute;
`

const LeftControl = styled(Control)`
  justify-content: flex-start;
  left: 0;
`

const RightControl = styled(Control)`
  justify-content: flex-end;
  right: 0;
`

const CustomerTitle = styled.div`
  font-size: 18px;
`

const SplitContainer = styled.div`
  display: flex;
`

const TimelineContainer = styled.div`
  width: 450px;
`

const ProfileContainer = styled.div`
  margin-left: 30px;
  flex: 1;
`

const Title = styled.h2`
  font-size: 22px;
  margin-top: 0;
`

const CustomerOrdersContainer = styled.div``

const CustomerDataContainer = styled.div`
  position: relative;
`

const CustomerEditContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`
