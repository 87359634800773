import React, { Component } from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Map } from 'immutable'
import difference from 'lodash/difference'
import isPlainObject from 'lodash/isPlainObject'
import Mustache from 'mustache'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import FormatCurrency from '../../../../infrastructure/components/FormatCurrency'
import { StockList } from '../../../../infrastructure/components/Stock'
import EanBarcode from '../../../../infrastructure/components/EanBarcode'
import { toQuantityTotal } from '../../../orders/components/shared'

import {
  actionFilterer,
  ActionColumn,
  Column,
  createEmptyArray,
  createVariantPopover,
  ImageColumn,
  ImageHeaderColumn,
  renderColumn,
  ProductTableContainer,
  ProductTablePropertiesHeader,
  Row,
  HeaderRow,
  showColumn,
  sortProducts,
  AttributeColumn,
  AttributeHeaderColumn,
  PriceColumn,
  QuantityColumn,
  ClickLabel,
  createLineKeyFromVariant,
  generateLineIndexFromVariants,
  getQuantityForVariant,
} from './shared'
import { renderCustomField } from '../../../custom-fields/shared'
import QuantityField from './QuantityField'
import FulfilledField from './FulfilledField'
import { DiscountedPrice, getPrice, RrpTip, WhsTip } from '../Price'
import shouldUpdate from './utils/shouldUpdate'

import { renderDate, addPrefixIfNoneExists } from '../../../shared'

export default class TraditionalProductTable extends Component {
  constructor(props) {
    super(props)

    const rows = generateRows(
      props.products,
      props.options.sort,
      props.options.custom_order,
      props.currency,
      props.settings,
      props.options.columns
    )

    this.state = {
      quantities: updateQuantities(Map(), props.quantities),
      rows,
    }
  }

  componentWillReceiveProps(nextProps) {
    let changes = {}

    if (
      this.props.products !== nextProps.products ||
      this.props.options.custom_order !== nextProps.options.custom_order ||
      this.props.options.sort !== nextProps.options.sort ||
      this.props.currency !== nextProps.currency ||
      this.props.settings !== nextProps.settings ||
      this.props.options.columns !== nextProps.options.columns
    ) {
      changes.rows = generateRows(
        nextProps.products,
        nextProps.options.sort,
        nextProps.options.custom_order,
        nextProps.currency,
        nextProps.settings,
        nextProps.options.columns
      )
    }

    if (this.props.quantities !== nextProps.quantities) {
      changes.quantities = updateQuantities(
        this.state.quantities,
        nextProps.quantities
      )
    }

    if (Object.getOwnPropertyNames(changes).length > 0) {
      this.setState(changes)
    }
  }

  render() {
    let products = this.props.products

    const {
      bordered,
      canOverrideColliOnly,
      className,
      contextData,
      compareQuantities,
      currency,
      customerColliOnly,
      customColumns,
      customFields,
      drops,
      displayCompareQuantity,
      editableQuantities,
      editableFulfillment,
      enableColli,
      excludeColumns,
      extraPropsByProduct,
      instance,
      isBrandUser,
      lineIndex,
      onQuantityChange,
      onFulfilledChange,
      onUpdateExtraProps,
      options,
      preferColli,
      postQuantityColumns,
      productActions,
      productActionsV2,
      quantityLabels,
      quantityStyle,
      renderers,
      regulateInventoryActions,
      session,
      settings,
      variantActions,
      toggleDiscountModal,
      toggleProductModal,
      ...rest
    } = this.props

    const { quantities, rows } = this.state

    return (
      <ProductTableContainer bordered={bordered} {...rest}>
        <tbody>
          {rows.map(row => {
            if (row.type === 'header') {
              const maxActions = Math.max(
                productActions.length + productActionsV2.length,
                variantActions.length
              )
              const emptyActionColumns =
                maxActions - productActions.length + row.data.emptyColumns
              const attributes = getAttributes(
                row.data.product,
                settings.horizontal_attribute,
                settings.hide_attributes
              )

              return [
                <TraditionalProductTableProductHeader
                  attributeColumns={attributes}
                  columns={options.columns}
                  contextData={contextData}
                  currency={currency}
                  customColumns={customColumns}
                  emptyActionColumns={emptyActionColumns}
                  excludeColumns={excludeColumns}
                  extraPropsByProduct={extraPropsByProduct}
                  key={`product_header_${row.data.product.id}`}
                  instance={instance}
                  lineIndex={lineIndex}
                  product={row.data.product}
                  productActions={productActions}
                  productActionsV2={productActionsV2}
                  postQuantityColumns={postQuantityColumns}
                  renderers={renderers}
                  session={session}
                  toggleDiscountModal={toggleDiscountModal}
                  toggleProductModal={toggleProductModal}
                  variants={row.data.product.variants}
                />,
                <TraditionalProductTablePropertiesHeader
                  attributeColumns={attributes}
                  columns={options.columns}
                  customFields={customFields}
                  customColumns={customColumns}
                  emptyColumns={row.data.emptyColumns}
                  excludeColumns={excludeColumns}
                  key={`properties_header_${row.data.product.id}`}
                  maxActions={maxActions}
                  renderers={renderers}
                  postQuantityColumns={postQuantityColumns}
                  session={session}
                  settings={settings}
                  variantActions={variantActions}
                />,
              ]
            }
            if (row.type === 'row') {
              const colliOnly =
                !canOverrideColliOnly &&
                (customerColliOnly || row.data.product.order_colli_only)
              const attributes = getAttributes(
                row.data.product,
                settings.horizontal_attribute,
                settings.hide_attributes
              )

              return (
                <TraditionalProductTableVariantRow
                  attributeColumns={attributes}
                  colliOnly={colliOnly}
                  columns={options.columns}
                  contextData={contextData}
                  compareQuantities={compareQuantities}
                  currency={currency}
                  customFields={customFields}
                  customColumns={customColumns}
                  displayCompareQuantity={displayCompareQuantity}
                  editableQuantities={editableQuantities}
                  editableFulfillment={editableFulfillment}
                  emptyColumns={row.data.emptyColumns}
                  enableColli={enableColli}
                  excludeColumns={excludeColumns}
                  extraProps={
                    extraPropsByProduct
                      ? extraPropsByProduct[row.data.product.id]
                      : undefined
                  }
                  key={row.data.key}
                  lineIndex={lineIndex}
                  onQuantityChange={onQuantityChange}
                  onFulfilledChange={onFulfilledChange}
                  onUpdateExtraProps={onUpdateExtraProps}
                  preferColli={preferColli}
                  product={row.data.product}
                  productActions={productActions}
                  productActionsV2={productActionsV2}
                  postQuantityColumns={postQuantityColumns}
                  quantity={quantities.getIn([
                    row.data.product.id,
                    row.data.variant.id,
                  ])}
                  quantityLabels={quantityLabels}
                  quantityStyle={quantityStyle}
                  regulateInventoryActions={regulateInventoryActions}
                  renderers={renderers}
                  session={session}
                  settings={settings}
                  variant={row.data.variant}
                  variantActions={variantActions}
                  toggleProductModal={toggleProductModal}
                  variants={row.data.product.variants}
                />
              )
            }
          })}
        </tbody>
      </ProductTableContainer>
    )
  }
}

class TraditionalProductTableProductHeader extends Component {
  shouldComponentUpdate(nextProps) {
    return shouldUpdate(this.props, nextProps)
  }

  render() {
    const {
      attributeColumns,
      columns,
      contextData,
      currency,
      customColumns,
      emptyActionColumns,
      excludeColumns,
      extraPropsByProduct,
      lineIndex,
      instance,
      product,
      productActions,
      productActionsV2,
      postQuantityColumns,
      regulateInventoryActions,
      renderers,
      session,
      toggleDiscountModal,
      toggleProductModal,
    } = this.props

    const action2HeaderColumns = productActionsV2.map(Action => {
      return (
        <Action.Component
          callbackVars={[{ product }, instance]}
          product={product}
          extraPropsByProduct={extraPropsByProduct}
        />
      )
    })

    const actionHeaderColumns = productActions.map(Action => {
      return (
        <ActionColumn>
          <Action.Component
            callbackVars={[
              {
                product,
                variants: product.variants,
                lines: generateLineIndexFromVariants(
                  lineIndex,
                  product.variants,
                  currency
                ),
              },
              { toggleDiscountModal },
            ]}
            context={contextData}
            session={session}
          />
        </ActionColumn>
      )
    })

    const renderNameColumn =
      showColumn('name', columns, excludeColumns) ||
      showColumn('item_number', columns, excludeColumns) ||
      showColumn('collection', columns, excludeColumns) ||
      showColumn('subbrand', columns, excludeColumns)

    const nonNameColumns =
      difference(difference(columns, excludeColumns), [
        'name',
        'item_number',
        'collection',
        'subbrand',
        'inventory',
      ]).length +
      customColumns.length +
      postQuantityColumns.length
    const nameColumnColSpan = nonNameColumns + attributeColumns.length

    return (
      <HeaderRow>
        {renderNameColumn &&
          renderColumn(
            renderers.name_header,
            // the class is vital to push actions to the right
            ({ product }) => (
              <Column
                colSpan={nameColumnColSpan}
                className="product-table-traditional__properties-header"
              >
                <ClickLabel onClick={() => toggleProductModal(product)}>
                  {product.name}
                </ClickLabel>
              </Column>
            ),
            { product }
          )}
        {createEmptyArray(emptyActionColumns).map(i => (
          <ActionColumn />
        ))}
        {action2HeaderColumns}
        {actionHeaderColumns}
      </HeaderRow>
    )
  }
}

class TraditionalProductTablePropertiesHeader extends Component {
  shouldComponentUpdate(nextProps) {
    return shouldUpdate(this.props, nextProps)
  }

  render() {
    const {
      attributeColumns,
      emptyColumns,
      excludeColumns,
      columns,
      customColumns,
      customFields,
      maxActions,
      postQuantityColumns,
      renderers,
      settings,
    } = this.props

    const attributeHeaderColumns = attributeColumns.map(attribute => {
      return renderColumn(
        renderers.attribute_header,
        data => <AttributeHeaderColumn>{data.attribute}</AttributeHeaderColumn>,
        { attribute }
      )
    })

    return (
      <Row bold>
        {showColumn('image', columns, excludeColumns) &&
          renderColumn(
            renderers.image_header,
            data => (
              <ImageHeaderColumn
                className="image"
                imageSettings={data.settings.table_images}
              >
                Image
              </ImageHeaderColumn>
            ),
            { settings }
          )}
        {attributeHeaderColumns}
        {createEmptyArray(emptyColumns).map(i => (
          <AttributeColumn />
        ))}
        {showColumn('sku', columns, excludeColumns) &&
          renderColumn(
            renderers.sku_header,
            data => <Column className="tracking">SKU</Column>,
            {}
          )}
        {showColumn('ean', columns, excludeColumns) &&
          renderColumn(
            renderers.ean_header,
            data => <Column className="tracking">EAN</Column>,
            {}
          )}
        {showColumn('barcode', columns, excludeColumns) &&
          renderColumn(
            renderers.barcode_header,
            data => <Column className="tracking">Barcode</Column>,
            {}
          )}
        {customFields
          .filter(c =>
            showColumn(`meta.${c.internal_name}`, columns, excludeColumns)
          )
          .map(c => {
            return renderColumn(
              renderers.header,
              data => <Column className="meta">{data.label}</Column>,
              { label: c.label }
            )
          })}
        {showColumn('weight', columns, excludeColumns) &&
          renderColumn(
            renderers.weight_header,
            data => <Column className="quantity">Unit weight</Column>,
            {}
          )}
        {showColumn('fulfilled', columns, excludeColumns) &&
          renderColumn(
            renderers.fulfilled_header,
            data => <Column className="quantity">Fulfilled</Column>,
            {}
          )}
        {showColumn('available_colli', columns, excludeColumns) &&
          renderColumn(
            renderers.available_colli_header,
            data => <QuantityColumn>Available colli</QuantityColumn>,
            {}
          )}
        {showColumn('production_delivery', columns, excludeColumns) &&
          renderColumn(
            renderers.production_delivery_header,
            data => <Column className="production_delivery">Production</Column>,
            {}
          )}
        {customColumns.map(column =>
          renderColumn(
            column.header_renderer,
            data => <Column className={column.key}>{column.label}</Column>,
            {}
          )
        )}
        {showColumn('quantity', columns, excludeColumns) &&
          renderColumn(
            renderers.quantity_header,
            data => <QuantityColumn>Quantity</QuantityColumn>,
            {}
          )}
        {postQuantityColumns.map(column => (
          <PriceColumn>{column.header}</PriceColumn>
        ))}
        {showColumn('total_quantity', columns, excludeColumns) &&
          renderColumn(
            renderers.total_quantity_header,
            data => <QuantityColumn>Total quantity</QuantityColumn>,
            {}
          )}
        {showColumn('rec_sales_price', columns, excludeColumns) &&
          renderColumn(
            renderers.rec_sales_price_header,
            data => (
              <PriceColumn>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="rrp_tip">
                      {settings.rec_sales_price_label_tooltip}
                    </Tooltip>
                  }
                >
                  <span>{settings.rec_sales_price_label}</span>
                </OverlayTrigger>
              </PriceColumn>
            ),
            {}
          )}
        {showColumn('price', columns, excludeColumns) &&
          renderColumn(
            renderers.header,
            data => (
              <PriceColumn>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="whs_tip">
                      {settings.sales_price_label_tooltip}
                    </Tooltip>
                  }
                >
                  <span>{settings.sales_price_label}</span>
                </OverlayTrigger>
              </PriceColumn>
            ),
            {}
          )}
        {showColumn('total_price', columns, excludeColumns) &&
          renderColumn(
            renderers.total_price_header,
            data => <PriceColumn>Total</PriceColumn>,
            {}
          )}
        {createEmptyArray(maxActions).map(i => (
          <ActionColumn />
        ))}
      </Row>
    )
  }
}

TraditionalProductTablePropertiesHeader.PropTypes = {
  attributeColumns: PropTypes.array.isRequired,
  emptyColumns: PropTypes.number,
  columns: PropTypes.array.isRequired,
}

TraditionalProductTablePropertiesHeader.defaultProps = {
  emptyColumns: 0,
}

class TraditionalProductTableVariantRow extends Component {
  shouldComponentUpdate(nextProps) {
    return shouldUpdate(this.props, nextProps)
  }

  render() {
    const {
      attributeColumns,
      colliOnly,
      columns,
      contextData,
      compareQuantities,
      displayCompareQuantity,
      currency,
      customColumns,
      customFields,
      editableQuantities,
      editableFulfillment,
      extraProps,
      emptyColumns,
      excludeColumns,
      enableColli,
      lineIndex,
      onQuantityChange,
      onFulfilledChange,
      onUpdateExtraProps,
      preferColli,
      product,
      productActions,
      productActionsV2,
      postQuantityColumns,
      quantity: quantityMap = Map(),
      renderers,
      session,
      settings,
      variant,
      variantActions,
      variants,
    } = this.props

    const { attributes, prices } = variant

    const hasCurrency = prices.hasOwnProperty(currency)

    const maxActions = Math.max(
      productActions.length + productActionsV2.length,
      variantActions.length
    )
    const emptyActionColumns = maxActions - variantActions.length
    const actionColumns = variantActions.map(Action => {
      return (
        <ActionColumn>
          <Action.Component
            callbackVars={[
              {
                product,
                variant,
                lines: generateLineIndexFromVariants(
                  lineIndex,
                  [variant],
                  currency
                ),
              },
            ]}
            context={contextData}
            session={session}
          />
        </ActionColumn>
      )
    })

    const toggleProductModal = () => this.props.toggleProductModal(product)

    let quantity = getQuantityForVariant(quantityMap, variant.prices, currency)
    let quantityTotal =
      quantity.get('quantity', 0) * (quantityMap.get('colli', 1) || 1)

    let compareQuantity
    if (variant && compareQuantities) {
      const comparisonQuantityForVariant = compareQuantities.getIn([
        product.id,
        variant.id,
      ])

      // For something like reservation change preview we only want to view the changed reservations
      if (comparisonQuantityForVariant) {
        compareQuantity = getQuantityForVariant(
          comparisonQuantityForVariant,
          variant.prices,
          currency
        )
      }
    }

    const picture = variant.picture

    let lineId = null
    if (hasCurrency) {
      const price = prices[currency]

      lineId = lineIndex[createLineKeyFromVariant(variant, currency)]
    }

    let delivery
    const showDeliveryDate = showColumn(
      'production_delivery',
      columns,
      excludeColumns
    )
    if (extraProps && extraProps.production_delivery && lineId) {
      delivery = extraProps.production_delivery[lineId]
    }

    return (
      <Row>
        {showColumn('image', columns, excludeColumns) &&
          renderColumn(
            renderers.image,
            data => (
              <ImageColumn
                image={data.picture}
                settings={data.settings}
                toggleProductModal={toggleProductModal}
              />
            ),
            { picture, settings }
          )}
        {attributeColumns.map(attribute =>
          renderColumn(
            renderers.attributes,
            data => (
              <AttributeColumn>
                <ClickLabel onClick={toggleProductModal}>
                  {data.value}
                </ClickLabel>
              </AttributeColumn>
            ),
            { name: attribute, value: attributes[attribute], variant, product }
          )
        )}
        {createEmptyArray(emptyColumns).map(i => (
          <AttributeColumn />
        ))}
        {showColumn('sku', columns, excludeColumns) &&
          renderColumn(
            renderers.sku,
            data => (
              <Column className="tracking">
                <ClickLabel onClick={toggleProductModal}>{data.sku}</ClickLabel>
              </Column>
            ),
            { sku: variant.sku }
          )}
        {showColumn('ean', columns, excludeColumns) &&
          renderColumn(
            renderers.ean,
            data => (
              <Column className="tracking">
                <ClickLabel onClick={toggleProductModal}>{data.ean}</ClickLabel>
              </Column>
            ),
            { ean: variant.ean }
          )}
        {showColumn('barcode', columns, excludeColumns) &&
          renderColumn(
            renderers.ean,
            data => (
              <Column className="tracking">
                <EanBarcode ean={data.ean} />
              </Column>
            ),
            { ean: variant.ean }
          )}
        {customFields
          .filter(c =>
            showColumn(`meta.${c.internal_name}`, columns, excludeColumns)
          )
          .map(c => {
            return renderColumn(
              renderers.meta,
              data => (
                <Column className="meta">
                  {data.value ? renderCustomField(c, data.value) : null}
                </Column>
              ),
              {
                value: product.meta ? product.meta[c.internal_name] : undefined,
              }
            )
          })}
        {showColumn('weight', columns, excludeColumns) &&
          renderColumn(
            renderers.weight,
            data => {
              return (
                <Column className="quantity">
                  {product.weight
                    ? `${product.weight} ${product.weight_type}`
                    : null}
                </Column>
              )
            },
            { product: product }
          )}
        {showColumn('available_colli', columns, excludeColumns) &&
          renderColumn(
            renderers.available_colli,
            data => {
              return (
                <Column className="quantity">
                  {product.colli ? product.colli.join(', ') : []}
                </Column>
              )
            },
            { product: product }
          )}
        {showColumn('fulfilled', columns, excludeColumns) &&
          renderColumn(
            renderers.fulfilled,
            data => (
              <Column className="quantity">
                <FulfilledField
                  editable={editableFulfillment}
                  value={data.fulfilled}
                  maxValue={data.quantity - data.fulfilled}
                  onChange={newValue => onFulfilledChange(variant, newValue)}
                />
              </Column>
            ),
            { fulfilled: variant.fulfilled || 0, quantity: quantity || 0 }
          )}
        {showDeliveryDate &&
          renderColumn(
            renderers.production_delivery,
            data => (
              <Column className="production_delivery">
                {data.delivery ? (
                  <>
                    {!data.delivery
                      .production_order_delivery_note_received_quantity && (
                      <div>{renderDate(data.delivery.delivery_date)}</div>
                    )}
                    <div>
                      <ProductionOrderDeliveryNoteLink
                        to={`/production/orders/delivery-notes/${data.delivery.production_order_delivery_note_id}`}
                      >
                        {addPrefixIfNoneExists(
                          data.delivery.production_order_delivery_note_number,
                          'PO: '
                        )}
                      </ProductionOrderDeliveryNoteLink>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </Column>
            ),
            { delivery }
          )}
        {customColumns.map(column =>
          renderColumn(
            column.renderer,
            data => <Column className={column.key}>data</Column>,
            {
              extraProps: extraProps ? extraProps[variant.id] : {},
              onUpdateExtraProps: data => {
                onUpdateExtraProps(product.id, variant.id, data)
              },
            }
          )
        )}
        {showColumn('quantity', columns, excludeColumns) &&
          renderColumn(
            renderers.quantity,
            data => (
              <QuantityColumn>
                {defaultQuantityRenderer(data, settings)}
                {showColumn('inventory', settings.columns, excludeColumns) && (
                  <StockList
                    entityId={data.product.brand_id}
                    stock={
                      data.variant.stock
                        ? data.variant.stock.filter(
                            s => s.stock_type === 'stock'
                          )
                        : []
                    }
                  />
                )}
              </QuantityColumn>
            ),
            {
              columns,
              colliOnly,
              compareQuantity,
              displayCompareQuantity,
              currency,
              editableQuantities,
              enableColli,
              extraProps,
              hasCurrency,
              onChange: onQuantityChange,
              onQuantityChange,
              preferColli,
              product,
              quantity,
              quantityObject: quantityMap,
              settings,
              variant,
            }
          )}
        {postQuantityColumns.map(column => {
          const Component = column.component

          return (
            <PriceColumn>
              <Component
                extraProps={extraProps}
                product={product}
                rowVariants={[variant]}
              />
            </PriceColumn>
          )
        })}
        {showColumn('total_quantity', columns, excludeColumns) &&
          renderColumn(
            renderers.total_quantity,
            data => <PriceColumn>{data.total_quantity}</PriceColumn>,
            { total_quantity: quantityTotal }
          )}
        {showColumn('rec_sales_price', columns, excludeColumns) &&
          renderColumn(
            renderers.price,
            ({ currency, price }) => (
              <PriceColumn>
                {price && price.rec_sales_price && (
                  <FormatCurrency currency={currency}>
                    {price.rec_sales_price}
                  </FormatCurrency>
                )}
              </PriceColumn>
            ),
            { price: prices[currency], currency }
          )}
        {showColumn('price', columns, excludeColumns) &&
          renderColumn(
            renderers.price,
            data => {
              if (data.show_whs_after_discount) {
                return (
                  <PriceColumn>
                    {data.price && (
                      <DiscountedPrice
                        price={data.price}
                        currency={data.currency}
                      />
                    )}
                  </PriceColumn>
                )
              }

              let price = data.price ? getPrice(data.price) : 0
              if (data.price && data.price.discount_amount) {
                price += data.price.discount_amount
              }

              return (
                <PriceColumn>
                  <FormatCurrency currency={data.currency}>
                    {price}
                  </FormatCurrency>
                </PriceColumn>
              )
            },
            {
              extraProps,
              price: prices[currency],
              currency,
              show_whs_after_discount: settings.show_whs_after_discount,
            }
          )}
        {showColumn('total_price', columns, excludeColumns) &&
          renderColumn(
            renderers.price,
            ({ currency, price, quantity }) => (
              <PriceColumn>
                {price && (
                  <DiscountedPrice
                    price={price}
                    currency={currency}
                    quantity={quantity}
                  />
                )}
              </PriceColumn>
            ),
            {
              extraProps,
              price: prices[currency],
              currency,
              quantity: quantity ? toQuantityTotal(quantity) : 0,
            }
          )}
        {createEmptyArray(emptyActionColumns).map(i => (
          <ActionColumn />
        ))}
        {actionColumns}
      </Row>
    )
  }
}

TraditionalProductTableVariantRow.propTypes = {
  actions: PropTypes.array,
  attributeColumns: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
  editableQuantities: PropTypes.bool,
  editableFulfillment: PropTypes.bool,
  onQuantityChange: PropTypes.func,
  onFulfilledChange: PropTypes.func,
  quantities: PropTypes.object,
  variant: PropTypes.object.isRequired,
}

TraditionalProductTableVariantRow.defaultProps = {
  actions: [],
  editableQuantities: false,
  editableFulfillment: true,
  onQuantityChange: () => {},
  onFulfilledChange: () => {},
  quantities: {},
}

export const defaultQuantityRenderer = (
  data,
  settings,
  preFieldSiblings = null,
  postFieldSiblings = null
) => {
  return (
    <div>
      {preFieldSiblings}
      <QuantityField
        colliOnly={data.colliOnly}
        colliSizes={data.product.colli || []}
        compareQuantity={data.compareQuantity}
        editable={data.editableQuantities && data.hasCurrency}
        enableColli={data.enableColli}
        preferColli={data.preferColli}
        value={data.quantity}
        matrix={false}
        narrowStyle={data.narrowStyle}
        onChange={newValue => data.onChange(data.variant, newValue)}
        popover={
          settings.show_variant_popover
            ? createVariantPopover(
                data.variant,
                data.currency,
                data.product,
                settings
              )
            : null
        }
      />
      {postFieldSiblings}
    </div>
  )
}

const generateRows = (
  products,
  sort,
  custom_order,
  currency,
  settings,
  columns
) => {
  products = products.toJS()

  const maxAttributes = products.reduce((carry, product) => {
    const attributes = getAttributes(
      product,
      settings.horizontal_attribute,
      settings.hide_attributes
    )

    if (attributes.length > carry) {
      carry = attributes.length
    }
    return carry
  }, 0)

  products = products.map(product => {
    const labelData = {}

    for (let column of columns) {
      switch (column) {
        case 'name':
        case 'item_number':
          labelData[column] = product[column]
          break
        case 'collection':
          let collectionLabel
          if (isPlainObject(product.collection)) {
            collectionLabel = product.collection.title
          } else {
            collectionLabel = product.collection
          }

          if (collectionLabel) {
            labelData.collection = collectionLabel
          }

          break
        case 'subbrand':
          let subbrandLabel
          if (isPlainObject(product.subbrand)) {
            subbrandLabel = product.subbrand.name
          } else {
            subbrandLabel = product.subbrand
          }

          if (subbrandLabel) {
            labelData.subbrand = subbrandLabel
          }

          break
      }
    }

    const name = Mustache.render(settings.product_name_label, labelData)

    return {
      ...product,
      name: name,
    }
  })

  // auto only really exists in horizontal tables
  const sortBy = sort === 'auto' ? 'name' : sort
  const productsGroupedById = groupBy(products, 'id')

  let order
  if (sort === 'custom') {
    order = custom_order
  } else {
    order = sortProducts(productsGroupedById, sortBy)
  }

  return order.reduce((carry, productId) => {
    const product = productsGroupedById[productId][0]
    const emptyColumns =
      maxAttributes -
      getAttributes(
        product,
        settings.horizontal_attribute,
        settings.hide_attributes
      ).length

    carry.push({
      type: 'header',
      data: {
        emptyColumns,
        product,
      },
    })

    return carry.concat(
      product.variants.map(variant => {
        const prices = variant.prices[currency]

        let price = 'no_price'
        if (prices) {
          price = prices.offer_price ? prices.offer_price : prices.sales_price
        }

        return {
          type: 'row',
          data: {
            emptyColumns,
            key: `row_${variant.sku}_${price}`,
            product,
            variant,
          },
        }
      })
    )
  }, [])
}

const getAttributes = (product, horizontalAttribute, hideAttributes) => {
  const attributes = product.attributes.filter(
    a => hideAttributes.indexOf(a) === -1
  )

  attributes.sort((a, b) => {
    if (a === horizontalAttribute) {
      return 1
    }
    if (b === horizontalAttribute) {
      return -1
    }
    return 0
  })

  return attributes
}

/*
We make sure only to update the Maps that should be updated.
This enable us to render only the updated rows.
*/
export const updateQuantities = (stateQuantities, propQuantities) => {
  // The starting point is the prop quantities since additions will not be caught
  // by the state quantities
  return (
    propQuantities
      .reduce((quantities, productQuantities, productId) => {
        const updatedProductQuantities = productQuantities
          .reduce((stateProductQuantities, variantQuantity, variantId) => {
            // Update the variant key if the quantity was updated
            if (stateProductQuantities.get(variantId) !== variantQuantity) {
              return stateProductQuantities.set(variantId, variantQuantity)
            }
            return stateProductQuantities
          }, quantities.get(productId, Map()))
          // Remove variants that were removed
          .filter((variantQuantity, variantId) => {
            return productQuantities.has(variantId)
          })

        // If the product had any updates, then update it
        if (updatedProductQuantities !== quantities.get(productId)) {
          return quantities.set(productId, updatedProductQuantities)
        }

        return quantities
      }, stateQuantities)
      // Remove products that were removed and empty product maps
      .filter(
        (productQuantity, productId) =>
          propQuantities.has(productId) && productQuantity.size > 0
      )
  )
}

// TODO: REMOVE?
/*
export const updateDropQuantities = (stateQuantities, propQuantities) => {
  for(let [dropId, dropQuantities] of Object.entries(propQuantities)) {
    const parsedDropId = parseInt(dropId)

    for(let [productId, productQuantities] of dropQuantities.entries()) {
      for(let [variantId, variantQuantity] of productQuantities.entries()) {
        if (stateQuantities.getIn([productId, variantId, parsedDropId, 'quantity']) !== variantQuantity.get('quantity')) {
          stateQuantities = stateQuantities.setIn([productId, variantId, parsedDropId], variantQuantity)
        }
      }
    }
  }

  return stateQuantities
}
*/

const ProductionOrderDeliveryNoteLink = styled(Link)`
  font-size: 12px;
`
