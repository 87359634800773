/* @flow */

import * as React from 'react'
import { FieldArray } from 'formik'
import styled from 'styled-components'

type Props = {
  addNewLabel: string,
  defaultValue: { [string]: mixed },
  name: string,
}

const ListFormInput = ({
  addNewLabel,
  addBtnSize,
  addBtnStyle = 'white',
  defaultValue,
  deleteByMarking = false,
  disabled = false,
  name,
  renderFields,
  renderSubContainer,
  removeBtnSize,
  removeBtnLabel = 'Remove',
  values,
}: Props) => {
  const addButtonClass = addBtnSize ? `btn-${addBtnSize}` : ''
  const removeButtonClass = removeBtnSize ? `btn-${removeBtnSize}` : ''

  return (
    <FieldArray
      name={name}
      render={fields => {
        return (
          <Container>
            {!disabled && (
              <ButtonContainer>
                <button
                  type="button"
                  className={`btn btn-${addBtnStyle} ${addButtonClass}`}
                  onClick={() =>
                    fields.push(JSON.parse(JSON.stringify(defaultValue)))
                  }
                >
                  <span className="glyphicon glyphicon-plus" /> {addNewLabel}
                </button>
              </ButtonContainer>
            )}

            {values.map((value, i) => {
              if (deleteByMarking && value.delete === true) {
                return null
              }

              const onDelete = index => {
                if (deleteByMarking) {
                  fields.replace(i, {
                    ...value,
                    delete: true,
                  })
                } else {
                  fields.remove(i)
                }
              }

              return (
                <div>
                  <RowContainer>
                    {renderFields(
                      fieldName => `${name}.${i}.${fieldName}`,
                      value,
                      i
                    )}

                    {!disabled && (
                      <RowContainerRemoteButtonContainer>
                        <button
                          type="button"
                          className={`btn btn-white ${removeButtonClass}`}
                          onClick={onDelete}
                        >
                          <span className="glyphicon glyphicon-remove" />
                          {removeBtnLabel && ` ${removeBtnLabel}`}
                        </button>
                      </RowContainerRemoteButtonContainer>
                    )}
                  </RowContainer>
                  {renderSubContainer && renderSubContainer(value)}
                </div>
              )
            })}
          </Container>
        )
      }}
    />
  )
}

export default ListFormInput

const Container = styled.div`
  width: 100%;
`

const ButtonContainer = styled.div`
  margin-bottom: 20px;
`

const RowContainer = styled.div`
  display: flex;
  margin-left: -10px;

  > * {
    flex: 1;
    margin-left: 10px;
  }
`

const RowContainerRemoteButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 0;
`
