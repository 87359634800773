/* @flow */

import * as React from 'react'

const useCustomScript = (scriptUrl, attrs) => {
  React.useEffect(() => {
    if (!scriptUrl) {
      return
    }

    const head = document.head || document.getElementsByTagName('head')[0]
    const script = document.createElement('script')

    script.src = scriptUrl
    script.type = 'module'
    script.crossorigin = 'anonymous'
    script.async = true

    head.appendChild(script)

    return () => {
      head.removeChild(script)
    }
  }, [scriptUrl])
}

export default useCustomScript
