/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import type { RowConfig } from '../../types'

type Options = {
  label?: string,
}

const sub_category: (options: Options) => RowConfig = ({
  label = 'Category',
}) => ({
  key: 'sub_category',
  label,
  render: ({ product }) => {
    return product.categories
      ? product.categories
          .filter(category => category.parent !== null)
          .map(category => category.name)
          .join(', ')
      : ''
  },
  type: 'product',
})

export default sub_category
