/* @flow */

import * as React from 'react'
import qs from 'qs'

export const useSubTabs = (baseUrl, history, location, tab, subTab) => {
  const query = React.useMemo(() => {
    return qs.parse(location.search.slice(1))
  }, [location.search])

  const setTab = React.useCallback(
    (tab, subTab) => {
      if (baseUrl) {
        let url = `/${baseUrl}/${tab}`

        if (subTab) {
          url += `/${subTab}`
        }

        history.push(url)
      }
    },
    [baseUrl, history]
  )

  const onQueryChange = React.useCallback(
    updates => {
      if (baseUrl) {
        let url = `/${baseUrl}/${tab}`

        if (subTab) {
          url += `/${subTab}`
        }

        history.push(
          `${url}?${qs.stringify({
            ...query,
            ...updates,
          })}`
        )
      }
    },
    [baseUrl, tab, history, query, subTab, tab]
  )

  return {
    onQueryChange,
    setTab,
  }
}
