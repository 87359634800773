/* @flow */

import { CALL_API } from '../../../infrastructure/middleware/api'

import type { BrunoRequestOptions, CustomerGroup, Id } from '../../types'

export const CREATE_CUSTOMER_GROUP_REQUEST = 'CREATE_CUSTOMER_GROUP_REQUEST'
export const CREATE_CUSTOMER_GROUP_SUCCESS = 'CREATE_CUSTOMER_GROUP_SUCCESS'
export const CREATE_CUSTOMER_GROUP_FAILURE = 'CREATE_CUSTOMER_GROUP_FAILURE'
export const DELETE_CUSTOMER_GROUP_REQUEST = 'DELETE_CUSTOMER_GROUP_REQUEST'
export const DELETE_CUSTOMER_GROUP_SUCCESS = 'DELETE_CUSTOMER_GROUP_SUCCESS'
export const DELETE_CUSTOMER_GROUP_FAILURE = 'DELETE_CUSTOMER_GROUP_FAILURE'
export const GET_CUSTOMER_GROUPS_REQUEST = 'GET_CUSTOMER_GROUPS_REQUEST'
export const GET_CUSTOMER_GROUPS_SUCCESS = 'GET_CUSTOMER_GROUPS_SUCCESS'
export const GET_CUSTOMER_GROUPS_FAILURE = 'GET_CUSTOMER_GROUPS_FAILURE'
export const UPDATE_CUSTOMER_GROUP_REQUEST = 'UPDATE_CUSTOMER_GROUP_REQUEST'
export const UPDATE_CUSTOMER_GROUP_SUCCESS = 'UPDATE_CUSTOMER_GROUP_SUCCESS'
export const UPDATE_CUSTOMER_GROUP_FAILURE = 'UPDATE_CUSTOMER_GROUP_FAILURE'

export const createCustomerGroup = (values: $Shape<CustomerGroup>) => ({
  [CALL_API]: {
    endpoint: '/customer-groups',
    method: 'POST',
    body: {
      customer_group: values,
    },
    types: [
      CREATE_CUSTOMER_GROUP_REQUEST,
      CREATE_CUSTOMER_GROUP_SUCCESS,
      CREATE_CUSTOMER_GROUP_FAILURE,
    ],
  },
})

export const deleteCustomerGroup = (id: Id) => ({
  [CALL_API]: {
    endpoint: `/customer-groups/${id}`,
    method: 'DELETE',
    types: [
      DELETE_CUSTOMER_GROUP_REQUEST,
      DELETE_CUSTOMER_GROUP_SUCCESS,
      DELETE_CUSTOMER_GROUP_FAILURE,
    ],
  },
})

export const getCustomerGroups = (options?: BrunoRequestOptions) => ({
  [CALL_API]: {
    endpoint: '/customer-groups',
    method: 'GET',
    query: options,
    types: [
      GET_CUSTOMER_GROUPS_REQUEST,
      GET_CUSTOMER_GROUPS_SUCCESS,
      GET_CUSTOMER_GROUPS_FAILURE,
    ],
  },
})

export const updateCustomerGroup = (id: Id, values: $Shape<CustomerGroup>) => ({
  [CALL_API]: {
    endpoint: `/customer-groups/${id}`,
    method: 'PUT',
    body: {
      customer_group: values,
    },
    types: [
      UPDATE_CUSTOMER_GROUP_REQUEST,
      UPDATE_CUSTOMER_GROUP_SUCCESS,
      UPDATE_CUSTOMER_GROUP_FAILURE,
    ],
  },
})
