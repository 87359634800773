/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import EditableColumn, {
  createEditableInput,
  createEditableInputRender,
  DefaultLabel,
} from '../Column'
import { hideColumn } from '../actions'
import CurrencySelector from '../../CurrencySelector'
import Select from 'react-select'

type InputComponentProps = {
  dispatch: Function,
  onChange: Function,
  tableKey: string,
}

class InputComponent extends Component<void, InputComponentProps, void> {
  props: InputComponentProps

  constructor(props: InputComponentProps) {
    super(props)

    ;(this: any).onChange = this.onChange.bind(this)
  }

  onChange(newValue) {
    this.props.onChange(newValue)
    this.props.dispatch(hideColumn(this.props.tableKey))
  }

  render() {
    const { onChange, setRef, ...props } = this.props

    return (
      <CurrencySelector
        autofocus
        autoBlur
        onChange={this.onChange}
        openOnFocus
        {...props}
        setRef={setRef}
      />
    )
  }
}

const ConnectedInputComponent = connect()(InputComponent)

type Props = {
  currency: string,
  error?: string,
  input: any,
  label: any,
  tableKey: string,
}

class EditableCurrencyColumn extends Component<void, Props, void> {
  props: Props

  Input: Function

  constructor(props: Props) {
    super(props)

    this.Input = inputProps => (
      <ConnectedInputComponent tableKey={props.tableKey} {...inputProps} />
    )
  }

  render() {
    const { currency, error, label, input, ...props } = this.props

    return (
      <EditableColumn
        error={error}
        LabelComponent={DefaultLabel}
        InputComponent={this.Input}
        {...props}
      />
    )
  }
}

const currencyRenderer = createEditableInputRender(EditableCurrencyColumn)
export const EditableCurrencyColumnInput = createEditableInput(currencyRenderer)

export default EditableCurrencyColumnInput
