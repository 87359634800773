/* @flow */

import React, { Component, type Element } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter, type RouterHistory } from 'react-router-dom'

import type { PayingCustomer } from './types'
import type { Id } from '../types'

import { getPayingCustomers, switchToPayingCustomer } from './actions'

type Props = {
  dispatch: Function,
  history: RouterHistory,
  render: (
    payingCustomers: Array<PayingCustomer>,
    onSwitch: (entityId: Id) => void
  ) => Element<'div'>,
}

type State = {
  payingCustomers: Array<PayingCustomer>,
}

class PayingCustomerSwitcher extends Component<Props, State> {
  state = {
    payingCustomers: [],
  }

  componentDidMount() {
    this.props
      .dispatch(
        getPayingCustomers({
          filter_groups: [
            {
              not: false,
              filters: [
                {
                  key: 'freemium',
                  value: false,
                  operator: 'eq',
                },
              ],
            },
          ],
        })
      )
      .then(response => {
        if (!response.error) {
          this.setState({
            payingCustomers: response.payload.paying_customers,
          })
        }
      })
  }

  render() {
    const { render: RenderComponent, ...rest } = this.props
    const { payingCustomers } = this.state

    return (
      <RenderComponent
        payingCustomers={payingCustomers}
        switchEntity={this._switchEntity}
        {...rest}
      />
    )
  }

  _switchEntity = entity => {
    this.props.dispatch(switchToPayingCustomer(this.props.history, entity))
  }
}

export default withRouter(connect()(PayingCustomerSwitcher))
