import { CALL_API } from '../../../infrastructure/middleware/api'

export const OPEN_NAVIGATION_SEARCH = 'OPEN_NAVIGATION_SEARCH'
export const SEARCH_REQUEST = 'SEARCH_REQUEST'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'SEARCH_FAILURE'

export const requestSearch = query => {
  return {
    [CALL_API]: {
      endpoint: '/search',
      method: 'POST',
      body: {
        query,
      },
      types: [SEARCH_REQUEST, SEARCH_SUCCESS, SEARCH_FAILURE],
    },
  }
}

export const openNavigationSearch = () => ({
  type: OPEN_NAVIGATION_SEARCH,
})
