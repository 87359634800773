/* @flow */

import React, { PureComponent } from 'react'
import { Modal } from 'react-bootstrap'
import sortBy from 'lodash/sortBy'
import { IsBrandPredicate } from './shared'

import {
  CheckmarkDropdown,
  CheckmarkDropdownButton,
  CheckmarkDropdownGroup,
  CheckmarkDropdownSeparator,
} from '../../../../../infrastructure/components/Dropdown'
import { createAction } from '../shared'
import { ProductTableOptionActionCallback } from './shared'
import type { CustomField } from '../../../../types'

export const aggregateValuesToLabel = {
  price: 'Price',
  collection: 'Collection',
  item_number: 'Item number',
  product_name: 'Product name',
  sub_category: 'Sub category',
  top_category: 'Top category',
  total_weight: 'Total weight',
}

const aggregateOptions = Object.keys(aggregateValuesToLabel).map(k => ({
  label: aggregateValuesToLabel[k],
  value: k,
}))

type Props = {
  callback: (key: string, value: string) => void,
  customFields: Array<CustomField>,
  tableOptions: {
    aggregate_method: 'percentage' | 'quantity',
    aggregates: Array<string>,
  },
}

type State = {
  showHelpMenu: boolean,
}

class AggregateMenu extends PureComponent<Props, State> {
  state = {
    showHelpMenu: false,
  }

  toggleAggregate = (aggregates: Array<string>) => {
    this.props.callback('aggregates', aggregates)
  }

  render() {
    const {
      customFields,
      tableOptions: { aggregate_method, aggregates },
    } = this.props
    const { showHelpMenu } = this.state

    const combinedOptions = aggregateOptions.concat(
      customFields.map(c => ({
        label: c.label,
        value: `meta.${c.internal_name}`,
      }))
    )

    return (
      <div>
        <Modal show={showHelpMenu} onHide={() => this._toggleHelpMenu()}>
          <Modal.Body>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/bqQ5L_TsM7k"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-white"
              onClick={() => this._toggleHelpMenu()}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <CheckmarkDropdown
          onChange={this.toggleAggregate}
          id="product_table_aggregate"
          selected={aggregates}
          title="Group by"
        >
          <CheckmarkDropdownGroup>Show</CheckmarkDropdownGroup>
          <CheckmarkDropdownButton
            controlled={true}
            multi={false}
            key="percentage"
            toggle={this._changeAggregateMethod}
            selected={aggregate_method}
            value="percentage"
            label="Percentage"
          />
          <CheckmarkDropdownButton
            controlled={true}
            multi={false}
            key="quantity"
            toggle={this._changeAggregateMethod}
            selected={aggregate_method}
            value="quantity"
            label="Total Quantity"
          />
          <CheckmarkDropdownGroup>Group products by</CheckmarkDropdownGroup>
          {sortBy(combinedOptions, 'label').map((option, i) => (
            <CheckmarkDropdownButton
              key={option.value}
              value={option.value}
              label={option.label}
            />
          ))}
          <CheckmarkDropdownSeparator />
          <CheckmarkDropdownButton
            controlled={true}
            multi={false}
            key="help"
            value="Help"
            label="Help"
            toggle={() => this._toggleHelpMenu()}
          />
        </CheckmarkDropdown>
      </div>
    )
  }

  _changeAggregateMethod = (aggregateMethod: 'percentage' | 'quantity') => {
    this.props.callback('aggregate_method', aggregateMethod)
  }

  _toggleHelpMenu = () => {
    this.setState({
      showHelpMenu: !this.state.showHelpMenu,
    })
  }
}

export const createAggregateAction = (callback = () => {}) => {
  return createAction(
    (key, value, instance) => {
      ProductTableOptionActionCallback({ [key]: value }, instance)
      callback(key, value)
    },
    AggregateMenu,
    [IsBrandPredicate]
  )
}

export default createAggregateAction
