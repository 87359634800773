/* @flow */

import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import memoize from 'memoize-one'
import isFunction from 'lodash/isFunction'

import { type Match } from 'react-router-dom'
import { SessionContext } from '../shared'
import { useCustomCss } from './styles'

import { useRefValue } from '../shared'
import type { ApiResponse, Id } from '../types'

import { useReportSettings } from './reports'
import { useSubTabs } from './tabs'
import { useTitle } from './title'

export { useCustomCss, useReportSettings, useSubTabs, useTitle }

export const useConnectedBrand = (entityId: Id) => {
  const { brand, brands, entity } = useContext(SessionContext)

  // user is not logged in
  if (!entity) {
    return [null]
  }

  if (entity.entity_type === 'brand') {
    return [brand]
  }

  if (entityId) {
    return [brands[entityId]]
  }

  return [null]
}

export const useConnectedBrandByMatch = (match?: Match) => {
  const { brand, brands, entity } = useContext(SessionContext)

  if (entity.entity_type === 'brand') {
    return [brand]
  }

  if (match && match.params.brandId) {
    return [brands[match.params.brandId]]
  }

  return [null]
}

type CreateApiHookHookControls<M> = {
  isError: boolean,
  isFetching: boolean,
  isInitialized: boolean,
  refresh: () => void,
  override: (input: $Shape<M>) => void,
}
type CreateApiHookHookResult<M> = [M, boolean, CreateApiHookHookControls<M>]
type CreateApiHookFetchFunction<M> = () => Promise<ApiResponse<{ entity: M }>>
type CreateApiHookHook<M> = (
  requestArgs: any,
  options?: any
) => CreateApiHookHookResult<M>

const ensureArgsArray = memoize(args => [args])

export function createApiHook<M>(
  fetch: CreateApiHookFetchFunction<M>,
  initialValue: any = null,
  hookOptions
): CreateApiHookHook<M> {
  let v2 = false
  if (hookOptions) {
    v2 = hookOptions.v2 || false
  }

  return (requestArgs, options = {}) => {
    const [state, setState] = useState<{
      entity: M,
      isFetching: boolean,
      isError: boolean,
      isInitialized: boolean,
    }>({
      entity: initialValue,
      isFetching: false,
      isError: false,
      isInitialized: false,
    })

    let useArgs = requestArgs || []
    if (!Array.isArray(useArgs)) {
      useArgs = ensureArgsArray(useArgs)
    }

    const ignore = options.ignore || false
    const cancelPreviousRequestRef = React.useRef(null)

    const dataCache = options.dataCache
    let effectArgs = [...useArgs, ignore, cancelPreviousRequestRef]
    if (v2) {
      // avoid having to memoize arguments
      effectArgs = [JSON.stringify(useArgs), ignore, cancelPreviousRequestRef]
    }

    if (dataCache) {
      effectArgs.push(dataCache.cache)
    }

    const doRequest = React.useCallback(requestArgs => {
      setState(s => ({
        ...s,
        isFetching: true,
      }))

      if (cancelPreviousRequestRef.current !== null) {
        cancelPreviousRequestRef.current.call()
      }

      let useRequestArgs = requestArgs
      if (v2) {
        const cancelTokenSource = axios.CancelToken.source()
        cancelPreviousRequestRef.current = () => cancelTokenSource.cancel()

        const axiosOptions = {
          cancelToken: cancelTokenSource.token,
          retry: 3,
        }
        useRequestArgs = v2 ? [axiosOptions, ...requestArgs] : requestArgs
      }

      let request
      if (dataCache) {
        const requestArgsStringified = JSON.stringify(requestArgs)

        if (
          dataCache.request === null ||
          // if the input args changes we want to refire the request
          // an example would be in AccountingSettings we show cached ERPAccountSelectors,
          // but if the input internal company changes then we need to re-fetch
          dataCache.lastRequestArgs != requestArgsStringified
        ) {
          request = fetch(...useRequestArgs)
          dataCache.request = request
          dataCache.lastRequestArgs = requestArgsStringified
        } else {
          request = dataCache.request
        }
      } else {
        request = fetch(...useRequestArgs)
      }

      if (request) {
        return request.then(response => {
          if (response.isCancel) {
            // Request cancelled, do nothing
            return
          }

          cancelPreviousRequestRef.current = null

          if (dataCache) {
            dataCache.cache = response
          }

          if (!response.error) {
            setState(s => ({
              ...s,
              isFetching: false,
              isError: false,
              isInitialized: true,
              ...response,
            }))
          } else {
            setState(s => ({
              ...s,
              isFetching: false,
              isError: true,
              isInitialized: true,
            }))
          }

          return response
        })
      }
    }, effectArgs)

    const doRequetRefValue = useRefValue(doRequest)
    const useArgsRefValue = useRefValue(useArgs)

    useEffect(() => {
      if (ignore) {
        return
      }

      doRequest(useArgs)
    }, [doRequest])

    const refresh = React.useCallback(
      args => {
        if (doRequetRefValue.current) {
          return doRequetRefValue.current(args || useArgsRefValue.current)
        }
      },
      [doRequetRefValue, useArgsRefValue]
    )

    const override = React.useCallback(
      (input: $Shape<M>) => {
        if (isFunction(input)) {
          setState(input)
        } else {
          setState(s => ({
            ...s,
            entity: Array.isArray(input) ? input : { ...s.entity, ...input },
          }))
        }
      },
      [setState]
    )

    // there is a bug in webpack-dev-server where sometimes the full json response is not returned
    // which makes axios unable to parse the text
    let returnValue = state.entity
    if (returnValue == undefined && initialValue !== undefined) {
      returnValue = null
    }

    let primaryValueReturn = v2 ? state.isInitialized : state.isFetching

    return [
      returnValue,
      primaryValueReturn,
      {
        isError: state.isError,
        isFetching: state.isFetching,
        isInitialized: state.isInitialized,
        refresh,
        override,
      },
    ]
  }
}

let createdDataCaches = []

export const clearAllDataCaches = () => {
  for (let cache of createdDataCaches) {
    if (cache.clearCache) {
      cache.clearCache()
    }
  }
}

export const createDataCache = dataHook => {
  const dataCache = {
    cache: null,
    clearCache: () => {
      dataCache.cache = null
      dataCache.request = null
      dataCache.lastRequestArgs = null
    },
    request: null,
    lastRequestArgs: null,
  }

  createdDataCaches.push(dataCache)

  return {
    cache: dataCache,
    hook: (args, options = {}) => {
      const [entity, fetchData, dataUtilities] = dataHook(args, {
        dataCache,
        ...options,
      })

      return [entity, fetchData, dataUtilities]
    },
  }
}

export function quickCreateHook<M>(request, key, defaultValue, options) {
  const fetchData = (...args) => {
    return request(...args).then(response => {
      if (!response.error) {
        return {
          entity: key ? response.payload[key] : response.payload,
        }
      } else {
        return response
      }
    })
  }

  const hook = createApiHook<M>(fetchData, defaultValue, options)
  const cache = createDataCache(hook)

  return {
    hook,
    hookCached: cache.hook,
    clearCache: cache.cache.clearCache,
  }
}

// The below is a generic data cache - the above data cache is specifically for API hooks

export const useDataCache = dataSources => {
  const [dataCache, setDataCache] = React.useState({})

  const primeCache = React.useCallback(
    (key, defaultValue = null) => {
      if (dataCache[key]) {
        return dataCache[key].promise
      }

      const dataSourceSettings = dataSources[key]

      const promise = dataSourceSettings.request().then(response => {
        let values = defaultValue

        if (!response.error) {
          values = response.payload
          if (dataSourceSettings.map) {
            values = dataSourceSettings.map(values)
          }

          setDataCache(s => ({
            ...s,
            [key]: {
              ...s[key],
              isFetching: false,
              isInitialized: true,
              values: values,
            },
          }))
        }

        return values
      })

      setDataCache(s => ({
        ...s,
        [key]: {
          promise,
          isFetching: true,
          isInitialized: false,
          values: null,
        },
      }))

      return promise
    },
    [dataSources, dataCache]
  )

  const dataCacheActions = React.useMemo(() => {
    return {
      primeCache,
    }
  }, [primeCache])

  return [dataCache, dataCacheActions]
}

export const useDataCacheValues = (
  dataCache,
  dataCacheActions,
  key,
  defaultValue = null
) => {
  React.useEffect(() => {
    if (key) {
      dataCacheActions.primeCache(key)
    }
  }, [dataCacheActions, key])

  if (!key) {
    return [null, false]
  }

  let values = defaultValue
  let isFetching = false

  let dataCacheValues = dataCache[key]

  if (dataCacheValues) {
    isFetching = dataCacheValues.isFetching

    if (dataCacheValues.isInitialized) {
      values = dataCacheValues.values
    }
  }

  return [values, isFetching]
}
