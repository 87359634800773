/* @flow */

import api from '../api'

import type { Address, ApiResponse, Entity, Id, User } from '../types'

export const getAddresses = () => api.get(`/addresses`)

export const createAddress = (address: $Shape<Address>) =>
  api.post(`/addresses`, {
    body: {
      address,
    },
  })

export const deleteAddress = (addressId: Id) =>
  api.delete(`/addresses/${addressId}`)

export const updateAddress = (addressId: Id, address: $Shape<Address>) =>
  api.put(`/addresses/${addressId}`, {
    body: {
      address,
    },
  })

// emails

export const getEmails = (entityId: Id) =>
  api.get(`/entities/${entityId}/emails`)

// entity users

export const getEntityUsers = options =>
  api.get(`/entity-users`, {
    query: options,
  })

export const getMyEntityUser = options =>
  api.get(`/entity-users/my`, {
    query: options,
  })

// users

export const getUsers = (
  entityId: Id,
  options: Object
): Promise<ApiResponse<{ users: Array<User> }>> =>
  api.get(`/entities/${entityId}/users`, {
    query: options,
  })

export const updateUser = (
  userId,
  data
): Promise<ApiResponse<{ users: Array<User> }>> =>
  api.put(`/users/${userId}`, {
    body: {
      user: data,
    },
  })

export const updateUserTip = (tip, status) =>
  api.post('/users/update-tip', {
    body: {
      tip,
      status,
    },
  })

// sub users

export const getMySubUsers = () => api.get(`/users/my-sub-users`)

export const setMySubUserDevice = (subUserId, deviceId) =>
  api.post(`/users/set-my-sub-user-device`, {
    body: {
      sub_user_id: subUserId,
      device_id: deviceId,
    },
  })

// Mode of transportation

export const getModeOfTransportations = (axiosOptions = {}, options = {}) =>
  api.get(`/mode-of-transportations`, {
    ...axiosOptions,
    query: options,
  })

export const createModeOfTransportation = (
  axiosOptions,
  modeOfTransportation
) =>
  api.post('/mode-of-transportations', {
    ...axiosOptions,
    body: {
      mode_of_transportation: modeOfTransportation,
    },
  })

export const updateModeOfTransportation = (
  axiosOptions,
  id,
  modeOfTransportation
) =>
  api.put(`/mode-of-transportations/${id}`, {
    ...axiosOptions,
    body: {
      mode_of_transportation: modeOfTransportation,
    },
  })

export const deleteModeOfTransportation = (axiosOptions, id) =>
  api.delete(`/mode-of-transportations/${id}`, {
    ...axiosOptions,
  })

// Forwarder

export const getForwarders = (axiosOptions = {}, options = {}) =>
  api.get(`/forwarders`, {
    ...axiosOptions,
    query: options,
  })

export const createForwarder = (axiosOptions, forwarder) =>
  api.post('/forwarders', {
    ...axiosOptions,
    body: {
      forwarder: forwarder,
    },
  })

export const updateForwarder = (axiosOptions, id, forwarder) =>
  api.put(`/forwarders/${id}`, {
    ...axiosOptions,
    body: {
      forwarder: forwarder,
    },
  })

export const deleteForwarder = (axiosOptions, id) =>
  api.delete(`/forwarders/${id}`, {
    ...axiosOptions,
  })

// Custom fonts

export const getCustomFonts = (axiosOptions = {}) =>
  api.get('/custom-fonts', {
    ...axiosOptions,
  })

export const createCustomFont = (axiosOptions = {}, data) =>
  api.post('/custom-fonts', {
    body: {
      font: data,
    },
    ...axiosOptions,
  })

export const updateCustomFont = (axiosOptions = {}, id, data) =>
  api.put(`/custom-fonts/${id}`, {
    body: {
      font: data,
    },
    ...axiosOptions,
  })

export const deleteCustomFont = (axiosOptions = {}, id) =>
  api.delete(`/custom-fonts/${id}`, {
    ...axiosOptions,
  })

// Product accounting group

export const getProductAccountingGroups = (axiosOptions = {}, options = {}) =>
  api.get(`/product-accounting-groups`, {
    ...axiosOptions,
    query: options,
  })

export const createProductAccountingGroup = (
  axiosOptions,
  productAccountingGroup
) =>
  api.post('/product-accounting-groups', {
    ...axiosOptions,
    body: {
      product_accounting_group: productAccountingGroup,
    },
  })

export const updateProductAccountingGroup = (
  axiosOptions,
  id,
  productAccountingGroup
) =>
  api.put(`/product-accounting-groups/${id}`, {
    ...axiosOptions,
    body: {
      product_accounting_group: productAccountingGroup,
    },
  })

export const deleteProductAccountingGroup = (axiosOptions, id) =>
  api.delete(`/product-accounting-groups/${id}`, {
    ...axiosOptions,
  })
