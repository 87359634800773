/* @flow */

import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import sortBy from 'lodash/sortBy'

import { useCachedSubbrands } from './hooks'

const SubbrandSelector = ({ excludeOptions, ...props }) => {
  const [subbrands, { isFetching }] = useCachedSubbrands()

  const options = useMemo(() => {
    let options = sortBy(subbrands, 'name')

    if (excludeOptions) {
      options = options.filter(
        subbrand => !excludeOptions.includes(subbrand.id)
      )
    }

    return options
  }, [subbrands, excludeOptions])

  return (
    <Select
      isLoading={isFetching}
      labelKey="name"
      placeholder="Select subbrand..."
      options={options}
      valueKey="id"
      {...props}
    />
  )
}

export default SubbrandSelector

export const DatalessSubbrandSelector = ({
  subbrands,
  isFetching,
  ...props
}) => {
  const options = useMemo(() => {
    const options = subbrands.map(subbrand => ({
      ...subbrand,
      label: subbrand.name,
    }))

    return sortBy(options, subbrand => subbrand.name.toLowerCase())
  }, [subbrands])

  return (
    <Select
      isLoading={isFetching}
      labelKey="label"
      placeholder="Select subbrand..."
      options={options}
      valueKey="id"
      {...props}
    />
  )
}
