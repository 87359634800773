/* @flow */

import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Select from 'react-select'
import keyBy from 'lodash/keyBy'
import uuid from 'uuid'
import chunk from 'lodash/chunk'

import CurrencySelector from '../../../infrastructure/components/CurrencySelector'
import CountrySelector from '../../../infrastructure/components/CountrySelector'
import CustomerSelector from '../../customers/components/CustomerSelector'
import OrderChannelSelector from '../../order-channels/components/OrderChannelSelector'
import UserSelector from '../../users/components/UserSelector'
import {
  ControlLabel,
  FormControl,
  SelectWrapper,
} from '../../../infrastructure/components/Forms'
import { regionOptions, continentOptions } from '../../shipments/shared'

import FieldTypeSelector from '../../custom-fields/components/FieldTypeSelector'
import { useCachedCustomFields } from '../../custom-fields/hooks'
import { useErpCompanies, useShopifyShops } from '../../app-store/hooks'
import { useRefValue } from '../../shared'

import type { RuleBasedSetting } from '../../types'

type Props = {
  addMatcherFieldsInEnd?: boolean,
  continent?: boolean,
  country?: boolean,
  countryMulti?: boolean,
  currency?: boolean,
  defaultRuleValues?: Object,
  matcherFields: Array<Object>,
  onChange: (rules: Array<RuleBasedSetting>) => void,
  orderChannels?: boolean,
  orderCustomFields?: boolean,
  orderType?: boolean,
  orderTypeSeparateMove?: boolean,
  orderTypeSeparatePos?: boolean,
  newRuleLabel?: string,
  shopifyShops?: boolean,
  region?: boolean,
  regionMulti?: boolean,
  rules: Array<RuleBasedSetting>,
  valueRender: Function,
}

const defaultInputRuleValues = { matchers: [], value: null }

const RuleBasedSettingsForm = ({
  addMatcherFieldsInEnd = false,
  advancedMatcherFields = [],
  continent = false,
  country = true,
  countryMulti = false,
  currency = true,
  customer = false,
  defaultRuleValues = defaultInputRuleValues,
  dropshippingOrder = false,
  erpCompany: fetchErpCompanies = false,
  headerRender,
  matcherFields = [],
  onChange,
  orderChannel = false,
  orderCustomFields: fetchOrderCustomFields = false,
  orderType = false,
  orderTypeSeparateConsignment = true,
  orderTypeSeparateMove = false,
  orderTypeSeparatePos = false,
  newRuleLabel = 'Add new rule',
  region = false,
  regionMulti = false,
  rules,
  renderData,
  shopifyShops: fetchShopifyShops = false,
  users = false,
  valueRender,
}) => {
  const [showAdvancedFields, setShowAdvancedFields] = React.useState(false)

  // Fetch custom fields

  const customFieldsFetchArgs = React.useMemo(() => {
    return []
  }, [])
  const customFieldsFetchOptions = React.useMemo(() => {
    return {
      ignore: !fetchOrderCustomFields,
    }
  }, [fetchOrderCustomFields])
  const [customFields] = useCachedCustomFields(
    customFieldsFetchArgs,
    customFieldsFetchOptions
  )

  const orderCustomFields = React.useMemo(() => {
    return customFields
      .filter(c => c.group_type === 'order')
      .map(c => ({
        definition: c,
        key: `order_meta.${c.internal_name}`,
        label: c.label,
      }))
  }, [customFields])

  // Fetch shopify shops

  const shopifyShopsFetchArgs = React.useMemo(() => {
    return []
  }, [])
  const shopifyShopsFetchOptions = React.useMemo(() => {
    return {
      ignore: !fetchShopifyShops,
    }
  }, [fetchShopifyShops])
  const [shopifyShops] = useShopifyShops(
    shopifyShopsFetchArgs,
    shopifyShopsFetchOptions
  )

  // Fetch ERP companies

  const erpCompaniesFetchArgs = React.useMemo(() => {
    return []
  }, [])
  const erpCompaniesFetchOptions = React.useMemo(() => {
    return {
      ignore: !fetchErpCompanies,
    }
  }, [fetchErpCompanies])
  const [erpCompanies] = useErpCompanies(
    erpCompaniesFetchArgs,
    erpCompaniesFetchOptions
  )

  const id = React.useMemo(() => {
    return uuid()
  }, [])

  const _addRule = React.useCallback(() => {
    onChange([...rules, { ...defaultRuleValues }])
  }, [onChange, rules, defaultRuleValues])

  const _removeRule = React.useCallback(
    (ruleIndex: number) => {
      const rulesCopy = [...rules]

      rulesCopy.splice(ruleIndex, 1)

      onChange(rulesCopy)
    },
    [onChange, rules]
  )

  const _onMatcherChange = React.useCallback(
    (index: number, key: string, value: mixed) => {
      const rulesCopy = [...rules]

      if (!rulesCopy[index]) {
        return
      }

      const rule = { ...rulesCopy[index] }

      const matchers = [...rule.matchers]
      const matcherIndex = matchers.findIndex(matcher => matcher.key === key)

      if (matcherIndex === -1) {
        matchers.push({ key: key, value: value })
      } else {
        matchers[matcherIndex] = { ...matchers[matcherIndex], value: value }
      }

      rule.matchers = matchers

      rulesCopy[index] = rule

      onChange(rulesCopy)
    },
    [onChange, rules]
  )

  const _onValueChange = React.useCallback(
    (index: number, value: mixed) => {
      const updatedRules = [...rules]

      if (updatedRules[index]) {
        updatedRules[index] = { ...updatedRules[index], value: value }

        onChange(updatedRules)
      }
    },
    [onChange, rules]
  )

  const allAdvancedMatcherFields = React.useMemo(() => {
    const allAdvancedMatcherFields = [...advancedMatcherFields]

    if (fetchOrderCustomFields) {
      for (let orderCustomField of orderCustomFields) {
        const customFieldDefinition = orderCustomField.definition

        allAdvancedMatcherFields.push({
          label: orderCustomField.label,
          key: orderCustomField.key,
          component: ({ index, onChange, value }) => {
            return (
              <FieldTypeSelector
                name={customFieldDefinition.internal_name}
                type={customFieldDefinition.field_type}
                value={value}
                options={customFieldDefinition.options}
                onChange={newValue => {
                  onChange(newValue[customFieldDefinition.internal_name])
                }}
                description={customFieldDefinition.description}
              />
            )
          },
        })
      }
    }

    return allAdvancedMatcherFields
  }, [advancedMatcherFields, fetchOrderCustomFields, orderCustomFields])

  const showAdvancedFieldsToggle = allAdvancedMatcherFields.length > 0

  // toggle advanced fields if any of them have a value
  React.useEffect(() => {
    if (allAdvancedMatcherFields.length == 0) {
      return
    }

    if (showAdvancedFields) {
      return
    }

    const keys = []
    for (let advancedField of allAdvancedMatcherFields) {
      keys.push(advancedField.key)
    }

    for (let rule of rules) {
      for (let matcher of rule.matchers) {
        if (matcher.value && keys.includes(matcher.key)) {
          setShowAdvancedFields(true)
        }
      }
    }
  }, [
    allAdvancedMatcherFields,
    rules,
    setShowAdvancedFields,
    showAdvancedFields,
  ])

  return (
    <div>
      <RulesContainer>
        {rules.map((rule, i) => {
          const matchersByKey = keyBy(rule.matchers, 'key')

          const renderMatcherFields = matcherFields.map(
            ({ key, label, component: MatchComponent }, matcherIndex) => {
              return (
                <div key={matcherIndex}>
                  <ControlLabel>{label}</ControlLabel>
                  <MatchComponent
                    index={i}
                    onChange={newValue => _onMatcherChange(i, key, newValue)}
                    value={matchersByKey[key] ? matchersByKey[key].value : null}
                  />
                </div>
              )
            }
          )

          return (
            <Rule key={i}>
              <MatcherRow>
                <MatcherInputs>
                  {addMatcherFieldsInEnd === false && renderMatcherFields}
                  {orderType && (
                    <div>
                      <ControlLabel>Order type</ControlLabel>
                      <div className="radio radio-success">
                        <input
                          checked={
                            matchersByKey.order_type
                              ? matchersByKey.order_type.value === 'b2b'
                              : false
                          }
                          id={`rule-matcher-${id}-${i}-order-type-b2b`}
                          onChange={() =>
                            _onMatcherChange(i, 'order_type', 'b2b')
                          }
                          type="radio"
                          value="b2b"
                        />
                        <label
                          htmlFor={`rule-matcher-${id}-${i}-order-type-b2b`}
                        >
                          B2B
                        </label>
                      </div>
                      <div className="radio radio-success">
                        <input
                          checked={
                            matchersByKey.order_type
                              ? matchersByKey.order_type.value === 'b2c'
                              : false
                          }
                          id={`rule-matcher-${id}-${i}-order-type-b2c`}
                          onChange={() =>
                            _onMatcherChange(i, 'order_type', 'b2c')
                          }
                          type="radio"
                          value="b2c"
                        />
                        <label
                          htmlFor={`rule-matcher-${id}-${i}-order-type-b2c`}
                        >
                          B2C
                        </label>
                      </div>

                      {orderTypeSeparateConsignment && (
                        <div className="radio radio-success">
                          <input
                            checked={
                              matchersByKey.order_type
                                ? matchersByKey.order_type.value ===
                                  'consignment'
                                : false
                            }
                            id={`rule-matcher-${id}-${i}-order-type-consignment`}
                            onChange={() =>
                              _onMatcherChange(i, 'order_type', 'consignment')
                            }
                            type="radio"
                            value="consignment"
                          />
                          <label
                            htmlFor={`rule-matcher-${id}-${i}-order-type-consignment`}
                          >
                            Consignment
                          </label>
                        </div>
                      )}

                      {orderTypeSeparateMove && (
                        <div className="radio radio-success">
                          <input
                            checked={
                              matchersByKey.order_type
                                ? matchersByKey.order_type.value === 'move'
                                : false
                            }
                            id={`rule-matcher-${id}-${i}-order-type-move`}
                            onChange={() =>
                              _onMatcherChange(i, 'order_type', 'move')
                            }
                            type="radio"
                            value="move"
                          />
                          <label
                            htmlFor={`rule-matcher-${id}-${i}-order-type-move`}
                          >
                            Move
                          </label>
                        </div>
                      )}

                      {orderTypeSeparatePos && (
                        <div className="radio radio-success">
                          <input
                            checked={
                              matchersByKey.order_type
                                ? matchersByKey.order_type.value === 'pos'
                                : false
                            }
                            id={`rule-matcher-${id}-${i}-order-type-pos`}
                            onChange={() =>
                              _onMatcherChange(i, 'order_type', 'pos')
                            }
                            type="radio"
                            value="pos"
                          />
                          <label
                            htmlFor={`rule-matcher-${id}-${i}-order-type-pos`}
                          >
                            POS
                          </label>
                        </div>
                      )}
                    </div>
                  )}
                  {region && (
                    <div>
                      <ControlLabel>Region</ControlLabel>
                      <SelectWrapper
                        placeholder="Any region"
                        multi={regionMulti}
                        multiParseInt={false}
                        onChange={region =>
                          _onMatcherChange(i, 'region', region)
                        }
                        options={regionOptions}
                        simpleValue
                        value={
                          matchersByKey.region
                            ? matchersByKey.region.value
                            : null
                        }
                      />
                    </div>
                  )}
                  {continent && (
                    <div>
                      <ControlLabel>Continent</ControlLabel>
                      <Select
                        placeholder="Any continent"
                        onChange={continent =>
                          _onMatcherChange(i, 'continent', continent)
                        }
                        options={continentOptions}
                        simpleValue
                        value={
                          matchersByKey.continent
                            ? matchersByKey.continent.value
                            : null
                        }
                      />
                    </div>
                  )}
                  {country && (
                    <div>
                      <ControlLabel>Country</ControlLabel>
                      <CountrySelector
                        placeholder="Any country"
                        multi={countryMulti}
                        multiParseInt={false}
                        onChange={country => {
                          _onMatcherChange(i, 'country', country)
                        }}
                        value={
                          matchersByKey.country
                            ? matchersByKey.country.value
                            : null
                        }
                      />
                    </div>
                  )}
                  {customer && (
                    <div>
                      <ControlLabel>Customer(s)</ControlLabel>
                      <SelectWrapper
                        cached
                        Component={CustomerSelector}
                        multi={true}
                        onChange={customer =>
                          _onMatcherChange(i, 'customer_ids', customer)
                        }
                        simpleValue
                        value={
                          matchersByKey.customer_ids
                            ? matchersByKey.customer_ids.value
                            : null
                        }
                      />
                    </div>
                  )}
                  {orderChannel && (
                    <div>
                      <ControlLabel>Order channel(s)</ControlLabel>
                      <SelectWrapper
                        cached
                        Component={OrderChannelSelector}
                        multi={true}
                        onChange={customer =>
                          _onMatcherChange(i, 'order_channel_ids', customer)
                        }
                        simpleValue
                        value={
                          matchersByKey.order_channel_ids
                            ? matchersByKey.order_channel_ids.value
                            : null
                        }
                      />
                    </div>
                  )}
                  {currency && (
                    <div>
                      <ControlLabel>Currency</ControlLabel>
                      <CurrencySelector
                        placeholder="Any currency"
                        onChange={currency =>
                          _onMatcherChange(i, 'currency', currency)
                        }
                        value={
                          matchersByKey.currency
                            ? matchersByKey.currency.value
                            : null
                        }
                      />
                    </div>
                  )}
                  {fetchShopifyShops && (
                    <div>
                      <ControlLabel>Shopify shops</ControlLabel>
                      <SelectWrapper
                        labelKey="url"
                        multi
                        multiParseInt
                        options={shopifyShops}
                        onChange={shops =>
                          _onMatcherChange(i, 'shopify_shop_ids', shops)
                        }
                        placeholder="Any Shopify shop"
                        value={
                          matchersByKey.shopify_shop_ids
                            ? matchersByKey.shopify_shop_ids.value
                            : null
                        }
                        valueKey="id"
                      />
                    </div>
                  )}
                  {users && (
                    <div>
                      <ControlLabel>Users</ControlLabel>
                      <SelectWrapper
                        Component={UserSelector}
                        multi
                        multiParseInt
                        onChange={userIds =>
                          _onMatcherChange(i, 'user_ids', userIds)
                        }
                        simpleValue
                        value={
                          matchersByKey.user_ids
                            ? matchersByKey.user_ids.value
                            : []
                        }
                      />
                    </div>
                  )}
                  {fetchErpCompanies && erpCompanies.length > 0 && (
                    <div>
                      <ControlLabel>ERP Company</ControlLabel>
                      <SelectWrapper
                        labelKey="name"
                        options={erpCompanies}
                        onChange={shops =>
                          _onMatcherChange(i, 'erp_company_id', shops)
                        }
                        value={
                          matchersByKey.erp_company_id
                            ? matchersByKey.erp_company_id.value
                            : null
                        }
                        valueKey="id"
                      />
                    </div>
                  )}
                  {addMatcherFieldsInEnd === true && renderMatcherFields}
                </MatcherInputs>
                <RuleRemoveButton
                  className="btn btn-xs btn-danger"
                  onClick={() => _removeRule(i)}
                >
                  <i className="glyphicon glyphicon-trash" />
                </RuleRemoveButton>
              </MatcherRow>

              {dropshippingOrder === true && (
                <MatcherRow>
                  <div className="checkbox check-success">
                    <input
                      type="checkbox"
                      id={`rule-matcher-${id}-${i}-dropshipping-order`}
                      checked={
                        matchersByKey['dropshipping_order']
                          ? matchersByKey['dropshipping_order'].value
                          : false
                      }
                      onChange={() =>
                        _onMatcherChange(
                          i,
                          'dropshipping_order',
                          matchersByKey['dropshipping_order']
                            ? !matchersByKey['dropshipping_order'].value
                            : true
                        )
                      }
                    />

                    <label
                      htmlFor={`rule-matcher-${id}-${i}-dropshipping-order`}
                    >
                      Dropshipping order
                    </label>
                  </div>
                </MatcherRow>
              )}

              {showAdvancedFieldsToggle && (
                <MatcherRow>
                  <div>
                    <span
                      style={{ fontWeight: 'bold' }}
                      onClick={() => setShowAdvancedFields(true)}
                    >
                      Show advanced fields
                    </span>
                  </div>
                  {showAdvancedFields == true && (
                    <MatcherInputs>
                      {allAdvancedMatcherFields.map(field => {
                        const AdvancedFieldComponent = field.component

                        return (
                          <div>
                            <ControlLabel>{field.label}</ControlLabel>

                            <AdvancedFieldComponent
                              index={i}
                              onChange={newValue =>
                                _onMatcherChange(i, field.key, newValue)
                              }
                              value={
                                matchersByKey[field.key]
                                  ? matchersByKey[field.key].value
                                  : null
                              }
                            />
                          </div>
                        )
                      })}
                    </MatcherInputs>
                  )}
                </MatcherRow>
              )}
              <ValueRow>
                <ValueHeader>
                  {headerRender ? (
                    headerRender()
                  ) : (
                    <>
                      For {rule.matchers.map(({ value }) => value).join(', ')}:
                    </>
                  )}
                </ValueHeader>
                {valueRender(i, _onValueChange, rule, renderData)}
              </ValueRow>
            </Rule>
          )
        })}
      </RulesContainer>

      <AdderContainer>
        <AddButton type="button" onClick={_addRule}>
          {newRuleLabel}
        </AddButton>
      </AdderContainer>
    </div>
  )
}

const ConnectedRuleBasedSettingsForm = connect(state => ({
  entity: state.session.entity,
}))(RuleBasedSettingsForm)

export default ConnectedRuleBasedSettingsForm

const AdderContainer = styled.div``

const AddButton = styled.button.attrs({ className: 'btn btn-success btn-sm' })``

const RuleRemoveButton = styled.button.attrs({ type: 'button' })`
  margin-left: 5px;
  height: 20px;
`

const RulesContainer = styled.div`
  margin-top: 20px;
`

const Rule = styled.div`
  align-items: baseline;
  border: 2px dashed #ccc;
  margin-bottom: 10px;
  padding: 10px;
`

const RuleLabel = styled.div`
  align-items: center;
  display: flex;
  font-weight: bold;
  text-align: right;
`

const RuleValue = styled.div`
  margin-left: 15px;
  flex: 1;
`

const MatcherRow = styled.div`
  align-items: center;
  border-radius: 5px;
  display: flex;
  margin-bottom: 15px;
`

const MatcherInputs = styled.div`
  display: flex;
  flex: 1;

  > div {
    flex: 1;
    margin: 0 5px;
  }
`

const ValueRow = styled.div``

const ValueHeader = styled.div`
  color: black;
  font-weight: bold;
  margin-bottom: 5px;
  text-decoration: underline;
`

export const ValueMatcherField = ({ index, onChange, value }) => {
  return (
    <ValueMatcherFieldContainer>
      <FormControl
        onChange={e =>
          onChange(
            value
              ? { ...value, amount: e.target.value }
              : { amount: e.target.value, incl_vat: 'ex' }
          )
        }
        value={value ? value.amount : null}
      />
      <ValueMatcherFieldCurrencyContainer>
        <CurrencySelector
          clearable
          onChange={currency =>
            onChange(
              value
                ? { ...value, currency }
                : { amount: null, incl_vat: 'ex', currency }
            )
          }
          simpleValue
          value={value ? value.currency : null}
        />
      </ValueMatcherFieldCurrencyContainer>
      <ValueMatcherFieldVatContainer>
        <SelectWrapper
          clearable={false}
          onChange={inclVat =>
            onChange(
              value
                ? { ...value, incl_vat: inclVat }
                : { amount: null, incl_vat: inclVat }
            )
          }
          options={vatOptions}
          simpleValue
          value={value ? value.incl_vat : 'ex'}
        />
      </ValueMatcherFieldVatContainer>
    </ValueMatcherFieldContainer>
  )
}

const ValueFieldsContainer = styled.div`
  display: flex;
`

const ValueMatcherFieldContainer = styled.div`
  display: flex;
`

const ValueMatcherFieldCurrencyContainer = styled.div`
  margin-left: 5px;
  min-width: 180px;
`

const ValueMatcherFieldVatContainer = styled.div`
  margin-left: 5px;
  min-width: 80px;
`

const vatOptions = [
  { value: 'incl', label: 'Incl VAT' },
  { value: 'ex', label: 'Ex VAT' },
]
