// @flow

import * as React from 'react'

import locale from '../modules/globalize'
import { convertWeight } from '../utilities'

type Props = {
  value: number | null,
  valueType: 'kg' | 'g',
}

const FormatWeight = ({ renderType = 'kg', value, valueType }: Props) => {
  if (value === null || isNaN(parseFloat(value))) {
    return null
  }

  const renderValue = convertWeight(value, valueType, renderType, false)

  let maxDigits = renderType == 'kg' ? 1 : 0
  // e.g. if we show as kg, but the weight is below 0.1 kg (e.g. 0.02 kg)
  // then we need to show more digits
  if (renderType === 'kg') {
    if (renderValue < 0.01) {
      maxDigits = 3
    } else if (renderValue < 0.1) {
      maxDigits = 2
    }
  }

  const formatter = locale.numberFormatter({
    maximumFractionDigits: maxDigits,
  })

  return (
    <>
      {formatter(renderValue)} {renderType}
    </>
  )
}

export default FormatWeight
