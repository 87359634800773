/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import uniq from 'lodash/uniq'

import AttributeList, { type SelectedAttributes } from '../AttributeList'

import msg from '../../../../infrastructure/modules/msg'
import ActionButton from '../../../../infrastructure/components/ActionButton'
import {
  PermissionContext,
  UserHasPermissions,
} from '../../../../infrastructure/components/Authorization'
import {
  ControlLabel,
  FormControl,
  PriceControl,
} from '../../../../infrastructure/components/Forms'
import FormatCurrency from '../../../../infrastructure/components/FormatCurrency'
import {
  createLineKeyFromVariant,
  createLineKeyPriceIdentifier,
  createLineKey,
} from '../../../products/components/ProductTable/shared'

import type { Product, Variant } from '../../../types'

type Props = {
  allowQuantityExpansion: boolean,
  currency: string,
  lineIndex: Object<number, number>,
  products: List<Product>,
  onHide: Function,
  onSave: Function,
  show: boolean,
}

type State = {
  attributes: Array<string>,
  bulkPercentage: string,
  bulkMonetary: string,
  bulkPrice: string,
  products: List<Product>,
  rows: List,
  selected: Object<string, boolean>,
  selectedAttributes: SelectedAttributes,
  selectedVariants: List<Variant>,
}

export class DiscountModal extends Component<Props, State> {
  static defaultProps = {
    lineIndex: {},
  }

  state = {
    attributes: [],
    bulkPercentage: '',
    bulkMonetary: '',
    bulkPrice: '',
    newPrices: Map(),
    // Copy the Object so we do not edit directly
    products: List(JSON.parse(JSON.stringify(this.props.products))),
    rows: List(),
    selected: {},
    selectedAttributes: {},
    selectedVariants: List(),
  }

  componentDidMount() {
    this._generateRows()
  }

  render() {
    const {
      attributes_order,
      currency,
      horizontal_attribute,
      quantities,
      onHide,
      onSave,
      show,
      use_attributes_order,
      user,
    } = this.props
    const {
      attributes,
      bulkPercentage,
      bulkMonetary,
      bulkPrice,
      products,
      rows,
      selected,
      selectedAttributes,
      selectedVariants,
    } = this.state

    return (
      <Modal bsSize="lg" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Set Discounts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BulkEditContainer>
            <AttributeList
              onChange={this._onAttributeListChange}
              products={products}
              selected={selected}
            />

            {selectedVariants.size > 0 && (
              <BulkFormContainer>
                <PermissionContext permission="orders:discounts:percentage">
                  <div>
                    <ControlLabel>Discount %</ControlLabel>
                    <FormControl
                      onChange={e =>
                        this.setState({ bulkPercentage: e.target.value })
                      }
                      value={bulkPercentage}
                    />
                  </div>
                </PermissionContext>
                <PermissionContext permission="orders:discounts:monetary">
                  <div>
                    <ControlLabel>Discount {currency}</ControlLabel>
                    <PriceControl
                      currency={currency}
                      onChange={e =>
                        this.setState({ bulkMonetary: e.target.value })
                      }
                      value={bulkMonetary}
                    />
                  </div>
                  <div>
                    <ControlLabel>Price {currency}</ControlLabel>
                    <PriceControl
                      currency={currency}
                      onChange={e =>
                        this.setState({ bulkPrice: e.target.value })
                      }
                      value={bulkPrice}
                    />
                  </div>
                </PermissionContext>
                <div>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this._setBulkDiscount}
                  >
                    Set discount
                  </button>
                </div>
              </BulkFormContainer>
            )}
          </BulkEditContainer>

          <table
            className="table table-condensed table-hover"
            style={{ marginTop: 20 }}
          >
            <thead>
              <th>Product name</th>
              {attributes.map(attribute => (
                <th key={`head_${attribute}`}>{attribute}</th>
              ))}
              <th>Quantity</th>
              <PermissionContext permission="orders:discounts:percentage">
                <th>Discount %</th>
              </PermissionContext>
              <PermissionContext permission="orders:discounts:monetary">
                <th>Discount {currency}</th>
              </PermissionContext>
              <th>Price</th>
              <th>Original price</th>
              <th>Total price</th>
              <th className="listview-action" />
              <th className="listview-action" />
            </thead>
            <tbody>
              {rows.map(row => {
                const {
                  colli,
                  discount_amount,
                  discount_percentage,
                  error,
                  index,
                  lock_quantity,
                  net_price,
                  new_price,
                  product,
                  quantity,
                  variant,
                  unit_price,
                } = row
                const marked = selectedVariants.includes(variant.id)

                return (
                  <ProductRow
                    key={index}
                    error={error}
                    newPrice={new_price}
                    marked={marked}
                  >
                    <td>{product.name}</td>
                    {attributes.map(attribute => {
                      return (
                        <td key={`row_${attribute}`}>
                          {variant.attributes[attribute]
                            ? variant.attributes[attribute]
                            : ''}
                        </td>
                      )
                    })}
                    <td>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FormControl
                          value={quantity}
                          disabled={lock_quantity}
                          onChange={e =>
                            this._updateRowProperty(
                              index,
                              'quantity',
                              e.target.value
                            )
                          }
                          onBlur={() => this._distributeQuantities(index)}
                          style={{ flex: '1 0' }}
                        />
                        {colli > 1 && (
                          <div style={{ flex: '0 0' }}>
                            &nbsp;×&nbsp;{colli}pcs
                          </div>
                        )}
                      </div>
                    </td>
                    <PermissionContext permission="orders:discounts:percentage">
                      <td>
                        <FormControl
                          value={discount_percentage}
                          onChange={e =>
                            this._updateRowProperty(
                              index,
                              'discount_percentage',
                              e.target.value
                            )
                          }
                          onBlur={() =>
                            this._updateSiblingProperties(
                              index,
                              'discount_percentage'
                            )
                          }
                        />
                      </td>
                    </PermissionContext>
                    <PermissionContext permission="orders:discounts:monetary">
                      <td>
                        <PriceControl
                          currency={currency}
                          value={discount_amount}
                          onChange={e =>
                            this._updateRowProperty(
                              index,
                              'discount_amount',
                              e.target.value
                            )
                          }
                          onBlur={() =>
                            this._updateSiblingProperties(
                              index,
                              'discount_amount'
                            )
                          }
                        />
                      </td>
                    </PermissionContext>
                    <td>
                      <PriceControl
                        currency={currency}
                        disabled={
                          !UserHasPermissions(user, 'orders:discounts:monetary')
                        }
                        value={net_price}
                        onChange={e =>
                          this._updateRowProperty(
                            index,
                            'net_price',
                            e.target.value
                          )
                        }
                        onBlur={() =>
                          this._updateSiblingProperties(index, 'net_price')
                        }
                      />
                    </td>
                    <td>
                      <FormatCurrency currency={currency}>
                        {unit_price}
                      </FormatCurrency>
                    </td>
                    <td>
                      <FormatCurrency currency={currency}>
                        {net_price * quantity * colli}
                      </FormatCurrency>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-sm btn-white"
                        onClick={() => this._newPrice(index)}
                      >
                        New price
                      </button>
                    </td>
                    <td>
                      {row.new_price === true && (
                        <span
                          className="glyphicon glyphicon-remove"
                          style={{ cursor: 'pointer' }}
                          onClick={() => this._deletePrice(index)}
                        />
                      )}
                    </td>
                  </ProductRow>
                )
              }, [])}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-white" onClick={onHide}>
            Cancel
          </button>
          <ActionButton className="btn btn-success" onClick={this._save}>
            Set discount
          </ActionButton>
        </Modal.Footer>
      </Modal>
    )
  }

  _deletePrice = lineIndex => {
    const rows = this.state.rows
    const row = rows.get(lineIndex)

    const variantId = row.variant.id
    const variantCount = rows.reduce((carry, row) => {
      if (!carry[row.variant.id]) {
        carry[row.variant.id] = 0
      }
      carry[row.variant.id]++
      return carry
    }, {})

    this.setState({
      rows: rows.delete(lineIndex).map((row, i) => {
        if (i < lineIndex) {
          return row
        }

        return {
          ...row,
          index: row.index - 1,
          lock_quantity: variantCount[variantId] <= 2,
        }
      }),
    })
  }

  _distributeQuantities = lineIndex => {
    const rows = this.state.rows
    const row = rows.get(lineIndex)

    const variant = row.variant
    let quantity = row.quantity

    if (isNaN(parseInt(quantity))) {
      return
    }

    quantity = parseInt(quantity)

    const totalQuantity = this.props.quantities.getIn([
      variant.product_id,
      variant.id,
      'quantity',
    ])
    if (quantity > totalQuantity) {
      quantity = totalQuantity
    }

    let left = totalQuantity

    let updatedRows = rows.set(lineIndex, {
      ...row,
      quantity,
    })

    const newTotalQuantity = updatedRows
      .filter(row => row.variant.id == variant.id)
      .reduce((carry, row) => (carry += parseInt(row.quantity)), 0)

    let takeFromOtherRows = newTotalQuantity - totalQuantity
    if (takeFromOtherRows < 0) {
      takeFromOtherRows = 0
    }

    let leftToAllocate = takeFromOtherRows

    const createdFromRow = row._created_from_row || 0

    const loopRows = new List([
      ...updatedRows.slice(createdFromRow),
      ...(createdFromRow > 0 ? updatedRows.slice(0, createdFromRow) : []),
    ])

    for (let row of loopRows.values()) {
      const i = row.index

      if (i === lineIndex) {
        continue
      }

      if (row.variant.id !== variant.id) {
        continue
      }

      if (leftToAllocate <= 0) {
        break
      }

      // leave 1 piece
      const availableOfRow = row.quantity - 1

      if (availableOfRow <= 0) {
        continue
      }

      const allocateToRow =
        availableOfRow >= leftToAllocate ? leftToAllocate : availableOfRow

      if (allocateToRow <= 0) {
        continue
      }

      updatedRows = updatedRows.set(i, {
        ...row,
        quantity: row.quantity - allocateToRow,
      })

      leftToAllocate -= allocateToRow
    }

    this.setState({
      rows: updatedRows,
    })
  }

  _generateRows = () => {
    const {
      attributes_order,
      currency,
      horizontal_attribute,
      quantities,
      use_attributes_order,
    } = this.props
    const { products } = this.state

    const attributes = uniq(
      products.reduce((carry, product) => carry.concat(product.attributes), [])
    )
    attributes.sort((a, b) => {
      if (a === horizontal_attribute) {
        return 1
      }
      if (b === horizontal_attribute) {
        return -1
      }

      return a < b ? 1 : -1
    })

    const variantCount = products.reduce((carry, product) => {
      return product.variants.reduce((innerCarry, variant) => {
        if (!carry[variant.id]) {
          carry[variant.id] = 0
        }
        carry[variant.id]++
        return carry
      }, carry)
    }, {})

    let rowIndex = -1
    let lastVariant
    const rows = products.reduce((carry, product) => {
      return carry.concat(
        List(
          product.variants.map((variant, i) => {
            // We do not want people to change the quantity of lines with no more
            // than one line of that variant
            const hasMoreOfVariant = variantCount[variant.id] > 1

            const prices = variant.prices[currency]

            let net_price, discount_percentage, discount_amount, unit_price
            if (prices) {
              net_price = prices.offer_price
                ? prices.offer_price
                : prices.sales_price
              discount_amount = prices.discount_amount || 0
              discount_percentage = prices.discount_percentage || 0
              unit_price = prices.price_before_discount || net_price
            }

            let quantity = quantities.getIn([
              product.id,
              variant.id,
              'quantity',
            ])
            if (quantities.hasIn([product.id, variant.id, 'price_splits'])) {
              quantity = quantities.getIn([
                product.id,
                variant.id,
                'price_splits',
                net_price == 0 ? 0 : net_price.toFixed(2),
                'quantity',
              ])
            }

            const colli = quantities.getIn([product.id, variant.id, 'colli'], 1)

            rowIndex++
            lastVariant = variant.id

            return {
              discount_amount: discount_amount,
              discount_percentage: discount_percentage,
              lock_quantity: !hasMoreOfVariant,
              index: rowIndex,
              net_price: net_price,
              net_price_before_edit: net_price,
              quantity: quantity,
              colli: colli,
              unit_price: unit_price,
              variant: variant,
              product: product,
            }
          })
        )
      )
    }, List())

    this.setState({
      attributes: attributes,
      rows: rows,
    })
  }

  _newPrice = lineIndex => {
    const rows = this.state.rows

    const row = rows.get(lineIndex)
    const updatedRows = rows
      .splice(lineIndex + 1, 0, {
        ...row,
        lock_quantity: false,
        index: lineIndex + 1,
        new_price: true,
        quantity: 0,
        _created_from_row: lineIndex,
      })
      .map((row, i) => {
        if (lineIndex === i) {
          return { ...row, lock_quantity: false }
        }

        if (i <= lineIndex + 1) {
          return row
        }

        return { ...row, index: row.index + 1 }
      })

    this.setState({
      rows: updatedRows,
    })
  }

  _onAttributeListChange = (products, selected) => {
    let anyValues = false
    for (var i in selected) {
      if (selected[i].length > 0) {
        anyValues = true
      }
    }

    let selectedVariants = List()
    if (anyValues) {
      selectedVariants = products.reduce(
        (carry, product) =>
          carry.concat(product.variants.map(variant => variant.id)),
        List()
      )
    }

    this.setState({
      selected: selected,
      selectedVariants: selectedVariants,
    })
  }

  _save = () => {
    // Ignore new rows with no quantity
    const rows = this.state.rows.filter(
      row => row.new_price || row.quantity !== 0
    )
    const rowsWithError = rows.filter(row => row.error === true)

    if (rowsWithError.size > 0) {
      msg('info', 'Please fix the rows marked with red')
      return false
    }

    let quantities = rows.reduce((carry, row) => {
      const key = [row.variant.product_id, row.variant.id]

      let quantities = carry.getIn(key)

      const splitKey = [
        'price_splits',
        createLineKeyPriceIdentifier(row.net_price),
      ]
      quantities = quantities.setIn(
        splitKey,
        Map({
          colli: row.colli,
          quantity: parseInt(row.quantity),
        })
      )

      return carry.setIn(key, quantities)
    }, this.props.quantities)

    /**
     * When giving 100% discount to something it leaves a key with the original price in the
     * quantities object. Here we go through all the rows and see if there is a variant price
     * in the quantities object that cannot be found in the quantities object
     */
    const rowsByVariant = groupBy(rows.toJS(), row => row.variant.id)
    for (var variantId in rowsByVariant) {
      const rows = rowsByVariant[variantId]
      const firstRow = rows[0]

      // It might be that the calculated price will be >2 decimals
      const variantPrices = rows.map(v =>
        v.net_price == 0 ? 0 : v.net_price.toFixed(2)
      )
      const key = [firstRow.product.id, firstRow.variant.id, 'price_splits']

      const variantQuantities = quantities.getIn(key).keys()
      for (var price of variantQuantities) {
        var priceFloat = parseFloat(price)
        var priceString = price.toString()

        // When rehydrating from JSON (NewOrder) the price will be string, but normally float...
        if (
          variantPrices.indexOf(priceFloat) === -1 &&
          variantPrices.indexOf(priceString) === -1
        ) {
          quantities = quantities.deleteIn([...key, priceFloat])
          quantities = quantities.deleteIn([...key, priceString])
        }
      }
    }

    let rowValues = rows.values()
    const errors = []
    for (var row of rowValues) {
      let totalSplitQuantity = 0
      const variantQuantities = quantities.getIn([
        row.variant.product_id,
        row.variant.id,
      ])
      const priceSplits = variantQuantities.get('price_splits')

      // Its not certain that a variant will have price splits
      if (priceSplits && priceSplits.keys().size > 0) {
        for (let split of priceSplits.values()) {
          totalSplitQuantity += split.get('quantity')
        }
      } else {
        totalSplitQuantity += variantQuantities.get('quantity')
      }

      const shouldHaveQuantity = quantities.getIn([
        row.variant.product_id,
        row.variant.id,
        'quantity',
      ])
      if (totalSplitQuantity != shouldHaveQuantity) {
        const product =
          row.product.name +
          ' ' +
          Object.keys(row.variant.attributes).map(
            attribute => row.variant.attributes[attribute]
          )
        errors.push(
          `${product} has a total of ${totalSplitQuantity} but there is only ${shouldHaveQuantity} on the order. Please correct this.`
        )
      }
    }

    if (errors.length > 0) {
      msg('error', errors)
      return false
    }

    const currency = this.props.currency
    const lineIndex = this.props.lineIndex
    const newLineIndex = {}

    let updatedProducts = this.state.products
    // reset iterator
    rowValues = rows.values()
    for (var row of rowValues) {
      updatedProducts = updatedProducts.map(product => {
        if (product.id !== row.variant.product_id) {
          return product
        }

        const variants = [...product.variants]
        let index = 0
        variants.forEach((variant, i) => {
          // There can be more variants with same id for each of the prices so
          // we need to find the correct variant by the price it had before editing
          if (
            variant.id === row.variant.id &&
            variant.prices[currency].sales_price == row.net_price_before_edit
          ) {
            index = i
          }
        })

        const variantCopy = JSON.parse(JSON.stringify(row.variant))

        const key = createLineKeyFromVariant(variantCopy, currency)
        const newKey = createLineKey(
          variantCopy.id,
          createLineKeyPriceIdentifier(row.net_price)
        )

        newLineIndex[newKey] = lineIndex[key]

        const newPrices = {
          ...variantCopy.prices,
          [currency]: {
            ...variantCopy.prices[currency],
            sales_price: row.net_price,
            offer_price: null,

            price_before_discount:
              variantCopy.prices[currency].price_before_discount ||
              variantCopy.prices[currency].sales_price,
            discount_amount: row.discount_amount,
            discount_percentage: row.discount_percentage,
          },
        }
        if (row.new_price) {
          const currency = this.props.currency
          variants.splice(index + 1, 0, {
            ...variantCopy,
            prices: newPrices,
            new_discount_variant: true,
          })
        } else {
          variants[index] = {
            ...variantCopy,
            prices: newPrices,
          }
        }

        return {
          ...product,
          variants: variants,
        }
      })
    }

    this.props.onSave(quantities, updatedProducts, newLineIndex)
  }

  _setBulkDiscount = () => {
    const { bulkMonetary, bulkPercentage, bulkPrice, selectedVariants } =
      this.state

    const parsedMonetary = parseFloat(bulkMonetary)
    const parsedPercentage = parseFloat(bulkPercentage)
    const parsedPrice = parseFloat(bulkPrice)

    if (
      isNaN(parsedMonetary) &&
      isNaN(parsedPercentage) &&
      isNaN(parsedPrice)
    ) {
      msg(
        'error',
        'Please enter either a valid monetary discount, percentage discount or price'
      )
      return
    }

    let useProperty
    let useValue

    if (!isNaN(parsedPercentage)) {
      useProperty = 'discount_percentage'
      useValue = bulkPercentage
    } else if (!isNaN(parsedMonetary)) {
      useProperty = 'discount_amount'
      useValue = bulkMonetary
    } else {
      useProperty = 'net_price'
      useValue = bulkPrice
    }

    const rows = [...this.state.rows]
    let promise

    const iterate = (rows, value) => {
      if (rows.length === 0) {
        this.setState({
          bulkMonetary: '',
          bulkPercentage: '',
          bulkPrice: '',
          selected: {},
          selectedVariants: List(),
        })

        return
      }

      const row = rows.shift()

      const createPromise = () => {
        return this._updateRowProperty(row.index, useProperty, useValue)
          .then(() => this._updateSiblingProperties(row.index, useProperty))
          .then(() => iterate(rows, useValue))
      }

      promise = !promise ? createPromise() : promise.then(createPromise)
    }

    iterate(
      rows.filter(row => selectedVariants.includes(row.variant.id)),
      bulkPercentage
    )
  }

  _updateRowProperty = (lineIndex, property, value) => {
    const rows = this.state.rows

    const updatedRows = rows.map((row, i) => {
      if (i != lineIndex) {
        return row
      }

      return {
        ...row,
        [property]: value,
      }
    })

    return new Promise(resolve => {
      this.setState(
        {
          rows: updatedRows,
        },
        resolve
      )
    })
  }

  _updateSiblingProperties = (lineIndex, property) => {
    let rows = this.state.rows
    const row = rows.get(lineIndex)

    const value = parseFloatInput(row[property])

    if (value === false) {
      this.setState({
        rows: setError(rows, lineIndex, true),
      })
      return false
    } else if (row.error === true) {
      rows = setError(rows, lineIndex, false)
    }

    const original_price = parseFloat(row.unit_price)

    let net_price, discount_amount, discount_percentage
    if (property === 'discount_percentage') {
      net_price = parseFloat((original_price * (1 - value / 100)).toFixed(2))

      discount_percentage = value
      discount_amount = parseFloat((original_price - net_price).toFixed(2))
    } else if (property === 'discount_amount') {
      net_price = original_price - value

      discount_amount = value
      discount_percentage = parseFloat(
        ((1 - net_price / original_price) * 100).toFixed(2)
      )
      // net_price
    } else {
      net_price = value

      discount_amount = parseFloat((original_price - net_price).toFixed(2))
      discount_percentage = parseFloat(
        ((1 - net_price / original_price) * 100).toFixed(2)
      )
    }

    const updatedRows = rows.map(row => {
      if (row.index !== lineIndex) {
        return row
      }

      return {
        ...row,
        discount_amount: discount_amount,
        discount_percentage: discount_percentage,
        net_price: net_price,
      }
    })

    return new Promise(resolve => {
      this.setState(
        {
          rows: updatedRows,
        },
        resolve
      )
    })
  }
}

export default connect(state => {
  const settings = state.session.settings

  return {
    attributes_order: settings.attributes_order,
    horizontal_attribute: settings.product_table.horizontal_attribute,
    use_attributes_order: settings.use_attributes_order,
    user: state.session.user,
  }
})(DiscountModal)

const ProductRow = styled.tr`
  td {
    background-color: ${props => determineRowBackground(props)};
  }
`

const BulkEditContainer = styled.div`
  background: #f8f8f8;
  padding: 5px;
`

const BulkFormContainer = styled.div`
  display: flex;
  margin-left: -10px;
  margin-top: 10px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 10px;
  }
`

const determineRowBackground = ({
  error = false,
  newPrice = false,
  marked = false,
}: {
  error: boolean,
  newPrice: boolean,
  marked: boolean,
}) => {
  if (error === true) {
    return '#ffb6b6'
  } else if (marked === true) {
    return '#fff5e3'
  } else if (newPrice === true) {
    return '#ebffec'
  } else {
    return 'transparent'
  }
}

const calculateDiscounts = row => {
  const original_price = row.unit_price
  const new_price = row.net_price

  const discount_percentage = parseFloat(
    ((1 - new_price / original_price) * 100).toFixed(1)
  )
  const discount_amount = original_price * (discount_percentage / 100)

  return {
    ...row,
    discount_amount: discount_amount,
    discount_percentage: discount_percentage,
  }
}

const parseFloatInput = input => {
  if (typeof input === 'string' && input.trim() === '') {
    input = 0
  }

  const parsed = parseFloat(input)

  if (isNaN(parsed)) {
    return false
  }

  return parsed
}

const setError = (rows, lineIndex, error) => {
  return rows.map(row => {
    if (row.index !== lineIndex) {
      return row
    }

    return { ...row, error: error }
  })
}
