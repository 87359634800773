/* @flow */

import ProductTable from './ProductTable'

import {
  columnConfigurations,
  dataSourceConfigurations,
  productActionConfigurations,
  productDataHeaderConfigs,
  rowConfigurations,
  verticalAttributeColumnConfigurations,
  tableActionConfigurations,
  tableSectionConfigurations,
} from './configuration'
import {
  calculateNumberOfLineChanges,
  getLinesThatDifferFromPersistedLines,
  prepareProductTableLinesForApi,
} from './data'
import {
  useLinesReducer,
  useLinesReducerV2,
  useProductTableControls,
  useProductIds,
  useSummaryProductTableState,
} from './hooks'
import CustomFieldColumn from './CustomFieldColumn'
import SortProductsAction from './SortProductsAction'
import ToggleVariantsAction from './ToggleVariantsAction'
import LineText from './LineText'
import UnitPrice from './UnitPrice'
import {
  wrapRowsInBatchControl,
  wrapRowsInBatchControlAndInventoryLocations,
} from './shared'
import { createDefaultLineData } from './Rows/shared'

export default ProductTable

export {
  calculateNumberOfLineChanges,
  createDefaultLineData,
  columnConfigurations,
  dataSourceConfigurations,
  getLinesThatDifferFromPersistedLines,
  rowConfigurations,
  prepareProductTableLinesForApi,
  productActionConfigurations,
  productDataHeaderConfigs,
  tableActionConfigurations,
  tableSectionConfigurations,
  useLinesReducer,
  useLinesReducerV2,
  useProductTableControls,
  useProductIds,
  useSummaryProductTableState,
  CustomFieldColumn,
  LineText,
  SortProductsAction,
  ToggleVariantsAction,
  UnitPrice,
  verticalAttributeColumnConfigurations,
  wrapRowsInBatchControl,
  wrapRowsInBatchControlAndInventoryLocations,
}
