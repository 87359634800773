/* @flow */

const euCountries = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DE', // Germany
  'DK', // Denmark
  'EE', // Estonia
  'ES', // Spain
  'FI', // Finland
  'FR', // France
  'GR', // Greece
  'HR', // Croatia
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LT', // Lithuania
  'LU', // Luxembourg
  'LV', // Latvia
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SE', // Sweden
  'SI', // Slovenia
  'SK', // Slovenia
]

const nonEuEuropeCountries = [
  'GB', // United Kingdom
  'FO', // Faraoe Islands
  'GL', // Greenland
  'IS', // Iceland
  'NO', // Norway
  'CH', // Switzerland
  'XK', // Kosovo
]

export const isEuCountry = countryCode => {
  return euCountries.indexOf(countryCode) !== -1
}

export const isEuropeanCountry = countryCode => {
  return euCountries.concat(nonEuEuropeCountries).indexOf(countryCode) !== -1
}

export const matchRuleBasedSettings = (data, rules) => {
  if (!data || !rules || rules.length === 0) {
    return null
  }

  let matchedRule = null
  let matchedRuleCount = 0
  for (let rule of rules) {
    let matches = true
    let matchCount = 0

    for (let matchConfig of rule.matchers) {
      const { key, value } = matchConfig

      if (value === null) {
        continue
      }

      if (!data[key] || data[key] !== value) {
        matches = false
        continue
      }

      matchCount++
    }

    if (matches === true && matchCount > matchedRuleCount) {
      matchedRule = rule
      matchedRuleCount = matchCount
    }
  }

  if (matchedRule === null) {
    for (let rule of rules) {
      if (rule.matchers.length === 0) {
        matchedRule = rule
        break
      } else {
        let hasNonNullValue = false
        for (let matcher of rule.matchers) {
          if (matcher.value !== null) {
            hasNonNullValue = true
          }
        }

        if (!hasNonNullValue) {
          matchedRule = rule
          break
        }
      }
    }
  }

  return matchedRule === null ? null : matchedRule.value
}
