/* @flow */

import * as React from 'react'
import sortBy from 'lodash/sortBy'
import Select from 'react-select'
import { Field } from 'formik'

import { renderFormInput } from '../../../infrastructure/components/Formik/FormInput'
import { renderSelectInput } from '../../../infrastructure/components/Formik/SelectInput'

const ZalandoInputField = ({ attribute, ...props }) => {
  return (
    <Field attribute={attribute} component={renderZalandoInput} {...props} />
  )
}

export default ZalandoInputField

export const renderZalandoInput = ({ attribute, ...props }) => {
  const { options, multi } = React.useMemo(() => {
    if (!attribute || attribute.usage !== 'reference_by_label') {
      return {
        options: [],
        multi: false,
      }
    }

    const multi = attribute.cardinality === 'many'

    return {
      options: attribute.values.map(v => ({
        label: v.name.en,
        value: v.label,
      })),
      multi,
    }
  }, [attribute])

  const selectInputProps = React.useMemo(() => {
    return {
      ...props,
      options,
      simpleValue: true,
      multi,
      multiParseInt: false,
    }
  }, [options, multi, props])

  if (!attribute) {
    return <div>Attribute not found</div>
  }

  if (attribute.usage === 'reference_by_label') {
    return renderSelectInput(selectInputProps)
  }

  return renderFormInput(props)
}
