/* @flow */

import * as React from 'react'
import styled from 'styled-components'
import { InfiniteLoader } from 'react-virtualized'
import memo from 'memoize-one'
import chunk from 'lodash/chunk'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'

import { ROW_OVERSCAN, fetchProducts as defaultFetchProducts } from './shared'
import { SessionContext } from '../../../shared'

import { useRefValue } from '../../../shared'
import type { Id, SortDirection } from '../../../types'

type Props = {
  brandId: Id,
  children: Function,
  productsFetchData?: Object,
  query: ?string,
  shopV2?: boolean,
  sort: Array<{ key: string, direction: SortDirection }>,
  width: number,
}

const ProductGridDataSource = ({
  brandId,
  children,
  config,
  fetchProducts = defaultFetchProducts,
  filters,
  mode,
  productsFetchData,
  query,
  shopV2,
  sort,
  width,
}: Props) => {
  const { settings } = React.useContext(SessionContext)

  const sortAndDirection = React.useMemo(() => {
    if (isArray(sort)) {
      return sort.map(({ key, direction }) => ({ key, direction }))
    } else if (isString(sort)) {
      const [key, direction = 'asc'] = sort.split('_')
      return [{ key, direction }]
    } else {
      return [{ key: 'name', direction: 'asc' }]
    }
  }, [sort])

  const infiniteLoaderRef = React.useRef(null)

  const [productsState, setProductsState] = React.useState({
    isDoingInitialFetch: false,
    fetchStatusMap: {},
    isLoadedMap: {},
    total: null,
  })
  const { isDoingInitialFetch, isLoadedMap, total } = productsState

  const { columnCount, columnWidth } = calculateColumnWidth(
    width,
    config.columnWidth
  )

  const rowCount = React.useMemo(() => {
    return Math.ceil(total / columnCount)
  }, [total, columnCount])

  React.useEffect(() => {
    setProductsState({
      isDoingInitialFetch: false,
      fetchStatusMap: {},
      isLoadedMap: {},
      total: null,
    })

    // We have had issues with some users, e.g. Alexeia, and Louise from IX Studios where
    // the `width` prop changed when the quantities modal was opened in ShowOrderV3. This can
    // cause `columnCount` to change which resets the state, but InfiniteLoader memoizes
    // what has been fetched. So we need to reset InfinitLoader to reset as well
    // Ref: https://app.shortcut.com/traede/story/27450/products-not-shown-in-the-product-overview
    // Ref: https://github.com/bvaughn/react-virtualized/blob/master/docs/InfiniteLoader.md#memoization-and-rowcount-changes
    if (infiniteLoaderRef.current) {
      infiniteLoaderRef.current.resetLoadMoreRowsCache()
    }
  }, [columnCount, filters, infiniteLoaderRef, mode, sort, query])

  React.useEffect(() => {
    if (isDoingInitialFetch === false && columnCount > 0 && total === null) {
      setProductsState(s => ({
        ...s,
        isDoingInitialFetch: true,
      }))

      const limit = ROW_OVERSCAN * columnCount
      const start = 0

      // we need to make sure that we immediately set a row as loaded,
      // so we dont dispatch multiple requests with the same row
      setProductsState(s => {
        const fetchStatusMap = { ...s.fetchStatusMap }
        const rowsToLoad = Math.ceil(limit / columnCount)

        for (let i = 0; i < rowsToLoad; i++) {
          fetchStatusMap[i] = true
        }

        return {
          ...s,
          fetchStatusMap: fetchStatusMap,
        }
      })

      fetchProducts(
        brandId,
        mode,
        filters,
        limit,
        sortAndDirection,
        start,
        productsFetchData,
        query,
        shopV2,
        settings.use_custom_product_sort
      ).then(response => {
        if (!response.error) {
          const rows = chunk(response.payload.products, columnCount)

          setProductsState(s => {
            const updatedMap = { ...s.isLoadedMap }
            let i = 0
            for (let row of rows) {
              updatedMap[i] = row
              i++
            }

            return {
              ...s,
              isDoingInitialFetch: false,
              isLoadedMap: updatedMap,
              total: response.payload.total,
            }
          })
        }
      })
    }
  }, [
    brandId,
    columnCount,
    fetchProducts,
    filters,
    isDoingInitialFetch,
    mode,
    productsFetchData,
    query,
    shopV2,
    total,
  ])

  const fetchStatusValue = useRefValue(productsState.fetchStatusMap)
  const isRowLoaded = React.useCallback(
    ({ index }) => {
      return !!fetchStatusValue.current[index]
    },
    [fetchStatusValue]
  )

  const getProducts = ({ startIndex, stopIndex }) => {
    if (total === null) {
      return
    }

    const perRow = columnCount

    const rowsToLoad = stopIndex - startIndex + 1

    const startProductIndex = startIndex * perRow
    const limit = rowsToLoad * perRow

    let productsPromise = fetchProducts(
      brandId,
      mode,
      filters,
      limit,
      sortAndDirection,
      startProductIndex,
      productsFetchData,
      query,
      shopV2,
      settings.use_custom_product_sort
    )

    if (productsPromise) {
      // we need to make sure that we immediately set a row as loaded,
      // so we dont dispatch multiple requests with the same row
      setProductsState(s => {
        const fetchStatusMap = { ...s.fetchStatusMap }
        const rowsToLoad = Math.ceil(limit / columnCount)

        for (let i = 0; i < rowsToLoad; i++) {
          fetchStatusMap[i + startIndex] = true
        }

        return {
          ...s,
          fetchStatusMap: fetchStatusMap,
        }
      })

      productsPromise.then(response => {
        const rows = chunk(response.payload.products, perRow)

        setProductsState(s => {
          const updatedMap = { ...s.isLoadedMap }

          for (let i = 0; i < rows.length; i++) {
            updatedMap[i + startIndex] = rows[i]
          }

          return {
            ...s,
            isLoadedMap: updatedMap,
            total: response.payload.total,
          }
        })
      })
    }
  }

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={getProducts}
      ref={infiniteLoaderRef}
      rowCount={rowCount}
    >
      {({ onRowsRendered, registerChild }) =>
        children({
          columnCount,
          columnWidth,
          isLoadedMap,
          onRowsRendered,
          registerChild,
          rowCount,
          total,
        })
      }
    </InfiniteLoader>
  )
}

export default ProductGridDataSource

const calculateColumnWidth = memo((width, targetColumnWidth) => {
  // target = 200
  const columnCount = Math.floor(width / targetColumnWidth)
  const actualColumnsWidth = columnCount * targetColumnWidth
  const excessColumnWidth = width - actualColumnsWidth
  const excessPerColumn = excessColumnWidth / columnCount

  const columnWidthIncludingExcess = targetColumnWidth + excessPerColumn

  return {
    columnCount,
    columnWidth: columnWidthIncludingExcess,
  }
})
