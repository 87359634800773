/* @flow */

import * as React from 'react'
import Select from 'react-select'
import isPlainObject from 'lodash/isPlainObject'
import difference from 'lodash/difference'
import sortBy from 'lodash/sortBy'
import {
  Search,
  UnorderedSearchIndex,
  AllSubstringsIndexStrategy,
} from 'js-search'

import { useProductsList } from '../hooks'
import type { Dispatch, Id, Product } from '../../types'

type Props = {
  collections?: Array<Id>,
  categories?: Array<Id>,
  isLoading: boolean,
  products: Array<Product>,
  showInactive?: boolean,
  value: Id | Array<Id>,
}

export const DatalessProductSelector = ({
  collections = [],
  categories = [],
  isLoading,
  products = [],
  showInactive = false,
  testEfficient = false,
  value,
  ...restProps
}: Props) => {
  const options = React.useMemo(() => {
    let options = products.filter(product => {
      if (!showInactive && !product.active) {
        return false
      }

      if (
        categories.length > 0 &&
        difference(
          categories,
          product.categories.map(c => c.id)
        ).length > 0
      ) {
        return false
      }

      if (
        collections.length > 0 &&
        (!product.collection_id || !~collections.indexOf(product.collection_id))
      ) {
        return false
      }

      return true
    })

    options = options.map(product => {
      let label = product.name + ' (#' + product.item_number + ')'

      if (product.collection) {
        label += ` (${
          isPlainObject(product.collection)
            ? product.collection.title
            : product.collection
        })`
      }

      let sku = product.sku
      if (!sku && product.variants) {
        sku = product.variants.map(v => v.sku)
      }

      return { ...product, label, sku }
    })

    return sortBy(options, ['name'])
  }, [categories, collections, products, showInactive])

  const search = React.useMemo(() => {
    // "id" field contains values that uniquely identify search documents - in our case - products
    // this field's values are used to ensure that a search result set does not contain duplicate objects
    const search = new Search('id')

    search.searchIndex = new FilteredProductsStrategy()
    search.indexStrategy = new AllSubstringsIndexStrategy()

    search.addIndex('name')
    search.addIndex('sku')
    search.addIndex('item_number')

    return search
  }, [])

  React.useEffect(() => {
    if (search) {
      search.addDocuments(options)
    }
  }, [search, options])

  const filterOptions = React.useCallback(
    (options, filter = '') => {
      search.searchIndex.setCollection(collections)
      search.searchIndex.setCategory(categories)
      return filter.length ? search.search(filter) : options
    },
    [categories, collections, search]
  )

  return (
    <Select
      {...restProps}
      value={value}
      valueKey="id"
      labelKey="label"
      isLoading={isLoading}
      placeholder="Select product..."
      options={options}
      filterOptions={filterOptions}
    />
  )
}

export const productSelectorNeededFields = [
  'id',
  'name',
  'active',
  'item_number',
  'collection_id',
  'collection',
  'categories',
  'sku',
  'variants.sku',
]

const ProductSelector = (
  props: $Diff<Props, { isLoading: boolean, products: Array<Product> }>
) => {
  const productsListOptions = React.useMemo(() => {
    if (!props.testEfficient) {
      return {}
    }

    return {
      fields: productSelectorNeededFields,
    }
  }, [props.testEfficient])

  const [{ products }, areProductsFetching] =
    useProductsList(productsListOptions)

  return (
    <DatalessProductSelector
      products={products}
      isLoading={areProductsFetching}
      {...props}
    />
  )
}

// e.g. on Jay M Jay we on product profile, we need to
// ensure that the product selector does not re-render all the time
const MemoizedProductSelector = React.memo(ProductSelector)

export default MemoizedProductSelector

class FilteredProductsStrategy extends UnorderedSearchIndex {
  constructor() {
    super()

    this._collections = null
    this._categories = null
  }

  setCollection(collections) {
    this._collections = collections
  }

  setCategory(categories) {
    this._categories = categories
  }

  search(tokens, corpus) {
    const collectionFilter = p =>
      this._collections.indexOf(p.collection_id) !== -1
    const categoryFilter = p =>
      difference(
        this._categories,
        p.categories.map(c => c.id)
      ).length === 0

    const filters = []
    if (this._collections.length > 0) {
      filters.push(collectionFilter)
    }
    if (this._categories.length > 0) {
      filters.push(categoryFilter)
    }

    return filters.reduce(
      (documents, filter) => documents.filter(filter),
      super.search(tokens, corpus)
    )
  }
}
