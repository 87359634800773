import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import createApiMiddleware from '../middleware/api'
import { apiMiddleware as baseApiMiddleware } from 'redux-api-middleware'
import rootReducer from '../../app/rootReducer'
import batchRequestMiddleware from '../middleware/batch'
import { attemptAccessTokenRefresh, logoutUser } from '../actions/session'

const devtools = window.devToolsExtension || (() => noop => noop)

export default function configureStore(initialState) {
  const middlewares = [
    thunk,
    batchRequestMiddleware,
    createApiMiddleware({
      logoutActionCreator: logoutUser,
      refreshActionCreator: attemptAccessTokenRefresh,
    }),
    baseApiMiddleware,
  ]

  const enhancers = [applyMiddleware(...middlewares)]

  // Enable Redux devtools the recommended way
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(...enhancers)
  )

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../../app/rootReducer', () => {
      System.import('../../app/rootReducer').then(reducerModule => {
        const createReducers = reducerModule.default
        const nextReducers = createReducers(store.asyncReducers)

        store.replaceReducer(nextReducers)
      })
    })
  }

  return store
}
