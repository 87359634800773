/* @flow */

import * as React from 'react'

export const WarehouseContext = React.createContext({
  barcode: null,
  debugScanner: false,
  setDebugScanner: () => {},
})

export const buildFullProductVariantName = (product, variant, shipmentsApp) => {
  const number = shipmentsApp.settings.use_product_sku_in_scanner
    ? variant.sku
    : product.item_number
  let name = `${product.name} (#${number})`

  product.attributes.forEach((attributeName, index) => {
    if (index > 0) {
      name += ','
    }

    name += ` ${attributeName}: ${variant.attributes[attributeName]}`
  })

  return name
}
