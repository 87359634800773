/* @flow */

import React from 'react'
import styled from 'styled-components'
import isPlainObject from 'lodash/isPlainObject'
import FormatCurrency from '../../../infrastructure/components/FormatCurrency'
import { Tooltip } from 'react-bootstrap'
import { isValidCurrency } from '../../../infrastructure/components/CurrencySelector'
import {
  emptyString,
  hasValue,
  isValidFloat,
} from '../../../infrastructure/components/Forms'
import isString from 'lodash/isString'
import type { Currency, Price as PriceType, Product } from '../../types'

export const getPriceByCurrency = (
  prices: { [Currency]: PriceType },
  currency: Currency
) => {
  const price = prices[currency]
  return price === undefined ? undefined : getPrice(price)
}

export const getPrice = (price: PriceType, dropshipping: boolean = false) => {
  if (!isPlainObject(price)) {
    return null
  }

  let { offer_price, sales_price, dropshipping_price } = price

  offer_price = parseFloat(offer_price)
  sales_price = parseFloat(sales_price)

  if (dropshipping) {
    offer_price = null
    sales_price = dropshipping_price || sales_price
  }

  if (!isNaN(offer_price)) {
    return offer_price
  }

  return !isNaN(sales_price) ? sales_price : null
}

export const DiscountedPrice = ({
  currency,
  price,
  quantity = 1,
  percent = true,
}: {
  price: PriceType,
  quantity: number,
  currency: Currency,
  percent: boolean,
}) => {
  let discountLabel
  if (percent && price.discount_percentage) {
    discountLabel = <span>(-{price.discount_percentage}%)</span>
  } else if (!percent && price.discount_amount) {
    discountLabel = (
      <span>
        (-
        <FormatCurrency currency={currency} key="discount_amount">
          {price.discount_amount * quantity}
        </FormatCurrency>
        )
      </span>
    )
  }

  return (
    <div>
      <Price currency={currency} quantity={quantity} price={price} />
      {discountLabel && <DiscountContainer>{discountLabel}</DiscountContainer>}
    </div>
  )
}

const DiscountContainer = styled.div`
  color: #ca1414;
  display: flex;
  font-size: 11px;
  justify-content: flex-end;
  opacity: 0.8;
`

export default function Price({
  price,
  quantity = 1,
  currency,
}: {
  price: PriceType,
  quantity: number,
  currency: Currency,
}) {
  return (
    <FormatCurrency currency={currency}>
      {getPrice(price) * quantity}
    </FormatCurrency>
  )
}

export const B2cOfferTooltip = (
  <Tooltip id="b2c_offer_tooltip">
    Puts the product on sale in your B2C shop using this price
  </Tooltip>
)

export const RrpTip = <Tooltip id="rrp_tip">Recommended Retail Price</Tooltip>

export const WhsTip = <Tooltip id="whs_tip">Wholesale Price</Tooltip>

export const createEmptyPrice = (currency: Currency) => ({
  currency,
  sales_price: null,
  rec_sales_price: null,
  offer_price: null,
})

export const validatePrice = (price: $Shape<PriceType>) => {
  const priceErrors = {}

  if (!hasValue(price.sales_price) || parseFloat(price.sales_price) < 0) {
    priceErrors.sales_price = 'Enter a sales price'
  }

  const props = ['sales_price', 'rec_sales_price', 'offer_price']
  for (var prop in props) {
    const property = props[prop]
    const val = price[property]

    // Check for null values first
    if (hasValue(val)) {
      if (!isValidFloat(val)) {
        priceErrors[property] = 'This is not a valid price'
      }

      if (isString(val) && val.indexOf(',') !== -1) {
        priceErrors[property] = 'Use . instead of ,'
      }
    }
  }

  return priceErrors
}

export const getLowestPriceFromProduct = (
  product: Product,
  currency: Currency,
  dropshipping?: boolean = false
) => {
  const lowestPrice = product.variants.reduce((carry, variant) => {
    if (variant.prices[currency]) {
      if (!carry) {
        return variant.prices[currency]
      }

      if (
        getPrice(carry, dropshipping) >
        getPrice(variant.prices[currency], dropshipping)
      ) {
        return variant.prices[currency]
      }
    }

    return carry
  }, null)

  if (dropshipping) {
    lowestPrice.offer_price = null
    lowestPrice.sales_price =
      lowestPrice.dropshipping_price || lowestPrice.sales_price
    lowestPrice.price = lowestPrice.sales_price
  }

  return lowestPrice
}
