/* @flow */

import React, { Component, useMemo } from 'react'
import Select from 'react-select'
import sortBy from 'lodash/sortBy'

import { useCachedCategories } from '../hooks'

type Props = {
  includeSubcategories?: boolean,
}

const CategorySelector = ({ includeSubcategories = false, ...props }) => {
  const requestArgs = React.useMemo(() => {
    if (!includeSubcategories) {
      return {}
    }

    return {
      includes: ['children'],
    }
  }, [includeSubcategories])
  const [categories, isFetching] = useCachedCategories(requestArgs)

  return (
    <DatalessCategorySelector
      categories={categories}
      includeSubcategories={includeSubcategories}
      isFetching={isFetching}
      {...props}
    />
  )
}

export default CategorySelector

export const DatalessCategorySelector = ({
  categories,
  isFetching,
  includeSubcategories,
  ...props
}) => {
  const options = useMemo(() => {
    const topCategories = sortBy(
      categories.filter(category => category.parent === null),
      'name'
    )

    let options = topCategories
    if (includeSubcategories) {
      for (let topCategory of topCategories) {
        const newOptions = [
          topCategory,
          ...topCategory.children.map(c => ({
            ...c,
            name: `${topCategory.name} - ${c.name}`,
          })),
        ]

        options = options.concat(newOptions)
      }
    }

    return options
  }, [categories, includeSubcategories])

  return (
    <Select
      isLoading={isFetching}
      labelKey="name"
      placeholder="Select category..."
      options={options}
      valueKey="id"
      {...props}
    />
  )
}
