import React from 'react'
import { Modal } from 'react-bootstrap'
import moment from 'moment-timezone'

import { markAsRead, remindMeLater } from './api'
import { useUnreadNewsNotifications, useAllNewsNotifications } from './hooks'
import IframelyContent from '../../infrastructure/components/IframelyContent'
import { NewsContent } from './NewsArchive'

const allOptions = { includes: ['news'] }

const News = ({ renderLink }) => {
  // Notifications
  const notificationOptions = React.useMemo(() => {
    return [
      {
        includes: ['news'],

        limit: 1,

        sort: [
          {
            key: 'created_at',
            direction: 'ASC',
          },
        ],
      },
    ]
  }, [])

  const [
    newsNotifications,
    isFetchingNewsNotifications,
    { refresh: refreshNewsNotifications },
  ] = useUnreadNewsNotifications(notificationOptions)
  const [allNewsNotifications] = useAllNewsNotifications(allOptions)
  const [activeNotification, setActiveNotification] = React.useState(null)
  const test = React.useRef(0)

  // Fetch notifications every five minutes

  React.useEffect(() => {
    const everyTwoHours = 1000 * 60 * 120

    const interval = setInterval(refreshNewsNotifications, everyTwoHours)

    return () => {
      if (!interval) {
        return
      }

      clearInterval(interval)
    }
  }, [])

  // Set active noticication

  React.useEffect(() => {
    if (activeNotification) {
      const notificationIds = newsNotifications.map(
        notification => notification.id
      )

      if (!notificationIds.includes(activeNotification.id)) {
        setActiveNotification(null)
        return
      }
    }

    if (
      isFetchingNewsNotifications ||
      !newsNotifications ||
      !newsNotifications.length
    ) {
      return
    }

    setActiveNotification(newsNotifications[0])
  }, [
    newsNotifications,
    setActiveNotification,
    isFetchingNewsNotifications,
    refreshNewsNotifications,
  ])

  const _remindMeLater = React.useCallback(() => {
    if (!activeNotification) {
      return
    }

    remindMeLater(activeNotification.id).then(() => {
      refreshNewsNotifications()
    })
  }, [activeNotification])

  const _markAsRead = React.useCallback(() => {
    if (!activeNotification) {
      return
    }

    markAsRead(activeNotification.id).then(() => {
      refreshNewsNotifications()
    })
  }, [activeNotification])

  // TOOD: its probably better just to change the API to return all
  // postponed news and unread news and then filter postponement in the client,
  // but we do this like this since a lot of people are on an old client
  const unreadNews = React.useMemo(
    () =>
      allNewsNotifications.filter(
        n =>
          n.read_at === null && moment(n.news.added_at).isSameOrBefore(moment())
      ),
    [allNewsNotifications]
  )

  return (
    <div>
      {activeNotification && (
        <Notification
          news={activeNotification.news}
          notification={activeNotification}
          _remindMeLater={_remindMeLater}
          _markAsRead={_markAsRead}
        />
      )}
      {renderLink({ unreadNews: unreadNews })}
    </div>
  )
}

export default News

const Notification = ({ news, notification, _remindMeLater, _markAsRead }) => {
  return (
    <Modal
      dialogClassName="xl-modal"
      show={true}
      onHide={() => {}}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>{news.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NewsContent style={{ overflowY: 'scroll', height: 500 }}>
          <IframelyContent content={news.description} />
        </NewsContent>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-white"
          onClick={_remindMeLater}
        >
          Remind me later
        </button>
        <button type="button" className="btn btn-primary" onClick={_markAsRead}>
          Mark as read
        </button>
      </Modal.Footer>
    </Modal>
  )
}
