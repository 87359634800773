/* @flow */

import * as React from 'react'
import { Link } from 'react-router-dom'

import ActionButton from '../../../infrastructure/components/ActionButton'
import { createWebshopButtonStyle, createStyledWebshopButton } from './shared'

import { WebshopContext } from '../shared'

const WebshopLinkButton = ({ color = 'white', ...props }) => {
  const { webshopDesignSettings } = React.useContext(WebshopContext)

  const combinedStyle = React.useMemo(() => {
    return createWebshopButtonStyle(color, webshopDesignSettings)
  }, [color, webshopDesignSettings])

  return <StyledLink theme={combinedStyle} {...props} />
}

export default WebshopLinkButton

const StyledLink = createStyledWebshopButton(Link)
