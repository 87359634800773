/* @flow */

import React, { Component } from 'react'
import styled from 'styled-components'

import { FormInput, RadioInput, SelectInput } from '../../Formik'
import { FieldGroup } from './shared'

import type { FilterOperator } from '../../../../app/types'

type Props = {
  operator: FilterOperator,
  value: Array<string>,
}

export default class NumericFilter extends Component<Props, void> {
  render() {
    const { operator, value } = this.props

    return (
      <div>
        <FieldGroup>
          <RadioInput
            id="filter_numeric_operator_eq"
            name="operator"
            label="equals"
            value="eq"
          />
          {operator === 'eq' && <FormInput name="value" />}
        </FieldGroup>

        <FieldGroup>
          <RadioInput
            id="filter_numeric_operator_lt"
            name="operator"
            label="lesser than"
            value="lt"
          />
          {operator === 'lt' && <FormInput name="value" />}
        </FieldGroup>

        <FieldGroup>
          <RadioInput
            id="filter_numeric_operator_gt"
            name="operator"
            label="greater than"
            value="gt"
          />
          {operator === 'gt' && <FormInput name="value" />}
        </FieldGroup>

        <FieldGroup>
          <RadioInput
            id="filter_numeric_operator_bt"
            name="operator"
            label="between"
            value="bt"
          />
          {operator === 'bt' && (
            <>
              <FormInput name="value[0]" />
              <FormInput name="value[1]" />
            </>
          )}
        </FieldGroup>

        <FieldGroup>
          <RadioInput
            id="filter_numeric_operator_no_value"
            name="operator"
            label="no value"
            value="no_value"
          />
        </FieldGroup>
      </div>
    )
  }
}
