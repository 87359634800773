import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  deleteTimelineEvent,
  fetchTimelineEvents,
  fetchTimelineEventTypes,
  fetchTimelineTotals,
} from '../actions/timeline'
import { List as ImmutableList, Map } from 'immutable'
import moment from 'moment'
import CloudinaryResource from './CloudinaryResource'
import FormatCurrency from './FormatCurrency'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Globalize from '../modules/globalize'
import { Link } from 'react-router-dom'
import StockLabel from '../bindings/stock-label'
import Select from 'react-select'
// Should be a universal thing
import { createAction } from '../../app/products/components/ProductTable/shared'
import { IsBrandPredicate } from '../../app/products/components/ProductTable/actions'

import { renderDate } from '../../app/shared'

const formatDate = Globalize.dateFormatter({ date: 'short' })
const formatTime = Globalize.dateFormatter({ time: 'short' })

class Timeline extends Component {
  constructor(props) {
    super(props)

    this.state = {
      deleteEvent: null,
      fetching: false,
      error: null,
      loaded: null,
      rows: ImmutableList(),
      showDeleteModal: false,
      types: [],
      type: null,
      total: null,
    }

    this.deleteEvent = this.deleteEvent.bind(this)
    this.loadMoreRows = this.loadMoreRows.bind(this)
    this.onTypeFilterChange = this.onTypeFilterChange.bind(this)
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this)
  }

  componentDidMount() {
    const { context, dispatch, filterMode, filteredEvents, setRef, type } =
      this.props

    if (setRef) {
      setRef(this)
    }

    const eventTypesPromise = dispatch(
      fetchTimelineEventTypes(context, filterMode, filteredEvents)
    )
    const totalsPromise = dispatch(
      fetchTimelineTotals(context, type, filterMode, filteredEvents)
    )

    Promise.all([eventTypesPromise, totalsPromise]).then(([types, totals]) => {
      const changes = {}

      if (!types.error && !totals.error) {
        changes.types = types.payload.types
        changes.total = totals.payload.total
      }

      this.setState(changes, this.loadMoreRows)
    })
  }

  componentWillReceiveProps(nextProps) {
    const changes = {}

    let contextChanged = false
    for (let [key, value] of Object.entries(nextProps.context)) {
      if (this.props.context[key] !== value) {
        contextChanged = true
      }
    }

    if (
      (this.state.stale !== nextProps.stale && nextProps.stale === true) ||
      nextProps.filterMode !== this.props.filterMode ||
      nextProps.filteredEvents !== this.props.filteredEvents ||
      contextChanged === true
    ) {
      this.reset(this.state.type)
    }
  }

  deleteEvent(event) {
    this.props.dispatch(deleteTimelineEvent(event.id)).then(response => {
      if (!response.error) {
        this.reset(this.state.type)
        this.toggleDeleteModal()
      }
    })
  }

  loadMoreRows() {
    const { chunk, chunkSize, context, filterMode, filteredEvents } = this.props
    const { loaded, type } = this.state

    this.setState({
      fetching: true,
    })

    const startIndex = loaded / chunkSize

    return this.props
      .dispatch(
        fetchTimelineEvents(
          context,
          startIndex,
          chunkSize,
          type,
          filterMode,
          filteredEvents
        )
      )
      .then(response => {
        const changes = {
          fetching: false,
        }

        if (!response.error) {
          const events = response.payload.events

          changes.loaded = this.state.loaded + events.length
          changes.rows = generateRows(events, this.state.rows)
        } else {
          changes.error = 'An error occurred loading more events.'
        }

        this.setState(changes)
      })
  }

  onTypeFilterChange(newType) {
    this.reset(newType)
  }

  reset(type) {
    this.setState(
      {
        loaded: null,
        rows: ImmutableList(),
        type: type,
        total: null,
      },
      () => {
        this.props
          .dispatch(
            fetchTimelineTotals(
              this.props.context,
              type,
              this.props.filterMode,
              this.props.filteredEvents
            )
          )
          .then(response => {
            if (!response.error) {
              this.setState(
                {
                  total: response.payload.total,
                },
                this.loadMoreRows
              )
            }
          })
      }
    )
  }

  toggleDeleteModal(event) {
    this.setState({
      deleteEvent: !this.state.showDeleteModal ? event : null,
      showDeleteModal: !this.state.showDeleteModal,
    })
  }

  render() {
    const {
      actions,
      className,
      chunk,
      context,
      filterable,
      height,
      image,
      session,
      title,
    } = this.props
    const {
      deleteEvent,
      error,
      fetching,
      loaded,
      rows,
      showDeleteModal,
      total,
      type,
      types,
    } = this.state

    if (error) {
      return <div className="alert alert-info">{error}</div>
    }

    let header, titleElement, filterSelect
    if (title || filterable || actions.length > 0) {
      if (title) {
        titleElement =
          typeof title === 'string' ? <Title>{title}</Title> : title
      }

      if (filterable) {
        const options = Object.keys(EventLabels)
          .filter(key => types.indexOf(key) !== -1)
          .map(key => ({
            label: EventLabels[key],
            value: key,
          }))

        filterSelect = (
          <Select
            placeholder="Show all events"
            onChange={this.onTypeFilterChange}
            options={options}
            simpleValue
            value={this.state.type}
          />
        )
      }

      header = (
        <HeaderContainer>
          <TitleContainer>{titleElement}</TitleContainer>
          <FilterContainer className="react-select--small">
            {filterSelect}
          </FilterContainer>
          <ActionsContainer>
            {actions.map(Action => (
              <Action.Component
                callbackVars={[this]}
                context={context}
                session={session}
              />
            ))}
          </ActionsContainer>
        </HeaderContainer>
      )
    }

    const loading = total === null || loaded === null
    const containerStyles = {}
    if (height) {
      containerStyles.maxHeight = height
      containerStyles.overflow = 'auto'
    }

    return (
      <div className={className}>
        <Modal show={showDeleteModal} onHide={this.toggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Remove Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to remove the event?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-white"
              onClick={this.toggleDeleteModal}
              style={{ marginRight: 10 }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => this.deleteEvent(deleteEvent)}
            >
              Yes, delete it!
            </button>
          </Modal.Footer>
        </Modal>
        {header}
        {loading && <div>Loading...</div>}
        {!loading && (
          <div style={containerStyles}>
            {rows.map((row, i) => {
              if (row.type === 'date') {
                return (
                  <DateRow key={i}>
                    {formatDate(row.data.date.toDate())}
                  </DateRow>
                )
              }
              if (row.type === 'events') {
                return (
                  <EventsRow
                    key={i}
                    deleteEvent={this.toggleDeleteModal}
                    events={row.data.events}
                    image={image}
                  />
                )
              }
            })}
            {total > loaded && (
              <LoadMoreButton
                type="button"
                className="btn btn-white btn-block"
                disabled={fetching}
                onClick={this.loadMoreRows}
              >
                <span className="glyphicon glyphicon-plus" /> Load more
              </LoadMoreButton>
            )}
          </div>
        )}
      </div>
    )
  }
}

Timeline.propTypes = {
  actions: PropTypes.array,
  chunkSize: PropTypes.number,
  chunk: PropTypes.bool,
  className: PropTypes.string,
  context: PropTypes.object,
  filterMode: PropTypes.string,
  filteredEvents: PropTypes.string,
  image: PropTypes.bool,
  filterable: PropTypes.bool,
  height: PropTypes.number.isRequired,
  setRef: PropTypes.func,
  stale: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

Timeline.defaultProps = {
  actions: [],
  className: '',
  chunkSize: 100,
  chunk: false,
  context: {},
  image: true,
  filterable: true,
  height: null,
  setRef: () => {},
  stale: false,
  title: null,
}

export default connect(state => ({
  session: state.session,
}))(Timeline)

const generateRows = (events, currentState) => {
  let currentDate = null
  let currentEvent = null
  let currentUser = null
  let currentApp
  const lastRow = currentState.last()

  if (lastRow && lastRow.type === 'events') {
    const lastEvent = lastRow.data.events[lastRow.data.events.length - 1]
    currentDate = lastEvent.created_at
    currentEvent = lastEvent.event_type
    currentUser = lastEvent.user.id
    currentApp = lastEvent.app
  }

  return events.reduce((rows, event) => {
    // Some times the user has been deleted
    if (!event.user) {
      return rows
    }

    const date = moment(event.created_at)
    const isSameDateAsPreviousRow = date.isSame(currentDate, 'day')
    const isSameEventTypeAsPreviousRow = currentEvent === event.event_type
    const isSameUserAsPreviousRow = currentUser === event.user.id
    const isSameAppAsPreviousRow = currentApp === event.app
    const isFoldable = event.foldable === true

    currentDate = event.created_at
    currentEvent = event.event_type
    currentUser = event.user.id
    currentApp = event.app

    if (!isSameDateAsPreviousRow) {
      rows = rows.push({
        data: {
          date,
        },
        type: 'date',
      })

      rows = rows.push({
        data: {
          events: [event],
          eventIds: [event.id],
        },
        type: 'events',
      })
    } else if (
      !isSameEventTypeAsPreviousRow ||
      !isSameUserAsPreviousRow ||
      !isSameAppAsPreviousRow ||
      !isFoldable
    ) {
      rows = rows.push({
        data: {
          events: [event],
          eventIds: [event.id],
        },
        type: 'events',
      })
    } else {
      const lastIndex = rows.count() - 1
      const lastRow = rows.last()
      lastRow.data.events.push(event)
      lastRow.data.eventIds.push(event.id)
    }

    return rows
  }, currentState)
}

const HeaderContainer = styled.div`
  display: flex;
`

const TitleContainer = styled.div`
  flex: 1;
`

const FilterContainer = styled.div`
  min-width: 175px;
  max-width: 250px;
`

const ActionsContainer = styled.div`
  margin-left: 10px;
`

const LoadMoreButton = styled.button`
  display: block;
  margin: 25px 0;
`

const Row = styled.div`
  align-items: center;
  border-top: 1px solid #e8edf1;
  display: flex;
  min-height: 40px;
  padding: 0 12px;
`

const DateRow = styled(Row)`
  min-height: 30px;
`

const EventRow = styled(Row)``

const SubEventRow = styled.div`
  align-items: center;
  display: flex;
  margin: 4px 0;
  min-height: 30px;
  padding: 0 12px;
`

const SubEventsContainer = styled.div`
  border-top: 1px solid #e8edf1;
`

class EventsRow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  render() {
    const { expanded, image } = this.state
    const { deleteEvent, events } = this.props

    if (events.length === 1) {
      const event = events[0]

      return (
        <EventHeadline
          image={image}
          deleteEvent={() => deleteEvent(event)}
          event={event}
        />
      )
    }

    const subEvents = events

    return (
      <div>
        <EventHeadline
          event={events[0]}
          expandEvent={this.toggle}
          expanded={expanded}
          image={image}
          subEvents={subEvents}
        />
        {expanded && (
          <SubEventsContainer>
            {subEvents.map(event => (
              <EventSubRow
                deleteEvent={() => deleteEvent(event)}
                key={event.id}
                event={event}
                image={image}
              />
            ))}
          </SubEventsContainer>
        )}
      </div>
    )
  }
}

const EventHeadline = ({
  deleteEvent,
  event,
  expandEvent,
  expanded,
  image,
  subEvents,
}) => {
  const { app, data, deletable, sub_user_name, user } = event

  const renderer = renderers[event.event_type]

  if (!renderer) {
    return null
  }

  let nameLabelRender
  let nameTooltip

  if (app) {
    nameLabelRender = <strong>{app} </strong>
  } else {
    let name = user.firstname
    let nameLabel = user.firstname
    if (user.lastname) {
      name += ' ' + user.lastname
    }

    if (sub_user_name) {
      name = sub_user_name + ` (${name})`
      nameLabel = sub_user_name + ` (${nameLabel})`
    }

    nameTooltip = <Tooltip id={`timeline-tooltip-${name}`}>{name}</Tooltip>

    nameLabelRender = (
      <Link key={user.id} to={`/users/edit/${user.id}`}>
        {nameLabel}&nbsp;
      </Link>
    )
  }

  const EventTitle = [nameLabelRender]

  const renderedEvent = expandEvent
    ? renderer.plural(data, expandEvent, subEvents, event)
    : renderer.singular(data, event)

  EventTitle.push(
    React.cloneElement(renderedEvent, {
      key: `rendered-event-${event.id}`,
    })
  )

  if (!expandEvent && deletable) {
    EventTitle.push(
      <span>
        &nbsp;
        <EventLink key={`${event.id}-delete-link`} onClick={deleteEvent}>
          (delete)
        </EventLink>
      </span>
    )
  }

  return (
    <EventRow>
      {/*<EventIcon
        color={renderer.color}
        icon={renderer.icon}
        noMargin={!image}
      />*/}
      <EventText>
        {/* The div will ensure the text wraps the nameLabel */}
        <div>{EventTitle}</div>
      </EventText>
      <EventTime>
        {expandEvent && !expanded && (
          <span className="glyphicon glyphicon-plus" />
        )}
        {expandEvent && expanded && (
          <span className="glyphicon glyphicon-minus" />
        )}
        {!expandEvent && formatTime(moment(event.created_at).toDate())}
      </EventTime>
    </EventRow>
  )
}

EventHeadline.propTypes = {
  deleteEvent: PropTypes.func,
  event: PropTypes.object.isRequired,
  expandedEvent: PropTypes.func,
}

const EventSubRow = ({ deleteEvent, event, image }) => {
  const renderer = renderers[event.event_type]

  if (!renderer) {
    return null
  }

  return (
    <SubEventRow>
      {/*<EventIcon noMargin={!image} />*/}
      <EventText sub>{renderer.subRow(event.data, event)}</EventText>
      <EventTime>{formatTime(moment(event.created_at).toDate())}</EventTime>
    </SubEventRow>
  )
}

const ProfilePictureContainer = styled.div`
  height: 35px;
  width: 35px;

  @media (max-width: 1440px) {
    display: none;
  }
`

const COLOR_SUCCESS = '#f6f6f6'
const COLOR_ATTENTION = '#f6f6f6'
const COLOR_DANGER = '#f6f6f6'

const IconColorProps = PropTypes.oneOf([
  COLOR_SUCCESS,
  COLOR_DANGER,
  COLOR_ATTENTION,
])

const commentsToolTip = (
  <Tooltip id="comments_tooltip">Visible for retailer</Tooltip>
)

const EventIcon = ({ color, icon, noMargin }) => (
  <EventIconColumn noMargin={noMargin}>
    {icon && (
      <EventIconContainer color={color}>
        <span className={icon} />
      </EventIconContainer>
    )}
  </EventIconColumn>
)

EventIcon.propTypes = {
  color: IconColorProps,
  icon: PropTypes.string,
}

const EventIconContainer = styled.div`
  align-items: center;
  border-radius: 100px;
  background: ${props => (props.color ? props.color : '#f6f6f6')};
  color: #bababa;
  display: flex;
  flex: 1;
  font-size: 10px;
  justify-content: center;
  height: 24px;
  z-index: 1;

  > span {
    top: 0;
  }
`

EventIconContainer.propTypes = {
  color: IconColorProps,
}

const EventIconColumn = styled.div`
  align-items: center;
  display: flex;
  min-height: 100%;
  position: relative;
  margin: ${({ noMargin }) => (noMargin ? 0 : '0 0 0 20px')};
  width: 24px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -2px;
    width: 3px;
    height: 100%;
    background: #dbe0ea;
  }

  @media (max-width: 1260px) {
    display: none;
  }

  @media (max-width: 1440px) {
    margin: 0;
  }
`

const EventText = styled.div`
  display: flex;
  flex: 1;
  padding-left: ${props => (props.sub ? '15px' : '10px')};

  @media (max-width: 1260px) {
    padding-left: 0;
  }
`

EventText.propTypes = {
  sub: PropTypes.bool,
}

EventText.defaultProps = {
  sub: false,
}

const EventTime = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 60px;

  @media (max-width: 1440px) {
    display: none;
  }
`

const Title = styled.h2`
  font-size: 22px;
  margin-top: 0;
`

const createRenderer = (singular, plural, subRow, icon, color) => {
  if (!singular) {
    console.log('Timeline: No singular renderer found for event type')
  }

  if (!plural) {
    console.log('Timeline: No plural renderer found for event type')
  }

  if (!subRow) {
    console.log('Timeline: No subRow renderer found for event type')
  }

  return {
    singular,
    plural,
    subRow,
    icon,
    color,
  }
}

export const renderers = {
  'accounting.years.closed': createRenderer(
    (data, event) => (
      <span>closed {CreateAccountingYearLink(data, event)}</span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        <ExpandLink onClick={expand}>
          closed {subEvents.length} accounting years
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>closed {CreateAccountingYearLink(data, event)}</span>
    ),
    'glyphicon glyphicon-calendar'
  ),
  'accounting.years.reopened': createRenderer(
    (data, event) => (
      <span>reopened {CreateAccountingYearLink(data, event)}</span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        <ExpandLink onClick={expand}>
          reopened {subEvents.length} accounting years
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>reopened {CreateAccountingYearLink(data, event)}</span>
    ),
    'glyphicon glyphicon-calendar'
  ),
  'accounting.years.period_closed': createRenderer(
    (data, event) => (
      <span>
        closed {CreateAccountingYearLink(data, event)} period{' '}
        {renderDate(data.period_from_date)} - {renderDate(data.period_to_date)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        <ExpandLink onClick={expand}>
          closed {subEvents.length} accounting year periods
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        closed {CreateAccountingYearLink(data, event)} period{' '}
        {renderDate(data.period_from_date)} - {renderDate(data.period_to_date)}
      </span>
    ),
    'glyphicon glyphicon-calendar'
  ),
  'accounting.years.period_reopened': createRenderer(
    (data, event) => (
      <span>
        reopened {CreateAccountingYearLink(data, event)} period{' '}
        {renderDate(data.period_from_date)} - {renderDate(data.period_to_date)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        <ExpandLink onClick={expand}>
          reopened {subEvents.length} accounting year periods
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        reopened {CreateAccountingYearLink(data, event)} period{' '}
        {renderDate(data.period_from_date)} - {renderDate(data.period_to_date)}
      </span>
    ),
    'glyphicon glyphicon-calendar'
  ),
  'c5.order_pushed': createRenderer(
    (data, event) => <span>sent {CreateOrderLink(data, event)} to C5</span>,
    (data, expand, subEvents, event) => (
      <span>
        sent{' '}
        <ExpandLink onClick={expand}>
          order to C5 {subEvents.length} times
        </ExpandLink>
      </span>
    ),
    (data, event) => <span>sent {CreateOrderLink(data, event)} to C5</span>,
    'glyphicon glyphicon-send',
    COLOR_SUCCESS
  ),
  'customer.first_login': createRenderer(
    (data, event) => (
      <span>
        from {CreateCustomerLink(data, event)} logged in for the first time
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        <ExpandLink onClick={expand}>logged in for the first time</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreateCustomerLink(data, event)} logged in for the first time
      </span>
    ),
    'glyphicon glyphicon-star',
    COLOR_SUCCESS
  ),
  'customer.state_change': createRenderer(
    (data, event) => (
      <span>
        {data.status === true ? 'activated' : 'deactivated'}{' '}
        {CreateCustomerLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        changed customers' status{' '}
        <ExpandLink onClick={expand}>{subEvents.length} times</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {data.status === true ? 'Activated' : 'Deactivated'}{' '}
        {CreateCustomerLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-pencil'
  ),
  'customer.agents_updated': createRenderer(
    (data, event) => (
      <span>
        updated agents of {CreateCustomerLink(data, event)} from{' '}
        {CreateAgentsListString(data.old_agents)} to{' '}
        <strong>{CreateAgentsListString(data.new_agents)}</strong>
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated customers' agents{' '}
        <ExpandLink onClick={expand}>{subEvents.length} times</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        updated agents of {CreateCustomerLink(data, event)} from{' '}
        {CreateAgentsListString(data.old_agents)} to{' '}
        <strong>{CreateAgentsListString(data.new_agents)}</strong>
      </span>
    ),
    'glyphicon glyphicon-pencil'
  ),
  'customer.updated': createRenderer(
    (data, event, options = {}) => {
      return (
        <span>
          {data.changes.length == 1 && (
            <span>updated {CreateCustomerLink(data, event)}: </span>
          )}
          {data.changes.length > 1 && (
            <span>
              updated {CreateCustomerLink(data, event)} multiple fields
            </span>
          )}
          {CreateGenericUpdatesTable(data.changes)}
        </span>
      )
    },
    (data, expand, subEvents, event) => (
      <span>
        <ExpandLink onClick={expand}>
          updated customer {CreateCustomerLink(data, event)} {subEvents.length}{' '}
          times
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {data.changes.length == 1 && (
          <span>updated {CreateCustomerLink(data, event)}: </span>
        )}
        {data.changes.length > 1 && (
          <span>updated {CreateCustomerLink(data, event)} multiple fields</span>
        )}
        {CreateGenericUpdatesTable(data.changes)}
      </span>
    ),
    'glyphicon glyphicon-pencil'
  ),
  'customer.addresses_merged': createRenderer(
    (data, event) => (
      <span>
        merged addresses of {CreateCustomerLink(data, event)}: merged{' '}
        {data?.merge_addresses ? data?.merge_addresses.join(', ') : ''} into{' '}
        {data?.receiving_address}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        merged addresses of{' '}
        <ExpandLink onClick={expand}>{subEvents.length} customer(s)</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        merged addresses of {CreateCustomerLink(data, event)}: {data?.addresses}
      </span>
    ),
    'glyphicon glyphicon-home'
  ),
  'customer.address_created': createRenderer(
    (data, event) => (
      <span>
        added address {data?.address_title || data?.address_name || ''} to{' '}
        {CreateCustomerLink(data, event)}: {data?.address}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        added address to{' '}
        <ExpandLink onClick={expand}>{subEvents.length} customer(s)</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        added address {data?.address_title || data?.address_name || ''} to{' '}
        {CreateCustomerLink(data, event)}: {data?.address}
      </span>
    ),
    'glyphicon glyphicon-home'
  ),
  'customer.address_deleted': createRenderer(
    (data, event) => (
      <span>
        deleted address {data?.address_title || data?.address_name || ''} from{' '}
        {CreateCustomerLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        deleted address from{' '}
        <ExpandLink onClick={expand}>{subEvents.length} customer(s)</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        deleted address {data?.address_title || data?.address_name || ''} from{' '}
        {CreateCustomerLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-home'
  ),
  'customer.address_updated': createRenderer(
    (data, event, options = {}) => {
      return (
        <span>
          <span>
            updated {CreateCustomerLink(data, event)} address{' '}
            <strong>{data?.address_title || data?.address_name || ''}</strong>:{' '}
          </span>
          {CreateCustomerAddressUpdatesTable(data.changes)}
        </span>
      )
    },
    (data, expand, subEvents, event) => (
      <span>
        <ExpandLink onClick={expand}>
          updated {CreateCustomerLink(data, event)} addresse(s):{' '}
          {subEvents.length} times
        </ExpandLink>
      </span>
    ),
    (data, event, options = {}) => (
      <span>
        <span>
          updated address{' '}
          <strong>{data?.address_title || data?.address_name || ''}</strong>:{' '}
        </span>
        {CreateCustomerAddressUpdatesTable(data.changes)}
      </span>
    ),
    'glyphicon glyphicon-pencil'
  ),
  'inventory.updated': createRenderer(
    (data, event) => (
      <span>
        updated <StockLabel type={data.type} /> inventory for{' '}
        {data.product_name} ({data.attributes}) from {data.old_quantity} to{' '}
        <strong>{data.new_quantity}</strong>
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated{' '}
        <ExpandLink onClick={expand}>
          inventory for {subEvents.length} products
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Updated <StockLabel type={data.type} /> inventory for{' '}
        {data.product_name} ({data.attributes}) from {data.old_quantity} to{' '}
        <strong>{data.new_quantity}</strong>
      </span>
    ),
    'glyphicon glyphicon-list-alt'
  ),
  'navision.order_pushed': createRenderer(
    (data, event) => <span>sent {CreateOrderLink(data, event)} to NAV</span>,
    (data, expand, subEvents, event) => (
      <span>
        sent{' '}
        <ExpandLink onClick={expand}>
          order to NAV {subEvents.length} times
        </ExpandLink>
      </span>
    ),
    (data, event) => <span>sent {CreateOrderLink(data, event)} to NAV</span>,
    'glyphicon glyphicon-send',
    COLOR_SUCCESS
  ),
  'order.agents_updated': createRenderer(
    (data, event) => (
      <span>
        updated agents of {CreateOrderLink(data, event)} from{' '}
        {CreateAgentsListString(data.old_agents)} to{' '}
        <strong>{CreateAgentsListString(data.new_agents)}</strong>
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated orders' agents{' '}
        <ExpandLink onClick={expand}>{subEvents.length} times</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        updated agents of {CreateOrderLink(data, event)} from{' '}
        {CreateAgentsListString(data.old_agents)} to{' '}
        <strong>{CreateAgentsListString(data.new_agents)}</strong>
      </span>
    ),
    'glyphicon glyphicon-pencil'
  ),
  'order.comment_created': createRenderer(
    (data, event) => (
      <span>
        {CreateOrderLink(data, event)}: <strong>{data.comment}</strong>{' '}
        {event.visibility.shop_id && (
          <OverlayTrigger placement="top" overlay={commentsToolTip}>
            <span className="fa fa-eye" />
          </OverlayTrigger>
        )}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        added{' '}
        <ExpandLink onClick={expand}>{subEvents.length} comments</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreateOrderLink(data, event)}: {data.comment}
      </span>
    ),
    'fa fa-comment',
    COLOR_ATTENTION
  ),
  'order.sent_cancellation_notification': createRenderer(
    (data, event) => (
      <span>
        send order cancellation email to {data.email} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        sent{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} order cancellation emails
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreateOrderLink(data, event)}: sent cancellation email to {data.email}
      </span>
    ),
    'fa fa-envelope'
  ),
  'order.archived': createRenderer(
    (data, event) => <span>archived {CreateOrderLink(data, event)}</span>,
    (data, expand, subEvents, event) => (
      <span>
        archived{' '}
        <ExpandLink onClick={expand}>{subEvents.length} orders</ExpandLink>
      </span>
    ),
    (data, event) => <span>{CreateOrderLink(data, event)}</span>,
    'glyphicon glyphicon-folder-close',
    COLOR_SUCCESS
  ),
  'order.unarchived': createRenderer(
    (data, event) => <span>unarchived {CreateOrderLink(data, event)}</span>,
    (data, expand, subEvents, event) => (
      <span>
        unarchived{' '}
        <ExpandLink onClick={expand}>{subEvents.length} orders</ExpandLink>
      </span>
    ),
    (data, event) => <span>{CreateOrderLink(data, event)}</span>,
    'glyphicon glyphicon-folder-open'
  ),
  'order.cancelled': createRenderer(
    (data, event) => <span>cancelled {CreateOrderLink(data, event)}</span>,
    (data, expand, subEvents, event) => (
      <span>
        cancelled{' '}
        <ExpandLink onClick={expand}>{subEvents.length} orders</ExpandLink>
      </span>
    ),
    (data, event) => <span>{CreateOrderLink(data, event)}</span>,
    'glyphicon glyphicon-folder-close',
    COLOR_DANGER
  ),
  'order.approved': createRenderer(
    (data, event) => <span>approved {CreateOrderLink(data, event)}</span>,
    (data, expand, subEvents, event) => (
      <span>
        approved{' '}
        <ExpandLink onClick={expand}>{subEvents.length} orders</ExpandLink>
      </span>
    ),
    (data, event) => <span>{CreateOrderLink(data, event)}</span>,
    'glyphicon glyphicon-ok',
    COLOR_SUCCESS
  ),
  'order.created': createRenderer(
    (data, event) => <span>created {CreateOrderLink(data, event)}</span>,
    (data, expand, subEvents, event) => (
      <span>
        created{' '}
        <ExpandLink onClick={expand}>{subEvents.length} orders</ExpandLink>
      </span>
    ),
    (data, event) => <span>{CreateOrderLink(data, event)}</span>,
    'glyphicon glyphicon-folder-open',
    COLOR_SUCCESS
  ),
  'order.changed_inventory_location': createRenderer(
    (data, event) => (
      <span>
        changed inventory location of {CreateOrderLink(data, event)} to{' '}
        {data.target_location}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        change inventory location of{' '}
        <ExpandLink onClick={expand}>{subEvents.length} orders</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        change inventory location of {CreateOrderLink(data, event)} to{' '}
        {data.target_location}
      </span>
    ),
    'fa fa-truck'
  ),
  'production_order.created_production_orders': createRenderer(
    (data, event) => (
      <span>created {CreateProductionOrderLink(data, event)}</span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        created{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} production orders
        </ExpandLink>
      </span>
    ),
    (data, event) => <span>{CreateProductionOrderLink(data, event)}</span>,
    'glyphicon glyphicon-folder-open',
    COLOR_SUCCESS
  ),
  'production_order.created_lines': createRenderer(
    (data, event) => (
      <span>
        added {data.quantity} pieces of #{data.product} (SKU{' '}
        {data.production_order_line_sku}) to{' '}
        {CreateProductionOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        added{' '}
        <ExpandLink onClick={expand}>{subEvents.length} products</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        added {data.quantity} pieces of #{data.product} (SKU{' '}
        {data.production_order_line_sku}) to{' '}
        {CreateProductionOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-shopping-cart',
    COLOR_SUCCESS
  ),
  'production_order.deleted_lines': createRenderer(
    (data, event) => (
      <span>
        deleted {data.quantity} pieces of #{data.product} (SKU{' '}
        {data.production_order_line_sku}) to{' '}
        {CreateProductionOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        deleted{' '}
        <ExpandLink onClick={expand}>{subEvents.length} products</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        deleted {data.quantity} pieces of #{data.product} (SKU{' '}
        {data.production_order_line_sku}) to{' '}
        {CreateProductionOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-shopping-cart',
    COLOR_SUCCESS
  ),
  'production_order.updated_lines': createRenderer(
    (data, event) => (
      <span>
        {CreateVariantQuantityChangeString(data)} for{' '}
        {CreateProductionOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated{' '}
        <ExpandLink onClick={expand}>{subEvents.length} variants</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreateVariantQuantityChangeString(data)} for{' '}
        {CreateProductionOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-shopping-cart',
    COLOR_SUCCESS
  ),
  'production_order.production_order_expected_delivery_date_change':
    createRenderer(
      (data, event) => (
        <span>
          Expected arrival (ETA) changed for{' '}
          {CreateProductionOrderLink(data, event)} from{' '}
          <strong>
            {data.old_delivery_date
              ? renderDate(data.old_delivery_date)
              : 'No date'}
          </strong>{' '}
          to{' '}
          <strong>
            {data.new_delivery_date
              ? renderDate(data.new_delivery_date)
              : 'No date'}
          </strong>
        </span>
      ),
      (data, expand, subEvents, event) => (
        <span>
          Expected arrival (ETA){' '}
          <ExpandLink onClick={expand}>{subEvents.length} changes</ExpandLink>{' '}
          for {CreateProductionOrderLink(data, event)}
        </span>
      ),
      (data, event) => (
        <span>
          Expected arrival (ETA) changed for{' '}
          {CreateProductionOrderLink(data, event)} from{' '}
          <strong>
            {data.old_delivery_date
              ? renderDate(data.old_delivery_date)
              : 'No date'}
          </strong>{' '}
          to{' '}
          <strong>
            {data.new_delivery_date
              ? renderDate(data.new_delivery_date)
              : 'No date'}
          </strong>
        </span>
      ),
      'fa fa-truck'
    ),
  'production_order.sent_confirmation': createRenderer(
    (data, event) => (
      <span>
        sent production order confirmation email to {data.email} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        sent{' '}
        <ExpandLink onClick={expand}>production order confirmations</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Sent order confirmation to {data.email} for{' '}
        {CreateProductionOrderLink(data, event)}
      </span>
    ),
    'fa fa-envelope',
    COLOR_SUCCESS
  ),
  'production_order.updated_inventory_location': createRenderer(
    (data, event) => (
      <span>
        changed inventory location of {CreateProductionOrderLink(data, event)}{' '}
        from {data.previous_location_name} to {data.new_location_name}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated inventory location on{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} production orders
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        changed inventory location of {CreateProductionOrderLink(data, event)}{' '}
        from {data.previous_location_name} to {data.new_location_name}
      </span>
    ),
    'glyphicon glyphicon-shopping-cart',
    COLOR_SUCCESS
  ),

  // PO delivery note
  'production_order_delivery_note.mark_as_sent': createRenderer(
    (data, event) => (
      <span>
        marked {CreateProductionOrderDeliveryNoteLink(data, event)} as sent
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        marked{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} production order delivery notes as sent
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        marked {CreateProductionOrderDeliveryNoteLink(data, event)} as sent
      </span>
    ),
    'glyphicon glyphicon-envelope',
    COLOR_SUCCESS
  ),
  'production_order_delivery_note.received': createRenderer(
    (data, event) => (
      <span>received {CreateProductionOrderDeliveryNoteLink(data, event)}</span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        received{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} production order delivery notes
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>received {CreateProductionOrderDeliveryNoteLink(data, event)}</span>
    ),
    'glyphicon glyphicon-download',
    COLOR_SUCCESS
  ),
  'production_order_delivery_note.unreceived': createRenderer(
    (data, event) => (
      <span>
        unreceived {CreateProductionOrderDeliveryNoteLink(data, event)} (
        {data.comment})
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        unreceived{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} production order delivery notes
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        unreceived {CreateProductionOrderDeliveryNoteLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-upload',
    COLOR_ATTENTION
  ),
  'production_order_delivery_note.booked': createRenderer(
    (data, event) => (
      <span>booked {CreateProductionOrderDeliveryNoteLink(data, event)}</span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        booked{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} production order delivery notes
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>booked {CreateProductionOrderDeliveryNoteLink(data, event)}</span>
    ),
    'glyphicon glyphicon-usd',
    COLOR_SUCCESS
  ),
  'production_order_delivery_note.updated_inventory_location': createRenderer(
    (data, event) => (
      <span>
        changed inventory location of{' '}
        {CreateProductionOrderDeliveryNoteLink(data, event)} from{' '}
        {data.previous_location_name} to {data.new_location_name}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated inventory location on{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} production order delivery notes
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        changed inventory location of{' '}
        {CreateProductionOrderDeliveryNoteLink(data, event)} from{' '}
        {data.previous_location_name} to {data.new_location_name}
      </span>
    ),
    'glyphicon glyphicon-shopping-cart',
    COLOR_SUCCESS
  ),

  'order.b2c_created': createRenderer(
    (data, event) => (
      <span>
        imported {CreateOrderLink(data, event)} from{' '}
        {CreateOrderSourceLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        imported{' '}
        <ExpandLink onClick={expand}>{subEvents.length} B2C orders</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreateOrderLink(data, event)} from {CreateOrderSourceLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-folder-open',
    COLOR_SUCCESS
  ),
  'order.order_estimated_delivery_date_change': createRenderer(
    (data, event) => (
      <span>
        Estimated departure (ETD) changed for {CreateOrderLink(data, event)}{' '}
        from{' '}
        <strong>
          {data.old_estimated_delivery_date
            ? renderDate(data.old_estimated_delivery_date)
            : 'No date'}
        </strong>{' '}
        to{' '}
        <strong>
          {data.new_estimated_delivery_date
            ? renderDate(data.new_estimated_delivery_date)
            : 'No date'}
        </strong>
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        Estimated departure (ETD){' '}
        <ExpandLink onClick={expand}>{subEvents.length} changes</ExpandLink> for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, event) => (
      <span>
        Estimated departure (ETD) changed for {CreateOrderLink(data, event)}{' '}
        from{' '}
        <strong>
          {data.old_estimated_delivery_date
            ? renderDate(data.old_estimated_delivery_date)
            : 'No date'}
        </strong>{' '}
        to{' '}
        <strong>
          {data.new_estimated_delivery_date
            ? renderDate(data.new_estimated_delivery_date)
            : 'No date'}
        </strong>
      </span>
    ),
    'fa fa-truck'
  ),
  'order.reopened_because_exchange_return_approved': createRenderer(
    (data, event) => (
      <span>
        reopened {CreateOrderLink(data, event)}, because of approved{' '}
        {CreateReturnLink(data, event)} with exchange:{' '}
        {CreateReturnExchangesString(data.exchanges)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        reopened order, because of approved return{' '}
        <ExpandLink onClick={expand}>{subEvents.length} times</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Reopened {CreateOrderLink(data, event)}, because of approved{' '}
        {CreateReturnLink(data, event)} with exchange:{' '}
        {CreateReturnExchangesString(data.exchanges)}
      </span>
    ),
    'glyphicon glyphicon-refresh',
    COLOR_SUCCESS
  ),
  'order.reopened_because_exchange_return_received': createRenderer(
    (data, event) => (
      <span>
        reopened {CreateOrderLink(data, event)}, because of received{' '}
        {CreateReturnLink(data, event)} with exchange:{' '}
        {CreateReturnExchangesString(data.exchanges)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        reopened order, because of received return{' '}
        <ExpandLink onClick={expand}>{subEvents.length} times</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Reopened {CreateOrderLink(data, event)}, because of received{' '}
        {CreateReturnLink(data, event)} with exchange:{' '}
        {CreateReturnExchangesString(data.exchanges)}
      </span>
    ),
    'glyphicon glyphicon-refresh',
    COLOR_SUCCESS
  ),

  'order.shopify_line_removed': createRenderer(
    (data, event) => (
      <span>
        removed {data.quantity} pieces of {data.product} (SKU {data.sku}) from{' '}
        {CreateOrderLink(data, event)} (change synced from Shopify)
        {data.reason && <strong> (note: {data.reason})</strong>}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        removed{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} variants from order(s)
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Removed {data.quantity} pieces of {data.product} (SKU {data.sku}) from{' '}
        {CreateOrderLink(data, event)} (change synced from Shopify)
      </span>
    ),
    'glyphicon glyphicon-shopping-cart',
    COLOR_DANGER
  ),

  'order.shopify_line_added': createRenderer(
    (data, event) => (
      <span>
        added {data.quantity} pieces of {data.product} (SKU {data.sku}) to{' '}
        {CreateOrderLink(data, event)} (change synced from Shopify)
        {data.reason && <strong> (note: {data.reason})</strong>}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        added{' '}
        <ExpandLink onClick={expand}>{subEvents.length} products</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Added {data.quantity} pieces of {data.product} (SKU {data.sku}) to{' '}
        {CreateOrderLink(data, event)} (change synced from Shopify)
      </span>
    ),
    'glyphicon glyphicon-shopping-cart',
    COLOR_SUCCESS
  ),
  'order.split': createRenderer(
    (data, event) => (
      <span>
        has split {CreateOrderLink(data, event)} creating new{' '}
        {
          <EventRouterLink href={`/orders/show/${data.new_order_id}`}>
            order #{data.new_order_number}
          </EventRouterLink>
        }
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        has split this order{' '}
        <ExpandLink onClick={expand}>{subEvents.length} times</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {' '}
        creating new{' '}
        {
          <EventRouterLink href={`/orders/show/${data.new_order_id}`}>
            order #{data.new_order_number}
          </EventRouterLink>
        }
      </span>
    ),
    'glyphicon glyphicon-resize-horizontal',
    COLOR_SUCCESS
  ),
  'order.delivery_address_updated': createRenderer(
    (data, event) => (
      <span>
        updated delivery address of {CreateOrderLink(data, event)}:{' '}
        {CreateAddressChangeString(data.changes)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated delivery address{' '}
        <ExpandLink onClick={expand}>{subEvents.length} times</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreateOrderLink(data, event)}:{' '}
        {CreateAddressChangeString(data.changes)}
      </span>
    ),
    'glyphicon glyphicon-home'
  ),
  'order.discount_updated': createRenderer(
    (data, event) => (
      <span>
        updated discount of {CreateOrderLink(data, event)} from{' '}
        {data.old_discount}% to <strong>{data.new_discount}%</strong>
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} order discounts
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreateOrderLink(data, event)}: from {data.old_discount}% to{' '}
        <strong>{data.new_discount}%</strong>
      </span>
    ),
    'glyphicon glyphicon-pencil'
  ),
  'order.prices_bulk_updated': createRenderer(
    (data, event) => (
      <span>updated all product prices of {CreateOrderLink(data, event)}</span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} orders' prices
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>Updated {CreateOrderLink(data, event)}'s prices</span>
    ),
    'glyphicon glyphicon-usd'
  ),
  'order.prices_bulk_updated_product': createRenderer(
    (data, event) => (
      <span>
        updated {CreateProductLink(data, event)} prices on{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} orders' prices
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Updated {CreateProductLink(data, event)}'s prices on{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-usd'
  ),
  'order.invoice_address_updated': createRenderer(
    (data, event) => (
      <span>
        updated invoice address of {CreateOrderLink(data, event)}:{' '}
        {CreateAddressChangeString(data.changes)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated invoice address{' '}
        <ExpandLink onClick={expand}>{subEvents.length} times</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreateOrderLink(data, event)}:{' '}
        {CreateAddressChangeString(data.changes)}
      </span>
    ),
    'glyphicon glyphicon-home'
  ),
  // legacy
  'order.invoice_created': createRenderer(
    (data, event) => (
      <span>
        created {CreateInvoiceLink(data, event)} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        created{' '}
        <ExpandLink onClick={expand}>{subEvents.length} invoices</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreateInvoiceLink(data, event)} for {CreateOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-file',
    COLOR_SUCCESS
  ),
  // legacy
  'order.invoice_booked': createRenderer(
    (data, event) => (
      <span>
        booked <strong>{CreateInvoiceLink(data, event)} </strong> for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        booked{' '}
        <ExpandLink onClick={expand}>{subEvents.length} invoices</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreateInvoiceLink(data, event)} for {CreateOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-file',
    COLOR_SUCCESS
  ),
  'order.prepayment_created': createRenderer(
    (data, event) => (
      <span>
        created {CreatePrepaymentLink(data, event)} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        created{' '}
        <ExpandLink onClick={expand}>{subEvents.length} prepayments</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreatePrepaymentLink(data, event)} for {CreateOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-usd',
    COLOR_SUCCESS
  ),
  'order.prepayment_deleted': createRenderer(
    (data, event) => (
      <span>
        deleted {CreatePrepaymentLink(data, event)} from{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        deleted{' '}
        <ExpandLink onClick={expand}>{subEvents.length} prepayments</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreatePrepaymentLink(data, event)} from {CreateOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-usd',
    COLOR_DANGER
  ),
  'order.prepayment_amount_updated': createRenderer(
    (data, event) => (
      <span>
        {CreatePrepaymentLink(data, event)} updated amount from{' '}
        <FormatCurrency currency={data.currency}>
          {data.previous_amount}
        </FormatCurrency>{' '}
        to{' '}
        <FormatCurrency currency={data.currency}>{data.amount}</FormatCurrency>
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated{' '}
        <ExpandLink onClick={expand}>{subEvents.length} prepayments</ExpandLink>{' '}
        amounts
      </span>
    ),
    (data, event) => (
      <span>
        {CreatePrepaymentLink(data, event)} updated amount from{' '}
        <FormatCurrency currency={data.currency}>
          {data.previous_amount}
        </FormatCurrency>{' '}
        to{' '}
        <FormatCurrency currency={data.currency}>{data.amount}</FormatCurrency>
      </span>
    ),
    'glyphicon glyphicon-pencil',
    COLOR_SUCCESS
  ),
  'order.prepayment_sent': createRenderer(
    (data, event) => (
      <span>
        sent prepayment confirmation to {data.email} for{' '}
        {CreatePrepaymentLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        sent <ExpandLink onClick={expand}>prepayment confirmations</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Sent prepayment confirmation to {data.email} for{' '}
        {CreatePrepaymentLink(data, event)}
      </span>
    ),
    'fa fa-envelope',
    COLOR_SUCCESS
  ),
  'order.prepayment_paid': createRenderer(
    (data, event) => (
      <span>
        marked {CreatePrepaymentLink(data, event)} from{' '}
        {CreateOrderLink(data, event)} as paid
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        marked{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} prepayments as paid
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreatePrepaymentLink(data, event)} from {CreateOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-ok',
    COLOR_SUCCESS
  ),
  'order.prepayment_unpaid': createRenderer(
    (data, event) => (
      <span>
        unmarked {CreatePrepaymentLink(data, event)} from{' '}
        {CreateOrderLink(data, event)} as paid
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        unmarked{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} prepayments as paid
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreatePrepaymentLink(data, event)} from {CreateOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-remove',
    COLOR_DANGER
  ),
  'order.prepayment_booked': createRenderer(
    (data, event) => (
      <span>
        booked {CreatePrepaymentLink(data, event)} from{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        booked{' '}
        <ExpandLink onClick={expand}>{subEvents.length} prepayments</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreatePrepaymentLink(data, event)} from {CreateOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-file',
    COLOR_SUCCESS
  ),
  'order.prepayment_payment_link_sent': createRenderer(
    (data, event) => (
      <span>
        sent prepayment payment link to {data.email} for{' '}
        {CreatePrepaymentLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        sent <ExpandLink onClick={expand}>prepayment payment links</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Sent prepayment payment link to {data.email} for{' '}
        {CreatePrepaymentLink(data, event)}
      </span>
    ),
    'fa fa-envelope',
    COLOR_SUCCESS
  ),
  'invoice.agents_updated': createRenderer(
    (data, event) => (
      <span>
        updated agents of {CreateInvoiceLink(data, event)} from{' '}
        {CreateAgentsListString(data.old_agents)} to{' '}
        <strong>{CreateAgentsListString(data.new_agents)}</strong>
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated invoices' agents{' '}
        <ExpandLink onClick={expand}>{subEvents.length} times</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        updated agents of {CreateInvoiceLink(data, event)} from{' '}
        {CreateAgentsListString(data.old_agents)} to{' '}
        <strong>{CreateAgentsListString(data.new_agents)}</strong>
      </span>
    ),
    'glyphicon glyphicon-pencil'
  ),
  'invoice.created': createRenderer(
    (data, event) => (
      <span>
        created {CreateInvoiceLink(data, event)}{' '}
        {event.context.order_id ? (
          <span>for {CreateOrderLink(data, event)}</span>
        ) : (
          ''
        )}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        created{' '}
        <ExpandLink onClick={expand}>{subEvents.length} invoices</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreateInvoiceLink(data, event)}{' '}
        {event.context.order_id ? (
          <span>for {CreateOrderLink(data, event)}</span>
        ) : (
          ''
        )}
      </span>
    ),
    'glyphicon glyphicon-usd',
    COLOR_SUCCESS
  ),
  'invoice.created_from_shipment': createRenderer(
    (data, event) => (
      <span>
        created {CreateInvoiceLink(data, event)}{' '}
        {event.context.order_id ? (
          <span>for {CreateOrderLink(data, event)}</span>
        ) : (
          ''
        )}{' '}
        from shipment {data.shipment_nr}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        created{' '}
        <ExpandLink onClick={expand}>{subEvents.length} invoices</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreateInvoiceLink(data, event)}{' '}
        {event.context.order_id ? (
          <span>for {CreateOrderLink(data, event)}</span>
        ) : (
          ''
        )}{' '}
        from shipment {data.shipment_nr}
      </span>
    ),
    'glyphicon glyphicon-usd',
    COLOR_SUCCESS
  ),
  'invoice.deleted': createRenderer(
    (data, event) => (
      <span>
        deleted {CreateInvoiceLink(data, event)}{' '}
        {event.context.order_id ? (
          <span>for {CreateOrderLink(data, event)}</span>
        ) : (
          ''
        )}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        deleted{' '}
        <ExpandLink onClick={expand}>{subEvents.length} invoices</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreateInvoiceLink(data, event)}{' '}
        {event.context.order_id ? (
          <span>for {CreateOrderLink(data, event)}</span>
        ) : (
          ''
        )}
      </span>
    ),
    'glyphicon glyphicon-remove',
    COLOR_DANGER
  ),
  'invoice.booked': createRenderer(
    (data, event) => (
      <span>
        booked {CreateInvoiceLink(data, event)}{' '}
        {event.context.order_id ? (
          <span>for {CreateOrderLink(data, event)}</span>
        ) : (
          ''
        )}{' '}
        with number #{data.invoice_number} (date {formatDate(data.date)}) (
        {data.total_quantity} pcs.,{' '}
        <FormatCurrency currency={data.currency}>
          {data.net_price}
        </FormatCurrency>
        )
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        booked{' '}
        <ExpandLink onClick={expand}>{subEvents.length} invoices</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreateInvoiceLink(data, event)}{' '}
        {event.context.order_id ? (
          <span>for {CreateOrderLink(data, event)}</span>
        ) : (
          ''
        )}{' '}
        with number #{data.invoice_number} (date {formatDate(data.date)}) (
        {data.total_quantity} pcs.,{' '}
        <FormatCurrency currency={data.currency}>
          {data.net_price}
        </FormatCurrency>
        )
      </span>
    ),
    'glyphicon glyphicon-file',
    COLOR_SUCCESS
  ),
  'overhead_cost_invoice.booked': createRenderer(
    (data, event) => (
      <span>
        booked {CreateOverheadCostInvoiceLink(data, event)}{' '}
        <FormatCurrency currency={data.currency}>{data.amount}</FormatCurrency>
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        booked{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} overhead cost invoices
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        booked {CreateOverheadCostInvoiceLink(data, event)}{' '}
        <FormatCurrency currency={data.currency}>{data.amount}</FormatCurrency>
      </span>
    ),
    'glyphicon glyphicon-file',
    COLOR_SUCCESS
  ),
  'overhead_cost_invoice.allocation_created': createRenderer(
    (data, event) => (
      <span>
        allocated{' '}
        <FormatCurrency currency={data.currency}>{data.amount}</FormatCurrency>{' '}
        to {CreateProductionOrderDeliveryNoteLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        allocated overhead cost invoices to{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} production order delivery notes
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        allocated{' '}
        <FormatCurrency currency={data.currency}>{data.amount}</FormatCurrency>{' '}
        to {CreateProductionOrderDeliveryNoteLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-ok',
    COLOR_SUCCESS
  ),
  'production_prepayment.booked': createRenderer(
    (data, event) => (
      <span>
        booked {CreateProductionPrepaymentLink(data, event)}{' '}
        <FormatCurrency currency={data.currency}>{data.amount}</FormatCurrency>
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        booked{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} production prepayments
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        booked {CreateProductionPrepaymentLink(data, event)}{' '}
        <FormatCurrency currency={data.currency}>{data.amount}</FormatCurrency>
      </span>
    ),
    'glyphicon glyphicon-file',
    COLOR_SUCCESS
  ),
  'production.parts.attribute_added': createRenderer(
    (data, event) => (
      <span>
        added {data.name}: {data.value} to {CreatePartLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        added{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} attributes to parts
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Changed {data.name} from {data.old_value} to {data.new_value} for{' '}
        {CreatePartLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-pencil'
  ),
  'production.parts.attribute_changed': createRenderer(
    (data, event) => (
      <span>
        changed {data.name} of {CreatePartLink(data, event)} from{' '}
        {data.old_value} to {data.new_value}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        changed{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} attributes of parts
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Changed {data.name} from {data.old_value} to {data.new_value} for{' '}
        {CreatePartLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-pencil'
  ),
  'production.parts.attribute_removed': createRenderer(
    (data, event) => (
      <span>
        removed {data.name} from {CreatePartLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        removed{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} attributes from parts
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Removed {data.name} from {CreatePartLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-pencil'
  ),
  'production.parts.part_created': createRenderer(
    (data, event) => <span>created {CreatePartLink(data, event)}</span>,
    (data, expand, subEvents, event) => (
      <span>
        created{' '}
        <ExpandLink onClick={expand}>{subEvents.length} parts</ExpandLink>
      </span>
    ),
    (data, event) => <span>Created {CreatePartLink(data, event)}</span>,
    'glyphicon glyphicon-plus',
    COLOR_SUCCESS
  ),
  'production.parts.part_variant_created': createRenderer(
    (data, event) => (
      <span>
        created variant {data.part_variant_name} of{' '}
        {CreatePartLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        created{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} part variants
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Created {data.part_variant_name} for {CreatePartLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-plus',
    COLOR_SUCCESS
  ),
  'production.parts.approved': createRenderer(
    (data, event) => <span>approved {CreatePartLink(data, event)}</span>,
    (data, expand, subEvents, event) => (
      <span>
        approved{' '}
        <ExpandLink onClick={expand}>{subEvents.length} parts</ExpandLink>
      </span>
    ),
    (data, event) => <span>Approved {CreatePartLink(data, event)}</span>,
    'glyphicon glyphicon-ok',
    COLOR_SUCCESS
  ),
  'production.parts.disapproved': createRenderer(
    (data, event) => <span>disapproved {CreatePartLink(data, event)}</span>,
    (data, expand, subEvents, event) => (
      <span>
        disapproved{' '}
        <ExpandLink onClick={expand}>{subEvents.length} parts</ExpandLink>
      </span>
    ),
    (data, event) => <span>Disapproved {CreatePartLink(data, event)}</span>,
    'glyphicon glyphicon-remove',
    COLOR_DANGER
  ),
  'production.parts.change_deadline': createRenderer(
    (data, event) => (
      <span>
        changed deadline of {CreatePartLink(data, event)} to{' '}
        {formatDate(data.new_deadline)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        changed{' '}
        <ExpandLink onClick={expand}>{subEvents.length} deadlines</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Changed deadline of {CreatePartLink(data, event)} to{' '}
        {formatDate(data.new_deadline)}
      </span>
    ),
    'glyphicon glyphicon-calendar'
  ),
  'production.parts.change_status': createRenderer(
    (data, event) => (
      <span>
        {data.new_status === true ? 'activated' : 'deactivated'}{' '}
        {CreatePartLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        changed status of{' '}
        <ExpandLink onClick={expand}>{subEvents.length} parts</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {data.new_status === true ? 'Activated' : 'Deactivated'}{' '}
        {CreatePartLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-heart'
  ),
  'production.parts.variant_change_status': createRenderer(
    (data, event) => (
      <span>
        {data.new_status === true ? 'activated' : 'deactivated'}{' '}
        {data.part_variant_name} of {CreatePartLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        changed status of{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} parts variants
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {data.new_status === true ? 'Activated' : 'Deactivated'}{' '}
        {data.part_variant_name} of {CreatePartLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-heart'
  ),
  'production.parts.file_uploaded': createRenderer(
    (data, event) => (
      <span>
        uploaded {data.file_name} for {CreatePartLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        uploaded{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} production part files
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Uploaded {data.file_name} for {CreatePartLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-upload'
  ),
  'production.parts.prices.approved_price': createRenderer(
    (data, event) => (
      <span>
        approved price of{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreatePartLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        approved{' '}
        <ExpandLink onClick={expand}>{subEvents.length} part prices</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Approved price of{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreatePartLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-ok',
    COLOR_SUCCESS
  ),
  'production.parts.prices.disapproved_price': createRenderer(
    (data, event) => (
      <span>
        disapproved price of{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreatePartLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        disapproved{' '}
        <ExpandLink onClick={expand}>{subEvents.length} part prices</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Disapproved price of{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreatePartLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-remove',
    COLOR_DANGER
  ),
  'production.products.file_uploaded': createRenderer(
    (data, event) => (
      <span>
        uploaded {data.file_name} for {CreateProductLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        uploaded{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} product production files
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Uploaded {data.file_name} for {CreateProductLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-upload'
  ),
  'production.parts.prices.created_price': createRenderer(
    (data, event) => (
      <span>
        created price at{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreatePartLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        created{' '}
        <ExpandLink onClick={expand}>{subEvents.length} part prices</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Created price at{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreatePartLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-plus'
  ),
  'production.parts.prices.deleted_price': createRenderer(
    (data, event) => (
      <span>
        deleted price at{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreatePartLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        deleted{' '}
        <ExpandLink onClick={expand}>{subEvents.length} part prices</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Deleted price at{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreatePartLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-remove'
  ),
  'production.parts.prices.updated_price': createRenderer(
    (data, event) => (
      <span>
        changed price from{' '}
        <FormatCurrency currency={data.currency}>
          {data.old_value}
        </FormatCurrency>{' '}
        to{' '}
        <FormatCurrency currency={data.currency}>
          {data.new_value}
        </FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreatePartLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        changed{' '}
        <ExpandLink onClick={expand}>{subEvents.length} part prices</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Changed price from{' '}
        <FormatCurrency currency={data.currency}>
          {data.old_value}
        </FormatCurrency>{' '}
        to{' '}
        <FormatCurrency currency={data.currency}>
          {data.new_value}
        </FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreatePartLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-usd'
  ),
  'production.parts.prices.updated_quantity': createRenderer(
    (data, event) => (
      <span>
        changed the minimum quantity from {data.old_value} to {data.new_value}{' '}
        for price{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        for {CreatePartLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} minimum quantities for part prices
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Changed the minimum quantity from {data.old_value} to {data.new_value}{' '}
        for price{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        for {CreatePartLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-asterisk'
  ),
  'production.products.prices.created_price': createRenderer(
    (data, event) => (
      <span>
        created price at{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreateProductLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        created{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} product prices
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Created price at{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreateProductLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-plus'
  ),
  'production.products.prices.deleted_price': createRenderer(
    (data, event) => (
      <span>
        deleted price at{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreateProductLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        deleted{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} product prices
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Deleted price at{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreateProductLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-remove'
  ),
  'production.products.prices.updated_requested_price': createRenderer(
    (data, event) => (
      <span>
        changed requested price from{' '}
        <FormatCurrency currency={data.currency}>
          {data.old_value}
        </FormatCurrency>{' '}
        to{' '}
        <FormatCurrency currency={data.currency}>
          {data.new_value}
        </FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreateProductLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        changed{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} product prices
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Changed requested price from{' '}
        <FormatCurrency currency={data.currency}>
          {data.old_value}
        </FormatCurrency>{' '}
        to{' '}
        <FormatCurrency currency={data.currency}>
          {data.new_value}
        </FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreateProductLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-usd'
  ),
  'production.products.prices.approved_price': createRenderer(
    (data, event) => (
      <span>
        approved price of{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreateProductLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        approved{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} product prices
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Approved price of{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreateProductLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-ok',
    COLOR_SUCCESS
  ),
  'production.products.prices.disapproved_price': createRenderer(
    (data, event) => (
      <span>
        disapproved price of{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreateProductLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        disapproved{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} product prices
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Disapproved price of{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        (min {data.quantity} pieces) for {CreateProductLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-remove',
    COLOR_DANGER
  ),
  'production.products.prices.updated_quantity': createRenderer(
    (data, event) => (
      <span>
        changed the minimum quantity from {data.old_value} to {data.new_value}{' '}
        for price{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        for {CreateProductLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} minimum quantities for product prices
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Changed the minimum quantity from {data.old_value} to {data.new_value}{' '}
        for price{' '}
        <FormatCurrency currency={data.currency}>{data.price}</FormatCurrency>{' '}
        for {CreateProductLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-asterisk'
  ),
  'production.products.parts.added_part': createRenderer(
    (data, event) => (
      <span>
        added {CreatePartLink(data, event)} to {CreateProductLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        added{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} product parts
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Added {CreatePartLink(data, event)} to {CreateProductLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-plus'
  ),
  'production.products.parts.updated_placement': createRenderer(
    (data, event) => (
      <span>
        changed placement of {CreatePartLink(data, event)} from {data.old_value}{' '}
        to {data.new_value} on {CreateProductLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        changed{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} product part placements
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Changed placement of {CreatePartLink(data, event)} from {data.old_value}{' '}
        to {data.new_value} on {CreateProductLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-pencil'
  ),
  'production.products.parts.updated_quantity': createRenderer(
    (data, event) => (
      <span>
        changed quantity of {CreatePartLink(data, event)} from {data.old_value}{' '}
        to {data.new_value} on {CreateProductLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        changed{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} product part quantitys
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Changed quantity of {CreatePartLink(data, event)} from {data.old_value}{' '}
        to {data.new_value} on {CreateProductLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-pencil'
  ),
  'invoice.sent_confirmation': createRenderer(
    (data, event) => (
      <span>
        sent invoice confirmation to {data.email} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        sent <ExpandLink onClick={expand}>invoice confirmations</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Sent invoice confirmation to {data.email} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    'fa fa-envelope',
    COLOR_SUCCESS
  ),
  'invoice.sent_reminder': createRenderer(
    (data, event) => (
      <span>
        sent invoice reminder to {data.email} for{' '}
        {CreateInvoiceLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        sent <ExpandLink onClick={expand}>invoice reminders</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Sent invoice reminder to {data.email} for{' '}
        {CreateInvoiceLink(data, event)}
      </span>
    ),
    'fa fa-envelope',
    COLOR_SUCCESS
  ),
  'invoices.booked_date_updated': createRenderer(
    (data, event) => (
      <span>
        updated invoice date to {formatDate(data.new_date)} ({data.comment})
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated <ExpandLink onClick={expand}>invoice dates</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Updated invoice date to {formatDate(data.new_date)} ({data.comment})
      </span>
    ),
    'glyphicon glyphicon-calendar'
  ),
  'invoice.remainder_amount_update': createRenderer(
    (data, event) => (
      <span>
        updated remainder amount of invoice {data.invoice_number} from{' '}
        <FormatCurrency currency={data.currency}>
          {data.previous_remainder_amount}
        </FormatCurrency>{' '}
        to{' '}
        <FormatCurrency currency={data.currency}>
          {data.new_remainder_amount}
        </FormatCurrency>{' '}
        {data.app !== null && `(updated from ${data.app})`}
        {data.comment && `(${data.comment})`}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} invoice remainder amounts
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        updated remainder amount of invoice {data.invoice_number} from{' '}
        <FormatCurrency currency={data.currency}>
          {data.previous_remainder_amount}
        </FormatCurrency>{' '}
        to{' '}
        <FormatCurrency currency={data.currency}>
          {data.new_remainder_amount}
        </FormatCurrency>{' '}
        {data.app !== null && `(updated from ${data.app})`}
        {data.comment && `(${data.comment})`}
      </span>
    ),
    'glyphicon glyphicon-usd',
    COLOR_SUCCESS
  ),
  'invoices.payment_flow_status_update': createRenderer(
    (data, event) => (
      <span>
        changed payment flow status of invoice {data.invoice_number} from{' '}
        {data.previous_payment_flow_status} to {data.payment_flow_status}
        {data.comment && ` (${data.comment})`}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} invoice payment flow statuses
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        changed payment flow status of invoice {data.invoice_number} from{' '}
        {data.previous_payment_flow_status} to {data.payment_flow_status}
        {data.comment && ` (${data.comment})`}
      </span>
    ),
    'glyphicon glyphicon-usd',
    COLOR_SUCCESS
  ),
  'invoices.payment_flow_comment': createRenderer(
    (data, event) => (
      <span>
        added a payment comment to invoice {data.invoice_number}: {data.comment}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        added{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} invoice payment comments
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        added a payment comment to invoice {data.invoice_number}: {data.comment}
      </span>
    ),
    'glyphicon glyphicon-comment',
    COLOR_SUCCESS
  ),
  'credit_note.agents_updated': createRenderer(
    (data, event) => (
      <span>
        updated agents of {CreateCreditNoteLink(data, event)} from{' '}
        {CreateAgentsListString(data.old_agents)} to{' '}
        <strong>{CreateAgentsListString(data.new_agents)}</strong>
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated credit notes' agents{' '}
        <ExpandLink onClick={expand}>{subEvents.length} times</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        updated agents of {CreateCreditNoteLink(data, event)} from{' '}
        {CreateAgentsListString(data.old_agents)} to{' '}
        <strong>{CreateAgentsListString(data.new_agents)}</strong>
      </span>
    ),
    'glyphicon glyphicon-pencil'
  ),
  'credit_note.sent_confirmation': createRenderer(
    (data, event) => (
      <span>
        sent credit note confirmation to {data.email} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        sent <ExpandLink onClick={expand}>credit note confirmations</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Sent credit note confirmation to {data.email} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    'fa fa-envelope',
    COLOR_SUCCESS
  ),
  'credit_notes.booked_date_updated': createRenderer(
    (data, event) => (
      <span>
        updated credit note date to {formatDate(data.new_date)} ({data.comment})
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated <ExpandLink onClick={expand}>credit note dates</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Updated credit note date to {formatDate(data.new_date)} ({data.comment})
      </span>
    ),
    'glyphicon glyphicon-calendar'
  ),
  'shipments.created': createRenderer(
    (data, event) => (
      <span>
        created <strong>shipment #{data.shipment_nr}</strong> ({data.items}{' '}
        pieces) for {CreateOrderLink(data, event)}
        {data.is_overriding_credit_check === true && (
          <span>
            .{' '}
            <span style={{ color: 'warning', fontWeight: 'bold' }}>
              Credit limit warning was overridden!
            </span>
          </span>
        )}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        created{' '}
        <ExpandLink onClick={expand}>{subEvents.length} shipments</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Shipment number: {data.shipment_nr} ({data.items} pieces) for{' '}
        {CreateOrderLink(data, event)}
        {data.is_overriding_credit_check === true && (
          <span>
            .{' '}
            <span style={{ color: 'warning', fontWeight: 'bold' }}>
              Credit limit warning was overridden!
            </span>
          </span>
        )}
      </span>
    ),
    'fa fa-truck',
    COLOR_SUCCESS
  ),
  'shipments.auto_shipped_order_creation': createRenderer(
    (data, event) => (
      <span>
        created <strong>shipment #{data.shipment_nr}</strong> ({data.items}{' '}
        pieces) for {CreateOrderLink(data, event)} (auto created on order
        creation)
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        created{' '}
        <ExpandLink onClick={expand}>{subEvents.length} shipments</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Shipment number: {data.shipment_nr} ({data.items} pieces) for{' '}
        {CreateOrderLink(data, event)} (auto created on order creation)
      </span>
    ),
    'fa fa-truck',
    COLOR_SUCCESS
  ),
  'shipments.auto_shipped_conditions_matched': createRenderer(
    (data, event) => (
      <span>
        created <strong>shipment #{data.shipment_nr}</strong> ({data.items}{' '}
        pieces) for {CreateOrderLink(data, event)} (auto created from
        conditions: {data.matched_conditions.join(', ')})
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        created{' '}
        <ExpandLink onClick={expand}>{subEvents.length} shipments</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Shipment number: {data.shipment_nr} ({data.items} pieces) for{' '}
        {CreateOrderLink(data, event)} (auto created from conditions:{' '}
        {data.matched_conditions.join(', ')})
      </span>
    ),
    'fa fa-truck',
    COLOR_SUCCESS
  ),
  'shipments.created_from_packing_instruction_order': createRenderer(
    (data, event) => (
      <span>
        created <strong>shipment #{data.shipment_nr}</strong> ({data.items}{' '}
        pieces) for {CreateOrderLink(data, event)} (packing instruction from{' '}
        {CreateProductionOrderDeliveryNoteLink(data, event)})
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        created{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} shipments from packing instructions
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Shipment number: {data.shipment_nr} ({data.items} pieces) for{' '}
        {CreateOrderLink(data, event)} (packing instruction from{' '}
        {CreateProductionOrderDeliveryNoteLink(data, event)})
      </span>
    ),
    'fa fa-truck',
    COLOR_SUCCESS
  ),
  'shipments.force_cancel_packed_shipment': createRenderer(
    (data, event) => (
      <span>
        deleted completed delivery note <strong>#{data.shipment_number}</strong>
        . Shipment {data.reopen_shipment ? 'was cancelled' : 'was reopened'}.
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        deleted{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} delivery notes
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        deleted completed delivery note <strong>#{data.shipment_number}</strong>
        . Shipment {data.reopen_shipment ? 'was cancelled' : 'was reopened'}.
      </span>
    ),
    'fa fa-truck',
    COLOR_DANGER
  ),
  'shipments.shipment_fulfilled': createRenderer(
    (data, event) => (
      <span>
        <strong>Shipment #{data.shipment_nr}</strong> for{' '}
        {CreateOrderLink(data, event)} was fulfilled
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        <ExpandLink onClick={expand}>{subEvents.length} shipments</ExpandLink>{' '}
        were fulfilled
      </span>
    ),
    (data, event) => (
      <span>
        Shipment number: {data.shipment_nr} for {CreateOrderLink(data, event)}
      </span>
    ),
    'fa fa-truck',
    COLOR_SUCCESS
  ),
  'shipments.full_shipment_fulfilled': createRenderer(
    (data, event) => (
      <span>
        The last shipment of {CreateOrderLink(data, event)} was fulfilled
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        The last shipment of {CreateOrderLink(data, event)} was fulfilled
      </span>
    ),
    (data, event) => (
      <span>
        Shipment number: {data.shipment_nr} for {CreateOrderLink(data, event)}
      </span>
    ),
    'fa fa-truck',
    COLOR_SUCCESS
  ),
  'shipments.delivery_note_approved': createRenderer(
    (data, event) => (
      <span>
        Delivery note of shipment {data.shipment_number}{' '}
        {CreateOrderLink(data, event)} was approved
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        Delivery note of shipment {data.shipment_number}{' '}
        {CreateOrderLink(data, event)} was approved
      </span>
    ),
    (data, event) => (
      <span>
        Shipment number: {data.shipment_number} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-ok',
    COLOR_SUCCESS
  ),
  'shipment.cancelled': createRenderer(
    (data, event) => <span>cancelled shipment #{data.shipment_number}</span>,
    (data, expand, subEvents, event) => (
      <span>
        cancelled{' '}
        <ExpandLink onClick={expand}>{subEvents.length} shipments</ExpandLink>
      </span>
    ),
    (data, event) => <span>Cancelled shipment #{data.shipment_number}</span>,
    'glyphicon glyphicon-folder-close',
    COLOR_DANGER
  ),
  'shipments.update_colli_on_completed_shipment': createRenderer(
    (data, event) => (
      <span>
        updated shipment <strong>#{data.shipment_number}</strong> T&T from{' '}
        {data.source}. {writeOutTrackingChanges(data.changes)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        Updated T&T on{' '}
        <ExpandLink onClick={expand}>{subEvents.length} shipments</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Updated shipment <strong>#{data.shipment_number}</strong> T&T from{' '}
        {data.source}. {writeOutTrackingChanges(data.changes)}
      </span>
    ),
    'fa fa-truck'
  ),
  'order.locked': createRenderer(
    (data, event) => <span>locked {CreateOrderLink(data, event)}</span>,
    (data, expand, subEvents, event) => (
      <span>
        locked{' '}
        <ExpandLink onClick={expand}>{subEvents.length} orders</ExpandLink>
      </span>
    ),
    (data, event) => <span>{CreateOrderLink(data, event)}</span>,
    'glyphicon glyphicon-lock'
  ),
  'order.drop_deliveries_updated': createRenderer(
    (data, event) => (
      <span>
        moved {data.quantity} pieces of {data.full_name} from{' '}
        {data.from_drop_delivery_name
          ? data.from_drop_delivery_name
          : 'no drop delivery'}{' '}
        to{' '}
        {data.target_drop_delivery_name
          ? data.target_drop_delivery_name
          : 'no drop delivery'}{' '}
        on {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        moved drop delivery on{' '}
        <ExpandLink onClick={expand}>{subEvents.length} order lines</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Moved {data.quantity} pieces of {data.full_name} from{' '}
        {data.from_drop_delivery_name
          ? data.from_drop_delivery_name
          : 'no drop delivery'}{' '}
        to{' '}
        {data.target_drop_delivery_name
          ? data.target_drop_delivery_name
          : 'no drop delivery'}{' '}
        on {CreateOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-shopping-cart',
    COLOR_SUCCESS
  ),
  'order.line_added': createRenderer(
    (data, event) => (
      <span>
        added {data.quantity} pieces of {data.product_name} (#
        {data.product_item_number}) ({data.attributes}) to{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        added{' '}
        <ExpandLink onClick={expand}>{subEvents.length} products</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Added {data.quantity} pieces of {data.product_name} (#
        {data.product_item_number}) ({data.attributes}) to{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-shopping-cart',
    COLOR_SUCCESS
  ),
  'order.line_underdelivered': createRenderer(
    (data, event) => (
      <span>
        removed {data.underdelivered} pieces of {data.product_name} (#
        {data.product_item_number}) ({data.attributes}) that were underdelivered
        in {data.shipment_number}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        removed{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} underdelivered products
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Removed {data.underdelivered} pieces of {data.product_name} (#
        {data.product_item_number}) ({data.attributes}) that were underdelivered
        in {data.shipment_number}
      </span>
    ),
    'glyphicon glyphicon-remove',
    COLOR_DANGER
  ),
  // The data for this one could not be converted to order.line_deleted, when we refactored to order lines.
  // We keep it for historical reasons.
  'order.product_deleted': createRenderer(
    (data, event) => (
      <span>
        removed product {data.name} (item number: {data.item_number}) from{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        removed{' '}
        <ExpandLink onClick={expand}>{subEvents.length} products</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {data.name} (item number: {data.item_number}):{' '}
        <strong>{data.variant_count} variants removed</strong> from{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-shopping-cart',
    COLOR_DANGER
  ),
  'order.reopened': createRenderer(
    (data, event) => <span>reopened {CreateOrderLink(data, event)}</span>,
    (data, expand, subEvents, event) => (
      <span>
        reopened{' '}
        <ExpandLink onClick={expand}>{subEvents.length} orders</ExpandLink>
      </span>
    ),
    (data, event) => <span>{CreateOrderLink(data, event)}</span>,
    'glyphicon glyphicon-folder-open',
    COLOR_SUCCESS
  ),
  'order.status_changed': createRenderer(
    (data, event) => (
      <span>
        changed status from {data.old_status} to{' '}
        <strong>{data.new_status}</strong> on {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        changed status{' '}
        <ExpandLink onClick={expand}>{subEvents.length} times</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Status changed from {data.old_status} to{' '}
        <strong>{data.new_status}</strong> on {CreateOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-pencil'
  ),
  'order.sent_confirmation': createRenderer(
    (data, event) => (
      <span>
        sent order confirmation to {data.email} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        sent <ExpandLink onClick={expand}>order confirmations</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Sent order confirmation to {data.email} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    'fa fa-envelope',
    COLOR_SUCCESS
  ),
  'order.sent_selection_confirmation': createRenderer(
    (data, event) => (
      <span>
        sent selection confirmation to {data.email} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        sent <ExpandLink onClick={expand}>selection confirmations</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Sent selection confirmation to {data.email} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    'fa fa-envelope',
    COLOR_SUCCESS
  ),
  'order.line_deleted': createRenderer(
    (data, event) => (
      <span>
        removed variant {data.product_name} ({data.attributes}) from{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        removed{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} variants from order(s)
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Removed {data.product_name} ({data.attributes}) from{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-shopping-cart',
    COLOR_DANGER
  ),
  'order.line_updated': createRenderer(
    (data, event) => (
      <span>
        {CreateVariantQuantityChangeString(data)} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated{' '}
        <ExpandLink onClick={expand}>{subEvents.length} variants</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreateVariantQuantityChangeString(data)} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-shopping-cart',
    COLOR_SUCCESS
  ),
  'order.change_proposal_applied': createRenderer(
    (data, event) => (
      <span>
        {CreateVariantQuantityChangeString(data)} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated{' '}
        <ExpandLink onClick={expand}>{subEvents.length} variants</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {CreateVariantQuantityChangeString(data)} for{' '}
        {CreateOrderLink(data, event)}
      </span>
    ),
    'glyphicon glyphicon-shopping-cart',
    COLOR_SUCCESS
  ),
  'order.move_line_from': createRenderer(
    (data, event) => (
      <span>
        moved {data.quantity} pieces of {data.product_name} (#
        {data.product_item_number}) ({data.attributes}) to{' '}
        <EventRouterLink href={`/orders/show/${data.target_order_id}`}>
          order #{data.target_order_number}
        </EventRouterLink>
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        moved{' '}
        <ExpandLink onClick={expand}>{subEvents.length} products</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Moved {data.quantity} pieces of {data.product_name} (#
        {data.product_item_number}) ({data.attributes}) to{' '}
        <EventRouterLink href={`/orders/show/${data.target_order_id}`}>
          order #{data.target_order_number}
        </EventRouterLink>
      </span>
    ),
    'glyphicon glyphicon-forward'
  ),
  'order.move_line_to': createRenderer(
    (data, event) => (
      <span>
        received {data.quantity} pieces of {data.product_name} (#
        {data.product_item_number}) ({data.attributes}) from{' '}
        <EventRouterLink href={`/orders/show/${data.from_order_id}`}>
          order #{data.from_order_number}
        </EventRouterLink>
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        received{' '}
        <ExpandLink onClick={expand}>{subEvents.length} products</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Received {data.quantity} pieces of {data.product_name} (#
        {data.product_item_number}) ({data.attributes}) from{' '}
        <EventRouterLink href={`/orders/show/${data.from_order_id}`}>
          order #{data.from_order_number}
        </EventRouterLink>
      </span>
    ),
    'glyphicon glyphicon-forward'
  ),
  'order.merge_from': createRenderer(
    (data, event) => (
      <span>
        merged into{' '}
        <EventRouterLink href={`/orders/show/${data.target_order_id}`}>
          order #{data.target_order_number}
        </EventRouterLink>
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        merged{' '}
        <ExpandLink onClick={expand}>{subEvents.length} orders</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Merged into{' '}
        <EventRouterLink href={`/orders/show/${data.target_order_id}`}>
          order #{data.target_order_number}
        </EventRouterLink>
      </span>
    ),
    'glyphicon glyphicon-forward'
  ),
  'order.merge_to': createRenderer(
    (data, event) => (
      <span>
        received{' '}
        {data.num_order_lines === data.num_lines_moved
          ? 'all'
          : data.num_lines_moved}{' '}
        lines from{' '}
        <EventRouterLink href={`/orders/show/${data.from_order_id}`}>
          order #{data.from_order_number}
        </EventRouterLink>
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        merged{' '}
        <ExpandLink onClick={expand}>{subEvents.length} orders</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Received{' '}
        {data.num_order_lines === data.num_lines_moved
          ? 'all'
          : data.num_lines_moved}{' '}
        lines from{' '}
        <EventRouterLink href={`/orders/show/${data.from_order_id}`}>
          order #{data.from_order_number}
        </EventRouterLink>
      </span>
    ),
    'glyphicon glyphicon-forward'
  ),
  'order.unlocked': createRenderer(
    (data, event) => <span>unlocked {CreateOrderLink(data, event)}</span>,
    (data, expand, subEvents, event) => (
      <span>
        unlocked{' '}
        <ExpandLink onClick={expand}>{subEvents.length} orders</ExpandLink>
      </span>
    ),
    (data, event) => <span>{CreateOrderLink(data, event)}</span>,
    'glyphicon glyphicon-folder-open'
  ),
  'product.created': createRenderer(
    (data, event) => <span>created {CreateProductLink(data, event)}</span>,
    (data, expand, subEvents, event) => (
      <span>
        created{' '}
        <ExpandLink onClick={expand}>{subEvents.length} product(s)</ExpandLink>
      </span>
    ),
    (data, event) => <span>Created {CreateProductLink(data, event)}</span>,
    'glyphicon glyphicon-shopping-cart',
    COLOR_SUCCESS
  ),
  'product.updated': createRenderer(
    (data, event, options = {}) => {
      return (
        <span>
          {!options.single_product && (
            <span>updated {CreateProductLink(data, event)}: </span>
          )}
          {options.single_product && data.changes.length == 1 && (
            <span>updated </span>
          )}
          {options.single_product && data.changes.length > 1 && (
            <span>updated multiple fields</span>
          )}
          {CreateGenericUpdatesTable(data.changes)}
        </span>
      )
    },
    (data, expand, subEvents, event) => (
      <span>
        updated{' '}
        <ExpandLink onClick={expand}>{subEvents.length} product(s)</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Updated {CreateProductLink(data, event)}:{' '}
        {CreateGenericUpdatesTable(data.changes)}
      </span>
    ),
    'glyphicon glyphicon-pencil'
  ),
  'variant.updated': createRenderer(
    (data, event, options = {}) => {
      return (
        <span>
          {!options.single_product && (
            <span>
              updated {CreateProductLink(data, event)} {data.attributes}:{' '}
            </span>
          )}
          {options.single_product && (
            <span>
              updated {data.attributes} (SKU {data.sku}){' '}
            </span>
          )}
          {CreateVariantUpdatesTable(data.changes)}
        </span>
      )
    },
    (data, expand, subEvents, event) => (
      <span>
        updated{' '}
        <ExpandLink onClick={expand}>{subEvents.length} variants(s)</ExpandLink>
      </span>
    ),
    (data, event, options = {}) => (
      <span>
        {!options.single_product && (
          <span>
            updated {CreateProductLink(data, event)} {data.attributes}:{' '}
          </span>
        )}
        {options.single_product && (
          <span>
            updated {data.attributes} (SKU {data.sku}){' '}
          </span>
        )}
        {CreateVariantUpdatesTable(data.changes)}
      </span>
    ),
    'glyphicon glyphicon-pencil'
  ),
  'product.deleted': createRenderer(
    (data, event) => (
      <span>
        deleted product {data.product_name} (item number: {data.item_number})
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        deleted{' '}
        <ExpandLink onClick={expand}>{subEvents.length} product(s)</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Deleted {data.product_name} (item number: {data.item_number})
      </span>
    ),
    'glyphicon glyphicon-shopping-cart',
    COLOR_DANGER
  ),
  'commission_reports.created': createRenderer(
    (data, event) => (
      <span>created commission report {data.commission_report_name}</span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        created{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} commission report(s)
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>Created commission report {data.commission_report_name}</span>
    ),
    'glyphicon glyphicon-plus'
  ),
  'commission_reports.agent_added': createRenderer(
    (data, event) => (
      <span>
        added {data.agent_type} {data.agent_name} to commission report{' '}
        {data.commission_report_name}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        added{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} agents to commission report(s)
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Added {data.agent_type} {data.agent_name} to commission report{' '}
        {data.commission_report_name}
      </span>
    ),
    'glyphicon glyphicon-plus'
  ),
  'commission_reports.agent_removed': createRenderer(
    (data, event) => (
      <span>
        removed {data.agent_type} {data.agent_name} to commission report{' '}
        {data.commission_report_name}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        removed{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} agents to commission report(s)
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Removed {data.agent_type} {data.agent_name} to commission report{' '}
        {data.commission_report_name}
      </span>
    ),
    'glyphicon glyphicon-plus'
  ),
  'commission_reports.excluded_invoice': createRenderer(
    (data, event) => (
      <span>
        excluded {CreateCommissionReportInvoiceLink(data)} from commission
        report {data.commission_report_name}{' '}
        {data.exclusion_comment && `(${data.exclusion_comment})`}.{' '}
        <CommissionReportInvoiceExclusionModeLabel data={data} />
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        excluded{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} invoices from commission report(s)
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Excluded {CreateCommissionReportInvoiceLink(data)} from commission
        report {data.commission_report_name}
        {data.exclusion_comment && `(${data.exclusion_comment})`}.{' '}
        <CommissionReportInvoiceExclusionModeLabel data={data} />
      </span>
    ),
    'glyphicon glyphicon-remove'
  ),
  'commission_reports.manually_added_invoice': createRenderer(
    (data, event) => (
      <span>
        manually added {CreateCommissionReportInvoiceLink(data)} to commission
        report {data.commission_report_name}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        manually added{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} invoices to commission report(s)
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Manually added {CreateCommissionReportInvoiceLink(data)} to commission
        report {data.commission_report_name}
      </span>
    ),
    'glyphicon glyphicon-remove'
  ),
  'commission_reports.re_added_excluded_invoices': createRenderer(
    (data, event) => (
      <span>
        re-added excluded {CreateCommissionReportInvoiceLink(data)} to
        commission report {data.commission_report_name}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        re-added{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} excluded invoices to commission report(s)
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Re-added exclude {CreateCommissionReportInvoiceLink(data)} to commission
        report {data.commission_report_name}
      </span>
    ),
    'glyphicon glyphicon-plus'
  ),
  'commission_reports.updated_commission_rate': createRenderer(
    (data, event) => (
      <span>
        updated {CreateCommissionReportInvoiceLink(data)} commission rate from{' '}
        {data.old_commission_rate || 0}% to {data.new_commission_rate || 0}% on
        commission report {data.commission_report_name}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        updated commission rates on{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} commission rate invoices
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        Updated {CreateCommissionReportInvoiceLink(data)} commission rate from{' '}
        {data.old_commission_rate || 0}% to {data.new_commission_rate || 0}% on
        commission report {data.commission_report_name}
      </span>
    ),
    'glyphicon glyphicon-plus'
  ),
  // legacy event, we divided this into 2 instead
  'webshop.added_to_basket': createRenderer(
    (data, event) => (
      <span>
        from {CreateCustomerLink(data, event)} put {data.quantity} pieces of{' '}
        {data.product_name} ({data.attributes}) in the webshop cart (
        {data.currency})
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        from {CreateCustomerLink(data, event)} put{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} variants in the webshop cart
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {data.quantity} pieces of {data.product_name} ({data.attributes}) in{' '}
        {data.currency}
      </span>
    ),
    'glyphicon glyphicon-shopping-cart'
  ),
  'webshop.added_new_product_to_cart': createRenderer(
    (data, event) => (
      <span>
        from {CreateCustomerLink(data, event)} added {data.quantity} x{' '}
        {data.product_name}({data.attributes}) to the webshop cart
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        from {CreateCustomerLink(data, event)} added{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} variants to the webshop cart
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        {data.quantity} pieces of {data.product_name} ({data.attributes})
      </span>
    ),
    'glyphicon glyphicon-shopping-cart'
  ),
  'webshop.updated_product_in_cart': createRenderer(
    (data, event) => (
      <span>
        from {CreateCustomerLink(data, event)} changed quantity of{' '}
        {data.product_name} ({data.attributes}) from {data.from_quantity} to{' '}
        {data.to_quantity}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        from {CreateCustomerLink(data, event)} update the quantity of{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} variants in the webshop cart
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        changed {data.product_name} ({data.attributes}) from{' '}
        {data.from_quantity} to {data.to_quantity}
      </span>
    ),
    'glyphicon glyphicon-shopping-cart'
  ),
  'webshop.opened': createRenderer(
    (data, event) => (
      <span>from {CreateCustomerLink(data, event)} opened the webshop</span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        from {CreateCustomerLink(data, event)}{' '}
        <ExpandLink onClick={expand}>
          opened the webshop {subEvents.length} times
        </ExpandLink>
      </span>
    ),
    (data, event) => <span>Opened the webshop</span>,
    'glyphicon glyphicon-shopping-cart'
  ),
  'webshop.selection_session_opened': createRenderer(
    (data, event) => (
      <span>
        from {CreateCustomerLink(data, event)} opened{' '}
        {CreateOrderSelectionLink(data, event)} in the webshop
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        from {CreateCustomerLink(data, event)} opened{' '}
        {CreateOrderSelectionLink(data, event)} in the webshop
      </span>
    ),
    (data, event) => (
      <span>
        Opened the {CreateOrderSelectionLink(data, event)} in the webshop
      </span>
    ),
    'glyphicon glyphicon-shopping-cart'
  ),
  'workflows.updated_from_flow': createRenderer(
    (data, event) => (
      <span>
        updated {workflowChangesLabel(event)} from workflow {data.workflow_name}
      </span>
    ),
    (data, expand, subEvents, event) => {
      const changes = subEvents.reduce((carry, event) => {
        return (carry += event.data.changes.length)
      }, 0)

      return (
        <span>
          made{' '}
          <ExpandLink onClick={expand}>
            {changes} changes from {subEvents.length} workflows
          </ExpandLink>
        </span>
      )
    },
    (data, event) => {
      const changes = workflowChangesLabel(event)

      return <span>{changes}</span>
    },
    'glyphicon glyphicon-cog'
  ),
  'orders.marked_as_exported': createRenderer(
    (data, event) => (
      <span>
        exported {CreateOrderLink(data, event)} to {data.system_id}{' '}
        {data.note && `(${data.note})`}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        exported{' '}
        <ExpandLink onClick={expand}>{subEvents.length} orders</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        exported {CreateOrderLink(data, event)} to {data.system_id}{' '}
        {data.note && `(${data.note})`}
      </span>
    ),
    'glyphicon glyphicon-folder-open'
  ),
  'invoices.marked_as_exported': createRenderer(
    (data, event) => (
      <span>
        exported {CreateInvoiceLink(data, event)} to {data.system_id}{' '}
        {data.note && `(${data.note})`}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        exported{' '}
        <ExpandLink onClick={expand}>{subEvents.length} invoices</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        exported {CreateInvoiceLink(data, event)} to {data.system_id}{' '}
        {data.note && `(${data.note})`}
      </span>
    ),
    'glyphicon glyphicon-folder-open'
  ),
  'credit_notes.marked_as_exported': createRenderer(
    (data, event) => (
      <span>
        exported {CreateCreditNoteLink(data, event)} to {data.system_id}{' '}
        {data.note && `(${data.note})`}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        exported{' '}
        <ExpandLink onClick={expand}>
          {subEvents.length} credit notes
        </ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        exported {CreateCreditNoteLink(data, event)} to {data.system_id}{' '}
        {data.note && `(${data.note})`}
      </span>
    ),
    'glyphicon glyphicon-folder-open'
  ),
  'shipments.marked_as_exported': createRenderer(
    (data, event) => (
      <span>
        exported {CreateShipmentLink(data, event)} to {data.system_id}{' '}
        {data.note && `(${data.note})`}
      </span>
    ),
    (data, expand, subEvents, event) => (
      <span>
        exported{' '}
        <ExpandLink onClick={expand}>{subEvents.length} shipments</ExpandLink>
      </span>
    ),
    (data, event) => (
      <span>
        exported {CreateShipmentLink(data, event)} to {data.system_id}{' '}
        {data.note && `(${data.note})`}
      </span>
    ),
    'glyphicon glyphicon-folder-open'
  ),
}

const workflowChangesLabel = event => {
  const labels = []
  for (let change of event.data.changes) {
    labels.push(
      `${change.key}: from ${change.from || 'no value'} to ${change.to}`
    )
  }

  return labels.join(', ')
}

const EventLink = styled.span`
  color: #2d61c5;
  cursor: pointer;
`

const ExpandLink = styled(EventLink)`
  font-weight: bold;
`

const EventRouterLink = styled.a`
  color: #2d61c5;
`

const CreateVariantQuantityChangeString = data => {
  return (
    <span>
      updated <strong>{data.key}</strong> for{' '}
      <strong>
        SKU {data.sku} ({data.attributes})
      </strong>{' '}
      from {data.old_value} to <strong>{data.new_value}</strong>
    </span>
  )
}

const CreateGenericUpdatesTable = changes => {
  const rows = []

  for (let [i, change] of changes.entries()) {
    const oldValue =
      change.old_value == null ? <i>no value</i> : change.old_value
    const newValue =
      change.new_value == null ? <i>no value</i> : change.new_value

    if (changes.length == 1) {
      rows.push(
        <span>
          {change.key} from {oldValue} to <strong>{newValue}</strong>
        </span>
      )
      continue
    }

    rows.push(
      <tr>
        <td>{change.key}</td>
        <td style={{ wordBreak: 'break-all' }}>{oldValue}</td>
        <td style={{ wordBreak: 'break-all' }}>{newValue}</td>
      </tr>
    )
  }

  if (changes.length == 1) {
    return rows
  }

  return (
    <div>
      <ChangesTable>
        <thead>
          <th>Field</th>
          <th>From</th>
          <th>To</th>
        </thead>
        <tbody>{rows}</tbody>
      </ChangesTable>
    </div>
  )
}

/** Variant updates can contain colli-based updates, that need more user-friendly presentation logic **/
const CreateVariantUpdatesTable = changes => {
  const rows = []

  function getAndFormatValue(value, changeType) {
    // check if value is an object with key value pairs
    if (isObject(value)) {
      if (Object.entries(value).length === 0) {
        return (
          <strong>
            <i>no value</i>
          </strong>
        )
      }

      return (
        <ul>
          {Object.entries(value).map(([key, value]) => {
            switch (changeType) {
              case 'Colli EAN numbers': // Add other colli-based change types here
                key = `Colli ${key}`
                break
              default:
                break
            }

            return (
              <li>
                <strong>{key}:</strong>
                &nbsp;
                <span>{value}</span>
              </li>
            )
          })}
        </ul>
      )
    } else {
      return <strong>{value == null ? <i>no value</i> : value}</strong>
    }
  }

  for (let [i, change] of changes.entries()) {
    const oldValue = getAndFormatValue(change.old_value, change.key)
    const newValue = getAndFormatValue(change.new_value, change.key)

    if (changes.length === 1) {
      rows.push(
        <span>
          {change.key} from {oldValue} to {newValue}
        </span>
      )
      continue
    }

    rows.push(
      <tr>
        <td>{change.key}</td>
        <td>{oldValue}</td>
        <td>{newValue}</td>
      </tr>
    )
  }

  if (changes.length === 1) {
    return rows
  }

  return (
    <div>
      <ChangesTable>
        <thead>
          <th>Field</th>
          <th>From</th>
          <th>To</th>
        </thead>
        <tbody>{rows}</tbody>
      </ChangesTable>
    </div>
  )
}

/** Customer address updates **/
const CreateCustomerAddressUpdatesTable = changes => {
  const rows = []

  function getAndFormatValue(value, changeType) {
    // check if value is an object with key value pairs
    if (isObject(value)) {
      if (Object.entries(value).length === 0) {
        return (
          <strong>
            <i>no value</i>
          </strong>
        )
      }

      return (
        <ul>
          {Object.entries(value).map(([key, value]) => {
            return (
              <li>
                <strong>{key}:</strong>
                &nbsp;
                <span>{value}</span>
              </li>
            )
          })}
        </ul>
      )
    } else {
      return <strong>{value == null ? <i>no value</i> : value}</strong>
    }
  }

  for (let [i, change] of changes.entries()) {
    const oldValue = getAndFormatValue(change.old_value, change.key)
    const newValue = getAndFormatValue(change.new_value, change.key)

    if (changes.length === 1) {
      rows.push(
        <span>
          {change.key} from {oldValue} to {newValue}
        </span>
      )
      continue
    }

    rows.push(
      <tr>
        <td>{change.key}</td>
        <td>{oldValue}</td>
        <td>{newValue}</td>
      </tr>
    )
  }

  if (changes.length === 1) {
    return rows
  }

  return (
    <div>
      <ChangesTable>
        <thead>
          <th>Field</th>
          <th>From</th>
          <th>To</th>
        </thead>
        <tbody>{rows}</tbody>
      </ChangesTable>
    </div>
  )
}

const ChangesTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 2px;

  thead th {
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
  }

  tbody td {
    font-size: 10px;
    padding: 1px 6px 1px 0;
  }
`

const CreateAddressChangeString = changes => {
  return changes.reduce((carry, change) => {
    if (carry !== '') {
      carry += ', '
    }

    const oldValue = change.old_value === null ? '(empty)' : change.old_value

    carry += `${AddressChangeLabels[change.key]} from ${oldValue} to ${
      change.new_value
    }`
    return carry
  }, '')
}

const CreateReturnExchangesString = exchanges => {
  return exchanges.reduce((carry, exchange) => {
    carry += exchange.quantity === 1 ? '1 piece' : `${exchange.quantity} pieces`
    carry += ` of ${exchange.from_product_name} (#${exchange.from_product_item_number}) (${exchange.from_attributes})`
    carry += ` for ${exchange.to_product_name} (#${exchange.to_product_item_number}) (${exchange.to_attributes})`
    return carry
  }, '')
}

const CreateAgentsListString = agents => {
  if (agents.length === 0) {
    return 'no agent'
  }

  return agents.reduce((carry, agent) => {
    if (carry !== '') {
      carry += ', '
    }

    carry += agent.name

    if (agent.primary && agents.length > 1) {
      carry += ' (primary)'
    }

    return carry
  }, '')
}

const CreateAccountingYearLink = (data, event) => {
  return (
    <EventRouterLink
      href={`/settings/accounting-years/${event.context.accounting_year_id}`}
    >
      accounting year {data.accounting_year}
    </EventRouterLink>
  )
}

const CreateCustomerLink = (data, event) => (
  <EventRouterLink href={`/customers/${event.context.customer_id}`}>
    {data.customer_name} (#{data.customer_number})
  </EventRouterLink>
)

const CreatePartLink = (data, event) => (
  <EventRouterLink href={`/production/parts/${event.context.part_id}`}>
    part {data.part_name} ({data.part_item_number})
  </EventRouterLink>
)

const CreateOverheadCostInvoiceLink = (data, event) => (
  <EventRouterLink
    href={`/production/overhead-cost-invoices/${event.context.overhead_cost_invoice_id}`}
  >
    {event.data.overhead_cost_invoice_number
      ? `overhead cost invoice #${event.data.overhead_cost_invoice_number} (#${event.data.supplier_invoice_number})`
      : 'open overhead cost invoice'}
  </EventRouterLink>
)

const CreateProductionPrepaymentLink = (data, event) => (
  <EventRouterLink
    href={`/production/production-prepayments/${event.context.production_prepayment_id}`}
  >
    {event.data.production_prepayment_number
      ? `production prepayment #${event.data.production_prepayment_number} (#${event.data.supplier_invoice_number})`
      : 'open production prepayment'}
  </EventRouterLink>
)

const CreateInvoiceLink = (data, event) => (
  <EventRouterLink href={`/invoices/${event.context.invoice_id}`}>
    {event.data.status === 'booked' || event.data.invoice_number
      ? `invoice #${event.data.invoice_number}`
      : 'open invoice'}
  </EventRouterLink>
)

const CreateCreditNoteLink = (data, event) => (
  <EventRouterLink href={`/credit-notes/${event.context.credit_note_id}`}>
    {event.data.status === 'booked' || event.data.credit_note_number
      ? `credit note #${event.data.credit_note_number}`
      : 'open credit note'}
  </EventRouterLink>
)

const CreateOrderLink = (data, event) => (
  <EventRouterLink href={`/orders/show/${event.context.order_id}`}>
    order #{data.order_number}
  </EventRouterLink>
)

const CreateShipmentLink = (data, event) => (
  <EventRouterLink href={`/shipments/${event.context.shipment_id}`}>
    shipment #{data.shipment_number}
  </EventRouterLink>
)

const CreateOrderSelectionLink = (data, event) => (
  <EventRouterLink href={`/orders/show/${event.context.order_id}`}>
    order selection #{data.order_number}
  </EventRouterLink>
)

const CreateProductionOrderDeliveryNoteLink = (data, event) => (
  <EventRouterLink
    href={`/production/orders/delivery-notes/${event.context.production_order_delivery_note_id}`}
  >
    production order delivery note #{data.production_order_delivery_note_number}
  </EventRouterLink>
)

const CreateProductionOrderLink = (data, event) => (
  <EventRouterLink
    href={`/production/orders/${event.context.production_order_id}`}
  >
    production order #{data.production_order_number}
  </EventRouterLink>
)

const CreateProductionOrderLineLink = (data, event) => (
  <EventRouterLink
    href={`/production/orders/${event.context.production_order_id}`}
  >
    production order line #{data.production_order_line_sku}
  </EventRouterLink>
)

const CreateOrderSourceLink = (data, event) => <span>{data.source}</span>

const CreatePrepaymentLink = (data, event) => (
  <EventRouterLink
    href={`/orders/${event.context.order_id}/prepayments/${event.context.prepayment_id}`}
  >
    prepayment #{data.prepayment_number}
  </EventRouterLink>
)

const CreateProductLink = (data, event) => (
  <EventRouterLink href={`/products/${event.context.product_id}`}>
    product {data.product_name} (item number: {data.item_number})
  </EventRouterLink>
)

const CreateReturnLink = (data, event) => (
  <EventRouterLink href={`/returns/${data.return_id}`}>
    return #{data.return_rma}
  </EventRouterLink>
)

const CreateCommissionReportInvoiceLink = data => {
  return (
    <EventRouterLink
      href={
        data.invoice_type === 'invoice'
          ? `/invoices/${data.invoice_id}`
          : `/credit-notes/${data.invoice_id}`
      }
    >
      {data.commission_report_invoice_type}{' '}
      {data.invoice_type === 'invoice' ? 'invoice' : 'credit note'} #
      {data.invoice_number}
    </EventRouterLink>
  )
}

const CommissionReportInvoiceExclusionModeLabel = ({ data }) => {
  if (data.exclusion_mode === 'skip') {
    return <span>Will be automatically added to next commission report</span>
  }

  return <span>Will not automatically be added to next commission report</span>
}

const writeOutTrackingChanges = changes => {
  return [
    ...changes.existing_colli.map(change => {
      return `Changed from ${change.colli_label} from ${
        change.previous_track_trace || 'no T&T'
      } to ${change.track_trace}`
    }),
    ...changes.new_colli.map(change => {
      return `Added new colli ${change.track_trace}`
    }),
  ].join(', ')
}

export const EventLabels = {
  'customer.agents_updated': 'Customer: Agents updated',
  'customer.first_login': 'Customer: First login',
  'customer.state_change': 'Customer: Changed status',
  'invoice.sent_confirmation': 'Invoice: Sent confirmation',
  'invoice.sent_reminder': 'Invoice: Sent reminder',
  'invoice.remainder_amount_update': 'Invoice: Remainder updated',
  'order.agents_updated': 'Order: Agents updated',
  'order.approved': 'Order: Approved',
  'order.archived': 'Order: Archived',
  'order.unarchived': 'Order: Unarchived',
  'order.cancelled': 'Order: Cancelled',
  'order.split': 'Order: Split',
  'order.comment_created': 'Order: Comment',
  'order.created': 'Order: New order',
  'order.delivery_address_updated': 'Order: Delivery address updated',
  'order.discount_updated': 'Order: New discount',
  'order.invoice_address_updated': 'Order: Invoice address updated',
  'order.invoice_created': 'Order: Invoice created',
  'order.line_deleted': 'Order: Line deleted',
  'order.line_updated': 'Order: Line updated',
  'order.shopify_line_added': 'Order: Line added in Shopify',
  'order.shopify_line_removed': 'Order: Line removed in Shopify',
  'order.locked': 'Order: Locked',
  'order.prepayment_created': 'Order: Prepayment created',
  'order.prepayment_amount_updated': 'Order: Prepayment amount updated',
  'order.prepayment_deleted': 'Order: Deleted prepayment',
  'order.prepayment_paid': 'Order: Prepayment paid',
  'order.prepayment_unpaid': 'Order: Prepayment unpaid',
  'order.prepayment_booked': 'Order: Prepayment booked',
  'order.prepayment_payment_link_sent': 'Order: Prepayment payment link sent',
  'order.product_added': 'Order: Product added',
  'order.product_deleted': 'Order: Product removed',
  'order.product_removed_from_catalogue':
    'Order: Product removed from catalogue',
  'order.reopened': 'Order: Reopened',
  'order.reopened_because_exchange_return_received':
    'Order: Reopened because of received exchange return',
  'order.sent_cancellation_notification':
    'Order: Sent cancellation notification',
  'order.sent_confirmation': 'Order: Sent confirmation',
  'order.sent_shipment_notification': 'Order: Sent shipment notification',
  'order.status_changed': 'Order: New status',
  'order.unlocked': 'Order: Unlocked',
  'order.variant_deleted': 'Order: Variant removed',
  'order.variant_removed_from_catalogue':
    'Order: Variant removed from catalogue',
  'order.variant_updated': 'Order: Variant updated',
  'production_order.created_production_orders': 'Production Order: Created',
  'production_order.created_lines': 'Production Order line: Created',
  'production_order.deleted_lines': 'Production Order Line: Deleted',
  'production_order.updated_lines': 'Production Order Line: Updated',

  'invoice.agents_updated': 'Invoice: Agents updated',
  'invoice.created': 'Order: Invoice created',
  'invoice.booked': 'Order: Invoice booked',

  'credit_note.agents_updated': 'Credit note: Agents updated',

  'product.created': 'Product: New product',
  'product.deleted': 'Product: Removed product',
  'webshop.added_to_basket': 'Webshop: Products added to cart (old event)',
  'webshop.added_new_product_to_cart': 'Webshop: Added new product to cart',
  'webshop.updated_product_in_cart': 'Webshop: Updated product in cart',
  'webshop.opened': 'Webshop: Customer opened webshop',
}

const AddressChangeLabels = {
  name: 'name',
  vat: 'VAT number',
  ean: 'EAN number',
  att: 'attention',
  address: 'address',
  address_2: 'address 2',
  address_name: 'address',
  zip: 'zip code',
  city: 'city',
  region: 'region',
  country: 'country',
  telephone: 'telephone',
}

export { createAction, IsBrandPredicate }
