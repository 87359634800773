const labels = function() {
  return {
    stock: 'in stock',
    remote: 'remotely in stock',
    underway: 'on the way in',
    discontinued: 'left',
  }
}

export default labels
