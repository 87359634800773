/* @flow */

import api from '../api'

import type { BrunoRequestOptions } from '../types'

export const getProductGroups = (options?: BrunoRequestOptions) =>
  api.get(`/product-groups`, {
    query: options,
  })

export const getProductGroupsList = (options?: BrunoRequestOptions) =>
  api.get('/product-groups/list', {
    query: options,
  })
