/* @flow */

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import TruncatedString from '../../../../../infrastructure/bindings/TruncatedString'
import CloudinaryResource from '../../../../../infrastructure/components/CloudinaryResource'
import NewsModal from '../../../../news/NewsModal'

import { getUserEntities } from '../../../../users/api'
import {
  Submenu,
  FakeSubmenuNavigationLink,
  SubmenuNavigationLink,
} from '../shared'

import { SessionContext } from '../../../../shared'

const NavigationRightSideUserProfile = ({
  adminEmail,
  dispatch,
  history,
  inWebshop,
  onRegainAdmin,
  onSwitchEntity,
  settingsPage,
  type,
  useNewColors,
}) => {
  const { entity, user } = useContext(SessionContext)
  const [userEntities, setUserEntities] = useState([])

  useEffect(() => {
    getUserEntities().then(response => {
      if (!response.error) {
        setUserEntities(response.payload)
      }
    })
  }, [])

  const rightNavigationElements = settingsPage ? (
    <div>
      {entity.entity_type === 'shop' && (
        <SubmenuNavigationLink to="/">Brands</SubmenuNavigationLink>
      )}
      {entity.entity_type === 'brand' && (
        <SubmenuNavigationLink to="/">Dashboard</SubmenuNavigationLink>
      )}
    </div>
  ) : (
    <div>
      <SubmenuNavigationLink to="/settings/my">Settings</SubmenuNavigationLink>
    </div>
  )

  return (
    <ProfileMenuContainer type={type}>
      {type === 'shop' ? (
        <InWebshopProfileLink to="/settings/my">
          <span className="lnr lnr-user" />
          <span>{user.firstname}, </span>
          <strong>{entity.name}</strong>
          <b className="caret" />
        </InWebshopProfileLink>
      ) : (
        <ProfileLink to="/settings/my">
          <UserNameContainer>
            <div>
              <EntityName value={entity.name} maxLength={12} />
            </div>
            <div>
              <TruncatedString value={user.firstname} maxLength={12} />
            </div>
          </UserNameContainer>
          <b className="caret" />
        </ProfileLink>
      )}
      <Submenu side="right">
        <EntitiesMenuSection
          entity={entity}
          entityType="agency"
          switchEntity={onSwitchEntity}
          title="Your agency"
          userEntities={userEntities}
        />
        <EntitiesMenuSection
          entity={entity}
          entityType="brand"
          switchEntity={onSwitchEntity}
          title="Brands"
          userEntities={userEntities}
        />
        <EntitiesMenuSection
          entity={entity}
          entityType="shop"
          switchEntity={onSwitchEntity}
          title="Shops"
          userEntities={userEntities}
        />
        {rightNavigationElements}
        {entity.entity_type === 'brand' && (
          <div>
            <SubmenuNavigationLink to="/tasks">Tasks</SubmenuNavigationLink>
          </div>
        )}
        {entity.entity_type === 'brand' && (
          <div>
            <NewsModal
              renderLink={({ unreadNews }) => (
                <SubmenuNavigationLink to="/news">
                  News{' '}
                  {unreadNews.length > 0 && (
                    <span className="label label-sm label-warning">
                      {unreadNews.length}
                    </span>
                  )}
                </SubmenuNavigationLink>
              )}
            />
          </div>
        )}
        {adminEmail && (
          <div>
            <FakeSubmenuNavigationLink tabIndex="-1" onClick={onRegainAdmin}>
              Log back in as Admin
            </FakeSubmenuNavigationLink>
          </div>
        )}
        <div>
          <FakeSubmenuNavigationLink>
            <a target="_blank" href="https://help.traede.com">
              Help Center
            </a>
          </FakeSubmenuNavigationLink>
        </div>
        <div>
          <SubmenuNavigationLink to="/gdpr">GDPR</SubmenuNavigationLink>
        </div>
        <div className="divider" />
        <div>
          <SubmenuNavigationLink to="/logout">Logout</SubmenuNavigationLink>
        </div>
      </Submenu>
    </ProfileMenuContainer>
  )
}

const ConnectedNavigationRightSideUserProfile = connect()(
  NavigationRightSideUserProfile
)

export default ConnectedNavigationRightSideUserProfile

const ProfileMenuContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;

  &:hover ${Submenu} {
    display: block;
  }
`

const ProfileLink = styled(Link)`
  align-items: center;
  color: white;
  display: flex;
  padding: 10px 14px;
  width: 100%;

  &:hover {
    color: white;
  }
`

const InWebshopProfileLink = styled(Link)`
  color: white;
  white-space: nowrap;

  &:hover {
    color: white;
  }
`

const UserNameContainer = styled.div`
  flex: 1;
`

const EntityName = styled(TruncatedString)`
  font-weight: bold;
`

const ProfileImage = styled(CloudinaryResource)`
  border-radius: 50%;
  margin-right: 10px;
`

const EntitiesMenuSection = ({
  entity: sessionEntity,
  entityType,
  switchEntity,
  title,
  userEntities,
}) => {
  const filtered = userEntities.filter(
    entity => entity.entity_type === entityType
  )

  if (userEntities.length === 1 || filtered.length === 0) {
    return []
  }

  return [
    <div key={entityType + '-section-title'}>
      <EntitiesMenuSectionTitle>{title}</EntitiesMenuSectionTitle>
    </div>,
    ...filtered.map(entity => (
      <div key={entity.id}>
        <FakeSubmenuNavigationLink onClick={() => switchEntity(entity.id)}>
          {sessionEntity.id === entity.id ? (
            <strong>{entity.name}</strong>
          ) : (
            entity.name
          )}
        </FakeSubmenuNavigationLink>
      </div>
    )),
    <div key={entityType + '-divider'} className="divider" />,
  ]
}

const EntitiesMenuSectionTitle = styled.span`
  display: block;
  padding: 11px 10px 4px 10px;
  color: #97a5b7;
  font-size: 11px;
  line-height: 13px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: default;
`
