import { CALL_API } from 'redux-api-middleware'

export const SORT_DATATABLE = 'SORT_DATATABLE'
export const CREATE_DATATABLE = 'CREATE_DATATABLE'
export const REFRESH_DATATABLE = 'REFRESH_DATATABLE'
export const PAGINATE_DATATABLE = 'PAGINATE_DATATABLE'

export const ADD_DATATABLE_FILTER = 'ADD_DATATABLE_FILTER'
export const REMOVE_DATATABLE_FILTER = 'REMOVE_DATATABLE_FILTER'

export const INSERT_DATATABLE_DATA = 'INSERT_DATATABLE_DATA'
export const UPDATE_DATATABLE_DATA = 'UPDATE_DATATABLE_DATA'
export const DELETE_DATATABLE_DATA = 'DELETE_DATATABLE_DATA'

export const UPDATE_DATATABLE_VIEW = 'UPDATE_DATATABLE_VIEW'

export const GET_VIEWS_REQUEST = 'GET_VIEWS_REQUEST'
export const GET_VIEWS_SUCCESS = 'GET_VIEWS_SUCCESS'
export const GET_VIEWS_FAILURE = 'GET_VIEWS_FAILURE'

export const CREATE_VIEW_REQUEST = 'CREATE_VIEW_REQUEST'
export const CREATE_VIEW_SUCCESS = 'CREATE_VIEW_SUCCESS'
export const CREATE_VIEW_FAILURE = 'CREATE_VIEW_FAILURE'

export const UPDATE_VIEW_REQUEST = 'UPDATE_VIEW_REQUEST'
export const UPDATE_VIEW_SUCCESS = 'UPDATE_VIEW_SUCCESS'
export const UPDATE_VIEW_FAILURE = 'UPDATE_VIEW_FAILURE'

export const DELETE_VIEW_REQUEST = 'DELETE_VIEW_REQUEST'
export const DELETE_VIEW_SUCCESS = 'DELETE_VIEW_SUCCESS'
export const DELETE_VIEW_FAILURE = 'DELETE_VIEW_FAILURE'

export const REMOVE_DATATABLE = 'REMOVE_DATATABLE'

export const ADD_SELECTION = 'ADD_SELECTION'
export const REMOVE_SELECTION = 'REMOVE_SELECTION'

export const SELECT_ALL = 'SELECT_ALL'
export const DESELECT_ALL = 'DESELECT_ALL'

export const SET_DATATABLE_FILTER_MANAGER_TYPES_VALUES =
  'SET_DATATABLE_FILTER_MANAGER_TYPES_VALUES'

export const createDataTable = (dataKey, options) => ({
  type: CREATE_DATATABLE,
  dataKey,
  options,
})

export const sortDataTable = (dataKey, column, direction) => ({
  type: SORT_DATATABLE,
  dataKey,
  column,
  direction,
})

export const updateDataTable = (dataKey, data) => ({
  type: UPDATE_DATATABLE_DATA,
  dataKey,
  data,
})

export const updateDataTableView = (dataKey, viewId, filterData) => ({
  type: UPDATE_DATATABLE_VIEW,
  dataKey,
  viewId,
  filterData,
})

export const deleteDataTableData = (dataKey, data) => ({
  type: DELETE_DATATABLE_DATA,
  dataKey,
  data,
})

export const paginateDataTable = (dataKey, page) => ({
  type: PAGINATE_DATATABLE,
  dataKey,
  page,
})

export const refreshDataTable = dataKey => ({
  type: REFRESH_DATATABLE,
  dataKey,
})

export const addDatatableFilter = (dataKey, filterName, filterData) => ({
  type: ADD_DATATABLE_FILTER,
  dataKey,
  filterName,
  filterData,
})

export const insertDataTableData = (dataKey, data) => ({
  type: INSERT_DATATABLE_DATA,
  dataKey,
  data,
})

export const removeDataTableFilter = (dataKey, filterName) => ({
  type: REMOVE_DATATABLE_FILTER,
  dataKey,
  filterName,
})

export const setDataTableFilterManagerTypesValues = (
  dataKey,
  types,
  values
) => ({
  type: SET_DATATABLE_FILTER_MANAGER_TYPES_VALUES,
  dataKey,
  types,
  values,
})

export const addSelection = (dataKey, selectionId) => ({
  type: ADD_SELECTION,
  dataKey,
  selectionId,
})

export const removeSelection = (dataKey, selectionId) => ({
  type: REMOVE_SELECTION,
  dataKey,
  selectionId,
})

export const selectAll = dataKey => ({
  type: SELECT_ALL,
  dataKey,
})

export const deselectAll = dataKey => ({
  type: DESELECT_ALL,
  dataKey,
})

export const createDataTableIfNeeded = (dataKey, options) => {
  return (dispatch, getState) => {
    const { datatable } = getState()

    if (!datatable[dataKey]) {
      return dispatch(createDataTable(dataKey, options))
    }
  }
}

export const removeDataTable = dataKey => ({
  type: REMOVE_DATATABLE,
  dataKey,
})

export const INVALIDATE_DATATABLE_DATA = 'INVALIDATE_DATATABLE_DATA'

export const invalidateDatatableData = dataKey => ({
  type: INVALIDATE_DATATABLE_DATA,
})

export const getViews = dataKey => ({
  [CALL_API]: {
    endpoint: '/views',
    method: 'GET',
    query: { datatable: dataKey },
    types: [
      GET_VIEWS_REQUEST,
      {
        type: GET_VIEWS_SUCCESS,
        meta: { dataKey },
      },
      GET_VIEWS_FAILURE,
    ],
  },
})

export const createView = data => ({
  [CALL_API]: {
    endpoint: '/views',
    method: 'POST',
    body: { data: data },
    types: [CREATE_VIEW_REQUEST, CREATE_VIEW_SUCCESS, CREATE_VIEW_FAILURE],
  },
})

export const updateView = (viewId, data) => ({
  [CALL_API]: {
    endpoint: `/views/${viewId}`,
    method: 'PUT',
    body: { data: data },
    types: [
      UPDATE_VIEW_REQUEST,
      {
        type: UPDATE_VIEW_SUCCESS,
        meta: { currentView: viewId },
      },
      UPDATE_VIEW_FAILURE,
    ],
  },
})

export const deleteView = (dataKey, viewId) => ({
  [CALL_API]: {
    endpoint: `/views/${viewId}`,
    method: 'DELETE',
    types: [
      DELETE_VIEW_REQUEST,
      {
        type: DELETE_VIEW_SUCCESS,
        meta: { currentView: viewId, dataKey },
      },
      DELETE_VIEW_FAILURE,
    ],
  },
})

export const DATATABLE_DATA_REQUEST = 'DATATABLE_DATA_REQUEST'
export const DATATABLE_DATA_SUCCESS = 'DATATABLE_DATA_SUCCESS'
export const DATATABLE_DATA_FAILURE = 'DATATABLE_DATA_FAILURE'
export const fetchDataTableData = (dataURL, dataKey, options) => {
  return {
    [CALL_API]: {
      types: [
        {
          type: DATATABLE_DATA_REQUEST,
          meta: {
            dataKey,
            options,
          },
        },
        {
          type: DATATABLE_DATA_SUCCESS,
          meta: {
            dataKey,
            options,
          },
        },
        DATATABLE_DATA_FAILURE,
      ],
      endpoint: dataURL,
      method: 'GET',
      query: options,
    },
  }
}
