/* @flow */

import api from '../api'

import type {
  ApiResponse,
  BrunoRequestOptions,
  ConsignmentElastic,
  DataTableRequestOptions,
  Id,
} from '../types'

// Showrooms

export const getShowroom = (showroom: Id, options?: Object) =>
  api.get(`/showrooms/${showroom}`, {
    query: options,
  })

export const canPlaceOrdersInShowroom = (showroom: Id) =>
  api.get(`/showrooms/${showroom}/place-orders`)

export const getShowroomByHash = (showroom: Id, hash, options?: Object) =>
  api.get(`/showrooms/${showroom}/${hash}`, {
    query: options,
  })

export const getShowroomProducts = (showroom: Id, hash, options?: Object) =>
  api.get(`/showrooms/${showroom}/${hash}/products`, {
    query: options,
  })

export const getShowrooms = (options?: Object) =>
  api.get(`/showrooms`, {
    query: options,
  })

export const createShowroom = (data: Object) =>
  api.post('/showrooms', {
    body: {
      showroom: data,
    },
  })

export const duplicateShowroom = (id: Id, data: Object) =>
  api.post(`/showrooms/${id}/duplicate`, {
    body: data,
  })

export const updateShowroom = (showroomId: Id, data: Object) =>
  api.put(`/showrooms/${showroomId}`, {
    body: {
      showroom: data,
    },
  })

export const deleteShowroom = (showroomId: Id) =>
  api.delete(`/showrooms/${showroomId}`)

export const getShowroomForDocumentData = (showroomId: Id) =>
  api.get(`/showrooms/${showroomId}/document-data`)

export const exportShowroomToExcel = (showroomId: Id, customerId: ?Id) =>
  api.post(`/showrooms/${showroomId}/export-excel`, {
    body: {
      customer_id: customerId,
    },
  })

export const getShowroomSelectionForDocumentData = (showroomSelectionId: Id) =>
  api.get(`/showrooms/selections/${showroomSelectionId}/document-data`)

export const exportShowroomSelectionToExcel = (showroomSelectionId: Id) =>
  api.post(`/showrooms/selections/${showroomSelectionId}/export-excel`)

// Selections

export const getShowroomSelection = (id: Id, options?: Object) =>
  api.get(`/showrooms/selections/${id}`, {
    query: options,
  })

export const exportShowroomSelection = (id: Id) =>
  api.post(`/showrooms/selections/${id}/export`)

export const downloadShowroomSelectionExcel = (id: Id) =>
  api.post(`/showrooms/selections/${id}/download-excel`)

export const getShowroomSelections = (options?: Object) =>
  api.get(`/showrooms/selections`, {
    query: options,
  })

export const getCurrentShowroomSelection = (
  sessionId,
  showroomId: Id,
  options: Object
) =>
  api.get(`/showrooms/${showroomId}/selection/current`, {
    query: {
      session_id: sessionId,
      showroom_id: showroomId,
    },
  })

export const importShowroomSelection = (options: Object) =>
  api.post(`/showrooms/selections/import`, {
    body: options,
  })

export const processShowroomSelection = (selectionId: Id, options: Object) =>
  api.post(`/showrooms/selections/${selectionId}/process`, {
    body: options,
  })

export const processShowroomSelections = (ids: Array, options: Object) =>
  api.post(`/showrooms/selections/process-bulk`, {
    body: { ...options, ids },
  })

export const convertShowroomSelectionToOrder = (
  selectionId: Id,
  options: Object
) =>
  api.post(`/showrooms/selections/${selectionId}/convert`, {
    body: options,
  })

export const updateShowroomSelection = (
  sessionId,
  showroomId: Id,
  data: Object
) =>
  api.post(`/showrooms/${showroomId}/selection`, {
    body: {
      session_id: sessionId,
      showroom_id: showroomId,
      selection: data,
    },
  })

export const submitShowroomSelection = (
  sessionId,
  showroomId: Id,
  placeOrder: boolean,
  emailData,
  quantities
) =>
  api.post(`/showrooms/${showroomId}/selection/submit`, {
    body: {
      session_id: sessionId,
      showroom_id: showroomId,
      place_order: placeOrder,
      email: emailData,
      quantities,
    },
  })

// Tracking

export const getTrackingList = (
  options?: DataTableRequestOptions
): Promise<ApiResponse<{ customers: Array, total: number }>> =>
  api.get('/showrooms/tracking', {
    query: options,
  })

export const getTrackingAttributes = () =>
  api.get('/showrooms/tracking/table-parameters')

export const showroomInvite = (options: Array) =>
  api.post('/showrooms/tracking', {
    body: options,
  })

export const showroomInviteProspect = (options: Array) =>
  api.post('/showrooms/tracking/prospects', {
    body: options,
  })

export const getShowroomTrackingV2List = (
  options?: DataTableRequestOptions
): Promise<
  ApiResponse<{ trackings: Array<ShowroomTracking>, total: number }>
> =>
  api.get('/showrooms/tracking-v2', {
    query: options,
  })

export const getShowroomTrackingV2Attributes = () =>
  api.get('/showrooms/tracking-v2/attributes')

export const getShowroomTrackingV2Totals = (showroomId: Id) =>
  api.get(`/showrooms/${showroomId}/tracking-v2/totals`)

// Currency

export const setShowroomCurrency = (
  brandId: Id,
  showroomId?: Id,
  currency: String
) =>
  api.post(`/showrooms/${brandId}/currency`, {
    body: {
      showroom_id: showroomId,
      currency,
    },
  })

export const getShowroomCurrencies = (brandId: Id, showroomId?: Id) =>
  api.get(`/showrooms/${brandId}/currencies`)

// Session

export const ensureShowroomSession = (
  brandId: Id,
  showroomId?: Id,
  resume: ?Id
) =>
  api.post(`/showrooms/${brandId}/sessions`, {
    body: {
      showroom_id: showroomId,
      resume,
    },
  })

export const showroomSessionSetCustomer = (brandId: Id, customerId: Id) =>
  api.post(`/showrooms/${brandId}/set-customer`, {
    body: {
      customer_id: customerId,
    },
  })

export const ensureAnonymousShowroomSession = (brandId: Id, extraData) =>
  api.post(`/showrooms/${brandId}/sessions/anonymous`, {
    body: {
      extra_data: extraData,
    },
  })

export const getPublicBrand = (id?: Id, options?: Array) =>
  api.get(`/brands/public`, {
    query: {
      brand_id: id,
      options: options || [],
    },
  })

// Prospects

export const showroomCreateProspect = (showroomId?: Id, options: Array) =>
  api.post(`/showrooms/${showroomId}/prospects`, {
    body: options,
  })
export const showroomConvertProspect = (id: Id, options) =>
  api.post(`/showrooms/prospects/${id}/convert`, {
    body: options,
  })

// Metrics

export const showroomMetricsSessions = (options: Array) =>
  api.get(`/showrooms/metrics/sessions`, {
    query: options,
  })

export const showroomMetricsOrders = (options: Array) =>
  api.get(`/showrooms/metrics/orders`, {
    query: options,
  })

export const showroomMetricsOrdersTotal = (options: Array) =>
  api.get(`/showrooms/metrics/orders-total`, {
    query: options,
  })

export const showroomMetricsOrdersAverage = (options: Array) =>
  api.get(`/showrooms/metrics/orders-average`, {
    query: options,
  })

export const showroomMetricsTopLocations = (options: Array) =>
  api.get(`/showrooms/metrics/locations`, {
    query: options,
  })

export const showroomMetricsTopCategories = (options: Array) =>
  api.get(`/showrooms/metrics/categories`, {
    query: options,
  })

export const showroomMetricsTopStyles = (options: Array) =>
  api.get(`/showrooms/metrics/styles`, {
    query: options,
  })

export const showroomMetricsTopAttributes = (options: Array) =>
  api.get(`/showrooms/metrics/attributes`, {
    query: options,
  })
