/* @flow */

import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import memo from 'memoize-one'

import LeadTimeLabel from '../../../../LeadTimeLabel'
import CustomerFacingDeliveryDate from './CustomerFacingDeliveryDate'

import {
  addPrefixIfNoneExists,
  renderDate,
  Tooltip,
  SplitComponent,
} from '../../../../../../shared'

const ProductTableRowsDeliveryList = ({
  brand,
  lastDeliveryOnly,
  isCustomerFacingDeliveryStatus,
  status: statusProps,
  userEntity,
}) => {
  // Delivery status
  // When showing delivery status facing customers, e.g. on Order Confirmation,
  // we want to accumulate all deliveries with same delivery date. E.g. if we have
  // 3 POs all being delivered in November 2024 when these deliveries should collapse
  // into one instead of showing 3 separate deliveries with same delivery date
  //
  // Available
  // In B2B the availability endpoint will return a delivery per inventory location
  // When selling we want to show this is "one location", so we combine deliveries
  // of the same location into 1 location
  const status = React.useMemo(() => {
    if (!statusProps) {
      return null
    }

    // we need to sort deliveries before merging in case we are using lastDeliveryOnly
    const deliveries = sortDeliveries(
      statusProps.deliveries,
      isCustomerFacingDeliveryStatus
    )

    let mergedDeliveries = {}
    for (let delivery of deliveries) {
      let key = `${delivery.location_label}-${delivery.production_order_id}-${delivery.production_order_delivery_note_id}`
      // we only want to show the last delivery
      if (lastDeliveryOnly) {
        key = '1'
      } else if (isCustomerFacingDeliveryStatus) {
        key = `${delivery.location_label}-${
          delivery.production_order_id !== null ? 'po' : 'inventory'
        }-${delivery.delivery_date}`
      }

      let quantity = 0
      if (delivery.quantity !== null) {
        if (delivery.quantity > 0) {
          quantity = delivery.quantity
        }
      } else if (delivery.received_quantity !== null) {
        if (delivery.received_quantity > 0) {
          quantity = delivery.received_quantity
        }
      }

      // we do not want to show 0 quantities
      if (quantity == 0) {
        continue
      }

      if (!mergedDeliveries[key]) {
        mergedDeliveries[key] = { ...delivery }

        if (delivery.quantity !== null) {
          mergedDeliveries[key].quantity = 0
        } else if (delivery.received_quantity !== null) {
          mergedDeliveries[key].received_quantity = 0
        }
      }

      if (delivery.quantity !== null) {
        mergedDeliveries[key].quantity += quantity
      } else if (delivery.received_quantity !== null) {
        mergedDeliveries[key].received_quantity += quantity
      }

      if (lastDeliveryOnly) {
        mergedDeliveries[key].delivery_date = delivery.delivery_date
      }
    }

    let mappedStatus = {
      ...statusProps,
      deliveries: Object.values(mergedDeliveries),
    }
    delete mappedStatus.inventory_location_id

    // when we show last delivery only, and we have items that fall under
    // lead time, then we should show lead time instead of delivery date
    if (
      lastDeliveryOnly &&
      statusProps.left_to_allocate > 0 &&
      statusProps.lead_time
    ) {
      mappedStatus = {
        ...mappedStatus,
        deliveries: [],
        left_to_allocate:
          statusProps.left_to_allocate +
          statusProps.allocated_from_inventory +
          statusProps.allocated_from_production_orders +
          statusProps.allocated_from_production_orders_delivery_notes,
        allocated_from_inventory: 0,
        allocated_from_production_orders: 0,
        allocated_from_production_orders_delivery_notes: 0,
      }
    }

    return mappedStatus
  }, [isCustomerFacingDeliveryStatus, lastDeliveryOnly, statusProps])

  if (!status) {
    return null
  }

  const sortedDeliveries = sortDeliveries(
    status.deliveries,
    isCustomerFacingDeliveryStatus
  )

  return (
    <div>
      <div>
        {(status.deliveries.length > 0 || status.lead_time) && (
          <DeliveryList
            multi={
              sortedDeliveries.length > 1 ||
              (sortedDeliveries.length > 0 &&
                status.lead_time &&
                status.left_to_allocate > 0)
            }
          >
            {sortedDeliveries.map(delivery => {
              return (
                <li key={delivery.id}>
                  {!isCustomerFacingDeliveryStatus && (
                    <DeliveryStatusWithProductionOrder
                      delivery={delivery}
                      leadTime={status.lead_time}
                      multipleRows={sortedDeliveries.length > 1}
                      userEntity={userEntity}
                    />
                  )}
                  {isCustomerFacingDeliveryStatus && (
                    <DeliveryStatusWithoutProductionOrder
                      brand={brand}
                      delivery={delivery}
                      leadTime={status.lead_time}
                    />
                  )}
                </li>
              )
            })}

            {status.lead_time && status.left_to_allocate > 0 && (
              <li>
                <div>
                  <span>{status.left_to_allocate} x </span>
                  <LeadTimeLabel
                    from={status.lead_time.lead_time_from}
                    to={status.lead_time.lead_time_to}
                    type={status.lead_time.lead_time_type}
                  />
                </div>
              </li>
            )}
          </DeliveryList>
        )}
      </div>
    </div>
  )
}

export default ProductTableRowsDeliveryList

const DeliveryList = styled.ul`
  list-style-type: ${({ multi }) => (multi ? 'disc' : 'none')};
  margin-bottom: 0;
  padding-left: ${({ multi }) => (multi ? `20px` : 0)};

  li {
    padding-left: 0;
    white-space: nowrap;
  }
`

const DeliveryDateLabel = styled.div`
  font-size: 10px;
`

const sortDeliveries = memo((deliveries, isCustomerFacingDeliveryStatus) => {
  let filteredDeliveries = [...deliveries]

  if (isCustomerFacingDeliveryStatus) {
    // we dont want to show received POs when not showing production order
    filteredDeliveries = filteredDeliveries.filter(d => {
      if (d.production_order_id && d.received_quantity !== null) {
        return false
      }

      return true
    })

    filteredDeliveries = filteredDeliveries.filter(d => {
      return d.delivery_date !== null
    })
  }

  return sortBy(filteredDeliveries, delivery => {
    const received = delivery.received_quantity !== null
    const isDeliveryNote = delivery.production_order_delivery_note_id !== null
    const isStock =
      delivery.production_order_id === null &&
      delivery.production_order_delivery_note_id === null
    const expectedDelivery =
      delivery.delivery_date !== null
        ? new Date(delivery.delivery_date).getTime()
        : 9999999999999

    return `${isStock ? '0' : '1'}-${isDeliveryNote ? '0' : '1'}-${
      received ? '0' : '1'
    }-${expectedDelivery}`
  })
})

const DeliveryStatusWithProductionOrder = ({
  delivery,
  leadTime,
  multipleRows,
  userEntity,
}) => {
  const isBrand = userEntity ? userEntity.entity_type === 'brand' : 'brand'

  return (
    <div>
      {delivery.production_order_delivery_note_id && (
        <div>
          <span>
            {delivery.received_quantity || delivery.quantity}
            {isBrand && ` x `}
          </span>
          {isBrand && (
            <>
              <Link
                to={`/production/orders/delivery-notes/${delivery.production_order_delivery_note_id}`}
              >
                {addPrefixIfNoneExists(
                  delivery.production_order_delivery_note_number,
                  'PO: '
                )}
              </Link>
              <Notes notes={delivery.notes} />
            </>
          )}
          {/* In the NORR11 system it's called delivery_date, but elsewhere it's called expected_delivery (great stuff) */}
          {!delivery.received_quantity &&
            delivery.delivery_date &&
            !delivery.expected_delivery && (
              <DeliveryDateLabel>
                Exp. {renderDate(delivery.delivery_date)}
              </DeliveryDateLabel>
            )}
          {!delivery.received_quantity &&
            !delivery.delivery_date &&
            delivery.expected_delivery && (
              <DeliveryDateLabel>
                Exp. {renderDate(delivery.expected_delivery)}
              </DeliveryDateLabel>
            )}
          {delivery.received_quantity && <div>Received</div>}
        </div>
      )}

      {!delivery.production_order_delivery_note_id &&
        delivery.production_order_id && (
          <>
            <div>
              <span>
                {delivery.quantity}
                {isBrand && ` x `}
              </span>
              {isBrand && (
                <>
                  <Link
                    to={`/production/orders/${delivery.production_order_id}`}
                  >
                    {addPrefixIfNoneExists(
                      delivery.production_order_number,
                      'PO: '
                    )}
                  </Link>
                  <Notes notes={delivery.notes} />
                </>
              )}
            </div>
            {delivery.delivery_date && !delivery.expected_delivery && (
              <DeliveryDateLabel>
                Exp. {renderDate(delivery.delivery_date)}
              </DeliveryDateLabel>
            )}
            {!delivery.delivery_date && delivery.expected_delivery && (
              <DeliveryDateLabel>
                Exp. {renderDate(delivery.expected_delivery)}
              </DeliveryDateLabel>
            )}
          </>
        )}

      {!delivery.production_order_delivery_note_id &&
        !delivery.production_order_id && (
          <>
            {delivery.received_quantity && (
              <div>
                {delivery.received_quantity}
                {` x ${delivery.location_label || 'stock'}`}
              </div>
            )}
          </>
        )}
    </div>
  )
}

const DeliveryStatusWithoutProductionOrder = ({
  brand,
  delivery,
  leadTime,
}) => {
  let deliveryDate = delivery.expected_delivery
  let quantity = delivery.quantity
  const isStock =
    delivery.production_order_id === null &&
    delivery.production_order_delivery_note_id === null

  return (
    <div>
      {isStock && delivery.received_quantity > 0 && (
        <InStockLabel delivery={delivery} />
      )}

      {!isStock && deliveryDate && (
        <span>
          <span>{quantity} pcs.</span>{' '}
          <CustomerFacingDeliveryDate
            brand={brand}
            deliveryDate={deliveryDate}
          />
        </span>
      )}
      {!isStock && !deliveryDate && (
        <span>
          <span>{quantity} pcs.</span>{' '}
          {!leadTime && (
            <Tooltip
              id="in-prod-type"
              tip="There are pieces currently production but they have no delivery date as of yet"
            >
              <span style={{ borderBottom: '1px dashed #d4d4d4' }}>
                in prod
              </span>
            </Tooltip>
          )}
          {leadTime && (
            <LeadTimeLabel
              from={leadTime.lead_time_from}
              to={leadTime.lead_time_to}
              type={leadTime.lead_time_type}
            />
          )}
        </span>
      )}
    </div>
  )
}

const Notes = ({ notes }) => {
  if (!notes) {
    return null
  }

  return (
    <span className="print-hide">
      <Tooltip id="delivery-notes" tip={notes}>
        <NotesIcon />
      </Tooltip>
    </span>
  )
}

const NotesIcon = styled.span.attrs({ className: 'fa fa-comment' })`
  font-size: 10px;
  margin-left: 5px;
`

const InStockLabel = ({ delivery }) => {
  return (
    <span style={{ color: delivery.location_color }}>{`${
      delivery.received_quantity
    } ${delivery.location_label || 'stock'}`}</span>
  )
}
