/* @flow */

import React, { Component, useMemo } from 'react'
import Select from 'react-select'
import sortBy from 'lodash/sortBy'

import { useCachedTags } from '../hooks'

import type { Tag } from '../../types'

const TagSelector = (props: any) => {
  const [tags, isFetching] = useCachedTags()

  return <DatalessTagSelector isFetching={isFetching} tags={tags} {...props} />
}

export default TagSelector

export const DatalessTagSelector = ({ isFetching, tags, ...props }) => {
  const options = useMemo(() => {
    let options = sortBy(tags, 'name')

    return options
  }, [tags])

  return (
    <Select
      isLoading={isFetching}
      labelKey="name"
      options={options}
      valueKey="id"
      {...props}
    />
  )
}
