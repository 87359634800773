/* @flow */

import React from 'react'
import moment from 'moment'
import { List, Map } from 'immutable'
import { Modal } from 'react-bootstrap'

import ActionButton from '../../../infrastructure/components/ActionButton'
import Globalize from '../../../infrastructure/modules/globalize'
import { UserHasPermissions } from '../../../infrastructure/components/Authorization'
import { getPrice } from '../../products/components/Price'
import type {
  BrandSettings,
  Customer,
  Quantities,
  Product,
  User,
  Variant,
} from '../../types'
import {
  createLineKeyFromOrderLine,
  getQuantityForVariant,
} from '../../products/components/ProductTable/shared'

export type Totals = {
  discount_amount: number,
  discount_percentage: number,
  price: number,
  price_before_discount: number,
  price_of_discounted_items: number,
  quantity: number,
}

// Converts a quantities map to totals using a product and variant catalouge
export const calculateProductTableTotals = (
  products: { [number]: Product }, // TODO: remove products as we don't need 'em?
  variants: { [number]: Variant },
  quantities: Quantities,
  currency: string
): Totals => {
  const totals = quantities.reduce(
    (carry, productQuantities, productId) => {
      const productIterator = productQuantities.entries()
      for (var [variantId, variantQuantity] of productIterator) {
        const variant = variants[variantId]

        if (!variant || !variant.prices[currency]) {
          continue
        }

        let priceBeforeDiscount = 0
        let quantity = 0
        let price = 0
        const splits = variantQuantity.get('price_splits', false)
        // The variant has no split prices so we just use its quantity and total price
        if (!splits) {
          quantity = toQuantityTotal(variantQuantity)
          price = quantity * getPrice(variant.prices[currency])
          priceBeforeDiscount = price

          // There are split prices. We loop through each of them and sum up the quantity and prices.
        } else {
          const originalPrice = variant.prices[currency].sales_price

          const splitIterator = splits.entries()
          for (var [splitPrice, split] of splitIterator) {
            const splitQuantity = toQuantityTotal(split)
            const totalSplitPrice = splitQuantity * parseFloat(splitPrice)

            quantity += splitQuantity
            price += totalSplitPrice
            priceBeforeDiscount += splitQuantity * originalPrice
            // We do not wish to include splits that are equal the original price
            if (parseFloat(splitPrice) !== parseFloat(originalPrice)) {
              carry.price_of_discounted_items += totalSplitPrice
            }
          }
        }

        carry.price_before_discount += priceBeforeDiscount
        carry.quantity += quantity
        carry.price += price
      }

      return carry
    },
    {
      price_before_discount: 0,
      price_of_discounted_items: 0,
      quantity: 0,
      price: 0,
    }
  )

  totals.discount_amount = totals.price_before_discount - totals.price
  totals.discount_percentage =
    totals.discount_amount > 0
      ? parseFloat(
          (
            (100 / totals.price_before_discount) *
            (totals.price_before_discount - totals.price)
          ).toFixed(2)
        )
      : 0

  return totals
}

export const toQuantityTotal = (quantity: Quantity): number => {
  let colli = quantity.get('colli', 1)

  // Colli can be undefined from discount modal
  colli = colli !== undefined ? colli : 1

  return quantity.get('quantity', 0) * colli
}

// Convert a List of products into an object of variants keyed by ID
export const extractVariants = (products: List<Product>) =>
  products.reduce((carry, product) => {
    return product.variants.reduce((innerCarry, variant) => {
      innerCarry[variant.id] = variant
      return innerCarry
    }, carry)
  }, {})

export const formatDate = Globalize.dateFormatter({ date: 'short' })
export const formatDateAndTime = Globalize.dateFormatter({ datetime: 'short' })

export const calculateExpirationDate = (
  invoiceDate,
  paymentTermType,
  paymentTermModifier
) => {
  let expirationDate = moment(invoiceDate)

  const weekdayWithStartWeekday = (expDate, targetWeekDay, startDayOfWeek) => {
    const weekday = (expDate.day() + 7 - startDayOfWeek) % 7
    return expDate.add(targetWeekDay - weekday, 'days')
  }

  if (paymentTermType === 'net') {
    expirationDate.add(paymentTermModifier, 'days')
  }

  if (paymentTermType === 'invoice_month') {
    if (paymentTermModifier == 0) {
      expirationDate.endOf('month')
    } else {
      expirationDate
        .startOf('month')
        .add(1, 'months')
        .add(paymentTermModifier - 1, 'days')
    }
  }

  if (paymentTermType === 'invoice_week_monday') {
    if (paymentTermModifier == 0) {
      // last day of week starting on Monday
      expirationDate = weekdayWithStartWeekday(expirationDate, 6, 1)
    } else {
      // last day of week starting on Monday + modifier days
      expirationDate = weekdayWithStartWeekday(expirationDate, 6, 1)
      expirationDate.add(paymentTermModifier, 'days')
    }
  }

  if (paymentTermType === 'invoice_week_sunday') {
    if (paymentTermModifier == 0) {
      // last day of week starting on Sunday
      expirationDate = weekdayWithStartWeekday(expirationDate, 6, 0)
    } else {
      // last day of week starting on Sunday + modifier days
      expirationDate = weekdayWithStartWeekday(expirationDate, 6, 0)
      expirationDate.add(paymentTermModifier, 'days')
    }
  }

  return expirationDate.toDate()
}

export const removeProductsFromQuantities = (
  quantities: Quantities,
  products: List<Product>
) => {
  // Iterate through the products collection and remove
  // the given variants from the quantity map
  return products.reduce((carry, product) => {
    const productQuantities = carry.get(product.id)

    // If that product does not have any quantity, lets don't do anything
    if (productQuantities === undefined) {
      return carry
    }

    // We loop through all variants. If the product quantity map has a key we remove it.
    const newProductQuantities = product.variants.reduce(
      (innerCarry, variant) => {
        if (innerCarry.has(variant.id)) {
          return innerCarry.delete(variant.id)
        }
        return innerCarry
      },
      productQuantities
    )

    // After the filtering of variants from the quantity map, we remove the
    // product key if there are no keys left
    if (newProductQuantities.size === 0) {
      return carry.delete(product.id)
    }

    return productQuantities === newProductQuantities
      ? carry
      : carry.set(product.id, newProductQuantities)
  }, quantities)
}

export const removeZeroQuantities = (quantities: Quantities): Quantities => {
  // Filter out variantIds with zero quantity
  let result = quantities.map(productQuantities => {
    return productQuantities.filter(quantity => {
      return quantity.get('quantity') > 0
    })
  })

  // Filter out productId which does not have any variantId anymore
  return result.filter(productQuantities => productQuantities.size > 0)
}

type Lines = {
  [string]: {
    quantity: number,
    price_splits?: {
      [string]: {
        quantity: number,
      },
    },
  },
}

export const toLinesWithoutColli = (quantities: Quantities): Lines => {
  return quantities.reduce((carry, productQuantities) => {
    productQuantities.forEach((quantity: Quantity, variantId) => {
      const quantityTotal = toQuantityTotal(quantity)

      if (!isNaN(quantityTotal) && quantityTotal > 0) {
        carry[variantId] = { quantity: quantityTotal }

        if (quantity.get('price_splits')) {
          carry[variantId].price_splits = quantity
            .get('price_splits')
            .map(priceSplitQuantity => ({
              quantity: toQuantityTotal(priceSplitQuantity),
            }))
            .toJS()
        }
      }
    })
    return carry
  }, {})
}

export const generateLineIndexFromOrderLines = orderLines =>
  orderLines.reduce((carry, orderLine) => {
    carry[createLineKeyFromOrderLine(orderLine)] = orderLine.id
    return carry
  }, {})

export const isNewOrderAllowedWhenCreditLimitExceeded = (
  brandSettings: BrandSettings,
  user: User
) => {
  const rule = brandSettings.credit_limit_exceeded_rules.allow_new_order
  return (
    rule === 'always' ||
    (rule === 'check_permission' &&
      UserHasPermissions(user, 'orders:allow_new_when_credit_limit_exceeded'))
  )
}

export const isNewInvoiceAllowedWhenCreditLimitExceeded = (
  brandSettings: BrandSettings,
  user: User
) => {
  const rule = brandSettings.credit_limit_exceeded_rules.allow_new_invoice
  return (
    rule === 'always' ||
    (rule === 'check_permission' &&
      UserHasPermissions(
        user,
        'orders:invoices:allow_new_when_credit_limit_exceeded'
      ))
  )
}

export const isNewShipmentAllowedWhenCreditLimitExceeded = (
  brandSettings: BrandSettings,
  user: User
) => {
  const rule = brandSettings.credit_limit_exceeded_rules.allow_new_shipment
  return (
    rule === 'always' ||
    (rule === 'check_permission' &&
      UserHasPermissions(
        user,
        'orders:shipments:allow_new_when_credit_limit_exceeded'
      ))
  )
}

type ExceededCreditLimitWarningModalProps = {
  customer: Customer,
  objectType: 'invoice' | 'order' | 'shipment',
  onHide: () => void,
  onOverride: () => void,
  show: boolean,
}

export const ExceededCreditLimitWarningModal = ({
  apiResponse,
  objectType,
  onHide,
  onOverride,
  show,
}: ExceededCreditLimitWarningModalProps) => {
  let canOverride = false
  if (apiResponse.errors[0].meta) {
    canOverride = apiResponse.errors[0].meta.can_override || false
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <p>{apiResponse.errors[0].detail}</p>
      </Modal.Body>
      <Modal.Footer>
        {!canOverride && (
          <button type="button" className="btn btn-white" onClick={onHide}>
            Close
          </button>
        )}
        {canOverride && (
          <>
            <button type="button" className="btn btn-white" onClick={onHide}>
              Cancel
            </button>

            <ActionButton
              type="button"
              className="btn btn-success"
              onClick={onOverride}
            >
              I understand, make new {objectType} anyway
            </ActionButton>
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}
