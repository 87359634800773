/* @flow */

import React, { Component } from 'react'
import styled from 'styled-components'

import {
  ControlLabel,
  DateInput,
  FormInput,
  RadioInput,
  SelectInput,
} from '../../Formik'
import { FieldGroup } from './shared'

import type { FilterOperator } from '../../../../app/types'

type Props = {
  operator: FilterOperator,
  value: Array<string>,
}

export default class DateFilter extends Component<Props, void> {
  render() {
    const { operator, value } = this.props

    // we always use array - even for the values that only require
    // one value. This is because when we switch between operators
    // and go from arrays to string and vice versa it can break the UI

    return (
      <div>
        <FieldGroup>
          <RadioInput
            id="filter_date_operator_no_value"
            name="operator"
            label="no value"
            value="no_value"
          />
        </FieldGroup>

        <FieldGroup>
          <RadioInput
            id="filter_date_operator_has_value"
            name="operator"
            label="has value"
            value="has_value"
          />
        </FieldGroup>

        <FieldGroup>
          <RadioInput
            id="filter_date_operator_lt"
            name="operator"
            label="before"
            value="lt"
          />
          {operator === 'lt' && (
            <>
              <DateInput name="value[0]" />
            </>
          )}
        </FieldGroup>

        <FieldGroup>
          <RadioInput
            id="filter_date_operator_gt"
            name="operator"
            label="after"
            value="gt"
          />
          {operator === 'gt' && (
            <>
              <DateInput name="value[0]" />
            </>
          )}
        </FieldGroup>

        <FieldGroup>
          <RadioInput
            id="filter_date_operator_bt"
            name="operator"
            label="between"
            value="bt"
          />
          {operator === 'bt' && (
            <>
              <DateInput name="value[0]" />
              <DateInput name="value[1]" />
            </>
          )}
        </FieldGroup>

        <FieldGroup>
          <RadioInput
            id="filter_date_operator_relative_2"
            name="operator"
            label="relative"
            value="date_relative_2"
          />
          {operator === 'date_relative_2' && (
            <SelectInput
              name="value[0]"
              options={relativeOptions}
              multiParseInt={false}
              simpleValue
            />
          )}
        </FieldGroup>

        <FieldGroup>
          <RadioInput
            id="filter_date_operator_past"
            name="operator"
            label="past"
            value="date_past"
          />
          {operator === 'date_past' && (
            <>
              <FormInput name="value[0]" />
              <SelectInput
                name="value[1]"
                options={pastFutureTypes}
                simpleValue
              />
            </>
          )}
        </FieldGroup>

        <FieldGroup>
          <RadioInput
            id="filter_date_operator_future"
            name="operator"
            label="future"
            value="date_future"
          />
          {operator === 'date_future' && (
            <>
              <FormInput name="value[0]" />
              <SelectInput
                name="value[1]"
                options={pastFutureTypes}
                simpleValue
              />
            </>
          )}
        </FieldGroup>
      </div>
    )
  }
}

export const createDateFilterLabel = (type, value) => {
  let str = ''
  if (type === 'date_relative_2' || type === 'date_relative') {
    str += `${relativeOperatorToLabel[value]}`
  } else if (type === 'date_past') {
    str += `past ${value[0]} ${pastFutureTypeLabels[value[1] || 'd']}`
  } else if (type === 'date_future') {
    str += `next ${value[0]} ${pastFutureTypeLabels[value[1] || 'd']}`
  }
  return str
}

const relativeOptions = [
  { label: 'Past week', value: 'past_week' },
  { label: 'Past month', value: 'past_month' },
  { label: 'Past quarter', value: 'past_quarter' },
  { label: 'Past year', value: 'past_year' },
  { label: 'Today', value: 'today' },
  { label: 'This week', value: 'this_week' },
  { label: 'This month', value: 'this_month' },
  { label: 'This year', value: 'this_year' },
]

const relativeOperatorToLabel = {
  past_week: 'past week',
  past_month: 'past month',
  past_quarter: 'past quarter',
  past_year: 'past year',
  today: 'today',
  this_week: 'this week',
  this_month: 'this month',
  this_year: 'this year',
}

const pastFutureTypes = [
  { label: 'Days', value: 'd' },
  { label: 'Weeks', value: 'w' },
  { label: 'Months', value: 'M' },
]

const pastFutureTypeLabels = pastFutureTypes.reduce((carry, type) => {
  carry[type.value] = type.label
  return carry
}, {})
