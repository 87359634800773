/* @flow */

import React, { Component } from 'react'
import type { Element } from 'react'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, Field } from 'redux-form'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import keyBy from 'lodash/keyBy'

import CloudinaryResource from '../../infrastructure/components/CloudinaryResource'
import CurrencySelector from '../../infrastructure/components/CurrencySelector'
import {
  BooleanInput,
  ControlLabel,
  ErrorLabel,
  FormInput,
  FormGroup,
  SaveButton,
  SelectInput,
} from '../../infrastructure/components/Forms'
import CustomerSelector from '../customers/components/CustomerSelector'
import { resolveCountrySettings } from '../customers/shared'
import FieldTypeSelector from '../custom-fields/components/FieldTypeSelector'
import DropDeliveryDate from '../orders/components/DropDeliveryDate'
import { getCountrySettingsAdminList } from '../settings/api'
import { renderDate } from '../shared'

import type {
  Currency,
  CustomField,
  Customer,
  ConnectedBrand,
  Drop as DropType,
  Entity,
  Id,
  User,
  WebshopSession,
  WebshopSettings,
} from '../types'

type Props = {
  brand: ConnectedBrand,
  customFields: Array<CustomField>,
  customers: Array<Customer>,
  customer_id_value: ?Id,
  drops: Array<DropType>,
  dropshipping_value: boolean,
  entity: Entity,
  handleSubmit: Function,
  onHide: Function,
  show: boolean,
  submitting: boolean,
  webshopSession: WebshopSession,
  webshopSettings: WebshopSettings,
}

const WebshopSplashModal = ({
  brand,
  customers,
  customFields,
  customer_id_value,
  drops,
  dropshipping_value,
  entity,
  handleSubmit,
  onHide,
  show,
  submitting,
  webshopSession,
  webshopSettings,
}: Props) => {
  const dropsFiltered = React.useMemo(() => {
    return drops.filter(d => d.active)
  }, [drops])
  const welcomeText = webshopSettings.splash_custom_description ? (
    <div
      dangerouslySetInnerHTML={{
        __html: webshopSettings.splash_custom_description_content,
      }}
    />
  ) : (
    <div>Welcome to {brand.name}'s B2B webshop.</div>
  )

  const inputFields = webshopSettings.splash_fields
  const inputMetaFields = webshopSettings.splash_meta_fields

  const customFieldsByInternalName = keyBy(
    customFields.filter(c => c.group_type === 'order'),
    'internal_name'
  )

  const [countrySettings, setCountrySettings] = React.useState(null)

  // useEffect instead useCountrySettings, because we want to fetch
  // country settings only for brand/agent, not retailer
  React.useEffect(() => {
    if (entity.entity_type === 'brand') {
      getCountrySettingsAdminList().then(response => {
        if (!response.error) {
          setCountrySettings(response.payload.settings)
        }
      })
    }
  }, [])

  const { customer, currencies } = React.useMemo(() => {
    let customer
    let currencies = brand.currencies

    if (
      entity.entity_type === 'brand' &&
      customers &&
      customer_id_value &&
      countrySettings
    ) {
      customer = customers.filter(c => c.id === customer_id_value)[0]

      if (customer) {
        const allowedCurrency = resolveCountrySettings(
          countrySettings,
          customer,
          'currency',
          null
        )

        if (allowedCurrency) {
          currencies = [allowedCurrency]
        }
      }
    } else if (entity.entity_type === 'shop' && webshopSession.customer) {
      customer = webshopSession.customer

      if (customer.currency) {
        currencies = [customer.currency]
      }
    }

    return { customer, currencies }
  }, [
    brand,
    entity,
    customers,
    customer_id_value,
    countrySettings,
    webshopSession,
  ])

  return (
    <Modal show={show} onHide={onHide} bsSize="large">
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <LogoContainer>
            <CloudinaryResource
              brandId={brand.id}
              id={brand.logo}
              presets="logo_webshop"
              fallback="logo_black"
            />
          </LogoContainer>

          <FormGroup>{welcomeText}</FormGroup>

          {inputFields.indexOf('customer_id') !== -1 &&
            entity.entity_type === 'brand' && (
              <FormGroup>
                <ControlLabel required>Customer</ControlLabel>
                <Field name="customer_id" component={createCustomerSelector} />
              </FormGroup>
            )}

          {inputFields.indexOf('currency') !== -1 &&
            (entity.entity_type === 'brand' ||
              (entity.entity_type === 'shop' &&
                currencies &&
                currencies.length > 1)) && (
              <FormGroup>
                <ControlLabel required>Currency</ControlLabel>
                <Field
                  name="currency"
                  component={CreateCurrencySelector}
                  currencies={currencies}
                />
              </FormGroup>
            )}

          {inputMetaFields.length > 0 && (
            <div>
              {inputMetaFields.map(internalName => {
                const customField = customFieldsByInternalName[internalName]

                if (!customField) {
                  return null
                }

                return (
                  <FormGroup>
                    <ControlLabel required>{customField.label}</ControlLabel>
                    <Field
                      customField={customField}
                      name={`meta.${internalName}`}
                      component={createCustomFieldSelector}
                    />
                  </FormGroup>
                )
              })}
            </div>
          )}

          {customer && customer.dropshipping === true && (
            <FormGroup>
              <ControlLabel>Dropshipping order</ControlLabel>

              <BooleanInput name="dropshipping" />

              <div className="alert alert-info" style={{ marginTop: 20 }}>
                <div>A dropshipping order is a B2C order to end consumers.</div>
                <div>
                  Use this to make an order that is delivered directly from{' '}
                  {brand.name} to one of your B2C customers.
                </div>
              </div>
            </FormGroup>
          )}

          {dropshipping_value !== true &&
            inputFields.indexOf('drop_id') !== -1 && (
              <FormGroup>
                <ControlLabel required>Drop</ControlLabel>
                <Field
                  drops={dropsFiltered}
                  name="drop_id"
                  component={createDropSelector}
                />
              </FormGroup>
            )}
        </Modal.Body>
        <Modal.Footer>
          <SaveButton submitting={submitting}>Save</SaveButton>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export const shouldShowSplashForMe = (
  webshopSettings: WebshopSettings,
  entity: Entity,
  user: User
) => {
  if (!webshopSettings.splash_show) {
    return false
  }

  const requiredFor = webshopSettings.splash_required_for
  let requiredForKey = 'brand'
  if (entity.entity_type === 'brand') {
    const key = user.user_type === 'agent' ? 'agents' : 'brand'
    return requiredFor[key]
  } else {
    return requiredFor['retailers']
  }
}

const formKey = 'webshop_splash'

const WebshopSplashModalForm = reduxForm({
  enableReinitialize: true,
  form: formKey,
  validate: (values, props) => {
    const errors: Object = { meta: {} }

    const entity = props.entity
    const webshopSession = props.webshopSession
    const webshopSettings = props.brand.settings.webshop_settings

    const inputFields = webshopSettings.splash_fields
    const inputMetaFields = webshopSettings.splash_meta_fields

    const customerIsRequired =
      entity.entity_type !== 'shop' && inputFields.indexOf('customer_id') !== -1
    const currencyIsRequired =
      entity.entity_type !== 'shop' && inputFields.indexOf('currency') !== -1
    const dropIsRequired =
      inputFields.indexOf('drop_id') !== -1 && values.dropshipping !== true

    if (customerIsRequired && !values.customer_id) {
      errors.customer_id = 'Please select a customer'
    }

    if (currencyIsRequired && !values.currency) {
      errors.currency = 'Please select a currency'
    }

    if (dropIsRequired && !values.drop_id) {
      errors.drop_id = 'Please select a drop'
    }

    inputMetaFields.forEach(key => {
      if (!values.meta || !values.meta[key]) {
        errors.meta[key] = 'Please fill out'
      }
    })

    return errors
  },
})(WebshopSplashModal)

export default connect(state => {
  const selector = formValueSelector(formKey)

  return {
    customer_id_value: selector(state, 'customer_id'),
    dropshipping_value: selector(state, 'dropshipping'),
  }
})(WebshopSplashModalForm)

const LogoContainer = styled.div`
  margin-bottom: 10px;
  text-align: center;
`

type CreateCurrencySelectorProps = {
  currencies: ?Array<Currency>,
  input: {
    onChange: Function,
    value: ?Currency,
  },
  meta: {
    error: string,
    touched: boolean,
  },
}

class CreateCurrencySelector extends Component<
  CreateCurrencySelectorProps,
  void
> {
  componentWillReceiveProps(nextProps: CreateCurrencySelectorProps) {
    if (nextProps.currencies !== this.props.currencies) {
      this.props.input.onChange(
        nextProps.currencies ? nextProps.currencies[0] : null
      )
    }
  }

  render() {
    const {
      currencies,
      input: { onChange, value },
      meta: { touched, error },
    } = this.props

    return (
      <div>
        <CurrencySelector
          currencies={currencies}
          onChange={onChange}
          value={value}
        />
        {touched && error && <ErrorLabel>{error}</ErrorLabel>}
      </div>
    )
  }
}

const createCustomerSelector = ({
  input: { onChange, value },
  meta: { touched, error },
}): Element<'div'> => {
  return (
    <div>
      <CustomerSelector onChange={onChange} simpleValue value={value} />
      {touched && error && <ErrorLabel>{error}</ErrorLabel>}
    </div>
  )
}

const createCustomFieldSelector = ({
  customField,
  input: { onChange, value },
  meta: { touched, error },
}): Element<'div'> => {
  return (
    <div>
      <FieldTypeSelector
        name={customField.internal_name}
        type={customField.field_type}
        value={value}
        options={customField.options}
        onChange={value => onChange(value[customField.internal_name])}
        description={customField.description}
      />
      {touched && error && <ErrorLabel>{error}</ErrorLabel>}
    </div>
  )
}

const createDropSelector = ({
  drops,
  input: { onChange, value },
  meta: { touched, error },
}): Element<'div'> => {
  return (
    <div>
      <DropsContainer>
        {drops.map(drop => {
          return (
            <Drop onClick={() => onChange(drop.id)} selected={value == drop.id}>
              <DropTitle>{drop.name}</DropTitle>
              <DropDates>
                <DropDeliveryDate
                  confirmed={drop.delivery_date_confirmed}
                  fromDate={drop.delivery_date_from}
                  toDate={drop.delivery_date_to}
                  type={drop.delivery_date_type}
                />
              </DropDates>
            </Drop>
          )
        })}
      </DropsContainer>
      {touched && error && <ErrorLabel>{error}</ErrorLabel>}
    </div>
  )
}

const DropsContainer = styled.div`
  display: flex;
  margin-left: -20px;
`

const Drop = styled.div`
  align-items: center;
  background: #fdfdfd;
  border: ${({ selected = false }: { selected: boolean }) =>
    selected ? '2px solid orange' : '2px dashed #ccc'};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  margin-left: 20px;
  width: 200px;
`

const DropTitle = styled.div`
  color: black;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`

const DropDates = styled.div`
  font-size: 14px;
  margin-top: 10px;
  padding: 0 10px;
  text-align: center;

  span {
    display: block;
  }
`
