import Raven from 'raven-js'
import LogRocket from 'logrocket'

const use = process.env.NODE_ENV === 'production' && process.env.RAVEN_CLIENT

export function TrackError(ErrorObject, data = {}) {
  // ErrorObject is sometimes undefined
  if (use && ErrorObject) {
    Raven.captureException(ErrorObject, {
      extra: data,
    })
    LogRocket.captureException(ErrorObject)
  }
}

export function SetUserContext(user, entity, settings) {
  if (use) {
    if (user && entity && settings) {
      Raven.setUserContext({
        id: user.id,
        email: user.email,
        firstname: user.firstname,
        type: user.user_type,
        entity: {
          id: entity.id,
          name: entity.name,
          type: entity.entity_type,
        },
        settings,
      })
    } else {
      // Logout
      Raven.setUserContext()
    }
  }
}

export function Installer() {
  if (use) {
    Raven.config(process.env.RAVEN_CLIENT, {
      release: process.env.VERSION,
      environment: 'production',
      // https://docs.sentry.io/clients/javascript/tips/
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        'Object Not Found Matching Id:1',
        'Object Not Found Matching Id:2',
        'Object Not Found Matching Id:3',
        'Object Not Found Matching Id:7',
      ],
      ignoreUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    }).install()

    if (
      process.env.NODE_ENV === 'production' &&
      process.env.LOG_ROCKET_CLIENT
    ) {
      Raven.setDataCallback(function (data) {
        data.extra.sessionURL = LogRocket.sessionURL
        return data
      })
    }

    window.addEventListener('unhandledrejection', err => {
      Raven.captureException(err.reason)
    })
  }
}
