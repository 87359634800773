// @flow

import { showMessage } from '../actions/toast'

let store = null

export default function(
  type: 'success' | 'error' | 'info' | 'warning',
  message: string
) {
  return store.dispatch(showMessage(type, message))
}

const setStore = s => {
  store = s
}

export { setStore }
