// @flow

import * as React from 'react'
import styled from 'styled-components'

export const WebshopContext = React.createContext({
  brand: null,
  currency: null,
  drops: [],
  fetchWebshopTotals: null,
  isWebshopSessionUpdating: false,
  setCurrency: null,
  updateWebshopSession: null,
  webshopSession: null,
})

export const NAVIGATION_LINK_TYPE_SEARCH = 'search'
export const NAVIGATION_LINK_TYPE_PAGE = 'page'
export const NAVIGATION_LINK_TYPE_BESTSELLERS = 'bestsellers'
export const NAVIGATION_LINK_TYPE_FILE_BANK = 'file_bank'

export const navigationLinkAvailableSortOptions = [
  'bestsellers',
  'newest',
  'name_asc',
  'name_desc',
  'price_asc',
  'price_desc',
  'number_asc',
  'number_desc',
]

export const allSortOptions = [
  { value: 'score', label: 'Relevant' },
  { value: 'newest', label: 'Newest' },
  { value: 'bestsellers', label: 'Bestsellers' },
  { value: 'name_asc', label: 'Name, A to Z' },
  { value: 'name_desc', label: 'Name, Z to A' },
  { value: 'price_asc', label: 'Price, low to high' },
  { value: 'price_desc', label: 'Price, high to low' },
  { value: 'number_asc', label: 'Item number, low to high' },
  { value: 'number_desc', label: 'Item number, high to low' },
]

export const TopBarPortalButton = styled.button`
  border: none;
  border-radius: 0;
  background: transparent;
  border-left: 1px solid #3e4149;
  border-right: 1px solid #3e4149;
  color: white;
  font-size: 12px;
  height: 35px;

  &:hover,
  &:active,
  &:focus {
    background: transparent;
    color: white;
  }
`
