import { combineReducers } from 'redux'
import users from './users/reducers/users'
import { reducer as formReducer } from 'redux-form'
import brandContext from './brands/reducers/brandContext'
import collections from './collections/reducers/collections'
import { reducer as editableTable } from '../infrastructure/components/EditableTable'
import session from '../infrastructure/reducers/session'
import orderContext from './orders/reducers/orderContext'
import cloudinary from '../infrastructure/reducers/cloudinary'
import {
  refreshSession,
  setAccessToken,
  setAdminEmail,
  USER_LOGOUT_SUCCESS,
} from '../infrastructure/actions/session'
import brands from './brands/reducers/brands'
import search from './template/reducers/search'
import settings from './settings/reducers/settings'
import { routerReducer as routing } from 'react-router-redux'
import products from './products/reducers/products'
import template from './template/reducers/template'
import { webshop } from './shop/reducers/index'
import categories from './categories/reducers/categories'
import inventory from './inventory/reducers/index'
import misc from '../infrastructure/reducers/misc'
import toastReducer from '../infrastructure/reducers/toast'
import filebank from './file-bank/reducers'
import entityUsers from './users/reducers/entityUsers'
import { SWITCH_IDENTITY } from './admin/actions'
import { clearAllDataCaches } from './hooks'

const appReducer = combineReducers({
  brandContext,
  brands,
  categories,
  cloudinary,
  collections,
  editableTable,
  entityUsers,
  form: formReducer,
  inventory,
  misc,
  orderContext,
  products,
  routing,
  search,
  session,
  settings,
  template,
  toast: toastReducer,
  users,
  webshop,
  filebank,
})

const RESET_STATE = 'RESET_STATE'
export const resetState = () => ({
  type: RESET_STATE,
})

const getResettedState = state => ({
  cloudinary: state.cloudinary,
})

// Resets state on log out
const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT_SUCCESS || action.type === RESET_STATE) {
    // The cloudinary presets are not user-specific and therefore
    // should not be cleared on logout. This will cause error for
    // any CloudinaryResource still mounted in the DOM.
    state = getResettedState(state)

    clearAllDataCaches()
  } else if (action.type === SWITCH_IDENTITY) {
    state = {
      ...getResettedState(state),
      session: session(
        session(state.session, setAdminEmail(action.adminEmail)),
        setAccessToken(action.accessToken, action.accessTokenExpiration)
      ),
    }

    clearAllDataCaches()
  }

  return appReducer(state, action)
}

export default rootReducer
