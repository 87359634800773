/* @flow */

import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import isNumber from 'lodash/isNumber'

const conversions = {
  g: 1,
  kg: 1000,
}

export const getProductWeight = (
  variant,
  product,
  fallbackIfMissingValue = true
) => {
  // Net weight
  const variantWeight = variant.weight
  const variantWeightType = variant.weight_type
  const productWeight = product.weight
  const productWeightType = product.weight_type

  let useWeight = null
  let useWeightType = null
  if (isNumber(variantWeight) || isNumeric(variantWeight)) {
    useWeight = parseFloat(variantWeight)
    useWeightType = variantWeightType || productWeightType
  } else if (isNumber(productWeight) || isNumeric(productWeight)) {
    useWeight = parseFloat(productWeight)
    useWeightType = productWeightType
  }

  // Gross weight
  const variantWeightGross = variant.weight_gross
  const variantWeightGrossType = variant.weight_gross_type
  const productWeightGross = product.weight_gross
  const productWeightGrossType = product.weight_gross_type

  let useWeightGross = null
  let useWeightGrossType = null
  if (isNumber(variantWeightGross) || isNumeric(variantWeightGross)) {
    useWeightGross = parseFloat(variantWeightGross)
    useWeightGrossType = variantWeightGrossType || productWeightGrossType
  } else if (isNumber(productWeightGross) || isNumeric(productWeightGross)) {
    useWeightGross = parseFloat(productWeightGross)
    useWeightGrossType = productWeightGrossType
  }

  if (fallbackIfMissingValue) {
    if (useWeight === null && useWeightGross !== null) {
      useWeight = useWeightGross
      useWeightType = useWeightGrossType
    }
    if (useWeight !== null && useWeightGross === null) {
      useWeightGross = useWeight
      useWeightGrossType = useWeightType
    }
  }

  return [useWeight, useWeightType, useWeightGross, useWeightGrossType]
}

export const convertWeight = (
  input,
  inputType,
  targetType,
  decimal = false
) => {
  if (isNaN(parseFloat(input))) {
    return 0
  }

  if (inputType === targetType) {
    return input
  }

  let normalizedToGrams
  if (inputType === 'g') {
    normalizedToGrams = input
  } else if (inputType === 'kg') {
    normalizedToGrams = input * 1000
  }

  const converted = normalizedToGrams / conversions[targetType]

  return decimal ? parseFloat(converted.toFixed(decimal)) : converted
}

export const aggregateWeight = (inputs, targetType, decimal = false) => {
  const sum = inputs.reduce(
    (carry, input) =>
      carry + convertWeight(input[0], input[1], targetType, false),
    0
  )

  return decimal ? parseFloat(sum.toFixed(decimal)) : sum
}

window.clone = function (obj) {
  function CloneFactory() {}
  CloneFactory.prototype = obj

  return new CloneFactory()
}

window.timestampToSeconds = function timestampToSeconds(timestamp) {
  return timestamp / 1000
}

window.ifNotNull = function (notNullValue, nullValue) {
  return notNullValue !== null && notNullValue !== '' ? notNullValue : nullValue
}

// http://stackoverflow.com/a/384380/602488
window.isElement = function isElement(obj) {
  try {
    //Using W3 DOM2 (works for FF, Opera and Chrom)
    return obj instanceof HTMLElement
  } catch (e) {
    //Browsers not supporting W3 DOM2 don't have HTMLElement and
    //an exception is thrown and we end up here. Testing some
    //properties that all elements have. (works on IE7)
    return (
      typeof obj === 'object' &&
      obj.nodeType === 1 &&
      typeof obj.style === 'object' &&
      typeof obj.ownerDocument === 'object'
    )
  }
}

window.isNumeric = function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

window.isArray = function (input) {
  return isArray(input)
}

window.isBoolean = function (input) {
  return typeof input === 'boolean'
}

window.isFunction = function (input) {
  return typeof input === 'function'
}

window.isObject = function (input) {
  // using typeof would evaluate null as an object
  return isObject(input)
}

window.isString = function (input) {
  return typeof input === 'string'
}

window.isNull = function (input) {
  return input === null
}

window.isUndefined = function (input) {
  return typeof input === 'undefined'
}

window.isSet = function (input) {
  return !isUndefined(input) && !isNull(input) && input !== ''
}

window.isDateValid = function (d) {
  if (Object.prototype.toString.call(d) === '[object Date]') {
    // it is a date
    if (isNaN(d.getTime())) {
      // d.valueOf() could also work
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

window.ucfirst = function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

window.substr_replace = function (str, replace, start, length) {
  // From: http://phpjs.org/functions
  // +   original by: Brett Zamir (http://brett-zamir.me)
  // *     example 1: substr_replace('ABCDEFGH:/MNRPQR/', 'bob', 0);
  // *     returns 1: 'bob'
  // *     example 2: $var = 'ABCDEFGH:/MNRPQR/';
  // *     example 2: substr_replace($var, 'bob', 0, $var.length);
  // *     returns 2: 'bob'
  // *     example 3: substr_replace('ABCDEFGH:/MNRPQR/', 'bob', 0, 0);
  // *     returns 3: 'bobABCDEFGH:/MNRPQR/'
  // *     example 4: substr_replace('ABCDEFGH:/MNRPQR/', 'bob', 10, -1);
  // *     returns 4: 'ABCDEFGH:/bob/'
  // *     example 5: substr_replace('ABCDEFGH:/MNRPQR/', 'bob', -7, -1);
  // *     returns 5: 'ABCDEFGH:/bob/'
  // *     example 6: substr_replace('ABCDEFGH:/MNRPQR/', '', 10, -1)
  // *     returns 6: 'ABCDEFGH://'

  if (start < 0) {
    // start position in str
    start = start + str.length
  }
  length = length !== undefined ? length : str.length
  if (length < 0) {
    length = length + str.length - start
  }

  return (
    str.slice(0, start) +
    replace.substr(0, length) +
    replace.slice(length) +
    str.slice(start + length)
  )
}

window.strripos = function (haystack, needle, offset) {
  // From: http://phpjs.org/functions
  // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // +   bugfixed by: Onno Marsman
  // +   input by: saulius
  // +   bugfixed by: Brett Zamir (http://brett-zamir.me)
  // *     example 1: strripos('Kevin van Zonneveld', 'E');
  // *     returns 1: 16
  haystack = (haystack + '').toLowerCase()
  needle = (needle + '').toLowerCase()

  var i = -1
  if (offset) {
    i = (haystack + '').slice(offset).lastIndexOf(needle) // strrpos' offset indicates starting point of range till end,
    // while lastIndexOf's optional 2nd argument indicates ending point of range from the beginning
    if (i !== -1) {
      i += offset
    }
  } else {
    i = (haystack + '').lastIndexOf(needle)
  }
  return i >= 0 ? i : false
}

window.mt_rand = function (min, max) {
  //  discuss at: http://phpjs.org/functions/mt_rand/
  // original by: Onno Marsman
  // improved by: Brett Zamir (http://brett-zamir.me)
  //    input by: Kongo
  //   example 1: mt_rand(1, 1);
  //   returns 1: 1

  var argc = arguments.length
  if (argc === 0) {
    min = 0
    max = 2147483647
  } else if (argc === 1) {
    throw new Error('Warning: mt_rand() expects exactly 2 parameters, 1 given')
  } else {
    min = parseInt(min, 10)
    max = parseInt(max, 10)
  }
  return Math.floor(Math.random() * (max - min + 1)) + min
}

window.sprintf = function () {
  //  discuss at: http://phpjs.org/functions/sprintf/
  // original by: Ash Searle (http://hexmen.com/blog/)
  // improved by: Michael White (http://getsprink.com)
  // improved by: Jack
  // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // improved by: Dj
  // improved by: Allidylls
  //    input by: Paulo Freitas
  //    input by: Brett Zamir (http://brett-zamir.me)
  //   example 1: sprintf("%01.2f", 123.1);
  //   returns 1: 123.10
  //   example 2: sprintf("[%10s]", 'monkey');
  //   returns 2: '[    monkey]'
  //   example 3: sprintf("[%'#10s]", 'monkey');
  //   returns 3: '[####monkey]'
  //   example 4: sprintf("%d", 123456789012345);
  //   returns 4: '123456789012345'
  //   example 5: sprintf('%-03s', 'E');
  //   returns 5: 'E00'

  var regex =
    /%%|%(\d+\$)?([-+\'#0 ]*)(\*\d+\$|\*|\d+)?(\.(\*\d+\$|\*|\d+))?([scboxXuideEfFgG])/g
  var a = arguments
  var i = 0
  var format = a[i++]

  // pad()
  var pad = function (str, len, chr, leftJustify) {
    if (!chr) {
      chr = ' '
    }
    var padding =
      str.length >= len ? '' : new Array((1 + len - str.length) >>> 0).join(chr)
    return leftJustify ? str + padding : padding + str
  }

  // justify()
  var justify = function (
    value,
    prefix,
    leftJustify,
    minWidth,
    zeroPad,
    customPadChar
  ) {
    var diff = minWidth - value.length
    if (diff > 0) {
      if (leftJustify || !zeroPad) {
        value = pad(value, minWidth, customPadChar, leftJustify)
      } else {
        value =
          value.slice(0, prefix.length) +
          pad('', diff, '0', true) +
          value.slice(prefix.length)
      }
    }
    return value
  }

  // formatBaseX()
  var formatBaseX = function (
    value,
    base,
    prefix,
    leftJustify,
    minWidth,
    precision,
    zeroPad
  ) {
    // Note: casts negative numbers to positive ones
    var number = value >>> 0
    prefix =
      (prefix &&
        number &&
        {
          2: '0b',
          8: '0',
          16: '0x',
        }[base]) ||
      ''
    value = prefix + pad(number.toString(base), precision || 0, '0', false)
    return justify(value, prefix, leftJustify, minWidth, zeroPad)
  }

  // formatString()
  var formatString = function (
    value,
    leftJustify,
    minWidth,
    precision,
    zeroPad,
    customPadChar
  ) {
    if (precision != null) {
      value = value.slice(0, precision)
    }
    return justify(value, '', leftJustify, minWidth, zeroPad, customPadChar)
  }

  // doFormat()
  var doFormat = function (
    substring,
    valueIndex,
    flags,
    minWidth,
    _,
    precision,
    type
  ) {
    var number, prefix, method, textTransform, value

    if (substring === '%%') {
      return '%'
    }

    // parse flags
    var leftJustify = false
    var positivePrefix = ''
    var zeroPad = false
    var prefixBaseX = false
    var customPadChar = ' '
    var flagsl = flags.length
    for (var j = 0; flags && j < flagsl; j++) {
      switch (flags.charAt(j)) {
        case ' ':
          positivePrefix = ' '
          break
        case '+':
          positivePrefix = '+'
          break
        case '-':
          leftJustify = true
          break
        case "'":
          customPadChar = flags.charAt(j + 1)
          break
        case '0':
          zeroPad = true
          customPadChar = '0'
          break
        case '#':
          prefixBaseX = true
          break
      }
    }

    // parameters may be null, undefined, empty-string or real valued
    // we want to ignore null, undefined and empty-string values
    if (!minWidth) {
      minWidth = 0
    } else if (minWidth === '*') {
      minWidth = +a[i++]
    } else if (minWidth.charAt(0) == '*') {
      minWidth = +a[minWidth.slice(1, -1)]
    } else {
      minWidth = +minWidth
    }

    // Note: undocumented perl feature:
    if (minWidth < 0) {
      minWidth = -minWidth
      leftJustify = true
    }

    if (!isFinite(minWidth)) {
      throw new Error('sprintf: (minimum-)width must be finite')
    }

    if (!precision) {
      precision = 'fFeE'.indexOf(type) > -1 ? 6 : type === 'd' ? 0 : undefined
    } else if (precision === '*') {
      precision = +a[i++]
    } else if (precision.charAt(0) == '*') {
      precision = +a[precision.slice(1, -1)]
    } else {
      precision = +precision
    }

    // grab value using valueIndex if required?
    value = valueIndex ? a[valueIndex.slice(0, -1)] : a[i++]

    switch (type) {
      case 's':
        return formatString(
          String(value),
          leftJustify,
          minWidth,
          precision,
          zeroPad,
          customPadChar
        )
      case 'c':
        return formatString(
          String.fromCharCode(+value),
          leftJustify,
          minWidth,
          precision,
          zeroPad
        )
      case 'b':
        return formatBaseX(
          value,
          2,
          prefixBaseX,
          leftJustify,
          minWidth,
          precision,
          zeroPad
        )
      case 'o':
        return formatBaseX(
          value,
          8,
          prefixBaseX,
          leftJustify,
          minWidth,
          precision,
          zeroPad
        )
      case 'x':
        return formatBaseX(
          value,
          16,
          prefixBaseX,
          leftJustify,
          minWidth,
          precision,
          zeroPad
        )
      case 'X':
        return formatBaseX(
          value,
          16,
          prefixBaseX,
          leftJustify,
          minWidth,
          precision,
          zeroPad
        ).toUpperCase()
      case 'u':
        return formatBaseX(
          value,
          10,
          prefixBaseX,
          leftJustify,
          minWidth,
          precision,
          zeroPad
        )
      case 'i':
      case 'd':
        number = +value || 0
        number = Math.round(number - (number % 1)) // Plain Math.round doesn't just truncate
        prefix = number < 0 ? '-' : positivePrefix
        value = prefix + pad(String(Math.abs(number)), precision, '0', false)
        return justify(value, prefix, leftJustify, minWidth, zeroPad)
      case 'e':
      case 'E':
      case 'f': // Should handle locales (as per setlocale)
      case 'F':
      case 'g':
      case 'G':
        number = +value
        prefix = number < 0 ? '-' : positivePrefix
        method = ['toExponential', 'toFixed', 'toPrecision'][
          'efg'.indexOf(type.toLowerCase())
        ]
        textTransform = ['toString', 'toUpperCase']['eEfFgG'.indexOf(type) % 2]
        value = prefix + Math.abs(number)[method](precision)
        return justify(value, prefix, leftJustify, minWidth, zeroPad)[
          textTransform
        ]()
      default:
        return substring
    }
  }

  return format.replace(regex, doFormat)
}

window.guid = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

window.firstObjectElement = function firstObjectElement(obj) {
  var first
  for (var i in obj) {
    if (obj.hasOwnProperty(i) && typeof i !== 'function') {
      first = obj[i]
      break
    }
  }
  return first
}

export const parseNumericString = numericString => {
  if (typeof numericString !== 'string') {
    return numericString
  }

  if (numericString.trim() === '') {
    return numericString
  }

  // there is a non-numeric character in the numericString
  if (numericString.match(/[^\d\.\,]+/)) {
    return numericString
  }

  // there is no comma or dot
  if (!numericString.match(/[\.\,]+/)) {
    return convertFloatSafely(numericString)
  }

  // there is only a dot
  if (numericString.match(/\./) && !numericString.match('/,/')) {
    return convertFloatSafely(numericString)
  }

  // 1,200.50 (US) or 1.200,50 (DK)
  if (numericString.match(/,/) && numericString.match(/\./)) {
    const lastDotPos = numericString.lastIndexOf('.')
    const lastCommaPos = numericString.lastIndexOf(',')

    // US Format: 1,200.50
    if (lastDotPos > lastCommaPos) {
      const numericStringWithoutCommas = numericString.replace(/,/g, '')

      return convertFloatSafely(numericStringWithoutCommas)

      // DK Format: 1.200,50
    } else {
      return parseNumericStringDkFormat(numericString)
    }
    // 1200,50
  } else if (numericString.match(/\,/)) {
    return parseNumericStringDkFormat(numericString)
  }

  return convertFloatSafely(numericString)
}

export const parseNumericStringDkFormat = price => {
  if (typeof price !== 'string') {
    return price
  }

  if (price.trim() === '') {
    return price
  }

  const priceWithoutDot = price.replace('.', '')
  const priceWithoutComma = priceWithoutDot.replace(',', '.')

  return convertFloatSafely(priceWithoutComma)
}

const convertFloatSafely = value => {
  const parsed = parseFloat(value)

  return isNaN(parsed) ? value : parsed
}

export const parseNumericStrings = (input, rules) => {
  for (const rule of rules) {
    const path = rule.split('.')

    if (path.length === 1) {
      input[path[0]] = parseNumericString(input[path[0]])
    } else {
      if (path[1] === '*') {
        if (input[path[0]] && Array.isArray(input[path[0]])) {
          input[path[0]].forEach((item, key) => {
            input[path[0]][key] = parseNumericStrings(item, [
              path.slice(2).join('.'),
            ])
          })
        }
      } else {
        input[path[0]][path[1]] = parseNumericStrings(input[path[0]][path[1]], [
          path.slice(2).join('.'),
        ])
      }
    }
  }

  return input
}

export const parseNumericStringsList = (input, rules) => {
  input.forEach((item, key) => {
    input[key] = parseNumericStrings(item, rules)
  })

  return input
}
