// @flow

import { type RouterHistory } from 'react-router-dom'

import { format as formatCurrencyString } from '../../../../infrastructure/components/FormatCurrency'
import { productionOrderDeliveryNoteStatusToLabel } from '../../../production/shared'
import { switchToPayingCustomer } from '../../../admin/actions'
import { renderDate } from '../../../shared'
import type { Dispatch } from '../../../types'
import { type SpotlightSuggestion } from './Spotlight'

type SpotlightSuggestionTypes = {
  [type: string]: {
    mapResultsToSuggestions: (
      type: string,
      searchResults: Array<Object>
    ) => Array<SpotlightSuggestion>,
    onSuggestionSelected: (
      suggestion: SpotlightSuggestion,
      history: RouterHistory,
      dispatch: Dispatch
    ) => void,
  },
}

const spotlightSuggestionTypes: SpotlightSuggestionTypes = {
  brands: {
    mapResultsToSuggestions: (type, searchResults) => {
      return searchResults.map(brand => {
        return {
          id: brand.entity.id,
          score: brand.score,
          type: type,
          type_label: 'Brand',
          title: `${brand.entity.name} (#${brand.entity.id})`,
          subtitle: '',
          image: null,
          resultEntity: brand.entity,
        }
      })
    },
    onSuggestionSelected: (suggestion, history, dispatch) => {
      dispatch(switchToPayingCustomer(history, suggestion.id))
    },
  },

  customers: {
    mapResultsToSuggestions: (type, searchResults) => {
      return searchResults.map(customer => {
        return {
          id: customer.entity.id,
          score: customer.score,
          type: type,
          type_label: 'Customer',
          title: `${customer.entity.name}`,
          subtitle: createSubtitle([
            `#${customer.entity.customer_number}`,
            customer.entity.active ? 'Active' : 'Inactive',
            customer.entity.country_name,
          ]),
          image: null,
          resultEntity: customer.entity,
        }
      })
    },
    onSuggestionSelected: (suggestion, history, dispatch) => {
      history.push(`/customers/${suggestion.id}`)
    },
  },

  invoices: {
    mapResultsToSuggestions: (type, searchResults) => {
      return searchResults.map(invoice => {
        return {
          id: invoice.entity.id,
          score: invoice.score,
          type: type,
          type_label:
            invoice.entity.invoice_type === 'invoice'
              ? 'Invoice'
              : 'Credit note',
          title: `#${invoice.entity.invoice_number}`,
          subtitle: createSubtitle([
            renderDate(invoice.entity.invoice_date),
            invoice.entity.customer_name,
            invoice.entity.drop,
            `${invoice.entity.total_quantity} pcs.`,
            formatCurrencyString(
              invoice.entity.currency,
              invoice.entity.net_price
            ),
          ]),
          image: null,
          resultEntity: invoice.entity,
        }
      })
    },
    onSuggestionSelected: (suggestion, history, dispatch) => {
      if (suggestion.resultEntity.invoice_type === 'invoice') {
        history.push(`/invoices/${suggestion.id}`)
      } else {
        history.push(`/credit-notes/${suggestion.id}`)
      }
    },
  },

  orders: {
    mapResultsToSuggestions: (type, searchResults) => {
      return searchResults.map(order => {
        return {
          id: order.entity.id,
          score: order.score,
          type: type,
          type_label: 'Order',
          title: `#${order.entity.order_number}`,
          subtitle: createSubtitle([
            renderDate(order.entity.order_date),
            order.entity.customer_name,
            order.entity.drop,
            `${order.entity.total_quantity} pcs.`,
            formatCurrencyString(order.entity.currency, order.entity.net_price),
          ]),
          image: null,
          resultEntity: order.entity,
        }
      })
    },
    onSuggestionSelected: (suggestion, history, dispatch) => {
      history.push(`/orders/show/${suggestion.id}`)
    },
  },

  products: {
    mapResultsToSuggestions: (type, searchResults) => {
      return searchResults.map(product => {
        return {
          id: product.entity.id,
          score: product.score,
          type: type,
          type_label: 'Product',
          title: `${product.entity.name}`,
          subtitle: createSubtitle([
            `#${product.entity.item_number}`,
            product.entity.subbrand,
            product.entity.collection,
            product.entity.variants_count > 0
              ? `${product.entity.variants_count} variants`
              : false,
          ]),
          image: product.entity.image,
          resultEntity: product.entity,
        }
      })
    },
    onSuggestionSelected: (suggestion, history, dispatch) => {
      history.push(`/products/${suggestion.id}`)
    },
  },

  production_orders: {
    mapResultsToSuggestions: (type, searchResults) => {
      return searchResults.map(productionOrder => {
        return {
          id: productionOrder.entity.id,
          score: productionOrder.score,
          type: type,
          type_label: 'Production Order',
          title: `#${productionOrder.entity.production_order_number}`,
          subtitle: createSubtitle([
            productionOrder.entity.supplier_name,
            renderDate(productionOrder.entity.date),
            `${productionOrder.entity.total_quantity} pcs.`,
            formatCurrencyString(
              productionOrder.entity.currency,
              productionOrder.entity.net_price
            ),
          ]),
          image: null,
          resultEntity: productionOrder.entity,
        }
      })
    },
    onSuggestionSelected: (suggestion, history, dispatch) => {
      history.push(`/production/orders/${suggestion.id}`)
    },
  },

  production_order_delivery_notes: {
    mapResultsToSuggestions: (type, searchResults) => {
      return searchResults.map(poDeliveryNote => {
        return {
          id: poDeliveryNote.entity.id,
          score: poDeliveryNote.score,
          type: type,
          type_label: 'Production Order Delivery',
          title: `#${poDeliveryNote.entity.production_order_delivery_note_number}`,
          subtitle: createSubtitle([
            productionOrderDeliveryNoteStatusToLabel(
              poDeliveryNote.entity.status
            ),
            poDeliveryNote.entity.supplier_name,
            ...(poDeliveryNote.entity.status === 'sent'
              ? [
                  `${poDeliveryNote.entity.ordered} pcs.`,
                  formatCurrencyString(
                    poDeliveryNote.entity.currency,
                    poDeliveryNote.entity.net_price
                  ),
                ]
              : []),
            ...(poDeliveryNote.entity.status === 'received'
              ? [
                  `${poDeliveryNote.entity.received} pcs.`,
                  formatCurrencyString(
                    poDeliveryNote.entity.currency,
                    poDeliveryNote.entity.net_price
                  ),
                ]
              : []),
          ]),
          image: null,
          resultEntity: poDeliveryNote.entity,
        }
      })
    },
    onSuggestionSelected: (suggestion, history, dispatch) => {
      history.push(`/production/orders/delivery-notes/${suggestion.id}`)
    },
  },

  shipments: {
    mapResultsToSuggestions: (type, searchResults) => {
      return searchResults.map(shipment => {
        return {
          id: shipment.entity.id,
          score: shipment.score,
          type: type,
          type_label: 'Shipment',
          title: `#${shipment.entity.shipment_nr}`,
          subtitle: createSubtitle([
            `Order number #${shipment.entity.order_number}`,
            shipment.entity.customer_name,
            shipment.entity.country_name,
          ]),
          image: null,
          resultEntity: shipment.entity,
        }
      })
    },
    onSuggestionSelected: (suggestion, history, dispatch) => {
      const { order_id: orderId, document_id: documentId } =
        suggestion.resultEntity

      history.push(`/shipments/${suggestion.id}`)
    },
  },

  suppliers: {
    mapResultsToSuggestions: (type, searchResults) => {
      return searchResults.map(supplier => {
        return {
          id: supplier.entity.id,
          score: supplier.score,
          type: type,
          type_label: 'Supplier',
          title: `${supplier.entity.name}`,
          subtitle: `#${supplier.entity.supplier_number}`,
          image: null,
          resultEntity: supplier.entity,
        }
      })
    },
    onSuggestionSelected: (suggestion, history, dispatch) => {
      history.push(`/production/suppliers/${suggestion.id}`)
    },
  },
}

export default spotlightSuggestionTypes

const createSubtitle = elements =>
  elements.filter(e => e && e.length > 0).join(' / ')
