/* @flow */

import * as React from 'react'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'

const useLocalStorage = (key: string, initialData: Object) => {
  const [{ data, isInitialized }, setState] = React.useState({
    data: initialData,
    isInitialized: false,
  })

  const setData = React.useCallback(
    (data: Object | ((currentData: Object) => Object)) => {
      setState(s => {
        const currentData = s.data

        const newData = isFunction(data) ? data(currentData) : data

        return isObject(s.data)
          ? {
              ...s,
              data: {
                ...s.data,
                ...newData,
              },
            }
          : {
              ...s,
              data: newData,
            }
      })
    },
    [setState]
  )

  const replaceData = React.useCallback(
    newData => {
      setState(s => ({
        ...s,
        data: newData,
      }))
    },
    [setState]
  )

  const clearStoredData = React.useCallback(() => {
    localStorage.removeItem(key)
  }, [key])

  React.useEffect(() => {
    const restoredData = localStorage.getItem(key)

    if (restoredData) {
      setState({
        data: JSON.parse(restoredData),
        isInitialized: true,
      })
    } else {
      setState(s => ({
        ...s,
        isInitialized: true,
      }))
    }
  }, [key, setState])

  React.useEffect(() => {
    if (isInitialized) {
      localStorage.setItem(key, JSON.stringify(data))
    }
  }, [data, key, isInitialized])

  return [data, setData, isInitialized, { clearStoredData, replaceData }]
}

export default useLocalStorage
