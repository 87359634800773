/* @flow */

import React from 'react'
import styled from 'styled-components'

import type { LeadTimeType } from '../../types'

type Props = {
  from: null | number,
  to: null | number,
  type: LeadTimeType,
}

const LeadTimeLabel = ({ from, to, type }: Props) => {
  if (!from || !to || !type) {
    return null
  }

  return (
    <span>
      {from} - {to} {type}
    </span>
  )
}


export default LeadTimeLabel
