import React from 'react'
import {
  CanWriteProductsPredicate,
  IsBrandPredicate,
  OpenOrderPredicate,
  UnlockedOrderPredicate,
} from './shared'
import { createAction } from '../shared'

export const createDeleteAction = callback =>
  createAction(callback, <span className="glyphicon glyphicon-remove" />, [
    CanWriteProductsPredicate,
    IsBrandPredicate,
    OpenOrderPredicate,
    UnlockedOrderPredicate,
  ])

export default createDeleteAction
