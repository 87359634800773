/* @flow */

import * as React from 'react'

const createSelectAllTableAction = ({
  lines_ref,
  key = 'table_select_all',
  set_property = 'quantity',
  source_property,
}) => {
  return {
    key,
    render: ({ updateLines }) => {
      const onClick = () => {
        const updatedLines = []
        for (let line of lines_ref.current) {
          updatedLines.push({
            ...line,
            [set_property]: line[source_property],
          })
        }

        updateLines({
          lines: updatedLines,
          type: 'update',
        })
      }

      return (
        <button
          type="button"
          className="btn btn-white btn-sm"
          onClick={onClick}
        >
          All
        </button>
      )
    },
  }
}

export default createSelectAllTableAction
