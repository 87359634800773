// @flow

import { quickCreateHook } from '../../hooks'
import type { OrderCancellationReason } from '../../types'

import { getCancellationReasons } from '../api'

const { hook: useCancellationReasons } = quickCreateHook<
  Array<OrderCancellationReason>
>(getCancellationReasons, 'cancellation_reasons', [])

export { useCancellationReasons }
