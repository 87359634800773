/* @flow */

import React from 'react'

import { Tooltip } from '../../../shared'

const ToggleVariantsAction = () => {
  return {
    key: 'toggle_variants',
    render: props => {
      return <Render {...props} />
    },
  }
}

export default ToggleVariantsAction

// We need to create a separate component, otherwise we might cause a
// "fewer hooks rendered than expected" exception

const Render = ({ onTableControlsChange, product, toggles }) => {
  const filterVariantsWithoutLines = toggles.filterVariantsWithoutLines

  const toggleVariants = React.useCallback(
    e => {
      e.stopPropagation()

      onTableControlsChange({
        type: 'update_toggles',
        product_ids: [product.id],
        data: {
          filter_variants_without_lines: !filterVariantsWithoutLines,
        },
      })
    },
    [filterVariantsWithoutLines, onTableControlsChange, product]
  )

  return (
    <Tooltip
      id="product-table-expand-variants"
      tip={
        filterVariantsWithoutLines === true
          ? 'Show all variants'
          : 'Hide variants without quantities'
      }
    >
      <button
        type="button"
        className="btn btn-white btn-xs"
        onClick={toggleVariants}
        tabIndex="-1"
      >
        {filterVariantsWithoutLines === true && (
          <span className="glyphicon glyphicon-plus" />
        )}
        {!filterVariantsWithoutLines && (
          <span className="glyphicon glyphicon-minus" />
        )}
      </button>
    </Tooltip>
  )
}
