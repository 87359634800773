/* @flow */

import { switchEntity } from '../template/actions'
import type { AvailableBrand, Entity, Id, User } from '../types'

export const ensureLoggedIntoEntityWithBrandAccess = (
  brandId: Id,
  customerId: Id | null,
  availableBrands: Array<Object>,
  user: User,
  userEntity: Entity,
  dispatch: Function
) => {
  if (userEntity.entity_type !== 'shop') {
    return
  }

  const availableBrand = availableBrands.find(b => b.id == brandId)

  if (availableBrand) {
    const retailerConnections =
      customerId !== null
        ? availableBrand.retailer_connections.filter(
            connection => connection.customer_id == customerId
          )
        : availableBrand.retailer_connections

    if (retailerConnections.length > 0) {
      const shopIds = retailerConnections.map(connection => connection.shop_id)

      if (!shopIds.includes(user.entity_id)) {
        dispatch(switchEntity(shopIds[0]))
      }
    }
  }
}

export const canShopBrand = (brand: AvailableBrand) =>
  !brand.freemium &&
  brand.retailer_connections.filter(conn => conn.b2b_access === true).length > 0
