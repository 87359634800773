/* @flow */

import * as React from 'react'
import uuid from 'uuid'
import moment from 'moment'

import { quickCreateHook } from '../hooks'

import { useMySubUsers } from '../entities/hooks'
import { setMySubUserDevice } from '../entities/api'

import {
  SessionContext,
  useCancellableRequest,
  useRefValue,
  userHasFlag,
} from '../shared'
import { recordWarehouseEvents } from '../shipments/api'
import {
  getWarehouseLocationScreenData,
  getWarehouseVariantScreenData,
  warehouseInventoryScan,
} from './api'
import { useWarehouseLocations } from '../shipments/hooks'

import { WarehouseContext } from './shared'

import { logrocketIdentify } from '../../infrastructure/actions/session'

export const useBarcode = () => {
  const { barcode, setBarcode } = React.useContext(WarehouseContext)

  React.useEffect(() => {
    // If you leave a screen we have to reset the barcode. E.g. if you scan a product
    // on a stock count and then go out of it, and go back in it should not count
    // as a scan again. We pretty much want barcode=null whenever we open a new page
    return () => {
      setBarcode(null)
    }
  }, [setBarcode])

  return [barcode, setBarcode]
}

export const useInventoryLocation = () => {
  const { brand } = React.useContext(SessionContext)

  if (!brand) {
    return null
  }

  return brand.inventory_locations.find(
    inventoryLocation => inventoryLocation.default === true
  )
}

export const useSound = () => {
  const audioRef = React.useRef(new Audio())
  const { debugScanner } = React.useContext(WarehouseContext)

  const clear = React.useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause()
      audioRef.current.currentTime = 0
    }
  }, [audioRef])

  const play = React.useCallback(
    (src, callback) => {
      if (audioRef.current) {
        audioRef.current.src = src
        audioRef.current.currentTime = 0
        audioRef.current.volume = 1.0
        audioRef.current.play()
        if (callback) {
          audioRef.current.onended = callback
        }
      }
    },
    [clear, audioRef]
  )

  return [play, clear]
}

export const useShipmentEvents = (shipmentId, exitEventType) => {
  return useWarehouseEventsRecorder('shipment', shipmentId, exitEventType)
}

export const useWarehouseEventsRecorder = (
  keyType,
  keyValue,
  exitEventType
) => {
  const { deviceId, isSubUsersInitialized, subUser, unsetMySubUserDevice } =
    React.useContext(WarehouseContext)

  const eventsRef = React.useRef({
    sync_tries: 0,
    events: [],
  })

  const addEvent = React.useCallback(
    (eventType, eventData = {}) => {
      eventsRef.current.events.push({
        happened_at: moment.utc().format(),
        event_id: uuid(),
        event_type: eventType,
        event_data: eventData,
      })
    },
    [eventsRef]
  )

  React.useEffect(() => {
    let interval

    const queueNextSync = () => {
      const wait = 5000 + (eventsRef.current.sync_tries + 1) * 1500

      interval = setTimeout(() => {
        attemptSync()
      }, wait)
    }

    const attemptSync = () => {
      const events = eventsRef.current.events

      if (events.length == 0) {
        queueNextSync()
        return
      }

      eventsRef.current.sync_tries++
      eventsRef.current.events = []

      const restore = () => {
        eventsRef.current.events = [...events, ...eventsRef.current.events]
      }

      recordWarehouseEvents(
        keyType,
        keyValue,
        events,
        subUser ? subUser.id : null,
        deviceId,
        () => {
          unsetMySubUserDevice()
        }
      )
        .then(response => {
          if (response.error) {
            restore()
          } else {
            eventsRef.current.sync_tries = 0
          }

          queueNextSync()
        })
        .catch(() => {
          restore()
          queueNextSync()
        })
    }

    if (!isSubUsersInitialized) {
      return
    }

    queueNextSync()

    return () => {
      addEvent(exitEventType)

      if (interval) {
        clearInterval(interval)
      }

      attemptSync()
    }
  }, [
    addEvent,
    deviceId,
    eventsRef,
    exitEventType,
    keyType,
    keyValue,
    isSubUsersInitialized,
    subUser,
    unsetMySubUserDevice,
  ])

  return {
    addEvent,
  }
}

export const useSubUser = (entity, user, deviceId, isDeviceIdInitialized) => {
  const fetchArgs = React.useMemo(() => {
    return []
  }, [])
  const fetchOptions = React.useMemo(() => {
    return {
      ignore: !user.use_sub_users,
    }
  }, [user.use_sub_users])
  const [
    subUsers,
    isFetchingSubUsers,
    { isInitialized: isSubUsersInitialized, refresh },
  ] = useMySubUsers(fetchArgs, fetchOptions)

  const isInitialized =
    !user.use_sub_users || (isDeviceIdInitialized && isSubUsersInitialized)

  React.useEffect(() => {
    let interval

    if (user.use_sub_users) {
      // refresh every hour
      interval = setInterval(refresh, 1000 * 60 * 60)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [refresh, user.use_sub_users])

  const [subUser, shouldShowSubUserList] = React.useMemo(() => {
    let subUser = null
    let shouldShowSubUserList = false

    if (user.use_sub_users) {
      let foundSubUser
      if (deviceId) {
        foundSubUser = subUsers.find(
          subUser => subUser.current_device_id == deviceId
        )
      }

      if (foundSubUser) {
        subUser = foundSubUser
      } else if (isDeviceIdInitialized && isSubUsersInitialized) {
        shouldShowSubUserList = true
      }
    }

    return [subUser, shouldShowSubUserList]
  }, [
    user.use_sub_users,
    subUsers,
    deviceId,
    isDeviceIdInitialized,
    isSubUsersInitialized,
  ])

  const onSetMySubUserDevice = React.useCallback(
    subUser => {
      return setMySubUserDevice(subUser.id, deviceId).then(response => {
        if (!response.error) {
          refresh()
        }
      })
    },
    [user, deviceId, refresh]
  )

  const unsetMySubUserDevice = React.useCallback(() => {
    if (!subUser) {
      return
    }

    return setMySubUserDevice(subUser.id, null).then(response => {
      if (!response.error) {
        refresh()
      }

      return response
    })
  }, [user, subUser, refresh])

  React.useEffect(() => {
    let interval = setInterval(() => {
      if (subUser && subUser.current_device_id_login_at) {
        const now = moment()
        const lastLoginAt = moment(subUser.current_device_id_login_at)

        if (now.date() != lastLoginAt.date()) {
          unsetMySubUserDevice()
        }
      }
    }, 1000 * 15)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [subUser, unsetMySubUserDevice])

  // we put this in a separate effect, so that we also identify on
  // browser refresh and similar
  React.useEffect(() => {
    if (user) {
      logrocketIdentify(user, entity, subUser)
    }
  }, [entity, user, subUser])

  return [
    subUser,
    shouldShowSubUserList,
    onSetMySubUserDevice,
    subUsers,
    unsetMySubUserDevice,
    isInitialized,
  ]
}

const {
  hook: useWarehouseVariantScreenData,
  hookCached: useCachedWarehouseVariantScreenData,
  clearCache: clearWarehouseVariantScreenDataCache,
} = quickCreateHook(getWarehouseVariantScreenData, 'variant', null, {
  v2: true,
})

export {
  useWarehouseVariantScreenData,
  useCachedWarehouseVariantScreenData,
  clearWarehouseVariantScreenDataCache,
}

const {
  hook: useWarehouseLocationScreenData,
  hookCached: useCachedWarehouseLocationScreenData,
  clearCache: clearWarehouseLocationScreenDataCache,
} = quickCreateHook(
  getWarehouseLocationScreenData,
  'warehouse_location',
  null,
  {
    v2: true,
  }
)

export {
  useWarehouseLocationScreenData,
  useCachedWarehouseLocationScreenData,
  clearWarehouseLocationScreenDataCache,
}

export const useWarehouseInventoryBarcodeScan = (
  navigate,
  history,
  barcode,
  setBarcode,
  ignore
) => {
  const [doCancellableRequest] = useCancellableRequest()

  const [initialBarcode] = React.useState(barcode)
  const previouslyScannedBarcode = React.useRef(null)

  const navigateToVariant = React.useCallback(
    id => {
      return navigate(history, `/warehouse/inventory/variant/${id}`)
    },
    [navigate, history]
  )

  const navigateToWarehouseLocation = React.useCallback(
    id => {
      return navigate(history, `/warehouse/inventory/locations/${id}`)
    },
    [navigate, history]
  )

  React.useEffect(() => {
    if (!barcode) {
      return
    }

    // dont want an old barcode to trigger
    if (
      barcode === initialBarcode ||
      barcode === previouslyScannedBarcode.current
    ) {
      return
    }

    previouslyScannedBarcode.current = barcode

    if (ignore) {
      return
    }

    doCancellableRequest(axiosOptions =>
      warehouseInventoryScan(axiosOptions, new String(barcode.barcode))
    ).then(response => {
      if (!response.error) {
        switch (response.payload.type) {
          case 'variant':
            navigateToVariant(response.payload.id)
            break

          case 'warehouse_location':
            navigateToWarehouseLocation(response.payload.id)
            break
        }

        setBarcode(null)
      }
    })
  }, [
    barcode,
    doCancellableRequest,
    ignore,
    navigateToWarehouseLocation,
    navigateToVariant,
  ])
}

export const useWarehouseSubLevelsScanning = () => {
  const { brand } = React.useContext(SessionContext)

  const [
    currentlySelectedWarehouseLocation,
    setCurrentlySelectedWarehouseLocation,
  ] = React.useState(null)

  const currentlySelectedWarehouseLocationRef = useRefValue(
    currentlySelectedWarehouseLocation
  )

  const inventoryLocation = useInventoryLocation()
  const requireSubLevelScans = inventoryLocation
    ? inventoryLocation.require_sub_level_scans
    : false

  const usesWarehouseLocationsV2 = userHasFlag(brand, 'warehouse_locations_v2')

  const [warehouseLocations, isWarehouseLocationsInitialized] =
    useWarehouseLocations()

  const setCurrentlySelectedWarehouseLocationIfLocation = React.useCallback(
    barcode => {
      const location = warehouseLocations.find(
        location => location.location === barcode
      )

      if (!location) {
        return false
      }

      setCurrentlySelectedWarehouseLocation(location)

      return true
    },
    [setCurrentlySelectedWarehouseLocation, warehouseLocations]
  )

  const isInitialized = isWarehouseLocationsInitialized

  return {
    currentlySelectedWarehouseLocation,
    currentlySelectedWarehouseLocationRef,
    isInitialized,
    requireSubLevelScans,
    setCurrentlySelectedWarehouseLocationIfLocation,
    usesWarehouseLocationsV2,
    warehouseLocations,
  }
}
