/* @flow */

import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  createContext,
  useCallback,
} from 'react'
import styled from 'styled-components'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'
import partition from 'lodash/partition'
import uuid from 'uuid'
import produce from 'immer'

import ProductCache from '../ProductCache'
import HoverImage from './HoverImage'
import { ProductRow } from './Rows'
import TableSectionHeader from './TableSectionHeader'
import TraditionalVariantsHeaderRow from './Rows/RowsRenderer/TraditionalRowsRenderer/TraditionalVariantsHeaderRow'
import TraditionalVariantsFooterRow from './Rows/RowsRenderer/TraditionalRowsRenderer/TraditionalVariantsFooterRow'
import SummaryProductTable from './SummaryProductTable'
import {
  CheckmarkDropdown,
  CheckmarkDropdownSeparator,
  CheckmarkDropdownButton,
} from '../../../../infrastructure/components/Dropdown'
import {
  IMAGE_RADIUS_CIRCLE,
  IMAGE_RADIUS_ROUNDED,
  IMAGE_SIZE_SMALL,
  IMAGE_SIZE_MEDIUM,
  IMAGE_SIZE_LARGE,
  IMAGE_SIZE_CUSTOM,
} from '../../../settings/brand/constants'

import * as productDataConfigurations from './configuration/product_data'
import * as rowConfigurations from './configuration/rows'
import * as columnConfigurations from './configuration/columns'

import { SessionContext } from '../../../shared'
import {
  TableRow,
  TableColumn,
  ProductTableContext,
  createEmptyArray,
  determineHorizontalAttribute,
  createConfiguredVariant,
  sortAttributesBySortOrder,
} from './shared'

import {
  createDataFetchPromises,
  DataCache,
  generateLinesByProduct,
  useProductTableSections,
} from './data'

import type { Currency, Customer, Id, Line } from '../../../types'

import type {
  ColumnConfig,
  RowConfigInput,
  TableControlsChangeFunction,
  TableControlsState,
} from './types'

type Props = {
  brandId: Id,
  columns: Array<ColumnConfig>,
  collapseByDefault?: boolean,
  customer: Customer,
  isMobile?: boolean,
  lines: Array<Line>,
  mode: 'inventory' | 'product_table',
  onTableControlsChange: TableControlsChangeFunction,
  preview?: boolean,
  productIds?: Array<Id>,
  productImage?: boolean,
  rows: Array<RowConfigInput>,
  showNoosLabel?: boolean,
  tableControls: TableControlsState,
  tableSectionFilter?: Object,
  title?: string,
  variantImages?: boolean,
  useOutsideProductCache?: boolean,
}

// if we use data = {} it will cause a lot of re-renders
const defaultDataConfig = {}
const defaultContext = {}
const defaultProductActions = []
const defaultProductCacheRequestData = {}
const defaultSettingsOverride = {}
const defaultTableActions = []

export const ProductTable = (
  {
    brandId,
    context: dataContext = defaultContext,
    collapseByDefault = false,
    columns,
    customer,
    data = defaultDataConfig,
    defaultRowData,
    extraColumnsDataOverrides,
    extraRowsDataOverrides,
    filterVariantsAlwaysRequireLine = false,
    filterVariantsWithoutLinesByDefault = false,
    filterVariantsByLinesAndData,
    isMobile = false,
    lines,
    linesByProductId,
    mode = 'inventory',
    onDataCacheUpdate,
    onLineChange,
    onTableControlsChange,
    preview = false,
    products,
    productActions = defaultProductActions,
    productCacheRequestData = defaultProductCacheRequestData,
    productDataConfig = false,
    productDataOverrides,
    productIds = [],
    productImage,
    rows,
    settings,
    settingsOverride = defaultSettingsOverride,
    showNoosLabel = false,
    summaryProductTableActions,
    summaryProductTableState,
    tableActions = defaultTableActions,
    tableControls,
    tableId,
    tableSections,
    tableSectionFilter,
    tableSettings = true,
    title,
    variantImages,
    verticalAttributeColumns,
    useOutsideProductCache = false,
    useLinesReducerV2 = false,
  }: Props,
  ref
) => {
  const { brands } = useContext(SessionContext)

  const productCacheRef = React.useRef(null)
  const dataCache = React.useRef(new DataCache())
  const [forceUpdate, setForceUpdate] = React.useState({})
  const brand = brands[brandId]

  const quickToggles = React.useMemo(() => {
    const quickToggles = {
      columns: {
        show: [],
        hide: [],
      },
      product_data: {
        show: [],
        hide: [],
      },
      rows: {
        show: [],
        hide: [],
      },
    }

    if (tableControls.quickToggles) {
      for (let toggle of tableControls.quickToggles) {
        switch (toggle.type) {
          case 'column':
            if (toggle.show) {
              quickToggles.columns.show.push(toggle.value)
            } else {
              quickToggles.columns.hide.push(toggle.value)
            }
            break

          case 'row':
            if (toggle.show) {
              quickToggles.rows.show.push(toggle.value)
            } else {
              quickToggles.rows.hide.push(toggle.value)
            }
            break

          case 'product_data':
            if (toggle.show) {
              quickToggles.product_data.show.push(toggle.value)
            } else {
              quickToggles.product_data.hide.push(toggle.value)
            }
            break

          default:
            break
        }
      }
    }

    return quickToggles
  }, [tableControls])

  const productTableContext = useMemo(() => {
    const mergedSettings = mergeInputSettingsWithDefaults(
      settings ? settings : {},
      settingsOverride
    )

    let {
      columns: columnsWithQuickToggles,
      extra_columns: extraColumns,
      rows: rowsWithQuickToggles,
      extra_rows: extraRows,
      productDataConfig: productDataConfigWithQuickToggles,
    } = addQuickTogglesToConfigs(
      rows,
      mergedSettings.extra_rows || [],
      columns,
      mergedSettings.extra_columns || [],
      mergedSettings.product_data || [],
      quickToggles
    )

    let useProductDataConfig
    // legacy: pass in product data config from props
    // quickToggles will not work for these product tables
    if (productDataConfig) {
      useProductDataConfig = productDataConfig

      // create from product table settings
    } else {
      useProductDataConfig = productDataConfigWithQuickToggles
        .map(rowKey => {
          const matches = rowKey.match(/^meta_(product|variant)_(.+)$/)
          const overrides = productDataOverrides
            ? productDataOverrides[rowKey] || {}
            : {}

          let rowCreator = productDataConfigurations[rowKey]
          const combinedRowCreatorParams = {
            editable: false,
            ...overrides,
          }

          if (matches) {
            rowCreator = productDataConfigurations.custom_field

            const customField = brand.custom_fields.find(
              c => c.group_type === matches[1] && c.internal_name === matches[2]
            )

            if (!customField) {
              return null
            }

            combinedRowCreatorParams.customField = customField
          }

          if (!rowCreator) {
            return null
          }

          return rowCreator(combinedRowCreatorParams)
        })
        // remove null values
        .filter(c => c)
    }

    // When viewed on mobile, force traditional product table, because that one
    // fits narrow mobile screen better
    if (isMobile === true) {
      mergedSettings.matrix = false
    }

    // No product headers mode can be enabled only for traditional table
    const noProductHeadersMode = mergedSettings.matrix
      ? false
      : mergedSettings.no_product_headers_mode === true

    let mappedProductActions = productActions
    // No product headers mode does not support product actions, so we set to empty
    // for correct column count calculations
    if (noProductHeadersMode) {
      mappedProductActions = []
    }

    // if there is no table ID or table settings then extra_rows is undefined
    const mappedExtraRows = extraRows
      .map(rowKey => {
        let rowCreator = rowConfigurations[rowKey]

        const overrides = extraRowsDataOverrides
          ? extraRowsDataOverrides[rowKey] || {}
          : {}

        const combinedRowCreatorParams = {
          editable: false,
          ...overrides,
        }

        const productVariantMatches = rowKey.match(/^__(product|variant)_(.+)$/)
        if (productVariantMatches) {
          rowCreator = rowConfigurations[productVariantMatches[2]]
        }

        const customFieldMatches = rowKey.match(/^meta_(product|variant)_(.+)$/)
        if (customFieldMatches) {
          rowCreator = rowConfigurations.custom_field

          const customField = brand.custom_fields.find(
            c =>
              c.group_type === customFieldMatches[1] &&
              c.internal_name === customFieldMatches[2]
          )

          if (!customField) {
            return null
          }

          combinedRowCreatorParams.customField = customField
        }

        if (!rowCreator) {
          return null
        }

        return rowCreator(combinedRowCreatorParams)
      })
      // remove null values
      .filter(c => c)

    const existingRowKeys = rowsWithQuickToggles.map(row => row.key)
    const finalRows = [...rowsWithQuickToggles]

    for (let extraRow of mappedExtraRows) {
      // e.g. in inventory profile you can add SKU as a `row`, but you might
      // also have SKUs added as extra row in product table settings
      if (existingRowKeys.includes(extraRow.key)) {
        continue
      }

      finalRows.push(extraRow)
    }

    const mappedExtraColumns = extraColumns
      .map(columnKey => {
        const matches = columnKey.match(
          /^meta_(order_line|production_order_line)_(.+)$/
        )

        const overrides = extraColumnsDataOverrides
          ? extraColumnsDataOverrides[columnKey] || {}
          : {}

        let columnCreator = columnConfigurations[columnKey]
        const combinedColumnCreatorParams = {
          editable: false,
          ...overrides,
        }

        if (matches) {
          columnCreator = columnConfigurations.custom_field

          const customField = brand.custom_fields.find(
            c => c.group_type === matches[1] && c.internal_name === matches[2]
          )

          if (!customField) {
            return null
          }

          combinedColumnCreatorParams.customField = customField
        }

        if (!columnCreator) {
          return null
        }

        const rows = []
        if (finalRows.length > 0) {
          if (finalRows[0].type === 'group') {
            rows.push(finalRows[0].configs[0].key)
          } else {
            rows.push(finalRows[0].key)
          }
        }

        return columnCreator({
          ...combinedColumnCreatorParams,
          rows: rows,
        })
      })
      // remove null values
      .filter(c => c)

    const [addAtBeginningExtraColumns, addAtEndExtraColumns] = partition(
      mappedExtraColumns,
      mappedExtraColumn =>
        !['total_assortment_quantity'].includes(mappedExtraColumn.key)
    )

    const finalColumns = [
      ...addAtBeginningExtraColumns,
      ...columnsWithQuickToggles,
      ...addAtEndExtraColumns,
    ]

    const useColumns = finalColumns.filter(
      c => c.matrix === mergedSettings.matrix || c.matrix === undefined
    )

    return {
      attribute_codes: mergedSettings.attribute_codes || [],
      attributes: mapAttributes(brand.attributes),
      brand,
      brandSettings: brand.settings,
      columns: useColumns,
      columnKeys: useColumns.map(c => c.key),
      defaultRowData,
      horizontalAttribute: mergedSettings.matrix
        ? determineHorizontalAttribute(brand, mergedSettings)
        : null,
      imageSettings: determineImageSettings(mergedSettings),
      matrix: mergedSettings.matrix,
      noProductHeadersMode,
      preview,
      productActions: mappedProductActions,
      productDataConfig: useProductDataConfig,
      productDataConfigKeys: useProductDataConfig.map(c => c.key),
      productDataOverrides,
      productImage: determineProductImage(productImage, mergedSettings),
      rows: mergedSettings.matrix ? finalRows : reverseRowsOrder(finalRows),
      settings: mergedSettings,
      updateLines: onLineChange,
      variantImages: determineVariantImages(variantImages, mergedSettings),
      verticalAttributeColumns,
    }
  }, [
    brand,
    columns,
    defaultRowData,
    extraColumnsDataOverrides,
    extraRowsDataOverrides,
    isMobile,
    rows,
    preview,
    productActions,
    productDataConfig,
    productDataOverrides,
    productImage,
    settings,
    settingsOverride,
    onLineChange,
    quickToggles,
    variantImages,
    verticalAttributeColumns,
  ])

  const onClearDataCache = React.useCallback(() => {
    // TODO: Find a more elegant way to clear single data keys, since
    // this will cause all data to refetch
    dataCache.current = new DataCache()

    // changing the ref will not cause re-render, so we do like this
    setForceUpdate({})
  }, [dataCache, setForceUpdate])

  React.useImperativeHandle(ref, () => ({
    clearProductDataCache: () => {
      if (productCacheRef.current) {
        productCacheRef.current.reload()
      }
    },
    clearDataCache: () => {
      onClearDataCache()
    },
  }))

  const renderData = useMemo(() => {
    return {
      columns,
      collapseByDefault,
      data,
      dataCache,
      dataContext,
      onClearDataCache,
      onDataCacheUpdate,
      filterVariantsAlwaysRequireLine,
      filterVariantsWithoutLinesByDefault,
      filterVariantsByLinesAndData,
      lines,
      linesByProductId,
      onTableControlsChange,
      setForceUpdate,
      showNoosLabel,
      summaryProductTableActions,
      summaryProductTableState,
      tableActions,
      tableControls,
      tableId,
      tableSections,
      tableSectionFilter,
      tableSettings,
      title,
      useLinesReducerV2,
    }
  }, [
    columns,
    collapseByDefault,
    data,
    dataCache,
    dataContext,
    onClearDataCache,
    onDataCacheUpdate,
    onTableControlsChange,
    filterVariantsAlwaysRequireLine,
    filterVariantsWithoutLinesByDefault,
    filterVariantsByLinesAndData,
    lines,
    linesByProductId,
    setForceUpdate,
    showNoosLabel,
    summaryProductTableActions,
    summaryProductTableState,
    tableActions,
    tableControls,
    tableId,
    tableSections,
    tableSectionFilter,
    tableSettings,
    title,
    useLinesReducerV2,
  ])

  return (
    <div>
      <ProductTableContext.Provider value={productTableContext}>
        {!useOutsideProductCache && (
          <ProductCache
            brandId={brandId}
            customer={customer}
            mode={mode}
            productIds={productIds}
            ref={productCacheRef}
            requestData={productCacheRequestData}
            renderData={renderData}
            render={RenderProductCache}
          />
        )}

        {useOutsideProductCache && (
          <SummaryProductTableWrap products={products} {...renderData} />
        )}
      </ProductTableContext.Provider>
    </div>
  )
}

const RenderProductCache = ({
  isFetching,
  keyCreator,
  productCache,
  productIds,
  renderData,
}) => {
  const products = React.useMemo(() => {
    return productIds
      .map(id => {
        return productCache[keyCreator(id)]
      })
      .filter(product => product)
  }, [keyCreator, productIds, productCache])

  return <SummaryProductTableWrap products={products} {...renderData} />
}

const SummaryProductTableWrap = ({
  dataContext,
  linesByProductId: propsLinesByProductId,
  products: propsProducts,
  summaryProductTableActions,
  summaryProductTableState,
  ...props
}) => {
  let showSummaryProductId = false
  let showSummaryProductAttributesFilter = null
  let showSummaryTable = false
  if (summaryProductTableState) {
    if (summaryProductTableState.product_id_filter) {
      showSummaryProductId = summaryProductTableState.product_id_filter
      showSummaryProductAttributesFilter =
        summaryProductTableState.attributes_filter
    } else if (summaryProductTableState.show) {
      showSummaryTable = true
    }
  }

  const [products, linesByProductId] = React.useMemo(() => {
    if (!showSummaryProductId) {
      return [propsProducts, propsLinesByProductId]
    }

    let product = propsProducts.find(p => p.id == showSummaryProductId)

    if (!product) {
      return [propsProducts, propsLinesByProductId]
    }

    let linesByProductId

    if (showSummaryProductAttributesFilter) {
      const variants = [...product.variants].filter(variant => {
        let matches = true
        for (let [attribute, value] of Object.entries(
          showSummaryProductAttributesFilter
        )) {
          if (variant.attributes[attribute] !== value) {
            matches = false
            break
          }
        }

        return matches
      })
      const variantIds = variants.map(v => v.id)

      product = {
        ...product,
        variants,
      }

      const filteredLinesOfProduct = (
        propsLinesByProductId[product.id] || []
      ).filter(line => {
        return variantIds.includes(line.variant_id)
      })

      linesByProductId = {
        [product.id]: filteredLinesOfProduct,
      }
    } else {
      linesByProductId = {
        [product.id]: propsLinesByProductId[product.id] || [],
      }
    }

    return [[product], linesByProductId]
  }, [
    propsProducts,
    propsLinesByProductId,
    showSummaryProductId,
    showSummaryProductAttributesFilter,
  ])

  return (
    <>
      {showSummaryTable && (
        <SummaryProductTable
          dataContext={dataContext}
          linesByProductId={linesByProductId}
          products={products}
          summaryProductTableState={summaryProductTableState}
          summaryProductTableActions={summaryProductTableActions}
          {...props}
        />
      )}
      {!showSummaryTable && (
        <RenderTablesWithProducts
          dataContext={dataContext}
          linesByProductId={linesByProductId}
          products={products}
          summaryProductTableState={summaryProductTableState}
          summaryProductTableActions={summaryProductTableActions}
          {...props}
        />
      )}
    </>
  )
}

const RenderTablesWithProducts = ({
  collapseByDefault,
  dataCache,
  data: dataConfigs,
  dataContext,
  filterVariantsAlwaysRequireLine,
  filterVariantsWithoutLinesByDefault,
  filterVariantsByLinesAndData,
  lines,
  linesByProductId,
  onClearDataCache,
  onDataCacheUpdate,
  onTableControlsChange,
  products: inputProducts,
  setForceUpdate,
  summaryProductTableActions,
  showNoosLabel,
  summaryProductTableState,
  tableActions,
  tableId,
  tableSections: tableSectionsConfig,
  tableSectionFilter,
  tableSettings,
  tableControls,
  title,
  useLinesReducerV2,
}) => {
  const [dataCacheData, setDataCacheData] = useState(
    dataCache.current.getDataCacheData([], dataConfigs)
  )
  const [isDataInitialized, setIsDataInitialized] = React.useState(false)
  const {
    brand,
    columns,
    dataConfigByType,
    horizontalAttribute,
    matrix,
    noProductHeadersMode,
    preview,
    productActions,
    rows,
    settings,
    updateLines,
  } = useContext(ProductTableContext)

  const { togglesByProduct } = tableControls
  const [activeField, setActiveField] = React.useState(false)
  const [productRowRefs, setProductRowRefs] = React.useState({})
  const [configuredVariantsByProductId, setConfiguredVariantsByVariantId] =
    React.useState({})
  const [{ productRows, products }, setProductRows] = React.useState({
    productRows: [],
    products: [],
  })
  const productIds = React.useMemo(() => products.map(p => p.id), [products])

  const variantSortValueByProductId = useVariantSortValue(
    lines,
    linesByProductId,
    inputProducts,
    settings.sort,
    useLinesReducerV2
  )

  React.useEffect(() => {
    const productRows = []
    const products = []

    for (let product of inputProducts) {
      if (product.type === 'configurable') {
        const variantsWithConfiguredVariants = produce(
          product.variants,
          draft => {
            const configuredVariants =
              configuredVariantsByProductId[product.id] || []

            for (let configuredVariant of configuredVariants) {
              let wasFound = false

              for (let variant of product.variants) {
                if (variant.placeholder_variant) {
                  continue
                }

                let matches = true
                for (let [attribute, value] of Object.entries(
                  configuredVariant.attributes
                )) {
                  if (value !== variant.attributes[attribute]) {
                    matches = false
                    break
                  }
                }

                if (matches) {
                  wasFound = true
                }
              }

              if (!wasFound) {
                draft.push(configuredVariant)
              }
            }
          }
        )

        product.variants = variantsWithConfiguredVariants
      }

      productRows.push(product)
      products.push(product)
    }

    setProductRows({
      productRows:
        settings.sort !== false
          ? sortProductRows(
              productRows,
              settings.sort,
              variantSortValueByProductId
            )
          : productRows,
      products,
    })
  }, [
    brand,
    configuredVariantsByProductId,
    horizontalAttribute,
    inputProducts,
    matrix,
    setProductRows,
    settings,
    variantSortValueByProductId,
  ])

  const maxHorizontalColumns = React.useMemo(() => {
    let maxHorizontalColumns = 1

    for (let product of products) {
      if (horizontalAttribute) {
        let variantHorizontalAttributes = new Set()
        for (let variant of product.variants) {
          if (variant.placeholder_variant) {
            continue
          }

          const horizontalValue = variant.attributes[horizontalAttribute]

          if (horizontalValue) {
            variantHorizontalAttributes.add(horizontalValue)
          }

          if (variant.assortment) {
            for (let assortmentAttributeValue in variant.assortment) {
              variantHorizontalAttributes.add(assortmentAttributeValue)
            }
          }
        }

        if (variantHorizontalAttributes.size > maxHorizontalColumns) {
          maxHorizontalColumns = variantHorizontalAttributes.size
        }
      }
    }

    return maxHorizontalColumns
  }, [horizontalAttribute, products])

  const configurableProducts = React.useMemo(() => {
    return productRows.filter(p => p.type === 'configurable')
  }, [productRows])

  React.useEffect(() => {
    // updateLines does not exist in preview mode
    if (!updateLines) {
      return
    }

    let useLinesByProductId = linesByProductId
    if (!useLinesReducerV2) {
      useLinesByProductId = groupBy(lines, 'product_id')
    }

    const updateLinesWithConfiguredVariant = []
    const newConfiguredVariantsByProductId = {}
    for (let product of configurableProducts) {
      const linesOfProduct = useLinesByProductId[product.id] || []
      const placeholderVariant = product.variants.find(
        v => v.placeholder_variant
      )

      for (let line of linesOfProduct) {
        if (line.variant_id != placeholderVariant.id) {
          continue
        }

        const configuredVariant = createConfiguredVariant(
          brand.attributes,
          product,
          placeholderVariant,
          line.configurator_values
        )

        if (!newConfiguredVariantsByProductId[product.id]) {
          newConfiguredVariantsByProductId[product.id] = []
        }

        newConfiguredVariantsByProductId[product.id].push(configuredVariant)
        updateLinesWithConfiguredVariant.push({
          line,
          placeholder_variant: placeholderVariant,
          new_variant: configuredVariant,
        })
      }
    }

    if (updateLinesWithConfiguredVariant.length > 0) {
      setConfiguredVariantsByVariantId(s => {
        return produce(s, draft => {
          for (let [productId, newVariants] of Object.entries(
            newConfiguredVariantsByProductId
          )) {
            if (!draft[productId]) {
              draft[productId] = []
            }

            for (let variant of newVariants) {
              draft[productId].push(variant)
            }
          }
        })
      })

      updateLines({
        type: 'set_configured_variants',
        updates: updateLinesWithConfiguredVariant,
      })
    }
  }, [
    brand,
    configurableProducts,
    lines,
    linesByProductId,
    updateLines,
    setConfiguredVariantsByVariantId,
    useLinesReducerV2,
  ])

  React.useEffect(() => {
    setProductRowRefs(s => {
      const updated = { ...s }

      let changed = false
      for (let product of productRows) {
        if (!updated[product.id]) {
          updated[product.id] = React.createRef(null)
          changed = true
        }
      }

      if (!changed) {
        return s
      }

      return updated
    })
  }, [productRows, setProductRowRefs])

  const onSettingsMenuSelect = newValue => {
    console.log('SETTINGS MENU', args)
  }

  const collapseAllProducts = React.useCallback(() => {
    onTableControlsChange({
      type: 'update_toggles',
      product_ids: productIds,
      data: {
        collapsed: true,
      },
    })
  }, [onTableControlsChange, productIds])
  const openAllProducts = React.useCallback(() => {
    onTableControlsChange({
      type: 'update_toggles',
      product_ids: productIds,
      data: {
        collapsed: false,
      },
    })
  }, [onTableControlsChange, productIds])
  const setAllFilterVariantsWithoutLines = React.useCallback(() => {
    let setTrue = false
    for (let productId of productIds) {
      const togglesOfProduct = togglesByProduct[productId] || {}

      if (!togglesOfProduct.filter_variants_without_lines) {
        setTrue = true
        break
      }
    }

    onTableControlsChange({
      type: 'update_toggles',
      product_ids: productIds,
      data: {
        filter_variants_without_lines: setTrue,
      },
    })
  }, [onTableControlsChange, productIds, togglesByProduct])

  const onProductJump = React.useCallback(direction => {}, [productRowRefs])

  const onConfiguratorAdd = React.useCallback(
    (product, variant) => {
      setConfiguredVariantsByVariantId(s => {
        return produce(s, draft => {
          if (!draft[product.id]) {
            draft[product.id] = []
          }

          draft[product.id].push(variant)
        })
      })
    },
    [setConfiguredVariantsByVariantId]
  )

  const noActionColumns = React.useMemo(
    () => calculateActionColumns(productActions, rows),
    [productActions, rows]
  )

  React.useEffect(() => {
    const foundProductIds = products.map(p => p.id)

    let linesWithFoundProduct = []
    if (!useLinesReducerV2) {
      linesWithFoundProduct = lines.filter(line =>
        foundProductIds.includes(line.product_id)
      )
    } else {
      linesWithFoundProduct = foundProductIds.reduce((carry, productId) => {
        return carry.concat(linesByProductId[productId] || [])
      }, [])
    }

    // TODO: Check if run fast enough this hook can run again before promises are made
    const newPromises = createDataFetchPromises({
      dataCache: dataCache.current,
      dataConfigs,
      dataContext,
      lines: linesWithFoundProduct,
      products,
    })

    dataCache.current.setNewPromises(dataConfigs, newPromises, () => {
      const updatedDataCacheData = dataCache.current.getDataCacheData(
        products,
        dataConfigs
      )

      setDataCacheData(updatedDataCacheData)

      if (onDataCacheUpdate) {
        onDataCacheUpdate(updatedDataCacheData)
      }
    })

    // update data cache data with new is fetching indicators
    // TODO: Performance - is fetching will cause regeneration of sections
    //setDataCacheData(dataCache.current.getDataCacheData(products, dataConfigs))
  }, [
    dataCache.current,
    dataConfigs,
    dataContext,
    lines,
    linesByProductId,
    onDataCacheUpdate,
    products,
    setDataCacheData,
    useLinesReducerV2,
  ])

  React.useEffect(() => {
    if (isDataInitialized || !dataCache.current) {
      return
    }

    let isInitialized = dataCache.current.isInitialized(dataConfigs)

    if (isInitialized) {
      setIsDataInitialized(true)
    }
  }, [
    dataConfigs,
    dataCacheData,
    dataCache,
    isDataInitialized,
    setIsDataInitialized,
  ])

  const tableSections = useProductTableSections(
    dataCacheData.product,
    dataCacheData.table,
    dataConfigs,
    preview,
    productRows,
    lines,
    linesByProductId,
    tableSectionsConfig,
    useLinesReducerV2,
    tableSectionFilter
  )

  if (!isDataInitialized) {
    return null
  }

  return (
    <>
      <TopContainer>
        {title && <TitleContainer>{title}</TitleContainer>}

        <TableActionsContainer>
          {tableActions
            .filter(action => action)
            .map(action => (
              <div className="print-hide" key={action.key}>
                {action.render({
                  brand,
                  columns,
                  lines,
                  linesByProductId,
                  onTableControlsChange,
                  productRowRefs,
                  tableId,
                  tableControls,
                  summaryProductTableActions,
                  summaryProductTableState,
                  updateLines,
                  useLinesReducerV2,
                })}
              </div>
            ))}

          {tableSettings === true && (
            <div className="print-hide">
              <CheckmarkDropdown id="product_table_settings" title="Settings">
                <CheckmarkDropdownButton
                  controlled
                  key="collapse_all"
                  label="Collapse all products"
                  toggle={collapseAllProducts}
                  value="collapse_all"
                />
                <CheckmarkDropdownButton
                  controlled
                  key="open_all"
                  label="Open all products"
                  toggle={openAllProducts}
                  value="open_all"
                />

                <CheckmarkDropdownSeparator />

                <CheckmarkDropdownButton
                  controlled
                  key="filter_variants_without_lines"
                  label="Filter variants without lines"
                  toggle={setAllFilterVariantsWithoutLines}
                  value="filter_variants_without_lines"
                />
              </CheckmarkDropdown>
            </div>
          )}
        </TableActionsContainer>
      </TopContainer>

      <Table data-testid="product-table">
        {tableSections.map(tableSection => {
          return (
            <React.Fragment key={tableSection.tableSectionKey}>
              {(tableSections.length > 1 ||
                tableSection.always_show_header) && (
                <TableSectionHeader
                  key={`${tableSection.tableSectionKey}-header`}
                  label={tableSection.tableSectionLabel}
                  maxHorizontalColumns={maxHorizontalColumns}
                  noActionColumns={noActionColumns}
                  tableSection={tableSection}
                  tableSectionsConfig={tableSectionsConfig}
                />
              )}

              {/* In no product headers mode the headers for columns are not rendered for each
                  product and we render the headers only once per table section here */}
              {noProductHeadersMode && (
                <tbody>
                  <TraditionalVariantsHeaderRow
                    id="variants-header"
                    noActionColumns={noActionColumns}
                  />
                </tbody>
              )}

              {tableSection.products.map(product => {
                return (
                  <ProductRow
                    activeField={activeField}
                    collapseByDefault={collapseByDefault}
                    data={tableSection.data_cache[product.id]}
                    dataConfigs={dataConfigs}
                    dataContext={dataContext}
                    data-testid={`${tableSection.tableSectionKey}-product-row-${product.id}`}
                    filterVariantsAlwaysRequireLine={
                      filterVariantsAlwaysRequireLine
                    }
                    filterVariantsWithoutLinesByDefault={
                      filterVariantsWithoutLinesByDefault
                    }
                    filterVariantsByLinesAndData={filterVariantsByLinesAndData}
                    lines={tableSection.lines[product.id]}
                    key={`${tableSection.tableSectionKey}-${product.id}`}
                    maxHorizontalColumns={maxHorizontalColumns}
                    noActionColumns={noActionColumns}
                    onClearDataCache={onClearDataCache}
                    onConfiguratorAdd={onConfiguratorAdd}
                    onProductJump={onProductJump}
                    onTableControlsChange={onTableControlsChange}
                    productActions={productActions}
                    product={product}
                    ref={productRowRefs[product.id]}
                    toggles={togglesByProduct[product.id]}
                    setActiveField={setActiveField}
                    showNoosLabel={showNoosLabel}
                    tableData={dataCacheData.table}
                    tableSectionData={tableSection.tableSectionData}
                    tableSectionKey={tableSection.tableSectionKey}
                  />
                )
              })}

              {/* In product headers mode there is no split row after product row, but we want to
                  separate the variant rows from the potential next table section, so we render
                  the footer row here */}
              {noProductHeadersMode && (
                <tbody>
                  <TraditionalVariantsFooterRow
                    id="variants-footer"
                    noActionColumns={noActionColumns}
                  />
                </tbody>
              )}
            </React.Fragment>
          )
        })}
      </Table>
    </>
  )
}

const ProductTableWithRefForwarded = React.forwardRef(ProductTable)

const ProductTableWithSettings = ({ brandId, tableId, ...props }, ref) => {
  const { brands } = React.useContext(SessionContext)
  const brand = brands[brandId]

  const productTableSettingsToUse = useMemo(() => {
    let defaultSettings = brand.product_table_settings.default

    let overrideSettings = {}
    if (tableId && brand.product_table_settings[tableId]) {
      overrideSettings = brand.product_table_settings[tableId].settings
    }

    return {
      ...defaultSettings.settings,
      ...overrideSettings,
    }
  }, [brand, tableId])

  return (
    <ProductTableWithRefForwarded
      brandId={brandId}
      ref={ref}
      settings={productTableSettingsToUse}
      tableId={tableId}
      {...props}
    />
  )
}

export default React.forwardRef(ProductTableWithSettings)

const Table = styled.table`
  width: 100%;
`

const TopContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const TitleContainer = styled.div`
  font-size: 20px;
`

const TableActionsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  > div {
    margin-left: 10px;
  }
`

const mergeInputSettingsWithDefaults = (inputSettings, settingsOverride) => {
  const defaultSettings = {
    attribute_codes: [],
    bordered: false,
    columns: [
      'name',
      'image',
      'quantity',
      'price',
      'total_price',
      'item_number',
      'collection',
      'subbrand',
      'rec_sales_price',
    ],
    custom_diameter: 200,
    extra_columns: [],
    extra_rows: [],
    hide_attributes: [],
    horizontal_attribute: 'auto',
    image_shape: 'circle',
    image_size: 'small',
    matrix: true,
    no_product_headers_mode: false,
    product_data: [],
    product_data_show: true,
    product_header_show: true,
    product_header_mode: 'default',
    product_name_label:
      '{{#name}}{{name}}{{/name}} {{#item_number}}(#{{item_number}}){{/item_number}} {{#collection}}({{collection}}){{/collection}} {{#subbrand}}({{subbrand}}){{/subbrand}}',
    product_header_color: null,
    product_header_font_color: null,
    rec_sales_price_label: 'RRP',
    rec_sales_price_label_tooltip: 'Recommended Retail Price',
    sales_price_label: 'WHS',
    sales_price_label_tooltip: 'Wholesale Price',
    show_product_image: true,
    show_variant_images: true,
    show_variant_popover: true,
    show_whs_after_discount: false,
    sort: 'name',
    zoom_custom_height: '',
    zoom_custom_width: '',
    zoom_on_hover: true,
    zoom_size: 'large',
    variant_popover_data: ['inventory', 'sku', 'ean', 'prices', 'colli_prices'],
  }

  return {
    ...defaultSettings,
    ...inputSettings,
    ...settingsOverride,
  }
}

const addQuickTogglesToConfigs = (
  rows,
  extraRows,
  columns,
  extraColumns,
  productDataConfig,
  quickToggles
) => {
  // ROWS
  const mappedRows = rows.filter(
    row => !quickToggles.rows.hide.includes(row.key)
  )
  const mappedExtraRows = extraRows.filter(
    rowKey => !quickToggles.rows.hide.includes(rowKey)
  )

  const rowKeys = mappedRows.map(row => row.key)

  for (let rowKey of quickToggles.rows.show) {
    if (!rowKeys.includes(rowKey) && !mappedExtraRows.includes(rowKey)) {
      mappedExtraRows.push(rowKey)
    }
  }

  // COLUMNS
  const mappedColumns = columns.filter(
    row => !quickToggles.columns.hide.includes(row.key)
  )
  const mappedExtraColumns = extraColumns.filter(
    columnKey => !quickToggles.columns.hide.includes(columnKey)
  )

  const columnKeys = mappedColumns.map(row => row.key)

  for (let columnKey of quickToggles.columns.show) {
    if (
      !columnKeys.includes(columnKey) &&
      !mappedExtraColumns.includes(columnKey)
    ) {
      mappedExtraColumns.push(columnKey)
    }
  }

  // PRODUCT DATA
  // We don't need to have a separate "extra" for product data because these
  // are already strings, not objects like rows and columns
  const mappedProductDataConfig = productDataConfig.filter(key => {
    return !quickToggles.product_data.hide.includes(key)
  })

  const productDataConfigKeys = mappedProductDataConfig.map(key => key)

  for (let productDataConfigKey of quickToggles.product_data.show) {
    if (!productDataConfigKeys.includes(productDataConfigKey)) {
      mappedProductDataConfig.push(productDataConfigKey)
    }
  }

  return {
    columns: mappedColumns,
    extra_columns: mappedExtraColumns,
    extra_rows: mappedExtraRows,
    productDataConfig: mappedProductDataConfig,
    rows: mappedRows,
  }
}

const calculateActionColumns = (productActions, rows) => {
  let actionColumns = 0

  let hasSplittable
  for (let rowGroup of rows) {
    if (rowGroup.type !== 'group') {
      rowGroup = { type: 'group', configs: [rowGroup] }
    }

    for (let row of rowGroup.configs) {
      if (row.splittable) {
        hasSplittable = true
      }

      if (!row.actions) {
        continue
      }

      if (row.actions.length > actionColumns) {
        actionColumns = row.actions.length
      }
    }
  }

  if (hasSplittable) {
    actionColumns++
  }

  return Math.max(productActions.length, actionColumns)
}

const mapAttributes = attributes => {
  const mapped = {}

  for (let attribute of attributes) {
    const name = attribute.name.toLowerCase()

    const values = sortBy(attribute.values, 'sort_order')

    if (!mapped[name]) {
      mapped[name] = {
        sort_order: [],
        tooltips: {},
      }
    }

    for (let value of attribute.values) {
      mapped[name].sort_order[value.sort_order] = value.name

      if (
        value.show_description === true &&
        typeof value.description === 'string' &&
        value.description.length > 0
      ) {
        mapped[name].tooltips[value.name] = value.description
      }
    }
  }

  return mapped
}

const determineImageSettings = settings => {
  const primaryNonZoomSize = resolveImageSize(
    settings.primary_image_size,
    IMAGE_SIZE_SMALL
  )
  const primaryZoomSize = resolveImageSize(
    settings.primary_image_zoom_size,
    IMAGE_SIZE_LARGE,
    settings.zoom_custom_width,
    settings.zoom_custom_height
  )

  const nonZoomSize = resolveImageSize(settings.image_size, IMAGE_SIZE_SMALL)
  const zoomSize = resolveImageSize(
    settings.zoom_size,
    IMAGE_SIZE_LARGE,
    settings.zoom_custom_width,
    settings.zoom_custom_height
  )

  const columnWidth = calculateImageColumnWidth(
    settings.image_size,
    settings.custom_diameter
  )

  return {
    column_width: columnWidth,
    image_shape: settings.image_shape,
    non_zoom_size: nonZoomSize,
    primary_zoom_size: primaryZoomSize,
    primary_non_zoom_size: primaryNonZoomSize,
    zoom_on_hover: settings.zoom_on_hover,
    zoom_size: zoomSize,
  }
}

const IMAGE_SIZES = {
  [IMAGE_SIZE_SMALL]: { height: 45, width: 45 },
  [IMAGE_SIZE_MEDIUM]: { height: 100, width: 100 },
  [IMAGE_SIZE_LARGE]: { height: 200, width: 200 },
}

const resolveImageSize = (
  sizeSetting,
  defaultSize,
  customWidth,
  customHeight
) => {
  if (sizeSetting !== IMAGE_SIZE_CUSTOM) {
    return IMAGE_SIZES[sizeSetting] || IMAGE_SIZES[defaultSize]
  } else {
    return {
      height: customHeight,
      width: customWidth,
    }
  }
}

const calculateImageColumnWidth = (image_size, custom_diameter) => {
  let colWidth
  switch (image_size) {
    case IMAGE_SIZE_SMALL:
      colWidth = 45
      break
    case IMAGE_SIZE_MEDIUM:
      colWidth = 100
      break
    case IMAGE_SIZE_LARGE:
      colWidth = 200
      break
    case IMAGE_SIZE_CUSTOM:
      colWidth = parseInt(custom_diameter)
      break
  }

  // We add the 20 to provide som spacing. Otherwise it looks weird with large pictures.
  return colWidth + 20
}

export const reverseRowsOrder = rows => {
  const rowGroupsMapped = rows.map(row => {
    if (row.type !== 'group') {
      return row
    }

    return {
      ...row,
      configs: reverse([...row.configs]),
    }
  })

  return reverse([...rowGroupsMapped])
}

const sortProductRows = (
  productRows,
  sortSetting,
  variantSortValueByProductId
) => {
  let sortByArg: string | Function = sortSetting
  const customFieldMetaMatch = sortSetting
    ? sortSetting.match(/^product_meta\.(.+)$/)
    : false

  if (sortByArg === 'warehouse_location') {
    sortByArg = product => {
      return variantSortValueByProductId[product.id]
    }
  } else if (sortByArg === 'warehouse_location') {
    sortByArg = product => {
      return variantSortValueByProductId[product.id]
    }
  } else if (sortByArg === 'sku') {
    sortByArg = product => {
      return product.variants && product.variants.length > 0
        ? product.variants[0].sku
        : null
    }
  } else if (sortByArg === 'collection') {
    sortByArg = product => {
      return product.collection ? product.collection.title : ''
    }
  } else if (sortByArg === 'subbrand') {
    sortByArg = product => {
      return product.subbrand ? product.subbrand.name : ''
    }
  } else if (sortByArg === 'newest') {
    return productRows
  } else if (customFieldMetaMatch) {
    return sortBy(productRows, row => {
      return row.meta[customFieldMetaMatch[1]]
    })
  }

  return sortBy(productRows, sortByArg)
}

// When sorting by warehouse location our shipment or order will contain some specific lines
// We only want to sort by the location of the lines actually present on the shipment,
// otherwise we might end up with an incorrect order. We try to calculate an object
// with the "lowest location" per product ID, which we can then pass to the sortProductRows
// function later on. The trick here is to only update the object when any of the locations
// actually change
const useVariantSortValue = (
  lines,
  linesByProductId,
  inputProducts,
  sort,
  useLinesReducerV2
) => {
  const [variantSortValueByProductId, setVariantSortValueByProductId] =
    React.useState({})

  React.useEffect(() => {
    if (
      sort !== 'warehouse_location' &&
      sort !== 'warehouse_location_integer'
    ) {
      return
    }

    let useLinesByProductId = linesByProductId
    if (!useLinesReducerV2) {
      useLinesByProductId = groupBy(lines, 'product_id')
    }

    const variantSortValueByProductId = {}
    for (let product of inputProducts) {
      const linesOfProduct = useLinesByProductId[product.id] || []

      let locationsOfLines = linesOfProduct
        .map(line => {
          const variant = product.variants.find(v => v.id == line.variant_id)

          if (!variant || !variant.location) {
            return null
          }

          return sort === 'warehouse_location_integer'
            ? parseInt(variant.location)
            : variant.location.toLowerCase()
        })
        .filter(location => location)

      locationsOfLines = []

      // there are not lines then just take locations from variants on the product
      if (locationsOfLines.length == 0) {
        locationsOfLines = product.variants
          .map(variant => {
            if (!variant.location) {
              return null
            }

            return sort === 'warehouse_location_integer'
              ? parseInt(variant.location)
              : variant.location.toLowerCase()
          })
          .filter(location => location)
      }

      locationsOfLines.sort()

      variantSortValueByProductId[product.id] = locationsOfLines[0]
    }

    setVariantSortValueByProductId(s => {
      const copy = { ...s }

      let wasUpdated = false
      for (let [productId, sortValue] of Object.entries(
        variantSortValueByProductId
      )) {
        if (sortValue !== copy[productId]) {
          copy[productId] = sortValue
          wasUpdated = true
        }
      }

      return wasUpdated ? copy : s
    })
  }, [
    lines,
    linesByProductId,
    inputProducts,
    sort,
    setVariantSortValueByProductId,
    useLinesReducerV2,
  ])

  return variantSortValueByProductId
}

const determineProductImage = (productImage, settings) => {
  // Use component property if defined
  if (productImage !== undefined) {
    return productImage
  }

  // Use settings overrides if defined
  if (settings.productImage !== undefined) {
    return settings.productImage
  }

  // Fallback to product table settings
  return settings.show_product_image
}

const determineVariantImages = (variantImages, settings) => {
  // Use component property if defined
  if (variantImages !== undefined) {
    return variantImages
  }

  // Use settings overrides if defined
  if (settings.variantImages !== undefined) {
    return settings.variantImages
  }

  // Fallback to product table settings
  return settings.show_variant_images
}
