/* @flow */

import * as React from 'react'
import styled, { css } from 'styled-components'
import {
  Link,
  type Location as LocationType,
  type RouterHistory,
} from 'react-router-dom'
import querystring from 'querystring'
import sortBy from 'lodash/sortBy'

import Address from '../entities/components/Address'
import CookiesBanner from '../../infrastructure/components/CookiesBanner/CookiesBanner'
import { useCustomCss } from '../../infrastructure/hooks'
import { getPrimaryAddress } from '../entities/components/AddressSelector'
import AppContext from '../app-store/components/AppContext'
import Navigation from '../template/components/Navigation'
import { SessionContext, Tooltip } from '../shared'
import type { ConnectedBrand, WebshopSettings } from '../types'
import { resolveSingleFont } from '../../infrastructure/components/CustomFont/helpers'

import { trackOpened, trackWebshopSessionOpened } from './api'

const CreditCard = React.lazy(() => import('./components/CreditCard'))
import WebshopCustomerSelectorModal from './components/WebshopCustomerSelectorModal'
import WebshopHeader from './components/WebshopHeader'
import WebshopSessionListModal from './components/WebshopSessionListModal'
import WebshopSplash from './components/WebshopSplashV2'

import { useWebshopPagesForSession } from './hooks'
import { WebshopContext } from './shared'

type Props = {
  brand: ConnectedBrand,
  children?: React.Node,
  history: RouterHistory,
  location: LocationType,
  setShowRetailerSelector: boolean,
}

const WebshopTemplateV2 = ({
  brand,
  children,
  history,
  location,
  setShowRetailerSelector,
}: Props) => {
  const { entity, user } = React.useContext(SessionContext)
  const {
    availableBrand,
    currency,
    isMobile,
    isNavigationLinksInitialized,
    navigationLinks,
    query,
    shopUrl,
    refreshNavigationLinks,
    refreshWebshopTotals,
    setUseWebshopSessionId,
    updateWebshopSession,
    webshopDesignSettings,
    webshopSession,
    webshopSettings,
  } = React.useContext(WebshopContext)

  const isFrontPage = shopUrl == location.pathname

  // Template
  const [showSessionListModal, setShowSessionListModal] = React.useState(false)
  const [showManualEditCustomerModal, setShowManualEditCustomerModal] =
    React.useState(false)
  const primaryAddress = React.useMemo(
    () => getPrimaryAddress(brand.addresses),
    [brand]
  )

  const [pages, isFetchingPages, { isInitialized: isPagesInitialized }] =
    useWebshopPagesForSession(brand.id)

  const sortedNavigationLinks = React.useMemo(() => {
    return sortBy(navigationLinks, 'sort_order')
  }, [navigationLinks])

  const onUpdateCustomer = React.useCallback(
    (data, formikProps) => {
      return updateWebshopSession(data, formikProps).then(response => {
        if (!response.error) {
          setShowManualEditCustomerModal(false)

          refreshNavigationLinks()
          refreshWebshopTotals()
        }
      })
    },
    [
      refreshNavigationLinks,
      refreshWebshopTotals,
      setShowManualEditCustomerModal,
      updateWebshopSession,
    ]
  )

  useCustomCss(webshopSettings.custom_css)

  const onSubmitSearch = React.useCallback(
    searchQuery => {
      const newQuery = {
        // we keep the current query, so that if a customer has filtered by drop,
        // then when they search they will continue in that drop
        drops: query.drops ? query.drops : undefined,
        drop_deliveries: query.drop_deliveries
          ? query.drop_deliveries
          : undefined,
        q: searchQuery,
      }

      const newUrl = `${shopUrl}/search?${querystring.stringify(newQuery)}`
      history.push(newUrl)
    },
    [history, query, shopUrl]
  )

  const [font, setFont] = React.useState(null)

  React.useEffect(() => {
    if (webshopDesignSettings.default_font) {
      resolveSingleFont(webshopDesignSettings.default_font).then(font =>
        setFont(`'${font.family}', ${font.category}`)
      )

      // unset previously set font
    } else {
      setFont(null)
    }
  }, [webshopDesignSettings.default_font, setFont])

  // TODO: How to do smarter?
  const { customer, drop, subbrand } = webshopSession
  const isLanding = location.pathname === '/shop/10919'
  const landingClass = isLanding ? 'webshop-current-page-landing' : ''

  React.useEffect(() => {
    if (entity && customer && entity.entity_type === 'shop') {
      trackOpened(brand.id)
    }
  }, [customer, entity])

  React.useEffect(() => {
    if (entity && customer && entity.entity_type === 'shop' && webshopSession) {
      trackWebshopSessionOpened(brand.id, webshopSession.id)
    }
  }, [entity, customer, webshopSession])

  const availableConnections = availableBrand
    ? availableBrand.retailer_connections.filter(
        connection => connection.b2b_access
      )
    : []
  const allowSelectingCustomer = entity.entity_type === 'brand'

  const showCustomerSelect = React.useMemo(() => {
    return allowSelectingCustomer && !customer
  }, [allowSelectingCustomer, customer])

  const showSplash = React.useMemo(() => {
    if (!webshopSettings.splash_show_v2) {
      return false
    }

    if (!customer) {
      return false
    }

    if (!webshopSettings.splash_show) {
      return false
    }

    if (
      webshopSettings.require_drop &&
      !drop &&
      !webshopSession.drop_filter_code
    ) {
      return true
    }

    if (webshopSettings.require_subbrand && !subbrand) {
      return true
    }

    if (isFrontPage) {
      return true
    }

    return false
  }, [
    brand,
    customer,
    drop,
    isFrontPage,
    subbrand,
    webshopSession.drop_filter_code,
    webshopSettings,
  ])

  if (!isNavigationLinksInitialized || !isPagesInitialized) {
    return null
  }

  return (
    <div>
      {/* Using show prop for displaying so it can be rendered in advance and slide up on mobile */}
      <WebshopSessionListModal
        allowSelectingCustomer={allowSelectingCustomer}
        availableConnections={availableConnections}
        brand={brand}
        currentSession={webshopSession}
        entity={entity}
        onHide={() => setShowSessionListModal(false)}
        setUseWebshopSessionId={setUseWebshopSessionId}
        show={showSessionListModal}
        webshopSettings={webshopSettings}
      />

      {(showCustomerSelect || showManualEditCustomerModal) && (
        <WebshopCustomerSelectorModal
          currentCustomerId={webshopSession.customer_id}
          onHide={
            showManualEditCustomerModal
              ? () => setShowManualEditCustomerModal(false)
              : undefined
          }
          onSave={onUpdateCustomer}
          show={true}
          title={showCustomerSelect ? 'Select customer' : 'Change customer'}
        />
      )}

      {customer && (
        <React.Suspense fallback={<div>Loading...</div>}>
          <Navigation
            fullWidth={webshopSettings.full_width}
            inWebshop
            webshopV2
          />
          <Container
            className={`webshop-main-container ${landingClass}`}
            font={font}
            fullWidth={webshopSettings.full_width}
          >
            {showSplash && (
              <WebshopSplash
                brand={brand}
                history={history}
                location={location}
                setUseWebshopSessionId={setUseWebshopSessionId}
                setShowManualEditCustomerModal={setShowManualEditCustomerModal}
                setShowRetailerSelector={setShowRetailerSelector}
                setShowSessionListModal={setShowSessionListModal}
              />
            )}

            {!showSplash && (
              <>
                <TopContainer>
                  <WebshopHeader
                    history={history}
                    brand={brand}
                    onSubmitSearch={onSubmitSearch}
                    setShowManualEditCustomerModal={
                      setShowManualEditCustomerModal
                    }
                    setShowRetailerSelector={setShowRetailerSelector}
                    setShowSessionListModal={setShowSessionListModal}
                  />
                </TopContainer>
                <ContentContainer fullWidth={webshopSettings.full_width}>
                  {children}
                </ContentContainer>
                <FooterContainer>
                  <div>
                    <Address
                      center={true}
                      address={primaryAddress}
                      entity={brand}
                    />
                  </div>
                  {pages.length ? (
                    <div className="text-center">
                      {pages.map((page, index) => {
                        return (
                          <Link key={index} to={`${shopUrl}/page/${page.id}`}>
                            {page.heading}&nbsp;&nbsp;&nbsp;
                          </Link>
                        )
                      })}
                    </div>
                  ) : null}

                  <AppContext
                    app="Epay"
                    overrideEntity={brand}
                    render={({ app }) => {
                      if (app.settings.credit_card_icons.length === 0) {
                        return null
                      }

                      return (
                        <CreditCardContainer>
                          <CreditCard cards={app.settings.credit_card_icons} />
                        </CreditCardContainer>
                      )
                    }}
                  />
                </FooterContainer>
              </>
            )}
          </Container>

          <CookiesBanner brand={brand} />
        </React.Suspense>
      )}
    </div>
  )
}

export default WebshopTemplateV2

// also used by WebshopProductDetail for the fixed panel
export const fixedWidthContainer = css`
  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }

  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
`

// also used by WebshopProductDetail for the fixed panel
export const fullWidthContainer = css``

const Container = styled.div`
  ${props => (props.font ? `font-family: ${props.font}` : '')};
  ${props =>
    props.fullWidth === true ? fullWidthContainer : fixedWidthContainer};
`

const TopContainer = styled.div.attrs({ className: 'webshop-top-container' })``

const ContentContainer = styled.div.attrs({ className: 'webshop-content' })`
  min-height: 500px;
  padding-top: 25px;
  position: relative;
  ${({ fullWidth }) =>
    fullWidth === true &&
    `
    padding-left: 15px;
    padding-right: 15px;
  `}

  img {
    max-width: 100%;
  }
`

const FooterContainer = styled.div.attrs({ className: 'webshop-footer' })`
  padding: 25px 0;
`

const CreditCardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`
