/* @flow */

import * as React from 'react'

import { SessionContext } from '../shared'
import { updateUser } from '../entities/api'

export const useReportSettings = (reportId, defaultSettings) => {
  const { user } = React.useContext(SessionContext)

  const [settings, setSettings] = React.useState(defaultSettings)

  React.useEffect(() => {
    const existingSettings = user.report_settings[reportId]

    if (existingSettings) {
      setSettings(existingSettings)
    }
  }, [user, reportId, setSettings])

  const onSaveSettings = React.useCallback(
    values => {
      const existingSettings = user.report_settings[reportId] || {}

      const updatedSettings = {
        ...existingSettings,
        ...values,
      }

      setSettings(updatedSettings)

      return updateUser(user.id, {
        report_settings: {
          ...user.report_settings,
          [reportId]: updatedSettings,
        },
      })
    },
    [setSettings, user]
  )

  return [settings, onSaveSettings]
}
