/* @flow */

import * as React from 'react'
import styled from 'styled-components'

import CustomerFacingDeliveryDate from './Components/CustomerFacingDeliveryDate'
import ProductTableRowsDeliveryList from './Components/ProductTableRowsDeliveryList'

import { Tooltip } from '../../../../../shared'
import type { ColumnConfig } from '../../types'

type Options = {
  isCustomerFacingDeliveryStatus?: boolean,
}

const delivery_status: (options: Options) => ColumnConfig = ({
  defaultRender,
  label = 'Delivery status',
  lastDeliveryOnly = false,
  isCustomerFacingDeliveryStatus = false,
}) => ({
  data_source: 'delivery_status',
  key: 'delivery',
  label,
  render: ({
    brand,
    context,
    data,
    onClearDataCache,
    lines,
    variant,
    value,
    userEntity,
  }) => {
    return (
      <DeliveryStatus
        brand={brand}
        context={context}
        data={data}
        defaultRender={defaultRender}
        isCustomerFacingDeliveryStatus={isCustomerFacingDeliveryStatus}
        lastDeliveryOnly={lastDeliveryOnly}
        lines={lines}
        userEntity={userEntity}
        value={value}
        variant={variant}
      />
    )
  },
  style: ({ matrix }) => {
    const style = {}

    if (matrix) {
      style.verticalAlign = 'top'
    }
    if (!isCustomerFacingDeliveryStatus) {
      style.width = '150px'
    }

    return style
  },
})

export default delivery_status

const DeliveryStatus = ({
  brand,
  defaultRender,
  lastDeliveryOnly,
  isCustomerFacingDeliveryStatus,
  value,
  userEntity,
}) => {
  const status = value ? value[0] : null

  if (!status) {
    if (defaultRender) {
      return defaultRender()
    }

    return null
  }

  if (status.deliveries.length === 0 && !status.lead_time) {
    return null
  }

  if (
    isCustomerFacingDeliveryStatus &&
    (status.confirmed_delivery || status.estimated_delivery)
  ) {
    return (
      <CustomerFacingDeliveryDate
        brand={brand}
        deliveryDate={status.confirmed_delivery || status.estimated_delivery}
        isEstimated={status.confirmed_delivery == null}
      />
    )
  }

  const allocatedFromIncoming =
    status.allocated_from_production_orders +
    status.allocated_from_production_orders_delivery_notes

  return (
    <DeliveryStatusContainer>
      <ProductTableRowsDeliveryList
        brand={brand}
        lastDeliveryOnly={lastDeliveryOnly}
        isCustomerFacingDeliveryStatus={isCustomerFacingDeliveryStatus}
        status={status}
        userEntity={userEntity}
      />
      {!isCustomerFacingDeliveryStatus && (
        <DeliveryStatusIndicatorsContainer>
          {status.allocated_from_inventory > 0 && (
            <StatusIndicator
              color="#329a34"
              tip={
                `${status.allocated_from_inventory} allocated from inventory\n` +
                (status.inventory_reservations || [])
                  .map(
                    reservation =>
                      `${reservation.quantity} x ${reservation.variant_name}${
                        reservation.batch ? ' (' + reservation.batch + ')' : ''
                      }`
                  )
                  .join('\n')
              }
            />
          )}
          {allocatedFromIncoming > 0 && (
            <StatusIndicator
              color="#e6a725"
              tip={`${allocatedFromIncoming} allocated from incoming POs`}
            />
          )}
          {status.left_to_allocate > 0 && (
            <StatusIndicator
              color="#db4141"
              tip={`${status.left_to_allocate} left to allocate to inventory or POs`}
            />
          )}
        </DeliveryStatusIndicatorsContainer>
      )}
    </DeliveryStatusContainer>
  )
}

const DeliveryStatusContainer = styled.div`
  position: relative;
`

const DeliveryStatusIndicatorsContainer = styled.div`
  position: absolute;
  right: 1px;
  top: 1px;
`

const StatusIndicator = ({ color, tip }) => {
  return (
    <Tooltip id="stock-allocations" tip={tip}>
      <StatusIndicatorHtml color={color} />
    </Tooltip>
  )
}

const StatusIndicatorHtml = styled.div`
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: inline-block;
  height: 5px;
  margin-right: 3px;
  width: 5px;
`
