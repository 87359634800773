/* @flow */

import React from 'react'

import { getCustomFields } from './api'
import { createApiHook, createDataCache, quickCreateHook } from '../hooks'

import type {
  BrunoRequestOptions,
  CustomField,
  CustomFieldGroupType,
} from '../types'

const {
  hook: useCustomFields,
  hookCached: useCachedCustomFields,
  clearCache: clearCustomFieldsCache,
} = quickCreateHook(getCustomFields, 'custom_fields', [])

export { useCustomFields, useCachedCustomFields, clearCustomFieldsCache }

export const useFilteredCustomFields = (
  customFields: Array<CustomField>,
  groupType: CustomFieldGroupType,
  isBrand: boolean
) => {
  const orderCustomFields = customFields.filter(
    f => f.visible && f.group_type === groupType
  )

  const predicateByEntityType = !isBrand
    ? (f: CustomField) =>
        f.visibility === 'brand_retailer' || f.visibility === 'retailer'
    : (f: CustomField) =>
        f.visibility === 'brand_retailer' || f.visibility === 'brand'

  return orderCustomFields.filter(predicateByEntityType)
}
