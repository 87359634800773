/* @flow */

import React from 'react'
import styled from 'styled-components'
import sortBy from 'lodash/sortBy'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

import {
  navigate,
  Badge,
  Headline,
  Header,
  HeaderContainer,
  Card,
  HeaderButton,
  ListItem,
  Grid,
  GridItem,
  HeadlineThree,
  HeadlineFour,
  List,
} from './WarehouseTemplate'

import FixedElement from '../components/FixedElement'
import { SessionContext } from '../../shared'
import { WarehouseContext } from '../shared'

const WarehouseSubUserList = ({ history, dispatch }) => {
  const { user } = React.useContext(SessionContext)
  const {
    subUser: mySubUser,
    setSubUser,
    subUsers,
  } = React.useContext(WarehouseContext)

  const sorted = React.useMemo(() => {
    return sortBy(subUsers, 'name')
  }, [subUsers])

  return (
    <Container>
      <Card>
        {sorted.map(subUser => {
          const isCurrentSubUser = mySubUser
            ? mySubUser.id == subUser.id
            : false

          return (
            <ListItem
              className="lg"
              key={subUser.id}
              onClick={() => {
                if (!isCurrentSubUser) {
                  setSubUser(subUser)
                }
              }}
            >
              <Grid className="align-items-center">
                <GridItem className="grow-1">
                  <HeadlineThree>{subUser.name}</HeadlineThree>
                </GridItem>
                <GridItem>
                  {isCurrentSubUser && (
                    <span style={{ fontWeight: 'bold' }}>Current user</span>
                  )}
                  {!isCurrentSubUser && subUser.current_device_id && (
                    <Badge intent="warning">In use</Badge>
                  )}
                  {!isCurrentSubUser && !subUser.current_device_id && (
                    <Badge intent="success">Available</Badge>
                  )}
                </GridItem>
              </Grid>
            </ListItem>
          )
        })}
      </Card>
    </Container>
  )
}

export default WarehouseSubUserList

const Container = styled.div`
  position: relative;
`
