import { createDocumentPdfLink, createPrimaryDocumentPdfLink } from './api'

export const openPdf = documentId => {
  const screen = window.screen
  const width = 0.8 * screen.height * 0.75
  const height = 0.8 * screen.height

  return createDocumentPdfLink(documentId).then(response => {
    if (!response.error) {
      window.open(
        response.payload.link,
        'test',
        `width=${width},height=${height},toolbar=0,menubar=0,location=0`
      )
    }
  })
}

export const openPrimaryPdf = (dataType, id) => {
  const screen = window.screen
  const width = 0.8 * screen.height * 0.75
  const height = 0.8 * screen.height

  return createPrimaryDocumentPdfLink(dataType, id).then(response => {
    if (!response.error) {
      window.open(
        response.payload.link,
        'test',
        `width=${width},height=${height},toolbar=0,menubar=0,location=0`
      )
    }
  })
}
