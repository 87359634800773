/* @flow */

import api from '../api'

import type {
  ApiResponse,
  DataTableAttribute,
  DataTableRequestOptions,
  Id,
  Shipment,
  ShipmentElastic,
} from '../types'

export const getNews = (
  options?: Object
): ApiResponse<{ shipment: Shipment }> =>
  api.get(`/news`, {
    query: options,
  })

export const getAllNewsNotifications = (options?: Object) =>
  api.get('/news/notifications/all', {
    query: options,
  })

export const getUnreadNewsNotifications = (options?: Object) =>
  api.get('/news/notifications/unread', {
    query: options,
  })

export const remindMeLater = (notificationId: Id, options?: Object) =>
  api.post(`/news/notifications/${notificationId}/remind`)

export const markAsRead = (notificationId: Id, options?: Object) =>
  api.post(`/news/notifications/${notificationId}/mark-read`)

export const getNewsNotification = (newsNotificationId: Id, options?: Object) =>
  api.get(`/news/notifications/${newsNotificationId}`, {
    query: options,
  })

export const createNews = data =>
  api.post('/news', {
    body: {
      news: data,
    },
  })

export const updateNews = (id: Id, data: $Shape<News>) =>
  api.put(`/news/${id}`, {
    body: {
      news: data,
    },
  })
