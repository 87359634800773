/* @flow */

import * as React from 'react'
import styled, { css } from 'styled-components'

export const createWebshopProductImageTransformations =
  webshopDesignSettings => {
    const transformations = {}

    if (webshopDesignSettings.product_image_background_color) {
      transformations[
        'background'
      ] = `rgb:${webshopDesignSettings.product_image_background_color.replace(
        '#',
        ''
      )}`
    }

    if (
      webshopDesignSettings.product_image_filter &&
      webshopDesignSettings.product_image_filter.filters.trim().length > 0
    ) {
      const filters = webshopDesignSettings.product_image_filter.filters
        .trim()
        .split(',')

      for (let filter of filters) {
        // changed delimiter from _ to # since _ is some times part of the value
        const [prop, value] = filter.split('#')

        transformations[prop] = value
      }
    }

    return transformations
  }

export const createWebshopButtonStyle = (color, webshopDesignSettings) => {
  let useStyle = { ...(DEFAULT_WEBSHOP_BUTTON_STYLES[color] || {}) }

  let settingsInput = {}
  if (color === 'primary') {
    settingsInput = webshopDesignSettings.primary_button_style || {}
  } else if (color === 'secondary') {
    settingsInput = webshopDesignSettings.secondary_button_style || {}
  }

  for (let [color, colorStyles] of Object.entries(settingsInput)) {
    for (let [cssProperty, cssValue] of Object.entries(colorStyles)) {
      useStyle[color][cssProperty] = cssValue
    }
  }

  return useStyle
}

export const DEFAULT_WEBSHOP_BUTTON_STYLES = {
  primary: {
    defaultState: {
      backgroundColor: '#3a3a3a',
      color: '#ffffff',
      border: null,
    },
    hover: {
      backgroundColor: '#4a4a4a',
      color: '#ffffff',
      border: null,
    },
  },
  white: {
    defaultState: {
      backgroundColor: '#fff',
      color: '#5e5e5e',
      border: '1px solid #e5e9ec',
    },
    hover: {
      backgroundColor: '#fff',
      color: '#5e5e5e',
      border: '1px solid #b4b9be',
    },
  },
  red: {
    defaultState: {
      backgroundColor: '#f35958',
      color: '#ffffff',
      border: null,
    },
    hover: {
      backgroundColor: '#e94847',
      color: '#ffffff',
      border: null,
    },
  },
  transparent: {
    defaultState: {
      backgroundColor: 'transparent',
    },
    hover: {
      backgroundColor: 'transparent',
    },
  },
}

export const createStyledWebshopButton = InputComponent => {
  const Button = styled(InputComponent)`
    background-color: #d1dade;
    background-image: none !important;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    color: #5e5e5e;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    text-align: center;
    text-shadow: none;
    text-wrap: nowrap;
    transition: all 0.12s linear 0s !important;
    vertical-align: middle;
    white-space-collapse: collapse;

    ${({ size }) => {
      if (!size) {
        return css`
          font-size: 12px;
          line-height: 18px;
          padding: 5px 10px;
        `
      } else if (size === 'xsmall') {
        return css`
          font-size: 12px;
          line-height: 16px;
          padding: 1px 5px;
        `
      } else if (size === 'small') {
        return css`
          font-size: 11px;
          line-height: 16px;
          padding: 3px 8px;
        `
      } else if (size === 'large') {
        return css`
          font-size: 14px;
          line-height: 20px;
          padding: 9px 12px;
        `
      } else if (size === 'xlarge') {
        return css`
          font-size: 16px;
          line-height: 22px;
          padding: 11px 19px;
        `
      }
    }};

    ${({ theme }) => {
      return css`
        background-color: ${theme.defaultState.backgroundColor};
        ${theme.defaultState.border
          ? `border: ${theme.defaultState.border};`
          : ''};
        ${theme.defaultState.color
          ? `color: ${theme.defaultState.color};`
          : ''};
        outline: 0;

        &:hover,
        &:focus,
        &:active {
          background-color: ${theme.hover.backgroundColor};
          ${theme.hover.border ? `border: ${theme.hover.border};` : ''};
          ${theme.hover.color ? `color: ${theme.hover.color};` : ''};
          outline: 0;
        }

        &:active {
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        }
      `
    }};

    ${({ block = false }) => {
      return block
        ? css`
            display: block;
            width: 100%;
          `
        : ''
    }}
  `

  return Button
}

export const HeaderBox = styled.div`
  display: flex;
  height: 110px;
  overflow-x: hidden;
`

export const HeaderBoxBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    font-size: 85%;
  }
`

export const HeaderBoxIcon = styled.div`
  display: inline-block;
  font-size: 20px;
  margin-right: 10px;
`

export const HeaderBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`

export const HeaderBoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ right }) => (right ? 'flex-end' : 'flex-start')};
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 11px;
`

export const HeaderBoxAction = styled.div`
  margin-top: 10px;
`
