import { CALL_API } from '../../../infrastructure/middleware/api'

export const ARCHIVE_VARIANT_REQUEST = 'ARCHIVE_VARIANT_REQUEST'
export const ARCHIVE_VARIANT_SUCCESS = 'ARCHIVE_VARIANT_SUCCESS'
export const ARCHIVE_VARIANT_FAILURE = 'ARCHIVE_VARIANT_FAILURE'
export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE'
export const CREATE_VARIANT_REQUEST = 'CREATE_VARIANT_REQUEST'
export const CREATE_VARIANT_SUCCESS = 'CREATE_VARIANT_SUCCESS'
export const CREATE_VARIANT_FAILURE = 'CREATE_VARIANT_FAILURE'
export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE'
export const DELETE_VARIANT_REQUEST = 'DELETE_VARIANT_REQUEST'
export const DELETE_VARIANT_SUCCESS = 'DELETE_VARIANT_SUCCESS'
export const DELETE_VARIANT_FAILURE = 'DELETE_VARIANT_FAILURE'
export const FETCH_PRODUCTS_ATTRIBUTES_REQUEST =
  'FETCH_PRODUCTS_ATTRIBUTES_REQUEST'
export const FETCH_PRODUCTS_ATTRIBUTES_SUCCESS =
  'FETCH_PRODUCTS_ATTRIBUTES_SUCCESS'
export const FETCH_PRODUCTS_ATTRIBUTES_FAILURE =
  'FETCH_PRODUCTS_ATTRIBUTES_FAILURE'
export const SEARCH_PRODUCTS_REQUEST = 'SEARCH_PRODUCTS_REQUEST'
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS'
export const SEARCH_PRODUCTS_FAILURE = 'SEARCH_PRODUCTS_FAILURE'
export const GET_ATTRIBUTES_REQUEST = 'GET_ATTRIBUTES_REQUEST'
export const GET_ATTRIBUTES_SUCCESS = 'GET_ATTRIBUTES_SUCCESS'
export const GET_ATTRIBUTES_FAILURE = 'GET_ATTRIBUTES_FAILURE'
export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST'
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS'
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE'
export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE'
export const PRODUCT_TABLE_PARAMETERS_REQUEST =
  'PRODUCT_TABLE_PARAMETERS_REQUEST'
export const PRODUCT_TABLE_PARAMETERS_SUCCESS =
  'PRODUCT_TABLE_PARAMETERS_SUCCESS'
export const PRODUCT_TABLE_PARAMETERS_FAILURE =
  'PRODUCT_TABLE_PARAMETERS_FAILURE'
export const SET_PRODUCT_STATUS_REQUEST = 'SET_PRODUCT_STATUS_REQUEST'
export const SET_PRODUCT_STATUS_SUCCESS = 'SET_PRODUCT_STATUS_SUCCESS'
export const SET_PRODUCT_STATUS_FAILURE = 'SET_PRODUCT_STATUS_FAILURE'
export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE'
export const UPDATE_VARIANT_REQUEST = 'UPDATE_VARIANT_REQUEST'
export const UPDATE_VARIANT_SUCCESS = 'UPDATE_VARIANT_SUCCESS'
export const UPDATE_VARIANT_FAILURE = 'UPDATE_VARIANT_FAILURE'

export const EXPORT_PRODUCTS_REQUEST = 'EXPORT_PRODUCTS_REQUEST'
export const EXPORT_PRODUCTS_SUCCESS = 'EXPORT_PRODUCTS_SUCCESS'
export const EXPORT_PRODUCTS_FAILURE = 'EXPORT_PRODUCTS_FAILURE'

// Mixpanel
export const OPEN_NEW_PRODUCT = 'OPEN_NEW_PRODUCT'
export const CREATED_PRODUCT = 'CREATED_PRODUCT'
export const UPDATED_PRODUCT = 'UPDATED_PRODUCT'
export const DELETED_PRODUCT = 'DELETED_PRODUCT'
export const DELETED_VARIANT = 'DELETED_VARIANT'

/**
 * Did not dare to refactor fetchProducts() so I just created a new action we can
 * gradually migrate to. We do not wish to use the original action descriptors as
 * this will cause the result to be saved in redux store.
 */
export const getProducts = options => ({
  [CALL_API]: {
    endpoint: '/products-elastic',
    method: 'POST',
    query: options,
    types: [
      'GET_PRODUCTS2_REQUEST',
      'GET_PRODUCTS2_SUCCESS',
      'GET_PRODUCTS2_FAILURE',
    ],
  },
})

/**
 * Async action creator that will request products from the API, including
 * their related resources
 * @return {function}
 */
export const fetchProducts = (brandId = null) => {
  const query = {
    'out-of-stock': true,
  }

  if (brandId) {
    query['brand_id'] = brandId
  }

  return {
    [CALL_API]: {
      endpoint: '/products-elastic',
      method: 'POST',
      query: query,
      types: [GET_PRODUCTS_REQUEST, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAILURE],
    },
  }
}

export const fetchProductsAttributes = (brandId, filters = {}) => ({
  [CALL_API]: {
    endpoint: '/products-elastic/attributes',
    method: 'GET',
    query: {
      brand_id: brandId,
      filters,
    },
    types: [
      FETCH_PRODUCTS_ATTRIBUTES_REQUEST,
      FETCH_PRODUCTS_ATTRIBUTES_SUCCESS,
      FETCH_PRODUCTS_ATTRIBUTES_FAILURE,
    ],
  },
})

export const searchProducts = (brandId, options) => {
  return {
    [CALL_API]: {
      endpoint: `/webshop/${brandId}/search`,
      method: 'POST',
      body: Object.assign({}, options, {
        brand_id: brandId,
      }),
      types: [
        SEARCH_PRODUCTS_REQUEST,
        SEARCH_PRODUCTS_SUCCESS,
        SEARCH_PRODUCTS_FAILURE,
      ],
    },
  }
}

/**
 * Action creator that will request products to be fetched if they have not already
 * been so
 * @return {function}
 */
export const fetchProductsIfNeeded = (brandId = null) => {
  return (dispatch, getState) => {
    const { products } = getState()
    if (shouldFetchProducts(products)) {
      return dispatch(fetchProducts(brandId))
    }

    return null
  }
}

/**
 * Helper function that determines if products have been, or are in the middle of being,
 * fetched from the API
 * @param  {object} products
 * @return {boolean}
 */
const shouldFetchProducts = products => {
  return Object.keys(products.items).length === 0 && !products.isFetching
}

export const GET_PRODUCTS_LIST_REQUEST = 'GET_PRODUCTS_LIST_REQUEST'
export const GET_PRODUCTS_LIST_SUCCESS = 'GET_PRODUCTS_LIST_SUCCESS'
export const GET_PRODUCTS_LIST_FAILURE = 'GET_PRODUCTS_LIST_FAILURE'

export const getProductsList = options => ({
  [CALL_API]: {
    endpoint: '/products',
    method: 'GET',
    query: options,
    types: [
      GET_PRODUCTS_LIST_REQUEST,
      GET_PRODUCTS_LIST_SUCCESS,
      GET_PRODUCTS_LIST_FAILURE,
    ],
  },
})

export const requestCreateProduct = data => {
  return {
    [CALL_API]: {
      endpoint: '/products',
      method: 'POST',
      body: {
        product: data,
      },
      types: [
        CREATE_PRODUCT_REQUEST,
        CREATE_PRODUCT_SUCCESS,
        CREATE_PRODUCT_FAILURE,
      ],
    },
  }
}

export const updateProduct = (id, data) => ({
  [CALL_API]: {
    endpoint: `/products/${id}`,
    method: 'PUT',
    body: {
      product: data,
    },
    types: [
      UPDATE_PRODUCT_REQUEST,
      UPDATE_PRODUCT_SUCCESS,
      UPDATE_PRODUCT_FAILURE,
    ],
  },
})

export const setActiveStatus = (productId, status) => {
  const fragment = status ? 'activate' : 'deactivate'
  return {
    [CALL_API]: {
      endpoint: `/products/${productId}/${fragment}`,
      method: 'POST',
      types: [
        SET_PRODUCT_STATUS_REQUEST,
        SET_PRODUCT_STATUS_SUCCESS,
        SET_PRODUCT_STATUS_FAILURE,
      ],
    },
  }
}

export const requestProduct = (productId, includes = []) => {
  return {
    [CALL_API]: {
      endpoint: `/products/${productId}`,
      method: 'GET',
      query: {
        includes: [
          'tags',
          'variants',
          'categories',
          'productGroups',
          'variants.stock',
          ...includes,
        ],
      },
      types: [GET_PRODUCT_REQUEST, GET_PRODUCT_SUCCESS, GET_PRODUCT_FAILURE],
    },
  }
}

export const requestProductTableParameters = dataKey => {
  return {
    [CALL_API]: {
      endpoint: '/products/table-parameters',
      method: 'GET',
      types: [
        PRODUCT_TABLE_PARAMETERS_REQUEST,
        PRODUCT_TABLE_PARAMETERS_SUCCESS,
        PRODUCT_TABLE_PARAMETERS_FAILURE,
      ],
    },
  }
}

export const requestAttributes = (
  includes = [],
  sort = [{ key: 'name', direction: 'asc' }]
) => ({
  [CALL_API]: {
    endpoint: '/attributes',
    method: 'GET',
    query: {
      includes,
      sort,
    },
    types: [
      GET_ATTRIBUTES_REQUEST,
      GET_ATTRIBUTES_SUCCESS,
      GET_ATTRIBUTES_FAILURE,
    ],
  },
})

export const GENERATE_EAN_ONE_PRODUCT_REQUEST =
  'GENERATE_EAN_ONE_PRODUCT_REQUEST'
export const GENERATE_EAN_ONE_PRODUCT_SUCCESS =
  'GENERATE_EAN_ONE_PRODUCT_SUCCESS'
export const GENERATE_EAN_ONE_PRODUCT_FAILURE =
  'GENERATE_EAN_ONE_PRODUCT_FAILURE'

export const requestGenerateEANForOneProduct = (
  productId,
  onlyEmptyVariants
) => ({
  [CALL_API]: {
    endpoint: `/products/${productId}/variants/generate-ean`,
    method: 'POST',
    types: [
      GENERATE_EAN_ONE_PRODUCT_REQUEST,
      GENERATE_EAN_ONE_PRODUCT_SUCCESS,
      GENERATE_EAN_ONE_PRODUCT_FAILURE,
    ],
    body: {
      only_empty_variants: onlyEmptyVariants,
    },
  },
})

export const GENERATE_EAN_ONE_COLLECTION_REQUEST =
  'GENERATE_EAN_ONE_COLLECTION_REQUEST'
export const GENERATE_EAN_ONE_COLLECTION_SUCCESS =
  'GENERATE_EAN_ONE_COLLECTION_SUCCESS'
export const GENERATE_EAN_ONE_COLLECTION_FAILURE =
  'GENERATE_EAN_ONE_COLLECTION_FAILURE'

export const requestGenerateEANForOneCollection = (
  collectionId,
  onlyEmptyVariants
) => ({
  [CALL_API]: {
    endpoint: `/products/variants/generate-ean/collections/${collectionId}`,
    method: 'POST',
    types: [
      GENERATE_EAN_ONE_COLLECTION_REQUEST,
      GENERATE_EAN_ONE_COLLECTION_SUCCESS,
      GENERATE_EAN_ONE_COLLECTION_FAILURE,
    ],
    body: {
      only_empty_variants: onlyEmptyVariants,
    },
  },
})

export const GENERATE_EAN_ALL_PRODUCTS_REQUEST =
  'GENERATE_EAN_ALL_PRODUCTS_REQUEST'
export const GENERATE_EAN_ALL_PRODUCTS_SUCCESS =
  'GENERATE_EAN_ALL_PRODUCTS_SUCCESS'
export const GENERATE_EAN_ALL_PRODUCTS_FAILURE =
  'GENERATE_EAN_ALL_PRODUCTS_FAILURE'

export const requestGenerateEANForAllProducts = onlyEmptyVariants => ({
  [CALL_API]: {
    endpoint: '/products/variants/generate-ean',
    method: 'POST',
    types: [
      GENERATE_EAN_ALL_PRODUCTS_REQUEST,
      GENERATE_EAN_ALL_PRODUCTS_SUCCESS,
      GENERATE_EAN_ALL_PRODUCTS_FAILURE,
    ],
    body: {
      only_empty_variants: onlyEmptyVariants,
    },
  },
})

export const getAttributes = brandId => ({
  [CALL_API]: {
    endpoint: `/brands/${brandId}/attributes`,
    method: 'GET',
    query: {
      includes: ['attributeValues'],
    },
    types: [
      GET_ATTRIBUTES_REQUEST,
      GET_ATTRIBUTES_SUCCESS,
      GET_ATTRIBUTES_FAILURE,
    ],
  },
})

export const deleteProduct = productId => ({
  [CALL_API]: {
    endpoint: `/products/${productId}`,
    method: 'DELETE',
    types: [
      DELETE_PRODUCT_REQUEST,
      DELETE_PRODUCT_SUCCESS,
      DELETE_PRODUCT_FAILURE,
    ],
  },
})

export const deleteVariant = (productId, variantId) => ({
  [CALL_API]: {
    endpoint: `/products/${productId}/variants/${variantId}`,
    method: 'DELETE',
    types: [
      DELETE_VARIANT_REQUEST,
      DELETE_VARIANT_SUCCESS,
      DELETE_VARIANT_FAILURE,
    ],
  },
})

export const openNewProduct = () => ({
  type: OPEN_NEW_PRODUCT,
})

export const createdProduct = () => ({
  type: CREATED_PRODUCT,
})

export const updatedProduct = () => ({
  type: UPDATED_PRODUCT,
})

export const deletedProduct = () => ({
  type: DELETED_PRODUCT,
})

export const deletedVariant = () => ({
  type: OPEN_NEW_PRODUCT,
})

const MERGE_ATTRIBUTES_REQUEST = 'MERGE_ATTRIBUTES_REQUEST'
const MERGE_ATTRIBUTES_SUCCESS = 'MERGE_ATTRIBUTES_SUCCESS'
const MERGE_ATTRIBUTES_FAILURE = 'MERGE_ATTRIBUTES_FAILURE'

export const mergeAttributes = (subject, target, targets) => ({
  [CALL_API]: {
    endpoint: '/attributes/merge',
    method: 'POST',
    body: {
      subject,
      target,
      targets,
    },
    types: [
      MERGE_ATTRIBUTES_REQUEST,
      MERGE_ATTRIBUTES_SUCCESS,
      MERGE_ATTRIBUTES_FAILURE,
    ],
  },
})

export const archiveVariant = (productId, variantId, archive) => ({
  [CALL_API]: {
    endpoint: `/products/${productId}/variants/${variantId}/${
      archive ? 'archive' : 'restore'
    }`,
    method: 'POST',
    types: [
      ARCHIVE_VARIANT_REQUEST,
      ARCHIVE_VARIANT_SUCCESS,
      ARCHIVE_VARIANT_FAILURE,
    ],
  },
})

export const createVariant = (productId, data) => ({
  [CALL_API]: {
    endpoint: `/products/${productId}/variants`,
    method: 'POST',
    body: {
      variant: data,
    },
    types: [
      CREATE_VARIANT_REQUEST,
      CREATE_VARIANT_SUCCESS,
      CREATE_VARIANT_FAILURE,
    ],
  },
})

export const updateVariant = (productId, variantId, data) => ({
  [CALL_API]: {
    endpoint: `/products/${productId}/variants/${variantId}`,
    method: 'PUT',
    body: {
      variant: data,
    },
    types: [
      UPDATE_VARIANT_REQUEST,
      UPDATE_VARIANT_SUCCESS,
      UPDATE_VARIANT_FAILURE,
    ],
  },
})

export const GET_PRODUCT_GROUPS_REQUEST = 'GET_PRODUCT_GROUPS_REQUEST'
export const GET_PRODUCT_GROUPS_SUCCESS = 'GET_PRODUCT_GROUPS_SUCCESS'
export const GET_PRODUCT_GROUPS_FAILURE = 'GET_PRODUCT_GROUPS_FAILURE'
export const getProductGroups = () => ({
  [CALL_API]: {
    endpoint: '/product-groups',
    method: 'GET',
    types: [
      GET_PRODUCT_GROUPS_REQUEST,
      GET_PRODUCT_GROUPS_SUCCESS,
      GET_PRODUCT_GROUPS_FAILURE,
    ],
  },
})
