/* @flow */

import React from 'react'
import styled from 'styled-components'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { HelpIndicator } from '../shared'

import type {
  ProductionOrderStatus,
  ProductionOrderDeliveryNoteStatus,
} from '../types'

export const productionOrderStatusOptions: Array<{
  label: string,
  value: ProductionOrderStatus,
}> = [
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Open', value: 'open' },
  { label: 'In production', value: 'in_production' },
  { label: 'Partially sent', value: 'partially_sent' },
  { label: 'Sent', value: 'sent' },
  { label: 'Partially received', value: 'partially_received' },
  { label: 'Received', value: 'received' },
  { label: 'Partially booked', value: 'partially_booked' },
  { label: 'Booked', value: 'booked' },
  { label: 'Closed', value: 'closed' },
]

export const productionOrderDeliveryNoteStatusOptions: Array<{
  label: string,
  value: ProductionOrderDeliveryNoteStatus,
}> = [
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Sent', value: 'sent' },
  { label: 'Open', value: 'open' },
  { label: 'Received', value: 'received' },
  { label: 'Booked', value: 'booked' },
]

export const productionPrepaymentStatusOptions = [
  { label: 'Open', value: 'open' },
  { label: 'Booked', value: 'booked' },
  { label: 'Allocated', value: 'allocated' },
  { label: 'Credited', value: 'credited' },
]

export const overheadCostInvoiceStatusOptions = [
  { label: 'Open', value: 'open' },
  { label: 'Booked', value: 'booked' },
  { label: 'Allocated', value: 'allocated' },
  { label: 'Credited', value: 'credited' },
]

export const productionOrderDeliveryNoteStatusToLabel = status => {
  return productionOrderDeliveryNoteStatusOptions.find(o => o.value === status)
    .label
}

export const isProductionOrderDeliveryNoteReceived = status =>
  ['received', 'booked'].includes(status)

export const fileCategories = [
  { types: [], label: 'All' },
  { types: ['image'], label: 'Images' },
  { types: ['csv', 'xls'], label: 'Spreadsheets' },
  { types: ['pdf'], label: 'PDF files' },
  { types: ['doc'], label: 'Word Documents' },
  { types: ['ppt'], label: 'Powerpoints' },
  { types: ['txt'], label: 'Text files' },
]

export const fileTypeLabel = fileCategories.reduce(
  (carry, { types, label }) => {
    types.forEach(type => {
      carry[type] = label
    })
    return carry
  },
  {}
)

export const QuantityLabel = ({
  quantity,
  units,
}: {
  quantity: number,
  units: string,
}) => {
  return (
    <span>
      {quantity % 1 === 0 ? parseInt(quantity) : quantity} {units}
    </span>
  )
}

export const PriceApprovalStatus = ({ approved }: { approved: boolean }) => (
  <OverlayTrigger
    trigger={['hover', 'focus']}
    placement="top"
    overlay={approved ? ApprovedTooltip : UnapprovedTooltip}
  >
    {approved ? <ApprovedPriceMarkIcon /> : <UnapprovedPriceMarkIcon />}
  </OverlayTrigger>
)

const ApprovedPriceMarkIcon = styled.span.attrs({
  className: 'glyphicon glyphicon-ok',
})`
  color: #3fb326;
`

const UnapprovedPriceMarkIcon = styled.span.attrs({
  className: 'glyphicon glyphicon-remove',
})`
  color: #dc6c6c;
`

const ApprovedTooltip = <Tooltip id="price-approved">Approved</Tooltip>

const UnapprovedTooltip = <Tooltip id="price-approved">Unapproved</Tooltip>

export const minimumOrderQuantityModes = [
  { label: 'Per variant', value: 'variant' },
  { label: 'Per color', value: 'color' },
  { label: 'Per product', value: 'product' },
]

export const ProductionExpectedArrivalHelpIndicator = () => {
  return (
    <HelpIndicator id="expected-arrival-tip" size="sm">
      When is the products expected to arrive at their destination
    </HelpIndicator>
  )
}

export const ProductionExpectedDepartureHelpIndicator = () => {
  return (
    <HelpIndicator id="expected-departure-tip" size="sm">
      When is the products expected to be sent from the supplier
    </HelpIndicator>
  )
}
